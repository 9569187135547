import produce from 'immer';
import { HOME_IN_THE_WORLD_SUCCESS, HOME_LATEST_UPDATES_SUCCESS, HOME_NEWS_FAILURE, HOME_NEWS_REQUEST, } from './constants';
export var homeNewsInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    latestUpdates: undefined,
    inTheWorld: undefined,
};
var homeNewsReducer = produce(function (draft, action) {
    switch (action.type) {
        case HOME_NEWS_REQUEST:
            draft.loading = true;
            break;
        case HOME_NEWS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case HOME_LATEST_UPDATES_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.latestUpdates = action.payload;
            break;
        case HOME_IN_THE_WORLD_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.inTheWorld = action.payload;
            break;
        default:
    }
}, homeNewsInitialState);
export default homeNewsReducer;
