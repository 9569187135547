import { LoadingIndicator } from 'components/LoadingIndicator';
import React from 'react';
import { useIntl } from 'react-intl';
import { TeamsList } from '../TeamsList';
import { BlockTitle, Content, TeamsScoreBoardContainer, Title } from './styles';
export var TeamsScoreBoard = function (_a) {
    var _b = _a.isMember, isMember = _b === void 0 ? false : _b, _c = _a.teams, teams = _c === void 0 ? [] : _c, isLoading = _a.isLoading;
    var formatMessage = useIntl().formatMessage;
    if (teams.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null, isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(TeamsScoreBoardContainer, null,
        isMember ? (React.createElement(BlockTitle, null, formatMessage({
            id: 'containers.challengesDetail.teamScoreboard',
        }))) : (React.createElement(Title, null, formatMessage({
            id: 'containers.challengesDetail.selectTeam',
        }))),
        React.createElement(Content, null,
            React.createElement(TeamsList, { teams: teams, isLoading: isLoading }))))));
};
