import { APP_FIELD_BLUR, APP_FIELD_ON_FOCUS, APP_INIT_REQUEST, APP_KEYBOARD_DISABLED, APP_KEYBOARD_ENABLED, APP_SET_DARK_MODE, APP_SET_DIRECTION, APP_SET_FONT_SIZE, APP_TOGGLE_DARK_MODE, APP_TOGGLE_DIRECTION, APP_TOOGLE_FONT_SIZE, APP_USER_CURRENT_USER, } from './constants';
export var toggleDirection = function () { return ({
    type: APP_TOGGLE_DIRECTION,
}); };
export var toggleDarkMode = function () { return ({
    type: APP_TOGGLE_DARK_MODE,
}); };
export var toggleFontSize = function () { return ({
    type: APP_TOOGLE_FONT_SIZE,
}); };
export var keyboardEnabled = function () { return ({
    type: APP_KEYBOARD_ENABLED,
}); };
export var keyboardDisabled = function () { return ({
    type: APP_KEYBOARD_DISABLED,
}); };
export var setDirection = function (payload) { return ({
    type: APP_SET_DIRECTION,
    payload: payload,
}); };
export var setDarkMode = function (payload) { return ({
    type: APP_SET_DARK_MODE,
    payload: payload,
}); };
export var setFontSize = function (payload) { return ({
    type: APP_SET_FONT_SIZE,
    payload: payload,
}); };
export var initApp = function () { return ({
    type: APP_INIT_REQUEST,
}); };
export var currentUserApp = function () { return ({
    type: APP_USER_CURRENT_USER,
}); };
export var fieldOnFocus = function () { return ({
    type: APP_FIELD_ON_FOCUS,
}); };
export var fieldBlur = function () { return ({
    type: APP_FIELD_BLUR,
}); };
