import { createSelector } from 'reselect';
import { initialState } from './reducer';
export var selectOAuthDomain = function (state) {
    return state.oAuthLogin || initialState;
};
export var makeSelectError = function () {
    return createSelector(selectOAuthDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectLoading = function () {
    return createSelector(selectOAuthDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectHasError = function () {
    return createSelector(selectOAuthDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectOAuth = function () {
    return createSelector(selectOAuthDomain, function (subState) { return subState; });
};
export default makeSelectOAuth;
