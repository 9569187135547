import { Button } from 'components/Button';
import UsersProfilesField from 'components/UsersProfilesField';
import { fieldBlur, fieldOnFocus } from 'containers/App/actions';
import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAction } from 'utils/hooks';
import { Template } from '../Template';
import { ButtonTemplate } from '../Template/ButtonTemplate';
import { SuccessFeedbackType } from '../types';
import messages from './messages';
import EditProfileTypeFormSchema from './validator';
export var EditProfileType = function (_a) {
    var onSubmit = _a.onSubmit, onBack = _a.onBack, profile = _a.profile;
    var formatMessage = useIntl().formatMessage;
    var schema = EditProfileTypeFormSchema(formatMessage);
    var fieldOnFocusActionCallback = useAction(fieldOnFocus);
    var fieldBlurActionCallback = useAction(fieldBlur);
    return (React.createElement(Template, { onBack: onBack, pageTitle: formatMessage(messages.title), fieldDescription: formatMessage(messages.fieldDescription), fieldInformation: formatMessage(messages.fieldInformation) },
        React.createElement(Formik, { initialValues: {
                profileId: profile === null || profile === void 0 ? void 0 : profile.profileId,
            }, validationSchema: schema, enableReinitialize: true, onSubmit: function (values) {
                return onSubmit(values, SuccessFeedbackType.ProfileType);
            } }, function (_a) {
            var isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, values = _a.values, handleChange = _a.handleChange;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(UsersProfilesField, { name: "profileId", required: true, value: values.profileId, onChange: handleChange, onFocus: fieldOnFocusActionCallback, onBlur: fieldBlurActionCallback }),
                React.createElement(ButtonTemplate, null,
                    React.createElement(Button, { type: "submit", disabled: isSubmitting }, formatMessage(messages.updateProfileType)))));
        })));
};
