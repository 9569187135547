import { WEEK_USERS_FAILURE, WEEK_USERS_REQUEST, WEEK_USERS_SUCCESS, } from './constants';
export var fetchWeekUsers = function (payload) { return ({
    type: WEEK_USERS_REQUEST,
    payload: payload,
}); };
export var fetchWeekUsersSuccess = function (payload) { return ({
    type: WEEK_USERS_SUCCESS,
    payload: payload,
}); };
export var fetchWeekUsersError = function (error) { return ({
    type: WEEK_USERS_FAILURE,
    payload: error,
}); };
