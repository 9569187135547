var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as api from 'api';
import { myProfile as MyProfileAPI } from 'api';
import { makeSelectUser } from 'containers/Auth/selectors';
import { useGeolocation, useSelector } from 'hooks';
import find from 'lodash/find';
import map from 'lodash/map';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Autocomplete, SearchFieldContainer } from '../SearchField/styles';
import { SuggestionItem } from '../SearchField/SuggestionItem';
export var Search = function (props) {
    var user = useSelector(makeSelectUser());
    var _a = __read(useState([]), 2), options = _a[0], setOptions = _a[1];
    var _b = __read(useState(true), 2), emptyQuery = _b[0], setEmptyQuery = _b[1];
    var geolocation = useGeolocation();
    var searchDelayTimer;
    var getUserPosition = function () {
        var latitude = geolocation.latitude, longitude = geolocation.longitude;
        if (latitude && longitude)
            return { latitude: latitude, longitude: longitude };
        return undefined;
    };
    var handleSearch = function (query) {
        clearTimeout(searchDelayTimer);
        if (!query) {
            setEmptyQuery(true);
            setOptions([]);
            return;
        }
        setEmptyQuery(false);
        searchDelayTimer = setTimeout(function () {
            var latLng = getUserPosition();
            var params = __assign({ token: query, 
                // eslint-disable-next-line @typescript-eslint/camelcase
                session_token: (user === null || user === void 0 ? void 0 : user.sessionToken) || uuid() }, latLng);
            api.places
                .search(params)
                .then(function (response) {
                setOptions(map(response.data, function (p) {
                    var _a;
                    return ({
                        id: p.gpid,
                        key: p.gpid,
                        value: p.name + " " + p.fullAddress,
                        label: React.createElement(SuggestionItem, { title: p.name, subtitle: p.fullAddress }),
                        isplace: (_a = p.isPlace) === null || _a === void 0 ? void 0 : _a.toString(),
                    });
                }));
            })
                .catch(function (_a) {
                var response = _a.response;
                console.warn(response);
            });
        }, 400);
    };
    var getPosition = function (googlePlaceID) { return __awaiter(void 0, void 0, Promise, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, MyProfileAPI.getReverseGpid(googlePlaceID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, {
                            latitude: Number(response.data.lat),
                            longitude: Number(response.data.lng),
                        }];
                case 2:
                    error_1 = _a.sent();
                    return [2 /*return*/, {
                            latitude: 0,
                            longitude: 0,
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSelect = function (placeId) { return __awaiter(void 0, void 0, void 0, function () {
        var placePosition, place;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!props.onSelect) return [3 /*break*/, 2];
                    return [4 /*yield*/, getPosition(placeId)];
                case 1:
                    placePosition = _a.sent();
                    place = find(options, { id: placeId });
                    if (place) {
                        // eslint-disable-next-line eqeqeq
                        place.isplace = place.isplace == 'true';
                    }
                    props.onSelect(placePosition, place);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (event) {
        event.preventDefault();
        if (options.length > 0) {
            handleSelect(options[0].id);
            return;
        }
        if (props.onEmptySearch)
            props.onEmptySearch();
    };
    var handleAutocompleteKeyDown = function (event) {
        var KEY_ENTER = 13;
        if (event.keyCode === KEY_ENTER) {
            if (options.length)
                return handleSelect(options[0].id);
            if (props.onEmptySearch)
                props.onEmptySearch();
        }
    };
    return (React.createElement(SearchFieldContainer, { onSubmit: handleSubmit },
        React.createElement(Autocomplete, { placeholder: props.placeholder, onSearch: handleSearch, options: emptyQuery ? [] : options, onSelect: function (inputValue, option) { return handleSelect(option.id); }, onKeyDown: handleAutocompleteKeyDown }, props.children)));
};
