var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography } from 'antd';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var Paragraph = Typography.Paragraph;
var textColor = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
export var ParagraphWrapper = styled(Paragraph)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", " !important;\n  margin-bottom: 0;\n"], ["\n  color: ", " !important;\n  margin-bottom: 0;\n"])), textColor);
var templateObject_1;
