var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from 'components';
import { AddUser, RemoveUser } from 'icons/mono';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
function getIconSize(size) {
    switch (size) {
        case 'icon':
            return 18;
        case 'mini':
            return 16;
        case 'small':
            return 18;
        case 'medium':
            return 20;
        case 'large':
            return 22;
        default:
            return 20;
    }
}
export var SubscribeButton = memo(function (_a) {
    var _b = _a.isSubscribed, isSubscribed = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? 'button' : _c, size = _a.size, rest = __rest(_a, ["isSubscribed", "type", "size"]);
    return (React.createElement(Button, __assign({ type: type, shape: "pill", size: size, variant: isSubscribed ? 'ghost' : 'primary', icon: isSubscribed ? (React.createElement(RemoveUser, { size: getIconSize(size) })) : (React.createElement(AddUser, { size: getIconSize(size) })) }, rest),
        React.createElement(FormattedMessage, { id: "components.subscribeButton." + (isSubscribed ? 'unsubscribe' : 'subscribe') })));
});
