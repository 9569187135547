import produce from 'immer';
import { OAUTH_LOGIN_FAILURE, OAUTH_LOGIN_REQUEST, OAUTH_LOGIN_SUCCESS, } from './constants';
export var initialState = {
    loading: false,
    hasError: false,
    error: undefined,
};
var oAuthLoginPageReducer = produce(function (draft, action) {
    switch (action.type) {
        case OAUTH_LOGIN_REQUEST:
            draft.loading = true;
            break;
        case OAUTH_LOGIN_SUCCESS:
            return initialState;
        case OAUTH_LOGIN_FAILURE:
            draft.hasError = true;
            draft.error = action.payload;
            break;
        default:
    }
}, initialState);
export default oAuthLoginPageReducer;
