/* eslint-disable lodash/prefer-lodash-method */
import { notification } from 'antd';
import { avatarImage1 } from 'assets/images';
import { Avatar, Button, LoadingIndicator, PageWrapper, Rate, } from 'components';
import makeSelectAuth from 'containers/Auth/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { getPlaceDetails } from 'containers/PlaceDetails/actions';
import makeSelectorPlaceDetails, { makeSelectorLoading as makeSelectPlaceDetailsLoading, } from 'containers/PlaceDetails/selectors';
import { Header } from 'containers/Reviews';
import { addReview, resetAddReviewState, } from 'containers/Reviews/store/addReview/actions';
import makeAddReview, { makeSelectAddReviewError, makeSelectAddReviewHasError, } from 'containers/Reviews/store/addReview/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { Formik } from 'formik';
import { useAction, useGeolocation, useSelector } from 'hooks';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { schema } from './schema';
import { ButtonWrapper, Content, ContentWrapper, ErrorMessage, LoadingWrapper, NewReviewContainer, TextArea, UserInfo, } from './styles';
var NewReviewPage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var history = useHistory();
    var location = useLocation();
    var placeId = useParams().placeId;
    var auth = useSelector(makeSelectAuth());
    var review = useSelector(makeAddReview());
    var placeDetailsLoading = useSelector(makeSelectPlaceDetailsLoading());
    var geolocation = useGeolocation();
    var loadPlaceDetailsRequest = useAction(getPlaceDetails);
    var placeDetail = useSelector(makeSelectorPlaceDetails());
    var addReviewRequest = useAction(function (payload, name) { return addReview(payload, name); });
    var resetAddReviewStateRequest = useAction(function () { return resetAddReviewState(); });
    var addReviewHasError = useSelector(makeSelectAddReviewHasError());
    var addReviewError = useSelector(makeSelectAddReviewError());
    var handleAddReview = function (_a) {
        var body = _a.body, rating = _a.rating;
        // @ts-ignore
        var latitude = geolocation.latitude, longitude = geolocation.longitude;
        var payload = {
            placeId: placeId,
            body: body,
            rating: rating,
            contributorPosition: {
                latitude: latitude,
                longitude: longitude,
            },
        };
        addReviewRequest(payload, placeDetail === null || placeDetail === void 0 ? void 0 : placeDetail.name);
    };
    var formatFeedback = function (message) {
        if (!message)
            return formatMessage({ id: 'pages.newReviewPage.commentSuccess' });
        return (React.createElement("div", null,
            React.createElement("div", null, formatMessage({ id: 'containers.editPlace.successfulFeedback' })),
            React.createElement("div", null,
                React.createElement("p", null, formatMessage({ id: 'containers.editPlace.pointsFeedback' }, { points: message.points })))));
    };
    if (!auth.isLoading && !auth.isLoggedIn) {
        history.push(paths.signInPage, { from: location.pathname });
    }
    useEffect(function () {
        loadPlaceDetailsRequest(placeId);
    }, []);
    useEffect(function () {
        if (addReviewHasError) {
            notification.error({
                message: addReviewError,
            });
        }
    }, [addReviewHasError]);
    useEffect(function () {
        if (review.item && placeDetail) {
            var url = paths.placeDetailsPage
                .replace(':placeId', placeId)
                .replace(':placeName', placeDetail.slug);
            loadPlaceDetailsRequest(placeId);
            resetAddReviewStateRequest();
            notification.success({
                message: formatMessage({
                    id: 'pages.newReviewPage.commentSuccess',
                }),
                description: formatFeedback(review.item.contribution),
            });
            history.push(url);
        }
    }, [review]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.newReviewPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.newReviewPage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.newReviewPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.newReviewPage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null, auth.isLoading || placeDetailsLoading ? (React.createElement(LoadingWrapper, null,
            React.createElement(LoadingIndicator, null))) : (React.createElement(NewReviewContainer, null,
            React.createElement(Header, { title: formatMessage({ id: 'pages.newReviewPage.title' }) }),
            React.createElement(ContentWrapper, null,
                React.createElement(Formik, { initialValues: {
                        body: '',
                        rating: 3,
                    }, validationSchema: schema, enableReinitialize: true, onSubmit: handleAddReview }, function (_a) {
                    var values = _a.values, errors = _a.errors, touched = _a.touched, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, setFieldValue = _a.setFieldValue;
                    var _b, _c, _d, _e, _f;
                    return (React.createElement(UserInfo, { onSubmit: handleSubmit },
                        React.createElement(Content, null,
                            React.createElement(Avatar, { src: ((_c = (_b = auth.user) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.urlThumb) || avatarImage1, alt: "User profile image" }),
                            ((_d = auth.user) === null || _d === void 0 ? void 0 : _d.pseudo) && React.createElement("strong", null, auth.user.pseudo),
                            ((_e = auth.user) === null || _e === void 0 ? void 0 : _e.pseudo) ? (React.createElement("span", null,
                                "(@",
                                auth.user.slug,
                                ")")) : (React.createElement("strong", null,
                                "@", (_f = auth.user) === null || _f === void 0 ? void 0 :
                                _f.slug)),
                            React.createElement(Rate, { value: values.rating, size: 30, onChange: function (v) { return setFieldValue('rating', v); }, disabled: review.loading, allowHalf: false })),
                        React.createElement(TextArea, { name: "body", onChange: handleChange, value: values.body, disabled: review.loading, maxLength: 2000, hasError: (!!errors.body && touched.body) || review.hasError }),
                        errors.body && touched.body && (React.createElement(ErrorMessage, null, formatMessage({
                            id: errors.body,
                        }))),
                        review.hasError && (React.createElement(ErrorMessage, null, review.error.startsWith('containers.')
                            ? formatMessage({ id: review.error })
                            : review.error)),
                        React.createElement(ButtonWrapper, null,
                            React.createElement(Button, { type: "submit", disabled: review.loading }, formatMessage({
                                id: 'pages.newReviewPage.postReviewButton',
                            })))));
                })))))));
};
export default NewReviewPage;
