var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import oAuthFacebookSaga from 'components/FacebookButton/saga';
import subFooterLinksSaga from 'components/SubFooter/saga';
import accountActivationSaga from 'containers/AccountActivated/saga';
import appSaga from 'containers/App/saga';
import authSaga from 'containers/Auth/saga';
import challengeDetailSaga from 'containers/ChallengeDetail/store/sagas';
import challengeJoinTeamSaga from 'containers/ChallengeJoinTeam/sagas';
import challengeTeamSaga from 'containers/ChallengeMembersTeam/store/sagas';
import challengesSaga from 'containers/Challenges/store/saga';
import chatSaga from 'containers/Chat/saga';
import contributorUserSaga from 'containers/Contributors/saga';
import myProfileSaga from 'containers/EditMyProfile/saga';
import addNewPlaceSaga from 'containers/ExplorePlaces/AddNewPlace/saga';
import explorePlacesSaga from 'containers/ExplorePlaces/store/sagas';
import filterPlacesSaga from 'containers/FilterPlaces/saga';
import globalScoreboardSaga from 'containers/GlobalScoreboard/store/sagas';
import newsLetterSaga from 'containers/Home/SignUpNewsletter/saga';
import homeNewsSaga from 'containers/Home/store/homeNews/saga';
import homeLatestNewsSaga from 'containers/Home/store/latestNews/saga';
import homeMostActiveWeeklyUsersSaga from 'containers/Home/store/mostActive/saga';
import mapSaga from 'containers/Map/saga';
import oAuthLoginSaga from 'containers/OAuth/saga';
import placeDetailsSaga from 'containers/PlaceDetails/saga';
import placePhotosSaga from 'containers/PlacePhotos/saga';
import profileSaga from 'containers/Profile/saga';
import resendEmailActivationSaga from 'containers/ResendEmailActivation/saga';
import reviewsSaga from 'containers/Reviews/store/sagas';
import signInEmailSaga from 'containers/SignInEmail/saga';
import signUpEmailSaga from 'containers/SignUpEmail/saga';
import subscriptionsSaga from 'containers/Subscribers/store/sagas';
import { fork } from 'redux-saga/effects';
export default function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fork(accountActivationSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, fork(appSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, fork(authSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, fork(contributorUserSaga)];
            case 4:
                _a.sent();
                return [4 /*yield*/, fork(explorePlacesSaga)];
            case 5:
                _a.sent();
                return [4 /*yield*/, fork(homeLatestNewsSaga)];
            case 6:
                _a.sent();
                return [4 /*yield*/, fork(homeMostActiveWeeklyUsersSaga)];
            case 7:
                _a.sent();
                return [4 /*yield*/, fork(homeNewsSaga)];
            case 8:
                _a.sent();
                return [4 /*yield*/, fork(mapSaga)];
            case 9:
                _a.sent();
                return [4 /*yield*/, fork(globalScoreboardSaga)];
            case 10:
                _a.sent();
                return [4 /*yield*/, fork(myProfileSaga)];
            case 11:
                _a.sent();
                return [4 /*yield*/, fork(oAuthFacebookSaga)];
            case 12:
                _a.sent();
                return [4 /*yield*/, fork(oAuthLoginSaga)];
            case 13:
                _a.sent();
                return [4 /*yield*/, fork(placeDetailsSaga)];
            case 14:
                _a.sent();
                return [4 /*yield*/, fork(placePhotosSaga)];
            case 15:
                _a.sent();
                return [4 /*yield*/, fork(addNewPlaceSaga)];
            case 16:
                _a.sent();
                return [4 /*yield*/, fork(newsLetterSaga)];
            case 17:
                _a.sent();
                return [4 /*yield*/, fork(profileSaga)];
            case 18:
                _a.sent();
                return [4 /*yield*/, fork(resendEmailActivationSaga)];
            case 19:
                _a.sent();
                return [4 /*yield*/, fork(reviewsSaga)];
            case 20:
                _a.sent();
                return [4 /*yield*/, fork(signInEmailSaga)];
            case 21:
                _a.sent();
                return [4 /*yield*/, fork(signUpEmailSaga)];
            case 22:
                _a.sent();
                return [4 /*yield*/, fork(subFooterLinksSaga)];
            case 23:
                _a.sent();
                return [4 /*yield*/, fork(subscriptionsSaga)];
            case 24:
                _a.sent();
                return [4 /*yield*/, fork(challengesSaga)];
            case 25:
                _a.sent();
                return [4 /*yield*/, fork(challengeDetailSaga)];
            case 26:
                _a.sent();
                return [4 /*yield*/, fork(challengeTeamSaga)];
            case 27:
                _a.sent();
                return [4 /*yield*/, fork(challengeJoinTeamSaga)];
            case 28:
                _a.sent();
                return [4 /*yield*/, fork(filterPlacesSaga)];
            case 29:
                _a.sent();
                return [4 /*yield*/, fork(chatSaga)];
            case 30:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
