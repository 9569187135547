import React, { memo } from 'react';
import { PodiumItem } from '../PodiumItem';
import { PodiumContainer } from './styles';
export var Podium = memo(function (_a) {
    var podiumUsers = _a.podiumUsers, loggedUserSlug = _a.loggedUserSlug;
    return (React.createElement(PodiumContainer, null,
        podiumUsers.length >= 3 && (React.createElement(PodiumItem, { user: podiumUsers[2], position: 3, loggedUserSlug: loggedUserSlug })),
        podiumUsers.length > 0 && (React.createElement(PodiumItem, { user: podiumUsers[0], position: 1, loggedUserSlug: loggedUserSlug })),
        podiumUsers.length >= 2 && (React.createElement(PodiumItem, { user: podiumUsers[1], position: 2, loggedUserSlug: loggedUserSlug }))));
});
