var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal25000Mobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-5", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/5", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-23", transform: "translate(0.000000, -0.632653)" },
                        React.createElement("polygon", { id: "Fill-1", fill: "#1B6CEF", points: "4.77310213 17.8887903 4.77310213 31.1400452 12.9437404 26.6614368 21.1138255 31.1400452 21.1138255 17.8887903" }),
                        React.createElement("polygon", { id: "Fill-2", fill: "#134EAD", points: "8.8584766 17.8889518 8.8584766 28.9714182 12.9437957 26.717215 17.0291149 28.9714182 17.0291149 17.8889518" }),
                        React.createElement("polygon", { id: "Fill-3", fill: "#F7D131", points: "12.9436851 -0.000161528441 10.3176851 1.75117442 7.18330213 1.3003463 5.58568511 4.00531503 2.5636 4.9430813 2.31134468 8.06659799 0.000110638298 10.2096729 1.14189787 13.1307547 0.000110638298 16.0523835 2.31134468 18.1949113 2.5636 21.3173338 5.58568511 22.2567414 7.18330213 24.961163 10.3176851 24.5103349 12.9436851 26.2616709 15.5702383 24.5103349 18.7046213 24.961163 20.3016851 22.2567414 23.3237702 21.3173338 23.5765787 18.1949113 25.8867064 16.0529306 25.8867064 16.0512893 24.7454723 13.1307547 25.8867064 10.2107672 25.8867064 10.2091258 23.5765787 8.06659799 23.3237702 4.9430813 20.3016851 4.00531503 18.7046213 1.3003463 15.5702383 1.75117442" }),
                        React.createElement("g", { id: "Group-22", transform: "translate(2.212766, 2.724285)" },
                            React.createElement("path", { d: "M19.9946638,10.1978828 C19.9946638,15.1778046 15.846834,19.2144003 10.7309191,19.2144003 C5.61445106,19.2144003 1.46717447,15.1778046 1.46717447,10.1978828 C1.46717447,5.21849935 5.61445106,1.18136518 10.7309191,1.18136518 C15.846834,1.18136518 19.9946638,5.21849935 19.9946638,10.1978828", id: "Fill-4", fill: "#F8E831" }),
                            React.createElement("path", { d: "M10.7305872,18.1959635 C6.33769362,18.1959635 2.7635234,14.7177178 2.7635234,10.4415215 C2.7635234,6.16586366 6.33769362,2.68654103 10.7305872,2.68654103 C15.124034,2.68654103 18.6982043,6.16586366 18.6982043,10.4415215 C18.6982043,14.7177178 15.124034,18.1959635 10.7305872,18.1959635 M10.7305872,1.02441337 C5.39560851,1.02441337 1.05582128,5.24945897 1.05582128,10.4415215 C1.05582128,15.6341224 5.39560851,19.8575528 10.7305872,19.8575528 C16.065566,19.8575528 20.4053532,15.6341224 20.4053532,10.4415215 C20.4053532,5.24945897 16.065566,1.02441337 10.7305872,1.02441337", id: "Fill-6", fill: "#F7D131" }),
                            React.createElement("path", { d: "M10.7305872,18.5961233 C5.97314043,18.5961233 2.10245957,14.8292801 2.10245957,10.1977212 C2.10245957,5.56831611 5.97314043,1.80039601 10.7305872,1.80039601 C15.4885872,1.80039601 19.3592681,5.56831611 19.3592681,10.1977212 C19.3592681,14.8292801 15.4885872,18.5961233 10.7305872,18.5961233 M10.7305872,0.00043074251 C4.95360851,0.00043074251 0.253693617,4.57545462 0.253693617,10.1977212 C0.253693617,15.8216031 4.95360851,20.3955502 10.7305872,20.3955502 C16.5081191,20.3955502 21.208034,15.8216031 21.208034,10.1977212 C21.208034,4.57545462 16.5081191,0.00043074251 10.7305872,0.00043074251", id: "Fill-8", fill: "#F8E831" }),
                            React.createElement("path", { d: "M3.4305617,12.5430065 C3.4305617,12.2673313 3.45324255,12.0250386 3.49860426,11.8150517 C3.54396596,11.6061416 3.61754043,11.4176917 3.71932766,11.2497021 C3.82111489,11.0811741 3.95277447,10.9314911 4.11430638,10.7990378 C4.27528511,10.6671229 4.47498723,10.5373617 4.71285957,10.4108311 C5.01877447,10.2509179 5.26051915,10.122772 5.43864681,10.0263934 C5.61732766,9.93001476 5.7512,9.85140426 5.8419234,9.7911003 C5.93264681,9.73025792 5.99017872,9.67964568 6.01617872,9.63764828 C6.04162553,9.59618932 6.05434894,9.55096135 6.05434894,9.50196439 C6.05434894,9.36412679 6.00898723,9.26182545 5.91826383,9.19559878 C5.82754043,9.12937212 5.70307234,9.09706644 5.54485957,9.09706644 C5.35788085,9.09706644 5.18085957,9.14390968 5.01434894,9.23705775 C4.84728511,9.33074425 4.67358298,9.48204255 4.49213617,9.69202953 L3.47315745,8.52633261 C3.5804766,8.41057056 3.71932766,8.30503865 3.88915745,8.20866001 C4.05898723,8.11228137 4.24707234,8.02936344 4.45396596,7.96044464 C4.66030638,7.89152584 4.8799234,7.83822145 5.11226383,7.7989162 C5.34349787,7.7606878 5.5819234,7.74130439 5.82532766,7.74130439 C6.47588085,7.74130439 6.96988085,7.87214242 7.30677447,8.1338185 C7.64311489,8.396033 7.8118383,8.76054885 7.8118383,9.22951976 C7.8118383,9.39966305 7.78915745,9.55096135 7.74379574,9.68287625 C7.69843404,9.815868 7.63758298,9.93539904 7.56124255,10.0430847 C7.48490213,10.1502319 7.39307234,10.2482258 7.2852,10.3359896 C7.17788085,10.4242918 7.0644766,10.5072097 6.94554043,10.584205 C6.8044766,10.6778914 6.65566809,10.7672705 6.50022128,10.8528806 C6.34422128,10.9384907 6.18988085,11.0327156 6.0372,11.1382475 L7.90532766,11.1382475 L7.90532766,12.5430065 L3.4305617,12.5430065 Z", id: "Fill-10", fill: "#D8B627" }),
                            React.createElement("path", { d: "M10.800566,9.84876596 C11.0948638,9.84876596 11.3637149,9.89560921 11.6071191,9.9892957 C11.8505234,10.0829822 12.0612894,10.2138202 12.239417,10.3812714 C12.4175447,10.5503378 12.5563957,10.7511715 12.655417,10.9848493 C12.7544383,11.2190656 12.8042255,11.4769726 12.8042255,11.7580321 C12.8042255,12.0557829 12.7478,12.3276891 12.6343957,12.575366 C12.5209915,12.8235814 12.3611191,13.0357221 12.1547787,13.2117881 C11.9478851,13.3883925 11.7017149,13.5262301 11.4157149,13.6253009 C11.1302681,13.7243717 10.8143957,13.7744455 10.4692043,13.7744455 C10.0277574,13.7744455 9.63001277,13.6942197 9.2765234,13.5343066 C8.92248085,13.3743934 8.64920426,13.1460999 8.45724681,12.8483491 L9.34014043,11.8565645 C9.5044383,12.0277846 9.6864383,12.1613148 9.8878,12.2576934 C10.0886085,12.3535337 10.2849915,12.4019922 10.4780553,12.4019922 C10.6871617,12.4019922 10.8514596,12.3476109 10.9703957,12.2372332 C11.0887787,12.1268554 11.1485234,11.9777108 11.1485234,11.7903378 C11.1485234,11.6088875 11.0948638,11.4629735 10.9869915,11.3525957 C10.8796723,11.242218 10.7380553,11.1872983 10.5626936,11.1872983 C10.4437574,11.1872983 10.3347787,11.2136813 10.2357574,11.2659088 C10.1367362,11.3181363 10.0615021,11.3886704 10.0106085,11.4769726 L8.6270766,11.4769726 L9.34014043,7.82373773 L12.3627787,7.82373773 L12.3627787,9.25434129 L10.5035021,9.25434129 L10.4100128,9.8896865 C10.4321404,9.88430221 10.4636723,9.87945636 10.5035021,9.87353365 C10.5317149,9.86814937 10.5709915,9.86222666 10.6224383,9.85738081 C10.6733319,9.8514581 10.7325234,9.84876596 10.800566,9.84876596", id: "Fill-12", fill: "#D8B627" }),
                            React.createElement("polygon", { id: "Fill-14", fill: "#D8B627", points: "16.3786723 12.5430065 15.3685447 10.9562588 15.0454809 11.3030065 15.0454809 12.5430065 13.4329277 12.5430065 13.4329277 6.50991924 15.0454809 6.50991924 15.0454809 9.80779158 16.2342894 8.19627616 17.9325872 8.19627616 16.3958213 10.0963891 18.0852681 12.5430065" }),
                            React.createElement("path", { d: "M3.76302979,11.9288215 C3.76302979,11.6596075 3.78515745,11.4232375 3.82941277,11.2180964 C3.87366809,11.0134937 3.94558298,10.8293513 4.04515745,10.6645923 C4.14473191,10.5009101 4.27362553,10.3539192 4.43128511,10.2246965 C4.58894468,10.0954737 4.78366809,9.96894312 5.01600851,9.84510465 C5.31473191,9.68896049 5.55094468,9.56350673 5.72575319,9.46982023 C5.90000851,9.37559531 6.03111489,9.29860009 6.11962553,9.23937299 C6.20813617,9.18068432 6.2645617,9.13061051 6.29000851,9.0902284 C6.31490213,9.04930786 6.32707234,9.00515675 6.32707234,8.95669822 C6.32707234,8.82209119 6.28281702,8.72248198 6.19430638,8.6578706 C6.10579574,8.59325923 5.98409362,8.56095354 5.8292,8.56095354 C5.64664681,8.56095354 5.47349787,8.60671993 5.31085957,8.69825271 C5.14711489,8.7897855 4.97728511,8.93785323 4.80026383,9.14245593 L3.80451915,8.00368042 C3.90962553,7.89061051 4.04515745,7.78723231 4.21111489,7.69300738 C4.37707234,7.59878246 4.56073191,7.51801824 4.7632,7.45071472 C4.9645617,7.3834112 5.1792,7.33064525 5.40600851,7.29295528 C5.63281702,7.25526531 5.86515745,7.23642032 6.10302979,7.23642032 C6.7392,7.23642032 7.22158298,7.36402779 7.55128511,7.61978116 C7.87988085,7.87607295 8.04473191,8.23251238 8.04473191,8.69017629 C8.04473191,8.85708901 8.02260426,9.00515675 7.97834894,9.1343795 C7.93409362,9.26360226 7.87490213,9.38097959 7.80022128,9.48597308 C7.72554043,9.59042814 7.63537021,9.68626835 7.53026383,9.77241685 C7.42515745,9.85856535 7.31451915,9.93932957 7.19834894,10.0147095 C7.06005106,10.1062423 6.91511489,10.1934677 6.76298723,10.2774624 C6.61030638,10.3609188 6.4598383,10.4535284 6.3104766,10.5558298 L8.13600851,10.5558298 L8.13600851,11.9288215 L3.76302979,11.9288215 Z", id: "Fill-16", fill: "#F9F1B7" }),
                            React.createElement("path", { d: "M10.965583,9.29612332 C11.2532426,9.29612332 11.5160085,9.34188971 11.7538809,9.43342249 C11.9917532,9.52495528 12.1980936,9.65256274 12.3717957,9.81678333 C12.5460511,9.98100391 12.6821362,10.1780686 12.7783915,10.4069006 C12.8752,10.6351941 12.9238809,10.8871785 12.9238809,11.1617768 C12.9238809,11.452528 12.8685617,11.7190499 12.7579234,11.9608042 C12.6472851,12.2030968 12.4907319,12.4103917 12.2893702,12.5826887 C12.0869021,12.7555241 11.8462638,12.8901311 11.5669021,12.9865098 C11.2880936,13.0834268 10.9794128,13.1324238 10.641966,13.1324238 C10.2104766,13.1324238 9.82158298,13.0538133 9.47639149,12.8976691 C9.13064681,12.741525 8.86345532,12.5180773 8.67537021,12.2273261 L9.53834894,11.2586939 C9.69877447,11.4256066 9.87690213,11.5559062 10.0738383,11.6501311 C10.2696681,11.7443561 10.4621787,11.7917377 10.6502638,11.7917377 C10.8549447,11.7917377 11.0153702,11.7378949 11.1315404,11.6302093 C11.2477106,11.5225237 11.3057957,11.3766096 11.3057957,11.1935441 C11.3057957,11.0158628 11.2532426,10.8737178 11.1481362,10.7654937 C11.0430298,10.6578081 10.9047319,10.6045037 10.7332426,10.6045037 C10.6170723,10.6045037 10.5103064,10.6303482 10.4134979,10.6809605 C10.3172426,10.7321112 10.2436681,10.80103 10.1938809,10.8871785 L8.84132766,10.8871785 L9.53834894,7.31686149 L12.4923915,7.31686149 L12.4923915,8.71462093 L10.6751574,8.71462093 L10.5838809,9.335967 C10.6060085,9.33058272 10.636434,9.32573686 10.6751574,9.32035258 C10.702817,9.3149683 10.7415404,9.30904559 10.7913277,9.30419974 C10.8411149,9.29881546 10.8992,9.29612332 10.965583,9.29612332", id: "Fill-18", fill: "#F9F1B7" }),
                            React.createElement("polygon", { id: "Fill-20", fill: "#F9F1B7", points: "16.4172851 11.9288215 15.4298383 10.3781485 15.1145191 10.7173582 15.1145191 11.9288215 13.5379234 11.9288215 13.5379234 6.03303343 15.1145191 6.03303343 15.1145191 9.25552584 16.2762213 7.68062353 17.9357957 7.68062353 16.4338809 9.53820061 18.0851574 11.9288215" })))),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
