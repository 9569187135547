var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Alert = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M20 4C11.1777 4 4 11.1777 4 20C4 28.8223 11.1777 36 20 36C28.8223 36 36 28.8223 36 20C36 11.1777 28.8223 4 20 4ZM20 28.6085C19.1503 28.6085 18.4615 27.9197 18.4615 27.07C18.4615 26.2203 19.1503 25.5315 20 25.5315C20.8497 25.5315 21.5385 26.2203 21.5385 27.07C21.5385 27.9197 20.8497 28.6085 20 28.6085ZM21.6708 13.1354L21.2292 22.52C21.2292 23.1997 20.6782 23.7508 19.9985 23.7508C19.3187 23.7508 18.7677 23.1997 18.7677 22.52L18.3262 13.14V13.1362C18.2998 12.5221 18.6124 11.9431 19.1401 11.6283C19.6679 11.3134 20.3259 11.3134 20.8537 11.6283C21.3815 11.9431 21.694 12.5221 21.6677 13.1362L21.6708 13.1354Z", fill: color })));
});
