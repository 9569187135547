var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Button, Input, Label, Spin, Switch } from 'components';
import Fuse from 'fuse.js';
import { useAction, useSelector } from 'hooks';
import { Plus } from 'icons/mono';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { setBooleanFilter, setSelectedAccessibilityFilters } from '../actions';
import { makeSelectorAccessibilityFilters, makeSelectorBooleanFilter, makeSelectorLoading, makeSelectorSelectedAccessibilityFilters, } from '../selectors';
import { Scrollbar, SwitchWrapper, TermButtonListItem, TermButtonsList, TermsSelectorContainer, } from './styles';
export var TermsSelector = function () {
    var formatMessage = useIntl().formatMessage;
    var loading = useSelector(makeSelectorLoading());
    var accessibilityFilters = useSelector(makeSelectorAccessibilityFilters());
    var selectedTerms = useSelector(makeSelectorSelectedAccessibilityFilters());
    var booleanFilter = useSelector(makeSelectorBooleanFilter());
    var setSelectedAccessibilityFiltersCallback = useAction(setSelectedAccessibilityFilters);
    var setBooleanFilterCallback = useAction(setBooleanFilter);
    // @ts-ignore
    var _a = __read(useState(), 2), fuse = _a[0], setFuse = _a[1];
    var _b = __read(useState([]), 2), terms = _b[0], setTerms = _b[1];
    var createFuse = function () {
        setFuse(new Fuse(accessibilityFilters, { keys: ['label'], threshold: 0.3 }));
    };
    useEffect(function () {
        if (accessibilityFilters === null || accessibilityFilters === void 0 ? void 0 : accessibilityFilters.length) {
            createFuse();
        }
    }, [accessibilityFilters]);
    var handleSearch = function (event) {
        var result = map(fuse === null || fuse === void 0 ? void 0 : fuse.search(event.currentTarget.value), function (_a) {
            var item = _a.item;
            return item;
        });
        setTerms(result);
    };
    var isSelected = function (term) {
        return find(selectedTerms, { criterionId: term.criterionId });
    };
    var handleSelect = function (term) {
        if (isSelected(term) && selectedTerms) {
            setSelectedAccessibilityFiltersCallback(filter(selectedTerms, function (t) { return t.criterionId !== term.criterionId; }));
            return;
        }
        setSelectedAccessibilityFiltersCallback(__spread(selectedTerms, [term]));
    };
    var getTerms = function () {
        if (!accessibilityFilters)
            return [];
        return orderBy(uniq(__spread(((terms === null || terms === void 0 ? void 0 : terms.length) ? terms : accessibilityFilters), selectedTerms)), ['label'], ['asc']);
    };
    return (React.createElement(TermsSelectorContainer, { onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Label, null, formatMessage({
                id: 'containers.filterPlaces.termsSelector.title',
            })),
            React.createElement(Input, { placeholder: formatMessage({
                    id: 'containers.filterPlaces.termsSelector.inputPlaceholder',
                }), onChange: handleSearch }),
            React.createElement(SwitchWrapper, null,
                React.createElement(Label, null, formatMessage({
                    id: 'containers.filterPlaces.termsSelector.showPlacesSwitch',
                })),
                React.createElement(Switch, { checked: booleanFilter, onChange: function () { return setBooleanFilterCallback(!booleanFilter); } })),
            React.createElement(Scrollbar, { hideScrollbars: false },
                React.createElement(TermButtonsList, null, map(getTerms(), function (term) { return (React.createElement(TermButtonListItem, { key: term.label },
                    React.createElement(Button, { size: "small", shape: "pill", variant: isSelected(term) ? 'primary' : 'ghost', icon: React.createElement(Plus, { size: rem(16) }), iconPosition: "right", onClick: function () { return handleSelect(term); } }, term.label))); }))))));
};
