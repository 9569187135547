/* eslint-disable lodash/prefer-lodash-method */
import { LoadingIndicator } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import map from 'lodash/map';
import React from 'react';
import UserListItem from '../UserListItem';
import { Body, Container, Place } from './styles';
var UserList = function (_a) {
    var users = _a.users, loggedUserId = _a.loggedUserId, isLoading = _a.isLoading;
    return (React.createElement(Container, null,
        map(users, function (user, index) {
            return user ? (React.createElement(Body, null,
                React.createElement(Place, { isLoggedUser: user.id === loggedUserId },
                    index + 1,
                    "\u00BA"),
                React.createElement(UserListItem, { key: user.id, user: user, highlightedBackground: user.id === loggedUserId, redirectLink: user.id === loggedUserId
                        ? paths.profilePage
                        : paths.usersPage.replace(':userSlug', user.slug), points: user.points }))) : null;
        }),
        isLoading && React.createElement(LoadingIndicator, null)));
};
export default UserList;
