var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Avatar, Button, Modal, Spin, Upload } from 'components';
import { Close } from 'icons/mono';
import find from 'lodash/find';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePrevious, useSelector } from 'utils/hooks';
import { makeSelectCarousel, makeSelectLoading } from '../selectors';
import { SuccessFeedbackType } from '../types';
import { getDefaultAvatarFileNameFromRenamedFile, numberToAvatar, } from '../utils';
import messages from './messages';
import { PhotoCarousel } from './PhotoCarousel';
import { ActionButtonsContainer, AvatarContainer, Container, PhotoError, RemovePhoto, RulesContainer, UploadButtonContainer, } from './styles';
export var EditPhoto = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, onUpdate = _a.onUpdate, onDelete = _a.onDelete, userAvatar = _a.userAvatar;
    var carousel = useSelector(makeSelectCarousel());
    var isLoading = useSelector(makeSelectLoading());
    var prevVisible = usePrevious(visible);
    var prevCarousel = usePrevious(carousel);
    var formatMessage = useIntl().formatMessage;
    var _b = __read(useState(), 2), imageFile = _b[0], setImageFile = _b[1];
    var _c = __read(useState(), 2), imageUploadFailed = _c[0], setImageUploadFailed = _c[1];
    var _d = __read(useState(), 2), localImageUrl = _d[0], setLocalImageUrl = _d[1];
    var fileMaxSize = 20;
    var allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    var validateImageUpload = function (file) {
        setImageUploadFailed(false);
        var isAllowedType = find(allowedTypes, function (item) { return file.type === item; });
        if (!isAllowedType) {
            setImageUploadFailed(true);
        }
        var isAllowedSize = file.size / 1024 / 1024 <= fileMaxSize;
        if (!isAllowedSize) {
            setImageUploadFailed(true);
        }
        var urlForImage = URL.createObjectURL(file);
        var image = new Image();
        image.src = urlForImage;
        image.onload = function () {
            if (image.naturalHeight < 320 || image.naturalWidth < 320) {
                setImageUploadFailed(true);
                return false;
            }
        };
        setLocalImageUrl(urlForImage);
        setImageFile(file);
        return false;
    };
    var hasChanges = function () {
        return imageFile || carousel.avatar !== (prevCarousel === null || prevCarousel === void 0 ? void 0 : prevCarousel.avatar);
    };
    var getAvatarToUpload = function (avatar, avatarNumber) { return __awaiter(void 0, void 0, void 0, function () {
        var image, buffer, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch(avatar)];
                case 1:
                    image = _a.sent();
                    return [4 /*yield*/, image.arrayBuffer()];
                case 2:
                    buffer = _a.sent();
                    return [2 /*return*/, new File([buffer], "avatar" + avatarNumber + ".png", {
                            type: 'image/png',
                        })];
                case 3:
                    e_1 = _a.sent();
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var avatarToUpload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (imageFile) {
                        onUpdate({ file: imageFile, type: SuccessFeedbackType.Photo });
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAvatarToUpload(numberToAvatar(carousel.avatar), carousel.avatar)];
                case 1:
                    avatarToUpload = _a.sent();
                    if (avatarToUpload)
                        onUpdate({
                            file: avatarToUpload,
                            type: SuccessFeedbackType.DefaultAvatar,
                        });
                    return [2 /*return*/];
            }
        });
    }); };
    var clearImages = function () {
        setLocalImageUrl(undefined);
        setImageFile(undefined);
        setImageUploadFailed(false);
    };
    useEffect(function () {
        if (prevVisible !== visible) {
            clearImages();
        }
    }, [visible]);
    var handleCancel = function () {
        clearImages();
        onCancel();
    };
    var handleRemoveAvatar = function () {
        clearImages();
        onDelete(userAvatar.id);
    };
    var shouldShowCarousel = function () {
        var result = getDefaultAvatarFileNameFromRenamedFile(userAvatar === null || userAvatar === void 0 ? void 0 : userAvatar.filename);
        return (!userAvatar || result) && !localImageUrl;
    };
    return (React.createElement(Container, null,
        React.createElement(Modal, { title: formatMessage(messages.modalTitle), visible: visible, footer: null, onCancel: handleCancel },
            shouldShowCarousel() && (React.createElement(Spin, { spinning: isLoading },
                React.createElement(PhotoCarousel, null))),
            !shouldShowCarousel() && (React.createElement("div", null,
                React.createElement(AvatarContainer, null,
                    !localImageUrl && !imageUploadFailed && (React.createElement(RemovePhoto, { role: "button", onClick: handleRemoveAvatar },
                        React.createElement(Close, { size: rem(14), color: "white" }))),
                    React.createElement(Spin, { spinning: isLoading }, !imageUploadFailed && (React.createElement(Avatar, { size: "xLarge", src: localImageUrl || userAvatar.url, alt: "Avatar 1" })))))),
            imageUploadFailed && !shouldShowCarousel() && (React.createElement(PhotoError, null, formatMessage(messages.uploadError))),
            React.createElement(RulesContainer, null,
                React.createElement("p", null,
                    formatMessage(messages.resolutionRule),
                    React.createElement("b", null, "320x320 pixels"),
                    "."),
                React.createElement("p", null,
                    formatMessage(messages.sizeRule),
                    React.createElement("b", null, "20 MB"),
                    "."),
                React.createElement("p", null,
                    formatMessage(messages.extensionsRule),
                    React.createElement("b", null, "PNG, GIF, JPEG, JPG"),
                    ".")),
            React.createElement(UploadButtonContainer, null,
                React.createElement(Upload, { beforeUpload: function (file) { return validateImageUpload(file); }, showUploadList: false, disabled: isLoading, accept: "image/*" },
                    React.createElement(Button, { variant: "ghost" }, "Choose a new file"))),
            React.createElement(ActionButtonsContainer, null,
                React.createElement(Button, { variant: "ghost", onClick: handleCancel, disabled: isLoading }, "Cancel"),
                React.createElement(Button, { onClick: handleUpdate, disabled: !hasChanges() || isLoading || imageUploadFailed }, "Update")))));
};
