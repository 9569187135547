var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { rem, rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var media = generateMedia({
    medium: '768px',
    large: '1220px',
    huge: '1820px',
});
var background = theme('mode', {
    light: colors.base.primary,
    dark: colors.carbon.tint900,
});
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
var shadow = theme('mode', {
    light: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", " !important;\n  "], ["\n    box-shadow: 0 0 0 ", " ", " !important;\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", " !important;\n  "], ["\n    box-shadow: 0 0 0 ", " ", " !important;\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var NavbarContainer = styled.nav(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: ", ";\n  padding: 0 ", ";\n  position: fixed;\n  top: 0;\n  transition: background-color 0.3s;\n  width: 100%;\n  z-index: 1000;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  height: ", ";\n  padding: 0 ", ";\n  position: fixed;\n  top: 0;\n  transition: background-color 0.3s;\n  width: 100%;\n  z-index: 1000;\n\n  ",
    ";\n\n  ",
    ";\n\n  ",
    ";\n\n  ",
    ";\n\n  ",
    ";\n\n  ",
    ";\n"])), rem(80), rem(16), media.lessThan('medium')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    height: ", ";\n  "], ["\n    height: ", ";\n  "])), rem(60)), ifProp('isTransparent', css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      background-color: 'transparent';\n    "], ["\n      background-color: 'transparent';\n    "]))), css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), background)), ifProp('isScroll', css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), background)), ifProp('isKeyboardMode', css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
    "\n    "])), media.greaterThan('medium')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n        top: ", ";\n      "], ["\n        top: ", ";\n      "])), rem(50)))), ifProp('hasAlert', css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      ", "\n      ", "\n    "], ["\n      ",
    "\n      ",
    "\n    "])), media.lessThan('medium')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        top: ", ";\n      "], ["\n        top: ", ";\n      "])), rem(50)), media.greaterThan('medium')(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n        top: ", ";\n      "], ["\n        top: ", ";\n      "])), rem(40)))), ifProp(['isKeyboardMode', 'hasAlert'], css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
    "\n    "])), media.greaterThan('medium')(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n        top: ", ";\n      "], ["\n        top: ", ";\n      "])), rem(90)))));
export var HomeLink = styled(NavLink)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-right: ", ";\n  position: relative;\n\n  svg {\n    height: ", ";\n    width: ", ";\n  }\n\n  ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-right: ", ";\n  position: relative;\n\n  svg {\n    height: ", ";\n    width: ", ";\n  }\n\n  ",
    ";\n"])), rem(22), rem(36), rem(44), ifProp('isKeyboardMode', css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      &:focus {\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n        }\n      }\n    "])), border, rem(10), rem(10), rem(10))));
export var Navigation = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  margin-left: auto;\n\n  ", ";\n\n  a:focus {\n    outline: 0 !important;\n  }\n\n  button {\n    box-shadow: none !important;\n  }\n\n  ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  margin-left: auto;\n\n  ",
    ";\n\n  a:focus {\n    outline: 0 !important;\n  }\n\n  button {\n    box-shadow: none !important;\n  }\n\n  ",
    ";\n"])), media.lessThan('medium')(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "]))), ifProp('isKeyboardMode', css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      a:focus {\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: ", ";\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n\n      button:focus {\n        ", ";\n      }\n    "], ["\n      a:focus {\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: ", ";\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n\n      button:focus {\n        ", ";\n      }\n    "])), border, rem(10), rem(38), rem(10), rem(-8), shadow)));
export var MenuItem = styled(NavLink)(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  align-items: center;\n  align-self: baseline;\n  border-bottom: ", " solid transparent;\n  color: ", ";\n  display: flex;\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  height: 100%;\n  margin: 0 ", ";\n  position: relative;\n  text-decoration: none;\n  white-space: nowrap;\n\n  &:hover,\n  &:focus {\n    color: ", ";\n    text-decoration: underline;\n    outline: 0 !important;\n  }\n\n  &.active {\n    border-color: ", ";\n  }\n\n  ", ";\n"], ["\n  align-items: center;\n  align-self: baseline;\n  border-bottom: ", " solid transparent;\n  color: ", ";\n  display: flex;\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  height: 100%;\n  margin: 0 ", ";\n  position: relative;\n  text-decoration: none;\n  white-space: nowrap;\n\n  &:hover,\n  &:focus {\n    color: ", ";\n    text-decoration: underline;\n    outline: 0 !important;\n  }\n\n  &.active {\n    border-color: ", ";\n  }\n\n  ",
    ";\n"])), rem(2), colors.base.white, fonts.family.primary, rem(16), fonts.weight.bold, rem(8), colors.base.white, colors.base.white, ifProp('isKeyboardMode', css(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n      &:focus {\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: ", ";\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: ", ";\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n    "])), border, rem(10), rem(38), rem(10), rem(-8))));
export var UserSignIn = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n\n  ", ";\n"], ["\n  display: flex;\n  height: 100%;\n\n  ",
    ";\n"])), media.lessThan('medium')(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n    margin-left: auto;\n  "], ["\n    margin-left: auto;\n  "]))));
export var UserMenu = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  display: flex;\n\n  ", ";\n"], ["\n  display: flex;\n\n  ",
    ";\n"])), media.lessThan('medium')(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n    margin-left: auto;\n  "], ["\n    margin-left: auto;\n  "]))));
export var SearchFieldWrapper = styled.div(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  position: absolute;\n  left: ", ";\n  width: ", ";\n  transition: all 0.2s;\n\n  ", ";\n\n  ", "\n"], ["\n  position: absolute;\n  left: ", ";\n  width: ", ";\n  transition: all 0.2s;\n\n  ",
    ";\n\n  ",
    "\n"])), rem(80), rem(390), media.lessThan('large')(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n    width: ", ";\n\n    .ant-select-selection-search-input {\n      background: transparent;\n    }\n\n    .ant-select-selection-placeholder {\n      color: transparent;\n    }\n\n    .ant-input-prefix {\n      color: ", ";\n    }\n\n    &:hover {\n      .ant-select-selection-search-input {\n        background: ", ";\n      }\n\n      .ant-input-prefix {\n        color: inherit;\n      }\n    }\n\n    ", "\n  "], ["\n    width: ", ";\n\n    .ant-select-selection-search-input {\n      background: transparent;\n    }\n\n    .ant-select-selection-placeholder {\n      color: transparent;\n    }\n\n    .ant-input-prefix {\n      color: ", ";\n    }\n\n    &:hover {\n      .ant-select-selection-search-input {\n        background: ", ";\n      }\n\n      .ant-input-prefix {\n        color: inherit;\n      }\n    }\n\n    ",
    "\n  "])), rem(40), colors.base.white, colors.base.white, ifProp('hasFocus', css(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n        width: ", ";\n\n        .ant-select-selection-search-input {\n          background: ", ";\n        }\n\n        .ant-input-prefix {\n          color: inherit;\n        }\n      "], ["\n        width: ", ";\n\n        .ant-select-selection-search-input {\n          background: ", ";\n        }\n\n        .ant-input-prefix {\n          color: inherit;\n        }\n      "])), rem(390), colors.base.white))), ifProp('fontSize', css(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n      ", ";\n    "], ["\n      ",
    ";\n    "])), media.lessThan('huge')(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n        width: ", ";\n\n        .ant-select-selection-search-input {\n          background: transparent;\n        }\n\n        .ant-select-selection-placeholder {\n          color: transparent;\n        }\n\n        .ant-input-prefix {\n          color: ", ";\n        }\n\n        &:hover {\n          .ant-select-selection-search-input {\n            background: ", ";\n          }\n\n          .ant-input-prefix {\n            color: inherit;\n          }\n        }\n\n        ", "\n      "], ["\n        width: ", ";\n\n        .ant-select-selection-search-input {\n          background: transparent;\n        }\n\n        .ant-select-selection-placeholder {\n          color: transparent;\n        }\n\n        .ant-input-prefix {\n          color: ", ";\n        }\n\n        &:hover {\n          .ant-select-selection-search-input {\n            background: ", ";\n          }\n\n          .ant-input-prefix {\n            color: inherit;\n          }\n        }\n\n        ",
    "\n      "])), rem(40), colors.base.white, colors.base.white, ifProp('hasFocus', css(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n            width: ", ";\n\n            .ant-select-selection-search-input {\n              background: ", ";\n            }\n\n            .ant-input-prefix {\n              color: inherit;\n            }\n          "], ["\n            width: ", ";\n\n            .ant-select-selection-search-input {\n              background: ", ";\n            }\n\n            .ant-input-prefix {\n              color: inherit;\n            }\n          "])), rem(390), colors.base.white))))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33;
