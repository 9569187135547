import * as Yup from 'yup';

const signInEmailSchema = (formatMessage) =>
  Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'containers.signInEmail.emailFormat' }))
      .required(formatMessage({ id: 'containers.signInEmail.emailRequired' })),
    password: Yup.string().required(
      formatMessage({ id: 'containers.signInEmail.passwordRequired' }),
    ),
  });

export const defaultProps = {
  email: '',
  password: '',
};

export default signInEmailSchema;
