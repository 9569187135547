var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Title = styled.h4(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), color, rem(24), fonts.weight.bold, rem(24));
export var CardsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  padding-bottom: ", ";\n  display: flex;\n  justify-content: space-between;\n\n  a {\n    width: calc(20% - ", ");\n    text-decoration: none;\n  }\n\n  > div {\n    background: none;\n    width: 100%;\n\n    img {\n      border-radius: ", ";\n    }\n  }\n"], ["\n  border-bottom: ", " solid ", ";\n  padding-bottom: ", ";\n  display: flex;\n  justify-content: space-between;\n\n  a {\n    width: calc(20% - ", ");\n    text-decoration: none;\n  }\n\n  > div {\n    background: none;\n    width: 100%;\n\n    img {\n      border-radius: ", ";\n    }\n  }\n"])), rem(1), border, rem(60), rem(20), rem(4));
export var LoadingWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(32));
var templateObject_1, templateObject_2, templateObject_3;
