import { createSelector } from 'reselect';
import { contributorUserContributionsInitialState } from '../reducers/contributorUserContributionsReducer';
export var selectContributorUserContributionsDomain = function (state) {
    return state.contributorUserContributions ||
        contributorUserContributionsInitialState;
};
export var makeSelectContributorUserContributionsLoading = function () {
    return createSelector(selectContributorUserContributionsDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectContributorUserContributionsHasError = function () {
    return createSelector(selectContributorUserContributionsDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectContributorUserContributionsError = function () {
    return createSelector(selectContributorUserContributionsDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectContributorUserContributionsPages = function () {
    return createSelector(selectContributorUserContributionsDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectContributorUserContributionsCurrentPage = function () {
    return createSelector(selectContributorUserContributionsDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectContributorUserContributionsTotal = function () {
    return createSelector(selectContributorUserContributionsDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectContributorUserContributionsItems = function () {
    return createSelector(selectContributorUserContributionsDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeContributorUserContributions = function () {
    return createSelector(selectContributorUserContributionsDomain, function (subState) { return subState; });
};
export default makeContributorUserContributions;
