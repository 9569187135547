var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { breakpoints, colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
export var JoinTeamContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  border-bottom: ", " solid ", ";\n  color: ", ";\n  display: flex;\n  justify-content: center;\n  margin-top: auto;\n"], ["\n  border-top: ", " solid ", ";\n  border-bottom: ", " solid ", ";\n  color: ", ";\n  display: flex;\n  justify-content: center;\n  margin-top: auto;\n"])), rem(5), border, rem(5), border, color);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n  max-width: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  padding: ", ";\n  max-width: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"])), rem(30), breakpoints.medium);
export var Title = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: ", ";\n"])), rem(18), fonts.weight.semibold, rem(10));
var templateObject_1, templateObject_2, templateObject_3;
