var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var DarkModeWhite = memo(function (props) { return (React.createElement("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("g", { clipPath: "url(#dark-mode-white_svg__clip0)", fill: "#fff" },
        React.createElement("path", { d: "M9.998 1.17A8.778 8.778 0 003.75 3.756C.306 7.2.306 12.801 3.751 16.246A8.775 8.775 0 0010 18.83a8.777 8.777 0 006.247-2.585c3.444-3.445 3.444-9.047 0-12.49A8.775 8.775 0 0010 1.168h-.001zm0 18.829a9.942 9.942 0 01-7.072-2.928c-3.9-3.899-3.9-10.243 0-14.142A9.938 9.938 0 0110 .002a9.94 9.94 0 017.072 2.927A9.932 9.932 0 0120.003 10a9.928 9.928 0 01-2.93 7.07A9.938 9.938 0 019.998 20" }),
        React.createElement("path", { d: "M4.06 15.935A8.393 8.393 0 019.999 1.606c2.227 0 4.362.885 5.937 2.46", fillOpacity: 0.75 })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "dark-mode-white_svg__clip0" },
            React.createElement("path", { fill: "#fff", d: "M0 0h20v20H0z" }))))); });
