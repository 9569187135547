var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
import { colors } from 'styles';
export var Target = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  svg {\n    fill: ", ";\n  }\n\n  &:focus {\n    box-shadow: none !important;\n  }\n"], ["\n  svg {\n    fill: ", ";\n  }\n\n  &:focus {\n    box-shadow: none !important;\n  }\n"])), colors.space.tint600);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    svg {\n      margin-left: 0.5rem;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    svg {\n      margin-left: 0.5rem;\n    }\n  }\n"])));
export var PopoverItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n\n  & + div {\n    margin-top: ", ";\n  }\n\n  &:hover {\n    color: ", ";\n  }\n\n  svg {\n    fill: ", ";\n  }\n"], ["\n  cursor: pointer;\n\n  & + div {\n    margin-top: ", ";\n  }\n\n  &:hover {\n    color: ", ";\n  }\n\n  svg {\n    fill: ", ";\n  }\n"])), rem(8), colors.base.primary, colors.space.tint600);
export var ModalContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0 ", ";\n"], ["\n  padding: 0 ", ";\n"])), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
