var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { fonts } from 'styles';
export var Subtitle = styled.h4(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"])), rem(14), fonts.weight.regular);
export var WarningAdvice = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  max-width: ", ";\n  margin: ", " auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  svg {\n    margin-bottom: ", ";\n  }\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  max-width: ", ";\n  margin: ", " auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  svg {\n    margin-bottom: ", ";\n  }\n"])), rem(14), fonts.weight.semibold, rem(240), rem(24), rem(8));
var templateObject_1, templateObject_2;
