import * as Yup from 'yup';
import messages from './messages';
var EditEmailFormSchema = function (formatMessage) {
    return Yup.object().shape({
        password: Yup.string()
            .required(formatMessage(messages.passwordRequiredMessage))
            .matches(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/, formatMessage(messages.passwordPolicy)),
        passwordConfirm: Yup.mixed()
            .required(formatMessage(messages.confirmPasswordRequiredMessage))
            .test('match', formatMessage(messages.passwordsNotMatching), 
        // eslint-disable-next-line func-names
        function (value) {
            // @ts-ignore
            return value === this.options.parent.password;
        }),
    });
};
export default EditEmailFormSchema;
