import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    optIn: versionV4 + "/newsletter/optin",
    confirm: versionV4 + "/newsletter/confirm",
};
export default {
    optIn: function (payload) {
        return axios.post(endpoints.optIn, payload);
    },
    confirm: function (token) {
        var config = {
            params: {
                token: token,
            },
        };
        return axios.get(endpoints.confirm, config);
    },
};
