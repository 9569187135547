export var EDIT_MY_PROFILE_REQUEST = 'EditMyProfile/EDIT_MY_PROFILE_REQUEST';
export var EDIT_MY_PROFILE_SUCCESS = 'EditMyProfile/EDIT_MY_PROFILE_SUCCESS';
export var EDIT_MY_PROFILE_ERROR = 'EditMyProfile/EDIT_MY_PROFILE_ERROR';
export var EDIT_MY_PROFILE_FIELDS = 'EditMyProfile/EDIT_MY_PROFILE_FIELDS';
export var EDIT_MY_PROFILE_FIELDS_SUCCESS = 'EditMyProfile/EDIT_MY_PROFILE_FIELDS_SUCCESS';
export var EDIT_MY_PROFILE_FIELDS_ERROR = 'EditMyProfile/EDIT_MY_PROFILE_FIELDS_ERROR';
export var EDIT_MY_PROFILE_PASSWORD = 'EditMyProfile/EDIT_MY_PROFILE_PASSWORD';
export var EDIT_MY_PROFILE_PASSWORD_SUCCESS = 'EditMyProfile/EDIT_MY_PROFILE_PASSWORD_SUCCESS';
export var EDIT_MY_PROFILE_PASSWORD_ERROR = 'EditMyProfile/EDIT_MY_PROFILE_PASSWORD_ERROR';
export var PHOTO_UPLOAD = 'EditMyProfile/PHOTO_UPLOAD';
export var PHOTO_UPLOAD_ERROR = 'EditMyProfile/PHOTO_UPLOAD_ERROR';
export var PHOTO_UPLOAD_SUCCESS = 'EditMyProfile/PHOTO_UPLOAD_SUCCESS';
export var PHOTO_DELETE = 'EditMyProfile/PHOTO_DELETE';
export var PHOTO_DELETE_ERROR = 'EditMyProfile/PHOTO_DELETE_ERROR';
export var PHOTO_DELETE_SUCCESS = 'EditMyProfile/PHOTO_DELETE_SUCCESS';
export var CLEAR_ERROR = 'EditMyProfile/CLEAR_ERROR';
export var CLEAR_SUCCESS = 'EditMyProfile/CLEAR_SUCCESS';
export var SET_ACTIVE_SECTION = 'EditMyProfile/SET_ACTIVE_SECTION';
export var SET_ACTIVE_AVATAR = 'EditMyProfile/SET_ACTIVE_AVATAR';
export var sections = {
    options: 'options',
    name: 'name',
    profileType: 'profileType',
    profileMessage: 'profileMessage',
    telephone: 'telephone',
    password: 'password',
    location: 'location',
    photo: 'photo',
};
