export default {
    components: {
        podium: {
            points: 'pts',
        },
        userReview: {
            datePattern: "MMMM d',' yyyy",
        },
        ellipsis: {
            more: 'plus',
            less: 'moins',
        },
        loadingIndicator: {
            loading: 'Chargement...',
        },
        nav: {
            explore: 'Explorer',
            challenge: 'Challenges',
            donation: 'Faire un don',
            aboutUs: 'À propos de Jaccede',
            myProfile: 'Mon profil',
            changeFontSize: 'Modifier la taille de la police',
            darkMode: 'Modifier le contraste',
            signIn: 'Connexion',
            logout: 'Déconnexion',
            mailSystem: 'Messagerie',
            homePage: 'Accueil',
        },
        facebookButton: {
            continueWithFacebook: 'Connexion avec Facebook',
        },
        usersProfilesField: {
            label: 'Type de profil',
            select: 'Sélectionner une option',
            placeholder: 'Sélectionner un type de profil',
        },
        passwordField: {
            label: 'Mot de passe',
            placeholder: 'Insérer votre mot de passe',
            showPassword: 'Afficher le mot de passe',
            hidePassword: 'Cacher le mot de passe',
            forgotPassword: 'Mot de passe oublié?',
        },
        emailField: {
            label: 'Email',
            placeholder: 'Insérer votre email',
        },
        searchField: {
            button: 'Rechercher',
            placeholder: 'Ville, pays',
        },
        profileMenu: {
            profile: 'Mon profil',
            mailSystem: 'Système de messageri',
            logout: 'Déconnexion',
        },
        passwordScore: {
            tips: '{tipsHighlight} Le mot de passe doit répondre aux critères suivants : ',
            tipsHighlight: 'Astuce : ',
            tipOne: '1 - minimum une lettre majuscule',
            tipTwo: '2 - minimum un chiffre',
            tipThree: '3 - minimum 8 caractères',
            veryWeak: 'Très faible',
            weak: 'Faible',
            fair: 'Moyen',
            strong: 'Fort',
        },
        placesAutocomplete: {
            googlePlaceIdLabel: 'Mon adresse',
            googlePlaceIdPlaceholder: 'Adresse',
        },
        aboutJaccede: {
            container: {
                definition: {
                    headline: 'Jaccedeur, Jaccedeuse [ʒaksɛdœʁ/øz]',
                    description: "Nom, âge et genre sans importance C´est un utilisateur ou contributeur de Jaccede d'espirit positif, qui travaille main dans la main avec ses homologues à travers le monde pour compléter et mettre à jour le guide de Jaccede, afin de diffuser à tous l'information et de sensibiliser sur l'importance de l'accessibilité.",
                },
                headline: 'Jaccede \n c’est quoi',
            },
            headline: 'Jaccede, c’est quoi ?',
            subtitle: 'En fonction de ma mobilité, à quels lieux puis-je accéder ?',
            firstParagraph: "\n        Jaccede, c\u2019est une plateforme collaborative (site Internet et application mobile) o\u00F9 chacun peut\n        d\u00E9tailler l\u2019accessibilit\u00E9 des lieux accueillant du public pour permettre aux personnes \u00E0 mobilit\u00E9\n        r\u00E9duite d\u2019identifier les \u00E9tablissements correspondant \u00E0 leurs besoins en mati\u00E8re d\u2019accessibilit\u00E9.\n      ",
            secondParagraph: "\n        L\u2019un des principaux freins des personnes \u00E0 mobilit\u00E9 r\u00E9duite\n        est le manque d\u2019informations sur l\u2019accessibilit\u00E9. Partager ces informations sur Jaccede\n        a donc un impact social majeur et imm\u00E9diat.\n      ",
            thirdParagraph: "\n        Jaccede permet aussi l\u2019organisation d\u2019\u00E9v\u00E9nements toute l\u2019ann\u00E9e, pour rassembler les Jaccedeurs,\n        sensibiliser le grand public et alimenter le guide. Des centaines\n        de rassemblements ont d\u00E9j\u00E0 eu lieu en France et \u00E0 l\u2019\u00E9tranger !\n      ",
        },
        emailNotConfirmed: {
            message: 'Veuillez confirmez votre adresse email',
        },
        subscribeButton: {
            subscribe: 'S’abonner',
            unsubscribe: 'Se désabonner',
        },
        subFooter: {
            searchByType: {
                title: 'Rechercher par type de lieux',
            },
            findPlacesInFrance: {
                title: 'Trouver des lieux en France',
            },
            findPlacesInEurope: {
                title: 'Trouver des lieux en Europe',
            },
            findPlacesInTheWorld: {
                title: 'Trouver des lieux dans le monde',
            },
        },
        bottomMenu: {
            languages: {
                english: 'English (UK)',
                french: 'Français',
                spanish: 'Español',
                italian: 'Italiano',
                german: 'Deutsche',
            },
            submenu: {
                fontSize: 'Taille de police',
                rightHand: 'Main droite',
                leftHand: 'Main gauche',
                defaultLanguage: 'Langue par défaut',
                darkMode: 'Haut contraste',
            },
            challenge: 'Challenge',
            explore: 'Explorer',
            donate: 'Faire un don',
            settings: 'Paramètres',
            menu: 'Menu',
        },
        footer: {
            copyRight: '2020 copyright. Jaccede. Tous droits réservés.',
            privacyTerms: 'Données personnelles · Conditions générales',
            sitemap: 'Plan du site',
        },
        addPlaceToMap: {
            button: 'Ajouter un lieu manquant',
            altImage: 'Homme avec une femme en fauteuil roulant',
        },
        notificationMenu: {
            title: 'Notifications',
            empty: 'Aucune notification',
        },
        keyboardMode: {
            tabButton: 'Voir le contenu',
            escButton: 'Appuyez sur ESCAPE pour fermer le mode clavier',
        },
        alertNewSite: {
            message: 'Bienvenue sur la version bêta de la nouvelle plateforme Jaccede ! N’hésitez-pas à nous envoyer vos',
            email: 'suggestions',
        },
    },
    containers: {
        app: {
            title: 'Jaccede',
            cookieConsentText: 'Jaccede utilise des cookies pour améliorer votre expérience sur le site tout en respectant vos informations personnelles.',
            cookieConsentButtonOk: 'J’accepte',
            cookieConsentButtonDecline: 'Plus tard',
        },
        signInEmail: {
            emailFormat: 'Format de l’email incorrect',
            emailRequired: 'L’email est requis',
            signInButton: 'Connexion',
            passwordRequired: 'Le mot de passe est requis',
            invalidSignin: 'Email ou mot de passe incorrect',
        },
        signUpEmail: {
            header: 'ou avec un email',
            emailLabel: 'Email',
            emailPlaceholder: 'Insérer votre email',
            emailRequired: 'L’email est requis',
            pseudoLabel: 'Pseudonyme',
            pseudoPlaceholder: 'Pseudonyme',
            pseudoRequired: 'Le pseudonyme est requis',
            acceptsNewsletterLabel: 'Je souhaite recevoir la newsletter mensuelle',
            termsAndCoditions: 'En créant un compte, vous acceptez les ',
            termsAndCoditionsLink: "conditions générales d'utilisation",
            registrationButton: 'Inscription',
            profileIdRequired: 'Le type du profil est requis',
            PlaceIdRequired: 'Mon adresse est requis',
            passwordRequired: 'Le mot de passe est requis',
            passwordStrengthRegex: 'Ce champ doit contenir: 1 majuscule, 1 chiffre et minimum 8 caractères',
            addressInfo: "Saisissez votre adresse. Elle va alors s'afficher dans le menu déroulant. Certaines adresses ne sont pas toujours reconnues. Dans ce cas, saisissez une adresse la plus proche de chez vous.",
        },
        home: {
            lastPlacesUpdates: {
                title: 'Les derniers lieux mis à jour',
            },
            signUpNewsletter: {
                title: 'S’abonner à notre newsletter',
                submitButton: 'S’abonner',
                emailRequired: "L'email est requis",
                optinSuccess: 'Votre email a bien été enregistré',
                optinFailure: 'Désolé une erreur s’est produite lors de l’inscription à notre newsletter. Merci d’essayer plus tard.',
            },
            socialNetworks: 'Suivez-nous:',
        },
        contribute: {
            title: "\n        Contribuer \u00E0\n        Jaccede!\n      ",
            collaborateParagraph: "\n        Devenir membre est la premi\u00E8re fa\u00E7on de montrer votre support \u00E0\n        Jaccede. Votre adh\u00E9sion aide \u00E0 promouvoir la cause des personnes \u00E0\n        mobilit\u00E9 r\u00E9duite et c\u2019est aussi apporter son soutien moral et financier \u00E0 l\u2019association.\n      ",
            collaborateAlt: 'Personne en fauteuil roulant',
            collaborateImage: 'Participez à l’aventure !',
            collaborateTitle: 'Adhérer à l’association',
            collaborateButtonText: 'Adhérez !',
            donateAlt: 'Personne en fauteuil roulant',
            donateImage: 'Aidez-nous à réussir !',
            donateTitle: 'Faire un don',
            donateButtonText: 'Faites un don !',
            donateParagraph: "\n        Votre int\u00E9r\u00EAt \u00E0 contribuer \u00E0 l\u2019am\u00E9lioration de la vie des personnes \u00E0 mobilit\u00E9\n        r\u00E9duite vous a conduit \u00E0 Jaccede. Aidez-nous \u00E0 atteindre cet objectif et \u00E0\n        prendre des mesures pour sensibiliser le grand public aux\n        avantages de l\u2019accessibilit\u00E9.\n      ",
        },
        homeHeader: {
            title: "\n        Trouvez les lieux qui\n        vous sont accessibles\n      ",
            placeholder: 'Ville, pays',
            seeMore: 'Voir plus',
        },
        homeSearch: {
            button: 'Rechercher',
            placeholder: 'Chercher un établissement ou une adresse',
        },
        homeLearnMore: {
            title: "\n        Jaccede, mode\n        d\u2019emploi: comment\n        acc\u00E9der \u00E0 l\u2019information!\n      ",
            desktopSubtitle: 'Retrouvez quelle est l’information la plus utile pour vous.',
            mobileSubtitle: 'Jaccede est une plateforme interactive où chacun peut ajouter des détails sur les lieux ouverts au public.',
            videoAlt: 'Vignette de la vidéo à propos de nous',
            blockquoteTitle: "Jaccede, c'est quoi ?",
            blockquoteParagraph: 'Retrouvez quelle est l’information la plus utile pour vous.',
        },
        homeBrowsePlaces: {
            title: "\n        Parcourir les lieux\n        par cat\u00E9gorie\n      ",
            subtitle: "Vous recherchez un type de lieu particulier ? Les lieux publics et leurs détails d'accessibilité peuvent être consultés par catégorie avec Jaccede.",
            button: 'Explorer les lieux',
            dogAndOwnerAlt: 'Chien d’aveugle avec son maître dans un parc',
            wheelchairHandAlt: 'Personne en fauteuil roulant',
            guideDogsAlt: 'Chiens guides en laisse',
            peopleSmilingAlt: 'Des gens qui bavardent',
        },
        homeMostActive: {
            title: "\n        Les contributeurs\n        les plus actifs de\n        la semaine\n      ",
            viewAllRank: 'Voir tous les classements',
        },
        homeLatestNews: {
            title: "\n        Actualit\u00E9s de\n        Jaccede\n      ",
            subtitle: 'Suivez les dernières actualités de la communauté et de l’association Jaccede en parcourant notre blog !',
            buttonText: 'Voir le blog',
            note: 'uniquement en français',
        },
        oAuth: {
            errorPageTitle: "Une erreur s'est produite",
            completeAccountTitle: 'Compléter votre compte',
            successNotification: 'Vous êtes connecté avec succès !',
        },
        resendEmailActivation: {
            header: 'Renvoyer l’email d’activation',
            submitButton: 'Renvoyer',
            emailRequired: 'L’email est requis',
            emailformat: 'Format de l’email incorrect',
        },
        profile: {
            achievements: {
                header: 'Succès',
            },
            user: {
                editMyProfile: {
                    mobile: 'Modifier',
                    desktop: 'Modifier mon profil',
                },
                pointsInfo: {
                    criterion: '1 critère édité une fois = 1 point',
                    photo: '1 photo ajoutée par lieu = 4 points',
                    comment: '1 commentaire par lieu = 2 points',
                    place: '1 lieu manquant ajouté = 1 point',
                },
                subscriptions: 'Abonnements',
                subscribers: 'Abonnés',
                points: 'Points',
                sendMessage: 'Envoyer un message',
                readMore: 'plus',
                medal200: 'Badge 200 points',
                medal1000: 'Badge 1000 points',
                medal5000: 'Badge 5000 points',
                medal10000: 'Badge 10000 points',
                medal25000: 'Badge 25000 points',
                medalCriterion: 'Badge de critères',
                medalPhoto: 'Badge de photos',
                medalComment: 'Badge de commentaires',
                medalPlace: 'Badge de lieux manquants',
                chatModalText: 'La messagerie sera bientôt disponible !',
            },
            activities: {
                header: 'Activité des dernières semaines',
            },
            myActiveChallenges: {
                header: 'Mon challenge actif',
                challengesEmptyList: "L'utilisateur ne participe à aucun challenge pour le moment",
            },
            moreAbout: {
                header: 'Jaccede, mode d’emploi : comment accéder à l’information !',
                contributions: 'Contributions',
                favorites: 'Favoris',
                contributionEmptyList: 'Aucune contribution pour le moment',
                favoriteEmptyList: 'Pas de favoris pour le moment',
            },
            nextAchievements: {
                medals: {
                    medal200: {
                        title: '200 points badges',
                        description: 'Pour gagner ce badge, vous devez gagner 200 points',
                    },
                    medal1000: {
                        title: '1000 points badges',
                        description: 'Pour gagner ce badge, vous devez accumuler 1000 points',
                    },
                    medal5000: {
                        title: '5000 points badges',
                        description: 'Pour gagner ce badge, vous devez accumuler 5000 points',
                    },
                    medal10000: {
                        title: '10000 points badges',
                        description: 'Pour gagner ce badge, vous devez accumuler 10000 points',
                    },
                    medal25000: {
                        title: '25000 points badges',
                        description: 'Pour gagner ce badge, vous devez accumuler 25000 points',
                    },
                    medalCriterion: {
                        title: 'Badge de critères',
                        description: "Pour gagner ce badge, vous devez informer 5000 critères d'accessibilité de lieux",
                    },
                    medalPhoto: {
                        title: 'Badge de photos',
                        description: 'Pour gagner ce badge, vous devez ajouter 1000 photos de lieux',
                    },
                    medalComment: {
                        title: 'Badge de commentaires',
                        description: 'Pour gagner ce badge, vous devez faire 1000 commentaires sur les lieux',
                    },
                    medalPlace: {
                        title: 'Badge de lieux manquants',
                        description: "Pour gagner ce badge, il faut ajouter 500 places qui n'existaient pas sur le site de Jaccede",
                    },
                },
                header: 'Prochain succès',
            },
        },
        forgotPasswordPage: {
            emailLabel: 'Email',
            submitButton: 'Demander le lien pour réinitialiser votre mot de passe',
            notReset: 'Si vous ne parvenez pas à réinitaliser votre mot de passe,',
            contatUs: 'Contactez-nous.',
            emailFormat: 'Format de l’email incorrect',
            emailRequired: 'L’email est requis',
            verifyEmail: 'Les instructions pour réinitialiser votre mot de passe vous ont été envoyées.',
            success: 'Terminé !!',
            error: 'Erreur !',
            passwordIsExpired: 'Le lien a expiré. Veuillez faire une nouvelle demande.',
        },
        changePasswordPage: {
            newPasswordLabel: 'Nouveau mot de passe',
            newPasswordRequired: 'Nouveau mot de passe requis',
            confirmPasswordRequired: 'La confirmation d’un nouveau mot de passe est requise',
            newPasswordStrengthRegex: 'Ce champ doit contenir: 1 majuscule, 1 chiffre et minimum 8 caractères',
            confirmPasswordValidation: 'Les deux nouveaux mots de passe doivent correspondre',
            confirmPasswordLabel: 'Confirmez le nouveau mot de passe',
            submitButton: 'Modifier',
            success: 'Succès !!',
            successfully: 'Le mot de passe a été modifié avec succès !',
            error: 'Erreur !',
            errorMessage: 'Désolé quelque chose a mal tourné',
            checkingPasswordIsLoading: 'Nous vérifions la validité du token',
            linkForgotPassword: 'Cliquez ici',
        },
        subscribers: {
            subscriptions: 'Abonnements',
            subscribers: 'Abonnés',
            loggedUserNoSubscribers: 'Vous n’avez pas d’abonnés !',
            loggedUserNoSubscriptions: 'Vous n’avez pas d’abonnements ! ',
            noSubscribers: 'Aucun abonné trouvé !',
            noSubscriptions: 'Aucun abonnement trouvé',
        },
        globalScoreboard: {
            tabWeek: {
                title: 'Hebdomadaire',
                footerTitleWithPoints: 'Voici vos points de la semaine',
                footerTitleWithoutPoints: 'Vous n’avez pas obtenu de points cette semaine',
            },
            tabMonth: {
                title: 'Mensuel',
                footerTitleWithPoints: 'Voici vos points du mois',
                footerTitleWithoutPoints: 'Vous n’avez pas obtenu de points ce mois',
            },
            tabAll: {
                title: 'Total',
                footerTitleWithPoints: 'Voici vos points',
                footerTitleWithoutPoints: 'Vous n’avez pas obtenu de points',
            },
            headerTitle: 'Classement general',
            emptyList: "Nous n'avons pas de scores pour le mois en cours, que pensez-vous de changer cela ? Commencez à rendre le monde plus accessible dès maintenant !",
        },
        editMyProfile: {
            identification: {
                fieldDescription: 'Identification',
                fieldInformation: 'Informations clés sur votre compte',
            },
            location: {
                fieldDescription: 'Localisation',
                fieldInformation: 'Je précise ma localisation pour bénéficier d’informations plus pertinentes',
            },
            message: {
                fieldDescription: 'Message',
                fieldInformation: 'En quelques mots, un message pour tous les Jaccedeurs !',
            },
            editOptions: {
                identification: 'Identification',
                identificationMessage: 'Modifiez votre profil public afin que les gens puissent en savoir un peu plus sur vous',
                name: 'NOM & PSEUDONYME',
                editPhoto: 'Modifier photo',
                editPhotoMessage: 'Modifiez votre photo qui sera utilisée dans la communauté de Jaccede',
                profileType: 'TYPE DE PROFIL',
                profileMessage: 'MESSAGE DU PROFIL',
                contactInformation: 'Coordonnées',
                email: 'EMAIL',
                telephone: 'TÉLÉPHONE',
                password: 'MOT DE PASSE',
                location: 'Ville',
                myAddress: 'MON ADRESSE',
                editPhotoOverlay: 'Modifier',
            },
            editPhoto: {
                uploadError: 'Votre photo n’a pas pu être téléchargée',
                modalTitle: 'Modifier ma photo',
                resolutionRule: 'Résolution minimale de ',
                sizeRule: 'Taille inférieure ou égale à ',
                extensionsRule: 'Extensions autorisées : ',
            },
            editName: {
                name: 'Prénom',
                title: 'Nom & Pseudonyme',
                lastName: 'Nom',
                username: 'Pseudonyme',
                usernameRequiredMessage: 'Le pseudonyme est requis',
                updateName: 'Modifier nom & pseudonyme',
            },
            editEmail: {
                title: 'Email',
                email: 'Email',
                emailRequiredMessage: 'L’email est requis',
                invalidEmailMessage: 'Format de l’email incorrect',
                updateEmail: 'Modifier email',
            },
            editTelephone: {
                title: 'Téléphone',
                telephone: 'Téléphone',
                updateTelephone: 'Modifier téléphone',
            },
            editProfileMessage: {
                title: 'Message du profil',
                profileMessage: 'Message du profil',
                maxSizeProfileMessage: 'Maximum de 500 caractères',
                updateProfileMessage: 'Modifier message du profil',
            },
            editProfileType: {
                title: 'Type de profil',
                profileTypeRequiredMessage: 'Le type de profil est requis',
                updateProfileType: 'Modifier type de profil',
            },
            editPassword: {
                title: 'Mot de passe',
                password: 'Nouveau mot de passe',
                confirmPassword: 'Confirmer nouveau mot de passe',
                passwordRequiredMessage: 'Nouveau mot de passe est requis',
                confirmPasswordRequiredMessage: 'La confirmation d’un nouveau mot de passe est requise',
                passwordsNotMatching: 'Les deux nouveaux mots de passe doivent correspondre',
                changePassword: 'Modifier mot de passe',
                passwordPolicy: 'Ce champ doit contenir: 1 majuscule, 1 chiffre et minimum 8 caractères',
            },
            editLocation: {
                title: 'Mon adresse',
                myAddress: 'Mon adresse',
                acceptVisibility: 'J’accepte d’être visible par les autres Jaccedeurs',
                updateMyAddress: 'Modifier mon adresse',
                shareLocalization: 'J’accepte d’être visible par les autres Jaccedeurs',
                invalidAddress: 'Adresse invalide',
            },
            acceptTerms: {
                contact: 'J’accepte d’être contacté par les autres Jaccedeurs',
                newsletter: 'Je souhaite recevoir la newsletter mensuelle',
                infoNewsletter: 'Votre email est uniquement utilisé pour vous envoyer notre newsletter mensuelle ainsi que des informations concernant les activités de Jaccede. Vous pouvez à tout moment utiliser le lien de désabonnement intégré dans la newsletter.',
            },
            pageTitle: 'Modifier mon profil',
            passwordUpdateSuccess: 'Le mot de passe a été modifié avec succès !',
            photoUploadSuccess: 'Photo téléchargée avec succès !',
            defaultAvatarUpdatedSuccess: 'Photo téléchargée avec succès !',
            photoDeleteSuccess: 'Photo supprimée avec succès !',
            nameUpdateSuccess: 'Nom et prénom ont été modifiés avec succès !',
            profileMessageUpdateSuccess: 'Message du profil a été modifié avec succès !',
            profileTypeUpdateSuccess: 'Votre type de profil a été modifié avec succès !',
            telephoneUpdateSuccess: 'Numéro de téléphone a été modifié avec succès !',
            locationUpdateSuccess: 'Votre adresse a été modifié avec succès !',
            termsUpdateSuccess: 'Les termes ont été modifiés avec succès !',
            success: 'Succès',
            error: 'Erreur',
        },
        placeDetails: {
            report: {
                title: 'Signaler une photo comme spam',
                label: 'Ajouter un commentaire',
                placeholder: 'Commenter',
                button: 'Continuer',
                required: 'Le message est requis.',
                reportPhotoButtonTitle: 'Signaler photo',
            },
            reported: {
                title: 'Photo signalée',
                message: 'Cette photo a été signalée comme étant inappropriée.\n Merci. Nous tiendrons compte de vos commentaires dans les plus brefs délais. \nMerci de votre vigilance.',
                button: 'Fermer',
            },
            reportPhoto: {
                title: 'Signaler comme photo de spam.',
                label: 'Ajouter un commentaire',
                placeholder: 'Faire un commentaire',
                button: 'Continuer',
                required: 'Le message est requis.',
            },
            reportedPhoto: {
                title: 'Photo signalée',
                alreadyReported: 'Cette photo a été déjà signalée',
                message: 'Cette photo a été signalée comme étant inappropriée.\n Merci. Nous tiendrons compte de vos commentaires dans les plus brefs délais. \nMerci de votre vigilance.',
                button: 'Fermer',
            },
            suggestChanges: 'Modifier les informations',
            saveChangesButton: 'Enregistrer les modifications',
            evaluateTitle: 'Évaluer et noter',
            evaluateTagline: 'Gagnez des points en notant les lieux',
            addReviewButton: 'Ajouter un commentaire',
            ratings: 'Score',
            addPhotos: 'Ajouter des photos',
            addMorePhotos: 'Ajouter plus de photos',
            reviews: 'Avis',
            moreReviews: "Plus d'avis",
            yourReviews: 'Votre commentaire',
            lastChange: 'Dernière modification par',
            lastChange2: 'le',
            searchPlacesAround: 'Chercher des lieux à proximité',
            reportProblemTitle: 'Signaler ce profil de lieu',
            reportedProblemTitle: 'Le lieu a été signalé.',
            reportedProblemMessage: 'Merci. Nous tiendrons compte de vos commentaires dans les plus brefs délais.',
            reportedProblemEndMessage: 'Merci de votre vigilance.',
            reportedButton: 'Signaler',
            reportReviewTitle: 'Signaler un commentaire comme spam',
            reportedReviewTitle: 'Le commentaire a été signalé.',
            error: 'Erreur',
            success: 'Succes !!',
            closed: 'Définitivement fermé',
            suggestModification: 'Suggérer une modification sur Google maps',
        },
        placePhotos: {
            report: {
                title: 'Signaler comme photo de spam.',
                label: 'Ajouter un commentaire',
                placeholder: 'Faire un commentaire',
                button: 'Continuer',
                required: 'Le message est requis.',
            },
            reported: {
                title: 'Photo signalée',
                alreadyReported: 'Cette photo a été déjà signalée',
                message: 'Cette photo a été signalée comme étant inappropriée.\n Merci. Nous tiendrons compte de vos commentaires dans les plus brefs délais. \nMerci de votre vigilance.',
                button: 'Fermer',
            },
            header: 'Photos de ce lieu',
            addPhoto: 'Ajouter des photos',
            successTitle: 'Succès',
            photoSuccess: 'Photo ajoutée avec succès !',
            validationTitle: 'Fichier incorrect',
            validationDescription: 'Votre photo n’a pas pu être téléchargée. Le fichier doit avoir une résolution minimale de 1080x1080 pixels. Le fichier doit être inférieur ou égal à 20 Mo. Les extensions autorisées sont : PNG, GIF, JPEG, JPG.',
        },
        explorePlaces: {
            inputPlaceholder: 'Ville, Pays',
        },
        filterPlaces: {
            desktopBar: {
                buttons: {
                    apply: 'Appliquer les filtres sauvegardés',
                    category: 'Sélectionner une catégorie',
                    terms: "Mes filtres d'accessibilité",
                    spaces: 'Lieux non détaillés',
                    save: 'Sauvegarder',
                },
                spaces: {
                    title: 'Afficher les lieux non détaillés',
                    tagline: 'Vestibulum ornare tincidunt orci, non consequat nisl gravida vel.Nulla ut varius ante.',
                },
            },
            termsSelector: {
                title: 'Accessibilités',
                inputPlaceholder: 'Commencez à taper ici pour ajouter des termes',
                showPlacesSwitch: 'Afficher les lieux avec tous les termes sélectionnés',
            },
            category: {
                placeholder: 'écrivez ici',
                suggestedCategoriesLabel: 'Catégories suggérées',
                selectedCategoryLabel: 'Sélectionnez une catégorie',
            },
            categorySelector: {
                label: 'Catégories',
            },
            filterBar: {
                editButton: 'Modifier filtres',
                more: 'Plus',
            },
            footer: {
                clear: 'Effacer les filtres',
                applyFilters: 'Appliquer les filtres',
            },
            header: {
                title: 'Filtres',
            },
            placeWithoutDetailsSwitch: {
                title: 'Lieux non détaillés',
            },
        },
        reviews: {
            headerTitle: 'Tous les avis',
            emptyReviews: 'Cet endroit n’a pas encore de commentaires',
            addReviewConflict: 'Ce commentaire existe déjà',
        },
        editPlace: {
            mainPageTitle: 'Suggérer des changements',
            category: 'Catégorie',
            categoryOfPlace: 'Catégorie de lieu',
            entrance: 'Entrée',
            access: 'Accès',
            doorOpeningSystem: 'Ouverture de porte',
            ninetyCmAndAbove: '90 cm et plus',
            indoors: 'Intérieur',
            servicesAndFacilities: 'Services et équipements',
            roomsTile: 'Salle(s)',
            rooms: 'Salle',
            outside: 'Extérieur',
            disabledParkingSpaces: 'Place de stationnement "handicapé"',
            pavements: 'Trottoirs',
            selectACategory: 'Sélectionnez une catégorie',
            categoryRequired: 'La catégorie est requise.',
            successfulFeedback: 'Modifié avec succès !',
            pointsFeedback: 'Vous avez gagné {points} points.',
            accessibilityTitle1: 'Accessibilité détaillée',
            accessibilityTitle2: 'par',
            accessibilityTitle2Singular: 'personne',
            accessibilityTitle2Plural: 'personnes',
            noAccessibility: 'Ce lieu ne contient pas encore d’information sur son accessibilité.',
            success: 'Succès !',
            photoAdded: 'Photo ajoutée.',
            failure: 'Échec',
            addMorePhotos: 'Ajouter plus de photos',
        },
        challenges: {
            secretChallenge: {
                text: 'Entrez votre code secret !',
                description: 'Pour déverrouiller un challenge privé, vous devez saisir le code secret qui vous a été transmis.',
                button: 'Accédez au challenge',
                required: 'Le code est requis',
                validation: 'Oups ! :( Il semble que votre code soit invalide, veuillez le vérifier à nouveau',
                max: 'Le code doit comporter 6 caractères',
            },
            header: {
                hashtag: '#JACCEDECHALLENGE2020',
                description: 'Challenge Jaccede 2020. ',
            },
            publicChallengeTitle: 'Challenges publics',
        },
        challengesDetail: {
            selectTeam: 'Sélectionnez une équipe ci-dessous pour participer',
            newTeamSection: 'Créer et rejoindre une nouvelle équipe',
            createNewTeamButton: 'Créer une nouvelle équipe',
            challengeStartsOn: 'Le challenge commence le',
            challengeEndsOn: 'se termine le',
            membersScoreBoard: 'Classement des membres',
            teamScoreboard: 'Classement des équipes',
            emptyTeams: 'Nous n’avons pas encore d’équipes',
        },
        createNewTeam: {
            label: 'Choisir le nom de votre équipe : ',
            placeholder: 'Tapez le nom de votre équipe',
            cancelButton: 'Annuler',
            button: 'Non, continuer',
            nameIsRequired: 'Le nom est requis',
        },
        challengesMembersTeam: {
            joinTeamSection: {
                title: 'Je veux faire partie de cette équipe',
                buttonText: 'Rejoindre cette équipe',
            },
            placesAroundMeSection: {
                title: 'Explore places',
            },
            memberHeader: {
                inviteButtonText: 'Inviter un nouveau membre',
            },
            title: 'Membres de l’équipe',
        },
        challengeInviteUserTeam: {
            label: 'Email : ',
            placeholder: 'Email',
            cancelButton: 'Annuler',
            button: 'Non, continuer',
            emailIsRequired: 'L’email est requis',
            emailFormat: 'Format de l’email incorrect',
            inviteSuccess: 'Un email a été envoyé',
        },
    },
    pages: {
        globalScoreboard: {
            title: 'Classement general',
            description: 'Classement general page de description de helmet',
            showByPeriod: 'Afficher par période',
        },
        explorePlacesPage: {
            newPlaceModal: {
                addressTitle: 'Saisissez l’adresse du lieu',
                newPlaceTitle: 'Ajouter un nouveau lieu',
                suggestionTitle: 'Vous recherchez une de ces adresses ?',
                nameLabel: 'Nom de l’établissement',
                nameValidation: 'Le nom est requis',
                addressLabel: 'Adresse',
                addressValidation: "L'adresse est requise",
                categoryLabel: 'Catégorie',
                categoryPlaceholder: 'Sélectionner',
                categoryValidation: 'La catégorie est requise',
                telephoneLabel: 'Téléphone',
                telephoneValidation: 'Le numéro de téléphone est requis',
                websiteLabel: 'Site web',
                websiteValidation: 'Le site web est requis',
                continueButton: 'Continuer',
                validateButton: 'Valider',
                suggestionButton: 'Non, continuer',
                validateWebsite: 'Entrez un lien valide ou laissez le champ vide.',
            },
            addPlaceNotFound: {
                title: 'Vous n’avez pas trouvé le lieu que vous cherchiez ?',
                tagline: 'Ajoutez l’adresse à la carte, gagnez des points et contribuez à rendre le monde plus accessible !',
            },
            addPlaceNoResults: {
                title: 'Oups ! Nous n’avons pas trouvé le lieu que vous cherchiez.',
                tagline: 'Si vous connaissez un lieu accessible qui n’est pas sur notre application, ajoutez-le maintenant et aidez les gens dans le monde entier !',
            },
            chooseLocation: {
                text: 'Choisir un lieu sur la carte',
                button: 'Choisir sur la carte',
            },
            collaboration: {
                message: 'Pourquoi ne pas collaborer vous aussi et aider le monde à être accessible à tous !',
                highlight: 'Nous avons des collaborations partout dans le monde !',
            },
            title: 'Explorer les lieux',
            description: 'Explorer les lieux description pour helmet',
            sidebarTitle: 'Accessibilité des lieux dans et autour de la région',
            recentContributors: 'Contributeurs récent(e)s',
        },
        filterPlacesPage: {
            title: 'Filtrer les lieux',
            description: 'Filtrer les lieux description pour helmet',
        },
        reviewsPage: {
            title: 'Tous les commentaires',
            description: 'Tous les commentaires description pour helmet',
        },
        notFoundPage: {
            title: 'Page non trouvée',
            description: 'Page non trouvée description pour helmet',
        },
        homePage: {
            title: 'Accueil',
            description: 'Page d’accueil description pour helmet',
        },
        signInPage: {
            title: 'Connexion',
            description: 'Connexion et création de compte',
            byEmail: 'ou avec un email',
            dontHaveAccount: 'Vous n’avez pas de compte ?',
            signUpButton: 'Créer un compte',
        },
        forgotPasswordPage: {
            title: 'Réinitialiser le mot de passe',
            description: 'Réinitialiser le mot de passe description pour helmet',
        },
        changePasswordPage: {
            title: 'Modifier mon mot de passe',
            description: 'Modifier mon mot de passe description pour helmet',
        },
        profilePage: {
            title: 'Profil',
            description: 'Mon profil',
        },
        signUpPage: {
            title: 'Créer un compte',
            description: 'Enregistrer un nouveau compte',
            header: 'Créer un compte',
            continueWithFacebook: 'Connexion avec Facebook',
            alreadyAccount: 'Vous avez déjà un compte ?',
            signIn: 'Connexion',
        },
        confirmAccountPage: {
            title: 'Confirmer votre compte',
            success: 'Parfait !',
            content: 'Nous vous avons envoyé des instructions sur la procédure à suivre pour terminer la création de votre compte',
        },
        aboutPage: {
            title: 'À propos',
            description: 'À propos de l’organisation Jaccede, notre objectif est d’impliquer les gens d’un point de vu local',
            listGroup: {
                inBrief: {
                    header: 'En bref',
                    item1: 'Jaccede, c’est quoi ?',
                    item2: 'Jaccede, c’est pour qui ?',
                    item3: 'Por la petite histoire',
                    item4: 'Prix et récompenses',
                },
                organization: {
                    header: "L'association",
                    item1: 'Notre vision et nos missions',
                    item2: 'Notre conseil d’administration',
                    item3: 'Notre équipe permanente',
                    item4: 'Nos bénévoles',
                    item5: 'Adhérer à l’association',
                    item6: 'Faire un don',
                },
                getInvolved: {
                    header: 'Comment contribuer ?',
                    item1: 'En ligne',
                    item2: 'Sur le terrain',
                    item3: 'En apportant vos compétences',
                },
                partners: {
                    header: 'Partenaires',
                    item1: 'Nos partenaires',
                    item2: 'Impliquez votre entreprise',
                    item3: 'Mobilisez votre association ou école',
                    item4: 'Participez en tant que collectivité',
                    item5: 'Référencez votre établissement',
                },
            },
        },
        resendEmailActivationPage: {
            title: 'Renvoyer l’email d’activation',
            description: 'Renvoyer l’email d’activation',
        },
        subscribersProfilePage: {
            title: 'Abonnements & Abonnés',
            description: 'Suivre les utilisateurs',
        },
        editProfilePage: {
            title: 'Modifier profil',
            description: 'Modifier votre profil',
        },
        editFieldProfilePage: {
            title: 'Modifier profil',
            description: 'Modifier votre profil',
        },
        userPage: {
            title: 'Utilisateur',
            description: 'Contributeur',
        },
        accountActivatedPage: {
            title: 'Compte activé',
            description: 'Compte activé',
            paragraph: 'Votre compte est activé',
        },
        newsletterPage: {
            title: 'Newsletter',
            description: 'Page de la newsletter',
        },
        editPlacePage: {
            title: 'Accessibilité détaillée',
            description: 'Description de l’accessibilité détaillée',
        },
        placeDetailsPage: {
            title: 'Détails du lieu',
            description: 'Détails du lieu',
        },
        newReviewPage: {
            title: 'Ajouter un commentaire',
            description: 'Ajouter un commentaire',
            postReviewButton: 'Publier',
            commentRequiredError: 'Un commentaire est requis',
            commentSizeError: 'Votre commentaire doit comporter entre 15 et 2000 caractères.',
            commentSuccess: 'Merci, votre commentaire a bien été pris en compte',
        },
        placePhotosPage: {
            title: 'Photos du lieu',
            description: 'Photos du lieu',
        },
        reviewDetailPage: {
            title: 'Avis',
            description: 'Détails du commentaire',
            notFound: 'Commentaire non trouvé',
            defaultError: "Une erreur s'est produite en trouvant ce commentaire",
        },
        categoryListViewPage: {
            title: 'Nom de la Région',
            description: 'This is the CategoryListViewPage page',
        },
        categoryMapViewPage: {
            title: 'Nom de la Région',
            description: 'This is the CategoryMapViewPage page',
            seeInListButton: 'Voir la liste',
        },
        challengePage: {
            title: 'Page du challenge',
            description: 'Page du challenge description pour helmet',
        },
        challengeDetailPage: {
            title: 'Détails du challenge',
            description: 'Détails du challenge description pour helmet',
        },
        challengeJoinTeamPage: {
            warning: 'Après confirmation, vous ne pourrez plus changer d’équipe',
            buttonText: 'Rejoindre cette équipe',
        },
        challengesMembersTeam: {
            title: "Membres de l'équipe",
            description: 'Membres de l’équipe description pour helmet',
        },
        challengeInviteUserTeamPage: {
            title: 'Inviter un nouveau participant',
            subtitle: 'Veuillez entrer l’adresse email d’un collègue ou d’un ami pour l’inviter à participer à ce challenge.',
        },
        challengeCreateNewTeamPage: {
            title: "L'inscription est irréversible",
            subtitle: 'Vous êtes sur le point de créer et de rejoindere une équipe pour participer à ce challenge',
            warningAdvice: 'Vous ne pourrez pas abandonner le challenge ni quitter votre équipe.',
        },
        mailSystemPage: {
            title: 'Systeme d’email',
            description: 'This is the Mail System page',
        },
    },
};
