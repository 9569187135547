import { CardPlaces, LoadingIndicator } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Section } from '../Section';
import { CardsContainer, LoadingWrapper, Title } from './styles';
export var LastPlacesUpdates = memo(function (_a) {
    var latestPlacesUpdates = _a.latestPlacesUpdates, isLoading = _a.isLoading;
    return (React.createElement(Section, { style: { paddingTop: rem(40), paddingBottom: 0 } },
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "containers.home.lastPlacesUpdates.title" })),
        isLoading ? (React.createElement(LoadingWrapper, null,
            React.createElement(LoadingIndicator, null))) : (React.createElement(CardsContainer, null, map(latestPlacesUpdates === null || latestPlacesUpdates === void 0 ? void 0 : latestPlacesUpdates.places, function (place) { return (React.createElement(Link, { key: place.id, to: paths.placeDetailsPage
                .replace(':placeId', place.googlePlaceId)
                .replace(':placeName', place.slug) },
            React.createElement(CardPlaces, { tabIndex: 0, key: place.id, place: place, imageHeight: rem(135), showCategoryIcon: true, placeStyle: {
                    paddingRight: 0,
                    paddingLeft: 0,
                } }))); })))));
});
