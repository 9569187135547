var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { breakpoints, colors } from 'styles';
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
var color = theme('mode', {
    light: colors.space.tint700,
    dark: colors.space.tint300,
});
export var FooterContainer = styled.footer(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: ", ";\n  max-width: ", ";\n  width: 100%;\n  padding: ", ";\n  margin: 0 auto;\n"], ["\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: ", ";\n  max-width: ", ";\n  width: 100%;\n  padding: ", ";\n  margin: 0 auto;\n"])), color, rem(14), breakpoints.large, rem(14));
export var CopyContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  p {\n    margin-right: ", ";\n\n    span {\n      margin-right: ", ";\n    }\n  }\n\n  a:last-of-type {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  p {\n    margin-right: ", ";\n\n    span {\n      margin-right: ", ";\n    }\n  }\n\n  a:last-of-type {\n    margin-right: 0;\n  }\n"])), rem(4), rem(2));
export var SocialNetworksContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  a:last-of-type {\n    margin-right: 0;\n  }\n\n  a {\n    width: ", ";\n    height: ", ";\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    > svg {\n      flex-shrink: 0;\n    }\n  }\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n\n  a:last-of-type {\n    margin-right: 0;\n  }\n\n  a {\n    width: ", ";\n    height: ", ";\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    > svg {\n      flex-shrink: 0;\n    }\n  }\n\n  ",
    "\n"])), rem(28), rem(28), ifProp('isKeyboardMode', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      a:focus {\n        position: relative;\n        outline: 0 !important;\n        outline-offset: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: 100%;\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          top: ", ";\n          left: ", ";\n        }\n      }\n    "], ["\n      a:focus {\n        position: relative;\n        outline: 0 !important;\n        outline-offset: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: 100%;\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          top: ", ";\n          left: ", ";\n        }\n      }\n    "])), border, rem(-1), rem(10), rem(-4), rem(-4))));
export var ExternalLink = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: inherit;\n  cursor: pointer;\n  display: flex;\n  text-decoration: none;\n  margin: 0 ", ";\n"], ["\n  color: inherit;\n  cursor: pointer;\n  display: flex;\n  text-decoration: none;\n  margin: 0 ", ";\n"])), rem(6));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
