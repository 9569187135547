import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import ConnectResendEmailActivation from './Resend';
export var ResendEmailActivation = function () {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement("div", null,
        React.createElement("span", null, formatMessage(messages.header)),
        React.createElement(ConnectResendEmailActivation, null)));
};
export default memo(ResendEmailActivation);
