import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, SubscriptionButton, SubscriptionSize, SubscriptionText, } from './styles';
var SubscriptionButtons = function (_a) {
    var subscriptionsSize = _a.subscriptionsSize, subscribersSize = _a.subscribersSize, subscriptionsLink = _a.subscriptionsLink, subscribersLink = _a.subscribersLink;
    var history = useHistory();
    var location = useLocation();
    function handleSubscriptions() {
        history.push(subscriptionsLink, { from: location.pathname });
    }
    function handleSubscribers() {
        history.push(subscribersLink, { from: location.pathname });
    }
    return (React.createElement(Container, null,
        React.createElement(SubscriptionButton, { onClick: handleSubscriptions },
            React.createElement(SubscriptionSize, null, subscriptionsSize),
            React.createElement(SubscriptionText, null,
                React.createElement(FormattedMessage, { id: "containers.profile.user.subscriptions" }))),
        React.createElement(SubscriptionButton, { onClick: handleSubscribers },
            React.createElement(SubscriptionSize, null, subscribersSize),
            React.createElement(SubscriptionText, null,
                React.createElement(FormattedMessage, { id: "containers.profile.user.subscribers" })))));
};
export default SubscriptionButtons;
