import { CLEAR_NOTIFICATION, CLEAR_REPORTED, DELETE_PHOTO, DELETE_PHOTO_FAILURE, DELETE_PHOTO_SUCCESS, GET_PHOTOS_ACTION_FAILURE, GET_PHOTOS_ACTION_REQUEST, GET_PHOTOS_ACTION_SUCCESS, POST_PHOTOS_ACTION_CLEAR_FAILURE, POST_PHOTOS_ACTION_CLEAR_SUCCESS, POST_PHOTOS_ACTION_REQUEST, POST_PHOTOS_ACTION_SUCCESS, PUT_PHOTOS_ACTION_FAILURE, PUT_PHOTOS_ACTION_REQUEST, PUT_PHOTOS_ACTION_SUCCESS, REPORT_MEDIA_PLACE, REPORT_MEDIA_PLACE_FAILURE, REPORT_MEDIA_PLACE_SUCCESS, } from './constants';
export var getPhotos = function (payload) { return ({
    type: GET_PHOTOS_ACTION_REQUEST,
    payload: payload,
}); };
export var getPlacePhotosSuccess = function (payload) { return ({
    type: GET_PHOTOS_ACTION_SUCCESS,
    payload: payload,
}); };
export var getPlacePhotosFailure = function () { return ({
    type: GET_PHOTOS_ACTION_FAILURE,
}); };
export var postPhotos = function (placeId, file, name) { return ({
    type: POST_PHOTOS_ACTION_REQUEST,
    placeId: placeId,
    file: file,
    name: name,
}); };
export var postPlacePhotosSuccess = function (payload) { return ({
    type: POST_PHOTOS_ACTION_SUCCESS,
    payload: payload,
}); };
export var postPlacePhotosClearFailure = function () { return ({
    type: POST_PHOTOS_ACTION_CLEAR_FAILURE,
}); };
export var postPlacePhotosClearSuccess = function () { return ({
    type: POST_PHOTOS_ACTION_CLEAR_SUCCESS,
}); };
export var putPhotos = function (placeId, mediaId) { return ({
    type: PUT_PHOTOS_ACTION_REQUEST,
    placeId: placeId,
    mediaId: mediaId,
}); };
export var putPlacePhotosSuccess = function () { return ({
    type: PUT_PHOTOS_ACTION_SUCCESS,
}); };
export var putPlacePhotosFailure = function () { return ({
    type: PUT_PHOTOS_ACTION_FAILURE,
}); };
export var clearReported = function () { return ({
    type: CLEAR_REPORTED,
}); };
export var reportMediaPlace = function (payload) { return ({
    type: REPORT_MEDIA_PLACE,
    payload: payload,
}); };
export var reportMediaPlaceSuccess = function () { return ({
    type: REPORT_MEDIA_PLACE_SUCCESS,
}); };
export var reportMediaPlaceFailure = function () { return ({
    type: REPORT_MEDIA_PLACE_FAILURE,
}); };
export var deletePhoto = function (payload) { return ({
    type: DELETE_PHOTO,
    payload: payload,
}); };
export var deletePhotoSuccess = function () { return ({
    type: DELETE_PHOTO_SUCCESS,
}); };
export var deletePhotoFailure = function (payload) { return ({
    type: DELETE_PHOTO_FAILURE,
    payload: payload,
}); };
export var clearNotification = function () { return ({
    type: CLEAR_NOTIFICATION,
}); };
