import produce from 'immer';
import messages from 'translations';
import { DEFAULT_USER_LOCALE } from 'utils/locale';
import { CHANGE_LOCALE } from './constants';
var locale = DEFAULT_USER_LOCALE(messages);
export var initialState = {
    locale: locale,
};
var languageProviderReducer = produce(function (draft, action) {
    if (action.type === CHANGE_LOCALE) {
        draft.locale = action.payload;
    }
}, initialState);
export default languageProviderReducer;
