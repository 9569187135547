import { HOME_MOST_ACTIVE_FAILURE, HOME_MOST_ACTIVE_REQUEST, HOME_MOST_ACTIVE_SUCCESS, } from './constants';
export var fetchHomeMostActiveWeeklyRequest = function () { return ({
    type: HOME_MOST_ACTIVE_REQUEST,
}); };
export var setHomeMostActiveWeeklyFailure = function (payload) { return ({
    type: HOME_MOST_ACTIVE_FAILURE,
    payload: payload,
}); };
export var setHomeMostActiveWeeklySuccess = function (payload) { return ({
    type: HOME_MOST_ACTIVE_SUCCESS,
    payload: payload,
}); };
