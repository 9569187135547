import { ADD_NEW_MESSAGE, GET_CONVERSATION, GET_CONVERSATIONS, GET_HISTORY, LOCK_CONVERSATION_NOTIFICATION, OPTIONS_CHANGED_SUCCESS, QUIT_GROUP_CONVERSATION, SET_CHAT_CONNECTION, SHOW_CONVERSATION, SHOW_CONVERSATIONS, SHOW_HISTORY, UNLOCK_CONVERSATION_NOTIFICATION, } from './contants';
export var getConversations = function (payload) { return ({
    type: GET_CONVERSATIONS,
    payload: payload,
}); };
export var showConversations = function (payload) { return ({
    type: SHOW_CONVERSATIONS,
    payload: payload,
}); };
export var getConversation = function (payload) { return ({
    type: GET_CONVERSATION,
    payload: payload,
}); };
export var showConversation = function (payload) { return ({
    type: SHOW_CONVERSATION,
    payload: payload,
}); };
export var lockConversationNotification = function (payload) { return ({
    type: LOCK_CONVERSATION_NOTIFICATION,
    payload: payload,
}); };
export var unlockConversationNotification = function (payload) { return ({
    type: UNLOCK_CONVERSATION_NOTIFICATION,
    payload: payload,
}); };
export var optionsChangedSuccess = function () { return ({
    type: OPTIONS_CHANGED_SUCCESS,
}); };
export var quitGroupConversation = function (payload) { return ({
    type: QUIT_GROUP_CONVERSATION,
    payload: payload,
}); };
export var getHistory = function (payload) { return ({
    type: GET_HISTORY,
    payload: payload,
}); };
export var showHistory = function (payload) { return ({
    type: SHOW_HISTORY,
    payload: payload,
}); };
export var setChatConnection = function (payload) { return ({
    type: SET_CHAT_CONNECTION,
    payload: payload,
}); };
export var addNewMessage = function (payload) { return ({
    type: ADD_NEW_MESSAGE,
    payload: payload,
}); };
