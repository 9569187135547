import { PROFILE_MY_NOTIFICATIONS_FAILURE, PROFILE_MY_NOTIFICATIONS_REQUEST, PROFILE_MY_NOTIFICATIONS_SUCCESS, PROFILE_UPDATE_NOTIFICATIONS_FAILURE, PROFILE_UPDATE_NOTIFICATIONS_REQUEST, PROFILE_UPDATE_NOTIFICATIONS_SUCCESS, } from '../constants';
export var fetchMyNotificationsRequest = function (payload) { return ({
    type: PROFILE_MY_NOTIFICATIONS_REQUEST,
    payload: payload,
}); };
export var fetchMyNotificationsSuccess = function (payload) { return ({
    type: PROFILE_MY_NOTIFICATIONS_SUCCESS,
    payload: payload,
}); };
export var fetchMyNotificationsError = function (error) { return ({
    type: PROFILE_MY_NOTIFICATIONS_FAILURE,
    payload: error,
}); };
export var putNotificationsRequest = function (payload) { return ({
    type: PROFILE_UPDATE_NOTIFICATIONS_REQUEST,
    payload: payload,
}); };
export var putNotificationsSuccess = function (payload) { return ({
    type: PROFILE_UPDATE_NOTIFICATIONS_SUCCESS,
    payload: payload,
}); };
export var putNotificationsError = function (error) { return ({
    type: PROFILE_UPDATE_NOTIFICATIONS_FAILURE,
    payload: error,
}); };
