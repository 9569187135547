import React, { memo } from 'react';
export var RvParkOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#b3b3b3", style: { fill: '#b3b3b3' }, d: "M31.39 0.305h-30.476v30.476h30.476v-30.476z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M10.382 21.068c0.83 0 1.503-0.673 1.503-1.503s-0.673-1.503-1.503-1.503c-0.83 0-1.503 0.673-1.503 1.503s0.673 1.503 1.503 1.503z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M10.382 18.061c0.83 0 1.503 0.673 1.503 1.504s-0.673 1.503-1.503 1.503c-0.83 0-1.503-0.673-1.503-1.503s0.673-1.504 1.503-1.504z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M20.432 21.068c0.83 0 1.503-0.673 1.503-1.503s-0.673-1.503-1.503-1.503c-0.83 0-1.503 0.673-1.503 1.503s0.673 1.503 1.503 1.503z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M20.432 18.061c0.83 0 1.503 0.673 1.503 1.504s-0.673 1.503-1.503 1.503c-0.83 0-1.504-0.673-1.504-1.503s0.673-1.504 1.504-1.504z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M16.354 13.456h-1.824c-0.396 0-0.544 0.186-0.544 0.582v5.527h2.907v-5.567c-0-0.396-0.143-0.541-0.539-0.541z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M24.286 9.784c0-0.396-0.323-0.72-0.72-0.72h-14.528c-0.594 0-1.174 0.477-1.289 1.060l-0.304 2.272c-0.114 0.583 0.279 1.060 0.873 1.060h1.989l-0.84 2.489-1.872 0.195c-0.393 0.051-0.714 0.416-0.714 0.812v1.893c0 0.396 0.236 0.72 0.524 0.72h0.881c0-1.156 0.94-2.096 2.096-2.096s2.096 0.941 2.096 2.096h1.058v-5.884c0-0.396 0.324-0.72 0.72-0.72h2.366c0.396 0 0.72 0.324 0.72 0.72v5.884h0.993c0-1.156 0.941-2.096 2.096-2.096s2.096 0.941 2.096 2.096l1.038-0.001c0.396 0.001 0.72-0.323 0.72-0.719l0-9.061zM12.255 15.607l-2.104 0.254 0.762-2.356h1.342v2.103z" })));
});
