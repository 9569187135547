var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { placeholderPlace } from 'assets/images';
import { Rate } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { useSelector } from 'hooks';
import * as categoryId from 'icons/category/id';
import { mapIcons } from 'icons/category/map';
import { rem } from 'polished';
import React, { memo } from 'react';
import { snakeToCamel } from 'utils/CamelChange';
import { Address, CategoryIcon, CategoryLabel, Container, Content, ImageWrapper, PlaceImage, PlaceInfo, PlaceName, Rating, } from './styles';
export var CardPlaces = memo(function (_a) {
    var _b;
    var place = _a.place, _c = _a.showHearts, showHearts = _c === void 0 ? true : _c, _d = _a.showCategoryIcon, showCategoryIcon = _d === void 0 ? false : _d, _e = _a.bookmarkButton, bookmarkButton = _e === void 0 ? false : _e, _f = _a.imageHeight, imageHeight = _f === void 0 ? 100 : _f, placeStyle = _a.placeStyle, _g = _a.isMapCard, isMapCard = _g === void 0 ? false : _g, _h = _a.onClick, onClick = _h === void 0 ? function () { return true; } : _h, props = __rest(_a, ["place", "showHearts", "showCategoryIcon", "bookmarkButton", "imageHeight", "placeStyle", "isMapCard", "onClick"]);
    function renderCategoryIcon() {
        return mapIcons(categoryId.square[place.hasEnoughContributions
            ? snakeToCamel(place.category.name)
            : snakeToCamel(place.category.name) + "Off"], 'square', {
            size: rem(32),
        });
    }
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(Container, __assign({ onClick: onClick, isKeyboardMode: keyboard }, props),
        React.createElement(ImageWrapper, { imageHeight: imageHeight, isMapCard: isMapCard },
            showCategoryIcon && (React.createElement(CategoryIcon, null, renderCategoryIcon())),
            React.createElement(PlaceImage, { src: ((_b = place.mainImage) === null || _b === void 0 ? void 0 : _b.urlThumb) || placeholderPlace, alt: place.name })),
        React.createElement(Content, { style: placeStyle },
            React.createElement(PlaceInfo, null,
                React.createElement(PlaceName, null, place.name),
                showHearts && (React.createElement(Rating, null,
                    React.createElement("span", null, place.rating || 0),
                    React.createElement(Rate, { defaultValue: place.rating || 0, size: 16, disabled: true }),
                    React.createElement("span", null,
                        "(",
                        place.totalComments || 0,
                        ")"))),
                React.createElement(CategoryLabel, null, place.category.label),
                React.createElement(Address, null,
                    place.address.postalCode,
                    " ",
                    place.address.city)),
            bookmarkButton)));
});
