import { selectAuthDomain } from 'containers/Auth/selectors';
import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { store } from 'store';

import { resendEmailActivation } from './actions';
import ResendEmailForm from './Form';

const defaultProps = {};

export const ResendEmail = withFormik({
  mapPropsToValues: () => defaultProps,
  // eslint-disable-next-line no-empty-pattern
  handleSubmit({}, { props, setSubmitting, resetForm, setErrors, setStatus }) {
    const {
      user: { email },
    } = selectAuthDomain(store.getState());
    const payload = { email };
    const actions = {
      resetForm,
      setErrors,
      setSubmitting,
      setStatus,
    };
    props.handle(payload, actions);
  },
  displayName: 'ResendEmail',
})(ResendEmailForm);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  handle(payload, actions) {
    dispatch(resendEmailActivation(payload, actions));
  },
});

const ResendEmailIntl = injectIntl(ResendEmail);

const ConnectResendEmailActivation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResendEmailIntl);

export default ConnectResendEmailActivation;
