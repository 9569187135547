var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Locale = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M20 28.5714C15.2737 28.5714 11.4286 24.7263 11.4286 20C11.4286 15.2737 15.2737 11.4286 20 11.4286C24.7263 11.4286 28.5714 15.2737 28.5714 20C28.5714 24.7263 24.7263 28.5714 20 28.5714ZM34.2857 18.2857H31.877C31.1231 13.0382 26.9618 8.87693 21.7143 8.123V5.71429C21.7143 4.7675 20.9468 4 20 4C19.0532 4 18.2857 4.7675 18.2857 5.71429V8.123C13.0382 8.87693 8.87693 13.0382 8.123 18.2857H5.71429C4.7675 18.2857 4 19.0532 4 20C4 20.9468 4.7675 21.7143 5.71429 21.7143H8.123C8.87693 26.9618 13.0382 31.1231 18.2857 31.877V34.2857C18.2857 35.2325 19.0532 36 20 36C20.9468 36 21.7143 35.2325 21.7143 34.2857V31.877C26.9618 31.1231 31.1231 26.9618 31.877 21.7143H34.2857C35.2325 21.7143 36 20.9468 36 20C36 19.0532 35.2325 18.2857 34.2857 18.2857Z", fill: color })));
});
