var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint700,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n\n  ", "\n"], ["\n  background: ", ";\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n\n  ",
    "\n"])), background, ifProp('isKeyboardMode', css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      &:focus {\n        position: relative;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          top: ", ";\n          left: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        position: relative;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          top: ", ";\n          left: ", ";\n        }\n      }\n    "])), border, rem(10), rem(10), rem(10), rem(-8), rem(-9))));
export var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding: ", ";\n\n  > div + div {\n    margin-left: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding: ", ";\n\n  > div + div {\n    margin-left: ", ";\n  }\n"])), rem(16), rem(8));
export var ImageWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: ", ";\n  position: relative;\n  width: 100%;\n\n  ", "\n"], ["\n  height: ",
    ";\n  position: relative;\n  width: 100%;\n\n  ",
    "\n"])), function (_a) {
    var imageHeight = _a.imageHeight;
    return typeof imageHeight === 'number' ? rem(imageHeight) : imageHeight;
}, ifProp('isMapCard', css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
    "\n    "])), media.lessThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        display: none;\n      "], ["\n        display: none;\n      "]))))));
export var PlaceImage = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: 100%;\n  object-fit: cover;\n  width: 100%;\n"], ["\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: 100%;\n  object-fit: cover;\n  width: 100%;\n"])), rem(6), rem(6));
export var CategoryIcon = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: ", " solid ", ";\n  overflow: hidden;\n  z-index: 1;\n  position: absolute;\n  bottom: ", ";\n  left: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  border-radius: ", ";\n  border: ", " solid ", ";\n  overflow: hidden;\n  z-index: 1;\n  position: absolute;\n  bottom: ", ";\n  left: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), rem(4), rem(1), colors.base.white, rem(10), rem(10), rem(32), rem(32));
export var PlaceInfo = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n"], ["\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n"])));
export var PlaceName = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  ", "\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  ",
    "\n"])), color, rem(17), fonts.weight.semibold, media.lessThan('medium')(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    margin-right: ", ";\n  "], ["\n    margin-right: ", ";\n  "])), rem(24)));
export var Rating = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  align-items: center;\n  font-size: ", ";\n\n  .ant-rate {\n    margin: ", " ", " 0;\n    white-space: nowrap;\n  }\n"], ["\n  color: ", ";\n  display: flex;\n  align-items: center;\n  font-size: ", ";\n\n  .ant-rate {\n    margin: ", " ", " 0;\n    white-space: nowrap;\n  }\n"])), color, rem(14), rem(4), rem(4));
export var CategoryLabel = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), color, rem(13));
export var Address = styled.span(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  padding-top: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  padding-top: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n"])), color, rem(13), rem(8));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
