var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var ArrowNext = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.1335 17.7135H28.7165L19.5577 7.90261C18.724 7.00941 18.724 5.56131 19.5577 4.6699C20.3914 3.7767 21.743 3.7767 22.575 4.6699L35.376 18.383C36.208 19.2762 36.208 20.7243 35.376 21.6157L22.575 35.3305C22.159 35.7763 21.6127 36 21.0663 36C20.52 36 19.9737 35.7763 19.5577 35.3305C18.724 34.4373 18.724 32.9892 19.5577 32.0978L28.7165 22.2851H6.1335C4.95398 22.2851 4 21.2613 4 19.9993C4 18.7374 4.95398 17.7135 6.1335 17.7135Z", fill: color })));
});
