import { MONTH_USERS_FAILURE, MONTH_USERS_REQUEST, MONTH_USERS_SUCCESS, } from './constants';
export var fetchMonthUsers = function (payload) { return ({
    type: MONTH_USERS_REQUEST,
    payload: payload,
}); };
export var fetchMonthUsersSuccess = function (payload) { return ({
    type: MONTH_USERS_SUCCESS,
    payload: payload,
}); };
export var fetchMonthUsersError = function (error) { return ({
    type: MONTH_USERS_FAILURE,
    payload: error,
}); };
