export var PROFILE_MY_PROFILE_REQUEST = 'Profile/PROFILE_MY_PROFILE_REQUEST';
export var PROFILE_MY_PROFILE_FAILURE = 'Profile/PROFILE_MY_PROFILE_FAILURE';
export var PROFILE_MY_PROFILE_SUCCESS = 'Profile/PROFILE_MY_PROFILE_SUCCESS';
export var PROFILE_MY_TEAM_REQUEST = 'Profile/PROFILE_MY_TEAM_REQUEST';
export var PROFILE_MY_TEAM_FAILURE = 'Profile/PROFILE_MY_TEAM_FAILURE';
export var PROFILE_MY_TEAM_SUCCESS = 'Profile/PROFILE_MY_TEAM_SUCCESS';
export var PROFILE_MY_CONTRIBUTIONS_REQUEST = 'Profile/PROFILE_MY_CONTRIBUTIONS_REQUEST';
export var PROFILE_MY_CONTRIBUTIONS_FAILURE = 'Profile/PROFILE_MY_CONTRIBUTIONS_FAILURE';
export var PROFILE_MY_CONTRIBUTIONS_SUCCESS = 'Profile/PROFILE_MY_CONTRIBUTIONS_SUCCESS';
export var PROFILE_MY_FAVORITES_REQUEST = 'Profile/PROFILE_MY_FAVORITES_REQUEST';
export var PROFILE_MY_FAVORITES_FAILURE = 'Profile/PROFILE_MY_FAVORITES_FAILURE';
export var PROFILE_MY_FAVORITES_SUCCESS = 'Profile/PROFILE_MY_FAVORITES_SUCCESS';
export var PROFILE_MY_CHALLENGES_REQUEST = 'Profile/PROFILE_MY_CHALLENGES_REQUEST';
export var PROFILE_MY_CHALLENGES_FAILURE = 'Profile/PROFILE_MY_CHALLENGES_FAILURE';
export var PROFILE_MY_CHALLENGES_SUCCESS = 'Profile/PROFILE_MY_CHALLENGES_SUCCESS';
export var PROFILE_MY_NOTIFICATIONS_REQUEST = 'Profile/PROFILE_MY_NOTIFICATIONS_REQUEST';
export var PROFILE_MY_NOTIFICATIONS_FAILURE = 'Profile/PROFILE_MY_NOTIFICATIONS_FAILURE';
export var PROFILE_MY_NOTIFICATIONS_SUCCESS = 'Profile/PROFILE_MY_NOTIFICATIONS_SUCCESS';
export var PROFILE_UPDATE_NOTIFICATIONS_REQUEST = 'Profile/PROFILE_UPDATE_NOTIFICATIONS_REQUEST';
export var PROFILE_UPDATE_NOTIFICATIONS_FAILURE = 'Profile/PROFILE_UPDATE_NOTIFICATIONS_FAILURE';
export var PROFILE_UPDATE_NOTIFICATIONS_SUCCESS = 'Profile/PROFILE_UPDATE_NOTIFICATIONS_SUCCESS';
