var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var Medal5000DisabledDesktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.34323 29.2766V49.5617L19.9134 42.7047L32.4828 49.5617V29.2766H7.34323Z", fill: "#EF8E1B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.6284 29.2766V46.1336L19.9135 42.7047L26.1986 46.1336V29.2766H13.6284Z", fill: "#E08119" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.8729 4.72426L11.0517 4.02298L8.59295 8.22894L3.94359 9.68936L3.5555 14.5472L-0.000244141 17.88L1.7572 22.4247L-0.000244141 26.9702L3.5555 30.303L3.94359 35.16L8.59295 36.6204L11.0517 40.8281L15.8729 40.126L19.9129 42.8502L23.9538 40.126L28.7759 40.8281L31.2338 36.6204L35.8832 35.16L36.2721 30.303L39.8261 26.9711V26.9677L38.0695 22.4247L39.8261 17.8826V17.88L36.2721 14.5472L35.8832 9.68936L31.2338 8.22894L28.7759 4.02298L23.9538 4.72426L19.9129 2L15.8729 4.72426Z", fill: "black", fillOpacity: "0.1" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.8729 3.72502L11.0517 3.02374L8.59295 7.2297L3.94359 8.69013L3.5555 13.548L-0.000244141 16.8808L1.7572 21.4254L-0.000244141 25.971L3.5555 29.3037L3.94359 34.1608L8.59295 35.6212L11.0517 39.8289L15.8729 39.1267L19.9129 41.851L23.9538 39.1267L28.7759 39.8289L31.2338 35.6212L35.8832 34.1608L36.2721 29.3037L39.8261 25.9718V25.9684L38.0695 21.4254L39.8261 16.8833V16.8808L36.2721 13.548L35.8832 8.69013L31.2338 7.2297L28.7759 3.02374L23.9538 3.72502L19.9129 1.00077L15.8729 3.72502Z", fill: "#F7D131" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.1653 21.4259C34.1653 29.2965 27.784 35.6769 19.9134 35.6769C12.0419 35.6769 5.66147 29.2965 5.66147 21.4259C5.66147 13.5544 12.0419 7.17396 19.9134 7.17396C27.784 7.17396 34.1653 13.5544 34.1653 21.4259Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9128 34.0671C13.1546 34.0671 7.65583 28.5693 7.65583 21.8101C7.65583 15.0518 13.1546 9.55226 19.9128 9.55226C26.672 9.55226 32.1707 15.0518 32.1707 21.8101C32.1707 28.5693 26.672 34.0671 19.9128 34.0671ZM19.9129 6.92587C11.7052 6.92587 5.02859 13.6033 5.02859 21.8101C5.02859 30.0178 11.7052 36.6944 19.9129 36.6944C28.1205 36.6944 34.7971 30.0178 34.7971 21.8101C34.7971 13.6033 28.1205 6.92587 19.9129 6.92587Z", fill: "#F7D131" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9129 34.6997C12.5937 34.6997 6.63881 28.7456 6.63881 21.4256C6.63881 14.1073 12.5937 8.15157 19.9129 8.15157C27.2329 8.15157 33.1877 14.1073 33.1877 21.4256C33.1877 28.7456 27.2329 34.6997 19.9129 34.6997ZM19.9129 5.30732C11.0252 5.30732 3.79456 12.5388 3.79456 21.4256C3.79456 30.315 11.0252 37.5439 19.9129 37.5439C28.8014 37.5439 36.032 30.315 36.032 21.4256C36.032 12.5388 28.8014 5.30732 19.9129 5.30732Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.1253 23.9134L15.247 23.6956C15.2844 23.6309 15.3389 23.5781 15.4172 23.5364C15.504 23.4896 15.5985 23.4666 15.7057 23.4666C15.8946 23.4666 15.9814 23.5322 16.0351 23.5883C16.0895 23.6462 16.161 23.7475 16.161 23.9722C16.161 24.1977 16.0827 24.2981 16.0248 24.3543C15.8223 24.5483 15.3431 24.5398 14.8785 24.3126C14.6751 24.2122 14.4836 24.0777 14.3074 23.9134H15.1253ZM18.5227 21.4896C18.2155 21.1926 17.8487 20.9586 17.4325 20.7943C16.9925 20.6207 16.4597 20.5271 15.9755 20.5483L15.9857 20.4794H18.8376V17.419H13.5151L12.281 23.9134H13.4436L12.0112 25.5654L12.1797 25.8334C12.5117 26.3611 12.9874 26.7705 13.5925 27.0522C14.1789 27.3245 14.8419 27.4624 15.5653 27.4624C16.1295 27.4624 16.6563 27.3764 17.1312 27.2071C17.6095 27.0369 18.03 26.7951 18.3806 26.4879C18.7363 26.1756 19.0155 25.7951 19.2095 25.3577C19.4036 24.9228 19.5014 24.4386 19.5014 23.922C19.5014 23.4351 19.4155 22.9781 19.2461 22.5662C19.0759 22.1526 18.8325 21.7909 18.5227 21.4896Z", fill: "#D8B627" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.0022 21.3751L27.6627 17.9939H24.015L22.872 19.5862V15.3896H19.5954V25.5598H22.872V23.3947L24.2133 25.5598H27.815L25.0022 21.3751Z", fill: "#D8B627" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.1982 20.1219C16.6408 20.1219 17.045 20.1942 17.411 20.3389C17.7769 20.4836 18.0944 20.6853 18.3616 20.9449C18.6297 21.2044 18.8391 21.5159 18.9871 21.8776C19.1361 22.2385 19.211 22.6368 19.211 23.0708C19.211 23.5304 19.1259 23.9517 18.9557 24.3338C18.7854 24.7168 18.5446 25.0444 18.2348 25.3168C17.9233 25.59 17.5531 25.8027 17.1233 25.9551C16.6944 26.1083 16.2195 26.1857 15.7003 26.1857C15.0365 26.1857 14.4382 26.0614 13.9071 25.8146C13.3752 25.5678 12.9642 25.2146 12.6748 24.7551L14.0025 23.224C14.2493 23.4878 14.5233 23.6938 14.8263 23.8427C15.1276 23.9917 15.4237 24.0666 15.7131 24.0666C16.028 24.0666 16.2748 23.9814 16.4535 23.8112C16.6323 23.641 16.7216 23.4104 16.7216 23.121C16.7216 22.8402 16.6408 22.6155 16.4791 22.4444C16.3174 22.2742 16.1046 22.19 15.8408 22.19C15.662 22.19 15.4978 22.2308 15.3488 22.3108C15.2008 22.3917 15.0876 22.5006 15.011 22.6368H12.9301L14.0025 16.9934H18.5471V19.2027H15.7514L15.611 20.1849C15.645 20.1763 15.6918 20.1687 15.7514 20.1602C15.794 20.1517 15.8535 20.1423 15.9301 20.1346C16.0067 20.1261 16.0961 20.1219 16.1982 20.1219Z", fill: "#F9F1B7" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.5855 24.2832L23.0663 21.8322L22.5812 22.3683V24.2832H20.1557V14.9641H22.5812V20.0577L24.3684 17.5683H26.9216L24.611 20.5045L27.1514 24.2832H24.5855Z", fill: "#F9F1B7" }),
        React.createElement("g", { style: { mixBlendMode: 'color' } },
            React.createElement("rect", { width: "40", height: "50", fill: "white" }))));
});
