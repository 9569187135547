import produce from 'immer';
import findIndex from 'lodash/findIndex';
import { SUBSCRIPTIONS_FAILURE, SUBSCRIPTIONS_REMOVE_USER, SUBSCRIPTIONS_REQUEST, SUBSCRIPTIONS_SUCCESS, SUBSCRIPTIONS_TOGGLE_USER_IS_SUBSCRIBED, } from './constants';
export var subscriptionsInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    pages: 1,
    currentPage: 1,
    total: 0,
    items: undefined,
};
var subscriptionsReducer = produce(function (draft, action) {
    switch (action.type) {
        case SUBSCRIPTIONS_REQUEST:
            draft.loading = true;
            break;
        case SUBSCRIPTIONS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case SUBSCRIPTIONS_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.currentPage = action.payload.currentPage;
            draft.pages = action.payload.pages;
            draft.total = action.payload.total;
            draft.items = action.payload.items;
            break;
        case SUBSCRIPTIONS_TOGGLE_USER_IS_SUBSCRIBED: {
            var index = findIndex(draft.items, ['id', action.payload]);
            draft.items[index].isFollowed = !draft.items[index].isFollowed;
            break;
        }
        case SUBSCRIPTIONS_REMOVE_USER: {
            var index = findIndex(draft.items, ['id', action.payload]);
            draft.items.splice(index, 1);
            break;
        }
        default:
    }
}, subscriptionsInitialState);
export default subscriptionsReducer;
