import { Option, Select } from 'components';
import { Button } from 'components/Button';
import { FieldTemplate } from 'components/Field';
import { Formik } from 'formik';
import includes from 'lodash/includes';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Template } from '../Template';
import { ButtonWrapper, EditCategoryContainer } from './styles';
export var EditCategory = function (_a) {
    var category = _a.category, onBack = _a.onBack, categories = _a.categories, onSubmit = _a.onSubmit, loading = _a.loading;
    var formatMessage = useIntl().formatMessage;
    var categoryTittle = formatMessage({ id: "containers.editPlace.category" });
    return (React.createElement(EditCategoryContainer, null,
        React.createElement(Template, { pageTitle: categoryTittle, onBack: onBack, loading: loading },
            React.createElement(Formik, { initialValues: {
                    categoryId: category === null || category === void 0 ? void 0 : category.id,
                }, enableReinitialize: true, onSubmit: function (form) { return onSubmit(form, categoryTittle); } }, function (_a) {
                var handleSubmit = _a.handleSubmit, values = _a.values, setFieldValue = _a.setFieldValue;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(FieldTemplate, { label: formatMessage({ id: "containers.editPlace.category" }) },
                        React.createElement(Select, { showSearch: true, value: values.categoryId, filterOption: function (input, option) {
                                return includes(toLower(option === null || option === void 0 ? void 0 : option.children), toLower(input));
                            }, onChange: function (value) { return setFieldValue('categoryId', value); } }, map(categories, function (c) { return (React.createElement(Option, { key: c.id, value: c.id }, c.label)); }))),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { type: "submit" },
                            React.createElement(FormattedMessage, { id: "containers.placeDetails.saveChangesButton" })))));
            }))));
};
