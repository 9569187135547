import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import { makeSelectDarkMode } from 'containers/App/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Place, PodiumContainer, UserName, UserPoints } from './styles';
export var Podium = function (_a) {
    var users = _a.users, loggedUserSlug = _a.loggedUserSlug, useContributions = _a.useContributions;
    var _b, _c, _d;
    var dark = useSelector(makeSelectDarkMode());
    return (React.createElement(PodiumContainer, { dark: dark },
        users.length >= 3 && (React.createElement(Place, { position: 3, hasFirstName: !!users[2].firstName, to: loggedUserSlug === users[2].slug
                ? paths.profilePage
                : paths.usersPage.replace(':userSlug', users[2].slug) },
            React.createElement(Avatar, { src: ((_b = users[2].avatar) === null || _b === void 0 ? void 0 : _b.urlThumb) || avatarImage1, alt: "User profile image" }),
            React.createElement(UserName, null,
                users[2].firstName && (React.createElement("strong", null, (users[2].firstName || '') + " " + (users[2].lastName || ''))),
                users[2].firstName ? (React.createElement("span", null,
                    "(@",
                    users[2].slug,
                    ")")) : (React.createElement("strong", null,
                    "@",
                    users[2].slug))),
            React.createElement(UserPoints, null,
                useContributions
                    ? users[2].contributions || 0
                    : users[2].points || 0,
                React.createElement(FormattedMessage, { id: "components.podium.points" })),
            React.createElement(Box, { position: 3 },
                React.createElement("strong", null, "3")))),
        users.length >= 1 && (React.createElement(Place, { position: 1, hasFirstName: !!users[0].firstName, to: loggedUserSlug === users[0].slug
                ? paths.profilePage
                : paths.usersPage.replace(':userSlug', users[0].slug) },
            React.createElement(Avatar, { src: ((_c = users[0].avatar) === null || _c === void 0 ? void 0 : _c.urlThumb) || avatarImage1, alt: "User profile image" }),
            React.createElement(UserName, null,
                users[0].firstName && (React.createElement("strong", null, (users[0].firstName || '') + " " + (users[0].lastName || ''))),
                users[0].firstName ? (React.createElement("span", null,
                    "(@",
                    users[0].slug,
                    ")")) : (React.createElement("strong", null,
                    "@",
                    users[0].slug))),
            React.createElement(UserPoints, null,
                useContributions
                    ? users[0].contributions || 0
                    : users[0].points || 0,
                React.createElement(FormattedMessage, { id: "components.podium.points" })),
            React.createElement(Box, { position: 1 },
                React.createElement("strong", null, "1")))),
        users.length >= 2 && (React.createElement(Place, { position: 2, hasFirstName: !!users[1].firstName, to: loggedUserSlug === users[1].slug
                ? paths.profilePage
                : paths.usersPage.replace(':userSlug', users[1].slug) },
            React.createElement(Avatar, { src: ((_d = users[1].avatar) === null || _d === void 0 ? void 0 : _d.urlThumb) || avatarImage1, alt: "User profile image" }),
            React.createElement(UserName, null,
                users[1].firstName && (React.createElement("strong", null, (users[1].firstName || '') + " " + (users[1].lastName || ''))),
                users[1].firstName ? (React.createElement("span", null,
                    "(@",
                    users[1].slug,
                    ")")) : (React.createElement("strong", null,
                    "@",
                    users[1].slug))),
            React.createElement(UserPoints, null,
                useContributions
                    ? users[1].contributions || 0
                    : users[1].points || 0,
                React.createElement(FormattedMessage, { id: "components.podium.points" })),
            React.createElement(Box, { position: 2 },
                React.createElement("strong", null, "2"))))));
};
