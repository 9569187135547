/* eslint-disable indent */
import { push } from 'connected-react-router';
import { useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector as useReduxSelector, } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line
export var useAction = function (action) {
    var dispatch = useDispatch();
    return useMemo(function () { return bindActionCreators(action, dispatch); }, [
        action,
        dispatch,
    ]);
};
export var useSelector = function (selector) { return useReduxSelector(selector, shallowEqual); };
export var usePush = function (path) { return useAction(function () { return push(path); }); };
export var usePrevious = function (value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
};
export var useOutsideClick = function (ref, callback) {
    var handleClick = function (e) {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };
    useEffect(function () {
        document.addEventListener('click', handleClick);
        return function () {
            document.addEventListener('click', handleClick);
        };
    });
};
