/* eslint-disable no-nested-ternary */
import { makeSelectDarkMode } from 'containers/App/selectors';
import { useSelector } from 'hooks';
import { DarkModeLight, DarkModePurple, FontSizeGray, FontSizeWhite, HeartDark, HeartLight, HeartPurple, PinDark, PinLight, PinPurple, ReaderLeftHandDark, ReaderLeftHandLight, ReaderRightHandDark, ReaderRightHandLight, SettingsDark, SettingsLight, SettingsPurple, TrophyDark, TrophyLight, TrophyPurple, } from 'icons/color';
import { rem } from 'polished';
import React, { memo } from 'react';
import { FlagIcon } from '../Flags';
export var ChallengeIcon = memo(function (_a) {
    var title = _a.title, isActive = _a.isActive;
    var isDarkMode = useSelector(makeSelectDarkMode());
    return (React.createElement(React.Fragment, null,
        isActive ? (React.createElement(TrophyPurple, { title: title, width: rem(40), height: rem(40) })) : isDarkMode ? (React.createElement(TrophyDark, { title: title, width: rem(40), height: rem(40) })) : (React.createElement(TrophyLight, { title: title, width: rem(40), height: rem(40) })),
        React.createElement("span", null, title)));
});
export var DonateIcon = memo(function (_a) {
    var title = _a.title, isActive = _a.isActive;
    var isDarkMode = useSelector(makeSelectDarkMode());
    return (React.createElement(React.Fragment, null,
        isActive ? (React.createElement(HeartPurple, { title: title, width: rem(40), height: rem(40) })) : isDarkMode ? (React.createElement(HeartDark, { title: title, width: rem(40), height: rem(40) })) : (React.createElement(HeartLight, { title: title, width: rem(40), height: rem(40) })),
        React.createElement("span", null, title)));
});
export var ExploreIcon = memo(function (_a) {
    var title = _a.title, isActive = _a.isActive;
    var isDarkMode = useSelector(makeSelectDarkMode());
    return (React.createElement(React.Fragment, null,
        isActive ? (React.createElement(PinPurple, { title: title, width: rem(40), height: rem(40) })) : isDarkMode ? (React.createElement(PinDark, { title: title, width: rem(40), height: rem(40) })) : (React.createElement(PinLight, { title: title, width: rem(40), height: rem(40) })),
        React.createElement("span", null, title)));
});
export var SettingsIcon = memo(function (_a) {
    var title = _a.title, isActive = _a.isActive;
    var isDarkMode = useSelector(makeSelectDarkMode());
    return (React.createElement(React.Fragment, null,
        isActive ? (React.createElement(SettingsPurple, { title: title, width: rem(40), height: rem(40) })) : isDarkMode ? (React.createElement(SettingsDark, { title: title, width: rem(40), height: rem(40) })) : (React.createElement(SettingsLight, { title: title, width: rem(40), height: rem(40) })),
        React.createElement("span", null, title)));
});
export var FontSizeIcon = memo(function (_a) {
    var title = _a.title;
    var isDarkMode = useSelector(makeSelectDarkMode());
    return (React.createElement(React.Fragment, null,
        isDarkMode ? (React.createElement(FontSizeWhite, { title: title, width: rem(31), height: rem(31) })) : (React.createElement(FontSizeGray, { title: title, width: rem(31), height: rem(31) })),
        React.createElement("span", null, title)));
});
export var ReadingHandIcon = memo(function (_a) {
    var title = _a.title, isActive = _a.isActive;
    var isDarkMode = useSelector(makeSelectDarkMode());
    return (React.createElement(React.Fragment, null,
        isActive && isDarkMode ? (React.createElement(ReaderLeftHandDark, { title: title, width: rem(51), height: rem(31) })) : isActive ? (React.createElement(ReaderLeftHandLight, { title: title, width: rem(51), height: rem(31) })) : isDarkMode ? (React.createElement(ReaderRightHandDark, { title: title, width: rem(51), height: rem(31) })) : (React.createElement(ReaderRightHandLight, { title: title, width: rem(51), height: rem(31) })),
        React.createElement("span", null, title)));
});
export var LanguageIcon = memo(function (_a) {
    var image = _a.image, title = _a.title;
    return (React.createElement(React.Fragment, null,
        React.createElement(FlagIcon, { flag: image, text: title, width: 24, height: 16, style: { marginRight: 8 } }),
        React.createElement("span", null, title)));
});
export var DarkModeIcon = memo(function (_a) {
    var title = _a.title, isActive = _a.isActive;
    return (React.createElement(React.Fragment, null,
        isActive ? (React.createElement(DarkModePurple, { title: title, width: rem(31), height: rem(31) })) : (React.createElement(DarkModeLight, { title: title, width: rem(31), height: rem(31) })),
        React.createElement("span", null, title)));
});
