import { CLEAR_FILTER, GET_ACCESSIBILITY_FILTERS, GET_ACCESSIBILITY_FILTERS_FAILURE, GET_ACCESSIBILITY_FILTERS_SUCCESS, SET_ACCESSIBILITY_FILTERS, SET_BOOLEAN_FILTER, SET_FILTER_MOBILE_VISIBLE, SET_PLACES_WITHOUT_DETAILS, SET_SELECTED_CATEGORY, } from './contants';
export var getAccessibilityFilters = function () { return ({
    type: GET_ACCESSIBILITY_FILTERS,
}); };
export var getAccessibilityFiltersSuccess = function (payload) { return ({
    type: GET_ACCESSIBILITY_FILTERS_SUCCESS,
    payload: payload,
}); };
export var getAccessibilityFiltersFailure = function () { return ({
    type: GET_ACCESSIBILITY_FILTERS_FAILURE,
}); };
export var setPlacesWithoutDetails = function (payload) { return ({
    type: SET_PLACES_WITHOUT_DETAILS,
    payload: payload,
}); };
export var setSelectedCategory = function (payload) { return ({
    type: SET_SELECTED_CATEGORY,
    payload: payload,
}); };
export var setSelectedAccessibilityFilters = function (payload) { return ({
    type: SET_ACCESSIBILITY_FILTERS,
    payload: payload,
}); };
export var setFilterMobileVisible = function (payload) { return ({
    type: SET_FILTER_MOBILE_VISIBLE,
    payload: payload,
}); };
export var setBooleanFilter = function (payload) { return ({
    type: SET_BOOLEAN_FILTER,
    payload: payload,
}); };
export var clearFilter = function () { return ({
    type: CLEAR_FILTER,
}); };
