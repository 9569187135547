import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Position } from '../Position';
import { PodiumItemContainer, Score, UserName, UserSlug } from './styles';
export var PodiumItem = memo(function (_a) {
    var user = _a.user, position = _a.position, loggedUserSlug = _a.loggedUserSlug;
    var _b;
    var keyboard = useSelector(makeSelectUseKeyboard());
    var points = (user === null || user === void 0 ? void 0 : user.points) ? user === null || user === void 0 ? void 0 : user.points : user === null || user === void 0 ? void 0 : user.contributions;
    return (React.createElement(PodiumItemContainer, { to: loggedUserSlug === user.slug
            ? paths.profilePage
            : paths.usersPage.replace(':userSlug', user.slug), isKeyboardMode: keyboard },
        React.createElement(Avatar, { alt: "Avatar", size: "medium", src: ((_b = user.avatar) === null || _b === void 0 ? void 0 : _b.urlThumb) || avatarImage1 }),
        user.firstName && (React.createElement(UserName, null, (user.firstName || '') + " " + (user.lastName || ''))),
        React.createElement(UserSlug, { hasNotFirstName: !user.firstName }, user.firstName ? "(@" + user.slug + ")" : "@" + user.slug),
        React.createElement(Score, null,
            points || 0,
            React.createElement(FormattedMessage, { id: "components.podium.points" })),
        React.createElement(Position, { position: position })));
});
