var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Youtube = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M34.8137 13.7431C34.8137 11.2258 32.9386 9.20082 30.6217 9.20082C27.4834 9.05594 24.2828 9 21.012 9H19.9923C16.7294 9 13.5232 9.05594 10.3849 9.20138C8.07365 9.20138 6.19861 11.2376 6.19861 13.7549C6.05699 15.7458 5.99695 17.7372 6.00034 19.7287C5.99468 21.7202 6.05888 23.7135 6.19295 25.7087C6.19295 28.226 8.06799 30.2678 10.3792 30.2678C13.6761 30.4188 17.058 30.4859 20.4965 30.4803C23.9407 30.4915 27.3131 30.4207 30.6138 30.2678C32.9307 30.2678 34.8057 28.226 34.8057 25.7087C34.9417 23.7116 35.004 21.7202 34.9983 19.7231C35.0112 17.7316 34.9496 15.7383 34.8137 13.7431ZM17.3929 25.3668V14.1146L25.6786 19.7379L17.3929 25.3668Z", fill: color })));
});
