import { avatarImage1, avatarImage2, avatarImage3 } from 'assets/images';
import find from 'lodash/find';
export var avatarMultiplier = [1, 0, -1];
export var avatarDefaultWidth = 57;
export var avatarToNumber = function (name) {
    var avatars = {
        avatar1: 1,
        avatar2: 2,
        avatar3: 3,
    };
    return avatars[name];
};
export var numberToAvatar = function (number) {
    var numbers = {
        1: avatarImage2,
        2: avatarImage1,
        3: avatarImage3,
    };
    return numbers[number];
};
export var getCarouselMargin = function (avatarNumber) {
    return avatarMultiplier[avatarNumber - 1] * avatarDefaultWidth;
};
export var getDefaultAvatarFileNameFromRenamedFile = function (name) {
    var partialFileNames = ['avatar1', 'avatar2', 'avatar3'];
    var result = find(partialFileNames, function (o) { return new RegExp(o).test(name); });
    return result;
};
