/* eslint-disable react/no-danger */
import { Avatar } from 'components';
import React, { memo } from 'react';
import { Author, Date, Info, NewsCardContainer, Title } from './styles';
export var NewsCard = memo(function (_a) {
    var author = _a.author, avatar = _a.avatar, background = _a.background, date = _a.date, title = _a.title;
    return (React.createElement(NewsCardContainer, { background: background },
        React.createElement(Title, { dangerouslySetInnerHTML: {
                __html: title,
            } }),
        React.createElement(Date, null, date),
        React.createElement(Info, null,
            React.createElement(Avatar, { size: "small", src: avatar, alt: author }),
            React.createElement(Author, null, author))));
});
