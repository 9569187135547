var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import { colors, fonts } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  margin-top: -", ";\n"], ["\n  margin-bottom: ", ";\n  margin-top: -", ";\n"])), rem(16), rem(8));
export var PasswordTips = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: ", ";\n  box-shadow: 0 ", " ", " ", ";\n  color: ", ";\n  font-size: ", ";\n  padding: ", " ", ";\n\n  ul {\n    list-style: none;\n    margin-top: ", ";\n  }\n\n  li {\n    margin-left: ", ";\n  }\n"], ["\n  border-radius: ", ";\n  box-shadow: 0 ", " ", " ", ";\n  color: ", ";\n  font-size: ", ";\n  padding: ", " ", ";\n\n  ul {\n    list-style: none;\n    margin-top: ", ";\n  }\n\n  li {\n    margin-left: ", ";\n  }\n"])), rem(4), rem(2), rem(4), rgba(colors.base.black, 0.2), colors.space.tint600, rem(13), rem(8), rem(16), rem(4), rem(4));
export var PasswordStrength = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: block;\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n\n  ", "\n"], ["\n  display: block;\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n\n  ",
    "\n"])), rem(14), fonts.weight.semibold, rem(8), switchProp('color', {
    gray: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), colors.space.tint600),
    danger: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), colors.base.danger),
    warning: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), colors.base.warning),
    info: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), colors.base.info),
    success: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), colors.base.primary),
}));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
