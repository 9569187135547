import React, { memo } from 'react';
export var ShoppingMall = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M27.733 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#936f3e", style: { fill: '#936f3e' }, d: "M7.042 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.758 4.759-12.474 0-17.232s-12.473-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M23.218 12.191h-1.888l0.074 9.062 0.798-0.647 1.493-0.247-0.478-8.167z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M18.238 9.342c-0.563-0.378-1.235-0.432-1.846-0.133-0.898 0.442-1.51 1.762-1.664 2.981h-2.537l-0.222 8.15 8.526 1.283 0.043-9.433h-1.010c-0.078-1.219-0.528-2.337-1.289-2.849zM16.755 9.955c0.345-0.17 0.709-0.155 1.026 0.058 0.53 0.357 0.855 0.959 0.924 2.178h-3.15c0.135-1.219 0.572-1.926 1.2-2.235z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M14.524 10.463l-0.702-0.002c-0.053-1.19-0.484-2.122-1.235-2.615-0.648-0.426-1.452-0.456-2.149-0.079-0.855 0.461-1.402 1.445-1.513 2.683l-1.54-0.004-0.222 8.129 4.114 0.176 0.154-7.196 2.792-0.011 0.301-1.080zM9.75 10.451c0.098-0.925 0.484-1.642 1.078-1.964 0.434-0.234 0.911-0.219 1.309 0.044 0.518 0.341 0.816 1.027 0.861 1.928l-3.248-0.008z" })));
});
