var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
var borderColor = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint900,
});
var mobileBackground = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint700,
});
var textDateColor = theme('mode', {
    light: colors.space.tint700,
    dark: colors.space.tint200,
});
export var CardChallenge = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: ", ";\n  border: ", " solid ", ";\n  display: flex;\n  flex-direction: column;\n  height: ", ";\n  margin: ", ";\n  max-height: ", ";\n  width: ", ";\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  ", ";\n"], ["\n  background-color: ", ";\n  border-radius: ", ";\n  border: ", " solid ", ";\n  display: flex;\n  flex-direction: column;\n  height: ", ";\n  margin: ", ";\n  max-height: ", ";\n  width: ", ";\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  ",
    ";\n"])), mobileBackground, rem(10), rem(1), borderColor, rem(230), rem(20), rem(230), rem(450), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: ", ";\n    margin: ", " 0;\n    width: 100%;\n  "], ["\n    height: ", ";\n    margin: ", " 0;\n    width: 100%;\n  "])), rem(150), rem(20)));
export var ChallengeImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  object-fit: cover;\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: ", ";\n  width: 100%;\n\n  ", ";\n"], ["\n  object-fit: cover;\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: ", ";\n  width: 100%;\n\n  ",
    ";\n"])), rem(10), rem(10), rem(130), media.lessThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n"], ["\n  height: ", ";\n"])), rem(70)));
export var ChallengeContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  padding: ", ";\n\n  ", ";\n"], ["\n  flex: 1;\n  display: flex;\n  padding: ", ";\n\n  ",
    ";\n"])), rem(10), media.lessThan('medium')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n"], ["\n  align-items: center;\n"]))));
export var ChallengeData = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: ", ";\n"], ["\n  margin-left: ", ";\n"])), rem(10));
export var ChallengeName = styled.h3(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n\n  ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n\n  ",
    ";\n"])), rem(19), fonts.weight.bold, rem(10), media.lessThan('medium')(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), rem(15)));
export var ChallengeDate = styled.h4(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"])), rem(13), fonts.weight.semibold, textDateColor);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
