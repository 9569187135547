var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { ProviderType } from 'containers/OAuth/types';
import { useAction } from 'hooks';
import { Facebook } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { oAuthFacebookGetUrl } from './actions';
export var FacebookButton = memo(function (_a) {
    var from = _a.from;
    var formatMessage = useIntl().formatMessage;
    var signInUser = useAction(function () {
        return oAuthFacebookGetUrl(ProviderType.Facebook, from);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { variant: "facebook", onClick: signInUser, icon: React.createElement(Facebook, { size: rem(24) }) }, formatMessage({
            id: 'components.facebookButton.continueWithFacebook',
        }))));
});
var Container = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: 0;\n  margin: 0 auto;\n  padding: ", " ", ";\n"], ["\n  border-radius: ", ";\n  border: 0;\n  margin: 0 auto;\n  padding: ", " ", ";\n"])), rem(4), rem(6), rem(10));
var templateObject_1;
