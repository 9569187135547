import { Tooltip } from 'components';
import { Question } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { colors } from 'styles';
import { Container, Item, QuestionIconWrapper } from './styles';
export var AddressInfo = memo(function () {
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (React.createElement(Tooltip, { title: React.createElement(Container, null,
            React.createElement(Item, null,
                React.createElement(FormattedMessage, { id: "containers.signUpEmail.addressInfo" }))), trigger: isMobile || isTabletOrMobile ? 'click' : 'hover' },
        React.createElement(QuestionIconWrapper, null,
            React.createElement(Question, { size: rem(22), color: colors.space.tint600 }))));
});
