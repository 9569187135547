var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { CardPlaces, Pagination } from 'components';
import { makeSelectIsLoggedIn } from 'containers/Auth/selectors';
import { AddPlaceToMap } from 'containers/ExplorePlaces/AddPlaceToMap';
import { addPlaceToBookmark, removePlaceFromBookmark, } from 'containers/PlaceDetails/actions';
import { makeSelectorBookmarkLoading } from 'containers/PlaceDetails/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import map from 'lodash/map';
import React, { memo, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { BookmarkButton } from '../../../PlaceDetails/BookmarkButton';
import { List, PaginationWrapper, PlacesListContainer, PlacesWrapper, Stick, } from './styles';
export var PlacesListMobile = memo(function (_a) {
    var places = _a.places, currentPage = _a.currentPage, perPage = _a.perPage, total = _a.total, loading = _a.loading, handlePageChange = _a.handlePageChange;
    var formatMessage = useIntl().formatMessage;
    var bottomLimit = window.innerHeight - 255;
    var _b = __read(useState(768), 2), windowHeight = _b[0], setWindowHeight = _b[1];
    var _c = __read(useState(bottomLimit), 2), positionY = _c[0], setPositionY = _c[1];
    var startPosition = useRef(bottomLimit);
    var draggableKey = useRef(1);
    var bookmarkLoading = useSelector(makeSelectorBookmarkLoading());
    var addPlaceToBookmarkCallback = useAction(function (payload, placeName) {
        return addPlaceToBookmark(payload, placeName);
    });
    var removePlaceFromBookmarkCallback = useAction(function (payload, placeName) {
        return removePlaceFromBookmark(payload, placeName);
    });
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var _d = __read(useState(), 2), placeForBookmarking = _d[0], setPlaceForBookmarking = _d[1];
    var handleWindowResize = function () {
        setWindowHeight(window.innerHeight);
        draggableKey.current += 1;
    };
    var handleStart = function (event, currentPosition) {
        startPosition.current = currentPosition.y;
    };
    var handleStop = function (event, currentPosition) {
        if (currentPosition.y < startPosition.current) {
            setPositionY(0);
        }
        else {
            setPositionY(bottomLimit);
        }
    };
    useEffect(function () {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
            window.addEventListener('resize', handleWindowResize);
        }
    }, []);
    var placesToShow = positionY !== bottomLimit ? places : places.slice(0, 2);
    return (React.createElement(Draggable, { key: draggableKey.current, axis: "y", defaultPosition: { x: 0, y: bottomLimit }, position: { x: 0, y: positionY }, bounds: { top: 0, bottom: bottomLimit, left: 0, right: 0 }, handle: ".handler", onStart: handleStart, onStop: handleStop },
        React.createElement(PlacesListContainer, { vh: windowHeight + "px" },
            React.createElement(Stick, { className: "handler" },
                React.createElement("div", null)),
            React.createElement(PlacesWrapper, { vh: windowHeight + "px" },
                React.createElement(List, { vh: windowHeight + "px" }, map(placesToShow, function (place) { return (React.createElement(Link, { key: place.googlePlaceId, to: paths.placeDetailsPage
                        .replace(':placeId', place.googlePlaceId)
                        .replace(':placeName', place.slug) },
                    React.createElement(CardPlaces, { place: place, placeStyle: { paddingRight: 0, paddingLeft: 0 }, imageHeight: 180, showCategoryIcon: true, bookmarkButton: React.createElement("div", null, isAuthenticated && (React.createElement(BookmarkButton, { loading: bookmarkLoading &&
                                placeForBookmarking === place.googlePlaceId, addToBookmark: function () {
                                setPlaceForBookmarking(place.googlePlaceId);
                                addPlaceToBookmarkCallback(place.googlePlaceId, place.name);
                            }, removeFromBookmark: function () {
                                setPlaceForBookmarking(place.googlePlaceId);
                                removePlaceFromBookmarkCallback(place.googlePlaceId, place.name);
                            }, inBookmark: !!(place === null || place === void 0 ? void 0 : place.isFavorite) }))) }))); })),
                React.createElement(PaginationWrapper, null,
                    React.createElement(Pagination, { hideOnSinglePage: true, showLessItems: true, current: currentPage, defaultCurrent: 1, pageSize: perPage, showSizeChanger: false, total: total, onChange: handlePageChange, disabled: loading })),
                places.length > 0 && (React.createElement(AddPlaceToMap, { title: formatMessage({
                        id: "pages.explorePlacesPage.addPlaceNoResults.title",
                    }), tagline: formatMessage({
                        id: "pages.explorePlacesPage.addPlaceNoResults.tagline",
                    }) }))))));
});
