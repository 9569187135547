var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var ReaderLeftHandLight = memo(function (props) { return (React.createElement("svg", __assign({ width: "51", height: "31", viewBox: "0 0 51 31", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { opacity: ".3", fillRule: "evenodd", clipRule: "evenodd", d: "M28.98 19.446s1.265-1.677 4.247-1.476c2.982.2 5.534.2 5.959 4.453.426 4.252-4.47 2.905-4.47 2.905s-5.311-2.905-5.737-5.882z", fill: "#585858" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.34 17.677L31.94 22.7a8.469 8.469 0 006.273 2.789h.003a5.238 5.238 0 005.19-5.232V9.14l.002-.046a.807.807 0 00-.806-.882l-.04.001a.812.812 0 00-.77.76V14.356a.55.55 0 01-1.099 0V9.04v-.004-2.628a1.002 1.002 0 00-1.018-.984l-.049.001a.98.98 0 00-.935 1.033v7.897a.55.55 0 01-1.099 0V6.531c0-.606-.49-1.1-1.096-1.106a1.146 1.146 0 00-1.109 1.198V8.01l-.001.007v6.34a.55.55 0 01-1.099 0V8.022l.001-.006v-.007c0-.551-.449-1-1.016-1h-.002c-.276.013-.53.132-.716.336a1.046 1.046 0 00-.269.77v9.305h2.594a4.271 4.271 0 013.235 1.508.55.55 0 11-.838.711 3.174 3.174 0 00-2.4-1.12h-3.14c-.025 0-.05-.004-.073-.008-.01 0-.02 0-.029-.002a.546.546 0 01-.13-.043.53.53 0 01-.128-.085l-.017-.011-1.22-1.149a2.025 2.025 0 00-2.813.036c-.11.112-.11.295.013.42zm-.792-1.195a3.123 3.123 0 014.344-.063l.295.279.001-8.558a2.118 2.118 0 01.555-1.535c.384-.421.909-.667 1.475-.693l.07-.002c.375 0 .728.098 1.033.272a2.254 2.254 0 012.122-1.854l.044-.002c.639 0 1.215.273 1.618.708a2.059 2.059 0 011.549-.708h.04c.548 0 1.064.209 1.459.59.403.39.63.913.64 1.473v.904c.22-.103.462-.166.719-.178.506-.02.998.151 1.375.495a1.9 1.9 0 01.62 1.55v11.095c.004 3.468-2.814 6.308-6.285 6.332h-.005a9.572 9.572 0 01-7.089-3.148l-4.587-5.006a1.4 1.4 0 01.007-1.951z", fill: "#585858" }),
    React.createElement("g", { opacity: ".3", fillRule: "evenodd", clipRule: "evenodd", fill: "#585858" },
        React.createElement("path", { opacity: ".3", d: "M21.66 19.446s-1.265-1.677-4.248-1.476c-2.981.2-5.533.2-5.958 4.453-.426 4.252 4.47 2.905 4.47 2.905s5.31-2.905 5.736-5.882z" }),
        React.createElement("path", { d: "M23.3 17.677L18.698 22.7a8.47 8.47 0 01-6.273 2.789h-.003a5.238 5.238 0 01-5.19-5.232V9.14c0-.015 0-.03-.002-.046a.806.806 0 01.806-.882l.04.001c.416.02.745.352.77.76V14.356a.55.55 0 001.1 0V9.04v-.004-2.628c.009-.546.456-.984 1-.984h.017l.049.001a.98.98 0 01.935 1.033v7.897a.55.55 0 001.1 0V6.531c0-.606.49-1.1 1.095-1.106h.001a1.146 1.146 0 011.109 1.198v7.733a.55.55 0 001.1 0V8.022l-.002-.006.001-.007c0-.551.448-1 1.016-1h.002c.275.013.53.132.716.336.186.204.281.469.268.77v9.305h-2.594a4.271 4.271 0 00-3.234 1.508.55.55 0 10.838.711 3.174 3.174 0 012.4-1.12h3.14c.024 0 .049-.004.073-.008.01 0 .019 0 .028-.002a.543.543 0 00.13-.043.532.532 0 00.128-.085l.017-.011 1.221-1.149a2.026 2.026 0 012.813.036c.11.112.11.295-.013.42zm.792-1.195a3.123 3.123 0 00-4.344-.063l-.296.279V8.14a2.118 2.118 0 00-.556-1.535 2.117 2.117 0 00-1.474-.693l-.07-.002a2.09 2.09 0 00-1.034.272 2.254 2.254 0 00-2.122-1.854l-.043-.002c-.639 0-1.215.273-1.619.708l-.038-.043a2.058 2.058 0 00-1.51-.665h-.041c-.547 0-1.064.209-1.458.59-.404.39-.631.913-.64 1.473v.904a1.922 1.922 0 00-2.094.317 1.9 1.9 0 00-.62 1.55v11.095c-.004 3.468 2.814 6.308 6.285 6.332h.004a9.572 9.572 0 007.09-3.148l4.586-5.006a1.4 1.4 0 00-.006-1.951z" })))); });
