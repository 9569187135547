import React, { memo } from 'react';
export var FireStationOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#b3b3b3", style: { fill: '#b3b3b3' }, d: "M15.848 30.171c8.247 0 14.933-6.686 14.933-14.933s-6.686-14.933-14.933-14.933c-8.247 0-14.933 6.686-14.933 14.933s6.686 14.933 14.933 14.933z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M9.356 19.961c0.817 0 1.479-0.662 1.479-1.479s-0.662-1.479-1.479-1.479c-0.817 0-1.479 0.662-1.479 1.479s0.662 1.479 1.479 1.479z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M9.356 17.003c0.817 0 1.479 0.662 1.479 1.479s-0.662 1.479-1.479 1.479c-0.817 0-1.479-0.662-1.479-1.479s0.662-1.479 1.479-1.479z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M16.789 19.961c0.817 0 1.479-0.662 1.479-1.479s-0.662-1.479-1.479-1.479c-0.817 0-1.479 0.662-1.479 1.479s0.662 1.479 1.479 1.479z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M16.789 17.003c0.817 0 1.479 0.662 1.479 1.479s-0.662 1.479-1.479 1.479c-0.817 0-1.479-0.662-1.479-1.479s0.662-1.479 1.479-1.479z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M21.421 19.961c0.817 0 1.479-0.662 1.479-1.479s-0.662-1.479-1.479-1.479c-0.817 0-1.479 0.662-1.479 1.479s0.662 1.479 1.479 1.479z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M21.421 17.003c0.817 0 1.479 0.662 1.479 1.479s-0.662 1.479-1.479 1.479c-0.817 0-1.479-0.662-1.479-1.479s0.662-1.479 1.479-1.479z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M24.039 14.277h-3.084v-0.937h-8.297v0.937h-0.433v-2.383h-2.783v-0.508c0-0.302-0.245-0.546-0.546-0.546s-0.547 0.245-0.547 0.546v0.508h-0.669l-1.022 2.383v3.222h-0.9v0.983h1.535c0-1.137 0.925-2.062 2.063-2.062s2.062 0.925 2.062 2.062h3.309c0-1.137 0.925-2.062 2.062-2.062s2.062 0.925 2.062 2.062h0.508c0-1.137 0.925-2.062 2.063-2.062s2.062 0.925 2.062 2.062h1.455v-0.983h-0.9v-3.222h-0zM9.402 14.277h-2.097l0.777-1.758h1.32v1.758zM11.534 14.277h-1.532v-1.758h1.532v1.758z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M25.633 11.030v-0.56h-12.975v0.56h0.862v1.442h-0.862v0.56h12.975v-0.56h-0.914v-1.442h0.914zM15.040 12.472h-0.959v-1.442h0.959v1.442zM16.559 12.472h-0.959v-1.442h0.959v1.442zM18.079 12.472h-0.959v-1.442h0.959v1.442zM19.599 12.472h-0.959v-1.442h0.959v1.442zM21.119 12.472h-0.959v-1.442h0.959v1.442zM22.639 12.472h-0.959v-1.442h0.959v1.442zM24.159 12.472h-0.959v-1.442h0.959v1.442z" })));
});
