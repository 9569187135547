var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { Checkbox } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var CheckboxWrapper = styled(Checkbox)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.ant-checkbox-wrapper {\n    font-size: ", " !important;\n    display: flex;\n\n    span {\n      padding: 0;\n    }\n\n    span:last-child {\n      color: ", " !important;\n    }\n\n    .ant-checkbox + span {\n      margin-left: ", ";\n    }\n\n    .ant-checkbox-input,\n    .ant-checkbox-inner {\n      border-radius: ", " !important;\n      height: ", ";\n      width: ", ";\n    }\n\n    .ant-checkbox-input {\n      &:focus + span,\n      &:hover + span {\n        border-color: ", " !important;\n      }\n    }\n\n    .ant-checkbox-checked {\n      .ant-checkbox-inner {\n        background-color: ", " !important;\n        border-color: ", " !important;\n\n        &:after {\n          left: ", ";\n        }\n      }\n\n      &:after {\n        border: 0;\n        height: ", ";\n        width: ", ";\n      }\n    }\n  }\n"], ["\n  &.ant-checkbox-wrapper {\n    font-size: ", " !important;\n    display: flex;\n\n    span {\n      padding: 0;\n    }\n\n    span:last-child {\n      color: ", " !important;\n    }\n\n    .ant-checkbox + span {\n      margin-left: ", ";\n    }\n\n    .ant-checkbox-input,\n    .ant-checkbox-inner {\n      border-radius: ", " !important;\n      height: ", ";\n      width: ", ";\n    }\n\n    .ant-checkbox-input {\n      &:focus + span,\n      &:hover + span {\n        border-color: ", " !important;\n      }\n    }\n\n    .ant-checkbox-checked {\n      .ant-checkbox-inner {\n        background-color: ", " !important;\n        border-color: ", " !important;\n\n        &:after {\n          left: ", ";\n        }\n      }\n\n      &:after {\n        border: 0;\n        height: ", ";\n        width: ", ";\n      }\n    }\n  }\n"])), rem(13), color, rem(12), rem(4), rem(20), rem(20), colors.base.primary, colors.base.primary, colors.base.primary, rem(4), rem(20), rem(20));
var templateObject_1;
