export var GET_CONVERSATIONS = 'Chat/GET_CONVERSATIONS';
export var SHOW_CONVERSATIONS = 'Chat/SHOW_CONVERSATIONS';
export var GET_CONVERSATION = 'Chat/GET_CONVERSATION';
export var SHOW_CONVERSATION = 'Chat/SHOW_CONVERSATION';
export var LOCK_CONVERSATION_NOTIFICATION = 'Chat/LOCK_CONVERSATION_NOTIFICATION';
export var UNLOCK_CONVERSATION_NOTIFICATION = 'Chat/UNLOCK_CONVERSATION_NOTIFICATION';
export var OPTIONS_CHANGED_SUCCESS = 'Chat/OPTIONS_CHANGED_SUCCESS';
export var QUIT_GROUP_CONVERSATION = 'Chat/QUIT_GROUP_CONVERSATION';
export var GET_HISTORY = 'Chat/GET_HISTORY';
export var SHOW_HISTORY = 'Chat/SHOW_HISTORY';
export var SET_CHAT_CONNECTION = 'Chat/SET_CHAT_CONNECTION';
export var ADD_NEW_MESSAGE = 'Chat/ADD_NEW_MESSAGE';
export var BLOCK_USER = 'Chat/BLOCK_USER';
export var UNBLOCK_USER = 'Chat/UNBLOCK_USER';
export var BLOCK_USER_SUCCESS = 'Chat/BLOCK_USER_SUCCESS';
export var UNBLOCK_USER_SUCCESS = 'Chat/UNBLOCK_USER_SUCCESS';
