var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var User = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M25.8956 4.78658C24.3986 3.17867 22.3079 2.29321 20.0001 2.29321C17.6801 2.29321 15.5824 3.17331 14.0924 4.77127C12.5862 6.38684 11.8524 8.5825 12.0247 10.9534C12.3662 15.631 15.944 19.4361 20.0001 19.4361C24.0563 19.4361 27.6279 15.6317 27.9748 10.9549C28.1495 8.60546 27.411 6.41439 25.8956 4.78658Z", fill: color }),
        React.createElement("path", { d: "M33.5381 36.5787H6.46191C5.74419 36.5881 5.06044 36.2718 4.60042 35.7175C4.10044 35.1162 3.8989 34.2952 4.04813 33.465C4.69734 29.842 6.72344 26.7987 9.90797 24.6622C12.7371 22.7656 16.3209 21.7218 20 21.7218C23.6791 21.7218 27.2629 22.7664 30.092 24.6622C33.2766 26.7979 35.3027 29.8413 35.9519 33.4642C36.1011 34.2945 35.8996 35.1155 35.3996 35.7167C34.9397 36.2713 34.2559 36.5879 33.5381 36.5787Z", fill: color })));
});
