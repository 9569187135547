/**
 * SignupEmail Messages
 *
 * This contains all the text for the SignupEmail container.
 */
import { defineMessages } from 'react-intl';
export var scope = 'containers.signUpEmail';
var messages = defineMessages({
    header: {
        id: scope + ".header",
    },
    selectAnOption: {
        id: scope + ".selectAnOption",
    },
    emailLabel: { id: scope + ".emailLabel" },
    emailPlaceholder: { id: scope + ".emailPlaceholder" },
    emailRequired: { id: scope + ".emailRequired" },
    pseudoLabel: { id: scope + ".pseudoLabel" },
    pseudoPlaceholder: { id: scope + ".pseudoPlaceholder" },
    pseudoRequired: { id: scope + ".pseudoRequired" },
    profileIdRequired: { id: scope + ".profileIdRequired" },
    passwordRequired: { id: scope + ".passwordRequired" },
    passwordStrengthRegex: { id: scope + ".passwordStrengthRegex" },
    googlePlaceIdRequired: { id: scope + ".googlePlaceIdRequired" },
    acceptsNewsletterLabel: { id: scope + ".acceptsNewsletterLabel" },
    termsAndCoditions: { id: scope + ".termsAndCoditions" },
    termsAndCoditionsLink: { id: scope + ".termsAndCoditionsLink" },
    registrationButton: { id: scope + ".registrationButton" },
});
export default messages;
