var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { switchProp } from 'styled-tools';
import { colors } from 'styles';
var textColor = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  align-items: center;\n\n  ", "\n\n  > span {\n    font-size: ", ";\n    color: ", ";\n  }\n"], ["\n  height: 100%;\n  display: flex;\n  align-items: center;\n\n  ",
    "\n\n  > span {\n    font-size: ", ";\n    color: ", ";\n  }\n"])), switchProp('align', {
    start: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      justify-content: flex-start;\n    "], ["\n      justify-content: flex-start;\n    "]))),
    center: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      justify-content: center;\n    "], ["\n      justify-content: center;\n    "]))),
    end: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      justify-content: flex-end;\n    "], ["\n      justify-content: flex-end;\n    "]))),
}), rem(16), textColor);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
