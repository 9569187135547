var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Block = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M27.2 15.7333H26.1333V10.4C26.1333 6.86538 23.268 4 19.7333 4C16.1987 4 13.3333 6.86538 13.3333 10.4V15.7333H12.2667C9.91132 15.7359 8.00257 17.6447 8 20V31.7333C8.00257 34.0887 9.91132 35.9974 12.2667 36H27.2C29.5553 35.9974 31.4641 34.0887 31.4667 31.7333V20C31.4641 17.6447 29.5553 15.7359 27.2 15.7333ZM24 15.7333H15.4667V10.4C15.4667 8.04359 17.3769 6.13333 19.7333 6.13333C22.0897 6.13333 24 8.04359 24 10.4V15.7333Z", fill: color })));
});
