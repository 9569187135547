var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import rootAxios from 'axios';
import { configInterceptors } from './interceptor';
import { requestToSnakeCase, responseToCamelCase } from './utils';
var _a = rootAxios.defaults, transformRequest = _a.transformRequest, transformResponse = _a.transformResponse;
var baseURL = process.env.RAZZLE_APP_API_BASE_URL;
var axios = rootAxios.create({
    baseURL: baseURL,
    transformRequest: __spread([requestToSnakeCase], transformRequest),
    transformResponse: __spread([responseToCamelCase], transformResponse),
    withCredentials: true,
});
var blogAxios = rootAxios.create({
    baseURL: 'https://blog.jaccede.com/wp-json/wp',
});
configInterceptors(axios);
var disableTransform = { headers: { disableTransform: true } };
export { axios, blogAxios, disableTransform };
export { default as app } from './app';
export { default as auth } from './auth';
export { default as aboutus } from './aboutus';
export { default as oauth } from './oauth';
export { default as subscribers } from './subscribers';
export { default as users } from './users';
export { default as myProfile } from './editMyProfile';
export { default as home } from './home';
export { default as places } from './places';
export { default as newsletter } from './newsletter';
export { default as challenge } from './challenge';
export { default as services } from './services';
export { default as chat } from './chat';
