var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { breakpoints, colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var color = theme('mode', {
    light: colors.space.tint700,
    dark: colors.space.tint300,
});
export var SubFooterContainer = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  max-width: ", ";\n  padding: ", " ", ";\n  margin: 0 auto;\n  display: flex;\n\n  .ant-row {\n    width: 100%;\n\n    > .ant-col {\n      display: flex;\n      flex-direction: column;\n      margin: 0 auto;\n      width: 100%;\n\n      &:first-of-type {\n        margin-right: auto;\n      }\n\n      &:last-of-type {\n        margin-left: auto;\n      }\n\n      ul {\n        list-style: none;\n      }\n\n      a {\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  border-bottom: ", " solid ", ";\n  max-width: ", ";\n  padding: ", " ", ";\n  margin: 0 auto;\n  display: flex;\n\n  .ant-row {\n    width: 100%;\n\n    > .ant-col {\n      display: flex;\n      flex-direction: column;\n      margin: 0 auto;\n      width: 100%;\n\n      &:first-of-type {\n        margin-right: auto;\n      }\n\n      &:last-of-type {\n        margin-left: auto;\n      }\n\n      ul {\n        list-style: none;\n      }\n\n      a {\n        color: ", ";\n      }\n    }\n  }\n"])), rem(1), border, breakpoints.large, rem(55), rem(16), color);
export var Title = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  margin-bottom: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"])), rem(24), rem(14), fonts.weight.semibold);
var templateObject_1, templateObject_2;
