import { CardHeader, Pagination } from 'components';
import React, { memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { ListMyChallenges } from './ListMyChallenges';
import { Container, PaginationWrapper, Title } from './styles';
var PER_PAGE = 5;
var MyChallenge = function (_a) {
    var _b = _a.perPage, perPage = _b === void 0 ? PER_PAGE : _b, challenges = _a.challenges, loadMyChallenges = _a.loadMyChallenges;
    var params = useParams();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var handleMyChallengePage = function (page) {
        if (loadMyChallenges) {
            loadMyChallenges({ page: page, perPage: perPage });
        }
    };
    useEffect(function () {
        if (loadMyChallenges) {
            loadMyChallenges({
                page: 1,
                perPage: perPage,
            });
        }
    }, [params.userSlug, perPage]);
    return (React.createElement(Container, null,
        isMobile || isTabletOrMobile ? (React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "containers.profile.myActiveChallenges.header" }))) : (React.createElement(CardHeader, { title: React.createElement(FormattedMessage, { id: "containers.profile.myActiveChallenges.header" }) })),
        React.createElement(ListMyChallenges, { showMobile: isMobile || isTabletOrMobile, challenges: challenges }),
        React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { hideOnSinglePage: true, showSizeChanger: false, defaultCurrent: 1, pageSize: perPage || 5, total: challenges.total || 0, onChange: handleMyChallengePage, disabled: challenges.loading, current: challenges.currentPage || 1, showLessItems: isMobile || isTabletOrMobile }))));
};
export default memo(MyChallenge);
