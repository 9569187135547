var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var textColor = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  display: flex;\n  padding-bottom: ", ";\n"], ["\n  border-bottom: ", " solid ", ";\n  display: flex;\n  padding-bottom: ", ";\n"])), rem(1), borderColor, rem(20));
export var UserData = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  padding-left: ", ";\n"], ["\n  flex: 1;\n  padding-left: ", ";\n"])), rem(20));
export var UserName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: ", ";\n"])), rem(8));
export var Fullname = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: ", ";\n"])), rem(24), fonts.weight.bold, rem(6));
export var Slug = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n\n  ", ";\n"], ["\n  font-size: ", ";\n\n  ",
    ";\n"])), rem(16), ifProp('hasNotFirstName', css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      font-size: ", ";\n      font-weight: ", ";\n    "], ["\n      font-size: ", ";\n      font-weight: ", ";\n    "])), rem(24), fonts.weight.bold)));
export var Message = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n  padding-right: ", ";\n  white-space: pre-wrap;\n"], ["\n  font-size: ", ";\n  padding-right: ", ";\n  white-space: pre-wrap;\n"])), rem(16), rem(96));
export var UserPoints = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  margin-bottom: ", ";\n  margin-top: ", ";\n\n  > span {\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    padding: 0 ", ";\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  margin-bottom: ", ";\n  margin-top: ", ";\n\n  > span {\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    padding: 0 ", ";\n  }\n"])), rem(20), rem(10), textColor, rem(17), fonts.weight.bold, rem(5));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
