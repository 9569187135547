var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint400,
    dark: colors.carbon.tint200,
});
export var TermsSelectorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n  margin-top: ", ";\n"], ["\n  width: 100%;\n  position: relative;\n  margin-top: ", ";\n"])), rem(16));
export var SwitchWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  margin: ", " 0;\n\n  > div {\n    margin-bottom: 0;\n\n    & + button {\n      margin-left: ", ";\n    }\n\n    > label {\n      font-size: ", ";\n      color: ", ";\n      white-space: normal;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  margin: ", " 0;\n\n  > div {\n    margin-bottom: 0;\n\n    & + button {\n      margin-left: ", ";\n    }\n\n    > label {\n      font-size: ", ";\n      color: ", ";\n      white-space: normal;\n    }\n  }\n"])), rem(20), rem(16), rem(14), colors.space.tint750);
export var Scrollbar = styled(ScrollContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", ";\n  max-height: 250px;\n"], ["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", ";\n  max-height: 250px;\n"])), rem(1), border, rem(10), rem(8));
export var TermButtonsList = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row wrap;\n  list-style: none;\n"], ["\n  display: flex;\n  flex-flow: row wrap;\n  list-style: none;\n"])));
export var TermButtonListItem = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: ", ";\n  margin-bottom: ", ";\n  max-width: 100%;\n\n  overflow: hidden;\n\n  > button {\n    width: 100%;\n    overflow: hidden;\n\n    > svg {\n      flex-shrink: 0;\n    }\n\n    > span {\n      display: block;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n    }\n  }\n"], ["\n  margin-right: ", ";\n  margin-bottom: ", ";\n  max-width: 100%;\n\n  overflow: hidden;\n\n  > button {\n    width: 100%;\n    overflow: hidden;\n\n    > svg {\n      flex-shrink: 0;\n    }\n\n    > span {\n      display: block;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n    }\n  }\n"])), rem(10), rem(10));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
