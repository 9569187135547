import produce from 'immer';
import assign from 'lodash/assign';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
export var flattenMessages = function (nestedMessages, prefix) {
    if (prefix === void 0) { prefix = ''; }
    return reduce(keys(nestedMessages), function (messages, key) {
        var value = nestedMessages[key];
        var prefixedKey = prefix ? prefix + "." + key : key;
        if (isString(value)) {
            return produce(messages, function (draft) {
                draft[prefixedKey] = value;
            });
        }
        return assign(messages, flattenMessages(value, prefixedKey));
    }, {});
};
