var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var MedalPhotoDisabledMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, _b = _a.progress, progress = _b === void 0 ? 0 : _b, props = __rest(_a, ["size", "dark", "progress"]);
    var strokeDasharraySize = 190;
    var strokeDasharray = (strokeDasharraySize * progress) / 100;
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0 0.406221046 21.8064516 0.406221046 21.8064516 20.1058081 0 20.1058081" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-7-gray", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: dark ? colors.carbon.tint100 : colors.space.tint200, strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round", strokeDasharray: strokeDasharray + ", " + strokeDasharraySize }),
                React.createElement("rect", { id: "active-bg", fill: "#E0E0E0", x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/7/disabled", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-22", strokeWidth: "1", transform: "translate(0.000000, 5.088021)" },
                        React.createElement("g", { id: "Group-3", transform: "translate(4.193548, 0.004103)" },
                            React.createElement("mask", { id: "mask-2", fill: "white" },
                                React.createElement("use", { xlinkHref: "#path-1" })),
                            React.createElement("g", { id: "Clip-2" }),
                            React.createElement("path", { d: "M19.2903226,3.69283653 L17.6129032,3.69283653 C16.2206452,3.69283653 15.0967742,2.5890642 15.0967742,1.2308908 C15.0967742,0.775430841 14.7193548,0.406138981 14.2580645,0.406138981 L14.2580645,0.410242224 L8.38709677,0.410242224 C7.92580645,0.410242224 7.5483871,0.775430841 7.5483871,1.22678756 L7.5483871,1.2308908 C7.5483871,2.5890642 6.42451613,3.69283653 5.03225806,3.69283653 L2.52032258,3.69283653 L0,3.69283653 C1.39225806,3.69283653 2.51612903,4.79250563 2.51612903,6.15478226 L2.51612903,17.6397591 C2.51612903,19.0020357 1.39225806,20.1017048 0,20.1017048 L0,20.1058081 L19.2903226,20.1058081 C20.6741935,20.1058081 21.8064516,18.9979325 21.8064516,17.6438623 L21.8064516,6.15478226 C21.8064516,4.80071211 20.6741935,3.69283653 19.2903226,3.69283653", id: "Fill-1", fill: "#E8D62E", mask: "url(#mask-2)" })),
                        React.createElement("path", { d: "M20.5483871,12.7208736 C20.5483871,10.0045268 18.2964516,7.79698213 15.516129,7.79698213 C12.74,7.79698213 10.483871,10.0045268 10.483871,12.7208736 C10.483871,15.4413236 12.74,17.6447651 15.516129,17.6447651 C18.2964516,17.6447651 20.5483871,15.4413236 20.5483871,12.7208736 M22.0203226,11.0914758 C22.1545161,11.612998 22.2258065,12.1587293 22.2258065,12.7208736 C22.2258065,13.2830179 22.1545161,13.8328524 22.0203226,14.3535539 C21.273871,17.1893051 18.6487097,19.2860622 15.516129,19.2860622 C11.8132258,19.2860622 8.80645161,16.3481403 8.80645161,12.7208736 C8.80645161,9.09771013 11.8132258,6.15568498 15.516129,6.15568498 C18.6487097,6.15568498 21.273871,8.25203177 22.0203226,11.0914758", id: "Fill-4", fill: "#D8B832" }),
                        React.createElement("path", { d: "M6.29443226,2.46596691 C6.52507742,2.46596691 6.7137871,2.65061284 6.7137871,2.8762912 L6.7137871,3.6973501 C6.7137871,3.92302846 6.52507742,4.10767439 6.29443226,4.10767439 L4.61281935,4.10767439 L3.77410968,4.10767439 L2.9354,4.10767439 C2.70475484,4.10767439 2.51604516,3.92302846 2.51604516,3.6973501 L2.51604516,2.8762912 C2.51604516,2.65061284 2.70475484,2.46596691 2.9354,2.46596691 L6.29443226,2.46596691 Z", id: "Fill-6", fill: "#F8E831" }),
                        React.createElement("path", { d: "M22.2258065,10.2621284 C23.1525806,10.2621284 23.9032258,10.9966089 23.9032258,11.9034255 C23.9032258,12.8102422 23.1525806,13.5447227 22.2258065,13.5447227 C22.1545161,13.5447227 22.0874194,13.5406195 22.0203226,13.532413 C22.1545161,13.0113011 22.2258065,12.4618769 22.2258065,11.8997326 C22.2258065,11.3375884 22.1545161,10.791857 22.0203226,10.2703349 C22.0874194,10.2621284 22.1545161,10.2621284 22.2258065,10.2621284", id: "Fill-8", fill: "#AAB2BD" }),
                        React.createElement("path", { d: "M15.516129,6.97555394 C18.2964516,6.97555394 20.5483871,9.18309861 20.5483871,11.8994454 C20.5483871,14.6198954 18.2964516,16.8233369 15.516129,16.8233369 C12.74,16.8233369 10.483871,14.6198954 10.483871,11.8994454 C10.483871,9.18309861 12.74,6.97555394 15.516129,6.97555394", id: "Fill-10", fill: "#E2F0F4" }),
                        React.createElement("path", { d: "M15.516129,6.97555394 C15.2292903,6.97555394 14.9504194,7.00427664 14.677,7.04982263 C17.0564194,7.44209265 18.8692903,9.46376042 18.8692903,11.8994454 C18.8692903,14.3392336 17.0564194,16.3576188 14.677,16.7490682 C14.9504194,16.7942038 15.2292903,16.8233369 15.516129,16.8233369 C18.2964516,16.8233369 20.5483871,14.6198954 20.5483871,11.8994454 C20.5483871,9.18309861 18.2964516,6.97555394 15.516129,6.97555394", id: "Fill-12", fill: "#DBDBDB" }),
                        React.createElement("path", { d: "M20.5483871,11.8994454 C20.5483871,9.18309861 18.2964516,6.97555394 15.516129,6.97555394 C12.74,6.97555394 10.483871,9.18309861 10.483871,11.8994454 C10.483871,14.6198954 12.74,16.8233369 15.516129,16.8233369 C18.2964516,16.8233369 20.5483871,14.6198954 20.5483871,11.8994454 M22.0203226,10.2700477 C22.1545161,10.7915698 22.2258065,11.3373011 22.2258065,11.8994454 C22.2258065,12.4615897 22.1545161,13.0114242 22.0203226,13.5321257 C21.273871,16.3678769 18.6487097,18.464634 15.516129,18.464634 C11.8132258,18.464634 8.80645161,15.5267121 8.80645161,11.8994454 C8.80645161,8.27628193 11.8132258,5.33425678 15.516129,5.33425678 C18.6487097,5.33425678 21.273871,7.43060357 22.0203226,10.2700477", id: "Fill-14", fill: "#CEAF2E" }),
                        React.createElement("path", { d: "M6.70967742,6.15888551 L6.70967742,17.6438623 C6.70967742,19.006139 5.58580645,20.1058081 4.19354839,20.1058081 L4.19354839,20.1099113 L2.51612903,20.1099113 C1.13225806,20.1099113 0,19.0020357 0,17.6479656 L0,6.15888551 C0,4.80481535 1.13225806,3.69693977 2.51612903,3.69693977 L4.19354839,3.69693977 C5.58580645,3.69693977 6.70967742,4.79660887 6.70967742,6.15888551", id: "Fill-16", fill: "#D1B12A" }),
                        React.createElement("path", { d: "M17.6133226,2.46358703 L13.4189355,2.46358703 C13.1870323,2.46358703 12.9995806,2.28017207 12.9995806,2.05326274 C12.9995806,1.82635341 13.1870323,1.64293845 13.4189355,1.64293845 L17.6133226,1.64293845 C17.8452258,1.64293845 18.0326774,1.82635341 18.0326774,2.05326274 C18.0326774,2.28017207 17.8452258,2.46358703 17.6133226,2.46358703", id: "Fill-18", fill: "#D8B832" }),
                        React.createElement("path", { d: "M16.3548387,3.69497022 L14.6774194,3.69497022 C14.4455161,3.69497022 14.2580645,3.51155526 14.2580645,3.28464593 C14.2580645,3.0577366 14.4455161,2.87432164 14.6774194,2.87432164 L16.3548387,2.87432164 C16.5867419,2.87432164 16.7741935,3.0577366 16.7741935,3.28464593 C16.7741935,3.51155526 16.5867419,3.69497022 16.3548387,3.69497022", id: "Fill-20", fill: "#D8B832" })),
                    React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", style: { mixBlendMode: 'color' }, x: "0", y: "0", width: "26", height: "31" })),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: dark ? colors.space.tint500 : colors.space.tint200 })))));
});
