import { Avatar } from 'components';
import React from 'react';
import { GlobalScoreboardUserContainer, PointsBadge, PositionBadge, UserInfo, UserName, UserSlug, UserWrapper, } from './styles';
export var GlobalScoreboardUser = function (_a) {
    var position = _a.position, avatar = _a.avatar, firstName = _a.firstName, lastName = _a.lastName, slug = _a.slug, points = _a.points, _b = _a.pointsVariant, pointsVariant = _b === void 0 ? 'ghost' : _b, _c = _a.pageURL, pageURL = _c === void 0 ? '#' : _c;
    return (React.createElement(GlobalScoreboardUserContainer, { to: pageURL, 
        // @ts-ignore
        showPosition: position && position > 0 },
        position && React.createElement(PositionBadge, null,
            position,
            "\u00B0"),
        React.createElement(UserWrapper, null,
            React.createElement(Avatar, { src: avatar, size: "small", alt: (firstName || slug) + "'s avatar" }),
            React.createElement(UserInfo, null,
                firstName ? (React.createElement(UserName, null, (firstName || '') + " " + (lastName || ''))) : (React.createElement(UserName, null, "@" + slug)),
                firstName && React.createElement(UserSlug, null,
                    "(@",
                    slug,
                    ")"))),
        React.createElement(PointsBadge, { variant: pointsVariant }, points)));
};
