import { SIGN_UP_NEWSLETTER_FAILURE, SIGN_UP_NEWSLETTER_REQUEST, SIGN_UP_NEWSLETTER_SUCCESS, } from './constants';
export var signUpNewsletter = function (payload, actions) { return ({
    type: SIGN_UP_NEWSLETTER_REQUEST,
    payload: payload,
    actions: actions,
}); };
export var signUpNewsletterSuccess = function () { return ({
    type: SIGN_UP_NEWSLETTER_SUCCESS,
}); };
export var signUpNewsletterError = function (error) { return ({
    type: SIGN_UP_NEWSLETTER_FAILURE,
    payload: error,
}); };
