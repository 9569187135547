import { makeSelectDarkMode } from 'containers/App/selectors';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'utils/hooks';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { UserProfileContainer } from './styles';
var UserProfile = function (_a) {
    var _b = _a.contributionsStats, contributionsStats = _b === void 0 ? [] : _b, _c = _a.followersCount, followersCount = _c === void 0 ? 0 : _c, _d = _a.followingCount, followingCount = _d === void 0 ? 0 : _d, handleToggleFollow = _a.handleToggleFollow, isFollowed = _a.isFollowed, showEditMyProfileButton = _a.showEditMyProfileButton, showSendMessageButton = _a.showSendMessageButton, showSubscribeButton = _a.showSubscribeButton, subscribersLink = _a.subscribersLink, subscriptionsLink = _a.subscriptionsLink, totalPoints = _a.totalPoints, user = _a.user, loading = _a.loading;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var isDarkMode = useSelector(makeSelectDarkMode());
    return (React.createElement(UserProfileContainer, null, isMobile || isTabletOrMobile ? (React.createElement(Mobile, { contributionsStats: contributionsStats, darkMode: isDarkMode, handleToggleSubscribe: handleToggleFollow, isSubscribed: isFollowed || false, loading: loading, showEditMyProfileButton: showEditMyProfileButton, showSendMessageButton: showSendMessageButton, showSubscribeButton: showSubscribeButton || false, subscribersLink: subscribersLink, subscribersSize: followersCount, subscriptionsLink: subscriptionsLink, subscriptionsSize: followingCount, totalPoints: totalPoints, user: user })) : (React.createElement(Desktop, { loading: loading, showEditMyProfileButton: showEditMyProfileButton, showSendMessageButton: showSendMessageButton, subscribersLink: subscribersLink, subscribersSize: followersCount, subscriptionsLink: subscriptionsLink, subscriptionsSize: followingCount, totalPoints: totalPoints, user: user, contributionsStats: contributionsStats }))));
};
export default memo(UserProfile);
