var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
export var Target = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: ", ";\n  font-weight: ", ";\n  justify-content: center;\n  padding: ", ";\n  width: ", ";\n  height: ", ";\n\n  &:hover,\n  &:active {\n    color: ", " !important;\n  }\n"], ["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: ", ";\n  font-weight: ", ";\n  justify-content: center;\n  padding: ", ";\n  width: ", ";\n  height: ", ";\n\n  &:hover,\n  &:active {\n    color: ", " !important;\n  }\n"])), colors.base.white, rem(16), fonts.weight.bold, rem(8), rem(38), rem(38), colors.base.white);
export var NotificationIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n\n  ", "\n"], ["\n  position: relative;\n  display: flex;\n\n  ",
    "\n"])), ifProp('hasNotifications', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      &::before {\n        background: ", ";\n        content: '';\n        width: ", ";\n        height: ", ";\n        border-radius: 50%;\n        position: absolute;\n        top: ", ";\n        right: ", ";\n        z-index: 1;\n      }\n    "], ["\n      &::before {\n        background: ", ";\n        content: '';\n        width: ", ";\n        height: ", ";\n        border-radius: 50%;\n        position: absolute;\n        top: ", ";\n        right: ", ";\n        z-index: 1;\n      }\n    "])), colors.base.danger, rem(13), rem(13), rem(-2), rem(-4))));
export var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n  font-weight: ", ";\n"])), colors.space.tint900, rem(16), fonts.weight.regular);
export var NotificationContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  overflow: auto;\n  max-height: ", ";\n\n  ::-webkit-scrollbar {\n    width: ", ";\n    background-color: ", ";\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: ", ";\n  }\n"], ["\n  overflow: auto;\n  max-height: ", ";\n\n  ::-webkit-scrollbar {\n    width: ", ";\n    background-color: ", ";\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: ", ";\n  }\n"])), rem(200), rem(4), colors.base.white, colors.space.tint500, rem(4));
export var LoadingWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(32));
export var Title = styled.h4(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), rem(15), fonts.weight.semibold, rem(4));
export var ListContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", " 0;\n  margin-right: ", ";\n  cursor: pointer;\n\n  &:not(:last-child) {\n    border-bottom: ", " solid ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", " 0;\n  margin-right: ", ";\n  cursor: pointer;\n\n  &:not(:last-child) {\n    border-bottom: ", " solid ", ";\n  }\n"])), rem(8), rem(16), rem(1), colors.space.tint300);
export var InfoContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var Message = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1.1;\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1.1;\n  margin-bottom: ", ";\n"])), rem(13), fonts.weight.semibold, rem(4));
export var SentDate = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), rem(10));
export var ReadBadge = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  display: flex;\n  flex-shrink: 0;\n  margin-left: ", ";\n"], ["\n  background: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  display: flex;\n  flex-shrink: 0;\n  margin-left: ", ";\n"])), colors.base.primary, rem(10), rem(10), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
