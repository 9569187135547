var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var Medal10000Desktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M7 6H40V34C40 40.6274 34.6274 46 28 46H7V6Z", fill: "black", fillOpacity: "0.15" }),
        React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "21", y: "27", width: "19", height: "18" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.3394 27.705H40V44.22H21.3394V27.705Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30.1885 34.7475C31.5235 43.5867 23.0802 44.1875 21.3394 44.2183H25.9485V44.22C33.7152 44.22 40.0002 37.9358 40.0002 30.1692V30.1675V27.705C40.0002 35.4717 30.1885 34.7475 30.1885 34.7475Z", fill: "#8EC26E" })),
        React.createElement("mask", { id: "mask1", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "7", y: "4", width: "33", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.17981 4.8335H39.9998V44.2188H7.17981V4.8335Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask1)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.17981 4.833V44.2188H21.0248H21.339C23.0806 44.188 31.5231 43.5872 30.1881 34.7472C30.1881 34.7472 39.9998 35.4722 39.9998 27.7047V4.833H7.17981Z", fill: "#BCE2A5" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.2822 21.2254V31.1929L7.17975 29.2287V21.2254H11.2822Z", fill: "#773BB3" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.07709 21.2254V31.1929L7.17959 29.2287V21.2254H3.07709Z", fill: "#8F6BB3" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.744 17.18C13.744 20.8058 10.8048 23.745 7.17983 23.745C3.55399 23.745 0.615662 20.8058 0.615662 17.18C0.615662 13.555 3.55399 10.6158 7.17983 10.6158C10.8048 10.6158 13.744 13.555 13.744 17.18Z", fill: "#F8E831" }),
        React.createElement("mask", { id: "mask2", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "10", width: "15", height: "15" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 10.0003H14.359V24.3603H0V10.0003Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask2)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.17985 11.2311C3.89985 11.2311 1.23068 13.8994 1.23068 17.1803C1.23068 20.4603 3.89985 23.1294 7.17985 23.1294C10.4598 23.1294 13.1282 20.4603 13.1282 17.1803C13.1282 13.8994 10.4598 11.2311 7.17985 11.2311ZM7.17985 24.3603C3.22151 24.3603 -0.000152588 21.1394 -0.000152588 17.1803C-0.000152588 13.2211 3.22151 10.0003 7.17985 10.0003C11.1382 10.0003 14.359 13.2211 14.359 17.1803C14.359 21.1394 11.1382 24.3603 7.17985 24.3603Z", fill: "#F7D131" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.18009 20.0517C5.59342 20.0517 4.30759 18.7658 4.30759 17.18C4.30759 15.5942 5.59342 14.3083 7.18009 14.3083C8.76592 14.3083 10.0518 15.5942 10.0518 17.18C10.0518 18.7658 8.76592 20.0517 7.18009 20.0517Z", fill: "#F7D131" }),
        React.createElement("mask", { id: "mask3", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "4", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 44.2202H40V4.8335H0V44.2202Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask3)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.4875 11.1927H27.6925V9.961H19.4875V11.1927Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.9492 16.116H34.2575V14.8852H25.9492V16.116Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.0258 16.116H23.4875V14.8852H17.0258V16.116Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.4358 21.0402H34.2575V19.8085H29.4358V21.0402Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.0267 21.0402H26.975V19.8085H17.0267V21.0402Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.1458 25.9635H34.2575V24.7327H23.1458V25.9635Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.3842 25.9635H20.6833V24.7327H15.3842V25.9635Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.3842 30.8868H27.6925V29.656H15.3842V30.8868Z", fill: "#8BB074" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.1025 35.8102H21.9483V34.5793H12.1025V35.8102Z", fill: "#8BB074" }))));
});
