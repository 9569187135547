/* eslint-disable lodash/prefer-lodash-method */
import { Button, EmptyListText, List, ListItem, LoadingIndicator, Pagination, UserReview, } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import map from 'lodash/map';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useAction, useSelector } from 'utils/hooks';
import { fetchPlaceReviews } from '../store/placeReviews/actions';
import makePlaceReviews from '../store/placeReviews/selectors';
import { ButtonWrapper, Container, PaginationContainer, Title } from './styles';
var PER_PAGE = 5;
export var ReviewsList = function (_a) {
    var place = _a.place, placeId = _a.placeId, showPagination = _a.showPagination, showMoreReviewButton = _a.showMoreReviewButton;
    var _b, _c;
    var history = useHistory();
    var reviews = useSelector(makePlaceReviews());
    var fetchPlaceReviewsRequest = useAction(function (payload) {
        return fetchPlaceReviews(payload);
    });
    var loadPlaceReviews = function (page) {
        var payload = {
            page: page,
            perPage: PER_PAGE,
            placeId: placeId,
        };
        fetchPlaceReviewsRequest(payload);
    };
    var handlePageChange = function (page) {
        loadPlaceReviews(page);
    };
    var handleMoreReviews = function () {
        return history.push(paths.reviewsPage.replace(':placeId', placeId));
    };
    useEffect(function () {
        if (place.id) {
            loadPlaceReviews(1);
        }
    }, [place]);
    return (React.createElement(Container, null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "containers.placeDetails.reviews" })),
        reviews.loading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(List, null, reviews.items && ((_b = reviews.items) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (map(reviews.items, function (review) { return (React.createElement(ListItem, { key: review.id },
            React.createElement(UserReview, { review: review, placeId: placeId, clickable: true, rows: 3 }))); })) : (React.createElement(EmptyListText, null,
            React.createElement(FormattedMessage, { id: "containers.reviews.emptyReviews" }))))),
        showPagination && (React.createElement(PaginationContainer, null,
            React.createElement(Pagination, { hideOnSinglePage: true, showLessItems: true, current: reviews.currentPage, defaultCurrent: 1, pageSize: PER_PAGE, showSizeChanger: false, total: reviews.total, onChange: handlePageChange, disabled: reviews.loading }))),
        showMoreReviewButton && reviews.items && ((_c = reviews.items) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(ButtonWrapper, null,
            React.createElement(Button, { type: "button", size: "small", shape: "pill", variant: "ghost", onClick: handleMoreReviews },
                React.createElement(FormattedMessage, { id: "containers.placeDetails.moreReviews" }))))));
};
