import { paths } from 'containers/RoutesProvider/routes';
import { format } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { Container, Username } from './styles';
export var LastUpdatedInfo = function (_a) {
    var modifiedDate = _a.modifiedDate, slug = _a.slug, loggedUserSlug = _a.loggedUserSlug;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Container, null,
        formatMessage({
            id: 'containers.placeDetails.lastChange',
        }),
        ' ',
        React.createElement(Username, { to: loggedUserSlug === slug
                ? paths.profilePage
                : paths.usersPage.replace(':userSlug', slug) },
            React.createElement("b", null,
                "@",
                slug)),
        ' ',
        formatMessage({
            id: 'containers.placeDetails.lastChange2',
        }),
        ' ',
        React.createElement("b", null, format(new Date(modifiedDate), 'dd/MM/yyyy'))));
};
