/* eslint-disable lodash/prefer-lodash-method */
import { EmptyListText, LoadingIndicator } from 'components';
import { makeSelectIsLoggedIn, makeSelectUserSlug, } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import map from 'lodash/map';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { subscribeUserRequest } from '../store/subscribe/actions';
import { unsubscribeUserRequest } from '../store/unsubscribe/actions';
import { SubscribeButton, UserItem } from '../styles';
var SubscribersList = function (_a) {
    var subscribers = _a.subscribers, slug = _a.slug, loading = _a.loading;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var history = useHistory();
    var location = useLocation();
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var loggedUserSlug = useSelector(makeSelectUserSlug());
    var subscribeUserRequestCallback = useAction(function (payload) { return subscribeUserRequest(payload); });
    var unSubscribeUserRequestCallback = useAction(function (payload) { return unsubscribeUserRequest(payload); });
    function handleUserClicked(userSlug) {
        var route = userSlug === loggedUserSlug
            ? paths.profilePage
            : paths.usersPage.replace(':userSlug', userSlug);
        history.push(route, { from: location.pathname });
    }
    function handleToggleSubscribe(isSubscribed, userId) {
        if (isSubscribed) {
            unSubscribeUserRequestCallback({
                from: 'subscribers',
                id: userId,
                userSlug: slug,
            });
        }
        else {
            subscribeUserRequestCallback({
                from: 'subscribers',
                id: userId,
                userSlug: slug,
            });
        }
    }
    return (React.createElement(React.Fragment, null, loading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(React.Fragment, null, (subscribers === null || subscribers === void 0 ? void 0 : subscribers.length) > 0 ? (React.createElement(React.Fragment, null, map(subscribers, function (user) { return (React.createElement("div", { key: user.id },
        React.createElement(UserItem, { user: user, size: "small", onClick: function () { return handleUserClicked(user.slug); } }, isAuthenticated && user.slug !== loggedUserSlug && (React.createElement(SubscribeButton, { size: isMobile || isTabletOrMobile ? 'mini' : 'small', isSubscribed: user.isFollowed, onClick: function () {
                return handleToggleSubscribe(user.isFollowed, user.id);
            } }))))); }))) : (React.createElement(EmptyListText, { align: "center" },
        React.createElement(FormattedMessage, { id: "containers.subscribers." + (slug === loggedUserSlug
                ? 'loggedUserNoSubscribers'
                : 'noSubscribers') })))))));
};
export default SubscribersList;
