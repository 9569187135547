import { Button } from 'components';
import React from 'react';
import { useIntl } from 'react-intl';
import { Content, JoinTeamContainer, Title } from './styles';
export var JoinTeamSection = function (_a) {
    var onClick = _a.onClick;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(JoinTeamContainer, null,
        React.createElement(Content, null,
            React.createElement(Title, null, formatMessage({
                id: 'containers.challengesMembersTeam.joinTeamSection.title',
            })),
            React.createElement(Button, { onClick: onClick }, formatMessage({
                id: 'containers.challengesMembersTeam.joinTeamSection.buttonText',
            })))));
};
