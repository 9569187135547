var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var CaretDown = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M6.91221 13.6197L18.8447 27.5446C19.2339 27.9986 19.802 28.2599 20.4 28.2599C20.998 28.2599 21.5662 27.9986 21.9554 27.5446L33.8878 13.6197C35.0266 12.2906 34.0825 10.2375 32.3325 10.2375H8.46417C6.71417 10.2375 5.77002 12.2906 6.91221 13.6197Z", fill: color })));
});
