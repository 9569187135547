var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, PlacesAutocomplete, Spin } from 'components';
import { fieldBlur, fieldOnFocus } from 'containers/App/actions';
import { Map } from 'containers/Map';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAction } from 'utils/hooks';
import { Template } from '../Template';
import { SuccessFeedbackType } from '../types';
import messages from './messages';
import { ButtonWrapper, CheckboxWrapper, Container, MapContainer, StyledCheckbox, } from './styles';
export var EditLocation = function (_a) {
    var onSubmit = _a.onSubmit, onBack = _a.onBack, profile = _a.profile;
    var formatMessage = useIntl().formatMessage;
    var fieldOnFocusActionCallback = useAction(fieldOnFocus);
    var fieldBlurActionCallback = useAction(fieldBlur);
    var _b = __read(useState(), 2), lastUpdate = _b[0], setLastUpdate = _b[1];
    var _c = __read(useState(), 2), currentPlaceId = _c[0], setCurrentPlaceId = _c[1];
    var _d = __read(useState(), 2), mapLoading = _d[0], setMapLoading = _d[1];
    useEffect(function () {
        window.scroll(0, 0);
        if (profile === null || profile === void 0 ? void 0 : profile.googlePlaceId)
            setCurrentPlaceId(profile === null || profile === void 0 ? void 0 : profile.googlePlaceId);
        return function () {
            setCurrentPlaceId(undefined);
        };
    }, []);
    var handleSearch = function () {
        setLastUpdate('search');
    };
    var handleSelect = function (placeId) {
        setCurrentPlaceId(placeId);
        setLastUpdate('selectedValue');
    };
    var validate = function () {
        if (lastUpdate === 'search') {
            return { googlePlaceId: formatMessage(messages.invalidAddress) };
        }
    };
    return (React.createElement(Template, { onBack: onBack, pageTitle: formatMessage(messages.title), fieldDescription: formatMessage(messages.fieldDescription), fieldInformation: formatMessage(messages.fieldInformation) },
        React.createElement(Formik, { initialValues: {
                googlePlaceId: profile === null || profile === void 0 ? void 0 : profile.googlePlaceId,
                acceptsLocalization: profile === null || profile === void 0 ? void 0 : profile.acceptsLocalization,
            }, enableReinitialize: true, onSubmit: function (values) {
                return onSubmit(values, SuccessFeedbackType.Location);
            }, validate: validate }, function (_a) {
            var isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, setFieldValue = _a.setFieldValue, values = _a.values;
            var _b;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Container, null,
                    React.createElement(PlacesAutocomplete
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        name: "googlePlaceId", required: true, label: formatMessage(messages.myAddress), currentPlace: (_b = profile === null || profile === void 0 ? void 0 : profile.address) === null || _b === void 0 ? void 0 : _b.fullAddress, onFocus: fieldOnFocusActionCallback, onBlur: fieldBlurActionCallback, onSearch: handleSearch, onSelect: handleSelect }),
                    React.createElement(Spin, { spinning: mapLoading },
                        React.createElement(MapContainer, null,
                            React.createElement(Map, { placeId: currentPlaceId, loadingCallback: setMapLoading }))),
                    React.createElement(CheckboxWrapper, null,
                        React.createElement(StyledCheckbox, { name: "acceptsLocalization", checked: values.acceptsLocalization, onChange: function () {
                                return setFieldValue('acceptsLocalization', !values.acceptsLocalization);
                            } }, formatMessage(messages.shareLocalization))),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { type: "submit", disabled: isSubmitting }, formatMessage(messages.updateMyAddress))))));
        })));
};
