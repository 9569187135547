var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { fonts } from 'styles';
import { Section } from '../Section';
import { Subtitle } from '../Subtitle';
export var StyledSection = styled(Section)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-left: 0;\n    padding-right: 0;\n  "], ["\n    padding-left: 0;\n    padding-right: 0;\n  "]))));
export var StyledSubtitle = styled(Subtitle)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0 ", ";\n"], ["\n  padding: 0 ", ";\n"])), rem(20));
export var ButtonWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(24));
export var Note = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n  text-align: center;\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n  text-align: center;\n"])), fonts.weight.semibold, rem(16), rem(16));
export var LoadingWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(32));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
