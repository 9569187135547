/**
 * OAuthLoginPage
 * It is an entrypoint when facebook redirects after login or account created
 * The flow is:
 * When I received a token as fragment I dispatch the action oAuthLoginRequest,
 * that will check token. Any error the saga will redirect the user to oAuthErrorPage,
 * and if there is success will redirect to oauthSuccessPage
 */
import OAuthError from 'containers/OAuth/Error';
import { OAuthSuccess } from 'containers/OAuth/Success';
import get from 'lodash/get';
import Qs from 'qs';
import React, { memo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
export var OAuthPage = function () {
    var location = useLocation();
    var params = useParams();
    var parsedSearch = Qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    var parseWithoutFragment = location.hash.slice(1);
    var parsedFragment = Qs.parse(parseWithoutFragment, {
        ignoreQueryPrefix: true,
    });
    var accessToken = get(parsedFragment, 'access_token', '');
    var state = get(parsedFragment, 'state', '{ "next": "/" }');
    if (get(parsedSearch, 'success', 'false') === 'false') {
        return React.createElement(OAuthError, null);
    }
    return (React.createElement(OAuthSuccess, { provider: params.provider, accessToken: accessToken, state: state }));
};
export default memo(OAuthPage);
