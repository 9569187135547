import React, { memo } from 'react';
export var MealDelivery = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M28.038 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#ff6900", style: { fill: '#ff6900' }, d: "M7.347 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.758 4.759-12.474 0-17.232s-12.474-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M12.071 19.151c-0.971 0-1.899 0.789-2.071 1.76s0.479 1.76 1.45 1.76c0.97 0 1.899-0.79 2.070-1.76s-0.479-1.76-1.449-1.76z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M18.616 19.151c-0.971 0-1.899 0.789-2.070 1.76s0.479 1.76 1.449 1.76c0.971 0 1.899-0.79 2.070-1.76s-0.479-1.76-1.449-1.76z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M18.766 18.299c1.103 0 1.925 0.688 2.136 1.657l0.868-0.020 1.234-6.5-1.626-2.685 0.591-3.354-8.956-0.213-0.723 3.568-2.362 2.683-1.237 6.518 0.805 0.010c0.552-0.973 1.619-1.665 2.725-1.665 1.115 0 1.943 0.704 2.142 1.691h1.666c0.547-0.986 1.621-1.691 2.736-1.691zM15.016 9.471c0.082-0.502 0.524-0.905 0.988-0.898l2.871 0.043c0.464 0.007 0.779 0.39 0.704 0.856s-0.514 0.848-0.981 0.854l-2.886 0.043c-0.467 0.007-0.778-0.394-0.696-0.898z" })));
});
