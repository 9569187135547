import produce from 'immer';
import { APP_FIELD_BLUR, APP_FIELD_ON_FOCUS, APP_KEYBOARD_DISABLED, APP_KEYBOARD_ENABLED, APP_SET_DARK_MODE, APP_SET_DIRECTION, APP_SET_FONT_SIZE, APP_TOGGLE_DARK_MODE, APP_TOGGLE_DIRECTION, APP_TOOGLE_FONT_SIZE, } from './constants';
export var initialState = {
    darkMode: false,
    isLtr: false,
    language: 'en',
    font: 'sixteen',
    isFieldOnFocus: false,
    useKeyboard: false,
};
var appReducer = produce(function (draft, action) {
    switch (action.type) {
        case APP_TOGGLE_DIRECTION:
            draft.isLtr = !draft.isLtr;
            window.localStorage.setItem('is-ltr', String(draft.isLtr));
            break;
        case APP_TOGGLE_DARK_MODE:
            draft.darkMode = !draft.darkMode;
            window.localStorage.setItem('dark-mode', String(draft.darkMode));
            break;
        case APP_TOOGLE_FONT_SIZE:
            if (draft.font === 'sixteen') {
                draft.font = 'twentyFour';
            }
            else if (draft.font === 'twentyFour') {
                draft.font = 'eight';
            }
            else {
                draft.font = 'sixteen';
            }
            window.localStorage.setItem('font', String(draft.font));
            break;
        case APP_SET_DIRECTION:
            draft.isLtr = action.payload;
            window.localStorage.setItem('is-ltr', String(action.payload));
            break;
        case APP_SET_DARK_MODE:
            draft.darkMode = action.payload;
            window.localStorage.setItem('dark-mode', String(action.payload));
            break;
        case APP_SET_FONT_SIZE:
            draft.font = action.payload;
            window.localStorage.setItem('font', String(action.payload));
            break;
        case APP_KEYBOARD_ENABLED:
            draft.useKeyboard = true;
            break;
        case APP_KEYBOARD_DISABLED:
            draft.useKeyboard = false;
            break;
        case APP_FIELD_ON_FOCUS:
            draft.isFieldOnFocus = true;
            break;
        case APP_FIELD_BLUR:
            draft.isFieldOnFocus = false;
            break;
        default:
            break;
    }
}, initialState);
export default appReducer;
