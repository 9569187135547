import { PageHeader, PageWrapper } from 'components';
import { makeSelectIsLoggedIn, makeSelectUser, } from 'containers/Auth/selectors';
import { fetchContributorUserAccountRequest } from 'containers/Contributors/actions';
import { makeSelectContributorUser } from 'containers/Contributors/selectors/contributorUserAccountSelectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import Subscribers from 'containers/Subscribers';
import { useAction, useQuery, useSelector } from 'hooks';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Container, UserProfileCard } from './styles';
var SubscribersProfilePage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var history = useHistory();
    var params = useParams();
    var queryParams = useQuery();
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var loggedUser = useSelector(makeSelectUser());
    var contributorUser = useSelector(makeSelectContributorUser());
    var isLoggedUser = (loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.slug) === params.slug;
    var fetchContributorUserAccount = useAction(function (payload) {
        return fetchContributorUserAccountRequest(payload);
    });
    var handleBackButton = function () { return history.goBack(); };
    useEffect(function () {
        if ((loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.slug) !== params.slug) {
            fetchContributorUserAccount(params.slug);
        }
    }, [params.slug]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.subscribersProfilePage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.subscribersProfilePage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.subscribersProfilePage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.subscribersProfilePage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(PageHeader, { onBack: handleBackButton, title: React.createElement(FormattedMessage, { id: "pages.subscribersProfilePage.title" }), sticky: true }),
            React.createElement(Container, null,
                isAuthenticated && isLoggedUser && (React.createElement(UserProfileCard, { user: loggedUser, hasUsernameParentheses: !!(loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.firstName) })),
                !isLoggedUser && contributorUser && (React.createElement(UserProfileCard, { user: contributorUser, hasUsernameParentheses: !!(contributorUser === null || contributorUser === void 0 ? void 0 : contributorUser.firstName) })),
                React.createElement(Subscribers, { activeTab: queryParams.get('tab') || 'subscriptions', userSlug: params.slug, showSubscribeButton: isLoggedUser })))));
};
export default SubscribersProfilePage;
