export var ADD_REVIEW_REQUEST = 'AddReview/ADD_REVIEW_REQUEST';
export var ADD_REVIEW_FAILURE = 'AddReview/ADD_REVIEW_FAILURE';
export var ADD_REVIEW_SUCCESS = 'AddReview/ADD_REVIEW_SUCCESS';
export var ADD_REVIEW_CLEAR_FAILURE = 'AddReview/ADD_REVIEW_CLEAR_FAILURE';
export var ADD_REVIEW_CLEAR_SUCCESS = 'AddReview/ADD_REVIEW_CLEAR_SUCCESS';
export var RESET_ADD_REVIEW_STATE = 'AddReview/RESET_ADD_REVIEW_STATE';
export var REPORT_REVIEW = 'AddReview/REPORT_REVIEW';
export var REPORT_REVIEW_FAILURE = 'AddReview/REPORT_REVIEW_FAILURE';
export var REPORT_REVIEW_SUCCESS = 'AddReview/REPORT_REVIEW_SUCCESS';
export var CLEAR_REPORTED = 'AddReview/CLEAR_REPORTED';
export var SET_REPORT_REVIEW_VISIBLE = 'AddReview/SET_REPORT_REVIEW_VISIBLE';
export var ACTIVE_REVIEW = 'AddReview/ACTIVE_REVIEW';
