import { EmptyListText } from 'components';
import { putNotificationsRequest } from 'containers/Profile/actions';
import { format } from 'date-fns';
import { useAction } from 'hooks';
import { map } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoContainer, ListContainer, Message, ReadBadge, SentDate, } from './styles';
export var NotificationsList = function (_a) {
    var notifications = _a.notifications;
    var putNotifications = useAction(function (payload) {
        return putNotificationsRequest(payload);
    });
    var handleClick = function (id) {
        var payload = { read: true, ids: [id] };
        putNotifications(payload);
    };
    return (React.createElement(React.Fragment, null, notifications.length > 0 ? (React.createElement(React.Fragment, null, map(notifications, function (item) { return (React.createElement(ListContainer, { key: item.id, onClick: function () { return handleClick(item.id); } },
        React.createElement(InfoContainer, null,
            React.createElement(Message, null, item.body),
            React.createElement(SentDate, null, format(new Date(item.created), 'dd MMMM HH:mm'))),
        !item.read && React.createElement(ReadBadge, null))); }))) : (React.createElement(EmptyListText, { align: "center" },
        React.createElement(FormattedMessage, { id: "components.notificationMenu.empty" })))));
};
