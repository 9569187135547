import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectMapStateDomain = function (state) { return state.map || initialState; };
var makeSelectLocation = function () {
    return createSelector(selectMapStateDomain, function (_a) {
        var location = _a.location;
        return location;
    });
};
var makeSelectLoading = function () {
    return createSelector(selectMapStateDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export { selectMapStateDomain, makeSelectLocation, makeSelectLoading };
