import { LoadingIndicator, PageWrapper } from 'components';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { PlaceDetails } from 'containers/PlaceDetails';
import { getPlaceDetails } from 'containers/PlaceDetails/actions';
import makeSelectorPlaceDetails, { makeSelectorLoading, } from 'containers/PlaceDetails/selectors';
import { useAction, useSelector } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
var PlaceDetailsPage = function () {
    var _a, _b;
    var formatMessage = useIntl().formatMessage;
    var placeId = useParams().placeId;
    var locale = useSelector(makeSelectLocale());
    var placeDetails = useSelector(makeSelectorPlaceDetails());
    var placeDetailIsLoading = useSelector(makeSelectorLoading());
    var getPlaceDetailsRequest = useAction(getPlaceDetails);
    useEffect(function () {
        getPlaceDetailsRequest(placeId);
    }, [placeId]);
    if (placeDetailIsLoading || isEmpty(placeDetails)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageWrapper, null,
                React.createElement(LoadingIndicator, null))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.name),
            React.createElement("meta", { name: "description", content: ((_a = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.category) === null || _a === void 0 ? void 0 : _a.label) + " - " + (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.name) }),
            React.createElement("meta", { property: "og:title", content: placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.name }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: ((_b = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.category) === null || _b === void 0 ? void 0 : _b.label) + " - " + (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.name) }),
            (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.mainImage) && (React.createElement("meta", { property: "og:image:secure_url", content: placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.mainImage.urlLow })),
            (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.mainImage) && (React.createElement("meta", { property: "og:image:type", content: "image/jpeg" })),
            (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.mainImage) && (React.createElement("meta", { property: "og:image:width", content: "800" })),
            (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.mainImage) && (React.createElement("meta", { property: "og:image:height", content: "600" })),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(PlaceDetails, { place: placeDetails }))));
};
export default PlaceDetailsPage;
