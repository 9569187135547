var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Search = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M37.4482 34.3864L29.2872 26.2254C31.252 23.6098 32.3127 20.426 32.3091 17.1546C32.3091 8.79839 25.5107 2 17.1546 2C8.79839 2 2 8.79839 2 17.1546C2 25.5107 8.79839 32.3091 17.1546 32.3091C20.426 32.3127 23.6098 31.252 26.2254 29.2872L34.3864 37.4482C35.2442 38.215 36.5514 38.1784 37.3649 37.3649C38.1784 36.5514 38.215 35.2442 37.4482 34.3864ZM6.32987 17.1546C6.32987 11.1762 11.1762 6.32987 17.1546 6.32987C23.1329 6.32987 27.9792 11.1762 27.9792 17.1546C27.9792 23.1329 23.1329 27.9792 17.1546 27.9792C11.1792 27.9721 6.33704 23.1299 6.32987 17.1546Z", fill: color })));
});
