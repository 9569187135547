import { createSelector } from 'reselect';
import { contributorUserFavoritesInitialState } from '../reducers/contributorUserFavoritesReducer';
export var selectContributorUserFavoritesDomain = function (state) {
    return state.contributorUserFavorites || contributorUserFavoritesInitialState;
};
export var makeSelectContributorUserFavoritesLoading = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectContributorUserFavoritesHasError = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectContributorUserFavoritesError = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectContributorUserFavoritesPages = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectContributorUserFavoritesCurrentPage = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectContributorUserFavoritesTotal = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectContributorUserFavoritesItems = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeContributorUserFavorites = function () {
    return createSelector(selectContributorUserFavoritesDomain, function (subState) { return subState; });
};
export default makeContributorUserFavorites;
