var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var CriteriaContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n\n  .ant-checkbox-wrapper,\n  .ant-radio-wrapper {\n    display: flex;\n  }\n\n  .ant-input {\n    margin-top: ", ";\n    margin-bottom: ", ";\n  }\n\n  label + div {\n    border: ", " solid ", ";\n    border-radius: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n    padding: ", ";\n    padding-bottom: ", ";\n  }\n\n  div + div {\n    margin-top: ", ";\n    margin-bottom: ", ";\n  }\n"], ["\n  margin-bottom: ", ";\n\n  .ant-checkbox-wrapper,\n  .ant-radio-wrapper {\n    display: flex;\n  }\n\n  .ant-input {\n    margin-top: ", ";\n    margin-bottom: ", ";\n  }\n\n  label + div {\n    border: ", " solid ", ";\n    border-radius: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n    padding: ", ";\n    padding-bottom: ", ";\n  }\n\n  div + div {\n    margin-top: ", ";\n    margin-bottom: ", ";\n  }\n"])), rem(8), rem(4), rem(8), rem(1), border, rem(5), rem(8), rem(16), rem(16), rem(4), rem(8), rem(12));
export var ChildContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: ", ";\n"], ["\n  margin-left: ", ";\n"])), rem(16));
export var CriteriaTitle = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n"])), color, rem(18), rem(12));
export var CriteriaPopover = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n\n  span + div {\n    margin-top: ", ";\n    margin-left: ", ";\n  }\n"], ["\n  display: flex;\n\n  span + div {\n    margin-top: ", ";\n    margin-left: ", ";\n  }\n"])), rem(1), rem(8));
export var CriteriaLabel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n\n  > span {\n    margin-top: ", ";\n  }\n\n  > div {\n    margin-left: ", ";\n  }\n"], ["\n  cursor: pointer;\n  display: flex;\n\n  > span {\n    margin-top: ", ";\n  }\n\n  > div {\n    margin-left: ", ";\n  }\n"])), rem(1), rem(8));
export var PopoverTrigger = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  margin-top: ", ";\n"], ["\n  cursor: pointer;\n  display: flex;\n  margin-top: ", ";\n"])), rem(-2));
export var ButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: ", ";\n"])), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
