import { Medal200Desktop, Medal1000Desktop, Medal5000Desktop, Medal10000Desktop, Medal25000Desktop, MedalCommentDesktop, MedalCriterionDesktop, MedalPhotoDesktop, MedalPlaceDesktop, } from 'icons/medal';
import map from 'lodash/map';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { calculatePercentage, getGoal, getPercentage, getTotalPoints, } from 'utils/achievements';
import CardAchievement from './CardAchievement';
import { Container, Title } from './styles';
var MEDAL_SIZE = 60;
var NextAchievements = function (_a) {
    var _b = _a.totalPoints, totalPoints = _b === void 0 ? 0 : _b, _c = _a.contributionsStats, contributionsStats = _c === void 0 ? [] : _c;
    var medals = [
        {
            title: 'medal200',
            description: 'medal200',
            goal: 200,
            percent: calculatePercentage(totalPoints, 200),
            icon: function (p) { return React.createElement(Medal200Desktop, { size: MEDAL_SIZE }); },
            totalPoints: totalPoints,
        },
        {
            title: 'medal1000',
            description: 'medal1000',
            goal: 1000,
            percent: calculatePercentage(totalPoints, 1000),
            icon: function (p) { return React.createElement(Medal1000Desktop, { size: MEDAL_SIZE }); },
            totalPoints: totalPoints,
        },
        {
            title: 'medal5000',
            description: 'medal5000',
            goal: 5000,
            percent: calculatePercentage(totalPoints, 5000),
            icon: function (p) { return React.createElement(Medal5000Desktop, { size: MEDAL_SIZE }); },
            totalPoints: totalPoints,
        },
        {
            title: 'medal10000',
            description: 'medal10000',
            goal: 10000,
            percent: calculatePercentage(totalPoints, 10000),
            icon: function (p) { return React.createElement(Medal10000Desktop, { size: MEDAL_SIZE }); },
            totalPoints: totalPoints,
        },
        {
            title: 'medal25000',
            description: 'medal25000',
            goal: 25000,
            percent: calculatePercentage(totalPoints, 25000),
            icon: function (p) { return React.createElement(Medal25000Desktop, { size: MEDAL_SIZE }); },
            totalPoints: totalPoints,
        },
        {
            title: 'medalCriterion',
            description: 'medalCriterion',
            goal: getGoal(contributionsStats, 'criterion'),
            totalPoints: getTotalPoints(contributionsStats, 'criterion'),
            percent: getPercentage(contributionsStats, 'criterion'),
            icon: function (p) { return React.createElement(MedalCriterionDesktop, { size: MEDAL_SIZE }); },
        },
        {
            title: 'medalPhoto',
            description: 'medalPhoto',
            goal: getGoal(contributionsStats, 'photo'),
            totalPoints: getTotalPoints(contributionsStats, 'photo'),
            percent: getPercentage(contributionsStats, 'photo'),
            icon: function (p) { return React.createElement(MedalPhotoDesktop, { size: MEDAL_SIZE }); },
        },
        {
            title: 'medalComment',
            description: 'medalComment',
            goal: getGoal(contributionsStats, 'comment'),
            totalPoints: getTotalPoints(contributionsStats, 'comment'),
            percent: getPercentage(contributionsStats, 'comment'),
            icon: function (p) { return React.createElement(MedalCommentDesktop, { size: MEDAL_SIZE }); },
        },
        {
            title: 'medalPlace',
            description: 'medalPlace',
            goal: getGoal(contributionsStats, 'place'),
            totalPoints: getTotalPoints(contributionsStats, 'place'),
            percent: getPercentage(contributionsStats, 'place'),
            icon: function (p) { return React.createElement(MedalPlaceDesktop, { size: MEDAL_SIZE }); },
        },
    ];
    // Sorting the medals in ASC by percentage
    // Filtering by percent
    // Getting only 3 firsts items
    medals = medals
        .sort(function (a, b) { return b.percent - a.percent; })
        .filter(function (m) { return m.percent < 100; })
        .slice(0, 3);
    return (React.createElement(Container, null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "containers.profile.nextAchievements.header" })),
        map(medals, function (medal, key) { return (React.createElement(CardAchievement, { key: key, medal: medal.icon(medal.percent), percent: medal.percent, goalPoints: medal.goal, totalPoints: medal.totalPoints, title: React.createElement(FormattedMessage, { id: "containers.profile.nextAchievements.medals." + medal.title + ".title" }), description: React.createElement(FormattedMessage, { id: "containers.profile.nextAchievements.medals." + medal.title + ".description" }) })); })));
};
export default memo(NextAchievements);
