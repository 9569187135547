var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n  padding-bottom: ", ";\n"], ["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n  padding-bottom: ", ";\n"])), rem(1), border, rem(16), rem(24));
export var Title = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), rem(16), fonts.weight.semibold, rem(14));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n"])));
export var RateValue = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: -", ";\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: -", ";\n  text-align: center;\n"])), color, rem(48), rem(8));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
