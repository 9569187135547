var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { avatarImage1 } from 'assets/images';
import { Avatar, NotificationMenu } from 'components';
import { makeSelectDirection } from 'containers/App/selectors';
import { authLogoutRequest } from 'containers/Auth/actions';
import { makeSelectIsLoggedIn, makeSelectUser, } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Container, Header, NavButton, Navigation, NavLink } from './styles';
export var Drawer = memo(function (props) {
    var _a;
    var formatMessage = useIntl().formatMessage;
    var location = useLocation();
    var user = useSelector(makeSelectUser());
    var isLoggedIn = useSelector(makeSelectIsLoggedIn());
    var direction = useSelector(makeSelectDirection());
    var logout = useAction(authLogoutRequest);
    var handleLogout = function () { return logout(); };
    return (React.createElement(Container, __assign({}, props),
        React.createElement(Header, { isRtl: direction }, isLoggedIn && (React.createElement(React.Fragment, null,
            React.createElement(NotificationMenu, null),
            React.createElement(Avatar, { src: ((_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.urlThumb) || avatarImage1, alt: (user === null || user === void 0 ? void 0 : user.firstName) ? user.firstName + " profile image"
                    : 'User profile image', size: "small", style: { marginLeft: '0.5rem' } })))),
        React.createElement(Navigation, { isRtl: direction }, isLoggedIn ? (React.createElement(React.Fragment, null,
            React.createElement(NavButton, { onClick: handleLogout }, formatMessage({ id: 'components.nav.logout' })),
            React.createElement(NavLink, { to: {
                    pathname: paths.aboutPage,
                    state: { from: location.pathname },
                } }, formatMessage({ id: 'components.nav.aboutUs' })),
            React.createElement(NavLink, { to: {
                    pathname: paths.profilePage,
                    state: { from: location.pathname },
                } }, formatMessage({ id: 'components.nav.myProfile' })),
            React.createElement(NavLink, { to: {
                    pathname: paths.homePage,
                    state: { from: location.pathname },
                } }, formatMessage({ id: 'components.nav.homePage' })))) : (React.createElement(React.Fragment, null,
            React.createElement(NavLink, { to: {
                    pathname: paths.signInPage,
                    state: { from: location.pathname },
                } }, formatMessage({ id: 'components.nav.signIn' })),
            React.createElement(NavLink, { to: {
                    pathname: paths.aboutPage,
                    state: { from: location.pathname },
                } }, formatMessage({ id: 'components.nav.aboutUs' })),
            React.createElement(NavLink, { to: {
                    pathname: paths.homePage,
                    state: { from: location.pathname },
                } }, formatMessage({ id: 'components.nav.homePage' })))))));
});
