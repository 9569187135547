var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { EmailNotConfirmed, KeyboardMode } from 'components';
import ScrollToTop from 'components/ScrollToTop';
import { ConnectedRouter } from 'connected-react-router';
import RoutesProvider from 'containers/RoutesProvider';
import { useAction, useSelector } from 'hooks';
import includes from 'lodash/includes';
import split from 'lodash/split';
import trim from 'lodash/trim';
import { RootPage } from 'pages/RootPage';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Switch, useLocation } from 'react-router-dom';
import { history } from 'store';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles';
import { defaultLang, languages } from 'translations';
import { OneSignalService } from 'utils/onesignal';
import LanguageProvider from '../LanguageProvider';
import { changeLocale } from '../LanguageProvider/actions';
import { makeSelectLocale } from '../LanguageProvider/selectors';
import { initApp } from './actions';
import { makeSelectDarkMode, makeSelectFont } from './selectors';
var ServerConnectRouter = function (_a) {
    var children = _a.children;
    return React.createElement(React.Fragment, null, children);
};
var App = function () {
    var isClientBuild = process.env.BUILD_TARGET === 'client';
    var initAppCallback = useAction(initApp);
    var ConnectRouter = isClientBuild ? ConnectedRouter : ServerConnectRouter;
    var darkMode = useSelector(makeSelectDarkMode());
    var fontSize = useSelector(makeSelectFont());
    var locale = useSelector(makeSelectLocale());
    var changeLocaleCallback = useAction(changeLocale);
    var _a = __read(useState({
        mode: 'light',
        text: 'sixteen',
    }), 2), theme = _a[0], setTheme = _a[1];
    var location = useLocation();
    var getUrlLang = function () {
        return location.pathname && split(trim(location.pathname), '/')[1];
    };
    useEffect(function () {
        var oneSignal = OneSignalService.getInstance();
        oneSignal.init();
        initAppCallback();
    }, []);
    useEffect(function () {
        return setTheme({
            mode: darkMode ? 'dark' : 'light',
            text: fontSize,
        });
    }, [darkMode, fontSize]);
    useEffect(function () {
        var lang = getUrlLang();
        if (includes(languages(), lang) && locale !== lang) {
            changeLocaleCallback(lang);
        }
        if (!includes(languages(), lang) && locale !== lang) {
            changeLocaleCallback(defaultLang);
            var currentUrlLanguage = split(trim(location.pathname), '/')[1];
            var updatedUrlLanguage = location.pathname.replace("/" + currentUrlLanguage + "/", "/" + defaultLang + "/");
            history.push(updatedUrlLanguage);
        }
    }, [getUrlLang()]);
    return (React.createElement(LanguageProvider, null,
        React.createElement(ConnectRouter, { history: history },
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(KeyboardMode, null),
                React.createElement(ScrollToTop, null),
                React.createElement(GlobalStyle, null),
                React.createElement(EmailNotConfirmed, null),
                React.createElement(Switch, null,
                    React.createElement(Route, { key: "/", path: "/", exact: true, component: RootPage }),
                    React.createElement(Route, { path: "/" + locale, component: RoutesProvider }))))));
};
export default App;
