import { womanInWheelchair } from 'assets/svg';
import { Button } from 'components';
import { useAction } from 'hooks';
import { AddPlace } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { colors } from 'styles';
import { paths } from '../../RoutesProvider/routes';
import { clearValues } from '../AddNewPlace/actions';
import { Container, Content, Image, Tagline, Title } from './styles';
export var AddPlaceToMap = memo(function (_a) {
    var title = _a.title, tagline = _a.tagline, style = _a.style;
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var clearValuesCallback = useAction(clearValues);
    return (React.createElement(Container, { style: style },
        React.createElement(Content, null,
            React.createElement("div", null,
                React.createElement(Title, null, title),
                React.createElement(Tagline, null, tagline),
                React.createElement(Button, { size: "small", shape: "pill", variant: "ghost", icon: React.createElement(AddPlace, { size: rem(16), color: colors.base.primary }), onClick: function () {
                        clearValuesCallback();
                        history.push(paths.addPlace);
                    } }, formatMessage({ id: 'components.addPlaceToMap.button' }))),
            React.createElement(Image, { src: womanInWheelchair, alt: formatMessage({ id: 'components.addPlaceToMap.altImage' }) }))));
});
