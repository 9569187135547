var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { generateMedia } from 'styled-media-query';
import { ifProp } from 'styled-tools';
var media = generateMedia({
    medium: '768px',
    large: '880px',
});
export var AddNewPlaceContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 90;\n"], ["\n  z-index: 90;\n"])));
export var IconButtonWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  button {\n    position: fixed;\n    left: ", ";\n    right: ", ";\n    bottom: calc(", " + env(safe-area-inset-bottom, 0));\n\n    width: ", ";\n    height: ", ";\n\n    ", ";\n  }\n"], ["\n  button {\n    position: fixed;\n    left: ", ";\n    right: ", ";\n    bottom: calc(", " + env(safe-area-inset-bottom, 0));\n\n    width: ", ";\n    height: ", ";\n\n    ",
    ";\n  }\n"])), ifProp('isRtl', rem(16), ''), ifProp('isRtl', '', rem(60)), rem(24), rem(50), rem(50), media.lessThan('medium')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      ", ";\n    "], ["\n      ",
    ";\n    "])), ifProp('hasPlaceList', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          bottom: calc(", " + env(safe-area-inset-bottom, 0));\n        "], ["\n          bottom: calc(", " + env(safe-area-inset-bottom, 0));\n        "])), rem(134)), css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          bottom: calc(", " + env(safe-area-inset-bottom, 0));\n        "], ["\n          bottom: calc(", " + env(safe-area-inset-bottom, 0));\n        "])), rem(100)))));
export var ButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  button {\n    position: fixed;\n    bottom: ", ";\n    left: ", ";\n    right: ", ";\n\n    ", ";\n  }\n"], ["\n  button {\n    position: fixed;\n    bottom: ", ";\n    left: ", ";\n    right: ", ";\n\n    ",
    ";\n  }\n"])), rem(24), ifProp('isRtl', rem(16), ''), ifProp('isRtl', '', rem(60)), media.lessThan('large')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    "], ["\n      bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    "])), rem(96)));
export var ModalButtonWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(24));
export var SelectWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", ";\n"])), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
