import { createSelector } from 'reselect';
import { initialState } from './reducer';
export var selectAccountActivationDomain = function (state) {
    return state.accountActivation || initialState;
};
export var makeSelectLoading = function () {
    return createSelector(selectAccountActivationDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectVisible = function () {
    return createSelector(selectAccountActivationDomain, function (_a) {
        var visible = _a.visible;
        return visible;
    });
};
export var makeSelectError = function () {
    return createSelector(selectAccountActivationDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectHasError = function () {
    return createSelector(selectAccountActivationDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectAccountActivation = function () {
    return createSelector(selectAccountActivationDomain, function (subState) { return subState; });
};
export default makeSelectAccountActivation;
