import { LoadingIndicator } from 'components/LoadingIndicator';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { makeSelectUserSlug } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import map from 'lodash/map';
import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { CardMember } from '../CardMember';
import { Content, MembersListContainer } from './styles';
export var MembersList = function (_a) {
    var _b = _a.members, members = _b === void 0 ? [] : _b, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c;
    var history = useHistory();
    var location = useLocation();
    var loggedUserSlug = useSelector(makeSelectUserSlug());
    var keyboard = useSelector(makeSelectUseKeyboard());
    var handleRedirectUserProfile = function (slug) {
        var route = slug === loggedUserSlug
            ? paths.profilePage
            : paths.usersPage.replace(':userSlug', slug);
        history.push(route, { from: location.pathname });
    };
    return (React.createElement(React.Fragment, null, isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(MembersListContainer, null,
        React.createElement(Content, { isKeyboardMode: keyboard }, map(members, function (user) { return (React.createElement(CardMember, { onClick: function () { return handleRedirectUserProfile(user.slug); }, key: user.slug, user: user })); }))))));
};
