import produce from 'immer';
import { CONTRIBUTOR_USER_CONTRIBUTIONS_FAILURE, CONTRIBUTOR_USER_CONTRIBUTIONS_REQUEST, CONTRIBUTOR_USER_CONTRIBUTIONS_SUCCESS, } from '../constants';
export var contributorUserContributionsInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    pages: 1,
    currentPage: 1,
    total: 0,
    items: [],
};
export var contributorUserContributionsReducer = produce(function (draft, action) {
    switch (action.type) {
        case CONTRIBUTOR_USER_CONTRIBUTIONS_REQUEST:
            draft.loading = true;
            break;
        case CONTRIBUTOR_USER_CONTRIBUTIONS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case CONTRIBUTOR_USER_CONTRIBUTIONS_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.currentPage = action.payload.currentPage;
            draft.pages = action.payload.pages;
            draft.total = action.payload.total;
            draft.items = action.payload.items;
            break;
        default:
    }
}, contributorUserContributionsInitialState);
