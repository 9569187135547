var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal200DisabledMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, _b = _a.progress, progress = _b === void 0 ? 0 : _b, props = __rest(_a, ["size", "dark", "progress"]);
    var strokeDasharraySize = 190;
    var strokeDasharray = (strokeDasharraySize * progress) / 100;
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0 0.288807273 23.6768291 0.288807273 23.6768291 30.4360255 0 30.4360255" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-1-gray", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: dark ? colors.carbon.tint100 : colors.space.tint200, strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round", strokeDasharray: strokeDasharray + ", " + strokeDasharraySize }),
                React.createElement("rect", { id: "active-bg", fill: "#E0E0E0", x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/1/Disabled", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-15", strokeWidth: "1", transform: "translate(1.300000, 0.000000)" },
                        React.createElement("g", { id: "Group-3", transform: "translate(0.000000, 0.274829)" },
                            React.createElement("mask", { id: "mask-2", fill: "white" },
                                React.createElement("use", { xlinkHref: "#path-1" })),
                            React.createElement("g", { id: "Clip-2" }),
                            React.createElement("path", { d: "M11.8387549,0.288807273 C5.30093673,0.288807273 -0.000226909091,5.55486182 -0.000226909091,12.0513345 C-0.000226909091,15.5932255 1.58359855,18.7619891 4.07732945,20.9184618 L4.07732945,29.7901921 L7.95804218,27.6872648 L11.8387549,25.5837739 L15.7194676,27.6872648 L19.6001804,29.7901921 L19.6001804,20.9190255 C22.093344,18.7619891 23.6771695,15.5932255 23.6771695,12.0513345 C23.6771695,5.55486182 18.3771404,0.288807273 11.8387549,0.288807273 Z", id: "Fill-1", fillOpacity: "0.2", fill: "#FF9400", mask: "url(#mask-2)" })),
                        React.createElement("polygon", { id: "Fill-4", fill: "#A7A7A7", points: "4.464096 16.7376703 4.464096 28.5621976 11.8386415 24.5660158 19.2131869 28.5621976 19.2131869 16.7376703" }),
                        React.createElement("polygon", { id: "Fill-5", fill: "#989898", points: "8.15136873 16.7376703 8.15136873 26.5641067 11.8386415 24.5660158 15.5259142 26.5641067 15.5259142 16.7376703" }),
                        React.createElement("g", { id: "Group-14", transform: "translate(0.567273, 0.274829)" },
                            React.createElement("path", { d: "M11.2713687,0.908525455 C5.41314327,0.908525455 0.647485091,5.64363455 0.647485091,11.4643073 C0.647485091,17.28498 5.41314327,22.0189618 11.2713687,22.0189618 C17.1290269,22.0189618 21.8946851,17.28498 21.8946851,11.4643073 C21.8946851,5.64363455 17.1290269,0.908525455 11.2713687,0.908525455", id: "Fill-6", fill: "#D9D9D9" }),
                            React.createElement("path", { d: "M10.8193091,20.7337582 C5.67584727,20.7337582 1.49050909,16.5758127 1.49050909,11.4653218 C1.49050909,6.35314 5.67584727,2.19632182 10.8193091,2.19632182 C15.9627709,2.19632182 20.1475418,6.35314 20.1475418,11.4653218 C20.1475418,16.5758127 15.9627709,20.7337582 10.8193091,20.7337582 M10.8193091,1.03804909 C5.02348364,1.03804909 0.324763636,5.70608545 0.324763636,11.4653218 C0.324763636,17.2228673 5.02348364,21.8925945 10.8193091,21.8925945 C16.6151345,21.8925945 21.3138545,17.2228673 21.3138545,11.4653218 C21.3138545,5.70608545 16.6151345,1.03804909 10.8193091,1.03804909", id: "Fill-8", fill: "#F7D131" }),
                            React.createElement("path", { d: "M11.2713687,21.3992436 C5.75804509,21.3992436 1.27261964,16.9431345 1.27261964,11.4651527 C1.27261964,5.98604364 5.75804509,1.53049818 11.2713687,1.53049818 C16.7841251,1.53049818 21.2695505,5.98604364 21.2695505,11.4651527 C21.2695505,16.9431345 16.7841251,21.3992436 11.2713687,21.3992436 M11.2713687,0.288807273 C5.05916509,0.288807273 0.0229178182,5.29220727 0.0229178182,11.4651527 C0.0229178182,17.6364073 5.05916509,22.6409345 11.2713687,22.6409345 C17.4835724,22.6409345 22.5198196,17.6364073 22.5198196,11.4651527 C22.5198196,5.29220727 17.4835724,0.288807273 11.2713687,0.288807273", id: "Fill-10", fill: "#F8E831" }),
                            React.createElement("polygon", { id: "Fill-12", fill: "#B1B1B1", points: "15.6414109 9.38014909 15.5381673 8.09393091 14.2998109 7.70784 13.6451782 6.59465818 12.3608727 6.78009455 11.2847564 6.05920364 11.2739782 6.05920364 10.1978618 6.78009455 8.91355636 6.59465818 8.25892364 7.70784 7.02056727 8.09393091 6.91732364 9.38014909 5.96997818 10.2611127 6.43797818 11.46504 5.96997818 12.6672764 6.91732364 13.5488036 7.02056727 14.8350218 8.25892364 15.2205491 8.91355636 16.3342945 10.1978618 16.1488582 11.2739782 16.8691855 11.2847564 16.8691855 12.3608727 16.1488582 13.6451782 16.3342945 14.2998109 15.2205491 15.5381673 14.8350218 15.6414109 13.5488036 16.5881891 12.6672764 16.1201891 11.46504 16.5881891 10.2611127" }))),
                    React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", style: { mixBlendMode: 'color' }, x: "0", y: "0", width: "26", height: "31" })),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: dark ? colors.space.tint500 : colors.space.tint200 })))));
});
