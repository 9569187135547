import { LoadingIndicator } from 'components';
import { makeSelectIsLoggedIn } from 'containers/Auth/selectors';
import { useAction, useSelector } from 'hooks';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { oAuthLoginRequest } from './actions';
import messages from './messages';
import { makeSelectError, makeSelectHasError, makeSelectLoading, } from './selectors';
export var OAuthSuccess = function (_a) {
    var provider = _a.provider, accessToken = _a.accessToken, _b = _a.state, state = _b === void 0 ? '{ "next": "/" }' : _b;
    var formatMessage = useIntl().formatMessage;
    var loginRequest = useAction(function (payload) {
        return oAuthLoginRequest(payload);
    });
    var isLoggedIn = useSelector(makeSelectIsLoggedIn());
    var isLoading = useSelector(makeSelectLoading());
    var hasError = useSelector(makeSelectHasError());
    var error = useSelector(makeSelectError());
    React.useEffect(function () {
        var payload = {
            accessToken: accessToken,
            provider: provider,
            state: state,
        };
        loginRequest(payload);
    }, [provider, accessToken, state]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(LoadingIndicator, null),
        isLoggedIn && React.createElement("span", null, formatMessage(messages.successNotification)),
        hasError && React.createElement("p", null, error)));
};
export default memo(OAuthSuccess);
