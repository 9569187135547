var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  overflow: hidden;\n\n  > label {\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n\n  ", ";\n"], ["\n  margin-bottom: ", ";\n  overflow: hidden;\n\n  > label {\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n\n  ",
    ";\n"])), rem(6), color, rem(14), fonts.weight.semibold, ifProp('hasError', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      > label {\n        color: ", ";\n      }\n    "], ["\n      > label {\n        color: ", ";\n      }\n    "])), colors.base.danger)));
var templateObject_1, templateObject_2;
