import produce from 'immer';
import { UNSUBSCRIBE_USER_FAILURE, UNSUBSCRIBE_USER_REQUEST, UNSUBSCRIBE_USER_SUCCESS, } from './constants';
export var unsubscribeUserInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
};
var unsubscribeUserReducer = produce(function (draft, action) {
    switch (action.type) {
        case UNSUBSCRIBE_USER_REQUEST:
            draft.loading = true;
            break;
        case UNSUBSCRIBE_USER_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case UNSUBSCRIBE_USER_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            break;
        default:
    }
}, unsubscribeUserInitialState);
export default unsubscribeUserReducer;
