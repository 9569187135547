var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export var ExternalContent = function (_a) {
    var body = _a.body;
    var pathname = useLocation().pathname;
    var url = pathname.substring(pathname.lastIndexOf('/') + 1);
    useEffect(function () {
        var _a, _b;
        var el = window.document.querySelector('p > iframe, div > iframe');
        var parent = el === null || el === void 0 ? void 0 : el.parentElement;
        el === null || el === void 0 ? void 0 : (_a = el.classList).remove.apply(_a, __spread(el.classList));
        el === null || el === void 0 ? void 0 : el.removeAttribute('style');
        parent === null || parent === void 0 ? void 0 : (_b = parent.classList).remove.apply(_b, __spread(parent.classList));
        parent === null || parent === void 0 ? void 0 : parent.removeAttribute('style');
        if (url === 'faire-un-don' && parent) {
            parent.classList.add('donate-container');
        }
        else if (url === 'adherer-a-lassociation' && parent) {
            parent.classList.add('donate-container');
        }
        else {
            parent === null || parent === void 0 ? void 0 : parent.classList.add('video-container');
        }
    }, [body]);
    return React.createElement("div", { dangerouslySetInnerHTML: { __html: body } });
};
