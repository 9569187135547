var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { switchProp } from 'styled-tools';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint400,
    dark: colors.carbon.tint200,
});
export var AvatarWrapper = styled.img(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid ", ";\n  object-fit: cover;\n\n  ", "\n\n  ", "\n"], ["\n  background: ", ";\n  border: ", " solid ", ";\n  object-fit: cover;\n\n  ",
    "\n\n  ",
    "\n"])), colors.base.white, rem(1), border, switchProp('size', {
    mini: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      width: ", ";\n      height: ", ";\n    "], ["\n      width: ", ";\n      height: ", ";\n    "])), rem(24), rem(25)),
    tiny: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      width: ", ";\n      height: ", ";\n    "], ["\n      width: ", ";\n      height: ", ";\n    "])), rem(31), rem(32)),
    small: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      width: ", ";\n      height: ", ";\n    "], ["\n      width: ", ";\n      height: ", ";\n    "])), rem(37), rem(38)),
    medium: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      width: ", ";\n      height: ", ";\n    "], ["\n      width: ", ";\n      height: ", ";\n    "])), rem(57), rem(58)),
    large: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      width: ", ";\n      height: ", ";\n    "], ["\n      width: ", ";\n      height: ", ";\n    "])), rem(77), rem(78)),
    xLarge: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      width: ", ";\n      height: ", ";\n    "], ["\n      width: ", ";\n      height: ", ";\n    "])), rem(97), rem(99)),
}), switchProp('shape', {
    square: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      border-radius: ", ";\n    "], ["\n      border-radius: ", ";\n    "])), rem(2)),
    rounded: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      border-radius: 35%;\n    "], ["\n      border-radius: 35%;\n    "]))),
    circle: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      border-radius: 50%;\n    "], ["\n      border-radius: 50%;\n    "]))),
}));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
