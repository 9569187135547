var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { notification } from 'antd';
import { Button, Modal, PageHeader, TextAreaField } from 'components';
import { Spin } from 'components/Spin';
import { makeSelectUser } from 'containers/Auth/selectors';
import makeSelectorPlaceDetails from 'containers/PlaceDetails/selectors';
import FavorityButton from 'containers/PlacePhotos/FavorityButton';
import Photo from 'containers/PlacePhotos/Photo';
import { paths } from 'containers/RoutesProvider/routes';
import { Formik } from 'formik';
import { useAction, useGeolocation, useSelector } from 'hooks';
import { Alert, ArrowBack } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { colors } from 'styles';
import { clearReportedPhoto, getPlaceDetails } from '../PlaceDetails/actions';
import { clearNotification, clearReported, deletePhoto, getPhotos, postPhotos, postPlacePhotosClearSuccess, putPhotos, reportMediaPlace, } from './actions';
import { makeSelectorAlreadyReported, makeSelectorDeletePhotoFailure, makeSelectorDeletePhotoLoading, makeSelectorDeletePhotoSuccess, makeSelectorLoadingUpload, makeSelectorReported, makeSelectorReportLoading, makeSelectPlacePhotos, makeSelectPostPlacePhotosCreated, makeSelectPostPlacePhotosHasSuccess, } from './selectors';
import { AddButton, AddButtonRedirect, CamSolidStyled, Container, ContainerButtons, Content, CustomButton, InputUpload, ModalButtonWrapper, ModalMessage, PhotoActions, PhotoCard, PhotoList, Wrapper, } from './styles';
import ReportFormFormSchema from './validator';
export var PlacePhotos = function () {
    var dispatch = useDispatch();
    var formatMessage = useIntl().formatMessage;
    var user = useSelector(makeSelectUser());
    var history = useHistory();
    var photos = useSelector(makeSelectPlacePhotos());
    var reported = useSelector(makeSelectorReported());
    var reportLoading = useSelector(makeSelectorReportLoading());
    var alreadyReported = useSelector(makeSelectorAlreadyReported());
    var photoFailure = useSelector(makeSelectorDeletePhotoFailure());
    var photoDeleteSuccess = useSelector(makeSelectorDeletePhotoSuccess());
    var photoLoading = useSelector(makeSelectorDeletePhotoLoading());
    var photoCreatedSuccess = useSelector(makeSelectPostPlacePhotosHasSuccess());
    var photoCreated = useSelector(makeSelectPostPlacePhotosCreated());
    var uploadLoading = useSelector(makeSelectorLoadingUpload());
    var clearPhotoCreatedCallback = useAction(postPlacePhotosClearSuccess);
    var loadPlaceDetailsRequest = useAction(getPlaceDetails);
    var placeDetail = useSelector(makeSelectorPlaceDetails());
    var deletePhotoCallback = useAction(deletePhoto);
    var clearNotificationCallback = useAction(clearNotification);
    var clearReportedPhotoCallback = useAction(clearReportedPhoto);
    var _a = __read(useState(false), 2), showReportModal = _a[0], setShowReportModal = _a[1];
    var _b = __read(useState(0), 2), selectedPhotoId = _b[0], setSelectedPhotoId = _b[1];
    var schema = ReportFormFormSchema(formatMessage);
    var geolocation = useGeolocation();
    var placeId = useParams().placeId;
    useEffect(function () {
        if (placeId) {
            dispatch(getPhotos(placeId));
            loadPlaceDetailsRequest(placeId);
        }
    }, [placeId]);
    var fileSelectedHandler = function (event) {
        var latitude = geolocation.latitude, longitude = geolocation.longitude;
        var fd = new FormData();
        var file = event.target.files[0];
        fd.append('file', file, file.name);
        if (latitude && longitude) {
            fd.append('latitude', latitude.toString());
            fd.append('longitude', longitude.toString());
        }
        var sizeMb = Math.floor(file.size / 1000000);
        var currentType = file.type;
        var typesAllowed = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'];
        // eslint-disable-next-line no-underscore-dangle
        var _URL = window.URL || window.webkitURL;
        var img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function (e) {
            var item = e.target;
            if (item.width < 1080 ||
                item.height < 1080 ||
                sizeMb > 20 ||
                // eslint-disable-next-line lodash/prefer-lodash-method
                !typesAllowed.includes(currentType)) {
                notification.error({
                    message: formatMessage({
                        id: 'containers.placePhotos.validationTitle',
                    }),
                    description: formatMessage({
                        id: 'containers.placePhotos.validationDescription',
                    }),
                });
            }
            else {
                dispatch(postPhotos(placeId, fd, placeDetail === null || placeDetail === void 0 ? void 0 : placeDetail.name));
            }
        };
    };
    var checkLogged = function (event) {
        var target = event.target;
        if (!user) {
            target.focus = null;
            history.push(paths.signInPage);
        }
    };
    var handlerFavority = function (mediaId) {
        if (!user) {
            history.push(paths.signInPage);
        }
        dispatch(putPhotos(placeId, mediaId));
    };
    var handleShowModal = function () {
        setShowReportModal(true);
    };
    var handleCancelModal = function () {
        setShowReportModal(false);
        dispatch(clearReported());
    };
    var handleReport = function (currentPhoto) {
        setSelectedPhotoId(currentPhoto.id);
        handleShowModal();
    };
    var onSubmit = function (values) {
        if (values.message) {
            dispatch(reportMediaPlace({
                placeId: placeId,
                mediaId: selectedPhotoId,
                message: values.message,
            }));
        }
    };
    var formatFeedback = function (message) {
        if (!message)
            return formatMessage({ id: 'containers.editPlace.successfulFeedback' });
        return (React.createElement("div", null,
            React.createElement("div", null, formatMessage({ id: 'containers.placePhotos.photoSuccess' })),
            React.createElement("div", null,
                React.createElement("p", null, formatMessage({ id: 'containers.editPlace.pointsFeedback' }, { points: message.points })))));
    };
    useEffect(function () {
        if (photoFailure) {
            notification.error({
                message: formatMessage({ id: 'containers.placeDetails.error' }),
                description: photoFailure.message,
                onClose: clearNotificationCallback,
            });
        }
    }, [photoFailure]);
    useEffect(function () {
        if (photoCreatedSuccess && photoCreated) {
            notification.success({
                message: formatMessage({ id: 'containers.placeDetails.success' }),
                description: formatFeedback(photoCreated.contribution),
                onClose: clearPhotoCreatedCallback,
            });
        }
    }, [photoCreatedSuccess, photoCreated]);
    useEffect(function () {
        if (photoDeleteSuccess) {
            notification.success({
                message: formatMessage({ id: 'containers.placeDetails.success' }),
                description: formatMessage({
                    id: 'containers.editMyProfile.photoDeleteSuccess',
                }),
                onClose: clearNotificationCallback,
            });
            clearNotificationCallback();
        }
    }, [photoDeleteSuccess]);
    useEffect(function () {
        if (alreadyReported) {
            notification.error({
                message: formatMessage({ id: 'containers.placeDetails.error' }),
                description: formatMessage({
                    id: 'containers.placeDetails.reportedPhoto.alreadyReported',
                }),
                onClose: function () {
                    clearReportedPhotoCallback();
                    clearNotificationCallback();
                },
            });
        }
    }, [alreadyReported]);
    var handleDeletePhoto = function (photoId) {
        deletePhotoCallback({ placeId: placeId, photoId: photoId });
    };
    return (React.createElement(Container, null,
        React.createElement(PageHeader, { title: formatMessage({ id: 'containers.placePhotos.header' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return history.goBack(); }, sticky: true }),
        React.createElement(Wrapper, null,
            React.createElement(Spin, { spinning: photoLoading || uploadLoading },
                React.createElement(Content, null,
                    React.createElement(Modal, { title: formatMessage({
                            id: reported
                                ? 'containers.placePhotos.reported.title'
                                : 'containers.placePhotos.report.title',
                        }), visible: showReportModal, footer: null, onCancel: handleCancelModal },
                        React.createElement(Spin, { spinning: reportLoading }, reported && !alreadyReported ? (React.createElement("div", null,
                            React.createElement(ModalMessage, null, formatMessage({
                                id: 'containers.placePhotos.reported.message',
                            })),
                            React.createElement(ModalButtonWrapper, null,
                                React.createElement(Button, { onClick: handleCancelModal },
                                    React.createElement(FormattedMessage, { id: "containers.placePhotos.reported.button" }))))) : (React.createElement(Formik, { initialValues: {
                                message: '',
                            }, enableReinitialize: true, validationSchema: schema, onSubmit: onSubmit }, function (_a) {
                            var handleBlur = _a.handleBlur, handleChange = _a.handleChange, values = _a.values, handleSubmit = _a.handleSubmit;
                            return (React.createElement("form", { onSubmit: handleSubmit },
                                React.createElement(TextAreaField, { rows: 4, name: "message", label: formatMessage({
                                        id: 'containers.placePhotos.report.label',
                                    }), placeholder: formatMessage({
                                        id: 'containers.placePhotos.report.placeholder',
                                    }), onBlur: handleBlur, onChange: handleChange, value: values.message }),
                                React.createElement(ModalButtonWrapper, null,
                                    React.createElement(Button, { type: "submit" },
                                        React.createElement(FormattedMessage, { id: "containers.placePhotos.report.button" })))));
                        })))),
                    React.createElement(PhotoList, null, map(photos, function (photo) { return (React.createElement(PhotoCard, { key: photo.id },
                        React.createElement(Photo, { key: photo.filename, src: photo.urlThumb, alt: photo.filename }),
                        React.createElement(PhotoActions, null,
                            React.createElement(FavorityButton, { onClick: function () { return handlerFavority(photo.id); }, isMain: photo.isMain, title: photo.isMain
                                    ? 'Remove from favorite'
                                    : 'Add to favorite' }),
                            user && !photo.isMine && (React.createElement(CustomButton, { onClick: function () { return handleReport(photo); }, title: formatMessage({
                                    id: 'containers.placeDetails.report.photoButtonTitle',
                                }) },
                                React.createElement(Alert, { size: rem(26), color: colors.base.white }))),
                            user && (photo === null || photo === void 0 ? void 0 : photo.isMine) && (React.createElement(CustomButton
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            , { 
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onClick: function () { return handleDeletePhoto(photo.id); }, isDelete: true, title: "Delete photo" },
                                React.createElement(MdDelete, { size: rem(20), color: colors.space.tint700 })))))); })),
                    React.createElement(ContainerButtons, null,
                        React.createElement(AddButton, null,
                            React.createElement(CamSolidStyled, { size: 20 }),
                            formatMessage({
                                id: 'containers.placePhotos.addPhoto',
                            })),
                        user && user ? (React.createElement(InputUpload, { onChange: fileSelectedHandler })) : (React.createElement(AddButtonRedirect, { onClick: checkLogged }))))))));
};
