import { rem } from 'polished';
import React from 'react';
import { FaStar } from 'react-icons/fa';
import { colors } from 'styles';
import { StyledButton } from './styles';
var FavorityButton = function (_a) {
    var onClick = _a.onClick, isMain = _a.isMain, title = _a.title;
    return (React.createElement(StyledButton, { shape: "circle", size: "icon", variant: !isMain ? 'ghost' : 'primary', onClick: onClick, title: title, icon: React.createElement(FaStar, { size: rem(14), color: !isMain ? colors.space.tint700 : colors.base.white }) }));
};
export default FavorityButton;
