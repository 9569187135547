var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { switchProp } from 'styled-tools';
import { colors, fonts } from 'styles';
export var PodiumBar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n\n  ", ";\n\n  background-color: ", ";\n\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n\n  padding: ", ";\n\n  width: 100%;\n  height: ", ";\n\n  &::after {\n    color: ", ";\n    content: '", "';\n    font-size: ", ";\n    font-weight: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n\n  ",
    ";\n\n  background-color: ",
    ";\n\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n\n  padding: ", ";\n\n  width: 100%;\n  height: ",
    ";\n\n  &::after {\n    color: ", ";\n    content: '", "';\n    font-size: ", ";\n    font-weight: ", ";\n  }\n"])), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    justify-content: flex-start;\n  "], ["\n    justify-content: flex-start;\n  "]))), switchProp('position', {
    1: colors.base.secondary,
    2: colors.base.primary,
    3: colors.base.warning,
}), rem(15), rem(15), rem(16), switchProp('position', {
    1: rem(110),
    2: rem(85),
    3: rem(70),
}), colors.base.white, function (props) { return props.position; }, rem(32), fonts.weight.bold);
var templateObject_1, templateObject_2;
