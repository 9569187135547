var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var color = theme('mode', {
    light: colors.space.tint500,
    dark: colors.space.tint700,
});
export var InfoMessageWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: ", " 0;\n  text-align: center;\n"], ["\n  margin: ", " 0;\n  text-align: center;\n"])), rem(8));
export var MessageText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), color, rem(13));
var templateObject_1, templateObject_2;
