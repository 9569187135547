var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Time = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M20.5 7.375C13.2634 7.375 7.375 13.2629 7.375 20.5003C7.375 27.7371 13.2634 33.625 20.5 33.625C27.7366 33.625 33.625 27.7371 33.625 20.5003C33.625 13.2629 27.7366 7.375 20.5 7.375ZM20.5 36.25C11.8158 36.25 4.75 29.1842 4.75 20.5C4.75 11.8152 11.8158 4.75 20.5 4.75C29.1842 4.75 36.25 11.8152 36.25 20.5C36.25 29.1842 29.1842 36.25 20.5 36.25Z", fill: color }),
        React.createElement("path", { d: "M28.478 23.5879H20.7573C20.0463 23.5879 19.4706 23.0011 19.4706 22.2779V10.4869C19.4706 9.76307 20.0463 9.17618 20.7573 9.17618C21.4675 9.17618 22.0439 9.76307 22.0439 10.4869V20.9679H28.478C29.189 20.9679 29.7647 21.5541 29.7647 22.2779C29.7647 23.0011 29.189 23.5879 28.478 23.5879Z", fill: color })));
});
