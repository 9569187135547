var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import React, { memo } from 'react';
import styled from 'styled-components';
import { colors } from 'styles';
export var Item = memo(function (_a) {
    var to = _a.to, label = _a.label;
    return (React.createElement(Container, null,
        React.createElement("a", { href: to }, label)));
});
var Container = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 1;\n  margin-bottom: ", ";\n\n  a {\n    color: ", ";\n    font-size: ", ";\n    text-decoration: none;\n  }\n"], ["\n  line-height: 1;\n  margin-bottom: ", ";\n\n  a {\n    color: ", ";\n    font-size: ", ";\n    text-decoration: none;\n  }\n"])), rem(12), colors.space.tint700, rem(14));
var templateObject_1;
