var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { switchProp } from 'styled-tools';
import { breakpoints, colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var color = theme('mode', {
    light: colors.space.tint700,
    dark: colors.base.white,
});
export var TeamsScoreBoardContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  justify-content: center;\n  padding: ", ";\n  margin: 0 auto;\n  max-width: ", ";\n"], ["\n  color: ", ";\n  display: flex;\n  justify-content: center;\n  padding: ", ";\n  margin: 0 auto;\n  max-width: ", ";\n"])), color, rem(20), breakpoints.medium);
export var Title = styled.h4(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"])), rem(18), fonts.weight.semibold);
export var BlockTitle = styled.h4(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  border-bottom: ", " solid ", ";\n  font-weight: ", ";\n  padding: ", " 0;\n  text-align: center;\n  text-transform: uppercase;\n"], ["\n  border-top: ", " solid ", ";\n  border-bottom: ", " solid ", ";\n  font-weight: ", ";\n  padding: ", " 0;\n  text-align: center;\n  text-transform: uppercase;\n"])), rem(1), border, rem(1), border, fonts.weight.semibold, rem(10));
export var Podium = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", " ", ";\n  margin: 0 ", ";\n  position: relative;\n\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  flex-basis: calc(33.3% - ", ");\n\n  ", ";\n\n  ", ";\n"], ["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", " ", ";\n  margin: 0 ", ";\n  position: relative;\n\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  flex-basis: calc(33.3% - ", ");\n\n  ",
    ";\n\n  ",
    ";\n"])), rem(1), border, rem(5), rem(20), rem(8), rem(5), rem(10), media.greaterThan('medium')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex-basis: calc(28% - ", ");\n  "], ["\n    flex-basis: calc(28% - ", ");\n  "])), rem(10)), switchProp('position', {
    pos1: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      margin-top: ", ";\n      order: 2;\n    "], ["\n      margin-top: ", ";\n      order: 2;\n    "])), rem(0)),
    pos2: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      margin-top: ", ";\n      order: 1;\n    "], ["\n      margin-top: ", ";\n      order: 1;\n    "])), rem(30)),
    pos3: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      margin-top: ", ";\n      order: 3;\n    "], ["\n      margin-top: ", ";\n      order: 3;\n    "])), rem(60)),
}));
export var Username = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"])), rem(12), fonts.weight.semibold, rem(12));
export var Score = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border: ", " solid ", ";\n  font-size: ", ";\n  line-height: 1;\n  font-weight: ", ";\n  padding: ", " ", ";\n  border-radius: ", ";\n  margin-top: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  border: ", " solid ", ";\n  font-size: ", ";\n  line-height: 1;\n  font-weight: ", ";\n  padding: ", " ", ";\n  border-radius: ", ";\n  margin-top: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), rem(1), border, rem(13), fonts.weight.semibold, rem(4), rem(16), rem(16), rem(12));
export var MedalWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n"])), rem(3), rem(3));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
