import React, { Children } from 'react';
import { IntlProvider } from 'react-intl';
import messages from 'translations';
import { flattenMessages } from 'utils/common';
import { useSelector } from 'utils/hooks';
import { makeSelectLocale } from './selectors';
import { Locale } from './types';
var LanguageProvider = function (_a) {
    var children = _a.children;
    var locale = useSelector(makeSelectLocale());
    return (React.createElement(IntlProvider, { locale: locale, defaultLocale: Locale.FR, messages: flattenMessages(messages[locale]) }, Children.only(children)));
};
export default LanguageProvider;
