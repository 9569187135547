import { Button } from 'components';
import { setFilterMobileVisible, setSelectedCategory, } from 'containers/FilterPlaces/actions';
import { makeSelectorCategories } from 'containers/PlaceDetails/selectors';
import { useAction, useSelector } from 'hooks';
import { Filter } from 'icons/mono';
import map from 'lodash/map';
import take from 'lodash/take';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { getCategoryIcon } from '../../CategoryDropdown';
import { makeSelectorSelectedCategory } from '../../selectors';
import { Container, FiltersList, MoreEllipsis, Separator } from './styles';
export var FilterBarMobile = function (_a) {
    var onSelectCategory = _a.onSelectCategory;
    var formatMessage = useIntl().formatMessage;
    var categories = useSelector(makeSelectorCategories());
    var selectedCategory = useSelector(makeSelectorSelectedCategory());
    var setFilterMobileVisibleCallback = useAction(setFilterMobileVisible);
    var setSelectedCategoryCallback = useAction(setSelectedCategory);
    var getFilters = function () {
        if (!(categories === null || categories === void 0 ? void 0 : categories.length))
            return [];
        var result = map(categories, function (_a) {
            var id = _a.id, label = _a.label, name = _a.name;
            return ({
                id: id,
                label: label,
                icon: getCategoryIcon(name),
            });
        });
        return take(result, 5);
    };
    var handleSelect = function (filter) {
        if (filter.id === (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id)) {
            setSelectedCategoryCallback(undefined);
            onSelectCategory();
            return;
        }
        setSelectedCategoryCallback(filter);
        onSelectCategory(filter.id);
    };
    return (React.createElement(Container, null,
        React.createElement(FiltersList, null,
            React.createElement("li", null,
                React.createElement(Button, { shape: "pill", size: "small", icon: React.createElement(Filter, { size: rem(18) }), onClick: function () { return setFilterMobileVisibleCallback(true); } },
                    React.createElement("span", null, formatMessage({
                        id: 'containers.filterPlaces.filterBar.editButton',
                    })))),
            React.createElement("li", null,
                React.createElement(Separator, null)),
            map(getFilters(), function (filter) { return (React.createElement("li", { key: filter.id },
                React.createElement(Button, { shape: "pill", size: "small", variant: filter.id === (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id) ? 'primary' : 'ghost', icon: filter.icon, onClick: function () { return handleSelect(filter); } },
                    React.createElement("span", null, filter.label)))); }),
            React.createElement("li", null,
                React.createElement(Button, { shape: "pill", size: "small", variant: "ghost", onClick: function () { return setFilterMobileVisibleCallback(true); }, icon: React.createElement(MoreEllipsis, null,
                        React.createElement("span", null, "...")) },
                    React.createElement("span", null, formatMessage({
                        id: 'containers.filterPlaces.filterBar.more',
                    })))))));
};
