var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/camelcase */
import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    signIn: versionV4 + "/account/login",
    signOut: versionV4 + "/account/logout",
    currentUser: versionV4 + "/account",
    signUp: versionV4 + "/account",
    resendEmailActivation: versionV4 + "/account/resend-email-activation",
    myProfile: versionV4 + "/users/me/profile",
    myTeams: versionV4 + "/users/me/teams",
    myContributions: versionV4 + "/users/me/contributions",
    myFavorites: versionV4 + "/users/me/favorites",
    myChallenges: versionV4 + "/users/me/challenges",
    myNotifications: versionV4 + "/users/me/notifications",
    putNotifications: versionV4 + "/account/notifications",
    forgotPassword: versionV4 + "/account/password_lost",
    changePassword: versionV4 + "/account/password",
    passwordExpired: function (token) {
        return versionV4 + "/account/password/" + token + "/expired";
    },
    accountActivated: function (token) { return versionV4 + "/account/activate/" + token; },
    oneSignal: versionV4 + "/account/onesignal",
    currentChallenge: versionV4 + "/account/challenges",
};
export default {
    signInUser: function (payload) { return axios.post(endpoints.signIn, payload); },
    signOut: function () { return axios.get(endpoints.signOut); },
    currentUser: function () { return axios.get(endpoints.currentUser); },
    signUp: function (payload) { return axios.post(endpoints.signUp, payload); },
    resendEmailActivation: function (payload) {
        return axios.post(endpoints.resendEmailActivation, payload);
    },
    myProfile: function () { return axios.get(endpoints.myProfile); },
    myTeams: function () { return axios.get(endpoints.myTeams); },
    myContributions: function (_a) {
        var _b = _a.pagination, page = _b.page, perPage = _b.perPage;
        var config = {
            params: {
                page: page,
                per_page: perPage,
            },
        };
        return axios.get(endpoints.myContributions, config);
    },
    myFavorites: function (_a) {
        var _b = _a.pagination, page = _b.page, perPage = _b.perPage;
        var config = {
            params: {
                page: page,
                per_page: perPage,
            },
        };
        return axios.get(endpoints.myFavorites, config);
    },
    myChallenges: function (_a) {
        var _b = _a.pagination, page = _b.page, perPage = _b.perPage;
        var config = {
            params: {
                page: page,
                per_page: perPage,
            },
        };
        return axios.get(endpoints.myChallenges, config);
    },
    myNotifications: function (_a) {
        var _b = _a.pagination, page = _b.page, perPage = _b.perPage;
        var config = {
            params: {
                page: page,
                per_page: perPage,
            },
        };
        return axios.get(endpoints.myNotifications, config);
    },
    updateNotifications: function (payload) {
        return axios.put(endpoints.myNotifications, payload);
    },
    forgotPassword: function (email) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post(endpoints.forgotPassword, { email: email })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); },
    changePassword: function (password, password_confirm, token) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post(endpoints.changePassword, {
                        password: password,
                        password_confirm: password_confirm,
                        token: token,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); },
    isPasswordExpired: function (token) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = endpoints.passwordExpired(token);
            return [2 /*return*/, axios.get(url)];
        });
    }); },
    accountActivated: function (payload) {
        var url = endpoints.accountActivated(payload.token);
        return axios.get(url);
    },
    updateOneSignal: function (ids) {
        var payload = {
            player_id: ids,
        };
        return axios.post(endpoints.oneSignal, payload);
    },
    currentChallenge: function () { return axios.get(endpoints.currentChallenge); },
    putNotifications: function (payload) {
        return axios.put(endpoints.putNotifications, payload);
    },
};
