import { createSelector } from 'reselect';
import { homeMostActiveUsersInitialState } from './reducer';
export var selectHomeMostActiveUsersDomain = function (state) {
    return state.homeMostActiveUsers || homeMostActiveUsersInitialState;
};
export var makeSelectHomeMostActiveUsersLoading = function () {
    return createSelector(selectHomeMostActiveUsersDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectHomeMostActiveUsersHasError = function () {
    return createSelector(selectHomeMostActiveUsersDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectHomeMostActiveUsersError = function () {
    return createSelector(selectHomeMostActiveUsersDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectHomeMostActiveUsersItems = function () {
    return createSelector(selectHomeMostActiveUsersDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeHomeMostActiveUsers = function () {
    return createSelector(selectHomeMostActiveUsersDomain, function (subState) { return subState; });
};
export default makeHomeMostActiveUsers;
