var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var MedalCriterionMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0 26.8352675 19.1576425 26.8352675 19.1576425 -3.91550523e-05 0 -3.91550523e-05" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-6", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/6", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group", transform: "translate(6.500000, 7.750000)" }),
                    React.createElement("g", { id: "Group-19", transform: "translate(14.294263, 14.886492) rotate(20.000000) translate(-14.294263, -14.886492) translate(4.544263, 1.323992)" },
                        React.createElement("path", { d: "M17.4417145,4.54969961 L1.71608561,4.54969961 C1.18132359,4.54969961 0.743110348,4.98471224 0.743110348,5.5168294 L0.743110348,25.8680594 C0.743110348,26.399785 1.18132359,26.8351892 1.71608561,26.8351892 L17.4417145,26.8351892 C17.9764766,26.8351892 18.4142957,26.399785 18.4142957,25.8680594 L18.4142957,5.5168294 C18.4142957,4.98471224 17.9764766,4.54969961 17.4417145,4.54969961", id: "Fill-1", fill: "#656D78", opacity: "0.458193824" }),
                        React.createElement("mask", { id: "mask-2", fill: "white" },
                            React.createElement("use", { xlinkHref: "#path-1" })),
                        React.createElement("g", { id: "Clip-4" }),
                        React.createElement("path", { d: "M1.56211986,24.9198023 L16.1082774,24.9198023 L16.1082774,5.59169386 L1.56211986,5.59169386 L1.56211986,24.9198023 Z M16.6982101,4.0458524 L0.972975261,4.0458524 C0.437819164,4.0458524 0,4.48086503 0,5.01298219 L0,25.3642122 C0,25.8959378 0.437819164,26.3313419 0.972975261,26.3313419 L16.6982101,26.3313419 C17.2333662,26.3313419 17.6711854,25.8959378 17.6711854,25.3642122 L17.6711854,5.01298219 C17.6711854,4.48086503 17.2333662,4.0458524 16.6982101,4.0458524 L16.6982101,4.0458524 Z", id: "Fill-3", fill: "#656D78", mask: "url(#mask-2)" }),
                        React.createElement("polygon", { id: "Fill-5", fill: "#FFFFFF", mask: "url(#mask-2)", points: "1.56251394 24.9198023 16.1086714 24.9198023 16.1086714 5.59169386 1.56251394 5.59169386" }),
                        React.createElement("path", { d: "M8.82183941,1.18248258 C9.59186519,1.18248258 10.2160826,1.80309016 10.2160826,2.56896298 C10.2160826,3.3340527 9.59186519,3.95505183 8.82183941,3.95505183 C8.05181362,3.95505183 7.4275962,3.3340527 7.4275962,2.56896298 C7.4275962,1.80309016 8.05181362,1.18248258 8.82183941,1.18248258 M12.8993506,2.90491333 L11.4645175,2.90491333 C11.4739753,2.81407361 11.4790983,2.72166768 11.4790983,2.62808711 C11.4790983,1.17660932 10.2956861,0 8.83563209,0 C7.37557808,0 6.19177181,1.17660932 6.19177181,2.62808711 C6.19177181,2.72166768 6.19689481,2.81407361 6.20635265,2.90491333 L4.77191362,2.90491333 C4.4160624,2.90491333 4.12562791,3.19387761 4.12562791,3.54783929 L4.12562791,5.87364939 C4.12562791,6.22721951 4.4160624,6.51657535 4.77191362,6.51657535 L12.8993506,6.51657535 C13.2548077,6.51657535 13.5456363,6.22721951 13.5456363,5.87364939 L13.5456363,3.54783929 C13.5456363,3.19387761 13.2548077,2.90491333 12.8993506,2.90491333", id: "Fill-6", fill: "#545C66", mask: "url(#mask-2)" }),
                        React.createElement("path", { d: "M5.73196317,4.7108618 C5.73196317,4.99630213 5.49906387,5.22731694 5.21257014,5.22731694 C4.92568233,5.22731694 4.69278303,4.99630213 4.69278303,4.7108618 C4.69278303,4.42542147 4.92568233,4.19440666 5.21257014,4.19440666 C5.49906387,4.19440666 5.73196317,4.42542147 5.73196317,4.7108618", id: "Fill-7", fill: "#AAB2BD", mask: "url(#mask-2)" }),
                        React.createElement("path", { d: "M13.005239,4.7108618 C13.005239,4.99630213 12.7723397,5.22731694 12.4854518,5.22731694 C12.1989581,5.22731694 11.9656647,4.99630213 11.9656647,4.7108618 C11.9656647,4.42542147 12.1989581,4.19440666 12.4854518,4.19440666 C12.7723397,4.19440666 13.005239,4.42542147 13.005239,4.7108618", id: "Fill-8", fill: "#AAB2BD", mask: "url(#mask-2)" }),
                        React.createElement("polygon", { id: "Fill-9", fill: "#8ABFF9", mask: "url(#mask-2)", points: "7.74163589 10.6305575 19.1576425 10.6305575 19.1576425 9.90188201 7.74163589 9.90188201" }),
                        React.createElement("polygon", { id: "Fill-10", fill: "#8ABFF9", mask: "url(#mask-2)", points: "7.74163589 12.0573676 19.1576425 12.0573676 19.1576425 11.3290837 7.74163589 11.3290837" }),
                        React.createElement("polygon", { id: "Fill-11", fill: "#5D9CEC", mask: "url(#mask-2)", points: "2.9236547 12.393318 5.96119756 12.393318 5.96119756 9.37407191 2.9236547 9.37407191" }),
                        React.createElement("polygon", { id: "Fill-12", fill: "#5D9CEC", mask: "url(#mask-2)", points: "2.9236547 17.2023415 5.96119756 17.2023415 5.96119756 14.1827039 2.9236547 14.1827039" }),
                        React.createElement("polygon", { id: "Fill-13", fill: "#8ABFF9", mask: "url(#mask-2)", points: "7.74163589 15.4023838 14.2829143 15.4023838 14.2829143 14.6740998 7.74163589 14.6740998" }),
                        React.createElement("polygon", { id: "Fill-14", fill: "#8ABFF9", mask: "url(#mask-2)", points: "7.74163589 16.8291939 14.2829143 16.8291939 14.2829143 16.1005183 7.74163589 16.1005183" }),
                        React.createElement("polygon", { id: "Fill-15", fill: "#5D9CEC", mask: "url(#mask-2)", points: "2.9236547 22.0861512 5.96119756 22.0861512 5.96119756 19.0665135 2.9236547 19.0665135" }),
                        React.createElement("polygon", { id: "Fill-16", fill: "#8ABFF9", mask: "url(#mask-2)", points: "7.74163589 20.286585 14.2829143 20.286585 14.2829143 19.5579095 7.74163589 19.5579095" }),
                        React.createElement("polygon", { id: "Fill-17", fill: "#8ABFF9", mask: "url(#mask-2)", points: "7.74163589 21.7133951 14.2829143 21.7133951 14.2829143 20.9851111 7.74163589 20.9851111" }),
                        React.createElement("polygon", { id: "Fill-18", fill: "#8ABFF9", mask: "url(#mask-2)", points: "4.37637889 12.0065444 3.22606913 9.80094029 3.92476704 9.44071381 4.33027192 10.2179416 5.41161826 7.85728349 6.12804962 8.18148733" }))),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
