var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var CamSolid = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M20 25.2857C22.5247 25.2857 24.5714 23.239 24.5714 20.7143C24.5714 18.1896 22.5247 16.1429 20 16.1429C17.4753 16.1429 15.4286 18.1896 15.4286 20.7143C15.4286 23.239 17.4753 25.2857 20 25.2857Z", fill: color }),
        React.createElement("path", { d: "M32.5714 11.5714H28.3571C28.1429 11.5714 27.8771 11.4329 27.67 11.2143L25.8171 8.29C25.7878 8.24359 25.7551 8.19942 25.7193 8.15786C25.0793 7.41143 24.2143 7 23.2857 7H16.7143C15.7857 7 14.9207 7.41143 14.2807 8.15786C14.2449 8.19942 14.2122 8.24359 14.1829 8.29L12.33 11.2186C12.1714 11.3914 11.9486 11.5757 11.7143 11.5757V11.0043C11.7143 10.3731 11.2026 9.86143 10.5714 9.86143H8.85714C8.22596 9.86143 7.71429 10.3731 7.71429 11.0043V11.5757H7.42857C5.53584 11.5777 4.00197 13.1116 4 15.0043V28.7143C4.00197 30.607 5.53584 32.1409 7.42857 32.1429H32.5714C34.4642 32.1409 35.998 30.607 36 28.7143V15C35.998 13.1073 34.4642 11.5734 32.5714 11.5714ZM20 27.5714C16.2129 27.5714 13.1429 24.5014 13.1429 20.7143C13.1429 16.9272 16.2129 13.8571 20 13.8571C23.7871 13.8571 26.8571 16.9272 26.8571 20.7143C26.8528 24.4996 23.7853 27.5671 20 27.5714Z", fill: color })));
});
