import { CLEAR_MAP_LOCATION, REVERSE_GPID, REVERSE_GPID_ERROR, REVERSE_GPID_SUCCESS, } from './constants';
export var reverseGpid = function (payload) { return ({
    type: REVERSE_GPID,
    payload: payload,
}); };
export var reverseGpidError = function (payload) { return ({
    type: REVERSE_GPID_ERROR,
    payload: payload,
}); };
export var reverseGpidSuccess = function (payload) { return ({
    type: REVERSE_GPID_SUCCESS,
    payload: payload,
}); };
export var clearMapLocation = function () { return ({
    type: CLEAR_MAP_LOCATION,
}); };
