import { CONTRIBUTOR_USER_PROFILE_FAILURE, CONTRIBUTOR_USER_PROFILE_REQUEST, CONTRIBUTOR_USER_PROFILE_SUCCESS, } from '../constants';
export var fetchContributorUserProfileRequest = function (userSlug) { return ({
    type: CONTRIBUTOR_USER_PROFILE_REQUEST,
    payload: userSlug,
}); };
export var fetchContributorUserProfileSuccess = function (payload) { return ({
    type: CONTRIBUTOR_USER_PROFILE_SUCCESS,
    payload: payload,
}); };
export var fetchContributorUserProfileError = function (error) { return ({
    type: CONTRIBUTOR_USER_PROFILE_FAILURE,
    payload: error,
}); };
