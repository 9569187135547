var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Pagination } from 'components/Pagination';
import { getConversations } from 'containers/Chat/actions';
import { makeSelectorConversations, makeSelectorPaginationMetadata, } from 'containers/Chat/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import map from 'lodash/map';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserCard } from '../UserCard';
import { ChatListWrapper, PaginationWrapper } from './styles';
export var ChatList = function () {
    var conversations = useSelector(makeSelectorConversations());
    var paginationMetadata = useSelector(makeSelectorPaginationMetadata());
    var getConversationsCallback = useAction(getConversations);
    var history = useHistory();
    var location = useLocation();
    var handleSelect = function (conversationId) {
        var params = qs.parse(location.search);
        var target = paths.mailSystemPage + "?" + qs.stringify(__assign(__assign({}, params), { conversation: conversationId }));
        history.push(target);
    };
    useEffect(function () {
        var params = qs.parse(location.search);
        if (Number.isNaN(Number(params.page))) {
            history.push(paths.mailSystemPage + "?" + qs.stringify(__assign(__assign({}, params), { page: 1 })));
        }
    });
    var getPageParam = function () {
        var params = qs.parse(location.search);
        if (!params.page || Number.isNaN(Number(params.page))) {
            return 1;
        }
        return Number(params.page);
    };
    var getConversationParam = function () {
        var params = qs.parse(location.search);
        if (!params.page || Number.isNaN(Number(params.page))) {
            return null;
        }
        return Number(params.conversation);
    };
    useEffect(function () {
        getConversationsCallback({ page: getPageParam() });
    }, [getPageParam()]);
    var handlePageChange = function (pageNumber) {
        var params = qs.parse(location.search);
        var target = paths.mailSystemPage + "?" + qs.stringify(__assign(__assign({}, params), { page: pageNumber }));
        history.push(target);
    };
    return (React.createElement(ChatListWrapper, null,
        map(conversations, function (c, index) { return (React.createElement(UserCard, { key: index, conversation: c, isActive: getConversationParam() === c.id, onSelect: function () { return handleSelect(c.id); } })); }),
        (conversations === null || conversations === void 0 ? void 0 : conversations.length) && (React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { pageSize: 10, total: paginationMetadata.total, current: getPageParam(), onChange: handlePageChange })))));
};
