var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var mobileHeader = theme('mode', {
    light: colors.base.primary,
    dark: colors.carbon.tint900,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100vw;\n  height: ", ";\n"], ["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100vw;\n  height: ", ";\n"])), mobileHeader, rem(20), rem(20), rem(300));
var templateObject_1;
