import { colaborateWithUs, helpAchieveGoals } from 'assets/images';
import { paths } from 'containers/RoutesProvider/routes';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from '../../Card';
import { Wrapper } from './styles';
export var CardListDesktop = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    return (React.createElement(Wrapper, null,
        React.createElement(Card, { image: colaborateWithUs, alternativeText: formatMessage({
                id: 'containers.contribute.collaborateAlt',
            }), imageText: formatMessage({
                id: 'containers.contribute.collaborateImage',
            }), title: formatMessage({
                id: 'containers.contribute.collaborateTitle',
            }), buttonText: formatMessage({
                id: 'containers.contribute.collaborateButtonText',
            }), buttonAction: function () {
                return history.push(paths.adhereALassociation, {
                    from: location.pathname,
                });
            } }, formatMessage({
            id: 'containers.contribute.collaborateParagraph',
        })),
        React.createElement(Card, { image: helpAchieveGoals, alternativeText: formatMessage({
                id: 'containers.contribute.donateAlt',
            }), imageText: formatMessage({
                id: 'containers.contribute.donateImage',
            }), title: formatMessage({
                id: 'containers.contribute.donateTitle',
            }), buttonText: formatMessage({
                id: 'containers.contribute.donateButtonText',
            }), buttonAction: function () {
                return history.push(paths.faireUnDon, { from: location.pathname });
            } }, formatMessage({
            id: 'containers.contribute.donateParagraph',
        }))));
});
