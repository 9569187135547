var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-top: ", ";\n  min-height: calc(100vh - ", ");\n\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-top: ", ";\n  min-height: calc(100vh - ", ");\n\n  ",
    ";\n"])), rem(60), rem(200), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: calc(100vh - ", ");\n  "], ["\n    min-height: calc(100vh - ", ");\n  "])), rem(140)));
export var AddPlaceWrapper = styled.footer(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: auto;\n  width: 100%;\n"], ["\n  margin-top: auto;\n  width: 100%;\n"])));
export var MapWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #999;\n\n  width: 100%;\n  height: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #999;\n\n  width: 100%;\n  height: ", ";\n"])), rem(240));
export var PlacesWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: ", ";\n  position: relative;\n\n  &:not(:last-child) {\n    border-bottom: ", " solid ", ";\n  }\n"], ["\n  margin-top: ", ";\n  position: relative;\n\n  &:not(:last-child) {\n    border-bottom: ", " solid ", ";\n  }\n"])), rem(8), rem(1), border);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
