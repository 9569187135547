/* eslint-disable react/button-has-type */
import { Button } from 'components';
import { toggleFontSize } from 'containers/App/actions';
import { useAction } from 'hooks';
import { FontSizeWhite } from 'icons/color';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
export var ToggleFontSize = memo(function () {
    var handleFontSize = useAction(toggleFontSize);
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Button, { variant: "link", onClick: handleFontSize, style: {
            width: rem(38),
            height: rem(38),
        } },
        React.createElement(FontSizeWhite, { title: formatMessage({ id: 'components.nav.changeFontSize' }), width: rem(25), height: rem(20) })));
});
