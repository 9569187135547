/* eslint-disable @typescript-eslint/no-use-before-define */
import { TabsWithPagination } from 'components';
import { fetchSubscribers } from 'containers/Subscribers/store/subscribers/actions';
import { fetchSubscriptions } from 'containers/Subscribers/store/subscriptions/actions';
import { useAction, useSelector } from 'hooks';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import makeSubscribers from './store/subscribers/selectors';
import makeSubscriptions from './store/subscriptions/selectors';
import { Container } from './styles';
import SubscribersList from './SubscribersList';
import SubscribersTabTitle from './SubscribersTabTitle';
import SubscriptionsList from './SubscriptionsList';
import SubscriptionsTabTitle from './SubscriptionsTabTitle';
var Subscribers = function (_a) {
    var activeTab = _a.activeTab, userSlug = _a.userSlug, _b = _a.showSubscribeButton, showSubscribeButton = _b === void 0 ? true : _b;
    var PER_PAGE = 5;
    var DEFAULT_ACTIVE_TAB = 'subscriptions';
    var params = useParams();
    var location = useLocation();
    var history = useHistory();
    var subscribers = useSelector(makeSubscribers());
    var subscriptions = useSelector(makeSubscriptions());
    var fetchSubscribersRequest = useAction(function (payload) {
        return fetchSubscribers(payload);
    });
    var fetchSubscriptionsRequest = useAction(function (payload) {
        return fetchSubscriptions(payload);
    });
    var handleSubscriptionsPage = function (page) { return loadSubscriptions(page); };
    var handleSubscribersPage = function (page) { return loadSubscribers(page); };
    var handleTab = function (activeKey) {
        return history.push(location.pathname + "?tab=" + activeKey, {
            from: location.pathname,
        });
    };
    var loadSubscriptions = function (page) {
        var payload = {
            page: page,
            perPage: PER_PAGE,
            userSlug: userSlug,
        };
        fetchSubscriptionsRequest(payload);
    };
    var loadSubscribers = function (page) {
        var payload = {
            page: page,
            perPage: PER_PAGE,
            userSlug: userSlug,
        };
        fetchSubscribersRequest(payload);
    };
    useEffect(function () {
        loadSubscriptions(1);
        loadSubscribers(1);
    }, []);
    var tabs = [
        {
            key: 'subscriptions',
            title: React.createElement(SubscriptionsTabTitle, null),
            perPage: PER_PAGE,
            total: subscriptions.total,
            onPageChange: handleSubscriptionsPage,
            loading: subscriptions.loading,
            currentPage: subscriptions.currentPage,
            data: (React.createElement(SubscriptionsList, { showSubscribeButton: showSubscribeButton, subscriptions: subscriptions.items, loading: subscriptions.loading, slug: params.slug })),
        },
        {
            key: 'subscribers',
            title: React.createElement(SubscribersTabTitle, null),
            perPage: PER_PAGE,
            total: subscribers.total,
            onPageChange: handleSubscribersPage,
            loading: subscribers.loading,
            currentPage: subscribers.currentPage,
            data: (React.createElement(SubscribersList, { showSubscribeButton: showSubscribeButton, subscribers: subscribers.items, loading: subscribers.loading, slug: params.slug })),
        },
    ];
    return (React.createElement(Container, null,
        React.createElement(TabsWithPagination, { defaultActiveKey: activeTab || DEFAULT_ACTIVE_TAB, tabs: tabs, onChange: handleTab, activeKey: activeTab || DEFAULT_ACTIVE_TAB })));
};
export default Subscribers;
