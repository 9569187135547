import React, { memo } from 'react';
export var MovieRentalOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#b3b3b3", style: { fill: '#b3b3b3' }, d: "M31.086 0.305h-30.476v30.476h30.476v-30.476z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M23.124 15.719c-0.227 0-0.267 0.058-0.571 0.16v-1.575c0-0.239-0.169-0.285-0.408-0.285h-2.474l-0.342 3.333-3.566 1.371-3.566-1.36-0.343-3.344h-2.518c-0.239 0-0.498 0.046-0.498 0.285v1.576c0-0.102-0.345-0.161-0.572-0.161-0.723 0-1.256 0.588-1.256 1.312v2.14c0 0.723 0.543 1.248 1.266 1.248h0.868v0.914h1.219v-0.914h10.667v0.914h1.219v-0.914h0.958c0.723 0 1.175-0.526 1.175-1.248v-2.14c0-0.724-0.535-1.312-1.257-1.312z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M18.759 8.051c-0.519 0-0.946 0.382-1.024 0.879-0.187-0.18-0.44-0.292-0.721-0.292-0.198 0-0.382 0.056-0.539 0.151 0.006-0.050 0.009-0.1 0.009-0.151 0-0.052-0.004-0.104-0.009-0.155 0.158 0.096 0.341 0.155 0.539 0.155 0.574 0 1.040-0.466 1.040-1.040s-0.42-1.040-0.994-1.040c-0.014 0 0.006 0.002 0.006 0.002s0-0.001 0-0.002c0-0.456-0.416-0.825-0.872-0.825s-0.848 0.369-0.848 0.825c0 0.405 0.28 0.742 0.665 0.812-0.015 0.065-0.029 0.133-0.030 0.202-0.237-0.193-0.541-0.309-0.87-0.309-0.053 0-0.106 0.004-0.157 0.009-0.183-0.298-0.51-0.5-0.885-0.5-0.574 0-1.040 0.466-1.040 1.040 0 0.46 0.3 0.846 0.714 0.983 0.003 0.025 0.007 0.049 0.011 0.073-0.052-0.010-0.107-0.016-0.162-0.016-0.116 0-0.225 0.024-0.325 0.067-0.079-0.373-0.41-0.654-0.806-0.654-0.456 0-0.826 0.369-0.826 0.826s0.369 0.825 0.826 0.825c0.116 0 0.225-0.024 0.325-0.067 0.079 0.374 0.41 0.654 0.807 0.654 0.409 0 0.745-0.297 0.811-0.688 0.080 0.048 0.164 0.088 0.253 0.119-0.015 0.071-0.024 0.144-0.024 0.22 0 0.574 0.465 1.040 1.040 1.040 0.403 0 0.752-0.23 0.924-0.565 0.128 0.056 0.27 0.088 0.418 0.088 0.519 0 0.946-0.382 1.024-0.879 0.187 0.18 0.44 0.293 0.721 0.293 0.575 0 1.040-0.466 1.040-1.040s-0.465-1.040-1.040-1.040z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M12.361 10.757l0.905 0.287 0.306 6.136-0.584-0.231-0.627-6.192z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M15.324 17.873l-0.717-0.283-0.14-6.163 0.857 0.273v6.173z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M16.17 11.72l0.887-0.281-0.14 6.161-0.748 0.294v-6.174z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M18.536 16.963l-0.583 0.23 0.306-6.135 0.904-0.286-0.627 6.192z" })));
});
