var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/camelcase */
import { selectLanguageProviderDomain } from 'containers/LanguageProvider/selectors';
import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { store } from 'store';
import { signUp } from './actions';
import SignUpForm from './Form';
import signUpEmailSchema, { defaultProps } from './schemas';
export var SignUp = withFormik({
    mapPropsToValues: function (props) { return (__assign({ from: props.from }, defaultProps)); },
    validationSchema: function (props) { return signUpEmailSchema(props.intl.formatMessage); },
    handleSubmit: function (_a, _b) {
        var email = _a.email, pseudo = _a.pseudo, password = _a.password, googlePlaceId = _a.googlePlaceId, profileId = _a.profileId, acceptsNewsletter = _a.acceptsNewsletter;
        var props = _b.props, setSubmitting = _b.setSubmitting, resetForm = _b.resetForm, setErrors = _b.setErrors, setStatus = _b.setStatus;
        // @ts-ignore
        var locale = selectLanguageProviderDomain(store.getState()).locale;
        var payload = {
            email: email,
            pseudo: pseudo,
            password: password,
            google_place_id: googlePlaceId,
            profile_id: profileId,
            accepts_newsletter: acceptsNewsletter,
        };
        var actions = {
            resetForm: resetForm,
            setErrors: setErrors,
            setSubmitting: setSubmitting,
            setStatus: setStatus,
        };
        var meta = {
            from: props.from,
        };
        props.handleSignUp(payload, actions, meta);
    },
    displayName: 'SignUpByEmail',
})(SignUpForm);
var mapStateToProps = function (state, ownprops) { return ({
    from: ownprops.from,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    handleSignUp: function (payload, actions, meta) {
        dispatch(signUp(payload, actions, meta));
    },
}); };
var SignUpIntl = injectIntl(SignUp);
var ConnectSignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpIntl);
export default ConnectSignUp;
