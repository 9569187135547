import { createSelector } from 'reselect';
var selectRouterProviderDomain = function (state) { return state.router; };
var makeSelectLocation = function () {
    return createSelector(selectRouterProviderDomain, function (routerState) { return routerState.location; });
};
var makeSelectRouterProvider = function () {
    return createSelector(selectRouterProviderDomain, function (subState) { return subState; });
};
export default makeSelectRouterProvider;
export { selectRouterProviderDomain, makeSelectLocation };
