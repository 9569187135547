var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var MedalPhotoDesktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "6", y: "8", width: "34", height: "32" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.45161 8.64516H40V39.6192H6.45161V8.64516Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36.129 13.8128H33.5484C31.4065 13.8128 29.6774 12.0773 29.6774 9.94181C29.6774 9.22568 29.0968 8.64503 28.3871 8.64503V8.65149H19.3548C18.6452 8.65149 18.0645 9.22568 18.0645 9.93536V9.94181C18.0645 12.0773 16.3355 13.8128 14.1935 13.8128H10.329H6.45161C8.59355 13.8128 10.3226 15.5418 10.3226 17.6837V35.7418C10.3226 37.8837 8.59355 39.6128 6.45161 39.6128V39.6192H36.129C38.2581 39.6192 40 37.8773 40 35.7483V17.6837C40 15.5547 38.2581 13.8128 36.129 13.8128Z", fill: "#545C66" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.6129 28.0013C31.6129 23.7303 28.1484 20.2594 23.871 20.2594C19.6 20.2594 16.129 23.7303 16.129 28.0013C16.129 32.2787 19.6 35.7432 23.871 35.7432C28.1484 35.7432 31.6129 32.2787 31.6129 28.0013ZM33.8774 25.4394C34.0839 26.2594 34.1935 27.1174 34.1935 28.0013C34.1935 28.8852 34.0839 29.7497 33.8774 30.5684C32.729 35.0271 28.6903 38.3239 23.871 38.3239C18.1742 38.3239 13.5484 33.7045 13.5484 28.0013C13.5484 22.3045 18.1742 17.6787 23.871 17.6787C28.6903 17.6787 32.729 20.9748 33.8774 25.4394Z", fill: "#434A54" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.68375 11.8773C10.0386 11.8773 10.3289 12.1676 10.3289 12.5225V13.8134C10.3289 14.1683 10.0386 14.4586 9.68375 14.4586H7.09665H5.80633H4.516C4.16116 14.4586 3.87084 14.1683 3.87084 13.8134V12.5225C3.87084 12.1676 4.16116 11.8773 4.516 11.8773H9.68375Z", fill: "#AAB2BD" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.1935 24.1354C35.6193 24.1354 36.7742 25.2902 36.7742 26.716C36.7742 28.1418 35.6193 29.2966 34.1935 29.2966C34.0839 29.2966 33.9806 29.2902 33.8774 29.2773C34.0839 28.4579 34.1935 27.5941 34.1935 26.7102C34.1935 25.8263 34.0839 24.9683 33.8774 24.1483C33.9806 24.1354 34.0839 24.1354 34.1935 24.1354Z", fill: "#AAB2BD" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.871 18.9678C28.1484 18.9678 31.6129 22.4388 31.6129 26.7097C31.6129 30.9872 28.1484 34.4517 23.871 34.4517C19.6 34.4517 16.129 30.9872 16.129 26.7097C16.129 22.4388 19.6 18.9678 23.871 18.9678Z", fill: "#5D9CEC" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.871 18.9678C23.4297 18.9678 23.0006 19.013 22.58 19.0846C26.2406 19.7014 29.0297 22.8801 29.0297 26.7097C29.0297 30.5459 26.2406 33.7194 22.58 34.3349C23.0006 34.4059 23.4297 34.4517 23.871 34.4517C28.1484 34.4517 31.6129 30.9872 31.6129 26.7097C31.6129 22.4388 28.1484 18.9678 23.871 18.9678Z", fill: "#4A89DC" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.6129 26.7097C31.6129 22.4388 28.1484 18.9678 23.871 18.9678C19.6 18.9678 16.129 22.4388 16.129 26.7097C16.129 30.9872 19.6 34.4517 23.871 34.4517C28.1484 34.4517 31.6129 30.9872 31.6129 26.7097ZM33.8774 24.1478C34.0839 24.9678 34.1935 25.8259 34.1935 26.7097C34.1935 27.5936 34.0839 28.4581 33.8774 29.2768C32.729 33.7355 28.6903 37.0323 23.871 37.0323C18.1742 37.0323 13.5484 32.413 13.5484 26.7097C13.5484 21.013 18.1742 16.3872 23.871 16.3872C28.6903 16.3872 32.729 19.6833 33.8774 24.1478Z", fill: "#656D78" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.3226 17.6837V35.7418C10.3226 37.8837 8.59355 39.6128 6.45161 39.6128V39.6192H3.87097C1.74194 39.6192 0 37.8773 0 35.7483V17.6837C0 15.5547 1.74194 13.8128 3.87097 13.8128H6.45161C8.59355 13.8128 10.3226 15.5418 10.3226 17.6837Z", fill: "#656D78" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.0974 11.8736H20.6445C20.2877 11.8736 19.9994 11.5852 19.9994 11.2284C19.9994 10.8716 20.2877 10.5832 20.6445 10.5832H27.0974C27.4542 10.5832 27.7426 10.8716 27.7426 11.2284C27.7426 11.5852 27.4542 11.8736 27.0974 11.8736Z", fill: "#656D78" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.1613 13.8097H22.5806C22.2239 13.8097 21.9355 13.5213 21.9355 13.1645C21.9355 12.8077 22.2239 12.5194 22.5806 12.5194H25.1613C25.5181 12.5194 25.8065 12.8077 25.8065 13.1645C25.8065 13.5213 25.5181 13.8097 25.1613 13.8097Z", fill: "#656D78" })));
});
