import produce from 'immer';
import { PROFILE_MY_FAVORITES_FAILURE, PROFILE_MY_FAVORITES_REQUEST, PROFILE_MY_FAVORITES_SUCCESS, } from '../constants';
export var myFavoritesInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    pages: 1,
    currentPage: 1,
    total: 0,
    items: [],
};
export var myFavoritesReducer = produce(function (draft, action) {
    switch (action.type) {
        case PROFILE_MY_FAVORITES_REQUEST:
            draft.loading = true;
            break;
        case PROFILE_MY_FAVORITES_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case PROFILE_MY_FAVORITES_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.currentPage = action.payload.currentPage;
            draft.pages = action.payload.pages;
            draft.total = action.payload.total;
            draft.items = action.payload.items;
            break;
        default:
    }
}, myFavoritesInitialState);
