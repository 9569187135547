var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var outline = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
var hover = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  content: '';\n  width: calc(100% - ", ");\n  display: block;\n  position: absolute;\n  border-radius: ", ";\n  left: ", ";\n  z-index: 1;\n"], ["\n  ", ";\n  content: '';\n  width: calc(100% - ", ");\n  display: block;\n  position: absolute;\n  border-radius: ", ";\n  left: ", ";\n  z-index: 1;\n"])), outline, rem(40), rem(10), rem(16));
export var AccessibilityPhotosContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", ";\n  border-bottom: ", " solid ", ";\n\n  button {\n    margin: ", " auto 0;\n  }\n"], ["\n  padding: ", ";\n  border-bottom: ", " solid ", ";\n\n  button {\n    margin: ", " auto 0;\n  }\n"])), rem(16), rem(1), border, rem(8));
export var SingleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: ", ";\n\n  img {\n    flex: 1;\n    height: 30vw;\n    max-height: ", ";\n  }\n\n  ", ";\n"], ["\n  display: flex;\n  margin-bottom: ", ";\n\n  img {\n    flex: 1;\n    height: 30vw;\n    max-height: ", ";\n  }\n\n  ",
    ";\n"])), rem(16), rem(230), ifProp('isKeyboardMode', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      &:focus {\n        &::before {\n          ", ";\n          height: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        &::before {\n          ", ";\n          height: ", ";\n        }\n      }\n    "])), hover, rem(222))));
export var DoubleWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: ", ";\n\n  img {\n    flex: 1;\n    height: 25vw;\n    max-height: ", ";\n    width: calc(50% - ", ");\n\n    &:first-child {\n      margin-right: ", ";\n    }\n\n    &:last-child {\n      margin-left: ", ";\n    }\n  }\n\n  ", ";\n"], ["\n  display: flex;\n  margin-bottom: ", ";\n\n  img {\n    flex: 1;\n    height: 25vw;\n    max-height: ", ";\n    width: calc(50% - ", ");\n\n    &:first-child {\n      margin-right: ", ";\n    }\n\n    &:last-child {\n      margin-left: ", ";\n    }\n  }\n\n  ",
    ";\n"])), rem(16), rem(170), rem(48), rem(8), rem(8), ifProp('isKeyboardMode', css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      &:focus {\n        &::before {\n          ", ";\n          height: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        &::before {\n          ", ";\n          height: ", ";\n        }\n      }\n    "])), hover, rem(162))));
export var TrippleWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), ifProp('isKeyboardMode', css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      &:focus {\n        &::before {\n          ", ";\n          height: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        &::before {\n          ", ";\n          height: ", ";\n        }\n      }\n    "])), hover, rem(408))));
export var Image = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border-radius: ", ";\n  object-fit: cover;\n"], ["\n  border-radius: ", ";\n  object-fit: cover;\n"])), rem(5));
export var Overlay = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  transition: opacity 0.2s;\n  z-index: 4000;\n\n  .ant-spin-container,\n  .ant-spin-nested-loading {\n    height: 100%;\n  }\n\n  ", "\n"], ["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  transition: opacity 0.2s;\n  z-index: 4000;\n\n  .ant-spin-container,\n  .ant-spin-nested-loading {\n    height: 100%;\n  }\n\n  ",
    "\n"])), rgba(colors.base.black, 0.7), ifProp('visible', css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      opacity: 1;\n    "], ["\n      opacity: 1;\n    "])))));
export var Carousel = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n\n  .swiper-pagination-fraction {\n    color: ", ";\n    font-weight: ", ";\n    margin-bottom: 8vh;\n    text-shadow: 0 ", " ", " ", " !important;\n  }\n"], ["\n  height: 100%;\n  display: flex;\n\n  .swiper-pagination-fraction {\n    color: ", ";\n    font-weight: ", ";\n    margin-bottom: 8vh;\n    text-shadow: 0 ", " ", " ", " !important;\n  }\n"])), colors.base.white, fonts.weight.semibold, rem(2), rem(6), rgba(colors.base.black, 0.2));
export var Slide = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  min-width: 100%;\n  transition: 0.3s;\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  min-width: 100%;\n  transition: 0.3s;\n"])));
export var CarouselImage = styled.img(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  border-radius: ", ";\n  max-height: 70%;\n  max-width: 85%;\n  object-fit: contain;\n"], ["\n  border-radius: ", ";\n  max-height: 70%;\n  max-width: 85%;\n  object-fit: contain;\n"])), rem(12));
export var FooterButtons = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  bottom: ", ";\n  display: flex;\n  left: 0;\n  margin: 0 ", ";\n  position: fixed;\n  right: 0;\n  z-index: 5000;\n\n  div {\n    bottom: 0;\n    position: absolute;\n  }\n\n  div:nth-child(1) {\n    display: flex;\n    left: 0;\n\n    button {\n      margin-right: ", ";\n      justify-self: flex-end;\n    }\n  }\n\n  div:nth-child(2) {\n    right: 0;\n  }\n\n  button {\n    border-radius: ", ";\n    border: 0;\n    bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    box-shadow: 0 3px 6px -4px ", ",\n      0 6px 16px 0 ", ",\n      0 9px 28px 8px ", ";\n    height: ", ";\n    width: ", ";\n\n    > svg {\n      flex-shrink: 0;\n    }\n  }\n"], ["\n  bottom: ", ";\n  display: flex;\n  left: 0;\n  margin: 0 ", ";\n  position: fixed;\n  right: 0;\n  z-index: 5000;\n\n  div {\n    bottom: 0;\n    position: absolute;\n  }\n\n  div:nth-child(1) {\n    display: flex;\n    left: 0;\n\n    button {\n      margin-right: ", ";\n      justify-self: flex-end;\n    }\n  }\n\n  div:nth-child(2) {\n    right: 0;\n  }\n\n  button {\n    border-radius: ", ";\n    border: 0;\n    bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    box-shadow: 0 3px 6px -4px ", ",\n      0 6px 16px 0 ", ",\n      0 9px 28px 8px ", ";\n    height: ", ";\n    width: ", ";\n\n    > svg {\n      flex-shrink: 0;\n    }\n  }\n"])), rem(16), rem(16), rem(8), rem(15), rem(16), rgba(colors.base.black, 0.12), rgba(colors.base.black, 0.08), rgba(colors.base.black, 0.05), rem(40), rem(40));
export var navigationButtonBase = css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  align-items: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  display: flex !important;\n  height: ", " !important;\n  justify-content: center !important;\n  position: fixed !important;\n  top: 65% !important;\n  width: ", " !important;\n  z-index: 10 !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  align-items: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  display: flex !important;\n  height: ", " !important;\n  justify-content: center !important;\n  position: fixed !important;\n  top: 65% !important;\n  width: ", " !important;\n  z-index: 10 !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), colors.base.white, rem(1), colors.space.tint100, rem(2), rem(6), rgba(colors.base.black, 0.2), rem(40), rem(40));
export var PrevButton = styled(Button)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  ", "\n  left: ", " !important;\n"], ["\n  ", "\n  left: ", " !important;\n"])), navigationButtonBase, rem(16));
export var NextButton = styled(Button)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  ", "\n  right: ", " !important;\n"], ["\n  ", "\n  right: ", " !important;\n"])), navigationButtonBase, rem(16));
export var ModalButtonWrapper = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(26));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
