var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Input } from 'components';
import { useField } from 'formik';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Container, ErrorMessage, LabelWrapper } from './styles';
export var PseudoField = memo(function (props) {
    var formatMessage = useIntl().formatMessage;
    var _a = __read(useField('pseudo'), 2), field = _a[0], meta = _a[1];
    var required = props.required || false;
    return (React.createElement(Container, { validateStatus: !!meta.error && meta.touched ? 'error' : '' },
        props.showLabel && (React.createElement(LabelWrapper, { hasError: !!meta.error && meta.touched },
            React.createElement("label", null,
                formatMessage({ id: 'containers.signUpEmail.pseudoLabel' }),
                required && ' *'))),
        React.createElement(Input, { type: "text", required: required, placeholder: formatMessage({
                id: 'containers.signUpEmail.pseudoPlaceholder',
            }), name: field.name, value: field.value, onChange: field.onChange, onBlur: field.onBlur, disabled: props.disabled, autoComplete: "on", autoCapitalize: "off" }),
        !!meta.error && meta.touched ? (React.createElement(ErrorMessage, null, meta.error)) : null));
});
