import { CONTRIBUTOR_USER_FAVORITES_FAILURE, CONTRIBUTOR_USER_FAVORITES_REQUEST, CONTRIBUTOR_USER_FAVORITES_SUCCESS, } from '../constants';
export var fetchContributorUserFavoriteRequest = function (payload) { return ({
    type: CONTRIBUTOR_USER_FAVORITES_REQUEST,
    payload: payload,
}); };
export var fetchContributorUserFavoriteSuccess = function (payload) { return ({
    type: CONTRIBUTOR_USER_FAVORITES_SUCCESS,
    payload: payload,
}); };
export var fetchContributorUserFavoriteError = function (error) { return ({
    type: CONTRIBUTOR_USER_FAVORITES_FAILURE,
    payload: error,
}); };
