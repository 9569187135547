var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { marker } from '../id';
import { Accounting } from './Accounting';
import { AccountingOff } from './AccountingOff';
import { Airport } from './Airport';
import { AirportOff } from './AirportOff';
import { AmusementPark } from './AmusementPark';
import { AmusementParkOff } from './AmusementParkOff';
import { Aquarium } from './Aquarium';
import { AquariumOff } from './AquariumOff';
import { ArtGallery } from './ArtGallery';
import { ArtGalleryOff } from './ArtGalleryOff';
import { Atm } from './Atm';
import { AtmOff } from './AtmOff';
import { Bakery } from './Bakery';
import { BakeryOff } from './BakeryOff';
import { Bank } from './Bank';
import { BankOff } from './BankOff';
import { Bar } from './Bar';
import { BarOff } from './BarOff';
import { BeautySalon } from './BeautySalon';
import { BeautySalonOff } from './BeautySalonOff';
import { BicycleStore } from './BicycleStore';
import { BicycleStoreOff } from './BicycleStoreOff';
import { BookStore } from './BookStore';
import { BookStoreOff } from './BookStoreOff';
import { BowlingAlley } from './BowlingAlley';
import { BowlingAlleyOff } from './BowlingAlleyOff';
import { BusStation } from './BusStation';
import { BusStationOff } from './BusStationOff';
import { Cafe } from './Cafe';
import { CafeOff } from './CafeOff';
import { Campground } from './Campground';
import { CampgroundOff } from './CampgroundOff';
import { CarDealer } from './CarDealer';
import { CarDealerOff } from './CarDealerOff';
import { CarRental } from './CarRental';
import { CarRentalOff } from './CarRentalOff';
import { CarRepair } from './CarRepair';
import { CarRepairOff } from './CarRepairOff';
import { CarWash } from './CarWash';
import { CarWashOff } from './CarWashOff';
import { Casino } from './Casino';
import { CasinoOff } from './CasinoOff';
import { Cemetery } from './Cemetery';
import { CemeteryOff } from './CemeteryOff';
import { Church } from './Church';
import { ChurchOff } from './ChurchOff';
import { CityHall } from './CityHall';
import { CityHallOff } from './CityHallOff';
import { ClothingStore } from './ClothingStore';
import { ClothingStoreOff } from './ClothingStoreOff';
import { ConvenienceStore } from './ConvenienceStore';
import { ConvenienceStoreOff } from './ConvenienceStoreOff';
import { Courthouse } from './Courthouse';
import { CourthouseOff } from './CourthouseOff';
import { Dentist } from './Dentist';
import { DentistOff } from './DentistOff';
import { DepartmentStore } from './DepartmentStore';
import { DepartmentStoreOff } from './DepartmentStoreOff';
import { Doctor } from './Doctor';
import { DoctorOff } from './DoctorOff';
import { Electrician } from './Electrician';
import { ElectricianOff } from './ElectricianOff';
import { ElectronicsStore } from './ElectronicsStore';
import { ElectronicsStoreOff } from './ElectronicsStoreOff';
import { Embassy } from './Embassy';
import { EmbassyOff } from './EmbassyOff';
import { Establishment } from './Establishment';
import { EstablishmentOff } from './EstablishmentOff';
import { FireStation } from './FireStation';
import { FireStationOff } from './FireStationOff';
import { Florist } from './Florist';
import { FloristOff } from './FloristOff';
import { FuneralHome } from './FuneralHome';
import { FuneralHomeOff } from './FuneralHomeOff';
import { FurnitureStore } from './FurnitureStore';
import { FurnitureStoreOff } from './FurnitureStoreOff';
import { GasStation } from './GasStation';
import { GasStationOff } from './GasStationOff';
import { GroceryOrSupermarket } from './GroceryOrSupermarket';
import { GroceryOrSupermarketOff } from './GroceryOrSupermarketOff';
import { Gym } from './Gym';
import { GymOff } from './GymOff';
import { HairCare } from './HairCare';
import { HairCareOff } from './HairCareOff';
import { HardwareStore } from './HardwareStore';
import { HardwareStoreOff } from './HardwareStoreOff';
import { HinduTemple } from './HinduTemple';
import { HinduTempleOff } from './HinduTempleOff';
import { HomeGoodsStore } from './HomeGoodsStore';
import { HomeGoodsStoreOff } from './HomeGoodsStoreOff';
import { Hospital } from './Hospital';
import { HospitalOff } from './HospitalOff';
import { InsuranceAgency } from './InsuranceAgency';
import { InsuranceAgencyOff } from './InsuranceAgencyOff';
import { JewelryStore } from './JewelryStore';
import { JewelryStoreOff } from './JewelryStoreOff';
import { Laundry } from './Laundry';
import { LaundryOff } from './LaundryOff';
import { Lawyer } from './Lawyer';
import { LawyerOff } from './LawyerOff';
import { Library } from './Library';
import { LibraryOff } from './LibraryOff';
import { LiquorStore } from './LiquorStore';
import { LiquorStoreOff } from './LiquorStoreOff';
import { LocalGovernmentOffice } from './LocalGovernmentOffice';
import { LocalGovernmentOfficeOff } from './LocalGovernmentOfficeOff';
import { Locksmith } from './Locksmith';
import { LocksmithOff } from './LocksmithOff';
import { Lodging } from './Lodging';
import { LodgingOff } from './LodgingOff';
import { MealDelivery } from './MealDelivery';
import { MealDeliveryOff } from './MealDeliveryOff';
import { MealTakeaway } from './MealTakeaway';
import { MealTakeawayOff } from './MealTakeawayOff';
import { Mosque } from './Mosque';
import { MosqueOff } from './MosqueOff';
import { MovieRental } from './MovieRental';
import { MovieRentalOff } from './MovieRentalOff';
import { MovieTheater } from './MovieTheater';
import { MovieTheaterOff } from './MovieTheaterOff';
import { Museum } from './Museum';
import { MuseumOff } from './MuseumOff';
import { NightClub } from './NightClub';
import { NightClubOff } from './NightClubOff';
import { Park } from './Park';
import { Parking } from './Parking';
import { ParkingOff } from './ParkingOff';
import { ParkOff } from './ParkOff';
import { PetStore } from './PetStore';
import { PetStoreOff } from './PetStoreOff';
import { Pharmacy } from './Pharmacy';
import { PharmacyOff } from './PharmacyOff';
import { Physiotherapist } from './Physiotherapist';
import { PhysiotherapistOff } from './PhysiotherapistOff';
import { Police } from './Police';
import { PoliceOff } from './PoliceOff';
import { PostOffice } from './PostOffice';
import { PostOfficeOff } from './PostOfficeOff';
import { RealEstateAgency } from './RealEstateAgency';
import { RealEstateAgencyOff } from './RealEstateAgencyOff';
import { Restaurant } from './Restaurant';
import { RestaurantOff } from './RestaurantOff';
import { RvPark } from './RvPark';
import { RvParkOff } from './RvParkOff';
import { School } from './School';
import { SchoolOff } from './SchoolOff';
import { ShoeStore } from './ShoeStore';
import { ShoeStoreOff } from './ShoeStoreOff';
import { ShoppingMall } from './ShoppingMall';
import { ShoppingMallOff } from './ShoppingMallOff';
import { Spa } from './Spa';
import { SpaOff } from './SpaOff';
import { Stadium } from './Stadium';
import { StadiumOff } from './StadiumOff';
import { Storage } from './Storage';
import { StorageOff } from './StorageOff';
import { Store } from './Store';
import { StoreOff } from './StoreOff';
import { SubwayStation } from './SubwayStation';
import { SubwayStationOff } from './SubwayStationOff';
import { Synagogue } from './Synagogue';
import { SynagogueOff } from './SynagogueOff';
import { TaxiStand } from './TaxiStand';
import { TaxiStandOff } from './TaxiStandOff';
import { TrainStation } from './TrainStation';
import { TrainStationOff } from './TrainStationOff';
import { TransitStation } from './TransitStation';
import { TransitStationOff } from './TransitStationOff';
import { TravelAgency } from './TravelAgency';
import { TravelAgencyOff } from './TravelAgencyOff';
import { University } from './University';
import { UniversityOff } from './UniversityOff';
import { VeterinaryCare } from './VeterinaryCare';
import { VeterinaryCareOff } from './VeterinaryCareOff';
import { Zoo } from './Zoo';
import { ZooOff } from './ZooOff';
export var markerIcons = function (props) {
    var _a;
    return (_a = {},
        _a[marker.accountingOff] = React.createElement(AccountingOff, __assign({}, props)),
        _a[marker.airportOff] = React.createElement(AirportOff, __assign({}, props)),
        _a[marker.amusementParkOff] = React.createElement(AmusementParkOff, __assign({}, props)),
        _a[marker.aquariumOff] = React.createElement(AquariumOff, __assign({}, props)),
        _a[marker.artGalleryOff] = React.createElement(ArtGalleryOff, __assign({}, props)),
        _a[marker.atmOff] = React.createElement(AtmOff, __assign({}, props)),
        _a[marker.bakeryOff] = React.createElement(BakeryOff, __assign({}, props)),
        _a[marker.bankOff] = React.createElement(BankOff, __assign({}, props)),
        _a[marker.barOff] = React.createElement(BarOff, __assign({}, props)),
        _a[marker.beautySalonOff] = React.createElement(BeautySalonOff, __assign({}, props)),
        _a[marker.bicycleStoreOff] = React.createElement(BicycleStoreOff, __assign({}, props)),
        _a[marker.bookStoreOff] = React.createElement(BookStoreOff, __assign({}, props)),
        _a[marker.bowlingAlleyOff] = React.createElement(BowlingAlleyOff, __assign({}, props)),
        _a[marker.busStationOff] = React.createElement(BusStationOff, __assign({}, props)),
        _a[marker.cafeOff] = React.createElement(CafeOff, __assign({}, props)),
        _a[marker.campgroundOff] = React.createElement(CampgroundOff, __assign({}, props)),
        _a[marker.carDealerOff] = React.createElement(CarDealerOff, __assign({}, props)),
        _a[marker.carRentalOff] = React.createElement(CarRentalOff, __assign({}, props)),
        _a[marker.carRepairOff] = React.createElement(CarRepairOff, __assign({}, props)),
        _a[marker.carWashOff] = React.createElement(CarWashOff, __assign({}, props)),
        _a[marker.casinoOff] = React.createElement(CasinoOff, __assign({}, props)),
        _a[marker.cemeteryOff] = React.createElement(CemeteryOff, __assign({}, props)),
        _a[marker.churchOff] = React.createElement(ChurchOff, __assign({}, props)),
        _a[marker.cityHallOff] = React.createElement(CityHallOff, __assign({}, props)),
        _a[marker.clothingStoreOff] = React.createElement(ClothingStoreOff, __assign({}, props)),
        _a[marker.convenienceStoreOff] = React.createElement(ConvenienceStoreOff, __assign({}, props)),
        _a[marker.courthouseOff] = React.createElement(CourthouseOff, __assign({}, props)),
        _a[marker.dentistOff] = React.createElement(DentistOff, __assign({}, props)),
        _a[marker.departmentStoreOff] = React.createElement(DepartmentStoreOff, __assign({}, props)),
        _a[marker.doctorOff] = React.createElement(DoctorOff, __assign({}, props)),
        _a[marker.electricianOff] = React.createElement(ElectricianOff, __assign({}, props)),
        _a[marker.electronicsStoreOff] = React.createElement(ElectronicsStoreOff, __assign({}, props)),
        _a[marker.embassyOff] = React.createElement(EmbassyOff, __assign({}, props)),
        _a[marker.establishmentOff] = React.createElement(EstablishmentOff, __assign({}, props)),
        _a[marker.fireStationOff] = React.createElement(FireStationOff, __assign({}, props)),
        _a[marker.floristOff] = React.createElement(FloristOff, __assign({}, props)),
        _a[marker.funeralHomeOff] = React.createElement(FuneralHomeOff, __assign({}, props)),
        _a[marker.furnitureStoreOff] = React.createElement(FurnitureStoreOff, __assign({}, props)),
        _a[marker.gasStationOff] = React.createElement(GasStationOff, __assign({}, props)),
        _a[marker.groceryOrSupermarketOff] = React.createElement(GroceryOrSupermarketOff, __assign({}, props)),
        _a[marker.gymOff] = React.createElement(GymOff, __assign({}, props)),
        _a[marker.hairCareOff] = React.createElement(HairCareOff, __assign({}, props)),
        _a[marker.hardwareStoreOff] = React.createElement(HardwareStoreOff, __assign({}, props)),
        _a[marker.hinduTempleOff] = React.createElement(HinduTempleOff, __assign({}, props)),
        _a[marker.homeGoodsStoreOff] = React.createElement(HomeGoodsStoreOff, __assign({}, props)),
        _a[marker.hospitalOff] = React.createElement(HospitalOff, __assign({}, props)),
        _a[marker.insuranceAgencyOff] = React.createElement(InsuranceAgencyOff, __assign({}, props)),
        _a[marker.jewelryStoreOff] = React.createElement(JewelryStoreOff, __assign({}, props)),
        _a[marker.laundryOff] = React.createElement(LaundryOff, __assign({}, props)),
        _a[marker.lawyerOff] = React.createElement(LawyerOff, __assign({}, props)),
        _a[marker.libraryOff] = React.createElement(LibraryOff, __assign({}, props)),
        _a[marker.liquorStoreOff] = React.createElement(LiquorStoreOff, __assign({}, props)),
        _a[marker.localGovernmentOfficeOff] = React.createElement(LocalGovernmentOfficeOff, __assign({}, props)),
        _a[marker.locksmithOff] = React.createElement(LocksmithOff, __assign({}, props)),
        _a[marker.lodgingOff] = React.createElement(LodgingOff, __assign({}, props)),
        _a[marker.mealDeliveryOff] = React.createElement(MealDeliveryOff, __assign({}, props)),
        _a[marker.mealTakeawayOff] = React.createElement(MealTakeawayOff, __assign({}, props)),
        _a[marker.mosqueOff] = React.createElement(MosqueOff, __assign({}, props)),
        _a[marker.movieRentalOff] = React.createElement(MovieRentalOff, __assign({}, props)),
        _a[marker.movieTheaterOff] = React.createElement(MovieTheaterOff, __assign({}, props)),
        _a[marker.museumOff] = React.createElement(MuseumOff, __assign({}, props)),
        _a[marker.nightClubOff] = React.createElement(NightClubOff, __assign({}, props)),
        _a[marker.parkOff] = React.createElement(ParkOff, __assign({}, props)),
        _a[marker.parkingOff] = React.createElement(ParkingOff, __assign({}, props)),
        _a[marker.petStoreOff] = React.createElement(PetStoreOff, __assign({}, props)),
        _a[marker.pharmacyOff] = React.createElement(PharmacyOff, __assign({}, props)),
        _a[marker.physiotherapistOff] = React.createElement(PhysiotherapistOff, __assign({}, props)),
        _a[marker.policeOff] = React.createElement(PoliceOff, __assign({}, props)),
        _a[marker.postOfficeOff] = React.createElement(PostOfficeOff, __assign({}, props)),
        _a[marker.realEstateAgencyOff] = React.createElement(RealEstateAgencyOff, __assign({}, props)),
        _a[marker.restaurantOff] = React.createElement(RestaurantOff, __assign({}, props)),
        _a[marker.rvParkOff] = React.createElement(RvParkOff, __assign({}, props)),
        _a[marker.schoolOff] = React.createElement(SchoolOff, __assign({}, props)),
        _a[marker.shoeStoreOff] = React.createElement(ShoeStoreOff, __assign({}, props)),
        _a[marker.shoppingMallOff] = React.createElement(ShoppingMallOff, __assign({}, props)),
        _a[marker.spaOff] = React.createElement(SpaOff, __assign({}, props)),
        _a[marker.stadiumOff] = React.createElement(StadiumOff, __assign({}, props)),
        _a[marker.storageOff] = React.createElement(StorageOff, __assign({}, props)),
        _a[marker.storeOff] = React.createElement(StoreOff, __assign({}, props)),
        _a[marker.subwayStationOff] = React.createElement(SubwayStationOff, __assign({}, props)),
        _a[marker.synagogueOff] = React.createElement(SynagogueOff, __assign({}, props)),
        _a[marker.taxiStandOff] = React.createElement(TaxiStandOff, __assign({}, props)),
        _a[marker.trainStationOff] = React.createElement(TrainStationOff, __assign({}, props)),
        _a[marker.transitStationOff] = React.createElement(TransitStationOff, __assign({}, props)),
        _a[marker.travelAgencyOff] = React.createElement(TravelAgencyOff, __assign({}, props)),
        _a[marker.universityOff] = React.createElement(UniversityOff, __assign({}, props)),
        _a[marker.veterinaryCareOff] = React.createElement(VeterinaryCareOff, __assign({}, props)),
        _a[marker.zooOff] = React.createElement(ZooOff, __assign({}, props)),
        _a[marker.accounting] = React.createElement(Accounting, __assign({}, props)),
        _a[marker.airport] = React.createElement(Airport, __assign({}, props)),
        _a[marker.amusementPark] = React.createElement(AmusementPark, __assign({}, props)),
        _a[marker.aquarium] = React.createElement(Aquarium, __assign({}, props)),
        _a[marker.artGallery] = React.createElement(ArtGallery, __assign({}, props)),
        _a[marker.atm] = React.createElement(Atm, __assign({}, props)),
        _a[marker.bakery] = React.createElement(Bakery, __assign({}, props)),
        _a[marker.bank] = React.createElement(Bank, __assign({}, props)),
        _a[marker.bar] = React.createElement(Bar, __assign({}, props)),
        _a[marker.beautySalon] = React.createElement(BeautySalon, __assign({}, props)),
        _a[marker.bicycleStore] = React.createElement(BicycleStore, __assign({}, props)),
        _a[marker.bookStore] = React.createElement(BookStore, __assign({}, props)),
        _a[marker.bowlingAlley] = React.createElement(BowlingAlley, __assign({}, props)),
        _a[marker.busStation] = React.createElement(BusStation, __assign({}, props)),
        _a[marker.cafe] = React.createElement(Cafe, __assign({}, props)),
        _a[marker.campground] = React.createElement(Campground, __assign({}, props)),
        _a[marker.carDealer] = React.createElement(CarDealer, __assign({}, props)),
        _a[marker.carRental] = React.createElement(CarRental, __assign({}, props)),
        _a[marker.carRepair] = React.createElement(CarRepair, __assign({}, props)),
        _a[marker.carWash] = React.createElement(CarWash, __assign({}, props)),
        _a[marker.casino] = React.createElement(Casino, __assign({}, props)),
        _a[marker.cemetery] = React.createElement(Cemetery, __assign({}, props)),
        _a[marker.church] = React.createElement(Church, __assign({}, props)),
        _a[marker.cityHall] = React.createElement(CityHall, __assign({}, props)),
        _a[marker.clothingStore] = React.createElement(ClothingStore, __assign({}, props)),
        _a[marker.convenienceStore] = React.createElement(ConvenienceStore, __assign({}, props)),
        _a[marker.courthouse] = React.createElement(Courthouse, __assign({}, props)),
        _a[marker.dentist] = React.createElement(Dentist, __assign({}, props)),
        _a[marker.departmentStore] = React.createElement(DepartmentStore, __assign({}, props)),
        _a[marker.doctor] = React.createElement(Doctor, __assign({}, props)),
        _a[marker.electrician] = React.createElement(Electrician, __assign({}, props)),
        _a[marker.electronicsStore] = React.createElement(ElectronicsStore, __assign({}, props)),
        _a[marker.embassy] = React.createElement(Embassy, __assign({}, props)),
        _a[marker.establishment] = React.createElement(Establishment, __assign({}, props)),
        _a[marker.fireStation] = React.createElement(FireStation, __assign({}, props)),
        _a[marker.florist] = React.createElement(Florist, __assign({}, props)),
        _a[marker.funeralHome] = React.createElement(FuneralHome, __assign({}, props)),
        _a[marker.furnitureStore] = React.createElement(FurnitureStore, __assign({}, props)),
        _a[marker.gasStation] = React.createElement(GasStation, __assign({}, props)),
        _a[marker.groceryOrSupermarket] = React.createElement(GroceryOrSupermarket, __assign({}, props)),
        _a[marker.gym] = React.createElement(Gym, __assign({}, props)),
        _a[marker.hairCare] = React.createElement(HairCare, __assign({}, props)),
        _a[marker.hardwareStore] = React.createElement(HardwareStore, __assign({}, props)),
        _a[marker.hinduTemple] = React.createElement(HinduTemple, __assign({}, props)),
        _a[marker.homeGoodsStore] = React.createElement(HomeGoodsStore, __assign({}, props)),
        _a[marker.hospital] = React.createElement(Hospital, __assign({}, props)),
        _a[marker.insuranceAgency] = React.createElement(InsuranceAgency, __assign({}, props)),
        _a[marker.jewelryStore] = React.createElement(JewelryStore, __assign({}, props)),
        _a[marker.laundry] = React.createElement(Laundry, __assign({}, props)),
        _a[marker.lawyer] = React.createElement(Lawyer, __assign({}, props)),
        _a[marker.library] = React.createElement(Library, __assign({}, props)),
        _a[marker.liquorStore] = React.createElement(LiquorStore, __assign({}, props)),
        _a[marker.localGovernmentOffice] = React.createElement(LocalGovernmentOffice, __assign({}, props)),
        _a[marker.locksmith] = React.createElement(Locksmith, __assign({}, props)),
        _a[marker.lodging] = React.createElement(Lodging, __assign({}, props)),
        _a[marker.mealDelivery] = React.createElement(MealDelivery, __assign({}, props)),
        _a[marker.mealTakeaway] = React.createElement(MealTakeaway, __assign({}, props)),
        _a[marker.mosque] = React.createElement(Mosque, __assign({}, props)),
        _a[marker.movieRental] = React.createElement(MovieRental, __assign({}, props)),
        _a[marker.movieTheater] = React.createElement(MovieTheater, __assign({}, props)),
        _a[marker.museum] = React.createElement(Museum, __assign({}, props)),
        _a[marker.nightClub] = React.createElement(NightClub, __assign({}, props)),
        _a[marker.park] = React.createElement(Park, __assign({}, props)),
        _a[marker.parking] = React.createElement(Parking, __assign({}, props)),
        _a[marker.petStore] = React.createElement(PetStore, __assign({}, props)),
        _a[marker.pharmacy] = React.createElement(Pharmacy, __assign({}, props)),
        _a[marker.physiotherapist] = React.createElement(Physiotherapist, __assign({}, props)),
        _a[marker.police] = React.createElement(Police, __assign({}, props)),
        _a[marker.postOffice] = React.createElement(PostOffice, __assign({}, props)),
        _a[marker.realEstateAgency] = React.createElement(RealEstateAgency, __assign({}, props)),
        _a[marker.restaurant] = React.createElement(Restaurant, __assign({}, props)),
        _a[marker.rvPark] = React.createElement(RvPark, __assign({}, props)),
        _a[marker.school] = React.createElement(School, __assign({}, props)),
        _a[marker.shoeStore] = React.createElement(ShoeStore, __assign({}, props)),
        _a[marker.shoppingMall] = React.createElement(ShoppingMall, __assign({}, props)),
        _a[marker.spa] = React.createElement(Spa, __assign({}, props)),
        _a[marker.stadium] = React.createElement(Stadium, __assign({}, props)),
        _a[marker.storage] = React.createElement(Storage, __assign({}, props)),
        _a[marker.store] = React.createElement(Store, __assign({}, props)),
        _a[marker.subwayStation] = React.createElement(SubwayStation, __assign({}, props)),
        _a[marker.synagogue] = React.createElement(Synagogue, __assign({}, props)),
        _a[marker.taxiStand] = React.createElement(TaxiStand, __assign({}, props)),
        _a[marker.trainStation] = React.createElement(TrainStation, __assign({}, props)),
        _a[marker.transitStation] = React.createElement(TransitStation, __assign({}, props)),
        _a[marker.travelAgency] = React.createElement(TravelAgency, __assign({}, props)),
        _a[marker.university] = React.createElement(University, __assign({}, props)),
        _a[marker.veterinaryCare] = React.createElement(VeterinaryCare, __assign({}, props)),
        _a[marker.zoo] = React.createElement(Zoo, __assign({}, props)),
        _a);
};
