import { ACTIVE_REVIEW, ADD_REVIEW_CLEAR_FAILURE, ADD_REVIEW_CLEAR_SUCCESS, ADD_REVIEW_FAILURE, ADD_REVIEW_REQUEST, ADD_REVIEW_SUCCESS, CLEAR_REPORTED, REPORT_REVIEW, REPORT_REVIEW_FAILURE, REPORT_REVIEW_SUCCESS, RESET_ADD_REVIEW_STATE, SET_REPORT_REVIEW_VISIBLE, } from './constants';
export var addReview = function (payload, name) { return ({
    type: ADD_REVIEW_REQUEST,
    payload: payload,
    name: name,
}); };
export var addReviewSuccess = function (payload) { return ({
    type: ADD_REVIEW_SUCCESS,
    payload: payload,
}); };
export var addReviewError = function (error) { return ({
    type: ADD_REVIEW_FAILURE,
    payload: error,
}); };
export var addReviewClearFailure = function () { return ({
    type: ADD_REVIEW_CLEAR_FAILURE,
}); };
export var addReviewClearSuccess = function () { return ({
    type: ADD_REVIEW_CLEAR_SUCCESS,
}); };
export var resetAddReviewState = function () { return ({
    type: RESET_ADD_REVIEW_STATE,
}); };
export var reportReview = function (payload) { return ({
    type: REPORT_REVIEW,
    payload: payload,
}); };
export var reportReviewSuccess = function () { return ({
    type: REPORT_REVIEW_SUCCESS,
}); };
export var reportReviewFailure = function (payload) { return ({
    type: REPORT_REVIEW_FAILURE,
    payload: payload,
}); };
export var clearReported = function () { return ({
    type: CLEAR_REPORTED,
}); };
export var setReportReviewVisible = function (payload) { return ({
    type: SET_REPORT_REVIEW_VISIBLE,
    payload: payload,
}); };
export var activeReview = function (payload) { return ({
    type: ACTIVE_REVIEW,
    payload: payload,
}); };
