import { placeholderPlace } from 'assets/images';
import { Avatar } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { formatWithLocal } from 'utils/date';
import { AvatarWrapper, ChallengeContainer, Dates, InfoWrapper, Name, } from './styles';
export var CardPublicChallenge = memo(function (_a) {
    var item = _a.item;
    var _b;
    var location = useLocation();
    return (React.createElement(ChallengeContainer, null,
        React.createElement(Link, { to: {
                pathname: paths.challengeDetailPage.replace(':challengeSlug', item.slug),
                state: { from: location.pathname },
            } },
            React.createElement(AvatarWrapper, null,
                React.createElement(Avatar, { size: "medium", src: ((_b = item.logo) === null || _b === void 0 ? void 0 : _b.urlThumb) || placeholderPlace, alt: "Challenge logo" })),
            React.createElement(InfoWrapper, null,
                React.createElement(Name, null, item.title),
                React.createElement(Dates, null,
                    formatWithLocal(new Date(item.startDatetime), 'dd MMMM HH:mm'),
                    " to",
                    ' ',
                    formatWithLocal(new Date(item.endDatetime), 'dd MMMM HH:mm'))))));
});
