var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { TextArea } from 'components';
import { useField } from 'formik';
import React, { memo } from 'react';
import { Container, ErrorMessage, LabelWrapper } from './styles';
export var TextAreaField = memo(function (props) {
    var _a = __read(useField(props), 2), field = _a[0], meta = _a[1];
    return (React.createElement(Container, { validateStatus: !!meta.error && meta.touched ? 'error' : '' },
        props.label && (React.createElement(LabelWrapper, { hasError: !!meta.error && meta.touched },
            React.createElement("label", null,
                props.label,
                props.required && ' *'))),
        React.createElement(TextArea, { required: props.required, rows: props.rows || 3, placeholder: props.placeholder || props.label, name: field.name, value: field.value, onChange: field.onChange, onBlur: props.onBlur || field.onBlur, onFocus: props.onFocus, disabled: props.disabled, autoComplete: "on", autoCapitalize: "off" }),
        !!meta.error && meta.touched ? (React.createElement(ErrorMessage, null, meta.error)) : null));
});
