export var CONTRIBUTOR_USER_ACCOUNT_REQUEST = 'Contributor/CONTRIBUTOR_USER_ACCOUNT_REQUEST';
export var CONTRIBUTOR_USER_ACCOUNT_FAILURE = 'Contributor/CONTRIBUTOR_USER_ACCOUNT_FAILURE';
export var CONTRIBUTOR_USER_ACCOUNT_SUCCESS = 'Contributor/CONTRIBUTOR_USER_ACCOUNT_SUCCESS';
export var CONTRIBUTOR_USER_PROFILE_REQUEST = 'Contributor/CONTRIBUTOR_USER_PROFILE_REQUEST';
export var CONTRIBUTOR_USER_PROFILE_FAILURE = 'Contributor/CONTRIBUTOR_USER_PROFILE_FAILURE';
export var CONTRIBUTOR_USER_PROFILE_SUCCESS = 'Contributor/CONTRIBUTOR_USER_PROFILE_SUCCESS';
export var CONTRIBUTOR_USER_CONTRIBUTIONS_REQUEST = 'Contributor/CONTRIBUTOR_USER_CONTRIBUTIONS_REQUEST';
export var CONTRIBUTOR_USER_CONTRIBUTIONS_FAILURE = 'Contributor/CONTRIBUTOR_USER_CONTRIBUTIONS_FAILURE';
export var CONTRIBUTOR_USER_CONTRIBUTIONS_SUCCESS = 'Contributor/CONTRIBUTOR_USER_CONTRIBUTIONS_SUCCESS';
export var CONTRIBUTOR_USER_FAVORITES_REQUEST = 'Contributor/CONTRIBUTOR_USER_FAVORITES_REQUEST';
export var CONTRIBUTOR_USER_FAVORITES_FAILURE = 'Contributor/CONTRIBUTOR_USER_FAVORITES_FAILURE';
export var CONTRIBUTOR_USER_FAVORITES_SUCCESS = 'Contributor/CONTRIBUTOR_USER_FAVORITES_SUCCESS';
