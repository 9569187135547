var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Close = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M23.9917 20L35.1648 8.82695C36.269 7.72467 36.2706 5.93593 35.1683 4.8317C34.066 3.72747 32.2773 3.72589 31.173 4.82817L20 16.0012L8.82695 4.82817C7.72272 3.72394 5.93241 3.72394 4.82817 4.82817C3.72394 5.93241 3.72394 7.72272 4.82817 8.82695L16.0012 20L4.82817 31.173C3.72394 32.2773 3.72394 34.0676 4.82817 35.1718C5.93241 36.2761 7.72272 36.2761 8.82695 35.1718L20 23.9988L31.173 35.1718C32.2773 36.2761 34.0676 36.2761 35.1718 35.1718C36.2761 34.0676 36.2761 32.2773 35.1718 31.173L23.9917 20Z", fill: color })));
});
