var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import React, { memo } from 'react';
import styled from 'styled-components';
import { fonts } from 'styles';
export var MobileTitle = memo(function (_a) {
    var children = _a.children;
    return (React.createElement(Heading, null, children));
});
var Heading = styled.h2(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: ", " 0;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: ", " 0;\n"])), rem(17), fonts.weight.semibold, rem(21), rem(36));
var templateObject_1;
