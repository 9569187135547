/* eslint-disable @typescript-eslint/camelcase */
import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    subscribeUser: versionV4 + "/follow/",
    unsubscribe: function (userId) { return versionV4 + "/follow/" + userId; },
    subscribers: function (userSlug) {
        return versionV4 + "/follow/" + userSlug + "/followers";
    },
    subscriptions: function (userSlug) {
        return versionV4 + "/follow/" + userSlug + "/followed";
    },
};
export default {
    subscribeUser: function (payload) {
        return axios.post(endpoints.subscribeUser, payload);
    },
    unsubscribe: function (userId) {
        var url = endpoints.unsubscribe(userId);
        return axios.delete(url);
    },
    subscribers: function (_a) {
        var page = _a.page, perPage = _a.perPage, userSlug = _a.userSlug;
        if (userSlug) {
            var url = endpoints.subscribers(userSlug);
            var config = {
                params: {
                    page: page,
                    per_page: perPage,
                },
            };
            return axios.get(url, config);
        }
        throw new Error('User slug is not found');
    },
    subscriptions: function (_a) {
        var page = _a.page, perPage = _a.perPage, userSlug = _a.userSlug;
        if (userSlug) {
            var url = endpoints.subscriptions(userSlug);
            var config = {
                params: {
                    page: page,
                    per_page: perPage,
                },
            };
            return axios.get(url, config);
        }
        throw new Error('User slug is not found');
    },
};
