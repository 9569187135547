import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editName';
var messages = defineMessages({
    title: {
        id: scope + ".title",
    },
    name: {
        id: scope + ".name",
    },
    lastName: {
        id: scope + ".lastName",
    },
    username: {
        id: scope + ".username",
    },
    usernameRequiredMessage: {
        id: scope + ".usernameRequiredMessage",
    },
    updateName: {
        id: scope + ".updateName",
    },
    fieldDescription: {
        id: 'containers.editMyProfile.identification.fieldDescription',
    },
    fieldInformation: {
        id: 'containers.editMyProfile.identification.fieldInformation',
    },
});
export default messages;
