var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import { colors } from 'styles';
export var Container = styled(ScrollContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: ", ";\n  width: 100%;\n  display: flex;\n"], ["\n  position: fixed;\n  top: ", ";\n  width: 100%;\n  display: flex;\n"])), rem(144));
export var Separator = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:after {\n    content: '';\n    width: 0;\n    height: ", ";\n    border: ", " dashed ", ";\n    margin: 0 ", ";\n  }\n"], ["\n  &:after {\n    content: '';\n    width: 0;\n    height: ", ";\n    border: ", " dashed ", ";\n    margin: 0 ", ";\n  }\n"])), rem(18), rem(1), colors.space.tint550, rem(8));
export var FiltersList = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  list-style: none;\n  display: flex;\n  align-items: center;\n\n  > li {\n    > button {\n      margin-right: ", ";\n      white-space: nowrap;\n      line-height: normal;\n    }\n  }\n\n  > li:first-child {\n    margin-left: ", ";\n\n    > button {\n      margin-right: 0;\n    }\n  }\n\n  > li:last-child {\n    margin-right: ", ";\n\n    > button {\n      > span {\n        margin-left: ", ";\n      }\n    }\n  }\n"], ["\n  list-style: none;\n  display: flex;\n  align-items: center;\n\n  > li {\n    > button {\n      margin-right: ", ";\n      white-space: nowrap;\n      line-height: normal;\n    }\n  }\n\n  > li:first-child {\n    margin-left: ", ";\n\n    > button {\n      margin-right: 0;\n    }\n  }\n\n  > li:last-child {\n    margin-right: ", ";\n\n    > button {\n      > span {\n        margin-left: ", ";\n      }\n    }\n  }\n"])), rem(10), rem(20), rem(10), rem(6));
export var MoreEllipsis = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  margin-right: ", ";\n  position: relative;\n  height: ", ";\n\n  > span {\n    transform: rotate(-135deg);\n    font-size: ", ";\n    position: absolute;\n    top: ", ";\n    left: ", ";\n  }\n"], ["\n  display: flex;\n  margin-right: ", ";\n  position: relative;\n  height: ", ";\n\n  > span {\n    transform: rotate(-135deg);\n    font-size: ", ";\n    position: absolute;\n    top: ", ";\n    left: ", ";\n  }\n"])), rem(12), rem(20), rem(16), rem(4), rem(-5));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
