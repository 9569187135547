import { MEMBERS_ON_TEAM_FAILURE, MEMBERS_ON_TEAM_REQUEST, MEMBERS_ON_TEAM_SUCCESS, } from './constants';
export var fetchMembersOnTeamRequest = function (payload) { return ({
    type: MEMBERS_ON_TEAM_REQUEST,
    payload: payload,
}); };
export var fetchMembersOnTeamFailure = function (payload) { return ({
    type: MEMBERS_ON_TEAM_FAILURE,
    payload: payload,
}); };
export var setMembersOnTeamSuccess = function (payload) { return ({
    type: MEMBERS_ON_TEAM_SUCCESS,
    payload: payload,
}); };
