import { Maps } from 'components/Maps';
import noop from 'lodash/noop';
import React, { useEffect } from 'react';
import { Marker } from 'react-google-maps';
import { useAction, useSelector } from 'utils/hooks';
import { clearMapLocation, reverseGpid } from './actions';
import { makeSelectLoading, makeSelectLocation } from './selectors';
var Map = function (_a) {
    var placeId = _a.placeId, loadingCallback = _a.loadingCallback, options = _a.options, place = _a.place;
    var loading = useSelector(makeSelectLoading());
    var location = useSelector(makeSelectLocation());
    var reverseGpidCallback = useAction(reverseGpid);
    var clearMapLocationCallback = useAction(clearMapLocation);
    useEffect(function () {
        if (placeId)
            reverseGpidCallback(placeId);
    }, [placeId]);
    useEffect(function () {
        loadingCallback(loading);
        return function () {
            clearMapLocationCallback();
        };
    }, []);
    useEffect(function () {
        loadingCallback(loading);
    }, [loading]);
    if (location) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Maps, { center: location, zoomControl: true, options: options }, location && React.createElement(Marker, { position: location }))));
    }
    if (place) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Maps, { zoomControl: true, options: options },
                React.createElement(Marker, { position: {
                        lat: parseFloat(place.latitude),
                        lng: parseFloat(place.longitude),
                    } }))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Maps, { zoomControl: true, options: options })));
};
Map.defaultProps = {
    loadingCallback: function () { return noop(); },
};
export { Map };
export default Map;
