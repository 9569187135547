var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { placeholderPlace } from 'assets/images';
import { List, ListItem } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { addPlaceToBookmark, removePlaceFromBookmark, } from 'containers/PlaceDetails/actions';
import { makeSelectorBookmarkLoading } from 'containers/PlaceDetails/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import * as categoryId from 'icons/category/id';
import { mapIcons } from 'icons/category/map';
import { Heart } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { memo, useState } from 'react';
import { FaCheckSquare, FaComment, FaImage, FaUser } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { colors } from 'styles';
import { snakeToCamel } from 'utils/CamelChange';
import { makeSelectIsLoggedIn } from '../../../Auth/selectors';
import { BookmarkButton } from '../../../PlaceDetails/BookmarkButton';
import { Address, BookmarkButtonWrapper, Category, CategoryIcon, Container, Content, Contributor, Header, Image, ImageWrapper, InfoWrapper, Name, NameWrapper, PlaceWrapper, Rating, } from './styles';
export var PlacesListDesktop = memo(function (_a) {
    var places = _a.places;
    var formatMessage = useIntl().formatMessage;
    var keyboard = useSelector(makeSelectUseKeyboard());
    var bookmarkLoading = useSelector(makeSelectorBookmarkLoading());
    var addPlaceToBookmarkCallback = useAction(function (payload, placeName) {
        return addPlaceToBookmark(payload, placeName);
    });
    var removePlaceFromBookmarkCallback = useAction(function (payload, placeName) {
        return removePlaceFromBookmark(payload, placeName);
    });
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var _b = __read(useState(), 2), placeForBookmarking = _b[0], setPlaceForBookmarking = _b[1];
    function renderCategoryIcon(categoryName, hasEnoughContributions) {
        if (!categoryName)
            return;
        return mapIcons(categoryId.square[hasEnoughContributions
            ? snakeToCamel(categoryName)
            : snakeToCamel(categoryName) + "Off"], 'square', {
            size: rem(32),
        });
    }
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement("span", null, formatMessage({
                id: 'pages.explorePlacesPage.sidebarTitle',
            }))),
        React.createElement(Content, { isKeyboardMode: keyboard },
            React.createElement(List, null, map(places, function (place) {
                var _a;
                return (React.createElement(Link, { key: place.googlePlaceId, to: paths.placeDetailsPage
                        .replace(':placeId', place.googlePlaceId)
                        .replace(':placeName', place.slug) },
                    React.createElement(ListItem, null,
                        React.createElement(ImageWrapper, null,
                            React.createElement(Image, { src: ((_a = place.mainImage) === null || _a === void 0 ? void 0 : _a.urlLow) || placeholderPlace }),
                            React.createElement(CategoryIcon, null, renderCategoryIcon(place.category.name, place.hasEnoughContributions)),
                            isAuthenticated && (React.createElement(BookmarkButtonWrapper, null,
                                React.createElement(BookmarkButton, { loading: bookmarkLoading &&
                                        placeForBookmarking === place.googlePlaceId, addToBookmark: function () {
                                        setPlaceForBookmarking(place.googlePlaceId);
                                        addPlaceToBookmarkCallback(place.googlePlaceId, place.name);
                                    }, removeFromBookmark: function () {
                                        setPlaceForBookmarking(place.googlePlaceId);
                                        removePlaceFromBookmarkCallback(place.googlePlaceId, place.name);
                                    }, inBookmark: !!(place === null || place === void 0 ? void 0 : place.isFavorite) })))),
                        React.createElement(PlaceWrapper, null,
                            React.createElement(InfoWrapper, null,
                                React.createElement(NameWrapper, null,
                                    React.createElement(Name, null, place.name),
                                    React.createElement(Category, null, place.category.label),
                                    React.createElement(Address, null,
                                        place.address.postalCode,
                                        " ",
                                        place.address.city)),
                                React.createElement(Rating, null,
                                    place.rating || '',
                                    React.createElement(Heart, { size: rem(16), color: colors.base.danger }),
                                    " (",
                                    place.totalComments || 0,
                                    ")")),
                            React.createElement(Contributor, null,
                                React.createElement("div", null,
                                    React.createElement(FaComment, { size: rem(16) }),
                                    React.createElement("span", null, place.totalComments)),
                                React.createElement("div", null,
                                    React.createElement(FaImage, { size: rem(16) }),
                                    React.createElement("span", null, place.totalMedias)),
                                React.createElement("div", null,
                                    React.createElement(FaCheckSquare, { size: rem(16) }),
                                    React.createElement("span", null, place.totalCriteria)),
                                React.createElement("div", null,
                                    React.createElement(FaUser, { size: rem(16) }),
                                    React.createElement("span", null, place.totalContributors)))))));
            })))));
});
