var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable dot-notation */
import * as api from 'api';
import { selectAuthDomain } from 'containers/Auth/selectors';
import { forEach } from 'lodash';
import { store } from 'store';
import { Cache } from './cache'; // Decorator to access local storage
var OneSignal;
var OneSignalService = /** @class */ (function () {
    function OneSignalService() {
        var _this = this;
        this.sendAccountSlugTag = function (slug) {
            return (window.OneSignal || []).push(function () {
                window.OneSignal.sendTags({ userSlug: slug });
            });
        };
        this.sendChallengeSlugTag = function (inChallenge) {
            if (inChallenge) {
                api.auth.currentChallenge().then(function (res) {
                    if (res.data) {
                        forEach(res.data.items, function (item) {
                            window.OneSignal.sendTags({
                                challenge_slug: item.slug,
                            });
                        });
                    }
                });
            }
        };
        this.sendExternaluserId = function (backendUserId) {
            if (backendUserId) {
                (window.OneSignal || []).push(function () {
                    window.OneSignal.setExternalUserId(backendUserId);
                });
            }
        };
        this.checkIsProduction = function () { return process.env.NODE_ENV === 'production'; };
        this.checkIsClient = function () { return process.env.BUILD_TARGET === 'client'; };
        this.checkBuildClient = function () {
            var isChecked = _this.checkIsClient();
            // const isChecked = this.checkIsProduction() && this.checkIsClient();
            return isChecked;
        };
        this.sendUserId = function () { return __awaiter(_this, void 0, void 0, function () {
            var userId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserID()];
                    case 1:
                        userId = _a.sent();
                        if (this.checkBuildClient()) {
                            this.updateUserId(userId);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateUserId = function (userId) {
            // @ts-ignore
            var user = selectAuthDomain(store.getState()).user;
            console.log(userId);
            if (user && (user === null || user === void 0 ? void 0 : user.id)) {
                var ids = [];
                if (user === null || user === void 0 ? void 0 : user.onesignalIdsWeb) {
                    ids = __spread(user.onesignalIdsWeb);
                }
                // if (user?.onesignalIdsChallengeApps) {
                //   ids = [...ids, ...user.onesignalIdsChallengeApps];
                // }
                // if (user?.onesignalIdsApps) {
                //   ids = [...ids, ...user.onesignalIdsApps];
                // }
                console.log('meus ids', ids);
                if (userId && ids.indexOf(userId) === -1) {
                    api.auth.updateOneSignal(userId);
                }
            }
        };
        this.getUserID = function () { return __awaiter(_this, void 0, void 0, function () {
            var userId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, window.OneSignal.getUserId()];
                    case 1:
                        userId = _a.sent();
                        this.oneSignalId = userId;
                        return [2 /*return*/, userId];
                }
            });
        }); };
    }
    OneSignalService.getInstance = function () {
        if (!OneSignalService.instance) {
            OneSignalService.instance = new OneSignalService();
        }
        return OneSignalService.instance;
    };
    // Call this method to start the onesignal process.
    OneSignalService.prototype.init = function () {
        // if (this.initialized) {
        //   console.log('Already Initialized');
        //   return;
        // }
        this.initOneSignal();
    };
    OneSignalService.prototype.setInitialized = function (value) {
        this.initialized = value;
    };
    OneSignalService.prototype.initOneSignal = function () {
        OneSignal = window.OneSignal || [];
        OneSignal.push([
            'init',
            {
                appId: process.env.RAZZLE_ONE_SIGNAL_APP_ID,
                allowLocalhostAsSecureOrigin: true,
                persistNotification: false,
                autoRegister: false,
                welcomeNotification: {
                    message: 'Merci pour votre souscription',
                },
                promptOptions: {
                    actionMessage: 'Nous souhaitons vous envoyer des notifications sur les dernières nouveautés de Jaccede.',
                    acceptButtonText: 'Autoriser',
                    cancelButtonText: 'Refuser',
                },
            },
        ]);
        console.log('OneSignal Initialized');
        this.subscribe();
        this.checkIfSubscribed();
        this.registerPushNotifications();
        this.initialized = true;
    };
    OneSignalService.prototype.checkIfSubscribed = function () {
        var _this = this;
        OneSignal.push(function () {
            /* These examples are all valid */
            OneSignal.isPushNotificationsEnabled(function (isEnabled) {
                if (isEnabled) {
                    console.log('Push notifications are enabled!');
                    _this.getUserID();
                }
                else {
                    console.log('Push notifications are not enabled yet.');
                    _this.subscribe();
                }
            }, function (error) {
                console.log('Push permission not granted', error);
            });
        });
    };
    OneSignalService.prototype.subscribe = function () {
        var _this = this;
        OneSignal.push(function () {
            console.log('Register For Push');
            OneSignal.push(['registerForPushNotifications']);
            OneSignal.on('subscriptionChange', function (isSubscribed) {
                console.log("The user's subscription state is now:", isSubscribed);
                if (isSubscribed) {
                    _this.sendUserId();
                }
                _this.checkIfSubscribed();
            });
        });
    };
    OneSignalService.prototype.sendTags = function (account) {
        if (this.checkBuildClient()) {
            this.sendAccountSlugTag(account.slug);
            this.sendChallengeSlugTag(account.inChallenge);
            this.sendExternaluserId(account.id);
        }
    };
    OneSignalService.prototype.listenForNotification = function () {
        var _this = this;
        console.log('Initalize Listener');
        OneSignal.on('notificationDisplay', function (event) {
            console.log('OneSignal notification displayed:', event);
            _this.listenForNotification();
        });
    };
    OneSignalService.prototype.registerPushNotifications = function () {
        OneSignal.push(function () {
            OneSignal.getUserId(function (userId) {
                if (!userId) {
                    if (OneSignal.isPushNotificationsSupported()) {
                        OneSignal.push(function () { return OneSignal.showHttpPrompt(); });
                    }
                }
            });
        });
    };
    __decorate([
        Cache({ pool: 'JaccedeOneSignal' })
    ], OneSignalService.prototype, "initialized", void 0);
    __decorate([
        Cache({ pool: 'JaccedeOneSignal' })
    ], OneSignalService.prototype, "oneSignalInit", void 0);
    __decorate([
        Cache({ pool: 'JaccedeOneSignal' })
    ], OneSignalService.prototype, "oneSignalId", void 0);
    return OneSignalService;
}());
export { OneSignalService };
