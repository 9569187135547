import { CHALLENGE_GET_TEAM_FAILURE, CHALLENGE_GET_TEAM_REQUEST, CHALLENGE_GET_TEAM_SUCCESS, } from './constants';
export var fetchTeamRequest = function (payload) { return ({
    type: CHALLENGE_GET_TEAM_REQUEST,
    payload: payload,
}); };
export var fetchTeamFailure = function (payload) { return ({
    type: CHALLENGE_GET_TEAM_FAILURE,
    payload: payload,
}); };
export var setTeamSuccess = function (payload) { return ({
    type: CHALLENGE_GET_TEAM_SUCCESS,
    payload: payload,
}); };
