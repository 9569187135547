import { CHALLENGES_PUBLIC_FAILURE, CHALLENGES_PUBLIC_REQUEST, CHALLENGES_PUBLIC_SUCCESS, } from './constants';
export var fetchPublicChallengesRequest = function () { return ({
    type: CHALLENGES_PUBLIC_REQUEST,
}); };
export var fetchPublicChallengesFailure = function (payload) { return ({
    type: CHALLENGES_PUBLIC_FAILURE,
    payload: payload,
}); };
export var setPublicChallengesSuccess = function (payload) { return ({
    type: CHALLENGES_PUBLIC_SUCCESS,
    payload: payload,
}); };
