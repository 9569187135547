var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Tooltip, TooltipContent } from 'components';
import { Medal200Desktop, Medal200DisabledDesktop, Medal1000Desktop, Medal1000DisabledDesktop, Medal5000Desktop, Medal5000DisabledDesktop, Medal10000Desktop, Medal10000DisabledDesktop, Medal25000Desktop, Medal25000DisabledDesktop, MedalCommentDesktop, MedalCommentDisabledDesktop, MedalCriterionDesktop, MedalCriterionDisabledDesktop, MedalPhotoDesktop, MedalPhotoDisabledDesktop, MedalPlaceDesktop, MedalPlaceDisabledDesktop, } from 'icons/medal';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { calculatePercentage, getDescription, getMedalValue, } from 'utils/achievements';
import { Container, MedalWrapper } from './styles';
var MEDAL_SIZE = rem(40);
var Medals = function (_a) {
    var _b = _a.totalPoints, totalPoints = _b === void 0 ? 0 : _b, _c = _a.contributionsStats, contributionsStats = _c === void 0 ? [] : _c;
    var criterionMedal = getMedalValue(contributionsStats, 'criterion');
    var photoMedal = getMedalValue(contributionsStats, 'photo');
    var commentMedal = getMedalValue(contributionsStats, 'comment');
    var placeMedal = getMedalValue(contributionsStats, 'place');
    var medalPointers = [
        {
            title: 'medal200',
            description: totalPoints >= 200 ? '200' : totalPoints + " / 200",
            goal: 200,
            percent: calculatePercentage(totalPoints, 200),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal200DisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(Medal200Desktop, { size: MEDAL_SIZE }));
            },
        },
        {
            title: 'medal1000',
            description: totalPoints >= 1000 ? '1000' : totalPoints + " / 1000",
            goal: 1000,
            percent: calculatePercentage(totalPoints, 1000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal1000DisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(Medal1000Desktop, { size: MEDAL_SIZE }));
            },
        },
        {
            title: 'medal5000',
            description: totalPoints >= 5000 ? '5000' : totalPoints + " / 5000",
            goal: 5000,
            percent: calculatePercentage(totalPoints, 5000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal5000DisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(Medal5000Desktop, { size: MEDAL_SIZE }));
            },
        },
        {
            title: 'medal10000',
            description: totalPoints >= 10000 ? '10000' : totalPoints + " / 10000",
            goal: 10000,
            percent: calculatePercentage(totalPoints, 10000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal10000DisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(Medal10000Desktop, { size: MEDAL_SIZE }));
            },
        },
        {
            title: 'medal25000',
            description: totalPoints >= 25000 ? '25000' : totalPoints + " / 25000",
            goal: 25000,
            percent: calculatePercentage(totalPoints, 25000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal25000DisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(Medal25000Desktop, { size: MEDAL_SIZE }));
            },
        },
    ];
    var medalNames = [
        {
            title: 'medalCriterion',
            description: getDescription(criterionMedal),
            goal: criterionMedal.goal,
            percent: criterionMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalCriterionDisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(MedalCriterionDesktop, { size: MEDAL_SIZE }));
            },
        },
        {
            title: 'medalPhoto',
            description: getDescription(photoMedal),
            goal: photoMedal.goal,
            percent: photoMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalPhotoDisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(MedalPhotoDesktop, { size: MEDAL_SIZE }));
            },
        },
        {
            title: 'medalComment',
            description: getDescription(commentMedal),
            goal: commentMedal.goal,
            percent: commentMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalCommentDisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(MedalCommentDesktop, { size: MEDAL_SIZE }));
            },
        },
        {
            title: 'medalPlace',
            description: getDescription(placeMedal),
            goal: placeMedal.goal,
            percent: placeMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalPlaceDisabledDesktop, { size: MEDAL_SIZE })) : (React.createElement(MedalPlaceDesktop, { size: MEDAL_SIZE }));
            },
        },
    ];
    var medals = __spread(medalPointers, medalNames);
    return (React.createElement(Container, null, map(medals, function (medal, key) { return (React.createElement(Tooltip, { key: key, title: React.createElement(TooltipContent, { title: React.createElement(FormattedMessage, { id: "containers.profile.user." + medal.title }), description: React.createElement("span", null,
                medal.description,
                ' ',
                React.createElement(FormattedMessage, { id: "containers.profile.user.points" })) }) },
        React.createElement(MedalWrapper, null, medal.icon(medal.percent)))); })));
};
export default Medals;
