import { Col, Row } from 'components/Grid';
import { ChevronLeft, ChevronRight } from 'icons/mono';
import noop from 'lodash/noop';
import { rem } from 'polished';
import React from 'react';
import { colors } from 'styles';
import { useAction, useSelector } from 'utils/hooks';
import { setActiveAvatar } from '../../actions';
import { makeSelectCarousel } from '../../selectors';
import { avatarMultiplier, numberToAvatar } from '../../utils';
import { AvatarOverflow, AvatarsContainer, CarouselContainer, NextButton, PrevButton, } from './styles';
export var PhotoCarousel = function () {
    var carousel = useSelector(makeSelectCarousel());
    var setActiveAvatarCallback = useAction(setActiveAvatar);
    var handleNavigate = function (param) {
        if (!carousel.avatar)
            return;
        if (carousel.avatar + param < 1)
            return;
        if (carousel.avatar + param > avatarMultiplier.length)
            return;
        var newActive = carousel.avatar + param;
        setActiveAvatarCallback(newActive);
    };
    return (React.createElement(Row, { justify: "center" },
        React.createElement(Col, null, carousel && (React.createElement(CarouselContainer, null,
            React.createElement(PrevButton, { onClick: function () { return handleNavigate(-1); }, role: "button" },
                React.createElement(ChevronLeft, { size: rem(20), color: colors.space.tint700 })),
            React.createElement(NextButton, { onClick: function () { return handleNavigate(+1); }, role: "button" },
                React.createElement(ChevronRight, { size: rem(20), color: colors.space.tint700 })),
            React.createElement(AvatarOverflow, null,
                React.createElement(AvatarsContainer, { style: { marginLeft: carousel.margin + "px" } },
                    React.createElement("div", { role: "button", className: (carousel.avatar === 1 ? '' : 'not-') + "selected-avatar avatar", tabIndex: 0, onKeyDown: noop },
                        React.createElement("img", { src: numberToAvatar(1), alt: "Avatar 1" })),
                    React.createElement("div", { role: "button", className: (carousel.avatar === 2 ? '' : 'not-') + "selected-avatar avatar", tabIndex: 0, onKeyDown: noop },
                        React.createElement("img", { src: numberToAvatar(2), alt: "Avatar 2" })),
                    React.createElement("div", { role: "button", className: (carousel.avatar === 3 ? '' : 'not-') + "selected-avatar avatar", tabIndex: 0, onKeyDown: noop },
                        React.createElement("img", { src: numberToAvatar(3), alt: "Avatar 3" })))))))));
};
