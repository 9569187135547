import { Button, Modal } from 'components';
import { makeSelectDirection } from 'containers/App/selectors';
import { useSelector } from 'hooks';
import { AddPlace, AddPlaceMap } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router';
import { colors } from 'styles';
import { useAction } from 'utils/hooks';
import { paths } from '../../RoutesProvider/routes';
import { clearValues } from './actions';
import { contents } from './constants';
import { MatchPlaceForm } from './MatchPlaceForm';
import { NewPlaceForm } from './NewPlaceForm';
import { makeSelectorCurrentContent } from './selectors';
import { AddNewPlaceContainer, ButtonWrapper, IconButtonWrapper, } from './styles';
import { SuggestedPlaces } from './SuggestedPlaces';
export var AddNewPlace = memo(function (_a) {
    var _b;
    var hasPlaceList = _a.hasPlaceList;
    var _c, _d;
    var formatMessage = useIntl().formatMessage;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 880 });
    var location = useLocation();
    var history = useHistory();
    var direction = useSelector(makeSelectDirection());
    var currentContent = useSelector(makeSelectorCurrentContent());
    var clearValuesCallback = useAction(clearValues);
    var handleShowModal = function () {
        clearValuesCallback();
        history.push(paths.addPlace);
    };
    var handleCancelModal = function () {
        clearValuesCallback();
        history.push(paths.explore);
    };
    var addressContent = React.createElement(MatchPlaceForm, null);
    var newPlaceContent = React.createElement(NewPlaceForm, null);
    var suggestedPlaces = React.createElement(SuggestedPlaces, null);
    var getContent = (_b = {},
        _b[contents.match] = {
            title: formatMessage({
                id: 'pages.explorePlacesPage.newPlaceModal.addressTitle',
            }),
            element: addressContent,
        },
        _b[contents.addNew] = {
            title: formatMessage({
                id: 'pages.explorePlacesPage.newPlaceModal.newPlaceTitle',
            }),
            element: newPlaceContent,
        },
        _b[contents.suggestedPlaces] = {
            title: formatMessage({
                id: 'pages.explorePlacesPage.newPlaceModal.suggestionTitle',
            }),
            element: suggestedPlaces,
        },
        _b);
    var showModal = function () {
        var pathname = location.pathname;
        if (pathname) {
            return pathname.substring(pathname.lastIndexOf('/') + 1) === 'add-place';
        }
        return false;
    };
    return (React.createElement(AddNewPlaceContainer, null,
        isMobile || isTabletOrMobile ? (React.createElement(React.Fragment, null,
            React.createElement(IconButtonWrapper, { isRtl: direction, hasPlaceList: hasPlaceList },
                React.createElement(Button, { size: "icon", shape: "circle", onClick: handleShowModal, icon: React.createElement(AddPlaceMap, { size: rem(24), color: colors.base.white }) })))) : (React.createElement(ButtonWrapper, { isRtl: direction },
            React.createElement(Button, { shape: "pill", onClick: handleShowModal, icon: React.createElement(AddPlace, { size: rem(24), color: colors.base.white }) }, formatMessage({ id: 'components.addPlaceToMap.button' })))),
        React.createElement(Modal, { visible: showModal(), onCancel: handleCancelModal, footer: null, title: (_c = getContent[currentContent]) === null || _c === void 0 ? void 0 : _c.title }, (_d = getContent[currentContent]) === null || _d === void 0 ? void 0 : _d.element)));
});
