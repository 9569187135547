import { createSelector } from 'reselect';
import { addCurrentUserJoinTeamInitialState } from './reducer';
export var selectChallengeJoinTeamDomain = function (state) {
    return state.addCurrentUserJoinTeam || addCurrentUserJoinTeamInitialState;
};
export var makeSelectChallengeJoinTeamLoading = function () {
    return createSelector(selectChallengeJoinTeamDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectChallengeJoinTeamHasError = function () {
    return createSelector(selectChallengeJoinTeamDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectChallengeJoinTeamHasSuccess = function () {
    return createSelector(selectChallengeJoinTeamDomain, function (_a) {
        var hasSuccess = _a.hasSuccess;
        return hasSuccess;
    });
};
export var makeSelectChallengeJoinTeamError = function () {
    return createSelector(selectChallengeJoinTeamDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeChallengeJoinTeam = function () {
    return createSelector(selectChallengeJoinTeamDomain, function (subState) { return subState; });
};
export default makeChallengeJoinTeam;
