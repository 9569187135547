import React, { memo } from 'react';
export var Gym = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M27.733 27.581c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#df047a", style: { fill: '#df047a' }, d: "M7.346 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.759 4.759-12.474 0-17.232s-12.473-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M16.549 9.175c0.671 0 1.216-0.575 1.216-1.285s-0.544-1.285-1.216-1.285c-0.671 0-1.216 0.575-1.216 1.285s0.544 1.285 1.216 1.285z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M13.884 10.4l-1.779 2.763 0.071 2.445-2.064 0.026-0.023 1.31h3.414l-0.021-2.163 1.605 1.011-0.005 3.022h1.266l0.126-3.806-1.948-1.317 1.078-1.959 0.133 1.497h2.817l0.070-1.026-1.771-0.067-0.127-2.136c-0.252-0.068-1.453-0.735-1.644-1.070l-1.199 1.47z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M21.983 20.506c0.857 0 1.55-0.695 1.55-1.55v-6.176c0-0.857-0.693-1.549-1.55-1.549h-2.74v8.133h-11.203v1.142h13.942z" })));
});
