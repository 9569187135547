var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { manWithDog, worldwideLandscapes } from 'assets/svg';
import { Form } from 'formik';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { breakpoints, colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint800,
    dark: colors.base.white,
});
var opacity = theme('mode', {
    light: '0.2',
    dark: '0.05',
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin: ", " auto 0;\n  max-width: ", ";\n  padding: ", " ", " 0;\n  position: relative;\n\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin: ", " auto 0;\n  max-width: ", ";\n  padding: ", " ", " 0;\n  position: relative;\n\n  ",
    ";\n"])), rem(60), breakpoints.medium, rem(24), rem(24), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: calc(100vh - ", ");\n  "], ["\n    height: calc(100vh - ", ");\n  "])), rem(200)));
export var StyledForm = styled(Form)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var InfoText = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n\n  a {\n    color: ", ";\n    text-align: center;\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n\n  a {\n    color: ", ";\n    text-align: center;\n  }\n"])), color, rem(14), fonts.weight.semibold, color);
export var SuccessContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n  padding-top: ", ";\n\n  ", ";\n\n  &::before {\n    background: url(", ") no-repeat center bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: ", ";\n    opacity: ", ";\n    position: absolute;\n\n    ", ";\n\n    ", ";\n\n    ", ";\n  }\n\n  &::after {\n    background: url(", ") no-repeat 90% bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: 100%;\n    position: absolute;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n  padding-top: ", ";\n\n  ",
    ";\n\n  &::before {\n    background: url(", ") no-repeat center bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: ", ";\n    opacity: ", ";\n    position: absolute;\n\n    ",
    ";\n\n    ",
    ";\n\n    ",
    ";\n  }\n\n  &::after {\n    background: url(", ") no-repeat 90% bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: 100%;\n    position: absolute;\n    width: 100%;\n  }\n"])), rem(60), media.lessThan('small')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 0 ", ";\n  "], ["\n    padding: 0 ", ";\n  "])), rem(8)), worldwideLandscapes, rem(320), opacity, media.greaterThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      background-size: contain;\n      width: 140%;\n    "], ["\n      background-size: contain;\n      width: 140%;\n    "]))), media.between('small', 'medium')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      background-size: cover;\n      width: 110%;\n    "], ["\n      background-size: cover;\n      width: 110%;\n    "]))), media.lessThan('small')(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      background-size: cover;\n      width: 100%;\n    "], ["\n      background-size: cover;\n      width: 100%;\n    "]))), manWithDog);
export var SuccessContent = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - ", ");\n  justify-content: center;\n  max-width: ", ";\n  min-height: ", ";\n\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - ", ");\n  justify-content: center;\n  max-width: ", ";\n  min-height: ", ";\n\n  ",
    ";\n"])), rem(140), rem(320), rem(200), media.lessThan('medium')(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    height: calc(100vh - ", ");\n  "], ["\n    height: calc(100vh - ", ");\n  "])), rem(200)));
export var Title = styled.h1(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  font-style: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n  transform: translateY(-", ");\n"], ["\n  color: ", ";\n  font-style: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n  transform: translateY(-", ");\n"])), colors.base.secondary, rem(21), fonts.weight.semibold, rem(10), rem(32));
export var Tagline = styled.p(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: ", ";\n  font-style: ", ";\n  font-weight: ", ";\n  text-align: center;\n  transform: translateY(-", ");\n"], ["\n  color: ", ";\n  font-style: ", ";\n  font-weight: ", ";\n  text-align: center;\n  transform: translateY(-", ");\n"])), color, rem(15), fonts.weight.regular, rem(32));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
