import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    usersAccountBySlug: function (userSlug) { return versionV4 + "/users/" + userSlug; },
    usersProfileBySlug: function (userSlug) {
        return versionV4 + "/users/" + userSlug + "/profile";
    },
    usersContributionsBySlug: function (userSlug) {
        return versionV4 + "/users/" + userSlug + "/contributions";
    },
    usersFavoritesBySlug: function (userSlug) {
        return versionV4 + "/users/" + userSlug + "/favorites";
    },
    top: versionV4 + "/users/top",
};
export default {
    usersAccountBySlug: function (userSlug) {
        var url = endpoints.usersAccountBySlug(userSlug);
        return axios.get(url);
    },
    usersProfileBySlug: function (userSlug) {
        var url = endpoints.usersProfileBySlug(userSlug);
        return axios.get(url);
    },
    usersContributionsBySlug: function (_a) {
        var userSlug = _a.userSlug, _b = _a.pagination, page = _b.page, perPage = _b.perPage;
        var url = endpoints.usersContributionsBySlug(userSlug);
        var config = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            params: { page: page, per_page: perPage },
        };
        return axios.get(url, config);
    },
    usersFavoritesBySlug: function (_a) {
        var userSlug = _a.userSlug, _b = _a.pagination, page = _b.page, perPage = _b.perPage;
        var url = endpoints.usersFavoritesBySlug(userSlug);
        var config = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            params: { page: page, per_page: perPage },
        };
        return axios.get(url, config);
    },
    top: function (_a) {
        var _b = _a.pagination, page = _b.page, perPage = _b.perPage, period = _a.period;
        var config = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            params: { page: page, per_page: perPage, period: period },
        };
        return axios.get(endpoints.top, config);
    },
};
