import { ADD_PLACE_TO_BOOKMARK, ADD_PLACE_TO_BOOKMARK_SUCCESS, CLEAR_FEEDBACK, CLEAR_REPORTED, CLEAR_REPORTED_PHOTO, GET_CATEGORIES, GET_CATEGORIES_FAILURE, GET_CATEGORIES_SUCCESS, GET_PLACE_CRITERIA, GET_PLACE_CRITERIA_FAILURE, GET_PLACE_CRITERIA_SUCCESS, GET_PLACE_DETAILS, GET_PLACE_DETAILS_FAILURE, GET_PLACE_DETAILS_SUCCESS, REMOVE_PLACE_FROM_BOOKMARK, REMOVE_PLACE_FROM_BOOKMARK_SUCCESS, REPORT_MEDIA_PLACE, REPORT_MEDIA_PLACE_FAILURE, REPORT_MEDIA_PLACE_SUCCESS, REPORT_PLACE, REPORT_PLACE_FAILURE, REPORT_PLACE_SUCCESS, sections, SET_ACTIVE_SECTION, UPDATE_PLACE, UPDATE_PLACE_FAILURE, UPDATE_PLACE_SUCCESS, } from './constants';
export var getPlaceDetails = function (payload) { return ({
    type: GET_PLACE_DETAILS,
    payload: payload,
}); };
export var getPlaceDetailsSuccess = function (payload) { return ({
    type: GET_PLACE_DETAILS_SUCCESS,
    payload: payload,
}); };
export var getPlaceDetailsFailure = function (payload) { return ({
    type: GET_PLACE_DETAILS_FAILURE,
    payload: payload,
}); };
export var addPlaceToBookmark = function (payload, placeName) { return ({
    type: ADD_PLACE_TO_BOOKMARK,
    payload: payload,
    placeName: placeName,
}); };
export var removePlaceFromBookmark = function (payload, placeName) { return ({
    type: REMOVE_PLACE_FROM_BOOKMARK,
    payload: payload,
    placeName: placeName,
}); };
export var addPlaceToBookmarkSuccess = function (payload) { return ({
    type: ADD_PLACE_TO_BOOKMARK_SUCCESS,
    payload: payload,
}); };
export var removePlaceFromBookmarkSuccess = function (payload) { return ({
    type: REMOVE_PLACE_FROM_BOOKMARK_SUCCESS,
    payload: payload,
}); };
export var getPlaceCriteria = function (payload) { return ({
    type: GET_PLACE_CRITERIA,
    payload: payload,
}); };
export var getPlaceCriteriaSuccess = function (payload) { return ({
    type: GET_PLACE_CRITERIA_SUCCESS,
    payload: payload,
}); };
export var getPlaceCriteriaFailure = function () { return ({
    type: GET_PLACE_CRITERIA_FAILURE,
}); };
export var setActiveSection = function (payload) {
    if (payload === void 0) { payload = sections.options; }
    return ({
        type: SET_ACTIVE_SECTION,
        payload: payload,
    });
};
export var getCategoriesSuccess = function (payload) { return ({
    type: GET_CATEGORIES_SUCCESS,
    payload: payload,
}); };
export var getCategoriesFailure = function () { return ({
    type: GET_CATEGORIES_FAILURE,
}); };
export var getCategories = function () { return ({
    type: GET_CATEGORIES,
}); };
export var updatePlace = function (payload) { return ({
    type: UPDATE_PLACE,
    payload: payload,
}); };
export var updatePlaceSuccess = function (payload) { return ({
    type: UPDATE_PLACE_SUCCESS,
    payload: payload,
}); };
export var updatePlaceFailure = function () { return ({
    type: UPDATE_PLACE_FAILURE,
}); };
export var clearFeedback = function () { return ({
    type: CLEAR_FEEDBACK,
}); };
export var reportPlace = function (payload) { return ({
    type: REPORT_PLACE,
    payload: payload,
}); };
export var reportPlaceSuccess = function () { return ({
    type: REPORT_PLACE_SUCCESS,
}); };
export var reportPlaceFailure = function () { return ({
    type: REPORT_PLACE_FAILURE,
}); };
export var reportMediaPlace = function (payload) { return ({
    type: REPORT_MEDIA_PLACE,
    payload: payload,
}); };
export var reportMediaPlaceSuccess = function () { return ({
    type: REPORT_MEDIA_PLACE_SUCCESS,
}); };
export var reportMediaPlaceFailure = function () { return ({
    type: REPORT_MEDIA_PLACE_FAILURE,
}); };
export var clearReported = function () { return ({
    type: CLEAR_REPORTED,
}); };
export var clearReportedPhoto = function () { return ({
    type: CLEAR_REPORTED_PHOTO,
}); };
