var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { dotDetail } from 'assets/svg';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { Section } from '../Section';
var opacity = theme('mode', {
    light: '0.4',
    dark: '1',
});
export var StyledSection = styled(Section)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n\n  &::after {\n    background: url(", ") no-repeat 70% bottom;\n    content: '';\n    top: 0;\n    left: -5%;\n    position: absolute;\n    display: block;\n    width: ", ";\n    height: ", ";\n    opacity: ", ";\n    z-index: -1;\n  }\n"], ["\n  position: relative;\n\n  &::after {\n    background: url(", ") no-repeat 70% bottom;\n    content: '';\n    top: 0;\n    left: -5%;\n    position: absolute;\n    display: block;\n    width: ", ";\n    height: ", ";\n    opacity: ", ";\n    z-index: -1;\n  }\n"])), dotDetail, rem(320), rem(320), opacity);
export var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n\n  > div:nth-child(2) {\n    margin-left: ", ";\n    width: 60%;\n  }\n\n  ", ";\n"], ["\n  display: flex;\n\n  > div:nth-child(2) {\n    margin-left: ", ";\n    width: 60%;\n  }\n\n  ",
    ";\n"])), rem(8), media.lessThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n  "], ["\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n  "]))));
export var Content = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 40%;\n\n  > button {\n    margin: 0 auto;\n  }\n\n  ", ";\n\n  ", ";\n"], ["\n  width: 40%;\n\n  > button {\n    margin: 0 auto;\n  }\n\n  ",
    ";\n\n  ",
    ";\n"])), media.lessThan('medium')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "]))), media.greaterThan('medium')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    h2 {\n      margin-top: ", ";\n    }\n  "], ["\n    h2 {\n      margin-top: ", ";\n    }\n  "])), rem(90)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
