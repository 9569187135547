var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var bgLogged = theme('mode', {
    light: colors.space.tint100,
    dark: colors.carbon.tint700,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Body = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: ", " auto;\n"], ["\n  display: grid;\n  grid-template-columns: ", " auto;\n"])), rem(60));
export var Place = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: ", ";\n  padding-bottom: ", ";\n  height: 100%;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n\n  ", ";\n"], ["\n  padding-left: ", ";\n  padding-bottom: ", ";\n  height: 100%;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n\n  ",
    ";\n"])), rem(12), rem(8), fonts.weight.bold, rem(18), colors.space.tint500, ifProp('isLoggedUser', css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), bgLogged)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
