import { defineMessages } from 'react-intl';
export var scope = 'containers.oAuth';
var messages = defineMessages({
    errorPageTitle: {
        id: scope + ".errorPageTitle",
    },
    completeAccountTitle: {
        id: scope + ".completeAccountTitle",
    },
    successNotification: {
        id: scope + ".successNotification",
    },
});
export default messages;
