var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
export var AlertNewSiteContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  font-size: ", ";\n  text-align: center;\n  position: fixed;\n  width: 100%;\n  top: 0;\n  cursor: pointer;\n  height: ", ";\n  z-index: 1000;\n  display: none;\n\n  a {\n    color: ", ";\n    font-weight: ", ";\n    text-decoration: none;\n    margin-left: ", ";\n\n    &:hover,\n    &:focus {\n      text-decoration: underline;\n    }\n  }\n\n  ", ";\n\n  ", "\n\n  ", "\n"], ["\n  background: ", ";\n  color: ", ";\n  font-size: ", ";\n  text-align: center;\n  position: fixed;\n  width: 100%;\n  top: 0;\n  cursor: pointer;\n  height: ", ";\n  z-index: 1000;\n  display: none;\n\n  a {\n    color: ", ";\n    font-weight: ", ";\n    text-decoration: none;\n    margin-left: ", ";\n\n    &:hover,\n    &:focus {\n      text-decoration: underline;\n    }\n  }\n\n  ",
    ";\n\n  ",
    "\n\n  ",
    "\n"])), colors.base.secondary, colors.base.white, rem(16), rem(40), colors.base.white, fonts.weight.bold, rem(4), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: ", ";\n    height: ", ";\n  "], ["\n    font-size: ", ";\n    height: ", ";\n  "])), rem(13), rem(50)), ifProp('isKeyboardMode', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin-top: ", ";\n    "], ["\n      margin-top: ", ";\n    "])), rem(50))), ifProp('hasAlert', css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    "], ["\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
