var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { Button } from 'components';
import { rem, rgba } from 'polished';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint800,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-bottom: ", ";\n  padding-top: ", ";\n  position: relative;\n\n  ", ";\n"], ["\n  padding-bottom: ", ";\n  padding-top: ", ";\n  position: relative;\n\n  ",
    ";\n"])), rem(16), rem(16), media.lessThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100vw;\n  "], ["\n    width: 100vw;\n  "]))));
export var Scrollbar = styled(ScrollContainer)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: ", ";\n\n  div:first-child {\n    margin-left: ", ";\n  }\n\n  ", ";\n"], ["\n  display: flex;\n  margin-bottom: ", ";\n\n  div:first-child {\n    margin-left: ", ";\n  }\n\n  ",
    ";\n"])), rem(16), rem(16), ifProp('isKeyboardMode', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      &:focus {\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% - ", ");\n          height: ", ";\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n          z-index: 1;\n        }\n      }\n    "], ["\n      &:focus {\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% - ", ");\n          height: ", ";\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n          z-index: 1;\n        }\n      }\n    "])), border, rem(32), rem(222), rem(10), rem(16))));
export var SingleImage = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border-radius: ", ";\n  margin: 0 ", ";\n  object-fit: cover;\n  min-width: ", ";\n  width: ", ";\n  height: ", ";\n"], ["\n  border-radius: ", ";\n  margin: 0 ", ";\n  object-fit: cover;\n  min-width: ", ";\n  width: ", ";\n  height: ", ";\n"])), rem(5), rem(16), rem(222), ifProp('fullWidth', '100%'), function (_a) {
    var imageHeight = _a.imageHeight;
    return rem(imageHeight);
});
export var ScrollImage = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: ", ";\n  margin-right: ", ";\n  object-fit: cover;\n  min-width: ", ";\n  width: ", ";\n  height: ", ";\n"], ["\n  border-radius: ", ";\n  margin-right: ", ";\n  object-fit: cover;\n  min-width: ", ";\n  width: ", ";\n  height: ", ";\n"])), rem(5), rem(16), rem(222), ifProp('fullWidth', '100%'), function (_a) {
    var imageHeight = _a.imageHeight;
    return rem(imageHeight);
});
export var InfoWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0 ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding: 0 ", ";\n"])), rem(16));
export var PlaceInfo = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n\n  > div {\n    display: flex;\n    align-items: center;\n    font-size: ", ";\n\n    .ant-rate {\n      margin: ", " ", " 0;\n    }\n  }\n\n  > h3 {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  > span {\n    font-size: ", ";\n  }\n"], ["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n\n  > div {\n    display: flex;\n    align-items: center;\n    font-size: ", ";\n\n    .ant-rate {\n      margin: ", " ", " 0;\n    }\n  }\n\n  > h3 {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  > span {\n    font-size: ", ";\n  }\n"])), color, rem(14), rem(2), rem(4), rem(17), fonts.weight.semibold, rem(13));
export var Address = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  padding-top: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  padding-top: ", ";\n"])), color, rem(13), rem(10));
export var CategoryWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  align-items: center;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  left: ", ";\n  overflow: hidden;\n  position: absolute;\n  top: calc(", " - ", ");\n  width: ", ";\n  z-index: 1;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  align-items: center;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  left: ", ";\n  overflow: hidden;\n  position: absolute;\n  top: calc(", " - ", ");\n  width: ", ";\n  z-index: 1;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), rem(4), colors.base.white, rem(32), rem(26), function (_a) {
    var imageHeight = _a.imageHeight;
    return rem(imageHeight);
}, rem(24), rem(32));
export var Overlay = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  transition: opacity 0.2s;\n  z-index: 4000;\n\n  .ant-spin-container,\n  .ant-spin-nested-loading {\n    height: 100%;\n  }\n\n  ", "\n"], ["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  transition: opacity 0.2s;\n  z-index: 4000;\n\n  .ant-spin-container,\n  .ant-spin-nested-loading {\n    height: 100%;\n  }\n\n  ",
    "\n"])), rgba(colors.base.black, 0.7), ifProp('visible', css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      opacity: 1;\n    "], ["\n      opacity: 1;\n    "])))));
export var Carousel = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n\n  .swiper-pagination-fraction {\n    color: ", ";\n    font-weight: ", ";\n    margin-bottom: 8vh;\n    text-shadow: 0 ", " ", " ", " !important;\n  }\n"], ["\n  height: 100%;\n  display: flex;\n\n  .swiper-pagination-fraction {\n    color: ", ";\n    font-weight: ", ";\n    margin-bottom: 8vh;\n    text-shadow: 0 ", " ", " ", " !important;\n  }\n"])), colors.base.white, fonts.weight.semibold, rem(2), rem(6), rgba(colors.base.black, 0.2));
export var Slide = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  min-width: 100%;\n  transition: 0.3s;\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  min-width: 100%;\n  transition: 0.3s;\n"])));
export var CarouselImage = styled.img(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  border-radius: ", ";\n  max-height: 70%;\n  max-width: calc(100% - ", ");\n  object-fit: contain;\n\n  ", ";\n"], ["\n  border-radius: ", ";\n  max-height: 70%;\n  max-width: calc(100% - ", ");\n  object-fit: contain;\n\n  ",
    ";\n"])), rem(12), rem(64), media.lessThan('medium')(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    max-width: 85%;\n  "], ["\n    max-width: 85%;\n  "]))));
export var FooterButtons = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  bottom: ", ";\n  display: flex;\n  left: 0;\n  margin: 0 ", ";\n  position: fixed;\n  right: 0;\n  z-index: 5000;\n\n  div {\n    bottom: 0;\n    position: absolute;\n  }\n\n  div:nth-child(1) {\n    display: flex;\n    left: 0;\n\n    button {\n      margin-right: ", ";\n      justify-self: flex-end;\n    }\n  }\n\n  div:nth-child(2) {\n    right: 0;\n  }\n\n  button {\n    border-radius: ", ";\n    border: 0;\n    bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    box-shadow: 0 3px 6px -4px ", ",\n      0 6px 16px 0 ", ",\n      0 9px 28px 8px ", ";\n    height: ", ";\n    width: ", ";\n\n    > svg {\n      flex-shrink: 0;\n    }\n  }\n"], ["\n  bottom: ", ";\n  display: flex;\n  left: 0;\n  margin: 0 ", ";\n  position: fixed;\n  right: 0;\n  z-index: 5000;\n\n  div {\n    bottom: 0;\n    position: absolute;\n  }\n\n  div:nth-child(1) {\n    display: flex;\n    left: 0;\n\n    button {\n      margin-right: ", ";\n      justify-self: flex-end;\n    }\n  }\n\n  div:nth-child(2) {\n    right: 0;\n  }\n\n  button {\n    border-radius: ", ";\n    border: 0;\n    bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    box-shadow: 0 3px 6px -4px ", ",\n      0 6px 16px 0 ", ",\n      0 9px 28px 8px ", ";\n    height: ", ";\n    width: ", ";\n\n    > svg {\n      flex-shrink: 0;\n    }\n  }\n"])), rem(16), rem(16), rem(8), rem(15), rem(16), rgba(colors.base.black, 0.12), rgba(colors.base.black, 0.08), rgba(colors.base.black, 0.05), rem(40), rem(40));
export var navigationButtonBase = css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  align-items: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  display: flex !important;\n  height: ", " !important;\n  justify-content: center !important;\n  position: fixed !important;\n  top: 50% !important;\n  transform: translateY(", ");\n  width: ", " !important;\n  z-index: 10 !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  align-items: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  display: flex !important;\n  height: ", " !important;\n  justify-content: center !important;\n  position: fixed !important;\n  top: 50% !important;\n  transform: translateY(", ");\n  width: ", " !important;\n  z-index: 10 !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), colors.base.white, rem(1), colors.space.tint100, rem(2), rem(6), rgba(colors.base.black, 0.2), rem(40), rem(-20), rem(40));
export var PrevButton = styled(Button)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  ", "\n  left: ", " !important;\n"], ["\n  ", "\n  left: ", " !important;\n"])), navigationButtonBase, rem(16));
export var NextButton = styled(Button)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  ", "\n  right: ", " !important;\n"], ["\n  ", "\n  right: ", " !important;\n"])), navigationButtonBase, rem(16));
export var ModalButtonWrapper = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(26));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23;
