var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Divider } from 'components/Divider';
import { PageHeader } from 'components/PageHeader';
import { useAction } from 'hooks';
import { ArrowBack } from 'icons/mono';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { setFilterMobileVisible } from '../actions';
import { CategorySelector } from '../CategorySelector';
import { Footer } from '../Footer';
import { PlacesWithoutDetailsSwitch } from '../PlacesWithoutDetailsSwitch';
import { TermsSelector } from '../TermsSelector';
import { Container, ContentWrapper, FiltersWrapperContainer } from './styles';
export var FiltersWrapperMobile = function (_a) {
    var onFilter = _a.onFilter, onClear = _a.onClear;
    var setFilterMobileVisibleCallback = useAction(setFilterMobileVisible);
    var _b = __read(useState(768), 2), windowHeight = _b[0], setWindowHeight = _b[1];
    var formatMessage = useIntl().formatMessage;
    var handleWindowResize = function () {
        setWindowHeight(window.innerHeight);
    };
    useEffect(function () {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
            window.addEventListener('resize', handleWindowResize);
        }
    }, []);
    return (React.createElement(FiltersWrapperContainer, { height: windowHeight + "px" },
        React.createElement(PageHeader, { title: formatMessage({ id: 'containers.filterPlaces.header.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return setFilterMobileVisibleCallback(false); }, sticky: true }),
        React.createElement(Container, null,
            React.createElement(ContentWrapper, null,
                React.createElement(CategorySelector, null),
                React.createElement(Divider, null),
                React.createElement(PlacesWithoutDetailsSwitch, null),
                React.createElement(Divider, null),
                React.createElement(TermsSelector, null))),
        React.createElement(Footer, { onFilter: onFilter, onClear: onClear })));
};
