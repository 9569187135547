var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Checkmark = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M20 4C11.1777 4 4 11.1777 4 20C4 28.8223 11.1777 36 20 36C28.8223 36 36 28.8223 36 20C36 11.1777 28.8223 4 20 4ZM28.3269 14.6377L17.9885 26.9454C17.759 27.2187 17.4222 27.379 17.0654 27.3846H17.0446C16.6957 27.3846 16.3633 27.2363 16.13 26.9769L11.6992 22.0538C11.3929 21.729 11.2866 21.2636 11.4214 20.838C11.5562 20.4123 11.911 20.093 12.3485 20.0037C12.786 19.9143 13.2376 20.0689 13.5285 20.4077L17.0123 24.2785L26.4423 13.0546C26.8824 12.5457 27.6496 12.4846 28.1648 12.9174C28.68 13.3501 28.7522 14.1163 28.3269 14.6377Z", fill: color })));
});
