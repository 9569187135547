import { createSelector } from 'reselect';
import { myChallengesInitialState } from '../reducers/myChallenges';
export var selectMyChallengesDomain = function (state) {
    return state.myChallenges || myChallengesInitialState;
};
export var makeSelectMyChallengesLoading = function () {
    return createSelector(selectMyChallengesDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectMyChallengesHasError = function () {
    return createSelector(selectMyChallengesDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectMyChallengesError = function () {
    return createSelector(selectMyChallengesDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectMyChallengesPages = function () {
    return createSelector(selectMyChallengesDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectMyChallengesCurrentPage = function () {
    return createSelector(selectMyChallengesDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectMyChallengesTotal = function () {
    return createSelector(selectMyChallengesDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectMyChallengesItems = function () {
    return createSelector(selectMyChallengesDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeMyChallenges = function () {
    return createSelector(selectMyChallengesDomain, function (subState) { return subState; });
};
export default makeMyChallenges;
