import { Button, Divider, Dropdown } from 'components';
import { CategoryDropdown, PlacesWithoutDetailsSwitch, TermsSelector, } from 'containers/FilterPlaces';
import { Filter } from 'icons/mono';
import noop from 'lodash/noop';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { Container, DropdownContainer, DropdownContent, DropdownFooter, StyledButton, SwitchWrapper, Title, } from './styles';
export var FilterBarDesktop = function (_a) {
    var onFilter = _a.onFilter;
    var formatMessage = useIntl().formatMessage;
    var categoryDropdown = (React.createElement(DropdownContainer, null,
        React.createElement(DropdownContent, null,
            React.createElement(CategoryDropdown, { onSelect: noop })),
        React.createElement(DropdownFooter, null,
            React.createElement(Button, null, formatMessage({
                id: 'containers.filterPlaces.desktopBar.buttons.save',
            })))));
    var termsDropdown = (React.createElement(DropdownContainer, null,
        React.createElement(DropdownContent, null,
            React.createElement(TermsSelector, null)),
        React.createElement(DropdownFooter, null,
            React.createElement(Button, null, formatMessage({
                id: 'containers.filterPlaces.desktopBar.buttons.save',
            })))));
    var spacesDropdown = (React.createElement(DropdownContainer, null,
        React.createElement(DropdownContent, { onClick: function (e) { return e.stopPropagation(); } },
            React.createElement("div", null,
                React.createElement(Title, null, formatMessage({
                    id: 'containers.filterPlaces.desktopBar.spaces.title',
                }))),
            React.createElement(SwitchWrapper, null,
                React.createElement(PlacesWithoutDetailsSwitch, { hideTitle: true }))),
        React.createElement(DropdownFooter, null,
            React.createElement(Button, null, formatMessage({
                id: 'containers.filterPlaces.desktopBar.buttons.save',
            })))));
    return (React.createElement(Container, null,
        React.createElement(Button, { shape: "pill", variant: "primary", icon: React.createElement(Filter, { size: rem(16) }), onClick: onFilter }, formatMessage({
            id: 'containers.filterPlaces.desktopBar.buttons.apply',
        })),
        React.createElement(Divider, { type: "vertical" }),
        React.createElement(Dropdown, { overlay: categoryDropdown, trigger: ['click'] },
            React.createElement(StyledButton, { shape: "pill", variant: "ghost" }, formatMessage({
                id: 'containers.filterPlaces.desktopBar.buttons.category',
            }))),
        React.createElement(Dropdown, { overlay: termsDropdown, trigger: ['click'] },
            React.createElement(StyledButton, { shape: "pill", variant: "ghost" }, formatMessage({
                id: 'containers.filterPlaces.desktopBar.buttons.terms',
            }))),
        React.createElement(Dropdown, { overlay: spacesDropdown, trigger: ['click'] },
            React.createElement(StyledButton, { shape: "pill", variant: "ghost" }, formatMessage({
                id: 'containers.filterPlaces.desktopBar.buttons.spaces',
            })))));
};
