var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import { CLEAR_NOTIFICATION, CLEAR_REPORTED, DELETE_PHOTO, DELETE_PHOTO_FAILURE, DELETE_PHOTO_SUCCESS, GET_PHOTOS_ACTION_FAILURE, GET_PHOTOS_ACTION_REQUEST, GET_PHOTOS_ACTION_SUCCESS, POST_PHOTOS_ACTION_CLEAR_FAILURE, POST_PHOTOS_ACTION_CLEAR_SUCCESS, POST_PHOTOS_ACTION_REQUEST, POST_PHOTOS_ACTION_SUCCESS, PUT_PHOTOS_ACTION_FAILURE, PUT_PHOTOS_ACTION_REQUEST, PUT_PHOTOS_ACTION_SUCCESS, REPORT_MEDIA_PLACE, REPORT_MEDIA_PLACE_FAILURE, REPORT_MEDIA_PLACE_SUCCESS, } from './constants';
export var initialState = {
    loading: false,
    hasError: false,
    hasSuccess: false,
    itemCreated: undefined,
    items: [],
    currentPage: 1,
    reported: false,
    reportLoading: false,
    alreadyReported: false,
    deleteLoading: false,
};
var placePhotosReducer = produce(function (draft, action) {
    switch (action.type) {
        case GET_PHOTOS_ACTION_REQUEST:
            draft.items = [];
            break;
        case GET_PHOTOS_ACTION_FAILURE:
            draft.items = [];
            break;
        case GET_PHOTOS_ACTION_SUCCESS:
            draft.items = action.payload.items || [];
            draft.currentPage = action.payload.currentPage;
            break;
        case POST_PHOTOS_ACTION_REQUEST:
            draft.loading = true;
            break;
        case POST_PHOTOS_ACTION_SUCCESS:
            draft.items = __spread(draft.items, [action.payload]);
            draft.itemCreated = action.payload;
            draft.hasSuccess = true;
            draft.loading = false;
            break;
        case POST_PHOTOS_ACTION_CLEAR_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.hasSuccess = false;
            draft.itemCreated = undefined;
            break;
        case POST_PHOTOS_ACTION_CLEAR_FAILURE:
            draft.loading = false;
            draft.hasError = false;
            draft.hasSuccess = false;
            break;
        case PUT_PHOTOS_ACTION_REQUEST:
            break;
        case PUT_PHOTOS_ACTION_SUCCESS:
            break;
        case PUT_PHOTOS_ACTION_FAILURE:
            break;
        case DELETE_PHOTO:
            draft.deleteLoading = true;
            break;
        case DELETE_PHOTO_SUCCESS:
            draft.deleteLoading = false;
            draft.deletePhotoSuccess = uuid();
            break;
        case DELETE_PHOTO_FAILURE:
            draft.error = {
                id: uuid(),
                message: action.payload,
            };
            draft.deleteLoading = false;
            break;
        case CLEAR_NOTIFICATION:
            draft.error = undefined;
            draft.deletePhotoSuccess = undefined;
            break;
        case REPORT_MEDIA_PLACE:
            draft.reportLoading = true;
            draft.alreadyReported = false;
            break;
        case REPORT_MEDIA_PLACE_SUCCESS:
            draft.reportLoading = false;
            draft.reported = true;
            break;
        case REPORT_MEDIA_PLACE_FAILURE:
            draft.reportLoading = false;
            draft.reported = true;
            draft.alreadyReported = true;
            break;
        case CLEAR_REPORTED:
            draft.reported = false;
            draft.alreadyReported = false;
            break;
        default:
    }
}, initialState);
export default placePhotosReducer;
