import { createSelector } from 'reselect';
import { teamDetailInitialState } from './reducer';
export var selectTeamDetailDomain = function (state) {
    return state.teamDetail || teamDetailInitialState;
};
export var makeSelectTeamDetailLoading = function () {
    return createSelector(selectTeamDetailDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectTeamDetailHasError = function () {
    return createSelector(selectTeamDetailDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectTeamDetailError = function () {
    return createSelector(selectTeamDetailDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectTeamDetailItem = function () {
    return createSelector(selectTeamDetailDomain, function (_a) {
        var item = _a.item;
        return item;
    });
};
export var makeTeamDetail = function () {
    return createSelector(selectTeamDetailDomain, function (subState) { return subState; });
};
export default makeTeamDetail;
