var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint700,
});
export var RankContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  box-shadow: 0 ", " ", " 0 ", ";\n  padding: ", ";\n  padding-bottom: ", ";\n  max-width: ", ";\n  width: 100%;\n  margin: 0 auto;\n  transition: all 0.2s;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  ", ";\n"], ["\n  background: ", ";\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  box-shadow: 0 ", " ", " 0 ", ";\n  padding: ", ";\n  padding-bottom: ", ";\n  max-width: ", ";\n  width: 100%;\n  margin: 0 auto;\n  transition: all 0.2s;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  ",
    ";\n"])), background, rem(20), rem(20), rem(-10), rem(30), rgba(colors.base.black, 0.1), rem(20), rem(40), rem(576), media.greaterThan('large')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: ", ";\n  "], ["\n    max-width: ", ";\n  "])), rem(768)));
var templateObject_1, templateObject_2;
