import { avatarImage1 } from 'assets/images';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { Title } from 'containers/Home';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import map from 'lodash/map';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Podium } from './Podium';
import { Rank } from './Rank';
import { RankItem } from './Ranktem';
import { StyledSection, ViewRanks } from './styles';
export var MostActive = memo(function (_a) {
    var podiumUsers = _a.podiumUsers, rankUsers = _a.rankUsers, useContributions = _a.useContributions, loggedUserSlug = _a.loggedUserSlug;
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var keyboard = useSelector(makeSelectUseKeyboard());
    var handleClickViewRanks = function () { return history.push(paths.globalScoreboardPage); };
    return (React.createElement(StyledSection, null,
        React.createElement(Title, { underline: "warning" }, formatMessage({ id: 'containers.homeMostActive.title' })),
        React.createElement(Podium, { podiumUsers: podiumUsers, loggedUserSlug: loggedUserSlug }),
        React.createElement(Rank, null,
            map(rankUsers, function (user, index) {
                var _a;
                return (React.createElement(RankItem, { key: user.id, rank: index + 4, avatar: ((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.urlThumb) || avatarImage1, name: user.firstName &&
                        (user.firstName || '') + " " + (user.lastName || ''), pseudo: user.slug, loggedUserSlug: loggedUserSlug, points: useContributions ? user.contributions || 0 : user.points || 0, isKeyboardMode: keyboard }));
            }),
            React.createElement(ViewRanks, { size: "medium", variant: "secondary", onClick: handleClickViewRanks }, formatMessage({ id: 'containers.homeMostActive.viewAllRank' })))));
});
