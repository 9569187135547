import * as Yup from 'yup';

const privateChallengeSchema = (formatMessage) =>
  Yup.object().shape({
    code: Yup.string()
      .matches(
        /[a-zA-Z0-9]-[a-zA-Z0-9]/,
        formatMessage({
          id: 'containers.challenges.secretChallenge.max',
        }),
      )
      .required(
        formatMessage({
          id: 'containers.challenges.secretChallenge.required',
        }),
      ),
  });

export const defaultProps = {
  code: '',
};

export default privateChallengeSchema;
