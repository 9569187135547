var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
var media = generateMedia({
    small: '560px',
});
export var PhotoMosaicContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1.2fr;\n  grid-column-gap: ", ";\n  margin: 0 auto ", ";\n  width: 80%;\n\n  img {\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n  }\n\n  > div {\n    max-height: ", ";\n\n    div {\n      display: flex;\n      overflow: hidden;\n      border-radius: ", ";\n    }\n  }\n\n  > div:first-child {\n    display: grid;\n    grid-template-rows: repeat(2, 1fr);\n    grid-row-gap: ", ";\n\n    ", ";\n  }\n\n  > div:last-child {\n    display: grid;\n    grid-template-rows: 1fr;\n  }\n\n  ", ";\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1.2fr;\n  grid-column-gap: ", ";\n  margin: 0 auto ", ";\n  width: 80%;\n\n  img {\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n  }\n\n  > div {\n    max-height: ", ";\n\n    div {\n      display: flex;\n      overflow: hidden;\n      border-radius: ", ";\n    }\n  }\n\n  > div:first-child {\n    display: grid;\n    grid-template-rows: repeat(2, 1fr);\n    grid-row-gap: ", ";\n\n    ",
    ";\n  }\n\n  > div:last-child {\n    display: grid;\n    grid-template-rows: 1fr;\n  }\n\n  ",
    ";\n"])), rem(16), rem(32), rem(300), rem(8), rem(16), media.lessThan('small')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      grid-gap: ", ";\n    "], ["\n      grid-gap: ", ";\n    "])), rem(12)), media.lessThan('small')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    grid-column-gap: ", ";\n    width: 100%;\n  "], ["\n    grid-column-gap: ", ";\n    width: 100%;\n  "])), rem(12)));
var templateObject_1, templateObject_2, templateObject_3;
