import { Dropdown } from 'components';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import get from 'lodash/get';
import map from 'lodash/map';
import split from 'lodash/split';
import trim from 'lodash/trim';
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { supportedLangs } from 'translations';
import { useAction, useSelector } from 'utils/hooks';
import { DEFAULT_APP_LOCALE, locales } from 'utils/locale';
import { FlagIcon } from '../Flags';
import { LanguageIcon } from '../Icons';
import { Button, Content, ListButton } from './styles';
export var LanguageSwitcher = memo(function (_a) {
    var title = _a.title;
    var locale = useSelector(makeSelectLocale());
    var changeLocaleCallback = useAction(changeLocale);
    var location = useLocation();
    var history = useHistory();
    var handleChange = function (event) {
        changeLocaleCallback(event.target.value);
        var currentUrlLanguage = split(trim(location.pathname), '/')[1];
        var updatedUrlLanguage = location.pathname.replace("/" + currentUrlLanguage + "/", "/" + event.target.value + "/");
        history.push(updatedUrlLanguage);
    };
    var content = (React.createElement(Content, null, map(locales, function (code) { return (React.createElement(ListButton, { key: code, type: "button", value: code, onClick: handleChange, isActive: locale === code },
        React.createElement("span", null, get(supportedLangs, code, DEFAULT_APP_LOCALE)),
        React.createElement(FlagIcon, { flag: code, text: get(supportedLangs, locale, DEFAULT_APP_LOCALE) }))); })));
    return (React.createElement(Dropdown, { overlay: content, trigger: ['click'], placement: "topRight" },
        React.createElement(Button, { className: "swiper-slide" },
            React.createElement(LanguageIcon, { title: title, image: locale }))));
});
