var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { breakpoints, colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var shadow = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var ChallengesContainer = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(20));
export var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 auto;\n  max-width: ", ";\n"], ["\n  margin: 0 auto;\n  max-width: ", ";\n"])), breakpoints.medium);
export var Title = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"])), color, rem(18), fonts.weight.semibold);
export var ChallengesList = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: ", ";\n\n  > a {\n    display: block;\n\n    & + a {\n      margin-top: ", ";\n    }\n  }\n\n  ", "\n"], ["\n  margin-top: ", ";\n\n  > a {\n    display: block;\n\n    & + a {\n      margin-top: ", ";\n    }\n  }\n\n  ",
    "\n"])), rem(16), rem(16), ifProp('isKeyboardMode', css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      & > div:focus {\n        ", ";\n      }\n    "], ["\n      & > div:focus {\n        ", ";\n      }\n    "])), shadow)));
export var LoadingWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: ", ";\n  padding-top: ", ";\n"], ["\n  padding: ", ";\n  padding-top: ", ";\n"])), rem(32), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
