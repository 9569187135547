import { CardPlaces, PageHeader, PageWrapper } from 'components';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { BookmarkButton } from 'containers/PlaceDetails/BookmarkButton';
import { useSelector } from 'hooks';
import { ArrowBack, List } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { CardPlacesWrapper, Content, Scrollbar, SeeInListButton, } from './styles';
export var CategoryMapViewPage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var places = [];
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.categoryMapViewPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.categoryMapViewPage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.categoryMapViewPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.categoryMapViewPage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, { mobileMenu: false },
            React.createElement(PageHeader, { title: formatMessage({ id: 'pages.categoryMapViewPage.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), 
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onBack: function () { }, sticky: true }),
            React.createElement(Content, null,
                React.createElement(SeeInListButton, { shape: "pill", variant: "ghost", icon: React.createElement(List, { size: rem(20) }) }, formatMessage({ id: 'pages.categoryMapViewPage.seeInListButton' })),
                React.createElement(Scrollbar, null, map(places, function (place) { return (React.createElement(CardPlacesWrapper, null,
                    React.createElement(CardPlaces, { key: place.id, place: place, showCategoryIcon: true, bookmarkButton: React.createElement(BookmarkButton, { loading: false, addToBookmark: null, removeFromBookmark: null, inBookmark: false }) }))); }))))));
};
