import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectFilterPlacesStateDomain = function (state) {
    return state.filterPlaces || initialState;
};
var makeSelectorFilterState = function () {
    return createSelector(selectFilterPlacesStateDomain, function (state) { return state; });
};
var makeSelectorLoading = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
var makeSelectorAccessibilityFilters = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var accessibilityFilters = _a.accessibilityFilters;
        return accessibilityFilters;
    });
};
var makeSelectorPlacesWithoutDetails = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var placeWithoutDetails = _a.placeWithoutDetails;
        return placeWithoutDetails;
    });
};
var makeSelectorSelectedCategory = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var selectedCategory = _a.selectedCategory;
        return selectedCategory;
    });
};
var makeSelectorSelectedAccessibilityFilters = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var selectedAccessibilityFilters = _a.selectedAccessibilityFilters;
        return selectedAccessibilityFilters;
    });
};
var makeSelectorFilterMobileVisible = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var filterMobileVisible = _a.filterMobileVisible;
        return filterMobileVisible;
    });
};
var makeSelectorBooleanFilter = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var booleanFilter = _a.booleanFilter;
        return booleanFilter;
    });
};
var makeSelectorPlaceWithoutDetailsChangeId = function () {
    return createSelector(selectFilterPlacesStateDomain, function (_a) {
        var placeWithoutDetailsChangeId = _a.placeWithoutDetailsChangeId;
        return placeWithoutDetailsChangeId;
    });
};
export { makeSelectorFilterState, makeSelectorLoading, makeSelectorAccessibilityFilters, makeSelectorPlacesWithoutDetails, makeSelectorSelectedCategory, makeSelectorSelectedAccessibilityFilters, makeSelectorFilterMobileVisible, makeSelectorBooleanFilter, makeSelectorPlaceWithoutDetailsChangeId, };
