var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { switchProp } from 'styled-tools';
import { breakpoints, colors } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var SectionContainer = styled.section(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  padding: ", " ", " ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  overflow: hidden;\n\n  ", ";\n\n  ", "\n"], ["\n  color: ", ";\n  padding: ", " ", " ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  overflow: hidden;\n\n  ",
    ";\n\n  ",
    "\n"])), color, rem(50), rem(20), rem(60), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: ", " ", " ", ";\n  "], ["\n    padding: ", " ", " ", ";\n  "])), rem(80), rem(20), rem(100)), switchProp('background', {
    primary: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n    "], ["\n      color: ", ";\n      background-color: ", ";\n    "])), colors.base.white, colors.base.primary),
    secondary: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n    "], ["\n      color: ", ";\n      background-color: ", ";\n    "])), colors.base.white, colors.base.secondary),
    warning: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n    "], ["\n      color: ", ";\n      background-color: ", ";\n    "])), colors.space.tint900, colors.base.warning),
    danger: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n    "], ["\n      color: ", ";\n      background-color: ", ";\n    "])), colors.base.white, colors.base.danger),
}));
export var Content = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n"], ["\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n"])), breakpoints.large);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
