export var colors = {
    base: {
        primary: '#318000',
        secondary: '#773bb3',
        danger: '#e02020',
        alert: '#fa6400',
        warning: '#ffc001',
        info: '#0091ff',
        facebook: '#407fbc',
        white: '#fff',
        black: '#000',
        success: '#749C00',
    },
    space: {
        tint100: '#f8f8f8',
        tint200: '#e8e8e8',
        tint300: '#e3e3e3',
        tint400: '#d9d9d9',
        tint500: '#b9b9b9',
        tint550: '#979797',
        tint600: '#6e6e6e',
        tint650: '#808080',
        tint700: '#474747',
        tint750: '#4a4a4a',
        tint800: '#353535',
        tint900: '#262626',
    },
    carbon: {
        tint100: '#38383E',
        tint200: '#33333A',
        tint300: '#313138',
        tint400: '#2F2F36',
        tint500: '#2A2A30',
        tint600: '#26262B',
        tint700: '#232328',
        tint800: '#202024',
        tint900: '#1D1D21',
        tint950: '#19191C',
    },
};
