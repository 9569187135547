export default {
    components: {
        podium: {
            points: 'pts',
        },
        userReview: {
            datePattern: "MMMM d',' yyyy",
        },
        ellipsis: {
            more: 'più',
            less: 'meno',
        },
        loadingIndicator: {
            loading: 'Caricamento in corso...',
        },
        nav: {
            explore: 'Esplorare',
            challenge: 'Challenge',
            donation: 'Fare una donazione',
            aboutUs: 'A proposito di Jaccede',
            myProfile: 'Il mio profilo',
            changeFontSize: 'Cambiare la dimensione del font',
            darkMode: 'Modificare il contrasto',
            signIn: 'Collegare',
            logout: 'Disconnettersi',
            mailSystem: 'Messaggi',
            homePage: 'Homepage',
        },
        facebookButton: {
            continueWithFacebook: 'Collegamento con Facebook',
        },
        usersProfilesField: {
            label: 'Tipo di profilo',
            select: 'Scegli un opzione',
            placeholder: 'Seleziona un tipo di profilo',
        },
        passwordField: {
            label: 'Password',
            placeholder: 'Inserisci la tua password',
            showPassword: 'Mostra password',
            hidePassword: 'Nascondi password',
            forgotPassword: 'Hai dimenticato la tua password?',
        },
        emailField: {
            label: 'Email',
            placeholder: 'Inserisci la tua email',
        },
        searchField: {
            button: 'Ricerca',
            placeholder: 'Trova luoghi accessibili',
        },
        profileMenu: {
            profile: 'Il mio profilo',
            mailSystem: 'Sistema posta ',
            logout: 'Sisconnettersi',
        },
        passwordScore: {
            tips: '{tipsHighlight} La password deve soddisfare i seguenti criteri :',
            tipsHighlight: 'Trucco :',
            tipOne: '1 - minimo una lettera maiuscola',
            tipTwo: '2 - minimo una cifra',
            tipThree: '3 - minimo 8 caratteri',
            veryWeak: 'Molto basso',
            weak: 'Basso',
            fair: 'Senso',
            strong: 'Forte',
        },
        placesAutocomplete: {
            googlePlaceIdLabel: 'Il mio indirizzo',
            googlePlaceIdPlaceholder: 'Indirizzo',
        },
        aboutJaccede: {
            container: {
                definition: {
                    headline: 'Jaccedeur, Jaccedeuse  [ʒaksɛdœʁ/øz]',
                    description: "\n            Non importa quale sia il tuo nome, et\u00E0 o sesso.\n            \u00C8 un utente, o un collaboratore, di Jaccede con uno spirito positivo,\n            che lavora mano nella mano con i loro colleghi in tutto il mondo per\n            completare e aggiornare il u\u00EDa Jaccede, al fine di diffondere\n            l'informazione tra tutti e sensibilizzare sull'importanza\n            dell'accessibilit\u00E0.\n          ",
                },
                headline: 'Jaccede, cos’è?',
            },
            headline: 'Jaccede, cos’è?',
            subtitle: 'A seconda della mia mobilità, a quali luoghi posso accedere?',
            firstParagraph: "\n        Jaccede \u00E8 una piattaforma collaborativa (sito internet e applicazione\n        mobile) dove tutti possono dettagliare \u2019accessibilit\u00E0 dei luoghi che\n        accolgono del pubblico per permettere alle persone a mobilit\u00E0 ridotta di\n        identificare le strutture che corrispondono ai loro bisogni in materia\n        di accessibilit\u00E0.\n      ",
            secondParagraph: "\n        Uno dei principali freni delle persone a mobilit\u00E0 ridotta \u00E8 dato dalla\n        mancanza di informazioni sull\u2019accessibilit\u00E0. Condividere queste\n        informazioni su Jaccede ha pertanto un impatto sociale maggiore e\n        immediato.",
            thirdParagraph: "\n        Jaccede permette inoltre di organizzare eventi nel corso di tutto l\u2019anno\n        volti a riunire i membri di Jaccede, sensibilizzare il grande pubblico e\n        alimentare la guida. Centinaia di incontri sono gi\u00E0 stati organizzati in\n        Francia e all\u2019estero!\n      ",
        },
        emailNotConfirmed: {
            message: 'conferma la tua email',
        },
        subscribeButton: {
            subscribe: 'sottoscrivere ',
            unsubscribe: "Annulla l'iscrizione",
        },
        subFooter: {
            searchByType: {
                title: 'Cerca per tipo di luogo',
            },
            findPlacesInFrance: {
                title: ' Trova luoghi in Francia',
            },
            findPlacesInEurope: {
                title: 'Trova posti in Europa',
            },
            findPlacesInTheWorld: {
                title: 'Trova posti nel mondo',
            },
        },
        bottomMenu: {
            languages: {
                english: 'English (UK)',
                french: 'Français',
                spanish: 'Español',
                italian: 'Italiano',
                german: 'Deutsche',
            },
            submenu: {
                fontSize: 'Dimensione del font',
                rightHand: 'Mano destra',
                leftHand: 'Mano sinistra',
                defaultLanguage: 'lingua di default',
                darkMode: 'Alto contrasto',
            },
            challenge: 'Challenge',
            explore: 'Esplorare',
            donate: 'Fare una donazione',
            settings: 'Parametri',
            menu: 'Menù',
        },
        footer: {
            copyRight: '2020 copyright. Jaccede. Tutti i diritti riservati.',
            privacyTerms: 'Informazione personale · condizioni generali',
            sitemap: 'Mappa del sito',
        },
        addPlaceToMap: {
            button: 'Aggiungi un luogo mancante',
            altImage: 'Uomo con una donna su una sedia a rotelle',
        },
        notificationMenu: {
            title: 'Notificaciones',
            empty: 'Nessuna notifica',
        },
        keyboardMode: {
            tabButton: 'Guarda il contenuto',
            escButton: 'Toccare ESCAPE per chiudere la modalità tastiera',
        },
        alertNewSite: {
            message: 'Welcome to the beta version of the new Jaccede platform! Do not hesitate to send us your',
            email: 'suggestions',
        },
    },
    containers: {
        app: {
            title: 'Jaccede',
            cookieConsentText: 'Jaccede utilizza i cookie per migliorare la tua esperienza sul sito rispettando le tue informazioni personali.',
            cookieConsentButtonOk: "Essere d'accordo",
            cookieConsentButtonDecline: 'Dopo',
        },
        signInEmail: {
            emailFormat: 'Formato e-mail errato',
            emailRequired: 'E-mail è richiesta',
            signInButton: 'Connessione',
            passwordRequired: 'E richiesta la password',
            invalidSignin: 'E-mail o password errati',
        },
        signUpEmail: {
            header: "O con un'e-mail",
            emailLabel: 'E-mail',
            emailPlaceholder: 'Inserisci la tua email',
            emailRequired: 'E-mail è richiesta',
            pseudoLabel: 'Pseudonimo',
            pseudoPlaceholder: 'Pseudonimo',
            pseudoRequired: 'Il soprannome è obbligatorio',
            acceptsNewsletterLabel: 'Voglio ricevere la newsletter mensile',
            termsAndCoditions: 'Creando un account, accetti i ',
            termsAndCoditionsLink: 'Condizioni generali di utilizzo',
            registrationButton: 'Registro',
            profileIdRequired: 'Tipo di profilo richiesto',
            googlePlaceIdRequired: 'Il mio indirizzo è richiesto ',
            passwordRequired: 'E richiesta la password',
            passwordStrengthRegex: 'Questo campo deve contenere: 1 lettera maiuscola, 1 numero e almeno 8 caratteri',
        },
        home: {
            lastPlacesUpdates: {
                title: 'Gli ultimi posti aggiornati',
            },
            signUpNewsletter: {
                title: 'Iscriviti alla nostra Newsletter',
                submitButton: 'Sottoscrivi',
                emailRequired: 'E-mail è richiesta',
                optinSuccess: 'La tua email è stata registrata',
                optinFailure: 'Spiacenti, si è verificato un errore durante la registrazione alla nostra newsletter. Grazie, riprova più tardi.',
            },
            socialNetworks: 'Seguici:',
        },
        contribute: {
            title: "\n        Contribuisci a\n        Jaccede!\n      ",
            collaborateParagraph: "\n        Diventare un membro \u00E8 il primo modo per dimostrare il tuo sostegno\n        Jaccede. La tua iscrizione aiuta a promuovere la causa delle persone in\n        mobilit\u00E0 ridotta e fornisce anche supporto morale e finanziario\n        all'associazione.\n      ",
            collaborateAlt: 'Persona su sedia a rotelle ',
            collaborateImage: "Unisciti all'avventura!",
            collaborateTitle: "Unisciti all'associazione",
            collaborateButtonText: 'Aderire!',
            donateAlt: 'Persona su sedia a rotelle',
            donateImage: 'Aiutaci ad avere successo!',
            donateTitle: 'Fare una donazione',
            donateButtonText: 'Fare una donazione',
            donateParagraph: "\n        Il tuo interesse a contribuire al miglioramento della vita delle persone\n        con mobilit\u00E0 ridotto ti ha portato a Jaccede. Aiutaci a raggiungere\n        questo obiettivo e adottare misure per educare il pubblico in generale\n        sui vantaggi dell'accessibilit\u00E0 ",
        },
        homeHeader: {
            title: "\n        Trova i luoghi a\n        te accessibili\n      ",
            placeholder: 'Trova luoghi accessibili',
            seeMore: 'Vedi altro',
        },
        homeSearch: {
            button: 'Ricerca',
            placeholder: 'Trova una struttura o un indirizzo',
        },
        homeLearnMore: {
            title: "\n        Scopri di pi\u00F9\n        su di noi\n      ",
            desktopSubtitle: "Scopri come Jaccede lavora per l'accessibilità per tutti, per tutti.",
            mobileSubtitle: 'Jaccede è una piattaforma interattiva dove chiunque può aggiungere dettagli sui luoghi aperti al pubblico.',
            videoAlt: 'Chi siamo video in miniatura',
            blockquoteTitle: 'Jaccede, cos’è?',
            blockquoteParagraph: "\n      Guarda come funziona Jaccede per ottenere l'accessibilit\u00E0 per tutti,\n        per tutti.",
        },
        homeBrowsePlaces: {
            title: "\n        Cerca localit\u00E0\n        per categoria\n      ",
            subtitle: "Cerchi una tipologia particolare di locale? Luoghi pubblici e loro. I dettagli sull'accessibilità possono essere visualizzati per categoria con Jaccede",
            button: 'Esplora i luoghi',
            dogAndOwnerAlt: 'Cane guida con il suo proprietario in un parco',
            wheelchairHandAlt: 'Persona su sedia a rotelle',
            guideDogsAlt: 'Cani guida al guinzaglio',
            peopleSmilingAlt: 'Persone in chat',
        },
        homeMostActive: {
            title: "\n        I contributori pi\u00F9\n        attivi della settimana\n      ",
            viewAllRank: 'Vedi tutte le classificazioni',
        },
        homeLatestNews: {
            title: 'Jaccede News',
            subtitle: "Segui le ultime notizie dalla comunità e dall'associazione Jaccede navigando nel nostro blog!",
            buttonText: 'Vedi blog',
            note: 'solo in francese',
        },
        oAuth: {
            errorPageTitle: "C'è stato un errore",
            completeAccountTitle: 'Completa il tuo account',
            successNotification: 'Sei connesso con successo!',
        },
        resendEmailActivation: {
            header: "Invia di nuovo l'email di attivazione",
            submitButton: 'Invia di nuovo',
            emailRequired: 'E-mail è richiesta',
            emailformat: 'Formato e-mail errato',
        },
        profile: {
            achievements: {
                header: 'Successo',
            },
            user: {
                editMyProfile: {
                    mobile: 'modificare',
                    desktop: 'Modifica il mio profilo',
                },
                pointsInfo: {
                    criterion: '1 criterio modificato una volta = 1 punto',
                    photo: '1 foto aggiunta per posizione = 4 punti',
                    comment: '1 commento per posizione = 2 punti',
                    place: '1 posizione mancante aggiunta = 1 punto',
                },
                subscriptions: 'Abbonamenti',
                subscribers: 'Iscritti',
                points: 'punti',
                sendMessage: 'Mandare un messaggio',
                readMore: 'Più',
                medal200: 'Badge 200 punti',
                medal1000: 'Distintivo da 1000 punti',
                medal5000: 'Distintivo da 5000 punti',
                medal10000: 'Distintivo da 10.000 punti',
                medal25000: '25.000 punti badge',
                medalCriterion: 'Badge dei criteri',
                medalPhoto: 'Badge fotografico',
                medalComment: 'Badge di feedback',
                medalPlace: 'Distintivo delle posizioni mancanti',
            },
            activities: {
                header: 'Attività delle ultime settimane',
            },
            myActiveChallenges: {
                header: 'La mia sfida attiva',
                challengesEmptyList: "L'utente non sta partecipando a nessuna sfida al momento",
            },
            moreAbout: {
                header: 'Maggiori informazioni',
                contributions: 'Contributi',
                favorites: 'Preferiti',
                contributionEmptyList: 'Nessun contributo al momento',
                favoriteEmptyList: 'Non ci sono ancora preferiti',
            },
            nextAchievements: {
                medals: {
                    medal200: {
                        title: '200 punti badge',
                        description: 'Per guadagnare questo badge devi guadagnare 200 punti',
                    },
                    medal1000: {
                        title: '1000 punti badge',
                        description: 'Per guadagnare questo badge, devi accumulare 1000 punti ',
                    },
                    medal5000: {
                        title: '5000 punti badge',
                        description: 'Per guadagnare questo badge, devi accumulare 5.000 punti ',
                    },
                    medal10000: {
                        title: '10.000 punti badge',
                        description: 'Per guadagnare questo badge, devi accumulare 10.000 punti ',
                    },
                    medal25000: {
                        title: '25.000 punti badge',
                        description: 'Per guadagnare questo badge, devi accumulare 25.000 punti ',
                    },
                    medalCriterion: {
                        title: 'Badge dei criteri',
                        description: 'Per ottenere questo badge, devi segnalare 5000 criteri di accessibilità del sito',
                    },
                    medalPhoto: {
                        title: 'Badge fotografico',
                        description: 'Per ottenere questo badge, devi aggiungere 1000 foto di luoghi',
                    },
                    medalComment: {
                        title: 'Badge di feedback',
                        description: 'Per guadagnare questo badge, devi fare 1000 commenti sul sito.',
                    },
                    medalPlace: {
                        title: 'Distintivo delle posizioni mancanti',
                        description: "Per guadagnare questo badge, devi aggiungere 500 luoghi che non esistevano sul sito di Jaccede",
                    },
                },
                header: 'Prossimo successo',
            },
        },
        forgotPasswordPage: {
            emailLabel: 'E-mail',
            submitButton: 'Richiedi il collegamento per reimpostare la password',
            notReset: 'Si no puede restablecer su contraseña,',
            contatUs: 'Contattaci.',
            emailFormat: 'Formato e-mail errato',
            emailRequired: 'E-mail è richiesta',
            verifyEmail: 'Ti sono state inviate le istruzioni per reimpostare la tua password.',
            success: 'Finito !!',
            error: 'Errore !!',
            passwordIsExpired: 'Il collegamento è scaduto. Riapplica.',
        },
        changePasswordPage: {
            newPasswordLabel: 'Nuova password',
            newPasswordRequired: "Nuova password richiesta '",
            confirmPasswordRequired: 'È richiesta la conferma di una nuova password',
            newPasswordStrengthRegex: 'Il campo deve contenere: 1 lettera maiuscola, 1 numero e almeno 8 caratteri',
            confirmPasswordValidation: 'Le due nuove password devono corrispondere',
            confirmPasswordLabel: 'Conferma la nuova password',
            submitButton: 'Modificare',
            success: 'Successo !!',
            successfully: 'La password è stata modificata con successo!',
            error: 'Errore !!',
            errorMessage: 'Scusa, qualcosa è andato storto',
            checkingPasswordIsLoading: 'Controlliamo la validità del token',
            linkForgotPassword: 'Clicca qui',
        },
        subscribers: {
            subscriptions: 'Abbonamenti',
            subscribers: 'Iscritti',
            loggedUserNoSubscribers: 'Non hai iscritti!',
            loggedUserNoSubscriptions: 'Non hai abbonamenti! ',
            noSubscribers: 'Nessun abbonato trovato!',
            noSubscriptions: 'Nessun abbonamento trovato',
        },
        globalScoreboard: {
            tabWeek: {
                title: 'Settimanalmente',
                footerTitleWithPoints: 'Questi sono i tuoi punti del mese',
                footerTitleWithoutPoints: 'Non hai guadagnato punti questa settimana',
            },
            tabMonth: {
                title: 'Mensile',
                footerTitleWithPoints: 'Questi sono i tuoi punti del mese',
                footerTitleWithoutPoints: 'Non hai guadagnato punti questo mese',
            },
            tabAll: {
                title: 'Total',
                footerTitleWithPoints: 'Ecco i tuoi punti',
                footerTitleWithoutPoints: 'Non hai ottenuto punti',
            },
            headerTitle: 'Classifica generale',
            emptyList: "Non abbiamo punteggi per il mese in corso, cosa pensi che cambier\u00E0? Inizia a rendere il mondo pi\u00F9 accessibile ora! ",
        },
        editMyProfile: {
            identification: {
                fieldDescription: 'Identificazione',
                fieldInformation: 'Informazioni chiave sul tuo account',
            },
            location: {
                fieldDescription: 'Posizione',
                fieldInformation: 'Especifico mi ubicación para beneficiarme de información más relevante',
            },
            message: {
                fieldDescription: 'Messaggio',
                fieldInformation: 'Insomma, un messaggio per tutti i Jaccedeur!',
            },
            editOptions: {
                identification: 'identificazione',
                identificationMessage: 'Modifica il tuo profilo pubblico in modo che le persone possano saperne di più su di te',
                name: 'cognome e pseudonimo',
                editPhoto: 'Modifica foto',
                editPhotoMessage: 'Modifica la tua foto da utilizzare nella comunità Jaccede',
                profileType: 'TIPO DI PROFILO',
                profileMessage: 'MESSAGGIO DEL PROFILO',
                contactInformation: 'Informazioni sui contatti',
                email: 'E-MAIL',
                telephone: 'TELEFONO',
                password: "PAROLA D'ORDINE",
                location: 'Città',
                myAddress: 'IL MIO INDIRIZZO',
                editPhotoOverlay: 'modificare',
            },
            editPhoto: {
                uploadError: 'Non è stato possibile caricare la tua foto',
                modalTitle: 'Modifica la mia foto',
                resolutionRule: 'Risoluzione minima di ',
                sizeRule: 'Dimensioni inferiori o uguali a',
                extensionsRule: 'Estensioni autorizzate : ',
            },
            editName: {
                name: 'Nome di battesimo',
                title: ' Nome e pseudonimo',
                lastName: 'Nome',
                username: 'pseudonimo',
                usernameRequiredMessage: 'Il soprannome è obbligatorio',
                updateName: 'Cambia nome e soprannome',
            },
            editEmail: {
                title: 'E-mail',
                email: 'E-mail',
                emailRequiredMessage: 'e-mail è richiesta',
                invalidEmailMessage: 'Formato e-mail errato',
                updateEmail: 'Modifica email',
            },
            editTelephone: {
                title: 'Telefono',
                telephone: 'Telefono',
                updateTelephone: 'Modifica telefono',
            },
            editProfileMessage: {
                title: 'Messaggio del profilo',
                profileMessage: 'Messaggio del profilo',
                maxSizeProfileMessage: 'Massimo 500 caratteri',
                updateProfileMessage: 'Modifica messaggio del profilo',
            },
            editProfileType: {
                title: 'Tipo di profilo',
                profileTypeRequiredMessage: 'il tipo di profilo è richiesto',
                updateProfileType: 'cambiare il tipo di profilo',
            },
            editPassword: {
                title: "Parola d'ordine",
                password: 'Nuova password',
                confirmPassword: 'Conferma la nuova password',
                passwordRequiredMessage: 'È richiesta una nuova password',
                confirmPasswordRequiredMessage: 'È richiesta la conferma di una nuova password',
                passwordsNotMatching: 'Le due nuove password devono corrispondere',
                changePassword: 'Cambia la password',
                passwordPolicy: 'Questo campo deve contenere: 1 lettera maiuscola, 1 numero e almeno 8 caratteri',
            },
            editLocation: {
                title: 'Il mio indirizzo',
                myAddress: 'Il mio indirizzo',
                acceptVisibility: 'Accetto di essere visibile ad altri Jaccedisti',
                updateMyAddress: 'Cambia il mio indirizzo',
                shareLocalization: 'Accetto di essere visibile ad altri Jaccedisti',
                invalidAddress: 'Indirizzo non valido',
            },
            acceptTerms: {
                contact: 'Accetto di essere contattato da altri Jaccedisti',
                newsletter: 'Voglio ricevere la newsletter mensile',
                infoNewsletter: "La tua posta elettronica viene utilizzata solo per inviarti la nostra newsletter mensile e informazioni sulle attività di Jaccede. Puoi utilizzare il link di annullamento dell'iscrizione integrato nella newsletter in qualsiasi momento.",
            },
            pageTitle: 'Modifica il mio profilo',
            passwordUpdateSuccess: 'La password è stata modificata con successo!',
            photoUploadSuccess: ' cambio di foto con successo!',
            defaultAvatarUpdatedSuccess: 'Foto caricata con successo!',
            photoDeleteSuccess: 'Foto cancellata con successo!',
            nameUpdateSuccess: 'Il nome e il cognome sono stati modificati con successo!',
            profileMessageUpdateSuccess: 'Il messaggio del profilo è stato modificato con successo!',
            profileTypeUpdateSuccess: 'Il tuo tipo di profilo è stato cambiato con successo!',
            telephoneUpdateSuccess: 'Il numero di telefono è stato modificato con successo!',
            locationUpdateSuccess: 'Il tuo indirizzo è stato cambiato con successo!',
            termsUpdateSuccess: 'I termini sono stati modificati con successo!',
            success: 'Successo',
            error: 'Errore',
        },
        placeDetails: {
            report: {
                title: 'Segnala una foto come spam',
                label: 'Aggiungi un commento',
                placeholder: 'Commento',
                button: 'Seguire ',
                required: 'Il messaggio è obbligatorio.',
                reportPhotoButtonTitle: 'Report photo',
            },
            reported: {
                title: 'Foto segnalata',
                message: 'Questa foto è stata segnalata come inappropriata.  N Grazie. Terremo conto dei tuoi commenti il ​​prima possibile.  nGrazie per la tua attenzione',
                button: 'Chiudere',
            },
            reportPhoto: {
                title: 'Segnala una foto come spam.',
                label: 'Aggiungi un commento',
                placeholder: 'fare un commento',
                button: 'Continua',
                required: 'Il messaggio è obbligatorio.',
            },
            reportedPhoto: {
                title: 'Foto segnalata',
                alreadyReported: 'Questa foto è già stata segnalata',
                message: 'Questa foto è stata segnalata come inappropriata.\n Grazie. Prenderemo in considerazione i tuoi commenti il ​​prima possibile.  nGrazie per la tua attenzione.',
                button: 'Chiudere',
            },
            suggestChanges: 'Suggerisci un cambiamento',
            saveChangesButton: 'Salvare le modifiche',
            evaluateTitle: 'Valuta e valuta',
            evaluateTagline: 'Guadagna punti individuando le posizioni',
            addReviewButton: 'Aggiungi un commento',
            ratings: 'Punteggiatura',
            addPhotos: 'Aggiungi foto',
            addMorePhotos: 'Aggiungi altre foto',
            reviews: 'Opinione',
            moreReviews: 'Altre opinioni',
            yourReviews: 'Il tuo commento',
            lastChange: "L'ultima modifica di",
            lastChange2: 'lui',
            searchPlacesAround: 'Ricerca di luoghi nelle vicinanze',
            reportProblemTitle: 'Segnala questo profilo di posizione',
            reportedProblemTitle: 'La posizione è stata segnalata.',
            reportedProblemMessage: 'Grazie. Terremo conto dei tuoi commenti il ​​prima possibile.',
            reportedProblemEndMessage: 'Grazie per la tua attenzione.',
            reportedButton: 'Vicino',
            reportReviewTitle: 'Segnala un commento come spam',
            reportedReviewTitle: 'Il commento è stato denunciato.',
            error: 'Errore',
            success: 'Successo!!',
            closed: 'Decisamente chiuso',
            suggestModification: 'Suggerisci una modifica a Google Maps',
        },
        placePhotos: {
            report: {
                title: 'Segnala come foto spam.',
                label: 'Aggiungi un commento',
                placeholder: 'Fai un commento',
                button: 'Continua',
                required: 'Il messaggio è necessario.',
            },
            reported: {
                title: 'Foto segnalata',
                alreadyReported: 'Questa foto è già stata segnalata',
                message: 'Questa foto è stata segnalata come inappropriata.\n Grazie. Terremo conto dei tuoi commenti il ​​prima possibile.  nMerci de votre vigilance.',
                button: 'Chiudere',
            },
            header: 'Foto di questo luogo',
            addPhoto: 'Scarica una foto',
            successTitle: 'Successo',
            photoSuccess: 'Foto aggiunta con successo!',
            validationTitle: 'File sbagliato',
            validationDescription: 'Impossibile scaricare la foto. Il file deve avere una risoluzione minima di 1080x1080 pixel. Il file deve essere inferiore o uguale a 20 MB. Le estensioni autorizzate sono: PNG, GIF, JPEG, JPG.',
        },
        explorePlaces: {
            inputPlaceholder: 'Cerca luoghi accessibili',
        },
        filterPlaces: {
            desktopBar: {
                buttons: {
                    apply: 'Applica filtri salvati',
                    category: 'Seleziona una categoria',
                    terms: 'Aggiungi termini da filtrare',
                    spaces: 'Posizioni non dettagliate',
                    save: 'Salva',
                },
                spaces: {
                    title: 'Visualizza posizioni non dettagliate',
                    tagline: 'Vestibolo ornare tincidunt orci, non conseguat nisl gravida vel.Nulla ut varius ante.',
                },
            },
            termsSelector: {
                title: 'Accessibilità',
                inputPlaceholder: 'Inizia a digitare qui per aggiungere termini',
                showPlacesSwitch: 'Visualizza i luoghi con tutti i termini selezionati',
            },
            category: {
                placeholder: 'scrivere qui',
                suggestedCategoriesLabel: 'Categorie suggerite',
                selectedCategoryLabel: 'Seleziona una categoria',
            },
            categorySelector: {
                label: 'Categorie',
            },
            filterBar: {
                editButton: 'Cambia filtri',
                more: 'Più',
            },
            footer: {
                clear: 'Cancella filtri',
                applyFilters: 'Applica filtri',
            },
            header: {
                title: 'Filtri',
            },
            placeWithoutDetailsSwitch: {
                title: 'Posizioni non dettagliate',
            },
        },
        reviews: {
            headerTitle: 'Tutte le opinioni',
            emptyReviews: 'Questo luogo non ha ancora commenti',
            addReviewConflict: 'Questo commento esiste già',
        },
        editPlace: {
            mainPageTitle: 'Modifiche suggerite',
            category: 'Categoria',
            categoryOfPlace: 'Categoria luogo ',
            entrance: 'Iscrizione',
            access: 'Accesso',
            doorOpeningSystem: 'Apertura porta',
            ninetyCmAndAbove: '90 cm e oltre',
            indoors: 'Dentro',
            servicesAndFacilities: 'vizi e attrezzature',
            roomsTile: 'Camere da letto',
            rooms: 'Camera',
            outside: 'Al di fuori',
            disabledParkingSpaces: 'Parcheggio "disabili"',
            pavements: 'Marciapiedi',
            selectACategory: 'Seleziona una categoria',
            categoryRequired: 'Categoria richiesta.',
            successfulFeedback: 'Modificato con successo!',
            pointsFeedback: 'Hai guadagnato punti.',
            accessibilityTitle1: 'Accessibilità dettagliata',
            accessibilityTitle2: 'Di',
            accessibilityTitle2Singular: 'Persona',
            accessibilityTitle2Plural: 'Persona',
            noAccessibility: 'Questo luogo non contiene ancora informazioni sulla sua accessibilità.',
            success: 'Successo!',
            photoAdded: 'Foto aggiunta.',
            failure: 'Fallimento',
            addMorePhotos: 'Aggiungi altre foto',
        },
        challenges: {
            secretChallenge: {
                text: 'Inserisci il tuo codice segreto!',
                description: 'Per sbloccare una sfida privata, devi inserire il codice segreto che ti è stato inviato.',
                button: 'Accedi alla sfida',
                required: 'Codice richiesto',
                validation: 'Oups! :( Sembra che il tuo codice non sia valido, controlla di nuovo',
                max: 'Il codice deve contenere 6 caratteri',
            },
            header: {
                hashtag: '#JACCEDECHALLENGE2020',
                description: "Lorem Ipsum è semplicemente un testo fittizio dell'industria della stampa e della composizione. ",
            },
            publicChallengeTitle: 'Challenges publics',
        },
        challengesDetail: {
            selectTeam: 'Seleziona una squadra qui sotto per partecipare',
            newTeamSection: 'Crea e unisciti a una nuova squadra',
            createNewTeamButton: 'Crea una nuova squadra',
            challengeStartsOn: 'La sfida inizia in',
            challengeEndsOn: 'Finisce in',
            membersScoreBoard: 'Classifica dei membri',
            teamScoreboard: 'Classificazione delle squadre',
            emptyTeams: 'Non abbiamo ancora squadre',
        },
        createNewTeam: {
            label: 'Scegli il nome della tua squadra :',
            placeholder: 'Inserisci il nome della tua squadra',
            cancelButton: 'Annulla',
            button: 'Non continuare',
            nameIsRequired: 'Il nome è obbligatorio',
        },
        challengesMembersTeam: {
            joinTeamSection: {
                title: 'Voglio far parte di questa squadra',
                buttonText: 'Unisciti a questa squadra',
            },
            placesAroundMeSection: {
                title: 'Esplora luoghi',
            },
            memberHeader: {
                inviteButtonText: 'Invita un nuovo membro',
            },
            title: 'Membri del team',
        },
        challengeInviteUserTeam: {
            label: 'Email :',
            placeholder: 'Email',
            cancelButton: 'Annulla',
            button: 'Non continuare',
            emailIsRequired: 'E-mail è richiesta',
            emailFormat: 'Formato e-mail errato',
            inviteSuccess: 'Una email è stata inviata',
        },
    },
    pages: {
        globalScoreboard: {
            title: 'Classifica generale',
            description: 'Pagina di descrizione del casco di classificazione generale',
            showByPeriod: 'Visualizza per periodo',
        },
        explorePlacesPage: {
            newPlaceModal: {
                addressTitle: "Immettere l'indirizzo della posizione",
                newPlaceTitle: 'Aggiungi una nuova posizione',
                suggestionTitle: 'Cerchi uno di questi indirizzi?',
                nameLabel: 'Nome della proprietà',
                nameValidation: 'Il nome è obbligatorio',
                addressLabel: 'Direzione',
                addressValidation: "L'indirizzo è necessario",
                categoryLabel: 'Categoria',
                categoryPlaceholder: 'selezionare',
                categoryValidation: 'la categoria è obbligatoria',
                telephoneLabel: 'Telefono',
                telephoneValidation: 'Il numero di telefono è richiesto',
                websiteLabel: 'Sito web',
                websiteValidation: 'Il sito web è obbligatorio',
                continueButton: 'Seguire',
                validateButton: 'Convalidare',
                suggestionButton: 'No, continua',
                validateWebsite: 'Ingrese un enlace válido o deje el campo en blanco',
            },
            addPlaceNotFound: {
                title: 'Non hai trovato il posto che stavi cercando?',
                tagline: "Aggiungi l'indirizzo alla mappa, guadagna punti e contribuisci a rendere il mondo più accessibile!",
            },
            addPlaceNoResults: {
                title: 'Ops! Non riusciamo a trovare la località che stavi cercando.',
                tagline: 'Se conosci un luogo accessibile che non è nella nostra app, aggiungilo ora e aiuta le persone in tutto il mondo.',
            },
            chooseLocation: {
                text: 'Scegli una posizione sulla mappa',
                button: 'Scegli sulla mappa',
            },
            collaboration: {
                message: 'Perché non lavorare insieme e contribuire a rendere il mondo accessibile a tutti?',
                highlight: 'Abbiamo collaborazioni in tutto il mondo!',
            },
            title: 'Esplora i luoghi',
            description: 'Esplora la descrizione dei luoghi per helmet',
            sidebarTitle: "Accessibilità dei luoghi all'interno e intorno alla regione",
            recentContributors: 'Collaboratori recenti ',
        },
        filterPlacesPage: {
            title: 'Filtra luoghi',
            description: 'Filtra la descrizione dei posti per il casco',
        },
        reviewsPage: {
            title: 'Tutti i commenti',
            description: 'Descrizione di tutti i commenti per helmet',
        },
        notFoundPage: {
            title: 'Pagina non trovata',
            description: 'Pagina non trovata descrizione per helmet',
        },
        homePage: {
            title: 'Ricezione',
            description: 'Homepage',
        },
        signInPage: {
            title: 'Connessione',
            description: "Accesso e creazione dell'account",
            byEmail: 'O con un e-mail',
            dontHaveAccount: 'Non hai un account?',
            signUpButton: 'Crea un account',
        },
        forgotPasswordPage: {
            title: 'Reimposta la tua password',
            description: 'Reimposta la descrizione della password per helmet',
        },
        changePasswordPage: {
            title: 'Cambia la mia password',
            description: 'Cambia la descrizione della mia password in helmet',
        },
        profilePage: {
            title: 'Profilo',
            description: 'Il mio profilo',
        },
        signUpPage: {
            title: 'Crea un account',
            description: 'Registra un nuovo account',
            header: 'Crea un account',
            continueWithFacebook: 'Collegamento con Facebook',
            alreadyAccount: 'Hai già un account ?',
            signIn: 'Connessione',
        },
        confirmAccountPage: {
            title: 'Conferma il tuo account',
            success: 'Perfetto !',
            content: 'Ti abbiamo inviato le istruzioni su come completare la creazione del tuo account.',
        },
        aboutPage: {
            title: 'su',
            description: "Riguardo all'organizzazione Jaccede, il nostro obiettivo è coinvolgere le persone da un punto di vista locale.",
            listGroup: {
                inBrief: {
                    header: 'Presto',
                    item1: "Jaccede,cos'è?",
                    item2: 'Jaccede, per chi è?',
                    item3: 'Per la cronaca',
                    item4: 'Premi e riconoscimenti',
                },
                organization: {
                    header: "L'ASSOCIAZIONE",
                    item1: 'La nostra visione e le nostre missioni',
                    item2: 'Il nostro consiglio di amministrazione',
                    item3: 'Il nostro team permanente',
                    item4: 'I nostri volontari',
                    item5: 'Iscriversi all’associazione',
                    item6: 'Fare una donazione',
                },
                getInvolved: {
                    header: 'COME CONTRIBUIRE?',
                    item1: 'Online',
                    item2: 'Sul posto',
                    item3: 'Portando le vostre competenze',
                },
                partners: {
                    header: 'PARTNER',
                    item1: 'I nostri partner',
                    item2: 'Coinvolgete la vostra società',
                    item3: 'Mobilitate la vostra associazione o la vostra scuola',
                    item4: 'Partecipate come collettività',
                    item5: 'Referenziate la vostra struttura',
                },
            },
        },
        resendEmailActivationPage: {
            title: "restituire l'email di attivazione",
            description: 'E-mail di attivazione Renvoyer',
        },
        subscribersProfilePage: {
            title: 'Abbonamenti e abbonati',
            description: "Tracciamento dell'utente",
        },
        editProfilePage: {
            title: 'Modifica Profilo',
            description: 'Modifica Profilo',
        },
        editFieldProfilePage: {
            title: 'Modifica Profilo',
            description: 'Modifica Profilo',
        },
        userPage: {
            title: 'Utente',
            description: 'Contribuente',
        },
        accountActivatedPage: {
            title: 'Account attivato',
            description: 'Account attivato',
            paragraph: 'Il tuo account è attivato',
        },
        newsletterPage: {
            title: 'Newsletter',
            description: 'Pagina Newsletter',
        },
        editPlacePage: {
            title: 'Accessibilità dettagliata',
            description: "Descrizione dettagliata dell'accessibilità",
        },
        placeDetailsPage: {
            title: 'Dettagli della sede',
            description: 'Dettagli della sede',
        },
        newReviewPage: {
            title: 'Aggiungi un commento',
            description: 'Aggiungi un commento',
            postReviewButton: 'Per pubblicare',
            commentRequiredError: 'È richiesto un commento',
            commentSizeError: 'Il tuo commento deve essere lungo tra 15 e 2000 caratteri.',
            commentSuccess: 'Grazie, il tuo commento è stato preso in considerazione',
        },
        placePhotosPage: {
            title: 'Foto del luogo',
            description: 'Foto del luogo',
        },
        reviewDetailPage: {
            title: 'Avviso',
            description: 'Dettagli del commento',
            notFound: 'Commento non trovato',
            defaultError: 'Si è verificato un errore durante la ricerca di questo commento.',
        },
        categoryListViewPage: {
            title: 'Nome della regione',
            description: 'Questa è la pagina CategoryListViewPage',
        },
        categoryMapViewPage: {
            title: 'Nome della regione',
            description: 'Questa è la pagina CategoryMapViewPage',
            seeInListButton: 'Vedi elenco',
        },
        challengePage: {
            title: 'Pagina della sfida',
            description: 'Descrizione della pagina della sfida perhelmet',
        },
        challengeDetailPage: {
            title: 'Dettagli della sfida',
            description: 'Descrizione dei dettagli della sfida per helmet',
        },
        challengeJoinTeamPage: {
            warning: 'Dopo la conferma, non potrai cambiare squadra.',
            buttonText: 'Unisciti a questa squadra',
        },
        challengesMembersTeam: {
            title: 'Miembros del equipo',
            description: 'Descrizione dei membri del team per helmet',
        },
        challengeInviteUserTeamPage: {
            title: 'Invita un nuovo partecipante',
            subtitle: "Inserisci l'indirizzo email di un collega o amico per invitarli a partecipare a questa sfida. ",
        },
        challengeCreateNewTeamPage: {
            title: 'La registrazione è irreversibile',
            subtitle: 'Stai per creare e unirti a una squadra per partecipare a questa sfida.',
            warningAdvice: 'Non potrai abbandonare la sfida o lasciare la tua squadra.',
        },
        mailSystemPage: {
            title: 'Sistema di posta elettronica',
            description: 'Questa è la pagina del sistema di posta elettronica',
        },
    },
};
