import { axios } from 'api';
var versionV4 = '/v4';
var getAllPages = function () { return versionV4 + "/pages?simple=true"; };
var getOnlyPage = function (slug) {
    return versionV4 + "/pages/" + slug + "?image_size=_large";
};
export default {
    aboutUs: function () { return axios.get(getAllPages()); },
    aboutUsSlug: function (slug) { return axios.get(getOnlyPage(slug)); },
};
