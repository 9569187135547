import { CHALLENGES_JOIN_TEAM_CLEAR_FAILURE, CHALLENGES_JOIN_TEAM_CLEAR_SUCCESS, CHALLENGES_JOIN_TEAM_FAILURE, CHALLENGES_JOIN_TEAM_REQUEST, CHALLENGES_JOIN_TEAM_SUCCESS, } from './constants';
export var addCurrentUserJoinTeamRequest = function (payload) { return ({
    type: CHALLENGES_JOIN_TEAM_REQUEST,
    payload: payload,
}); };
export var addCurrentUserJoinTeamFailure = function (payload) { return ({
    type: CHALLENGES_JOIN_TEAM_FAILURE,
    payload: payload,
}); };
export var addCurrentUserJoinTeamSuccess = function () { return ({
    type: CHALLENGES_JOIN_TEAM_SUCCESS,
}); };
export var addCurrentUserJoinTeamClearFailure = function () { return ({
    type: CHALLENGES_JOIN_TEAM_CLEAR_FAILURE,
}); };
export var addCurrentUserJoinTeamClearSuccess = function (payload) { return ({
    type: CHALLENGES_JOIN_TEAM_CLEAR_SUCCESS,
    payload: payload,
}); };
