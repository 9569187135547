export var GET_PHOTOS_ACTION_REQUEST = 'PlacePhotos/GET_PHOTOS_ACTION_REQUEST';
export var GET_PHOTOS_ACTION_SUCCESS = 'PlacePhotos/GET_PHOTOS_ACTION_SUCCESS';
export var GET_PHOTOS_ACTION_FAILURE = 'PlacePhotos/GET_PHOTOS_ACTION_FAILURE';
export var POST_PHOTOS_ACTION_REQUEST = 'PlacePhotos/POST_PHOTOS_ACTION_REQUEST';
export var POST_PHOTOS_ACTION_SUCCESS = 'PlacePhotos/POST_PHOTOS_ACTION_SUCCESS';
export var POST_PHOTOS_ACTION_FAILURE = 'PlacePhotos/POST_PHOTOS_ACTION_FAILURE';
export var POST_PHOTOS_ACTION_CLEAR_FAILURE = 'PlacePhotos/POST_PHOTOS_ACTION_CLEAR_FAILURE';
export var POST_PHOTOS_ACTION_CLEAR_SUCCESS = 'PlacePhotos/POST_PHOTOS_ACTION_CLEAR_SUCCESS';
export var PUT_PHOTOS_ACTION_REQUEST = 'PlacePhotos/PUT_PHOTOS_ACTION_REQUEST';
export var PUT_PHOTOS_ACTION_SUCCESS = 'PlacePhotos/PUT_PHOTOS_ACTION_SUCCESS';
export var PUT_PHOTOS_ACTION_FAILURE = 'PlacePhotos/PUT_PHOTOS_ACTION_FAILURE';
export var REPORT_MEDIA_PLACE = 'PlacePhotos/REPORT_MEDIA_PLACE';
export var REPORT_MEDIA_PLACE_SUCCESS = 'PlacePhotos/REPORT_MEDIA_PLACE_SUCCESS';
export var REPORT_MEDIA_PLACE_FAILURE = 'PlacePhotos/REPORT_MEDIA_PLACE_FAILURE';
export var CLEAR_REPORTED = 'PlacePhotos/CLEAR_REPORTED';
export var DELETE_PHOTO = 'PlacePhotos/DELETE_PHOTO';
export var DELETE_PHOTO_SUCCESS = 'PlacePhotos/DELETE_PHOTO_SUCCESS';
export var DELETE_PHOTO_FAILURE = 'PlacePhotos/DELETE_PHOTO_FAILURE';
export var CLEAR_NOTIFICATION = 'PlacePhotos/CLEAR_NOTIFICATION';
