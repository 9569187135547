import React, { memo } from 'react';
export var ParkingOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.3", style: { fill: '#000' }, d: "M7.347 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.758 4.759-12.474 0-17.232s-12.474-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M13.932 9.694l-0.659-1.538c-0.148-0.346-0.362-0.537-0.806-0.537h-2.966c-0.346 0-0.842 0.017-1.039 0.488l-0.802 1.628c-0.493 0.124-0.651 0.355-0.651 0.924v2.446h0.305v0.151c0 0.471 0.366 0.792 0.761 0.792s0.763-0.321 0.763-0.767v-0.176h3.962v0.274c0 0.347 0.453 0.668 0.825 0.668 0.321 0 0.699-0.296 0.699-0.668v-0.274h0.305v-2.397c0-0.495-0.081-0.99-0.697-1.014zM8.239 11.671c-0.346 0-0.67-0.297-0.67-0.666 0-0.347 0.297-0.644 0.67-0.644s0.667 0.322 0.667 0.692c0 0.347-0.272 0.618-0.667 0.618zM8.336 9.752l0.645-1.352c0.098-0.197 0.221-0.477 0.519-0.477h2.793c0.297 0 0.445 0.254 0.52 0.453l0.592 1.375h-5.069zM13.581 11.721c-0.397 0-0.718-0.321-0.718-0.667 0-0.371 0.321-0.669 0.669-0.669 0.421 0 0.691 0.298 0.691 0.643 0 0.397-0.27 0.693-0.642 0.693z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M23.742 9.694l-0.63-1.538c-0.148-0.346-0.333-0.537-0.777-0.537h-2.966c-0.346 0-0.842 0.017-1.039 0.488l-0.859 1.628c-0.493 0.124-0.708 0.355-0.708 0.924v2.446h0.305v0.151c0 0.471 0.366 0.792 0.761 0.792s0.763-0.321 0.763-0.767v-0.176h3.962v0.274c0 0.347 0.453 0.668 0.825 0.668 0.321 0 0.699-0.296 0.699-0.668v-0.274h0.305v-2.397c-0-0.495-0.023-0.99-0.639-1.014zM18.107 11.671c-0.346 0-0.67-0.297-0.67-0.666 0-0.347 0.297-0.644 0.67-0.644s0.667 0.322 0.667 0.692c0 0.347-0.272 0.618-0.667 0.618zM18.204 9.752l0.645-1.352c0.098-0.197 0.221-0.477 0.519-0.477h2.793c0.297 0 0.445 0.254 0.52 0.453l0.592 1.375h-5.069zM23.448 11.721c-0.397 0-0.718-0.321-0.718-0.667 0-0.371 0.321-0.669 0.669-0.669 0.421 0 0.691 0.298 0.691 0.643 0 0.397-0.27 0.693-0.642 0.693z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M13.932 17.309l-0.659-1.535c-0.148-0.347-0.362-0.535-0.806-0.535h-2.966c-0.346 0-0.842 0.015-1.039 0.486l-0.802 1.626c-0.493 0.124-0.651 0.354-0.651 0.924v2.45h0.305v0.147c0 0.471 0.366 0.791 0.761 0.791s0.763-0.321 0.763-0.766v-0.172h3.962v0.27c0 0.347 0.453 0.668 0.825 0.668 0.321 0 0.699-0.296 0.699-0.668v-0.27h0.305v-2.401c0-0.495-0.081-0.99-0.697-1.014zM8.239 19.287c-0.346 0-0.67-0.297-0.67-0.666 0-0.347 0.297-0.644 0.67-0.644s0.667 0.321 0.667 0.692c0 0.347-0.272 0.618-0.667 0.618zM8.336 17.371l0.645-1.352c0.098-0.197 0.221-0.477 0.519-0.477h2.793c0.297 0 0.445 0.254 0.52 0.453l0.592 1.376h-5.069zM13.581 19.337c-0.397 0-0.718-0.321-0.718-0.668 0-0.371 0.321-0.668 0.669-0.668 0.421 0 0.691 0.297 0.691 0.643 0 0.397-0.27 0.693-0.642 0.693z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M23.742 17.309l-0.63-1.535c-0.148-0.347-0.333-0.535-0.777-0.535h-2.966c-0.346 0-0.842 0.015-1.039 0.486l-0.859 1.626c-0.493 0.124-0.708 0.354-0.708 0.924v2.45h0.305v0.147c0 0.471 0.366 0.791 0.761 0.791s0.763-0.321 0.763-0.766v-0.172h3.962v0.27c0 0.347 0.453 0.668 0.825 0.668 0.321 0 0.699-0.296 0.699-0.668v-0.27h0.305v-2.401c-0-0.495-0.023-0.99-0.639-1.014zM18.107 19.287c-0.346 0-0.67-0.297-0.67-0.666 0-0.347 0.297-0.644 0.67-0.644s0.667 0.321 0.667 0.692c0 0.347-0.272 0.618-0.667 0.618zM18.204 17.371l0.645-1.352c0.098-0.197 0.221-0.477 0.519-0.477h2.793c0.297 0 0.445 0.254 0.52 0.453l0.592 1.376h-5.069zM23.448 19.337c-0.397 0-0.718-0.321-0.718-0.668 0-0.371 0.321-0.668 0.669-0.668 0.421 0 0.691 0.297 0.691 0.643 0 0.397-0.27 0.693-0.642 0.693z" })));
});
