import React from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Header } from '../Header';
import { Container, Context } from './styles';
export var Content = function (_a) {
    var sectionContent = _a.sectionContent, setListItemSelected = _a.setListItemSelected, listItemSelected = _a.listItemSelected, sectionTitle = _a.sectionTitle;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (React.createElement(Container, null,
        (isMobile || isTabletOrMobile) && (React.createElement(Header, { setListItemSelected: setListItemSelected, listItemSelected: listItemSelected, sectionTitle: sectionTitle })),
        React.createElement(Context, null,
            React.createElement("article", null, sectionContent))));
};
