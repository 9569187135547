var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { push } from 'connected-react-router';
import { paths } from 'containers/RoutesProvider/routes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createPlaceEvent } from 'utils/tagEvents';
import { addPlaceSuccess, matchPlaceFailure, matchPlaceSuccess, } from './actions';
import { ADD_PLACE, MATCH_PLACE } from './constants';
export function matchPlace(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.places.match, payload)];
            case 1:
                response = _c.sent();
                return [4 /*yield*/, put(matchPlaceSuccess(response.data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [4 /*yield*/, put(matchPlaceFailure())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function addPlace(_a) {
    var request, _b, latitude, longitude, responseGeolocalize, response, _c, response;
    var payload = _a.payload;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 9, , 11]);
                request = __assign({}, payload);
                _b = payload.contributorPosition, latitude = _b.latitude, longitude = _b.longitude;
                if (!(latitude === 0 || longitude === 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, call(api.services.geolocalize)];
            case 1:
                responseGeolocalize = _e.sent();
                if (responseGeolocalize.status === 200) {
                    request = __assign(__assign({}, payload), { contributorPosition: {
                            latitude: responseGeolocalize.data.latitude,
                            longitude: responseGeolocalize.data.longitude,
                        } });
                }
                _e.label = 2;
            case 2: return [4 /*yield*/, call(api.places.addPlace, request)];
            case 3:
                response = _e.sent();
                if (!((_d = response.data) === null || _d === void 0 ? void 0 : _d.googlePlaceId)) return [3 /*break*/, 7];
                if (!response.data.name) return [3 /*break*/, 5];
                return [4 /*yield*/, call(createPlaceEvent, response.data.name)];
            case 4:
                _e.sent();
                _e.label = 5;
            case 5: return [4 /*yield*/, put(push(paths.placeDetailsPage
                    .replace(':placeId', response.data.googlePlaceId)
                    .replace(':placeName', response.data.slug)))];
            case 6:
                _e.sent();
                _e.label = 7;
            case 7: return [4 /*yield*/, put(addPlaceSuccess())];
            case 8:
                _e.sent();
                return [3 /*break*/, 11];
            case 9:
                _c = _e.sent();
                response = _c.response;
                return [4 /*yield*/, put(matchPlaceFailure())];
            case 10:
                _e.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export default function addNewPlaceSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(MATCH_PLACE, matchPlace)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(ADD_PLACE, addPlace)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
