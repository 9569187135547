import { EmptyListText, List, ListItem, Skeleton } from 'components';
import map from 'lodash/map';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, ListDetails, Subtitle, Title } from './styles';
export var Accessibility = function (_a) {
    var values = _a.values, contributors = _a.contributors, loading = _a.loading;
    var formatAccessibility = function () {
        var accessibilityChild = function (a) {
            var _a;
            return (React.createElement("div", { key: a.label },
                a.label,
                ((_a = a.children) === null || _a === void 0 ? void 0 : _a.length) ? (map(a.children, function (c) { return accessibilityChild(c); })) : (React.createElement("div", null, a.value))));
        };
        return map(values, function (a) {
            var _a;
            return (React.createElement(ListItem, { key: a.label },
                React.createElement(Subtitle, null, a.label),
                React.createElement(ListDetails, null, ((_a = a.children) === null || _a === void 0 ? void 0 : _a.length) ? (map(a.children, function (c) { return accessibilityChild(c); })) : (React.createElement("div", null, a.value)))));
        });
    };
    return (React.createElement(Container, null,
        React.createElement(Skeleton, { loading: loading, active: true, paragraph: { rows: 3 } },
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "containers.editPlace.accessibilityTitle1" }),
                ' ',
                !!contributors && (React.createElement(React.Fragment, null,
                    React.createElement(FormattedMessage, { id: "containers.editPlace.accessibilityTitle2" }),
                    ' ',
                    React.createElement("span", null, contributors),
                    ' ',
                    React.createElement(FormattedMessage, { id: "containers.editPlace." + (contributors && contributors > 1
                            ? 'accessibilityTitle2Plural'
                            : 'accessibilityTitle2Singular') }))))),
        values && values.length ? (React.createElement(List, null, formatAccessibility())) : (React.createElement(EmptyListText, null,
            React.createElement(FormattedMessage, { id: "containers.editPlace.noAccessibility" })))));
};
