var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
var textColor = theme('mode', {
    light: colors.space.tint700,
    dark: colors.base.white,
});
var desktopBackground = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
export var Container = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  margin-top: ", ";\n\n  ", ";\n\n  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: ", ";\n  }\n"], ["\n  border-top: ", " solid ", ";\n  margin-top: ", ";\n\n  ",
    ";\n\n  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: ", ";\n  }\n"])), rem(1), borderColor, rem(20), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-radius: ", ";\n    border: ", " solid ", ";\n    background-color: ", ";\n  "], ["\n    border-radius: ", ";\n    border: ", " solid ", ";\n    background-color: ", ";\n  "])), rem(10), rem(1), borderColor, desktopBackground), textColor);
export var Title = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin: ", " 0;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin: ", " 0;\n"])), rem(16), fonts.weight.semibold, rem(20));
export var TabTitleContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-shrink: 0;\n\n  ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  flex-shrink: 0;\n\n  ",
    ";\n"])), media.greaterThan('medium')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 0 ", ";\n  "], ["\n    padding: 0 ", ";\n  "])), rem(40)));
export var TabTitle = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: ", ";\n  font-weight: ", ";\n"], ["\n  margin-left: ", ";\n  font-weight: ", ";\n"])), rem(6), fonts.weight.bold);
export var PaginationWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
