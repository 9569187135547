export var APP_TOGGLE_DIRECTION = 'App/TOGGLE_DIRECTION';
export var APP_TOGGLE_DARK_MODE = 'App/TOGGLE_DARK_MODE';
export var APP_TOOGLE_FONT_SIZE = 'App/TOOGLE_FONT_SIZE';
export var APP_KEYBOARD_ENABLED = 'App/APP_KEYBOARD_ENABLED';
export var APP_KEYBOARD_DISABLED = 'App/APP_KEYBOARD_DISABLED';
export var APP_SET_DIRECTION = 'App/SET_DIRECTION';
export var APP_SET_DARK_MODE = 'App/SET_DARK_MODE';
export var APP_SET_FONT_SIZE = 'App/SET_FONT_SIZE';
export var APP_INIT_REQUEST = 'App/INIT_REQUEST';
export var APP_INIT_SUCCESS = 'App/INIT_SUCCESS';
export var APP_INIT_FAILURE = 'App/INIT_FAILURE';
export var APP_USER_CURRENT_USER = 'App/APP_USER_CURRENT_USER';
export var APP_FIELD_ON_FOCUS = 'App/APP_FIELD_ON_FOCUS';
export var APP_FIELD_BLUR = 'App/APP_FIELD_BLUR';
