var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { breakpoints, colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var HeaderContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  width: 100%;\n  max-width: ", ";\n  margin: 0 auto;\n  padding: ", ";\n"], ["\n  color: ", ";\n  width: 100%;\n  max-width: ", ";\n  margin: 0 auto;\n  padding: ", ";\n"])), color, breakpoints.medium, rem(20));
export var Title = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: ", ";\n"])), rem(18), fonts.weight.semibold, rem(20));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  p {\n    margin-bottom: ", ";\n  }\n\n  img {\n    border-radius: ", ";\n    margin-bottom: ", ";\n    max-width: 100%;\n  }\n"], ["\n  p {\n    margin-bottom: ", ";\n  }\n\n  img {\n    border-radius: ", ";\n    margin-bottom: ", ";\n    max-width: 100%;\n  }\n"])), rem(10), rem(5), rem(5));
var templateObject_1, templateObject_2, templateObject_3;
