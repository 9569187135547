import React, { memo } from 'react';
export var Bank = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M27.733 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#00b4a0", style: { fill: '#00b4a0' }, d: "M7.042 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.759 4.759-12.474 0-17.232s-12.473-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M22.084 14.405c-0.322-0.8-0.858-1.517-1.555-2.107l1.312-0.808c-0.534-0.836-1.848-0.971-2.936-0.301-0.031 0.020-0.060 0.041-0.091 0.061-0.911-0.388-1.943-0.609-3.038-0.609-3.672 0-6.648 2.462-6.648 5.5 0 1.697 0.93 3.215 2.391 4.224v1.936h2.527v-0.848c0.552 0.123 1.132 0.19 1.73 0.19 0.634 0 1.247-0.074 1.827-0.211v0.87h2.528v-2.005c0.889-0.639 1.572-1.471 1.952-2.417h1.623v-3.475l-1.623-0zM17.804 12.993h-4.056v-1.347h4.056v1.347zM19.158 15.845c-0.451 0-0.816-0.359-0.816-0.802s0.365-0.802 0.816-0.802c0.451 0 0.817 0.359 0.817 0.802s-0.365 0.802-0.817 0.802z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M15.776 9.203c1.122 0 2.032-0.894 2.032-1.996s-0.91-1.996-2.032-1.996c-1.122 0-2.032 0.894-2.032 1.996s0.91 1.996 2.032 1.996z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M5.689 17.725l-0.236-0.82c0.469-0.13 0.827-0.293 1.16-0.512-0.176-0.184-0.315-0.387-0.408-0.6-0.295-0.674-0.059-1.328 0.599-1.665 0.587-0.299 1.19-0.184 1.538 0.293 0.35 0.481 0.364 1.286-0.286 1.897-0.001 0.001-0.002 0.002-0.003 0.002 0.453 0.162 1.003 0.202 1.57 0.010l0.282 0.806c-0.92 0.311-1.853 0.173-2.569-0.204-0.469 0.35-0.962 0.602-1.648 0.793zM7.429 14.817c-0.066 0.002-0.141 0.025-0.224 0.068-0.173 0.089-0.352 0.233-0.203 0.574 0.059 0.137 0.158 0.273 0.288 0.4 0.055-0.050 0.111-0.101 0.168-0.155 0.29-0.272 0.309-0.608 0.18-0.785-0.051-0.070-0.122-0.105-0.208-0.101z" })));
});
