var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { placeholderPlace } from 'assets/images';
import { paths } from 'containers/RoutesProvider/routes';
import { LodgingSquare } from 'icons/category';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { colors } from 'styles';
export var SuggestionList = memo(function (_a) {
    var places = _a.places;
    var history = useHistory();
    return (React.createElement(Container, null, map(uniq(places, 
    // @ts-ignore
    'googlePlaceId'), function (place) {
        var _a, _b;
        return (React.createElement(Place, { key: place.name, onClick: function () {
                return history.push(paths.placeDetailsPage
                    .replace(':placeId', place.googlePlaceId)
                    .replace(':placeName', place.slug));
            } },
            React.createElement(Info, null,
                React.createElement(Name, null, place.name),
                React.createElement(Category, null, place.category.label),
                React.createElement(Address, null, (_a = place.address) === null || _a === void 0 ? void 0 : _a.full)),
            React.createElement(Picture, null,
                React.createElement(Image, { src: ((_b = place.mainImage) === null || _b === void 0 ? void 0 : _b.urlLow) || placeholderPlace, alt: place.name }),
                React.createElement(CategoryIcon, null,
                    React.createElement(LodgingSquare, { size: rem(16) })))));
    })));
});
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: ", " solid ", ";\n  padding: ", ";\n"], ["\n  border-radius: ", ";\n  border: ", " solid ", ";\n  padding: ", ";\n"])), rem(8), rem(1), colors.space.tint300, rem(10));
var Place = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", ";\n  cursor: pointer;\n\n  &:not(:last-of-type) {\n    margin-bottom: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", ";\n  cursor: pointer;\n\n  &:not(:last-of-type) {\n    margin-bottom: ", ";\n  }\n"])), rem(1), colors.space.tint300, rem(8), rem(8), rem(10));
var Info = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var Name = styled.strong(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), colors.space.tint900, rem(16));
var Category = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), colors.space.tint600, rem(13));
var Address = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), colors.space.tint600, rem(10));
var Picture = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var Image = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  border-radius: ", ";\n\n  width: ", ";\n  height: ", ";\n  overflow: hidden;\n  object-fit: cover;\n"], ["\n  display: flex;\n  border-radius: ", ";\n\n  width: ", ";\n  height: ", ";\n  overflow: hidden;\n  object-fit: cover;\n"])), rem(6), rem(50), rem(50));
var CategoryIcon = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: ", ";\n  border: ", " solid ", ";\n  bottom: ", ";\n\n  position: absolute;\n  width: ", ";\n  height: ", ";\n  overflow: hidden;\n  left: ", ";\n\n  svg {\n    flex-shrink: 0;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: ", ";\n  border: ", " solid ", ";\n  bottom: ", ";\n\n  position: absolute;\n  width: ", ";\n  height: ", ";\n  overflow: hidden;\n  left: ", ";\n\n  svg {\n    flex-shrink: 0;\n  }\n"])), rem(4), rem(1), colors.base.white, rem(4), rem(16), rem(16), rem(4));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
