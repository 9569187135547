var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { push } from 'connected-react-router';
import { paths } from 'containers/RoutesProvider/routes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { addFavoritesEvent, removeFavoritesEvent, updatePlaceEvent, } from 'utils/tagEvents';
import { placesAroundBookmark } from '../ExplorePlaces/store/placesAround/actions';
import { addPlaceToBookmarkSuccess, getCategoriesFailure, getCategoriesSuccess, getPlaceCriteriaFailure, getPlaceCriteriaSuccess, getPlaceDetailsFailure, getPlaceDetailsSuccess, reportMediaPlaceFailure, reportMediaPlaceSuccess, reportPlaceFailure, reportPlaceSuccess, updatePlaceFailure, updatePlaceSuccess, } from './actions';
import { ADD_PLACE_TO_BOOKMARK, GET_CATEGORIES, GET_PLACE_CRITERIA, GET_PLACE_DETAILS, REMOVE_PLACE_FROM_BOOKMARK, REPORT_MEDIA_PLACE, REPORT_PLACE, UPDATE_PLACE, } from './constants';
export function getPlaceDetails(_a) {
    var response, error_1, err, _b;
    var payload = _a.payload;
    var _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 3, , 9]);
                return [4 /*yield*/, call(api.places.getPlacesDetails, payload)];
            case 1:
                response = _e.sent();
                return [4 /*yield*/, put(getPlaceDetailsSuccess(response.data))];
            case 2:
                _e.sent();
                return [3 /*break*/, 9];
            case 3:
                error_1 = _e.sent();
                err = error_1;
                _b = (_c = err.response) === null || _c === void 0 ? void 0 : _c.status;
                switch (_b) {
                    case 404: return [3 /*break*/, 4];
                }
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, put(push(paths.notFoundPageCustom))];
            case 5:
                _e.sent();
                return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, put(getPlaceDetailsFailure((_d = err.response) === null || _d === void 0 ? void 0 : _d.data.error))];
            case 7:
                _e.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/, false];
            case 9: return [2 /*return*/];
        }
    });
}
export function addPlaceToBookmark(_a) {
    var response, error_2, err, _b;
    var payload = _a.payload, placeName = _a.placeName;
    var _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 7, , 13]);
                return [4 /*yield*/, call(api.places.addPlaceToBookmark, payload)];
            case 1:
                _e.sent();
                return [4 /*yield*/, call(api.places.getPlacesDetails, payload)];
            case 2:
                response = _e.sent();
                return [4 /*yield*/, put(getPlaceDetailsSuccess(response.data))];
            case 3:
                _e.sent();
                return [4 /*yield*/, put(placesAroundBookmark({ googlePlaceId: payload, isFavorite: true }))];
            case 4:
                _e.sent();
                return [4 /*yield*/, put(addPlaceToBookmarkSuccess(payload))];
            case 5:
                _e.sent();
                return [4 /*yield*/, call(addFavoritesEvent, placeName)];
            case 6:
                _e.sent();
                return [3 /*break*/, 13];
            case 7:
                error_2 = _e.sent();
                err = error_2;
                _b = (_c = err.response) === null || _c === void 0 ? void 0 : _c.status;
                switch (_b) {
                    case 404: return [3 /*break*/, 8];
                }
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, put(push(paths.notFoundPageCustom))];
            case 9:
                _e.sent();
                return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, put(getPlaceDetailsFailure((_d = err.response) === null || _d === void 0 ? void 0 : _d.data.error))];
            case 11:
                _e.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/, false];
            case 13: return [2 /*return*/];
        }
    });
}
export function removePlaceFromBookmark(_a) {
    var response, error_3, err, _b;
    var payload = _a.payload, placeName = _a.placeName;
    var _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 7, , 13]);
                return [4 /*yield*/, call(api.places.removePlaceFromBookmark, payload)];
            case 1:
                _e.sent();
                return [4 /*yield*/, call(api.places.getPlacesDetails, payload)];
            case 2:
                response = _e.sent();
                return [4 /*yield*/, put(getPlaceDetailsSuccess(response.data))];
            case 3:
                _e.sent();
                return [4 /*yield*/, put(placesAroundBookmark({ googlePlaceId: payload, isFavorite: false }))];
            case 4:
                _e.sent();
                return [4 /*yield*/, put(addPlaceToBookmarkSuccess(payload))];
            case 5:
                _e.sent();
                return [4 /*yield*/, call(removeFavoritesEvent, placeName)];
            case 6:
                _e.sent();
                return [3 /*break*/, 13];
            case 7:
                error_3 = _e.sent();
                err = error_3;
                _b = (_c = err.response) === null || _c === void 0 ? void 0 : _c.status;
                switch (_b) {
                    case 404: return [3 /*break*/, 8];
                }
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, put(push(paths.notFoundPageCustom))];
            case 9:
                _e.sent();
                return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, put(getPlaceDetailsFailure((_d = err.response) === null || _d === void 0 ? void 0 : _d.data.error))];
            case 11:
                _e.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/, false];
            case 13: return [2 /*return*/];
        }
    });
}
export function getPlaceCriteria(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.places.getPlaceCriteria, payload)];
            case 1:
                response = _c.sent();
                return [4 /*yield*/, put(getPlaceCriteriaSuccess(response.data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [4 /*yield*/, put(getPlaceCriteriaFailure())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function getCategories() {
    var response, _a, response;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.places.getCategories)];
            case 1:
                response = _b.sent();
                return [4 /*yield*/, put(getCategoriesSuccess(response.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                _a = _b.sent();
                response = _a.response;
                return [4 /*yield*/, put(getCategoriesFailure())];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function updatePlace(_a) {
    var updateResponse, criteriaResponse, detailsResponse, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 8, , 10]);
                return [4 /*yield*/, call(api.places.updatePlace, payload)];
            case 1:
                updateResponse = _c.sent();
                return [4 /*yield*/, call(api.places.getPlaceCriteria, payload.placeId)];
            case 2:
                criteriaResponse = _c.sent();
                return [4 /*yield*/, call(api.places.getPlacesDetails, payload.placeId)];
            case 3:
                detailsResponse = _c.sent();
                return [4 /*yield*/, put(updatePlaceSuccess(updateResponse.data.contribution))];
            case 4:
                _c.sent();
                return [4 /*yield*/, put(getPlaceDetailsSuccess(detailsResponse.data))];
            case 5:
                _c.sent();
                return [4 /*yield*/, put(getPlaceCriteriaSuccess(criteriaResponse.data))];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(updatePlaceEvent, detailsResponse.data.name)];
            case 7:
                _c.sent();
                return [3 /*break*/, 10];
            case 8:
                _b = _c.sent();
                response = _b.response;
                return [4 /*yield*/, put(updatePlaceFailure())];
            case 9:
                _c.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function reportPlace(_a) {
    var _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.places.report, payload)];
            case 1:
                _c.sent();
                return [4 /*yield*/, put(reportPlaceSuccess())];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [4 /*yield*/, put(reportPlaceFailure())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function reportMediaPlace(_a) {
    var _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.places.reportMedia, payload)];
            case 1:
                _c.sent();
                return [4 /*yield*/, put(reportMediaPlaceSuccess())];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [4 /*yield*/, put(reportMediaPlaceFailure())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export default function placeDetailsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(GET_PLACE_DETAILS, getPlaceDetails)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(ADD_PLACE_TO_BOOKMARK, addPlaceToBookmark)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(REMOVE_PLACE_FROM_BOOKMARK, removePlaceFromBookmark)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(GET_PLACE_CRITERIA, getPlaceCriteria)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(GET_CATEGORIES, getCategories)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(UPDATE_PLACE, updatePlace)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(REPORT_PLACE, reportPlace)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(REPORT_MEDIA_PLACE, reportMediaPlace)];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
