var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { darken, rem, rgba, shade, tint } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp, switchProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var shadow = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var Container = styled.button(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  align-items: center;\n  border-style: solid;\n  border-width: 1px;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  outline: 0;\n  overflow: hidden;\n  text-decoration: none;\n  transition: all 0.2s ease;\n\n  svg {\n    flex-shrink: 0;\n  }\n\n  span {\n    display: flex;\n    line-height: 1;\n  }\n\n  > svg + span {\n    margin-left: ", ";\n  }\n\n  > span + svg {\n    margin-left: ", ";\n  }\n\n  ", "\n\n  ", "\n\n  ", "\n\n  &:focus:not(:disabled) {\n    ", ";\n  }\n\n  ", "\n\n  ", "\n"], ["\n  align-items: center;\n  border-style: solid;\n  border-width: 1px;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  outline: 0;\n  overflow: hidden;\n  text-decoration: none;\n  transition: all 0.2s ease;\n\n  svg {\n    flex-shrink: 0;\n  }\n\n  span {\n    display: flex;\n    line-height: 1;\n  }\n\n  > svg + span {\n    margin-left: ", ";\n  }\n\n  > span + svg {\n    margin-left: ", ";\n  }\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n\n  &:focus:not(:disabled) {\n    ", ";\n  }\n\n  ",
    "\n\n  ",
    "\n"])), colors.base.white, fonts.weight.semibold, rem(6), rem(6), switchProp('size', {
    icon: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      padding: ", ";\n    "], ["\n      padding: ", ";\n    "])), rem(4)),
    mini: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      padding: ", " ", ";\n    "], ["\n      padding: ", " ", ";\n    "])), rem(2), rem(8)),
    small: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      padding: ", " ", ";\n    "], ["\n      padding: ", " ", ";\n    "])), rem(6), rem(12)),
    medium: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      padding: ", " ", ";\n    "], ["\n      padding: ", " ", ";\n    "])), rem(12), rem(18)),
    large: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      padding: ", " ", ";\n    "], ["\n      padding: ", " ", ";\n    "])), rem(16), rem(24)),
}), switchProp('shape', {
    square: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      border-radius: ", ";\n    "], ["\n      border-radius: ", ";\n    "])), rem(2)),
    circle: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      border-radius: 50%;\n    "], ["\n      border-radius: 50%;\n    "]))),
    rounded: css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      border-radius: ", ";\n    "], ["\n      border-radius: ", ";\n    "])), rem(10)),
    pill: css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      border-radius: ", ";\n    "], ["\n      border-radius: ", ";\n    "])), rem(24)),
}), switchProp('variant', {
    primary: css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "])), colors.base.primary, shade(0.2, colors.base.primary), tint(0.2, colors.base.primary), darken(0.05, colors.base.primary)),
    secondary: css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "])), colors.base.secondary, shade(0.2, colors.base.secondary), tint(0.2, colors.base.secondary), darken(0.05, colors.base.secondary)),
    warning: css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "])), colors.base.warning, shade(0.2, colors.base.warning), colors.space.tint700, tint(0.2, colors.base.warning), darken(0.05, colors.base.warning)),
    info: css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "])), colors.base.info, shade(0.2, colors.base.info), tint(0.2, colors.base.info), darken(0.05, colors.base.info)),
    facebook: css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "])), colors.base.facebook, shade(0.2, colors.base.facebook), tint(0.2, colors.base.facebook), darken(0.05, colors.base.facebook)),
    ghost: css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n\n      &:hover:not(:disabled) {\n        background-color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        background-color: ", ";\n      }\n    "])), colors.space.tint100, shade(0.2, colors.space.tint100), colors.space.tint800, tint(0.2, colors.space.tint200), darken(0.05, colors.space.tint200)),
    link: css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n      background-color: transparent;\n      border-color: transparent;\n      color: ", ";\n      padding: ", ";\n\n      &:hover:not(:disabled) {\n        color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        color: ", ";\n      }\n    "], ["\n      background-color: transparent;\n      border-color: transparent;\n      color: ", ";\n      padding: ", ";\n\n      &:hover:not(:disabled) {\n        color: ", ";\n      }\n\n      &:active:not(:disabled) {\n        color: ", ";\n      }\n    "])), colors.space.tint700, rem(8), tint(0.2, colors.space.tint800), darken(0.05, colors.space.tint800)),
}), shadow, ifProp({ disabled: true }, css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n      cursor: not-allowed;\n    "], ["\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n      cursor: not-allowed;\n    "])), colors.space.tint500, colors.space.tint500, colors.base.white)), ifProp('full', css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      width: 100%;\n    "], ["\n      width: 100%;\n    "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
