import { avatarImage1 } from 'assets/images';
import { Avatar, Popover } from 'components';
import { authLogoutRequest } from 'containers/Auth/actions';
import { makeSelectUser } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Content, Target } from './styles';
export var ProfileMenu = memo(function () {
    var _a;
    var formatMessage = useIntl().formatMessage;
    var user = useSelector(makeSelectUser());
    var history = useHistory();
    var location = useLocation();
    var logoutCallback = useAction(authLogoutRequest);
    var handleLogout = function () { return logoutCallback(); };
    function handleGoToProfile() {
        history.push(paths.profilePage, { from: location.pathname });
    }
    // function handleGoToMailSystem() {
    //   history.push(paths.mailSystemPage, { from: location.pathname });
    // }
    var content = (React.createElement(Content, null,
        React.createElement(Button, { type: "button", onClick: handleGoToProfile }, formatMessage({ id: 'components.profileMenu.profile' })),
        React.createElement(Button, { type: "button", onClick: handleLogout }, formatMessage({ id: 'components.profileMenu.logout' }))));
    return (React.createElement(Popover, { content: content, trigger: "click", placement: "bottomRight" },
        React.createElement(Target, { variant: "link", tabIndex: 0, "aria-haspopup": "true" },
            React.createElement(Avatar, { src: ((_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.urlThumb) || avatarImage1, alt: (user === null || user === void 0 ? void 0 : user.firstName) ? user.firstName + " profile image"
                    : 'User profile image', size: "small" }))));
});
