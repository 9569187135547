import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    getConversations: versionV4 + "/conversations",
};
export default {
    getConversations: function (page, perPage) {
        if (perPage === void 0) { perPage = 10; }
        return axios.get(endpoints.getConversations + "?page=" + page + "&per_page=" + perPage);
    },
    getConversation: function (id) {
        return axios.get(endpoints.getConversations + "/" + id);
    },
    lockConversationNotification: function (id) {
        return axios.put(endpoints.getConversations + "/" + id + "/lock_notification");
    },
    unlockConversationNotification: function (id) {
        return axios.put(endpoints.getConversations + "/" + id + "/unlock_notification");
    },
    quitGroupConversation: function (id) {
        return axios.delete(endpoints.getConversations + "/" + id + "/leave");
    },
    getHistory: function (id, page, perPage) {
        if (perPage === void 0) { perPage = 5; }
        return axios.get(endpoints.getConversations + "/" + id + "/history?page=" + page + "&per_page=" + perPage);
    },
    getUnreadCount: function () { return axios.get(endpoints.getConversations + "/unread_count"); },
    blockUser: function (id) {
        return axios.put(endpoints.getConversations + "/" + id + "/block");
    },
    unblockUser: function (id) {
        return axios.put(endpoints.getConversations + "/" + id + "/unblock");
    },
};
