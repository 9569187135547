import { notification } from 'antd';
import { PageHeader } from 'components';
import { ArrowBack } from 'icons/mono';
import { rem } from 'polished';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from 'styles';
import { useAction, useSelector } from 'utils/hooks';
import { paths } from '../RoutesProvider/routes';
import { AcceptTerms } from './AcceptTerms';
import { clearError, clearSuccess, editMyProfileFields, editMyProfilePassword, getEditMyProfile, photoDelete, photoUpload, setActiveSection, } from './actions';
import { sections } from './constants';
import { EditLocation } from './EditLocation';
import { EditName } from './EditName';
import { EditOptions } from './EditOptions';
import { EditPassword } from './EditPassword';
import { EditPhoto } from './EditPhoto';
import { EditProfileMessage } from './EditProfileMessage';
import { EditProfileType } from './EditProfileType';
import { EditTelephone } from './EditTelephone';
import messages from './messages';
import makeSelectEditMyProfile, { makeSelectActiveSection, makeSelectErrorFeedback, makeSelectLoading, makeSelectSuccessFeedback, } from './selectors';
import { SuccessFeedbackType } from './types';
export var EditProfile = function () {
    var _a;
    var getEditMyProfileCallback = useAction(getEditMyProfile);
    var editMyProfileFieldsCallback = useAction(editMyProfileFields);
    var editMyProfilePasswordCallback = useAction(editMyProfilePassword);
    var photoUploadCallback = useAction(photoUpload);
    var photoDeleteCallback = useAction(photoDelete);
    var clearErrorCallback = useAction(clearError);
    var clearSuccessCallback = useAction(clearSuccess);
    var setSelectedSectionCallback = useAction(setActiveSection);
    var profile = useSelector(makeSelectEditMyProfile());
    var isLoading = useSelector(makeSelectLoading());
    var successFeedback = useSelector(makeSelectSuccessFeedback());
    var errorFeedback = useSelector(makeSelectErrorFeedback());
    var activeSection = useSelector(makeSelectActiveSection());
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var notificationSuccessTypeMessage = (_a = {},
        _a[SuccessFeedbackType.Password] = formatMessage(messages.passwordUpdateSuccess),
        _a[SuccessFeedbackType.Photo] = formatMessage(messages.photoUploadSuccess),
        _a[SuccessFeedbackType.PhotoDelete] = formatMessage(messages.photoDeleteSuccess),
        _a[SuccessFeedbackType.DefaultAvatar] = formatMessage(messages.defaultAvatarUpdatedSuccess),
        _a[SuccessFeedbackType.Name] = formatMessage(messages.nameUpdateSuccess),
        _a[SuccessFeedbackType.ProfileMessage] = formatMessage(messages.profileMessageUpdateSuccess),
        _a[SuccessFeedbackType.ProfileType] = formatMessage(messages.profileTypeUpdateSuccess),
        _a[SuccessFeedbackType.Telephone] = formatMessage(messages.telephoneUpdateSuccess),
        _a[SuccessFeedbackType.Location] = formatMessage(messages.locationUpdateSuccess),
        _a[SuccessFeedbackType.Terms] = formatMessage(messages.termsUpdateSuccess),
        _a);
    useEffect(function () {
        getEditMyProfileCallback();
    }, [getEditMyProfileCallback]);
    useEffect(function () {
        if (successFeedback) {
            notification.success({
                message: formatMessage(messages.success),
                description: notificationSuccessTypeMessage[successFeedback.type],
                onClose: clearSuccessCallback,
            });
        }
    }, [successFeedback]);
    useEffect(function () {
        if (errorFeedback) {
            notification.error({
                message: formatMessage(messages.error),
                description: errorFeedback.message,
                onClose: clearErrorCallback,
            });
        }
    }, [errorFeedback]);
    var handleBack = function () {
        setSelectedSectionCallback(sections.options);
    };
    var handleSubmitFields = function (form, editingField) {
        editMyProfileFieldsCallback({ form: form, editingField: editingField });
    };
    var handleSubmitPassword = function (passwordForm) {
        editMyProfilePasswordCallback(passwordForm);
    };
    var handleCancelPhotoUpload = function () {
        setSelectedSectionCallback(sections.options);
        getEditMyProfileCallback();
    };
    var backToProfile = function () {
        history.push(paths.profilePage, { from: location.pathname });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(EditPhoto, { visible: activeSection === sections.photo, onCancel: handleCancelPhotoUpload, onUpdate: photoUploadCallback, onDelete: photoDeleteCallback, isLoading: isLoading, userAvatar: profile === null || profile === void 0 ? void 0 : profile.avatar }),
        (activeSection === sections.options ||
            activeSection === sections.photo) && (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: formatMessage(messages.pageTitle), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: backToProfile, sticky: true }),
            React.createElement(EditOptions, { profile: profile, onSelectSection: setSelectedSectionCallback, userAvatar: profile === null || profile === void 0 ? void 0 : profile.avatar }),
            React.createElement(AcceptTerms, { onSubmit: handleSubmitFields, profile: profile }))),
        activeSection === sections.name && (React.createElement(EditName, { onSubmit: handleSubmitFields, onBack: handleBack, profile: profile, isLoading: isLoading })),
        activeSection === sections.profileType && (React.createElement(EditProfileType, { onSubmit: handleSubmitFields, onBack: handleBack, profile: profile, isLoading: isLoading })),
        activeSection === sections.profileMessage && (React.createElement(EditProfileMessage, { onSubmit: handleSubmitFields, onBack: handleBack, profile: profile, isLoading: isLoading })),
        activeSection === sections.telephone && (React.createElement(EditTelephone, { onSubmit: handleSubmitFields, onBack: handleBack, profile: profile, isLoading: isLoading })),
        activeSection === sections.password && (React.createElement(EditPassword, { onSubmit: handleSubmitPassword, onBack: handleBack, isLoading: isLoading })),
        activeSection === sections.location && (React.createElement(EditLocation, { onSubmit: handleSubmitFields, onBack: function () {
                handleBack();
            }, profile: profile, isLoading: isLoading }))));
};
