import React, { memo } from 'react';
export var Museum = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M28.038 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#c80000", style: { fill: '#c80000' }, d: "M7.347 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.758 4.759-12.474 0-17.232s-12.474-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M9.021 7.028v11.851h2.475c0.37-2.709 1.806-2.705 2.188-4.31 0.219-0.92 0.013-1.367-0.114-2.278-0.111-0.785-0.016-1.869 0.406-2.525 0.174-0.272 0.319-0.53 0.525-0.786 0.302-0.376 0.862-0.584 1.305-0.606 0.65-0.032 1.347 0.186 1.826 0.798 0.769 0.983 0.667 2.753 0.924 3.958 0.102 0.475 0.195 1.017 0.483 1.414 0.302 0.414 0.712 0.866 1.178 1.171 0.876 0.571 0.958 1.92 1.263 3.068h1.456v-11.755h-13.916z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M14.088 11.743c0.075 0.655 0.533 1.822 1.343 1.789 0.039 0.317 0.113 0.69-0.088 0.904-0.163 0.173-0.446 0.253-0.621 0.439-0.31 0.327-0.911 1.178-1.037 1.615 0.455 0.311 0.784 0.396 1.34 0.538 0.374 0.096 0.688 0.136 1.078 0.136 0.433 0 0.496-0.116 0.84-0.372 0.201-0.151 0.564-0.21 0.707-0.412 0.159-0.22 0.058-0.599 0.041-0.864-0.038-0.632-0.079-1.26-0.447-1.776-0.194-0.273-0.26-0.323-0.26-0.684 0-0.315-0.012-0.631 0-0.944 0.021-0.498 0.148-1.145 0.034-1.614-0.213-0.868-1.063-1.522-1.919-1.348-0.953 0.193-1.093 1.862-1.010 2.593z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M13.112 20.824c0.366-0.025 0.787-0.078 1.146 0 0.447 0.094 1.29 0.445 1.672 0.74 0.565 0.438 0.886 1.060 0.814 1.747-0.684 0.108-1.335 0.262-2.008-0.038-0.512-0.228-1.009 0.316-1.326 0.845-0.385-0.082-0.975-0.642-0.938-1.073 0.044-0.523 0.677-0.713 1.050-0.842-0.198-0.123-0.384-0.199-0.675-0.207-0.073-0.22-0.127-0.385-0.071-0.625 0.067-0.28 0.193-0.344 0.336-0.547z" })));
});
