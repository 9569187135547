import { RESEND_EMAIL_ACTIVATION_FAILURE, RESEND_EMAIL_ACTIVATION_REQUEST, RESEND_EMAIL_ACTIVATION_SUCCESS, } from './constants';
export var resendEmailActivation = function (payload, actions) { return ({
    type: RESEND_EMAIL_ACTIVATION_REQUEST,
    payload: payload,
    actions: actions,
}); };
export var resendEmailActivationSuccess = function () { return ({
    type: RESEND_EMAIL_ACTIVATION_SUCCESS,
}); };
export var resendEmailActivationError = function (error) { return ({
    type: RESEND_EMAIL_ACTIVATION_FAILURE,
    payload: error,
}); };
