var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Question = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("g", { id: "question", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "Icon", transform: "translate(4.000000, 4.000000)", fill: color, fillRule: "nonzero" },
                React.createElement("path", { d: "M16,0.444444444 C7.40972222,0.444444444 0.444444444,7.40972222 0.444444444,16 C0.444444444,24.5902778 7.40972222,31.5555556 16,31.5555556 C24.5902778,31.5555556 31.5555556,24.5902778 31.5555556,16 C31.5555556,7.40972222 24.5902778,0.444444444 16,0.444444444 Z M16,25.0277778 C15.2326389,25.0277778 14.6111111,24.40625 14.6111111,23.6388889 C14.6111111,22.8715278 15.2326389,22.25 16,22.25 C16.7673611,22.25 17.3888889,22.8715278 17.3888889,23.6388889 C17.3888889,24.40625 16.7673611,25.0277778 16,25.0277778 Z M18.1840278,17.40625 C17.5404991,17.6547553 17.114785,18.2719718 17.1111111,18.9618056 L17.1111111,19.75 C17.1111111,19.9027778 16.9861111,20.0277778 16.8333333,20.0277778 L15.1666667,20.0277778 C15.0138889,20.0277778 14.8888889,19.9027778 14.8888889,19.75 L14.8888889,19.0034722 C14.8888889,18.2013889 15.1215278,17.4097222 15.5798611,16.75 C16.0277778,16.1041667 16.6527778,15.6111111 17.3888889,15.3298611 C18.5694444,14.875 19.3333333,13.8854167 19.3333333,12.8055556 C19.3333333,11.2743056 17.8368056,10.0277778 16,10.0277778 C14.1631944,10.0277778 12.6666667,11.2743056 12.6666667,12.8055556 L12.6666667,13.0694444 C12.6666667,13.2222222 12.5416667,13.3472222 12.3888889,13.3472222 L10.7222222,13.3472222 C10.5694444,13.3472222 10.4444444,13.2222222 10.4444444,13.0694444 L10.4444444,12.8055556 C10.4444444,11.4409722 11.0416667,10.1666667 12.125,9.21875 C13.1666667,8.30555556 14.5416667,7.80555556 16,7.80555556 C17.4583333,7.80555556 18.8333333,8.30902778 19.875,9.21875 C20.9583333,10.1666667 21.5555556,11.4409722 21.5555556,12.8055556 C21.5555556,14.8125 20.2326389,16.6180556 18.1840278,17.40625 Z", id: "Icon-Shape" })))));
});
