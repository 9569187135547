var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Input } from 'components';
import { makeSelectUser } from 'containers/Auth/selectors';
import { getConversation, getHistory } from 'containers/Chat/actions';
import { makeSelectorChatConnection, makeSelectorConversation, makeSelectorHistory, makeSelectorMessages, } from 'containers/Chat/selectors';
import { useAction, useSelector } from 'hooks';
import { Send } from 'icons/mono';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import { rem } from 'polished';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { colors } from 'styles';
import { Header } from './Header';
import { InfoMessage } from './InfoMessage';
import { MessageBox } from './MessageBox';
import { ChatBody, ChatConversationWrapper, ChatFooter, LoadMore, LoadMoreWrapper, } from './styles';
export var ChatConversation = function () {
    var conversationDetails = useSelector(makeSelectorConversation());
    var messages = useSelector(makeSelectorMessages());
    var _a = __read(useState(768), 2), windowHeight = _a[0], setWindowHeight = _a[1];
    var _b = __read(useState(2), 2), loadMultiplier = _b[0], setLoadMultiplier = _b[1];
    var _c = __read(useState(), 2), inputValue = _c[0], setInputValue = _c[1];
    var _d = __read(useState(false), 2), scrollChanged = _d[0], setScrollChanged = _d[1];
    var getHistoryCallback = useAction(getHistory);
    var getConversationCallback = useAction(getConversation);
    var location = useLocation();
    var historyChat = useSelector(makeSelectorHistory());
    var chatConnection = useSelector(makeSelectorChatConnection());
    var user = useSelector(makeSelectUser());
    var handleWindowResize = function () {
        setWindowHeight(window.innerHeight);
    };
    useEffect(function () {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
            window.addEventListener('resize', handleWindowResize);
        }
    }, []);
    var getConversationParam = function () {
        var params = qs.parse(location.search);
        if (!params.page || Number.isNaN(Number(params.page))) {
            return null;
        }
        return Number(params.conversation);
    };
    useEffect(function () {
        if (getConversationParam()) {
            getConversationCallback(getConversationParam());
            getHistoryCallback({ id: getConversationParam(), page: 1, perPage: 5 });
            setLoadMultiplier(2);
            setScrollChanged(false);
        }
    }, [getConversationParam()]);
    var loadMore = function () {
        getHistoryCallback({
            id: getConversationParam(),
            page: 1,
            perPage: 5 * loadMultiplier,
        });
        setLoadMultiplier(loadMultiplier + 1);
    };
    var sendMessage = function (message) {
        var _a;
        if (chatConnection) {
            var post = {
                conversation_id: getConversationParam(),
                messaging_key: user === null || user === void 0 ? void 0 : user.messagingKey,
                body: message,
                pseudo: user === null || user === void 0 ? void 0 : user.pseudo,
                user_id: user === null || user === void 0 ? void 0 : user.id,
            };
            // eslint-disable-next-line no-unused-expressions
            (_a = chatConnection === null || chatConnection === void 0 ? void 0 : chatConnection.session) === null || _a === void 0 ? void 0 : _a.publish("messaging.conversation.fromui." + (user === null || user === void 0 ? void 0 : user.messagingKey), [post]);
        }
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        if (inputValue) {
            sendMessage(inputValue);
            setInputValue('');
        }
    };
    useEffect(function () {
        if (loadMultiplier === 2) {
            var timer_1 = setInterval(function () {
                var elements = document.getElementsByClassName('chat-body-wrapper');
                if (elements[0]) {
                    var chatBody = elements[0].firstElementChild;
                    if (chatBody && !scrollChanged) {
                        chatBody.scrollTop = chatBody.scrollHeight;
                        chatBody.addEventListener('scroll', function () {
                            setScrollChanged(true);
                        });
                    }
                    clearInterval(timer_1);
                }
            }, 100);
        }
    }, [messages]);
    return (React.createElement(ChatConversationWrapper, { isBlocked: conversationDetails === null || conversationDetails === void 0 ? void 0 : conversationDetails.isBlocking, height: windowHeight + "px" }, conversationDetails && (React.createElement(React.Fragment, null,
        React.createElement(Header, { data: conversationDetails }),
        React.createElement("div", { className: "chat-body-wrapper" },
            React.createElement(ChatBody, { height: windowHeight + "px" },
                historyChat.total / loadMultiplier > 5 && (React.createElement(LoadMoreWrapper, null,
                    React.createElement(LoadMore, { onClick: loadMore }, "Previous messages"))),
                messages &&
                    map(reverse(cloneDeep(messages)), function (message, index) { return (React.createElement("div", { key: index }, message.isSystem ? (React.createElement(InfoMessage, { data: message })) : (React.createElement(MessageBox, { data: message })))); }))),
        React.createElement(ChatFooter, null,
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Input, { placeholder: "Type something", disabled: conversationDetails.isBlocking, onChange: function (e) {
                        return setInputValue(e.target.value);
                    }, value: inputValue, addonAfter: React.createElement(Button, { type: "submit", icon: React.createElement(Send, { size: rem(18), color: colors.space.tint600 }), variant: "link", disabled: conversationDetails.isBlocking }) })))))));
};
