var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var modifier = {
    isBlocked: function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n\n    & > div,\n    & > footer {\n      opacity: 0.2;\n      pointer-events: none;\n    }\n\n    &::before {\n      content: 'Blocked user. To send or to receive messages from this user, unblock him.';\n      font-weight: ", ";\n      text-align: center;\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: calc(100% - ", ");\n      margin-top: ", ";\n      padding: ", ";\n      z-index: 1;\n    }\n  "], ["\n    position: relative;\n\n    & > div,\n    & > footer {\n      opacity: 0.2;\n      pointer-events: none;\n    }\n\n    &::before {\n      content: 'Blocked user. To send or to receive messages from this user, unblock him.';\n      font-weight: ", ";\n      text-align: center;\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: calc(100% - ", ");\n      margin-top: ", ";\n      padding: ", ";\n      z-index: 1;\n    }\n  "])), fonts.weight.semibold, rem(40), rem(160), rem(20)); },
};
export var ChatConversationWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), function (_a) {
    var height = _a.height, isBlocked = _a.isBlocked;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: grid;\n    grid-template-rows: auto 1fr auto;\n    overflow: hidden;\n    position: relative;\n\n    ", "\n\n    ", ";\n  "], ["\n    display: grid;\n    grid-template-rows: auto 1fr auto;\n    overflow: hidden;\n    position: relative;\n\n    ",
        "\n\n    ", ";\n  "])), media.lessThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      height: calc(100vh - ", ");\n      height: calc(", " - 200px);\n    "], ["\n      height: calc(100vh - ", ");\n      height: calc(", " - 200px);\n    "])), rem(200), height), isBlocked && modifier.isBlocked());
});
export var ChatBody = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), function (_a) {
    var height = _a.height;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    padding: ", ";\n    display: flex;\n    flex-direction: column;\n    overflow: hidden auto;\n    height: calc(100vh - ", ");\n\n    ", "\n  "], ["\n    padding: ", ";\n    display: flex;\n    flex-direction: column;\n    overflow: hidden auto;\n    height: calc(100vh - ", ");\n\n    ",
        "\n  "])), rem(20), rem(290), media.lessThan('medium')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      height: calc(100vh - ", ");\n      height: calc(", " - 360px);\n    "], ["\n      height: calc(100vh - ", ");\n      height: calc(", " - 360px);\n    "])), rem(360), height));
});
export var ChatFooter = styled.footer(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  padding: 0 ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  form {\n    width: 100%;\n  }\n\n  .ant-input {\n    border-right: 0;\n    border-radius: ", " 0 0 ", " !important;\n    background: ", ";\n  }\n\n  .ant-input-group-addon {\n    border-radius: 0 ", " ", " 0 !important;\n    background: ", ";\n\n    button {\n      border: 0;\n      background: transparent;\n      border-radius: 0 ", " ", " 0 !important;\n\n      &:focus {\n        box-shadow: none;\n        padding: 0;\n      }\n    }\n  }\n"], ["\n  border-top: ", " solid ", ";\n  padding: 0 ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  form {\n    width: 100%;\n  }\n\n  .ant-input {\n    border-right: 0;\n    border-radius: ", " 0 0 ", " !important;\n    background: ", ";\n  }\n\n  .ant-input-group-addon {\n    border-radius: 0 ", " ", " 0 !important;\n    background: ", ";\n\n    button {\n      border: 0;\n      background: transparent;\n      border-radius: 0 ", " ", " 0 !important;\n\n      &:focus {\n        box-shadow: none;\n        padding: 0;\n      }\n    }\n  }\n"])), rem(1), border, rem(16), rem(80), rem(20), rem(20), colors.space.tint100, rem(20), rem(20), colors.space.tint100, rem(20), rem(20));
export var LoadMoreWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  text-align: center;\n  padding-bottom: ", ";\n"], ["\n  text-align: center;\n  padding-bottom: ", ";\n"])), rem(10));
export var LoadMore = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  cursor: pointer;\n  background: transparent;\n  border: none;\n  outline: none;\n"], ["\n  cursor: pointer;\n  background: transparent;\n  border: none;\n  outline: none;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
