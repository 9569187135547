var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
export var FloatButtonContainer = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  bottom: calc(", " + env(safe-area-inset-bottom, 0));\n  position: fixed;\n  right: ", ";\n  z-index: 20;\n\n  align-items: center;\n  display: flex;\n\n  ", ";\n"], ["\n  bottom: calc(", " + env(safe-area-inset-bottom, 0));\n  position: fixed;\n  right: ", ";\n  z-index: 20;\n\n  align-items: center;\n  display: flex;\n\n  ",
    ";\n"])), rem(100), rem(20), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    bottom: ", ";\n  "], ["\n    bottom: ", ";\n  "])), rem(20)));
var templateObject_1, templateObject_2;
