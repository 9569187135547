import { markerIcons } from './marker/icons';
import { roundIcons } from './round/icons';
import { squareIcons } from './square/icons';
export var mapIcons = function (id, type, props) {
    if (type === 'marker') {
        return markerIcons(props)[id];
    }
    if (type === 'square') {
        return squareIcons(props)[id];
    }
    if (type === 'round') {
        return roundIcons(props)[id];
    }
};
