import * as Yup from 'yup';

const changePasswordSchema = (formatMessage) =>
  Yup.object().shape({
    password: Yup.string()
      .min(
        8,
        formatMessage({
          id: 'containers.changePasswordPage.newPasswordStrengthRegex',
        }),
      )
      .required(
        formatMessage({
          id: 'containers.changePasswordPage.newPasswordRequired',
        }),
      )
      .matches(
        /^(?=.*)(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/,
        formatMessage({
          id: 'containers.changePasswordPage.newPasswordStrengthRegex',
        }),
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        formatMessage({
          id: 'containers.changePasswordPage.confirmPasswordValidation',
        }),
      )
      .required(
        formatMessage({
          id: 'containers.changePasswordPage.confirmPasswordRequired',
        }),
      ),
  });

export const defaultProps = {
  email: '',
};

export default changePasswordSchema;
