var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { notification } from 'antd';
import * as api from 'api';
import { Spin } from 'components';
import { Button } from 'components/Button';
import { Upload } from 'components/Upload';
import { useGeolocation } from 'hooks';
import { CamSolid } from 'icons/mono';
import { rem } from 'polished';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
export var CriterionPhoto = function (_a) {
    var criterionId = _a.criterionId;
    var geolocation = useGeolocation();
    var placeId = useParams().placeId;
    var formatMessage = useIntl().formatMessage;
    var _b = __read(useState(false), 2), uploading = _b[0], setUploading = _b[1];
    var formatFeedback = function (message) {
        if (!message)
            return formatMessage({ id: 'containers.editPlace.successfulFeedback' });
        return (React.createElement("div", null,
            React.createElement("div", null, formatMessage({ id: 'containers.editPlace.successfulFeedback' })),
            React.createElement("div", null,
                React.createElement("p", null, formatMessage({ id: 'containers.editPlace.pointsFeedback' }, { points: message.points })))));
    };
    var upload = function (_a) {
        var file = _a.file;
        return __awaiter(void 0, void 0, void 0, function () {
            var formData, latitude, longitude, responseGeolocalize, response, _b, response;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        setUploading(true);
                        formData = new FormData();
                        formData.append('file', file);
                        formData.append('criterion_id', criterionId);
                        if (!(geolocation === null || geolocation === void 0 ? void 0 : geolocation.latitude)) return [3 /*break*/, 1];
                        latitude = geolocation.latitude, longitude = geolocation.longitude;
                        formData.append('latitude', latitude.toString());
                        formData.append('longitude', longitude.toString());
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, api.services.geolocalize()];
                    case 2:
                        responseGeolocalize = _d.sent();
                        if (responseGeolocalize.status === 200) {
                            formData.append('latitude', responseGeolocalize.data.latitude.toString());
                            formData.append('longitude', responseGeolocalize.data.longitude.toString());
                        }
                        _d.label = 3;
                    case 3:
                        _d.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, api.places.uploadCriterionPhoto(formData, placeId)];
                    case 4:
                        response = _d.sent();
                        notification.success({
                            message: formatMessage({
                                id: 'containers.placePhotos.successTitle',
                            }),
                            description: ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.contribution) ? formatFeedback(response.data.contribution)
                                : formatMessage({ id: 'containers.placePhotos.photoSuccess' }),
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        _b = _d.sent();
                        response = _b.response;
                        notification.error({
                            message: formatMessage({
                                id: 'containers.placePhotos.validationTitle',
                            }),
                            description: formatMessage({
                                id: 'containers.placePhotos.validationDescription',
                            }),
                        });
                        return [3 /*break*/, 6];
                    case 6:
                        setUploading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    return (React.createElement(Spin, { spinning: uploading },
        React.createElement(Upload, { showUploadList: false, customRequest: upload, accept: "image/*" },
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { shape: "pill", size: "small", icon: React.createElement(CamSolid, { size: rem(16) }) }, formatMessage({ id: 'containers.editPlace.addMorePhotos' }))))));
};
var ButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
var templateObject_1;
