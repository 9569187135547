var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var ArrowBack = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.8665 17.7135H11.2835L20.4423 7.90261C21.276 7.00941 21.276 5.56131 20.4423 4.6699C19.6086 3.7767 18.257 3.7767 17.425 4.6699L4.62401 18.383C3.792 19.2762 3.792 20.7243 4.62401 21.6157L17.425 35.3305C17.841 35.7763 18.3873 36 18.9337 36C19.48 36 20.0263 35.7763 20.4423 35.3305C21.276 34.4373 21.276 32.9892 20.4423 32.0978L11.2835 22.2851H33.8665C35.046 22.2851 36 21.2613 36 19.9993C36 18.7374 35.046 17.7135 33.8665 17.7135Z", fill: color })));
});
