var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { SearchBarDesktop, SearchBarMobile } from 'components';
import { makeSelectSubFooterLinksSearchByTypeOfPlace } from 'components/SubFooter/selectors';
import { useSelector } from 'hooks';
import React, { memo, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { NavTypePlace } from './NavTypePlace';
import { HeroContainer, Title } from './styles';
export var Header = memo(function (_a) {
    var geolocation = _a.geolocation;
    var formatMessage = useIntl().formatMessage;
    var searchByTypeOfPlace = useSelector(makeSelectSubFooterLinksSearchByTypeOfPlace());
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var _b = __read(useState(768), 2), windowHeight = _b[0], setWindowHeight = _b[1];
    var handleWindowResize = function () {
        setWindowHeight(window.innerHeight);
    };
    useEffect(function () {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
            window.addEventListener('resize', handleWindowResize);
        }
    }, []);
    return (React.createElement(HeroContainer, { id: "main", height: windowHeight + "px" },
        React.createElement(Title, null, formatMessage({ id: 'containers.homeHeader.title' })),
        isMobile || isTabletOrMobile ? (React.createElement(SearchBarMobile, { placeholder: formatMessage({
                id: 'containers.homeHeader.placeholder',
            }) })) : (React.createElement(React.Fragment, null,
            React.createElement(SearchBarDesktop, { placeholder: formatMessage({
                    id: 'containers.homeHeader.placeholder',
                }) }),
            React.createElement(NavTypePlace, { items: searchByTypeOfPlace === null || searchByTypeOfPlace === void 0 ? void 0 : searchByTypeOfPlace.items, geolocation: geolocation })))));
});
