var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Input } from 'components';
import { useField } from 'formik';
import React, { memo } from 'react';
import { FieldTemplate } from './FieldTemplate';
var Field = memo(function (props) {
    var _a = __read(useField(props), 2), field = _a[0], meta = _a[1];
    return (React.createElement(FieldTemplate, { error: meta.error, touched: meta.touched, label: props.label, required: props.required },
        React.createElement(Input, { type: "text", placeholder: props.placeholder || props.label, name: field.name, value: field.value, onChange: field.onChange, onBlur: props.onBlur || field.onBlur, onFocus: props.onFocus, disabled: props.disabled, autoComplete: "on", autoCapitalize: "off" })));
});
export { Field, FieldTemplate };
