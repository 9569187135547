import produce from 'immer';
import { PROFILE_MY_PROFILE_FAILURE, PROFILE_MY_PROFILE_REQUEST, PROFILE_MY_PROFILE_SUCCESS, } from '../constants';
export var myProfileInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    requested: false,
    contributionStats: [],
    pointsSerie: [],
    quickBadges: [],
    rocketThreshold: 0,
    totalFavorites: 0,
    totalFollowed: 0,
    totalFollowers: 0,
    totalPlaces: 0,
    totalPoints: 0,
    monthlyPoints: 0,
    weeklyPoints: 0,
};
export var myProfileReducer = produce(function (draft, action) {
    switch (action.type) {
        case PROFILE_MY_PROFILE_REQUEST:
            draft.loading = true;
            draft.requested = true;
            break;
        case PROFILE_MY_PROFILE_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case PROFILE_MY_PROFILE_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.contributionStats = action.payload.contributionStats;
            draft.pointsSerie = action.payload.pointsSerie;
            draft.quickBadges = action.payload.quickBadges;
            draft.rocketThreshold = action.payload.rocketThreshold;
            draft.totalFavorites = action.payload.totalFavorites;
            draft.totalFollowed = action.payload.totalFollowed;
            draft.totalFollowers = action.payload.totalFollowers;
            draft.totalPlaces = action.payload.totalPlaces;
            draft.totalPoints = action.payload.totalPoints;
            draft.monthlyPoints = action.payload.monthlyPoints;
            draft.weeklyPoints = action.payload.weeklyPoints;
            break;
        default:
    }
}, myProfileInitialState);
