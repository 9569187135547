var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { initApp } from 'containers/App/saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { editMyProfileFieldsSuccess, editMyProfilePasswordError, editMyProfilePasswordSuccess, getEditMyProfileError, getEditMyProfileSuccess, photoDeleteError, photoDeleteSuccess, photoUploadError, photoUploadSuccess, setActiveSection, } from './actions';
import { EDIT_MY_PROFILE_FIELDS, EDIT_MY_PROFILE_PASSWORD, EDIT_MY_PROFILE_REQUEST, PHOTO_DELETE, PHOTO_UPLOAD, } from './constants';
export function getEditMyProfile() {
    var response, _a, response;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.myProfile.getEditMyProfile)];
            case 1:
                response = _c.sent();
                return [4 /*yield*/, put(getEditMyProfileSuccess(response.data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                _a = _c.sent();
                response = _a.response;
                return [4 /*yield*/, put(getEditMyProfileError((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.message))];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function editMyProfileFields(_a) {
    var form, editingField, _b, response;
    var payload = _a.payload;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 6, , 8]);
                form = payload.form, editingField = payload.editingField;
                return [4 /*yield*/, call(api.myProfile.updateMyProfileFields, form)];
            case 1:
                _d.sent();
                return [4 /*yield*/, put(setActiveSection())];
            case 2:
                _d.sent();
                return [4 /*yield*/, put(editMyProfileFieldsSuccess(editingField))];
            case 3:
                _d.sent();
                return [4 /*yield*/, call(getEditMyProfile)];
            case 4:
                _d.sent();
                return [4 /*yield*/, call(initApp)];
            case 5:
                _d.sent();
                return [3 /*break*/, 8];
            case 6:
                _b = _d.sent();
                response = _b.response;
                return [4 /*yield*/, put(getEditMyProfileError((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message))];
            case 7:
                _d.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function editMyProfilePassword(_a) {
    var response, _b, response;
    var payload = _a.payload;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call(api.myProfile.updateMyProfilePassword, payload)];
            case 1:
                response = _d.sent();
                return [4 /*yield*/, put(setActiveSection())];
            case 2:
                _d.sent();
                return [4 /*yield*/, put(editMyProfilePasswordSuccess(response.data))];
            case 3:
                _d.sent();
                return [3 /*break*/, 6];
            case 4:
                _b = _d.sent();
                response = _b.response;
                return [4 /*yield*/, put(editMyProfilePasswordError((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message))];
            case 5:
                _d.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function photoUpload(_a) {
    var _b, response;
    var _c = _a.payload, file = _c.file, type = _c.type;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 6, , 8]);
                return [4 /*yield*/, call(api.myProfile.photoUpload, file)];
            case 1:
                _e.sent();
                return [4 /*yield*/, put(photoUploadSuccess(type))];
            case 2:
                _e.sent();
                return [4 /*yield*/, put(setActiveSection())];
            case 3:
                _e.sent();
                return [4 /*yield*/, call(getEditMyProfile)];
            case 4:
                _e.sent();
                return [4 /*yield*/, call(initApp)];
            case 5:
                _e.sent();
                return [3 /*break*/, 8];
            case 6:
                _b = _e.sent();
                response = _b.response;
                return [4 /*yield*/, put(photoUploadError((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.message))];
            case 7:
                _e.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function photoDelete(_a) {
    var response, _b, response;
    var payload = _a.payload;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(api.myProfile.photoDelete, payload)];
            case 1:
                response = _d.sent();
                return [4 /*yield*/, put(photoDeleteSuccess(response.status))];
            case 2:
                _d.sent();
                return [4 /*yield*/, call(getEditMyProfile)];
            case 3:
                _d.sent();
                return [4 /*yield*/, call(initApp)];
            case 4:
                _d.sent();
                return [3 /*break*/, 7];
            case 5:
                _b = _d.sent();
                response = _b.response;
                return [4 /*yield*/, put(photoDeleteError((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message))];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export default function myProfileSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(EDIT_MY_PROFILE_REQUEST, getEditMyProfile)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(EDIT_MY_PROFILE_FIELDS, editMyProfileFields)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(EDIT_MY_PROFILE_PASSWORD, editMyProfilePassword)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(PHOTO_UPLOAD, photoUpload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(PHOTO_DELETE, photoDelete)];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
