import { defineMessages } from 'react-intl';
export var scope = 'containers.resendEmailActivation';
var messages = defineMessages({
    header: {
        id: scope + ".header",
    },
    submitButton: {
        id: scope + ".submitButton",
    },
    emailformat: {
        id: scope + ".emailformat",
    },
    emailRequired: {
        id: scope + ".emailRequired",
    },
});
export default messages;
