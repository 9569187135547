import { CLEAR_ERROR, CLEAR_SUCCESS, EDIT_MY_PROFILE_ERROR, EDIT_MY_PROFILE_FIELDS, EDIT_MY_PROFILE_FIELDS_ERROR, EDIT_MY_PROFILE_FIELDS_SUCCESS, EDIT_MY_PROFILE_PASSWORD, EDIT_MY_PROFILE_PASSWORD_ERROR, EDIT_MY_PROFILE_PASSWORD_SUCCESS, EDIT_MY_PROFILE_REQUEST, EDIT_MY_PROFILE_SUCCESS, PHOTO_DELETE, PHOTO_DELETE_ERROR, PHOTO_DELETE_SUCCESS, PHOTO_UPLOAD, PHOTO_UPLOAD_ERROR, PHOTO_UPLOAD_SUCCESS, sections, SET_ACTIVE_AVATAR, SET_ACTIVE_SECTION, } from './constants';
export var getEditMyProfile = function () { return ({
    type: EDIT_MY_PROFILE_REQUEST,
}); };
export var getEditMyProfileSuccess = function (payload) { return ({
    type: EDIT_MY_PROFILE_SUCCESS,
    payload: payload,
}); };
export var getEditMyProfileError = function (payload) { return ({
    type: EDIT_MY_PROFILE_ERROR,
    payload: payload,
}); };
export var editMyProfileFields = function (payload) { return ({
    type: EDIT_MY_PROFILE_FIELDS,
    payload: payload,
}); };
export var editMyProfileFieldsSuccess = function (payload) { return ({
    type: EDIT_MY_PROFILE_FIELDS_SUCCESS,
    payload: payload,
}); };
export var editMyProfileFieldsError = function (payload) { return ({
    type: EDIT_MY_PROFILE_FIELDS_ERROR,
    payload: payload,
}); };
export var editMyProfilePassword = function (payload) { return ({
    type: EDIT_MY_PROFILE_PASSWORD,
    payload: payload,
}); };
export var editMyProfilePasswordSuccess = function (payload) { return ({
    type: EDIT_MY_PROFILE_PASSWORD_SUCCESS,
    payload: payload,
}); };
export var editMyProfilePasswordError = function (payload) { return ({
    type: EDIT_MY_PROFILE_PASSWORD_ERROR,
    payload: payload,
}); };
export var photoUpload = function (payload) { return ({
    type: PHOTO_UPLOAD,
    payload: payload,
}); };
export var photoUploadError = function (payload) { return ({
    type: PHOTO_UPLOAD_ERROR,
    payload: payload,
}); };
export var photoUploadSuccess = function (payload) { return ({
    type: PHOTO_UPLOAD_SUCCESS,
    payload: payload,
}); };
export var photoDelete = function (payload) { return ({
    type: PHOTO_DELETE,
    payload: payload,
}); };
export var photoDeleteError = function (payload) { return ({
    type: PHOTO_DELETE_ERROR,
    payload: payload,
}); };
export var photoDeleteSuccess = function (payload) { return ({
    type: PHOTO_DELETE_SUCCESS,
    payload: payload,
}); };
export var clearSuccess = function () { return ({
    type: CLEAR_SUCCESS,
}); };
export var clearError = function () { return ({
    type: CLEAR_ERROR,
}); };
export var setActiveSection = function (payload) {
    if (payload === void 0) { payload = sections.options; }
    return ({
        type: SET_ACTIVE_SECTION,
        payload: payload,
    });
};
export var setActiveAvatar = function (payload) { return ({
    type: SET_ACTIVE_AVATAR,
    payload: payload,
}); };
