import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile';
var messages = defineMessages({
    commonFieldsUpdateSuccess: { id: scope + ".commonFieldsUpdateSuccess" },
    passwordUpdateSuccess: { id: scope + ".passwordUpdateSuccess" },
    photoUploadSuccess: { id: scope + ".photoUploadSuccess" },
    photoDeleteSuccess: { id: scope + ".photoDeleteSuccess" },
    defaultAvatarUpdatedSuccess: { id: scope + ".defaultAvatarUpdatedSuccess" },
    nameUpdateSuccess: { id: scope + ".nameUpdateSuccess" },
    profileMessageUpdateSuccess: { id: scope + ".profileMessageUpdateSuccess" },
    profileTypeUpdateSuccess: { id: scope + ".profileTypeUpdateSuccess" },
    telephoneUpdateSuccess: { id: scope + ".telephoneUpdateSuccess" },
    locationUpdateSuccess: { id: scope + ".locationUpdateSuccess" },
    termsUpdateSuccess: { id: scope + ".termsUpdateSuccess" },
    error: { id: scope + ".error" },
    success: { id: scope + ".success" },
    pageTitle: { id: scope + ".pageTitle" },
});
export default messages;
