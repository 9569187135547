var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable no-unused-expressions */
import { placeholderPlace } from 'assets/images';
import { Button, Rate, Spin } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { makeSelectUser } from 'containers/Auth/selectors';
import { makeSelectorDeletePhotoLoading } from 'containers/PlacePhotos/selectors';
import * as categoryId from 'icons/category/id';
import { mapIcons } from 'icons/category/map';
import { Alert, ChevronLeft, ChevronRight, Close } from 'icons/mono';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { memo, useEffect, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Swiper from 'react-id-swiper';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { snakeToCamel } from 'utils/CamelChange';
import { useSelector } from 'utils/hooks';
import { Address, Carousel, CarouselImage, CategoryWrapper, Container, FooterButtons, InfoWrapper, NextButton, Overlay, PlaceInfo, PrevButton, Scrollbar, ScrollImage, SingleImage, Slide, } from './styles';
export var PlacePhotoScroll = memo(function (_a) {
    var bookmarkButton = _a.bookmarkButton, _b = _a.imageHeight, imageHeight = _b === void 0 ? 230 : _b, photos = _a.photos, placeDetails = _a.placeDetails, handleFavority = _a.handleFavority, handleModal = _a.handleModal, onDeletePhoto = _a.onDeletePhoto;
    var _c, _d, _e;
    var userLogged = useSelector(makeSelectUser());
    var _f = __read(useState(false), 2), showCarousel = _f[0], setShowCarousel = _f[1];
    var _g = __read(useState(), 2), currentPhoto = _g[0], setCurrentPhoto = _g[1];
    var swiperRef = useRef(null);
    var photoLoading = useSelector(makeSelectorDeletePhotoLoading());
    var keyboard = useSelector(makeSelectUseKeyboard());
    var formatMessage = useIntl().formatMessage;
    var handleShow = function () {
        setShowCarousel(true);
    };
    var handleCancel = function () {
        setShowCarousel(false);
    };
    var handleChange = function () {
        if (swiperRef.current && swiperRef.current.swiper) {
            var photo = photos[swiperRef.current.swiper.realIndex];
            setCurrentPhoto(photo);
        }
    };
    useEffect(function () {
        handleChange();
        var scroll = window.document.querySelector('.indiana-scroll-container');
        var rate = window.document.querySelectorAll('.ant-rate, .ant-rate-star > div');
        scroll === null || scroll === void 0 ? void 0 : scroll.setAttribute('tabindex', '0');
        rate === null || rate === void 0 ? void 0 : rate.forEach(function (node) { return node.removeAttribute('tabindex'); });
    }, [photos]);
    var params = {
        shouldSwiperUpdate: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: handleChange,
            slideChange: handleChange,
        },
        renderPrevButton: function () { return (React.createElement(PrevButton, { size: "icon", variant: "ghost", icon: React.createElement(ChevronLeft, { size: rem(24), color: colors.space.tint800 }), className: "swiper-button-prev", title: "Previews photo" })); },
        renderNextButton: function () { return (React.createElement(NextButton, { size: "icon", variant: "ghost", icon: React.createElement(ChevronRight, { size: rem(24), color: colors.space.tint800 }), className: "swiper-button-next", title: "Next photo" })); },
    };
    var getPhotos = function () {
        var photosClone = cloneDeep(photos);
        return photosClone.sort(function (a) { return (a.isMain ? -1 : 1); });
    };
    var renderCategoryIcon = function () {
        var _a;
        return mapIcons(categoryId.square[snakeToCamel((_a = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.category) === null || _a === void 0 ? void 0 : _a.name)], 'square', {
            size: rem(32),
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(CategoryWrapper, { imageHeight: imageHeight }, renderCategoryIcon()),
            React.createElement(Scrollbar, { vertical: false, fullWidth: !!(photos === null || photos === void 0 ? void 0 : photos.length), isKeyboardMode: !!(photos === null || photos === void 0 ? void 0 : photos.length) && keyboard, imageHeight: imageHeight, tabIndex: 0 },
                !(photos === null || photos === void 0 ? void 0 : photos.length) && (React.createElement(SingleImage, { src: placeholderPlace, imageHeight: imageHeight, fullWidth: true })),
                (photos === null || photos === void 0 ? void 0 : photos.length) === 1 &&
                    map(getPhotos(), function (photo) { return (React.createElement(SingleImage, { key: photo.id, src: photo.urlStandard, imageHeight: imageHeight, fullWidth: true, onClick: handleShow })); }),
                (photos === null || photos === void 0 ? void 0 : photos.length) >= 2 &&
                    map(getPhotos(), function (photo) { return (React.createElement("div", { key: photo.id },
                        React.createElement(ScrollImage, { src: photo.urlLow, imageHeight: imageHeight, onClick: handleShow }))); })),
            React.createElement(InfoWrapper, null,
                React.createElement(PlaceInfo, null,
                    React.createElement("h3", null, placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.name),
                    React.createElement("div", null,
                        React.createElement("span", null, (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.rating) || 0),
                        React.createElement(Rate, { value: (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.rating) || 0, size: 14 })),
                    React.createElement("span", null, (_c = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.category) === null || _c === void 0 ? void 0 : _c.label),
                    React.createElement(Address, null, (_d = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.address) === null || _d === void 0 ? void 0 :
                        _d.postalCode,
                        ' ', (_e = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.address) === null || _e === void 0 ? void 0 :
                        _e.city)),
                bookmarkButton)),
        (photos === null || photos === void 0 ? void 0 : photos.length) !== 0 && showCarousel && (React.createElement(Overlay, { visible: showCarousel },
            React.createElement(Spin, { spinning: photoLoading },
                React.createElement(Carousel, null,
                    React.createElement(Swiper, __assign({}, params, { pagination: { el: '.swiper-pagination', type: 'fraction' }, ref: swiperRef }), map(photos, function (photo) { return (React.createElement(Slide, { key: photo.id },
                        React.createElement(CarouselImage, { src: photo.urlLarge }))); }))),
                React.createElement(FooterButtons, null,
                    React.createElement("div", null,
                        userLogged && (React.createElement(Button, { variant: (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMain) ? 'primary' : 'ghost', onClick: function () { return handleFavority(swiperRef); }, title: (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMain) ? 'Remove from favorite'
                                : 'Add as favorite', icon: React.createElement(FaStar, { size: rem(20), color: (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMain) ? colors.base.white
                                    : colors.space.tint700 }) })),
                        userLogged && !(currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMine) && (React.createElement(Button, { variant: "ghost", onClick: function () { return handleModal(swiperRef); }, title: formatMessage({
                                id: 'containers.placeDetails.report.photoButtonTitle',
                            }), icon: React.createElement(Alert, { size: rem(24), color: colors.space.tint700 }) })),
                        userLogged && (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMine) && onDeletePhoto && (React.createElement(Button, { variant: "ghost", onClick: function () { return onDeletePhoto(currentPhoto.id); }, title: "Delete photo", icon: React.createElement(MdDelete, { size: rem(24), color: colors.space.tint700 }) }))),
                    React.createElement("div", null,
                        React.createElement(Button, { variant: "ghost", onClick: handleCancel, title: "Close modal", icon: React.createElement(Close, { size: rem(18), color: colors.space.tint700 }) }))))))));
});
