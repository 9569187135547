var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { List } from 'antd';
import styled from 'styled-components';
export var ListWrapper = styled(List)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ListItemWrapper = styled(List.Item)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var ListItemMetaWrapper = styled(List.Item.Meta)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3;
