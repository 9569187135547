var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Label, Select, Spin } from 'components';
import { makeSelectorLoading } from 'containers/PlaceDetails/selectors';
import { useSelector } from 'hooks';
import { rem } from 'polished';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { CategoryDropdown } from '../CategoryDropdown';
import { makeSelectorSelectedCategory } from '../selectors';
import { CategorySelectorContainer } from './styles';
export var CategorySelector = function () {
    var formatMessage = useIntl().formatMessage;
    var loading = useSelector(makeSelectorLoading());
    var selectedCategory = useSelector(makeSelectorSelectedCategory());
    var _a = __read(useState(false), 2), opened = _a[0], setOpened = _a[1];
    return (React.createElement(CategorySelectorContainer, null,
        React.createElement(Spin, { spinning: loading },
            React.createElement(Label, { hasError: false }, formatMessage({
                id: 'containers.filterPlaces.categorySelector.label',
            })),
            React.createElement(Select, { open: opened, onClick: function () { return setOpened(!opened); }, dropdownRender: function () { return (React.createElement(CategoryDropdown, { style: { padding: rem(8) + " " + rem(12) + " 0" }, onSelect: function () { return setOpened(false); } })); }, placeholder: formatMessage({
                    id: 'containers.filterPlaces.desktopBar.buttons.category',
                }), listHeight: 300, value: selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.label, dropdownStyle: {
                    borderRadius: rem(6),
                    borderWidth: rem(1),
                    borderStyle: 'solid',
                    borderColor: colors.space.tint300,
                }, style: { width: '100%' } }))));
};
