var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
export var PageWrapperContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: ", ";\n\n  ", "\n"], ["\n  padding-top: ", ";\n\n  ",
    "\n"])), rem(80), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: 100%;\n    padding-bottom: ", ";\n    padding-top: ", ";\n  "], ["\n    min-height: 100%;\n    padding-bottom: ", ";\n    padding-top: ", ";\n  "])), rem(80), rem(60)));
var templateObject_1, templateObject_2;
