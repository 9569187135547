var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Radio } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var RadioWrapper = styled(Radio)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.ant-radio-wrapper {\n    font-size: ", " !important;\n\n    &:hover .ant-radio,\n    .ant-radio:hover .ant-radio-inner,\n    .ant-radio-input:focus + .ant-radio-inner {\n      border-color: ", " !important;\n    }\n\n    .ant-radio::after {\n      content: none;\n    }\n\n    .ant-radio-inner {\n      width: ", ";\n      height: ", ";\n    }\n\n    .ant-radio-checked {\n      .ant-radio-inner {\n        border-color: ", " !important;\n        border-width: ", ";\n\n        &:after {\n          background-color: transparent !important;\n        }\n      }\n    }\n\n    .ant-radio + span {\n      margin-left: ", " !important;\n      color: ", " !important;\n    }\n  }\n"], ["\n  &.ant-radio-wrapper {\n    font-size: ", " !important;\n\n    &:hover .ant-radio,\n    .ant-radio:hover .ant-radio-inner,\n    .ant-radio-input:focus + .ant-radio-inner {\n      border-color: ", " !important;\n    }\n\n    .ant-radio::after {\n      content: none;\n    }\n\n    .ant-radio-inner {\n      width: ", ";\n      height: ", ";\n    }\n\n    .ant-radio-checked {\n      .ant-radio-inner {\n        border-color: ", " !important;\n        border-width: ", ";\n\n        &:after {\n          background-color: transparent !important;\n        }\n      }\n    }\n\n    .ant-radio + span {\n      margin-left: ", " !important;\n      color: ", " !important;\n    }\n  }\n"])), rem(13), colors.base.primary, rem(20), rem(20), colors.base.primary, rem(5), rem(4), color);
var templateObject_1;
