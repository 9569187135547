var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { avatarImage1 } from 'assets/images';
import { GlobalScoreboardUser, PageWrapper, TabPane, Tabs } from 'components';
import { makeSelectIsLoggedIn, makeSelectUser, } from 'containers/Auth/selectors';
import { Rank } from 'containers/GlobalScoreboard';
import { fetchAllUsers } from 'containers/GlobalScoreboard/store/allUsers/actions';
import makeAllUsers from 'containers/GlobalScoreboard/store/allUsers/selectors';
import { fetchMonthUsers } from 'containers/GlobalScoreboard/store/monthUsers/actions';
import makeMonthUsers from 'containers/GlobalScoreboard/store/monthUsers/selectors';
import { fetchWeekUsers } from 'containers/GlobalScoreboard/store/weekUsers/actions';
import makeWeekUsers from 'containers/GlobalScoreboard/store/weekUsers/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { fetchMyProfileRequest } from 'containers/Profile/actions';
import makeMyProfile from 'containers/Profile/selectors/myProfileSelectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Content, ContentTitle, Footer, FooterTitle, GlobalScoreboardUserWrapper, Header, TabPaneTitle, } from './styles';
var TabKeys;
(function (TabKeys) {
    TabKeys["WEEKLY"] = "weekly";
    TabKeys["MONTHLY"] = "monthly";
    TabKeys["ALL"] = "all";
})(TabKeys || (TabKeys = {}));
var GlobalScoreboardPage = function () {
    var _a;
    var PER_PAGE = 13;
    var formatMessage = useIntl().formatMessage;
    var containerRef = useRef(null);
    var locale = useSelector(makeSelectLocale());
    var _b = __read(useState(TabKeys.ALL), 2), currentTab = _b[0], setCurrentTab = _b[1];
    var loggedUser = useSelector(makeSelectUser());
    var isLoggedIn = useSelector(makeSelectIsLoggedIn());
    var profileData = useSelector(makeMyProfile());
    var weekUsers = useSelector(makeWeekUsers());
    var monthUsers = useSelector(makeMonthUsers());
    var allUsers = useSelector(makeAllUsers());
    var loadMyProfile = useAction(fetchMyProfileRequest);
    var fetchWeekUsersRequest = useAction(function (payload) {
        return fetchWeekUsers(payload);
    });
    var fetchMonthUsersRequest = useAction(function (payload) {
        return fetchMonthUsers(payload);
    });
    var fetchAllUsersRequest = useAction(function (payload) {
        return fetchAllUsers(payload);
    });
    var loadWeekUsers = function (page) {
        if (page > weekUsers.pages)
            return;
        var payload = {
            pagination: {
                page: page,
                perPage: PER_PAGE,
            },
        };
        fetchWeekUsersRequest(payload);
    };
    var loadMonthUsers = function (page) {
        if (page > monthUsers.pages)
            return;
        var payload = {
            pagination: {
                page: page,
                perPage: PER_PAGE,
            },
        };
        fetchMonthUsersRequest(payload);
    };
    var loadAllUsers = function (page) {
        if (page > allUsers.pages)
            return;
        var payload = {
            pagination: {
                page: page,
                perPage: PER_PAGE,
            },
        };
        fetchAllUsersRequest(payload);
    };
    var handleTabChange = function (activeKey) {
        if (activeKey === TabKeys.WEEKLY)
            setCurrentTab(TabKeys.WEEKLY);
        if (activeKey === TabKeys.MONTHLY)
            setCurrentTab(TabKeys.MONTHLY);
        if (activeKey === TabKeys.ALL)
            setCurrentTab(TabKeys.ALL);
    };
    var handleLoadMore = function () {
        switch (currentTab) {
            case TabKeys.WEEKLY:
                loadWeekUsers(weekUsers.currentPage + 1);
                break;
            case TabKeys.MONTHLY:
                loadMonthUsers(monthUsers.currentPage + 1);
                break;
            case TabKeys.ALL:
                loadAllUsers(allUsers.currentPage + 1);
                break;
            default:
                break;
        }
    };
    var checkHasMore = function () {
        switch (currentTab) {
            case TabKeys.WEEKLY:
                return !weekUsers.loading && weekUsers.currentPage < weekUsers.pages;
            case TabKeys.MONTHLY:
                return !monthUsers.loading && monthUsers.currentPage < monthUsers.pages;
            case TabKeys.ALL:
                return !allUsers.loading && allUsers.currentPage < allUsers.pages;
            default:
                return false;
        }
    };
    var getLoggedUserPointsByTab = function () {
        if (!loggedUser || !profileData.requested || profileData.loading)
            return 0;
        switch (currentTab) {
            case TabKeys.WEEKLY:
                return profileData.weeklyPoints;
            case TabKeys.MONTHLY:
                return profileData.monthlyPoints;
            case TabKeys.ALL:
                return profileData.totalPoints;
            default:
                return 0;
        }
    };
    useEffect(function () {
        loadWeekUsers(1);
        loadMonthUsers(1);
        loadAllUsers(1);
        loadMyProfile();
    }, []);
    var podiumWeekUsers = weekUsers.items !== undefined ? weekUsers.items.slice(0, 3) : [];
    var restWeekUsers = weekUsers.items !== undefined ? weekUsers.items.slice(3) : [];
    var podiumMonthUsers = monthUsers.items !== undefined ? monthUsers.items.slice(0, 3) : [];
    var restMonthUsers = monthUsers.items !== undefined ? monthUsers.items.slice(3) : [];
    var podiumAllUsers = allUsers.items !== undefined ? allUsers.items.slice(0, 3) : [];
    var restAllUsers = allUsers.items !== undefined ? allUsers.items.slice(3) : [];
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.globalScoreboard.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.globalScoreboard.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.globalScoreboard.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.globalScoreboard.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(Container, { ref: containerRef },
                React.createElement(InfiniteScroll, { initialLoad: false, pageStart: 1, loadMore: handleLoadMore, hasMore: checkHasMore(), useWindow: false, getScrollParent: function () { return containerRef.current; } },
                    React.createElement(Header, null,
                        React.createElement("h1", null, formatMessage({ id: 'pages.globalScoreboard.title' }))),
                    React.createElement(Content, null,
                        React.createElement(ContentTitle, null, formatMessage({ id: 'pages.globalScoreboard.showByPeriod' })),
                        React.createElement(Tabs, { defaultActiveKey: TabKeys.ALL, onChange: handleTabChange },
                            React.createElement(TabPane, { key: TabKeys.ALL, tab: React.createElement(TabPaneTitle, null, formatMessage({
                                    id: 'containers.globalScoreboard.tabAll.title',
                                })) },
                                React.createElement(Rank, { isLoggedIn: isLoggedIn, podiumUsers: podiumAllUsers, restUsers: restAllUsers, loggedUserSlug: (loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.slug) || '', isLoading: allUsers.loading &&
                                        allUsers.items &&
                                        allUsers.items.length > 0, useContributions: true })),
                            monthUsers.items && monthUsers.items.length > 0 && (React.createElement(TabPane, { key: TabKeys.MONTHLY, tab: React.createElement(TabPaneTitle, null, formatMessage({
                                    id: 'containers.globalScoreboard.tabMonth.title',
                                })) },
                                React.createElement(Rank, { isLoggedIn: isLoggedIn, podiumUsers: podiumMonthUsers, restUsers: restMonthUsers, loggedUserSlug: (loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.slug) || '', isLoading: monthUsers.loading &&
                                        monthUsers.items &&
                                        monthUsers.items.length > 0 }))),
                            weekUsers.items && weekUsers.items.length > 0 && (React.createElement(TabPane, { key: TabKeys.WEEKLY, tab: React.createElement(TabPaneTitle, null, formatMessage({
                                    id: 'containers.globalScoreboard.tabWeek.title',
                                })) },
                                React.createElement(Rank, { isLoggedIn: isLoggedIn, podiumUsers: podiumWeekUsers, restUsers: restWeekUsers, loggedUserSlug: (loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.slug) || '', isLoading: weekUsers.loading &&
                                        weekUsers.items &&
                                        weekUsers.items.length > 0 }))))))),
            loggedUser && (React.createElement(Footer, null,
                React.createElement(FooterTitle, { variant: getLoggedUserPointsByTab() === 0 ? 'ghost' : 'primary' },
                    currentTab === TabKeys.WEEKLY && (React.createElement("span", null, getLoggedUserPointsByTab() === 0 ? (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabWeek.footerTitleWithoutPoints" })) : (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabWeek.footerTitleWithPoints" })))),
                    currentTab === TabKeys.MONTHLY && (React.createElement("span", null, getLoggedUserPointsByTab() === 0 ? (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabMonth.footerTitleWithoutPoints" })) : (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabMonth.footerTitleWithPoints" })))),
                    currentTab === TabKeys.ALL && (React.createElement("span", null, getLoggedUserPointsByTab() === 0 ? (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabAll.footerTitleWithoutPoints" })) : (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabAll.footerTitleWithPoints" }))))),
                React.createElement(GlobalScoreboardUserWrapper, null,
                    React.createElement(GlobalScoreboardUser, { avatar: ((_a = loggedUser.avatar) === null || _a === void 0 ? void 0 : _a.urlThumb) || avatarImage1, firstName: loggedUser.firstName, lastName: loggedUser.lastName, slug: loggedUser.slug, points: getLoggedUserPointsByTab(), pointsVariant: getLoggedUserPointsByTab() === 0 ? 'ghost' : 'primary', pageURL: paths.profilePage })))))));
};
export default GlobalScoreboardPage;
