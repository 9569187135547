import { Calendar } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatWithLocal } from 'utils/date';
import { Content, Dates, DatesContainer } from './styles';
export var ChallengeDetailDates = function (_a) {
    var start = _a.start, end = _a.end;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(DatesContainer, null,
        React.createElement(Content, null,
            React.createElement(Calendar, { size: rem(22) }),
            React.createElement("div", null,
                React.createElement(Dates, null,
                    formatMessage({
                        id: 'containers.challengesDetail.challengeStartsOn',
                    }),
                    ' ',
                    React.createElement("span", null, formatWithLocal(new Date(start), 'dd MMMM HH:mm'))),
                React.createElement(Dates, null,
                    formatMessage({
                        id: 'containers.challengesDetail.challengeEndsOn',
                    }),
                    ' ',
                    React.createElement("span", null, formatWithLocal(new Date(end), 'dd MMMM HH:mm')))))));
};
