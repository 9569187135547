import React, { memo } from 'react';
export var Cafe = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M28.038 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#ff6900", style: { fill: '#ff6900' }, d: "M7.347 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.759 4.759-12.474 0-17.232s-12.474-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M20.589 11.886h-9.948c0 2.743 0.754 5.181 1.923 6.705h5.982c0.374-0.305 0.705-0.914 0.983-1.524h1.037c1.613 0 2.924-1.145 2.924-2.59s-1.302-2.59-2.901-2.59zM20.566 15.543h-0.578c0.196-0.61 0.337-1.524 0.414-2.438h0.187c0.811 0 1.471 0.562 1.471 1.219 0 0.646-0.684 1.219-1.495 1.219z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M10.376 21.638h10.325l0.476-1.829h-11.278l0.477 1.829z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M15.181 8.677c0.115 0.113 0.302 0.297 0.35 0.391-0.076 0.246-0.536 0.613-0.919 0.799l0.617 1.289c0.248-0.119 1.497-0.766 1.711-1.836 0.154-0.769-0.394-1.307-0.756-1.664-0.118-0.115-0.31-0.304-0.354-0.398 0.064-0.22 0.557-0.587 0.961-0.769l-0.291-0.652-0.29-0.654c-0.269 0.119-1.62 0.77-1.797 1.872-0.119 0.748 0.394 1.253 0.769 1.621z" })));
});
