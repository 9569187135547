import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { signInUser } from './actions';
import SignInForm from './Form';
import signInEmailSchema, { defaultProps } from './schemas';
export var SignIn = withFormik({
    mapPropsToValues: function (props) { return ({
        from: props.from,
        email: defaultProps.email,
        password: defaultProps.password,
    }); },
    validationSchema: function (props) { return signInEmailSchema(props.intl.formatMessage); },
    handleSubmit: function (_a, _b) {
        var email = _a.email, password = _a.password;
        var props = _b.props, setSubmitting = _b.setSubmitting, resetForm = _b.resetForm, setErrors = _b.setErrors, setStatus = _b.setStatus;
        var payload = {
            email: email,
            password: password,
        };
        var actions = {
            resetForm: resetForm,
            setErrors: setErrors,
            setSubmitting: setSubmitting,
            setStatus: setStatus,
        };
        var meta = {
            from: props.from,
        };
        props.handleSignIn(payload, actions, meta);
    },
    displayName: 'SignInByEmail',
})(SignInForm);
var mapStateToProps = function (state, ownprops) { return ({
    from: ownprops.from,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    handleSignIn: function (payload, actions, meta) {
        dispatch(signInUser(payload, actions, meta));
    },
}); };
var SignInIntl = injectIntl(SignIn);
var ConnectSignIn = connect(mapStateToProps, mapDispatchToProps)(SignInIntl);
export default ConnectSignIn;
