import avatarImage1 from './avatar-1.png';
import avatarImage2 from './avatar-2.png';
import avatarImage3 from './avatar-3.png';
import avatarImage4 from './avatar-4.png';
import avatarImage5 from './avatar-5.png';
import colaborateWithUs from './colaborate-with-us.png';
import dogAndOwner from './dog-and-owner.png';
import emoticonFlyingLeaves from './emoticon-flying-leaves.png';
import emoticonMonsterVidegame from './emoticon-monster-videgame.png';
import emoticonThoughtful from './emoticon-thoughtful.png';
import establishmentCard from './establishment-card.png';
import flagFrance from './flag-france.png';
import flagGermany from './flag-germany.png';
import flagItaly from './flag-italy.png';
import flagSpain from './flag-spain.png';
import flagUnitedKingdom from './flag-united-kingdom.png';
import guideDogs from './guide-dogs.png';
import helpAchieveGoals from './help-achieve-goals.png';
import homeBgDesktop from './home-bg-desktop.jpg';
import homeBgMobile from './home-bg-mobile.jpg';
import peopleSmiling from './people-smiling.png';
import placeholderNews from './placeholder-news.png';
import placeholderPlace from './placeholder-place.png';
import videoThumbnail from './video-thumbnail.jpg';
import wheelchairGirls from './wheelchair-girls.png';
import wheelchairHand from './wheelchair-hand.png';
import wheelchairPeople from './wheelchair-people.png';
export { avatarImage1, avatarImage2, avatarImage3, avatarImage4, avatarImage5, colaborateWithUs, dogAndOwner, emoticonFlyingLeaves, emoticonMonsterVidegame, emoticonThoughtful, establishmentCard, flagFrance, flagGermany, flagItaly, flagSpain, flagUnitedKingdom, guideDogs, helpAchieveGoals, homeBgMobile, homeBgDesktop, peopleSmiling, placeholderNews, placeholderPlace, videoThumbnail, wheelchairGirls, wheelchairHand, wheelchairPeople, };
