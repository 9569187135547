var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { rem, rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var shadow = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  a {\n    text-decoration: none;\n    outline: 0 !important;\n    outline-offset: 0 !important;\n    position: relative;\n\n    ", ";\n  }\n"], ["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  a {\n    text-decoration: none;\n    outline: 0 !important;\n    outline-offset: 0 !important;\n    position: relative;\n\n    ",
    ";\n  }\n"])), color, ifProp('isKeyboardMode', css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        &:focus {\n          &::before {\n            ", ";\n            content: '';\n            width: 100%;\n            height: calc(90% + 1px);\n            display: block;\n            position: absolute;\n            border-radius: ", ";\n            top: ", ";\n            left: ", ";\n            z-index: 1;\n          }\n        }\n      "], ["\n        &:focus {\n          &::before {\n            ", ";\n            content: '';\n            width: 100%;\n            height: calc(90% + 1px);\n            display: block;\n            position: absolute;\n            border-radius: ", ";\n            top: ", ";\n            left: ", ";\n            z-index: 1;\n          }\n        }\n      "])), shadow, rem(10), rem(-2), rem(-2))));
export var Header = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
export var UserWrapper = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n"], ["\n  align-items: center;\n  display: flex;\n"])));
export var AvatarWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var MedalWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  bottom: -", ";\n  position: absolute;\n  right: -", ";\n"], ["\n  bottom: -", ";\n  position: absolute;\n  right: -", ";\n"])), rem(2), rem(2));
export var UserInfo = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin: 0 ", ";\n\n  overflow: hidden;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin: 0 ", ";\n\n  overflow: hidden;\n"])), rem(12));
export var Fullname = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1;\n\n  display: inline-block;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  color: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1;\n\n  display: inline-block;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  color: ", ";\n"])), rem(14), fonts.weight.semibold, color);
export var Username = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: ", ";\n\n  display: inline-block;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  color: ", ";\n"], ["\n  font-size: ", ";\n\n  display: inline-block;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  color: ", ";\n"])), rem(12), color);
export var ReviewDate = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n\n  span {\n    font-size: ", ";\n    margin-right: ", ";\n    text-align: right;\n  }\n\n  svg {\n    min-width: ", ";\n    min-height: ", ";\n    cursor: pointer;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n\n  span {\n    font-size: ", ";\n    margin-right: ", ";\n    text-align: right;\n  }\n\n  svg {\n    min-width: ", ";\n    min-height: ", ";\n    cursor: pointer;\n  }\n"])), rem(12), rem(6), rem(18), rem(18));
export var ReviewText = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-size: ", ";\n  white-space: pre-wrap;\n\n  div {\n    color: ", ";\n  }\n"], ["\n  font-size: ", ";\n  white-space: pre-wrap;\n\n  div {\n    color: ", ";\n  }\n"])), rem(13), color);
export var ReportReviewButton = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  border-radius: 50%;\n  display: flex;\n  margin-top: ", ";\n\n  &:focus {\n    ", ";\n  }\n"], ["\n  border-radius: 50%;\n  display: flex;\n  margin-top: ", ";\n\n  &:focus {\n    ", ";\n  }\n"])), rem(-2), shadow);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
