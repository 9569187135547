import { createSelector } from 'reselect';
import { teamsOnChallengeInitialState } from './reducer';
export var selectTeamsOnChallengeDomain = function (state) {
    return state.teamsOnChallenge || teamsOnChallengeInitialState;
};
export var makeSelectTeamsOnChallengeLoading = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectTeamsOnChallengeHasError = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectTeamsOnChallengeError = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectTeamsOnChallengePages = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectTeamsOnChallengeCurrentPage = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectTeamsOnChallengeTotal = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectTeamsOnChallengeItems = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeTeamsOnChallenge = function () {
    return createSelector(selectTeamsOnChallengeDomain, function (subState) { return subState; });
};
export default makeTeamsOnChallenge;
