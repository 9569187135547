import produce from 'immer';
import { UPLOAD_COVER_IMAGE_TEAM_CLEAR_FAILURE, UPLOAD_COVER_IMAGE_TEAM_FAILURE, UPLOAD_COVER_IMAGE_TEAM_REQUEST, UPLOAD_COVER_IMAGE_TEAM_SUCCESS, } from './constants';
export var uploadCoverImageTeamInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    item: undefined,
};
export var uploadCoverImageTeamReducer = produce(function (draft, action) {
    switch (action.type) {
        case UPLOAD_COVER_IMAGE_TEAM_REQUEST:
            draft.loading = true;
            break;
        case UPLOAD_COVER_IMAGE_TEAM_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case UPLOAD_COVER_IMAGE_TEAM_CLEAR_FAILURE:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            break;
        case UPLOAD_COVER_IMAGE_TEAM_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.item = action.payload.item;
            break;
        default:
    }
}, uploadCoverImageTeamInitialState);
