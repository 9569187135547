import * as Yup from 'yup';

const createNewTeamSchema = (formatMessage) =>
  Yup.object().shape({
    name: Yup.string().required(
      formatMessage({
        id: 'containers.createNewTeam.nameIsRequired',
      }),
    ),
  });

export const defaultProps = {
  name: '',
};

export default createNewTeamSchema;
