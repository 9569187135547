import { Avatar } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import React, { memo } from 'react';
import { Rank, RankItemContainer, Score, UserInfo, UserName, UserSlug, UserWrapper, } from './styles';
export var RankItem = memo(function (_a) {
    var rank = _a.rank, avatar = _a.avatar, name = _a.name, pseudo = _a.pseudo, points = _a.points, loggedUserSlug = _a.loggedUserSlug, isKeyboardMode = _a.isKeyboardMode;
    return (React.createElement(RankItemContainer, { to: loggedUserSlug === pseudo
            ? paths.profilePage
            : paths.usersPage.replace(':userSlug', pseudo), isKeyboardMode: isKeyboardMode },
        React.createElement(Rank, null,
            rank,
            "\u00B0"),
        React.createElement(UserWrapper, null,
            React.createElement(Avatar, { src: avatar, size: "small", alt: name + "'s avatar" }),
            React.createElement(UserInfo, null,
                (name && React.createElement(UserName, null, name)) || (React.createElement(UserName, null, "@" + pseudo)),
                React.createElement(UserSlug, null, name && "(@" + pseudo + ")"))),
        React.createElement(Score, null, points)));
});
