import React, { memo } from 'react';
export var PostOffice = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#00b4a0", style: { fill: '#00b4a0' }, d: "M16.152 30.171c8.247 0 14.933-6.686 14.933-14.933s-6.686-14.933-14.933-14.933c-8.247 0-14.933 6.686-14.933 14.933s6.686 14.933 14.933 14.933z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M23.886 16.989l-1.527-7.605-3.312 4.623 4.838 2.983z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M22.066 7.752l-14.432 2.899 8.571 5.283 5.861-8.183z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M18.356 14.974l-1.829 2.553-2.676-1.651-4.035 5.629 14.435-2.896-5.896-3.635z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M7.93 12.227l1.55 7.71 3.357-4.686-4.906-3.024z" })));
});
