import map from 'lodash/map';
import React, { memo } from 'react';
import { Item } from './Item';
export var Items = memo(function (_a) {
    var items = _a.items, _b = _a.geolocation, geolocation = _b === void 0 ? null : _b;
    return (React.createElement("ul", null, map(items, function (item) {
        var url = item.href;
        if (geolocation) {
            var latitude = geolocation.latitude, longitude = geolocation.longitude;
            url += "&lat=" + latitude + "&lng=" + longitude;
            if (latitude === 0 || longitude === 0) {
                url += '&zoom=2';
            }
        }
        return React.createElement(Item, { key: item.href, label: item.label, to: url });
    })));
});
