var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { switchProp } from 'styled-tools';
import { colors, fonts } from 'styles';
export var Heading = styled.h2(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n\n  ", ";\n\n  ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n\n  ",
    ";\n\n  ",
    ";\n"])), rem(17), fonts.weight.semibold, media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-bottom: ", ";\n    text-align: center;\n  "], ["\n    margin-bottom: ", ";\n    text-align: center;\n  "])), rem(24)), media.greaterThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: ", ";\n    margin-bottom: ", ";\n    position: relative;\n    white-space: pre-line;\n\n    &::after {\n      content: '';\n      width: ", ";\n      height: ", ";\n      border-radius: ", ";\n\n      position: absolute;\n      left: 0;\n      bottom: ", ";\n\n      ", "\n    }\n  "], ["\n    font-size: ", ";\n    font-weight: ", ";\n    margin-bottom: ", ";\n    position: relative;\n    white-space: pre-line;\n\n    &::after {\n      content: '';\n      width: ", ";\n      height: ", ";\n      border-radius: ", ";\n\n      position: absolute;\n      left: 0;\n      bottom: ", ";\n\n      ",
    "\n    }\n  "])), rem(34), fonts.weight.bold, rem(48), rem(60), rem(5), rem(3), rem(-16), switchProp('underline', {
    primary: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          background-color: ", ";\n        "], ["\n          background-color: ", ";\n        "])), colors.base.primary),
    secondary: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          background-color: ", ";\n        "], ["\n          background-color: ", ";\n        "])), colors.base.secondary),
    warning: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          background-color: ", ";\n        "], ["\n          background-color: ", ";\n        "])), colors.base.warning),
    danger: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          background-color: ", ";\n        "], ["\n          background-color: ", ";\n        "])), colors.base.danger),
})));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
