import React, { memo } from 'react';
export var Pharmacy = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M28.038 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#0096ff", style: { fill: '#0096ff' }, d: "M7.042 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.758 4.759-12.474 0-17.232s-12.473-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M12.636 9.348c-0.312-1.028 0.24-2.28 1.167-2.455 1.144-0.216 1.901 0.395 2.093 1.248-0.191 0.304-0.49 0.3-0.523 0.724-0.038 0.489 0.749 1.813 1.248 1.691 0.381-0.094 0.701-1.667 0.564-2.013-0.138-0.347-0.374-0.349-0.564-0.523-0.107-0.266-0.139-0.548-0.242-0.765-0.292-0.617-0.887-1.045-1.69-1.127-1.784-0.205-3.001 0.925-3.1 2.335-0.147 2.099 1.449 3.234 2.898 3.743 0.753 0.265 1.519 0.359 2.214 0.604 0.612 0.216 1.461 0.529 1.691 1.127 0.493 1.283-0.394 2.802-1.167 3.341-0.408 0.285-0.644 0.291-0.926 0.332v1.158c1.184-0.275 2.089-0.693 2.778-1.49 0.619-0.717 1.203-1.836 1.006-3.22-0.492-3.461-6.523-1.66-7.447-4.71z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M16.302 19.421c-0.012 0.163-0.003 0.449-0.003 0.702 1.498-0.425 1.289 0.659 0.604 1.047-0.73 0.413-2.47 0.34-2.817-0.564-0.262-0.682 0.123-1.451 0.789-1.559l0.023-1.138c-1.545 0.081-2.773 1.99-1.698 3.381 0.378 0.489 1.272 0.97 2.013 1.047 1.506 0.157 2.755-0.428 3.22-1.422 0.537-1.494-0.948-1.975-2.13-1.494z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M14.642 20.914c0.058-0.13 0.14-0.151 0.266-0.214l0.018-0.832c-0.479 0.15-0.876 0.774-0.284 1.046z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M14.487 22.497c-0.262-0.098-0.662-0.232-0.966-0.483-0.563 1.131-0.009 2.040 0.939 2.434 0.808 0.357 1.657 0.218 2.603 0.411 0.293 0.059 0.77 0.483 1.168 0.254-0.638-1.528-4.423-0.576-3.743-2.616z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M15.22 21.004c0.264 0.114 0.528 0.127 0.805 0.072v-3.856c0.341-0.021 0.696-0.057 0.696-0.057 1.604-1.381 1.755-3.144 0.341-3.875h-8.292c1.221 2.238 3.636 3.798 6.449 3.935v3.782z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M15.22 22.644v0.783c0.277 0.19 0.805 0.26 0.805 0.26v-1.043c-0.347 0.050-0.546 0.050-0.805 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M15.22 24.987v1.293h0.805v-1.208c0 0-0.478 0.009-0.805-0.086z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M20.32 13.287c0.223 0.418 0.397 1.129 0.145 2.263 0 0 1.446-1.391 1.922-2.263h-2.066z" })));
});
