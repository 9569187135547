import { Collapse, List, Skeleton } from 'components';
import { Call, GlobeSharp, MapSharp, Pin, Time } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from 'styles';
import { Container, HourContainer, InfoContainer, LinkContainer, PermanentlyClosed, SiteContainer, } from './styles';
export var Information = function (_a) {
    var placeDetails = _a.placeDetails, loading = _a.loading;
    var _b, _c, _d;
    var getTodayNumber = function () {
        var d = new Date();
        // Adjust the native javascript date to work with google place
        if (d.getDay() === 0)
            return 6;
        return d.getDay() - 1;
    };
    var addSkeleton = function (element) { return (React.createElement(Skeleton, { loading: loading, active: true, paragraph: false }, element)); };
    var formatWebsite = function (dns) {
        if (/^(https?:\/\/)/g.test(dns)) {
            return new URL(dns).hostname;
        }
        return dns;
    };
    return (React.createElement(Container, null,
        React.createElement(List, null,
            (((_b = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.address) === null || _b === void 0 ? void 0 : _b.full) || loading) && (React.createElement(List.Item, null, addSkeleton(React.createElement(InfoContainer, null,
                React.createElement(Pin, { size: rem(18), color: colors.base.primary }),
                React.createElement("span", null, (_c = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.address) === null || _c === void 0 ? void 0 : _c.full))))),
            ((placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.phone) || loading) && (React.createElement(List.Item, null, addSkeleton(React.createElement(LinkContainer, { href: "tel:" + (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.phone) },
                React.createElement(InfoContainer, null,
                    React.createElement(Call, { size: rem(18), color: colors.base.primary }),
                    React.createElement("span", null, placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.phone)))))),
            (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.permanentlyClosed) && (React.createElement(List.Item, null, addSkeleton(React.createElement(InfoContainer, null,
                React.createElement(PermanentlyClosed, null,
                    React.createElement(FormattedMessage, { id: "containers.placeDetails.closed" })))))),
            !(placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.permanentlyClosed) && (React.createElement(List.Item, null, addSkeleton(React.createElement(HourContainer, null, ((_d = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.hours) === null || _d === void 0 ? void 0 : _d.weekdayText) && (React.createElement(Collapse, { expandIconPosition: "right" },
                React.createElement(Collapse.Panel, { key: 1, header: React.createElement(React.Fragment, null,
                        React.createElement(Time, { size: rem(18), color: colors.base.primary }),
                        placeDetails.hours.weekdayText[getTodayNumber()]) }, map(placeDetails.hours.weekdayText, function (hour, index) { return (React.createElement("div", { key: index }, index === getTodayNumber() ? React.createElement("b", null, hour) : hour)); })))))))),
            ((placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.website) || loading) && (React.createElement(List.Item, null, addSkeleton(React.createElement(LinkContainer, { href: placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.website, target: "_blank" },
                React.createElement(InfoContainer, null,
                    React.createElement(GlobeSharp, { size: rem(18), color: colors.base.primary }),
                    React.createElement(SiteContainer, null, (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.website) &&
                        formatWebsite(placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.website))))))),
            React.createElement(List.Item, null, addSkeleton(React.createElement(LinkContainer, { href: placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.gmapsUrl, target: "_blank", rel: "noopener noreferrer" },
                React.createElement(InfoContainer, null,
                    React.createElement(MapSharp, { size: rem(18), color: colors.base.primary }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "containers.placeDetails.suggestModification" })))))))));
};
