export var GET_PLACE_DETAILS = 'PlaceDetails/GET_PLACE_DETAILS';
export var GET_PLACE_DETAILS_SUCCESS = 'PlaceDetails/GET_PLACE_DETAILS_SUCCESS';
export var GET_PLACE_DETAILS_FAILURE = 'PlaceDetails/GET_PLACE_DETAILS_FAILURE';
export var ADD_PLACE_TO_BOOKMARK = 'PlaceDetails/ADD_PLACE_TO_BOOKMARK';
export var REMOVE_PLACE_FROM_BOOKMARK = 'PlaceDetails/REMOVE_PLACE_FROM_BOOKMARK';
export var ADD_PLACE_TO_BOOKMARK_SUCCESS = 'PlaceDetails/ADD_PLACE_TO_BOOKMARK_SUCCESS';
export var REMOVE_PLACE_FROM_BOOKMARK_SUCCESS = 'PlaceDetails/REMOVE_PLACE_FROM_BOOKMARK_SUCCESS';
export var GET_PLACE_CRITERIA = 'PlaceDetails/GET_PLACE_CRITERIA';
export var GET_PLACE_CRITERIA_SUCCESS = 'PlaceDetails/GET_PLACE_CRITERIA_SUCCESS';
export var GET_PLACE_CRITERIA_FAILURE = 'PlaceDetails/GET_PLACE_CRITERIA_FAILURE';
export var SET_ACTIVE_SECTION = 'PlaceDetails/SET_ACTIVE_SECTION';
export var GET_CATEGORIES = 'PlaceDetails/GET_CATEGORIES';
export var GET_CATEGORIES_SUCCESS = 'PlaceDetails/GET_CATEGORIES_SUCCESS';
export var GET_CATEGORIES_FAILURE = 'PlaceDetails/GET_CATEGORIES_FAILURE';
export var UPDATE_PLACE = 'PlaceDetails/UPDATE_PLACE';
export var UPDATE_PLACE_SUCCESS = 'PlaceDetails/UPDATE_PLACE_SUCCESS';
export var UPDATE_PLACE_FAILURE = 'PlaceDetails/UPDATE_PLACE_FAILURE';
export var CLEAR_FEEDBACK = 'PlaceDetails/CLEAR_FEEDBACK';
export var REPORT_PLACE = 'PlaceDetails/REPORT_PLACE';
export var REPORT_PLACE_SUCCESS = 'PlaceDetails/REPORT_PLACE_SUCCESS';
export var REPORT_PLACE_FAILURE = 'PlaceDetails/REPORT_PLACE_FAILURE';
export var CLEAR_REPORTED = 'PlaceDetails/CLEAR_REPORTED';
export var REPORT_MEDIA_PLACE = 'PlaceDetails/REPORT_MEDIA_PLACE';
export var REPORT_MEDIA_PLACE_SUCCESS = 'PlaceDetails/REPORT_MEDIA_PLACE_SUCCESS';
export var REPORT_MEDIA_PLACE_FAILURE = 'PlaceDetails/REPORT_MEDIA_PLACE_FAILURE';
export var CLEAR_REPORTED_PHOTO = 'PlaceDetails/CLEAR_REPORTED_PHOTO';
export var UPLOAD_CRITERION_PHOTO = 'PlaceDetails/UPLOAD_CRITERION_PHOTO';
export var UPLOAD_CRITERION_PHOTO_SUCCESS = 'PlaceDetails/UPLOAD_CRITERION_PHOTO_SUCCESS';
export var UPLOAD_CRITERION_PHOTO_FAILURE = 'PlaceDetails/UPLOAD_CRITERION_PHOTO_FAILURE';
export var sections = {
    options: 'options',
    category: 'category',
    criteria: 'criteria',
};
