var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Spin } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { makeSelectUser } from 'containers/Auth/selectors';
import { makeSelectorDeletePhotoLoading } from 'containers/PlacePhotos/selectors';
import { Alert, CamSolid, ChevronLeft, ChevronRight, Close } from 'icons/mono';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Swiper from 'react-id-swiper';
import { FormattedMessage, useIntl } from 'react-intl';
import { colors } from 'styles';
import { useSelector } from 'utils/hooks';
import { AccessibilityPhotosContainer, Carousel, CarouselImage, DoubleWrapper, FooterButtons, Image, NextButton, Overlay, PrevButton, SingleWrapper, Slide, TrippleWrapper, } from './styles';
export var AccessibilityPhotos = function (_a) {
    var redirectAddPhoto = _a.redirectAddPhoto, photos = _a.photos, handleFavority = _a.handleFavority, handleModal = _a.handleModal, onDeletePhoto = _a.onDeletePhoto;
    var userLogged = useSelector(makeSelectUser());
    var _b = __read(useState(false), 2), showCarousel = _b[0], setShowCarousel = _b[1];
    var swiperRef = useRef(null);
    var photoLoading = useSelector(makeSelectorDeletePhotoLoading());
    var _c = __read(useState(), 2), currentPhoto = _c[0], setCurrentPhoto = _c[1];
    var keyboard = useSelector(makeSelectUseKeyboard());
    var formatMessage = useIntl().formatMessage;
    var handleShow = function () {
        setShowCarousel(true);
    };
    var handleCancel = function () {
        setShowCarousel(false);
    };
    var handleChange = function () {
        if (swiperRef.current && swiperRef.current.swiper) {
            var photo = photos[swiperRef.current.swiper.realIndex];
            setCurrentPhoto(photo);
        }
    };
    useEffect(function () {
        handleChange();
    }, [photos]);
    var params = {
        shouldSwiperUpdate: true,
        on: {
            init: handleChange,
            slideChange: handleChange,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        renderPrevButton: function () { return (React.createElement(PrevButton, { size: "icon", variant: "ghost", icon: React.createElement(ChevronLeft, { size: rem(24), color: colors.space.tint800 }), className: "swiper-button-prev", tabIndex: 0, title: "Previews photo" })); },
        renderNextButton: function () { return (React.createElement(NextButton, { size: "icon", variant: "ghost", icon: React.createElement(ChevronRight, { size: rem(24), color: colors.space.tint800 }), className: "swiper-button-next", tabIndex: 0, title: "Next photo" })); },
    };
    var getPhotos = function () {
        var photosClone = cloneDeep(photos);
        return photosClone.sort(function (a) { return (a.isMain ? -1 : 1); });
    };
    return (React.createElement(React.Fragment, null,
        (photos === null || photos === void 0 ? void 0 : photos.length) !== 0 && (React.createElement(AccessibilityPhotosContainer, null,
            (photos === null || photos === void 0 ? void 0 : photos.length) === 1 && (React.createElement(SingleWrapper, { onClick: handleShow, isKeyboardMode: keyboard, tabIndex: 0 },
                React.createElement(Image, { src: photos[0].urlStandard }))),
            (photos === null || photos === void 0 ? void 0 : photos.length) === 2 && (React.createElement(DoubleWrapper, { onClick: handleShow, isKeyboardMode: keyboard, tabIndex: 0 },
                React.createElement(Image, { src: getPhotos()[0].urlLow }),
                React.createElement(Image, { src: getPhotos()[1].urlLow }))),
            (photos === null || photos === void 0 ? void 0 : photos.length) >= 3 && (React.createElement(TrippleWrapper, { onClick: handleShow, isKeyboardMode: keyboard, tabIndex: 0 },
                React.createElement(SingleWrapper, null,
                    React.createElement(Image, { src: getPhotos()[0].urlStandard })),
                React.createElement(DoubleWrapper, null,
                    React.createElement(Image, { src: getPhotos()[1].urlLow }),
                    React.createElement(Image, { src: getPhotos()[2].urlLow })))),
            React.createElement(Button, { type: "button", variant: "primary", shape: "pill", size: "small", icon: React.createElement(CamSolid, { size: rem(20) }), onClick: function () {
                    redirectAddPhoto();
                } },
                React.createElement(FormattedMessage, { id: "containers.placeDetails.addMorePhotos" })))),
        (photos === null || photos === void 0 ? void 0 : photos.length) !== 0 && showCarousel && (React.createElement(Overlay, { visible: showCarousel },
            React.createElement(Spin, { spinning: photoLoading },
                React.createElement(Carousel, null,
                    React.createElement(Swiper, __assign({}, params, { pagination: { el: '.swiper-pagination', type: 'fraction' }, ref: swiperRef }), map(photos, function (photo) { return (React.createElement(Slide, { key: photo.id },
                        React.createElement(CarouselImage, { src: photo.urlLarge }))); }))),
                React.createElement(FooterButtons, null,
                    React.createElement("div", null,
                        userLogged && (React.createElement(Button, { variant: (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMain) ? 'primary' : 'ghost', onClick: function () { return handleFavority(swiperRef); }, title: (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMain) ? 'Remove from favotite'
                                : 'Add as favorite', icon: React.createElement(FaStar, { size: rem(20), color: (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMain) ? colors.base.white
                                    : colors.space.tint700 }) })),
                        userLogged && !(currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMine) && (React.createElement(Button, { variant: "ghost", onClick: function () { return handleModal(swiperRef); }, icon: React.createElement(Alert, { size: rem(24), color: colors.space.tint700 }), title: formatMessage({
                                id: 'containers.placeDetails.report.photoButtonTitle',
                            }) })),
                        userLogged && onDeletePhoto && (currentPhoto === null || currentPhoto === void 0 ? void 0 : currentPhoto.isMine) && (React.createElement(Button, { variant: "ghost", onClick: function () { return onDeletePhoto(currentPhoto.id); }, icon: React.createElement(MdDelete, { size: rem(24), color: colors.space.tint700 }), title: "Delete photo" }))),
                    React.createElement("div", null,
                        React.createElement(Button, { variant: "ghost", onClick: handleCancel, icon: React.createElement(Close, { size: rem(18), color: colors.space.tint700 }), title: "Close modal" }))))))));
};
