import produce from 'immer';
import assign from 'lodash/assign';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import replace from 'lodash/replace';
import split from 'lodash/split';
import trim from 'lodash/trim';
import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import it from './it';
export var defaultLang = 'fr';
export var supportedLangs = {
    en: 'English (UK)',
    fr: 'Français',
    es: 'Español',
    it: 'Italiano',
    de: 'Deutsche',
};
export var languages = function () { return keys(supportedLangs); };
var stripCountry = function (lang) { return split(replace(trim(lang), '_', '-'))[0]; };
var findFirstSupported = function (langs) {
    return find(langs, function (code) { return includes(languages(), code); });
};
export var determineUserLang = function (acceptedLangs, path) {
    if (path === void 0) { path = undefined; }
    // check url for /en/foo where en is a supported language code
    if (path !== null) {
        var urlLang = split(trim(path), '/')[1];
        var matchingUrlLang = findFirstSupported([stripCountry(urlLang)]);
        if (matchingUrlLang) {
            return matchingUrlLang;
        }
    }
    // check browser-set accepted langs
    var matchingAcceptedLang = findFirstSupported(map(acceptedLangs, stripCountry));
    return matchingAcceptedLang || defaultLang;
};
export var flattenMessages = function (nestedMessages, prefix) {
    if (prefix === void 0) { prefix = ''; }
    return reduce(keys(nestedMessages), function (messages, key) {
        var value = nestedMessages[key];
        var prefixedKey = prefix ? prefix + "." + key : key;
        if (isString(value)) {
            return produce(messages, function (draft) {
                draft[prefixedKey] = value;
            });
        }
        return assign(messages, flattenMessages(value, prefixedKey));
    }, {});
};
export default { en: en, fr: fr, es: es, it: it, de: de };
