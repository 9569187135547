import { placeholderPlace } from 'assets/images';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Avatar } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import { Medal200Desktop } from 'icons/medal';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { AvatarWrapper, CardTeamContainer, ChallengeContainer, InfoWrapper, MedalWrapper, MembersCount, Name, RackWrapper, RankCount, Score, StyledLink, } from './styles';
export var CardTeam = memo(function (_a) {
    var item = _a.item, challengeSlug = _a.challengeSlug;
    var _b;
    var location = useLocation();
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(StyledLink, { to: {
            pathname: paths.membersTeamDetailPage
                .replace(':challengeSlug', challengeSlug)
                .replace(':teamSlug', item.slug),
            state: { from: location.pathname },
        }, isKeyboardMode: keyboard },
        React.createElement(CardTeamContainer, null,
            React.createElement(RackWrapper, null,
                React.createElement(RankCount, null,
                    item.rank,
                    "\u00BA"),
                React.createElement(MedalWrapper, null,
                    React.createElement(Medal200Desktop, { size: rem(32) }))),
            React.createElement(ChallengeContainer, null,
                React.createElement(Score, null, item.score),
                React.createElement(AvatarWrapper, null,
                    React.createElement(Avatar, { size: "medium", src: ((_b = item.logo) === null || _b === void 0 ? void 0 : _b.urlThumb) || placeholderPlace, alt: item.name || 'Team logo' })),
                React.createElement(InfoWrapper, null,
                    React.createElement(Name, null, item.name),
                    React.createElement(MembersCount, null, item.totalMembers === 1 ? (React.createElement(React.Fragment, null,
                        " ",
                        item.totalMembers,
                        " Member ")) : (React.createElement(React.Fragment, null,
                        item.totalMembers,
                        ' ',
                        item.sizeLimit && React.createElement(React.Fragment, null,
                            " / ",
                            item.sizeLimit,
                            " "),
                        " Members"))))))));
});
