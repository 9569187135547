var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint700,
});
var stick = theme('mode', {
    light: colors.space.tint500,
    dark: colors.space.tint600,
});
export var PlacesListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 1500;\n\n  position: fixed;\n  bottom: calc(", " + env(safe-area-inset-bottom, 0));\n  left: 0;\n\n  width: 100vw;\n  max-height: calc(\n    ", " - ", " + env(safe-area-inset-bottom, 0)\n  );\n\n  background-color: ", ";\n  border-radius: ", " ", " 0 0;\n  border: ", " solid ", ";\n"], ["\n  z-index: 1500;\n\n  position: fixed;\n  bottom: calc(", " + env(safe-area-inset-bottom, 0));\n  left: 0;\n\n  width: 100vw;\n  max-height: calc(\n    ", " - ", " + env(safe-area-inset-bottom, 0)\n  );\n\n  background-color: ", ";\n  border-radius: ", " ", " 0 0;\n  border: ", " solid ", ";\n"])), rem(80), function (_a) {
    var vh = _a.vh;
    return vh;
}, rem(70), background, rem(20), rem(20), rem(1), border);
export var Stick = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: grab;\n  padding: ", " 0 ", " 0;\n  display: flex;\n  justify-content: center;\n\n  &:active {\n    cursor: grabbing;\n  }\n\n  div {\n    width: ", ";\n    height: ", ";\n    background-color: ", ";\n    border-radius: ", ";\n  }\n"], ["\n  cursor: grab;\n  padding: ", " 0 ", " 0;\n  display: flex;\n  justify-content: center;\n\n  &:active {\n    cursor: grabbing;\n  }\n\n  div {\n    width: ", ";\n    height: ", ";\n    background-color: ", ";\n    border-radius: ", ";\n  }\n"])), rem(12), rem(30), rem(36), rem(5), stick, rem(2.5));
export var PlacesWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow-y: scroll;\n  padding: 0 ", ";\n  max-height: calc(\n    ", " - ", " + env(safe-area-inset-bottom, 0)\n  );\n\n  a {\n    text-decoration: none;\n  }\n"], ["\n  overflow-y: scroll;\n  padding: 0 ", ";\n  max-height: calc(\n    ", " - ", " + env(safe-area-inset-bottom, 0)\n  );\n\n  a {\n    text-decoration: none;\n  }\n"])), rem(20), function (_a) {
    var vh = _a.vh;
    return vh;
}, rem(270));
export var PaginationWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"])), rem(20));
export var List = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
