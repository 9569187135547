var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n\n  .ant-list-item {\n    align-items: initial;\n    border-bottom: 0 !important;\n    flex-direction: column;\n    justify-content: initial;\n    padding: ", " 0;\n  }\n"], ["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n\n  .ant-list-item {\n    align-items: initial;\n    border-bottom: 0 !important;\n    flex-direction: column;\n    justify-content: initial;\n    padding: ", " 0;\n  }\n"])), rem(1), border, rem(16), rem(8));
export var Title = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n"])), fonts.weight.semibold, rem(16), rem(8));
export var Subtitle = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-transform: uppercase;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-transform: uppercase;\n"])), colors.base.primary, rem(16), fonts.weight.semibold, rem(6));
export var ListDetails = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  line-height: ", ";\n\n  div {\n    margin-left: ", ";\n  }\n\n  > div {\n    margin-left: 0;\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  line-height: ", ";\n\n  div {\n    margin-left: ", ";\n  }\n\n  > div {\n    margin-left: 0;\n  }\n"])), color, rem(14), rem(17), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
