import React, { memo } from 'react';
export var Restaurant = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M28.038 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#ff6900", style: { fill: '#ff6900' }, d: "M7.042 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.758 4.759-12.474 0-17.232s-12.474-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M21.383 14.933c0 3.159-2.56 5.719-5.716 5.719-3.159 0-5.719-2.56-5.719-5.719 0-3.156 2.56-5.715 5.719-5.715 3.156 0 5.716 2.559 5.716 5.715z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M8.79 18.748c0.055 0.603-0.059 1.225-0.324 1.509-0.253 0.272-0.755 0.311-1.074 0.014-0.26-0.242-0.402-0.683-0.36-1.107l0.514-5.444c-0.018-0.354-0.734-0.787-0.745-1.792l-0.018-2.114c-0.003-0.115 0.028-0.196 0.083-0.255 0.067-0.071 0.168-0.086 0.251-0.009 0.059 0.055 0.074 0.158 0.078 0.248l0.006 1.817c0.009 0.251 0.059 0.364 0.13 0.431 0.106 0.098 0.312 0.114 0.424-0.004 0.054-0.059 0.075-0.128 0.069-0.311l-0.014-1.999c0.005-0.172 0.025-0.241 0.070-0.288 0.066-0.070 0.203-0.076 0.297 0.012 0.059 0.055 0.074 0.135 0.081 0.294l-0.012 1.978c-0.005 0.171 0.043 0.261 0.102 0.315 0.107 0.101 0.3 0.106 0.401-0.002 0.055-0.058 0.062-0.116 0.057-0.298l0.033-2.001c-0.003-0.091 0.006-0.149 0.050-0.197 0.077-0.083 0.203-0.076 0.285 0.001 0.048 0.044 0.063 0.124 0.066 0.238l-0.002 2.183c-0.003 0.903-0.717 1.218-0.741 1.825l0.293 4.956z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M22.519 14.184l0.006-2.673c-0.005-0.936 0.083-1.634 0.481-2.093 0.317-0.368 0.805-0.359 1.096-0.029 0.17 0.192 0.191 0.398 0.194 0.855l0.008 9.821c0 0.183-0.049 0.309-0.148 0.424-0.248 0.286-0.687 0.335-0.969 0.017-0.551-0.624-0.507-1.893-0.238-3.973-0.292-0.673-0.282-0.684-0.354-1.198l-0.075-1.153z" })));
});
