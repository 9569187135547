import camelCase from 'lodash/fp/camelCase';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';
import isObjectLike from 'lodash/fp/isObjectLike';
import isPlainObject from 'lodash/fp/isPlainObject';
import map from 'lodash/fp/map';
import snakeCase from 'lodash/fp/snakeCase';
import set from 'lodash/set';
import transform from 'lodash/transform';
var createIteratee = function (converter, self) { return function (result, value, key) {
    return set(result, converter(key), isObjectLike(value) ? self(value) : value);
}; };
var curryCase = function (keyConverter) {
    return function node(element) {
        if (isArray(element))
            return map(node, element);
        if (isPlainObject(element))
            return transform(element, createIteratee(keyConverter, node));
        return element;
    };
};
var mapToCamelCase = curryCase(camelCase);
var mapToSnakeCase = curryCase(snakeCase);
var requestToSnakeCase = function (request, header) {
    if (header.disableTransform) {
        delete header.disableTransform;
        return request;
    }
    return mapToSnakeCase(request);
};
var responseToCamelCase = function (response) {
    if (isEmpty(response))
        return {};
    return mapToCamelCase(JSON.parse(response));
};
export { requestToSnakeCase, responseToCamelCase };
