var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Facebook = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M32.3992 5H6.60083C5.71686 5.00036 5.00036 5.71686 5 6.60083V32.3992C5.00036 33.2831 5.71686 33.9996 6.60083 34H19.5V22.6071H16.0336V18.4643H19.5V15.1629C19.5 11.4175 22.0977 9.37848 25.4437 9.37848C27.0445 9.37848 28.7664 9.49888 29.1665 9.55196V13.4637H26.5008C24.6805 13.4637 24.3335 14.3247 24.3335 15.5934V18.4643H28.6706L28.1042 22.6071H24.3335V34H32.3992C33.2831 33.9996 33.9996 33.2831 34 32.3992V6.60083C33.9996 5.71686 33.2831 5.00036 32.3992 5Z", fill: color })));
});
