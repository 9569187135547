export var MATCH_PLACE = 'AddNewPlace/MATCH_PLACE';
export var MATCH_PLACE_SUCCESS = 'AddNewPlace/MATCH_PLACE_SUCCESS';
export var MATCH_PLACE_FAILURE = 'AddNewPlace/MATCH_PLACE_FAILURE';
export var CLEAR_VALUES = 'AddNewPlace/CLEAR_VALUES';
export var IGNORE_SUGGESTED_PLACES = 'AddNewPlace/IGNORE_SUGGESTED_PLACES';
export var ADD_PLACE = "AddNewPlace/ADD_PLACE";
export var ADD_PLACE_FAILURE = "AddNewPlace/ADD_PLACE_FAILURE";
export var ADD_PLACE_SUCCESS = "AddNewPlace/ADD_PLACE_SUCCESS";
export var contents = {
    match: 'match',
    addNew: 'addNew',
    suggestedPlaces: 'suggestedPlaces',
};
