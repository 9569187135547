var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button as TargetButton } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var Target = styled(TargetButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-self: baseline;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  padding: ", ";\n  white-space: nowrap;\n\n  &:hover,\n  &:focus {\n    color: ", " !important;\n    text-decoration: underline;\n    border-radius: ", ";\n    height: ", ";\n  }\n"], ["\n  align-self: baseline;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  padding: ", ";\n  white-space: nowrap;\n\n  &:hover,\n  &:focus {\n    color: ", " !important;\n    text-decoration: underline;\n    border-radius: ", ";\n    height: ", ";\n  }\n"])), colors.base.white, rem(16), fonts.weight.bold, rem(8), colors.base.white, rem(5), rem(38));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  padding: ", " ", ";\n  transition: color 0.2s;\n  white-space: nowrap;\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  padding: ", " ", ";\n  transition: color 0.2s;\n  white-space: nowrap;\n\n  &:hover {\n    color: ", ";\n  }\n"])), colors.space.tint800, rem(16), fonts.weight.regular, rem(4), rem(8), colors.base.primary);
var templateObject_1, templateObject_2, templateObject_3;
