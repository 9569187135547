var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { call, put } from 'redux-saga/effects';
import { fetchContributorUserFavoriteError, fetchContributorUserFavoriteSuccess, } from '../actions';
export function fetchContributorUserFavorites(_a) {
    var response, fake, error_1, err, _b;
    var payload = _a.payload;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 6, , 10]);
                return [4 /*yield*/, call(api.users.usersFavoritesBySlug, payload)];
            case 1:
                response = _d.sent();
                if (!(response.status === 204)) return [3 /*break*/, 3];
                fake = {
                    contributionStats: [],
                    pointsSerie: [],
                    quickBadges: [],
                    rocketThreshold: 0,
                    totalFavorites: 0,
                    totalFollowed: 0,
                    totalFollowers: 0,
                    totalPlaces: 0,
                    totalPoints: 0,
                };
                return [4 /*yield*/, put(fetchContributorUserFavoriteSuccess(fake))];
            case 2:
                _d.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, put(fetchContributorUserFavoriteSuccess(response.data))];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5: return [3 /*break*/, 10];
            case 6:
                error_1 = _d.sent();
                err = error_1;
                _b = (_c = err.response) === null || _c === void 0 ? void 0 : _c.status;
                return [3 /*break*/, 7];
            case 7: return [4 /*yield*/, put(fetchContributorUserFavoriteError(err.message))];
            case 8:
                _d.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/, false];
            case 10: return [2 /*return*/];
        }
    });
}
