import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Spin } from 'components/Spin';
import makeSelectAuth from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { clearReported } from '../actions';
import { makeSelectorReported, makeSelectorReportLoading } from '../selectors';
import { ReportForm } from './ReportForm';
import { ButtonWrapper, ModalContent, Text } from './styles';
export var ReportProblem = function (_a) {
    var onSubmit = _a.onSubmit, visible = _a.visible, onClose = _a.onClose;
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var auth = useSelector(makeSelectAuth());
    var reported = useSelector(makeSelectorReported());
    var reportLoading = useSelector(makeSelectorReportLoading());
    var clearReportedCallback = useAction(clearReported);
    if (!auth.isLoading && !auth.isLoggedIn) {
        history.push(paths.signInPage, { from: location.pathname });
    }
    useEffect(function () {
        clearReportedCallback();
        return function () {
            clearReportedCallback();
        };
    }, []);
    var handleClose = function () {
        clearReportedCallback();
        onClose();
    };
    return (React.createElement(Modal, { visible: visible, onCancel: handleClose, footer: null, title: formatMessage({
            id: reported
                ? 'containers.placeDetails.reportedProblemTitle'
                : 'containers.placeDetails.reportProblemTitle',
        }) },
        React.createElement(Spin, { spinning: reportLoading }, reported ? (React.createElement(ModalContent, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: "containers.placeDetails.reportedProblemMessage" })),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: "containers.placeDetails.reportedProblemEndMessage" })),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { onClick: handleClose },
                    React.createElement(FormattedMessage, { id: "containers.placeDetails.reportedButton" }))))) : (React.createElement(ReportForm, { onSubmit: onSubmit })))));
};
