import { notification } from 'antd';
import { Button, Footer, LoadingIndicator, PageHeader, PageWrapper, SubFooter, } from 'components';
import { fetchSubFooterLinksRequest } from 'components/SubFooter/actions';
import { makeSelectSubFooterLinksSearchByTypeOfPlace } from 'components/SubFooter/selectors';
import { makeSelectUser } from 'containers/Auth/selectors';
import { JoinTeamHeader } from 'containers/ChallengeJoinTeam';
import { addCurrentUserJoinTeamClearFailure, addCurrentUserJoinTeamClearSuccess, addCurrentUserJoinTeamRequest, } from 'containers/ChallengeJoinTeam/store/joinTeam/actions';
import { makeSelectChallengeJoinTeamError, makeSelectChallengeJoinTeamHasError, makeSelectChallengeJoinTeamHasSuccess, } from 'containers/ChallengeJoinTeam/store/joinTeam/selectors';
import { fetchTeamRequest } from 'containers/ChallengeMembersTeam/store/getTeamOnChallenge/actions';
import { 
// makeSelectTeamDetailHasError,
makeSelectTeamDetailItem, makeSelectTeamDetailLoading, } from 'containers/ChallengeMembersTeam/store/getTeamOnChallenge/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import makeMyChallenges from 'containers/Profile/selectors/myChallengesSelectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import { ArrowBack } from 'icons/mono';
import isEmpty from 'lodash/isEmpty';
import { rem } from 'polished';
import React, { memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { colors } from 'styles';
import { Container, Content, Message } from './styles';
export var ChallengeJoinTeamPage = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var locale = useSelector(makeSelectLocale());
    var params = useParams();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var fetchTeam = useAction(function (payload) {
        return fetchTeamRequest(payload);
    });
    var user = useSelector(makeSelectUser());
    var myChallenges = useSelector(makeMyChallenges());
    var teamIsLoading = useSelector(makeSelectTeamDetailLoading());
    // const teamHasError = useSelector(makeSelectTeamDetailHasError());
    var team = useSelector(makeSelectTeamDetailItem());
    var fetchSubFooterLinks = useAction(fetchSubFooterLinksRequest);
    var searchByType = useSelector(makeSelectSubFooterLinksSearchByTypeOfPlace());
    var joinTeam = useAction(function (payload) {
        return addCurrentUserJoinTeamRequest(payload);
    });
    var clearJoinTeamFailure = useAction(function () {
        return addCurrentUserJoinTeamClearFailure();
    });
    var clearJoinTeamFailureCallback = function () { return clearJoinTeamFailure(); };
    var joinTeamHasError = useSelector(makeSelectChallengeJoinTeamHasError());
    var joinTeamError = useSelector(makeSelectChallengeJoinTeamError());
    var joinTeamHasSuccess = useSelector(makeSelectChallengeJoinTeamHasSuccess());
    var handleJoinTeam = function () {
        var payload = {
            challengeSlug: params.challengeSlug,
            teamSlug: params.teamSlug,
        };
        joinTeam(payload);
    };
    var clearJoinTeamSuccess = useAction(function (payload) {
        return addCurrentUserJoinTeamClearSuccess(payload);
    });
    var clearJoinTeamSuccessCallback = function () {
        var payload = {
            challengeSlug: params.challengeSlug,
            teamSlug: params.teamSlug,
        };
        clearJoinTeamSuccess(payload);
    };
    var handleGoToMemberPage = function () {
        return history.push(paths.membersTeamDetailPage
            .replace(':challengeSlug', params.challengeSlug)
            .replace(':teamSlug', params.teamSlug), {
            from: location.pathname,
        });
    };
    useEffect(function () {
        if ((user === null || user === void 0 ? void 0 : user.inChallenge) && myChallenges && myChallenges.items.length > 0) {
            var slug = myChallenges.items[0].slug;
            history.push(paths.challengeDetailPage.replace(':challengeSlug', slug), {
                from: location.pathname,
            });
        }
        else {
            var payload = {
                challengeSlug: params.challengeSlug,
                teamSlug: params.teamSlug,
            };
            fetchTeam(payload);
        }
    }, [user, myChallenges]);
    useEffect(function () {
        if (isEmpty(team) || team.slug !== params.teamSlug) {
            var payload = {
                challengeSlug: params.challengeSlug,
                teamSlug: params.teamSlug,
            };
            fetchTeam(payload);
        }
    }, [team]);
    useEffect(function () {
        if (isEmpty(searchByType)) {
            fetchSubFooterLinks();
        }
    }, [searchByType, fetchSubFooterLinks]);
    useEffect(function () {
        if (joinTeamHasError) {
            notification.error({
                message: joinTeamError,
                onClose: clearJoinTeamFailureCallback,
            });
        }
        else if (joinTeamHasSuccess) {
            notification.success({
                message: formatMessage({
                    id: 'pages.challengesMembersTeam.userJoinedTeam',
                }),
                onClose: clearJoinTeamSuccessCallback,
                duration: 2.5,
            });
        }
    }, [joinTeamHasError, joinTeamHasSuccess]);
    if (teamIsLoading || isEmpty(team)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageWrapper, null,
                React.createElement(Container, null,
                    React.createElement(PageHeader, { title: formatMessage({ id: 'pages.challengesMembersTeam.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return handleGoToMemberPage(); }, sticky: true }),
                    React.createElement(Content, null,
                        React.createElement(LoadingIndicator, null)),
                    !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                        React.createElement(SubFooter, null),
                        React.createElement(Footer, null)))))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, team.name),
            React.createElement("meta", { name: "description", content: team.description }),
            React.createElement("meta", { property: "og:title", content: team.name }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: team.description }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(Container, null,
                React.createElement(PageHeader, { title: formatMessage({ id: 'pages.challengesMembersTeam.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return handleGoToMemberPage(); }, sticky: true }),
                React.createElement(Content, null,
                    React.createElement(JoinTeamHeader, { title: team.name, logo: team.logo, cover: team.cover }),
                    React.createElement(Message, null, formatMessage({
                        id: 'pages.challengeJoinTeamPage.warning',
                    })),
                    React.createElement(Button, { variant: "secondary", onClick: handleJoinTeam }, formatMessage({
                        id: 'pages.challengeJoinTeamPage.buttonText',
                    })))),
            !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                React.createElement(SubFooter, null),
                React.createElement(Footer, null))))));
});
