import React, { memo } from 'react';
export var Library = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M27.733 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#c80000", style: { fill: '#c80000' }, d: "M7.346 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.759 4.759-12.474 0-17.232s-12.473-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M7.924 10.971h-1.524v8.838h1.524v-8.838z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M19.449 10.911l-1.865-0.139-0.67 8.963 1.865 0.139 0.67-8.963z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M21.3 10.176l-1.699-0.056-0.322 9.759 1.699 0.056 0.322-9.759z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M10.667 12.19h-2.743v7.619h2.743v-7.619z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M23.162 10.971h-1.829v8.838h1.829v-8.838z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M25.295 10.057h-1.524v9.752h1.524v-9.752z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M12.376 9.922l-1.785 0.217 1.185 9.745 1.785-0.217-1.185-9.745z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M14.319 10.825l-1.72 0.521 2.609 8.609 1.72-0.521-2.609-8.609z" })));
});
