import { createSelector } from 'reselect';
import { myContributionsInitialState } from '../reducers/contributionsReducer';
export var selectMyContributionsDomain = function (state) {
    return state.myContributions || myContributionsInitialState;
};
export var makeSelectMyContributionsLoading = function () {
    return createSelector(selectMyContributionsDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectMyContributionsHasError = function () {
    return createSelector(selectMyContributionsDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectMyContributionsError = function () {
    return createSelector(selectMyContributionsDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectMyContributionsPages = function () {
    return createSelector(selectMyContributionsDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectMyContributionsCurrentPage = function () {
    return createSelector(selectMyContributionsDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectMyContributionsTotal = function () {
    return createSelector(selectMyContributionsDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectMyContributionsItems = function () {
    return createSelector(selectMyContributionsDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeMyContributions = function () {
    return createSelector(selectMyContributionsDomain, function (subState) { return subState; });
};
export default makeMyContributions;
