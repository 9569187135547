import { Col, Row } from 'antd';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useGeolocation, useSelector } from 'hooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Items } from './Items';
import { makeSelectSubFooterLinksFindPlacesInEurope, makeSelectSubFooterLinksFindPlacesInFrance, makeSelectSubFooterLinksFindPlacesInWorld, makeSelectSubFooterLinksLoading, makeSelectSubFooterLinksSearchByTypeOfPlace, } from './selectors';
import { SubFooterContainer, Title } from './styles';
export var SubFooter = memo(function () {
    var geolocation = useGeolocation();
    var searchByTypeOfPlace = useSelector(makeSelectSubFooterLinksSearchByTypeOfPlace());
    var findPlacesInFrance = useSelector(makeSelectSubFooterLinksFindPlacesInFrance());
    var findPlacesInEurope = useSelector(makeSelectSubFooterLinksFindPlacesInEurope());
    var findPlacesInTheWorld = useSelector(makeSelectSubFooterLinksFindPlacesInWorld());
    var isLoading = useSelector(makeSelectSubFooterLinksLoading());
    return (React.createElement(SubFooterContainer, null,
        React.createElement(Row, { gutter: { xs: 8, sm: 16, md: 24, lg: 32 } },
            React.createElement(Col, { className: "gutter-row", span: 6 },
                React.createElement(Title, null,
                    React.createElement(FormattedMessage, { id: "components.subFooter.searchByType.title" })),
                isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(Items, { items: searchByTypeOfPlace === null || searchByTypeOfPlace === void 0 ? void 0 : searchByTypeOfPlace.items, geolocation: geolocation }))),
            React.createElement(Col, { className: "gutter-row", span: 6 },
                React.createElement(Title, null,
                    React.createElement(FormattedMessage, { id: "components.subFooter.findPlacesInFrance.title" })),
                isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(Items, { items: findPlacesInFrance === null || findPlacesInFrance === void 0 ? void 0 : findPlacesInFrance.items }))),
            React.createElement(Col, { className: "gutter-row", span: 6 },
                React.createElement(Title, null,
                    React.createElement(FormattedMessage, { id: "components.subFooter.findPlacesInEurope.title" })),
                isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(Items, { items: findPlacesInEurope === null || findPlacesInEurope === void 0 ? void 0 : findPlacesInEurope.items }))),
            React.createElement(Col, { className: "gutter-row", span: 6 },
                React.createElement(Title, null,
                    React.createElement(FormattedMessage, { id: "components.subFooter.findPlacesInTheWorld.title" })),
                isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(Items, { items: findPlacesInTheWorld === null || findPlacesInTheWorld === void 0 ? void 0 : findPlacesInTheWorld.items }))))));
});
