var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var TrophyPurple = memo(function (props) { return (React.createElement("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { opacity: ".8", fillRule: "evenodd", clipRule: "evenodd", d: "M27.617 6.224v1.993h5.912v3.133a7.592 7.592 0 01-6.12 7.448c-.734 3.136-3.411 5.53-6.69 5.83v4.1h.688c2.57 0 4.66 2.09 4.66 4.66v.92H13.974v-.92c0-2.57 2.09-4.66 4.66-4.66h.689v-4.1c-3.28-.3-5.958-2.694-6.693-5.83a7.592 7.592 0 01-6.118-7.448V8.216h5.912V6.224h15.193zM7.907 9.612h4.517v7.455c0 .05.004.098.007.146.002.034.005.069.006.104a6.196 6.196 0 01-4.53-5.967V9.612zm24.226 1.738a6.196 6.196 0 01-4.53 5.967l.007-.106c.003-.048.007-.095.007-.144V9.612h4.516v1.738z", fill: "#773BB3" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.276 13.433h13.488V6.922H13.276v6.511z", fill: "#fff", fillOpacity: ".3" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.424 9.612H7.907v1.738a6.196 6.196 0 004.53 5.967 2.717 2.717 0 00-.006-.104c-.003-.048-.007-.097-.007-.146V9.612zm15.18 7.705a6.196 6.196 0 004.53-5.967V9.612h-4.517v7.455c0 .049-.004.096-.007.144-.003.035-.005.07-.006.106zm-1.383-.25c0 3.42-2.782 6.2-6.2 6.2a6.207 6.207 0 01-6.202-6.2V7.62h12.402v9.448zm-1.584 15.846h-9.234a3.27 3.27 0 013.23-2.79h2.774c1.639 0 3 1.214 3.23 2.79zm2.98-24.696V6.224H12.424v1.993H6.512v3.133a7.592 7.592 0 006.118 7.448c.735 3.136 3.413 5.53 6.693 5.83v4.1h-.69c-2.569 0-4.659 2.09-4.659 4.66v.92h12.093v-.92c0-2.57-2.09-4.66-4.66-4.66h-.689v-4.1c3.28-.3 5.957-2.694 6.692-5.83a7.592 7.592 0 006.119-7.448V8.216h-5.912z", fill: "#552A80" }))); });
