import { SUBSCRIBE_USER_FAILURE, SUBSCRIBE_USER_REQUEST, SUBSCRIBE_USER_SUCCESS, } from './constants';
export var subscribeUserRequest = function (payload) { return ({
    type: SUBSCRIBE_USER_REQUEST,
    payload: payload,
}); };
export var subscribeUserSuccess = function () { return ({
    type: SUBSCRIBE_USER_SUCCESS,
}); };
export var subscribeUserFailure = function (payload) { return ({
    type: SUBSCRIBE_USER_FAILURE,
    payload: payload,
}); };
