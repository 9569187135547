import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector as useReduxSelector, } from 'react-redux';
import { useLocation } from 'react-router-dom';
export var useAction = function (action) {
    var dispatch = useDispatch();
    return useCallback(function (prop) { return dispatch(action(prop)); }, [action, dispatch]);
};
export var useSelector = function (selector) { return useReduxSelector(selector, shallowEqual); };
export var usePush = function (path) { return useAction(function () { return push(path); }); };
export var useQuery = function () { return new URLSearchParams(useLocation().search); };
export { useGeolocation } from './useGeolocations';
