import { Input } from 'antd';
import { Search as SearchField } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { Search } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { SearchFieldWrapper } from './styles';
export var SearchBarMobile = memo(function (_a) {
    var placeholder = _a.placeholder;
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var inputPlaceholder = placeholder || formatMessage({ id: 'containers.homeSearch.placeholder' });
    var handleAutocompleteSelect = function (_a, place) {
        var latitude = _a.latitude, longitude = _a.longitude;
        if (place === null || place === void 0 ? void 0 : place.isplace) {
            history.push(paths.placeRedirectPage.replace(':placeId', place.id));
            return;
        }
        history.push(paths.explore + "?lat=" + latitude + "&lng=" + longitude);
    };
    var handleEmptySearch = function () { return history.push(paths.explore); };
    return (React.createElement(SearchFieldWrapper, null,
        React.createElement(SearchField, { onSelect: handleAutocompleteSelect, onEmptySearch: handleEmptySearch },
            React.createElement(Input.Search, { placeholder: inputPlaceholder, enterButton: React.createElement("button", { type: "submit" },
                    React.createElement(Search, { size: rem(18) })) }))));
});
