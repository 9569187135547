import { createSelector } from 'reselect';
import { myProfileInitialState } from '../reducers/myProfileReducer';
export var selectMyProfileDomain = function (state) {
    return state.myProfile || myProfileInitialState;
};
export var makeSelectMyProfileLoading = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectMyProfileHasError = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectMyProfileError = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectMyProfileContributionStats = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var contributionStats = _a.contributionStats;
        return contributionStats;
    });
};
export var makeSelectMyProfilePointsSerie = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var pointsSerie = _a.pointsSerie;
        return pointsSerie;
    });
};
export var makeSelectMyProfileQuickBadges = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var quickBadges = _a.quickBadges;
        return quickBadges;
    });
};
export var makeSelectMyProfileRocketThreshold = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var rocketThreshold = _a.rocketThreshold;
        return rocketThreshold;
    });
};
export var makeSelectMyProfileTotalFavorites = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var totalFavorites = _a.totalFavorites;
        return totalFavorites;
    });
};
export var makeSelectMyProfileTotalFollowed = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var totalFollowed = _a.totalFollowed;
        return totalFollowed;
    });
};
export var makeSelectMyProfileTotalFollowers = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var totalFollowers = _a.totalFollowers;
        return totalFollowers;
    });
};
export var makeSelectMyProfileTotalPlaces = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var totalPlaces = _a.totalPlaces;
        return totalPlaces;
    });
};
export var makeSelectMyProfileTotalPoints = function () {
    return createSelector(selectMyProfileDomain, function (_a) {
        var totalPoints = _a.totalPoints;
        return totalPoints;
    });
};
export var makeMyProfile = function () {
    return createSelector(selectMyProfileDomain, function (subState) { return subState; });
};
export default makeMyProfile;
