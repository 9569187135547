var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { rem, rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
var borderList = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var borderScore = theme('mode', {
    light: colors.space.tint400,
    dark: colors.base.white,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var RankItemContainer = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  border-bottom: ", " solid ", ";\n  padding: ", " 0;\n  display: grid;\n  grid-template-columns: ", " 1fr ", ";\n  grid-column-gap: ", ";\n  text-decoration: none;\n\n  &:focus {\n    outline: 0 !important;\n\n    ", "\n  }\n"], ["\n  align-items: center;\n  border-bottom: ", " solid ", ";\n  padding: ", " 0;\n  display: grid;\n  grid-template-columns: ", " 1fr ", ";\n  grid-column-gap: ", ";\n  text-decoration: none;\n\n  &:focus {\n    outline: 0 !important;\n\n    ",
    "\n  }\n"])), rem(1), borderList, rem(10), rem(32), rem(50), rem(16), ifProp('isKeyboardMode', css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        position: relative;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: 100%;\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      "], ["\n        position: relative;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: 100%;\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      "])), border, rem(12), rem(10), rem(-10))));
export var Rank = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  width: 100%;\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: ", ";\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  width: 100%;\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: ", ";\n"])), colors.base.secondary, rem(10), colors.base.white, rem(20), fonts.weight.semibold, rem(32), rem(16));
export var UserWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n"], ["\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n"])));
export var UserInfo = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n  overflow: hidden;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n  overflow: hidden;\n"])), rem(16));
export var UserName = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"])), color, rem(14), fonts.weight.semibold, rem(4));
export var UserSlug = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"])), color, rem(13));
export var Score = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background-color: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1;\n  margin-left: auto;\n  padding: ", " ", ";\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1;\n  margin-left: auto;\n  padding: ", " ", ";\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), colors.space.tint200, rem(1), borderScore, rem(15), colors.space.tint600, rem(14), fonts.weight.semibold, rem(4), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
