import produce from 'immer';
import { CONTRIBUTOR_USER_ACCOUNT_FAILURE, CONTRIBUTOR_USER_ACCOUNT_REQUEST, CONTRIBUTOR_USER_ACCOUNT_SUCCESS, } from '../constants';
export var contributorUserAccountInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    user: undefined,
};
export var contributorUserAccountReducer = produce(function (draft, action) {
    switch (action.type) {
        case CONTRIBUTOR_USER_ACCOUNT_REQUEST:
            draft.loading = true;
            break;
        case CONTRIBUTOR_USER_ACCOUNT_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case CONTRIBUTOR_USER_ACCOUNT_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.user = action.payload;
            break;
        default:
    }
}, contributorUserAccountInitialState);
