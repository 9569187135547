import produce from 'immer';
import { SIGN_UP_FAILURE, SIGN_UP_REQUEST, SIGN_UP_SUCCESS } from './constants';
export var initialState = {
    loading: false,
    hasError: false,
    error: undefined,
};
var signupEmailReducer = produce(function (draft, action) {
    switch (action.type) {
        case SIGN_UP_REQUEST:
            draft.loading = true;
            break;
        case SIGN_UP_SUCCESS:
            return initialState;
        case SIGN_UP_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        default:
            break;
    }
}, initialState);
export default signupEmailReducer;
