export default {
    components: {
        podium: {
            points: 'pts',
        },
        userReview: {
            datePattern: "MMMM d',' yyyy",
        },
        ellipsis: {
            more: 'more',
            less: 'less',
        },
        loadingIndicator: {
            loading: 'Loading...',
        },
        nav: {
            explore: 'Explore',
            challenge: 'Challenges',
            donation: 'Donate',
            aboutUs: 'About us',
            myProfile: 'My profile',
            changeFontSize: 'Change font size',
            darkMode: 'Change contrast',
            signIn: 'Sign in',
            logout: 'Log out',
            mailSystem: 'Mail system',
            homePage: 'Home',
        },
        facebookButton: {
            continueWithFacebook: 'Continue with Facebook',
        },
        usersProfilesField: {
            label: 'Profile type',
            select: 'Select an option',
            placeholder: 'Select',
        },
        passwordField: {
            label: 'Password',
            placeholder: 'Insert your password',
            showPassword: 'Show password',
            hidePassword: 'Hide password',
            forgotPassword: 'Forgotten your password?',
        },
        emailField: {
            label: 'Your email',
            placeholder: 'Insert your email',
        },
        searchField: {
            button: 'Search',
            placeholder: 'Find accessible locations',
        },
        profileMenu: {
            profile: 'My profile',
            mailSystem: 'Mail system',
            logout: 'Log out',
        },
        passwordScore: {
            tips: '{tipsHighlight} Password must meet the following requirements:',
            tipsHighlight: 'Tip:',
            tipOne: '1- at least one capital letter',
            tipTwo: '2- at least one number',
            tipThree: '3- at least 8 characters',
            veryWeak: 'Very weak',
            weak: 'Weak',
            fair: 'Fair',
            strong: 'Strong',
        },
        placesAutocomplete: {
            googlePlaceIdLabel: 'My address',
            googlePlaceIdPlaceholder: 'Insert your address',
        },
        aboutJaccede: {
            container: {
                definition: {
                    headline: 'Jaccedeur, Jaccedeuse [ʒaksɛdœʁ/øz]',
                    description: "\n            Your name, age and gender do not matter Jaccede members might be users\n            or contributors. They have a positive outlook and work closely with\n            their counterparts around the world. By adding to and updating the\n            Jaccede guide, they provide information for everyone and raise\n            awareness about the importance of accessibility.\n          ",
                },
                headline: 'What is \n Jaccede',
            },
            headline: 'What is Jaccede?',
            subtitle: 'According to mobility, where can i access?',
            firstParagraph: "\n        Jaccede is an interactive platform (website and mobile app). Anyone can\n        add details of places open to the public. This helps people with reduced\n        mobility identify establishments that can meet their accessibility needs.\n      ",
            secondParagraph: "\n        A lack of information on accessibility is one of the greatest barriers\n        people with reduced mobility face. Sharing information on Jaccede\n        therefore has an immediate social impact.\n      ",
            thirdParagraph: "\n        Jaccede also organises events all year round to enable members to meet,\n        raise awareness among the general public and develop the guide. Hundreds\n        of gatherings have already taken place in France and overseas!\n      ",
        },
        emailNotConfirmed: {
            message: 'Please confirm your email',
        },
        subscribeButton: {
            subscribe: 'Subscribe',
            unsubscribe: 'Unsubscribe',
        },
        subFooter: {
            searchByType: {
                title: 'Search by type',
            },
            findPlacesInFrance: {
                title: 'Find places in France',
            },
            findPlacesInEurope: {
                title: 'Find places in Europe',
            },
            findPlacesInTheWorld: {
                title: 'Find places in the world',
            },
        },
        bottomMenu: {
            languages: {
                english: 'English (UK)',
                french: 'Français',
                spanish: 'Español',
                italian: 'Italiano',
                german: 'Deutsche',
            },
            submenu: {
                fontSize: 'Font Size',
                rightHand: 'Right Hand',
                leftHand: 'Left Hand',
                defaultLanguage: 'Default Language',
                darkMode: 'High Contrast',
            },
            challenge: 'Challenges',
            explore: 'Explore',
            donate: 'Donate',
            settings: 'Settings',
            menu: 'Menu',
        },
        footer: {
            copyRight: '2020 copyright. Jaccede. All Rights reserved.',
            privacyTerms: 'Privacy · Terms',
            sitemap: 'Sitemap',
        },
        addPlaceToMap: {
            button: 'Add place to map',
            altImage: 'Man taking a woman in a wheelchair',
        },
        notificationMenu: {
            title: 'Notifications',
            empty: 'No notifications',
        },
        keyboardMode: {
            tabButton: 'Go to content',
            escButton: 'Tap ESCAPE to quit keyboard mode',
        },
        alertNewSite: {
            message: 'Welcome to the beta version of the new Jaccede platform! Do not hesitate to send us your',
            email: 'suggestions',
        },
    },
    containers: {
        app: {
            title: 'Jaccede',
            oneSignalWelcomeNotification: 'Thank you for your subscription',
            oneSignalActionMessage: 'Our souvenirs are sent to you by Jaccede.',
            oneSignalAcceptButtonText: 'Accept',
            oneSignalCancelButtonText: 'Refuse',
            cookieConsentText: 'This website uses cookies to enhance the user experience.',
            cookieConsentButtonOk: 'I understand',
            cookieConsentButtonDecline: 'I decline',
        },
        signInEmail: {
            emailFormat: 'Incorrect email format',
            emailRequired: 'Email is required',
            signInButton: 'Sign in',
            passwordRequired: 'Password is required',
            invalidSignin: 'Invalid email or password',
        },
        signUpEmail: {
            header: 'or by email',
            emailLabel: 'Your email',
            emailPlaceholder: 'Insert your email',
            emailRequired: 'Email is required',
            pseudoLabel: 'Username',
            pseudoPlaceholder: 'Insert your username',
            pseudoRequired: 'Username is required',
            acceptsNewsletterLabel: 'I would like to receive the Jaccede monthly newsletter',
            termsAndCoditions: 'By creating an account, you agree to the ',
            termsAndCoditionsLink: 'general terms & conditions',
            registrationButton: 'Registration',
            profileIdRequired: 'Profile type is required',
            googlePlaceIdRequired: 'My address is required',
            passwordRequired: 'Password is required',
            passwordStrengthRegex: 'This field must have at least one capital letter, at least one number and at least 8 characters',
            addressInfo: 'Enter your address. It will then appear in the drop-down menu. Some addresses are not always recognized. In this case, enter the nearest address.',
        },
        home: {
            lastPlacesUpdates: {
                title: 'Last places updated',
            },
            signUpNewsletter: {
                title: 'Sign up for our newsletter',
                submitButton: 'Subscribe',
                emailRequired: 'Email required',
                optinSuccess: 'Your email was subscribed with successful',
                optinFailure: 'An error occurred when subscribe on our newsletter',
            },
            socialNetworks: 'Follow us:',
        },
        contribute: {
            title: "\n        Contribute to\n        Jaccede!\n      ",
            collaborateParagraph: "\n        Becoming a member is the first way you can show your support for\n        Jaccede. Your membership will help to promote the cause of people with\n        reduced mobility and also lend moral and financial support.\n      ",
            collaborateAlt: 'Person in wheelchair',
            collaborateImage: 'Colaborate with us!',
            collaborateTitle: 'Becoming a member',
            collaborateButtonText: 'Collaborate',
            donateAlt: 'Person in wheelchair',
            donateImage: 'Help us achieve our common goals!',
            donateTitle: 'Make a donation',
            donateButtonText: 'Donate now!',
            donateParagraph: "\n        Your interest in helping to improve the lives of people with reduced\n        mobility brought you to Jaccede. Help us to achieve this goal, and to\n        take action to raise awareness among the general public on the\n        benefits of accessibility.\n      ",
        },
        homeHeader: {
            title: "\n        Find the places that\n        are accessible to you\n      ",
            placeholder: 'Find accessible locations',
            seeMore: 'See more',
        },
        homeSearch: {
            button: 'Search',
            placeholder: 'Type something',
        },
        homeLearnMore: {
            title: "\n        Learn More\n        About Us\n      ",
            desktopSubtitle: 'Watch to see how Jaccede works towards accessibility for everyone, by everyone.',
            mobileSubtitle: 'Jaccede is an interactive platform where anyone can add details of places open to the public.',
            videoAlt: 'About us video thumbnail',
            blockquoteTitle: 'What is Jaccede?',
            blockquoteParagraph: "\n        Watch to see how Jaccede works towards accessibility for everyone, by\n        everyone.\n      ",
        },
        homeBrowsePlaces: {
            title: "\n        Browse Places\n        by Category\n      ",
            subtitle: 'Searching for a particular type of place? Public places and their accessibility details can be viewed by category with Jaccede.',
            button: 'Explore places',
            dogAndOwnerAlt: 'Guide dog with owner on a park',
            wheelchairHandAlt: 'Person on a wheelchair',
            guideDogsAlt: 'Guide dogs on leashes',
            peopleSmilingAlt: 'People chatting',
        },
        homeMostActive: {
            title: "\n        Most active\n        of the week\n      ",
            viewAllRank: 'View all ranks',
        },
        homeLatestNews: {
            title: 'Jaccede News',
            subtitle: 'Keep up to date with the Jaccede community and association by browsing through our blog!',
            buttonText: 'View Blog',
            note: 'only in french',
        },
        oAuth: {
            errorPageTitle: 'An error occurred',
            completeAccountTitle: 'Complete your account',
            successNotification: 'You are athenticated with successful',
        },
        resendEmailActivation: {
            header: 'Resend email activation',
            submitButton: 'Resend',
            emailRequired: 'Email is required',
            emailformat: 'Incorrect email format',
        },
        profile: {
            achievements: {
                header: 'Achievements',
            },
            user: {
                editMyProfile: {
                    mobile: 'Edit',
                    desktop: 'Edit my profile',
                },
                pointsInfo: {
                    criterion: '1 criterion edited once = 1 point',
                    photo: '1 photo added per place = 4 points',
                    comment: '1 comment per place = 2 points',
                    place: '1 missing place added = 1 point',
                },
                subscriptions: 'Subscriptions',
                subscribers: 'Subscribers',
                points: 'Points',
                sendMessage: 'Send a message',
                readMore: 'more',
                medal200: '200 points badges',
                medal1000: '1000 points badges',
                medal5000: '5000 points badges',
                medal10000: '10000 points badges',
                medal25000: '25000 points badges',
                medalCriterion: 'Criteria badge',
                medalPhoto: 'Photo badge',
                medalComment: 'Comment badge',
                medalPlace: 'Missing place badge',
                chatModalText: 'The chat will soon be available!',
            },
            activities: {
                header: 'Activities in the past few weeks ago',
            },
            myActiveChallenges: {
                header: 'My active challenge',
                challengesEmptyList: 'User does not participate in any challenge yet',
            },
            moreAbout: {
                header: 'More about',
                contributions: 'Contributions',
                favorites: 'Favorites',
                contributionEmptyList: 'No contributions at the moment',
                favoriteEmptyList: 'No favorites at the moment',
            },
            nextAchievements: {
                medals: {
                    medal200: {
                        title: '200 points badges',
                        description: 'To win this badge you have to earn 200 points',
                    },
                    medal1000: {
                        title: '1000 points badges',
                        description: 'To win this badge you have to earn 1000 points',
                    },
                    medal5000: {
                        title: '5000 points badges',
                        description: 'To win this badge you have to earn 5000 points',
                    },
                    medal10000: {
                        title: '10000 points badges',
                        description: 'To win this badge you have to earn 10000 points',
                    },
                    medal25000: {
                        title: '25000 points badges',
                        description: 'To win this badge you have to earn 25000 points',
                    },
                    medalCriterion: {
                        title: 'Criteria badge',
                        description: 'To win this badge you need to select 5000 accessibility criteria that places have',
                    },
                    medalPhoto: {
                        title: 'Photo badge',
                        description: 'To win this badge you need to add 1000 photos of places',
                    },
                    medalComment: {
                        title: 'Comment badge',
                        description: 'To win this badge you need to make 1000 comments about the places',
                    },
                    medalPlace: {
                        title: 'Missing place badge',
                        description: "To win this badge you need to add 500 places that did not exist on Jaccede's website",
                    },
                },
                header: 'Next point achievements',
            },
        },
        forgotPasswordPage: {
            emailLabel: 'Email',
            submitButton: 'Request link to reset password',
            notReset: 'If you cannot reset your password,',
            contatUs: 'contact us.',
            emailFormat: 'Incorrect email format',
            emailRequired: 'Email required',
            verifyEmail: 'We have sent instructions on how to reset your password by email',
            success: 'Done!!',
            error: 'Error!!',
            passwordIsExpired: 'The link has expired. Please make a new request',
        },
        changePasswordPage: {
            newPasswordLabel: 'New password',
            newPasswordRequired: 'New password is required',
            confirmPasswordRequired: 'Confirm new password is required',
            newPasswordStrengthRegex: 'This field must have at least one capital letter, at least one number and at least 8 characters',
            confirmPasswordValidation: 'Confirm new password must match as the new password',
            confirmPasswordLabel: 'Confirm new password',
            submitButton: 'Change',
            success: 'Success!!',
            successfully: 'Password changed successfully!',
            error: 'Error!!',
            errorMessage: 'Something went wrong',
            checkingPasswordIsLoading: 'We are checking the validity of the token',
            linkForgotPassword: 'clicking here',
        },
        subscribers: {
            subscriptions: 'Subscriptions',
            subscribers: 'Subscribers',
            loggedUserNoSubscribers: 'You have no subscribers!',
            loggedUserNoSubscriptions: 'You have no subscriptions!',
            noSubscribers: 'No subscribers found!',
            noSubscriptions: 'No subscriptions found!',
        },
        globalScoreboard: {
            tabWeek: {
                title: 'Weekly',
                footerTitleWithPoints: 'These are your points this week',
                footerTitleWithoutPoints: "You didn't score this week",
            },
            tabMonth: {
                title: 'Monthly',
                footerTitleWithPoints: 'These are your points this month',
                footerTitleWithoutPoints: "You didn't score this month",
            },
            tabAll: {
                title: 'All the time',
                footerTitleWithPoints: 'These are your points',
                footerTitleWithoutPoints: "You didn't score yet",
            },
            headerTitle: 'Global Scoreboard',
            emptyList: "\n        We don't have scores in the current month, what do you think about changing that? start making the world more accessible now!\n      ",
        },
        editMyProfile: {
            identification: {
                fieldDescription: 'Identification',
                fieldInformation: 'Key information about your account',
            },
            location: {
                fieldDescription: 'Location',
                fieldInformation: 'I want to specify my location to receive more relevant information',
            },
            message: {
                fieldDescription: 'Message',
                fieldInformation: 'Visible to everyone, this short message is addressed to Jaccede’s community',
            },
            editOptions: {
                identification: 'Identification',
                identificationMessage: 'Edit your public profile so people can know a little more about you',
                name: 'NAME & USERNAME',
                editPhoto: 'Edit photo',
                editPhotoMessage: 'Edit your photo that will be used in the Jaccede community',
                profileType: 'PROFILE TYPE',
                profileMessage: 'PROFILE MESSAGE',
                contactInformation: 'Contact Information',
                email: 'EMAIL',
                telephone: 'TELEPHONE',
                password: 'PASSWORD',
                location: 'Location',
                myAddress: 'MY ADDRESS',
                editPhotoOverlay: 'Edit',
            },
            editPhoto: {
                uploadError: 'Your photo could not be uploaded',
                modalTitle: 'Change my photo',
                resolutionRule: 'Minimum resolution of ',
                sizeRule: 'Size below or equal to ',
                extensionsRule: 'Authorized extensions: ',
            },
            editName: {
                name: 'First name',
                title: 'Name & Username',
                lastName: 'Last name',
                username: 'Username',
                usernameRequiredMessage: 'Username is required',
                updateName: 'Update name & username',
            },
            editEmail: {
                title: 'Email',
                email: 'Email',
                emailRequiredMessage: 'Email is required',
                invalidEmailMessage: 'Incorrect email format',
                updateEmail: 'Update email',
            },
            editTelephone: {
                title: 'Telephone',
                telephone: 'Telephone',
                updateTelephone: 'Update telephone',
            },
            editProfileMessage: {
                title: 'Profile message',
                profileMessage: 'Profile message',
                maxSizeProfileMessage: 'Maximum of 500 characters',
                updateProfileMessage: 'Update profile message',
            },
            editProfileType: {
                title: 'Profile type',
                profileTypeRequiredMessage: 'Profile type is required',
                updateProfileType: 'Update profile type',
            },
            editPassword: {
                title: 'Password',
                password: 'New password',
                confirmPassword: 'Confirm new password',
                passwordRequiredMessage: 'New password is required',
                confirmPasswordRequiredMessage: 'Confirm new password is required',
                passwordsNotMatching: 'New password and confirm new password should match',
                changePassword: 'Change password',
                passwordPolicy: 'This field must have at least one capital letter, at least one number and at least 8 characters',
            },
            editLocation: {
                title: 'My address',
                myAddress: 'My address',
                acceptVisibility: "I accept to be visible by other members of the Jaccede's community",
                updateMyAddress: 'Update my address',
                shareLocalization: "I accept to be visible by other members of Jaccede's community",
                invalidAddress: 'Invalid address.',
            },
            acceptTerms: {
                contact: "I accept to be contacted by other members of Jaccede's community",
                newsletter: 'I would like to receive the monthly newsletter',
                infoNewsletter: 'We will only use your email address to send you our monthly newsletter and information concerning Jaccede\'s activities. You can unsubscribe at any time by clicking the "Unsubscribe" link in the newsletter.',
            },
            pageTitle: 'Edit my profile',
            passwordUpdateSuccess: 'Password changed successfully!',
            photoUploadSuccess: 'Photo uploaded successfully!',
            defaultAvatarUpdatedSuccess: 'Photo uploaded successfully!',
            photoDeleteSuccess: 'Photo deleted successfully!',
            nameUpdateSuccess: 'Name & Username updated successfully!',
            profileMessageUpdateSuccess: 'Profile message updated successfully!',
            profileTypeUpdateSuccess: 'Profile type updated successfully!',
            telephoneUpdateSuccess: 'Telephone updated successfully!',
            locationUpdateSuccess: 'My address updated successfully!',
            termsUpdateSuccess: 'Terms updated successfully!',
            success: 'Success',
            error: 'Error',
        },
        placeDetails: {
            report: {
                title: 'Report photo as spam',
                label: 'Add a comment',
                placeholder: 'Comment',
                button: 'Continue',
                required: 'Message is required.',
                reportPhotoButtonTitle: 'Report photo',
            },
            reported: {
                title: 'Photo reported',
                message: 'This photo has been reported as inappropriate.\nThank you. We will take your comments into account in the shortest time.\nThank you for your vigilance.',
                button: 'Close',
            },
            reportPhoto: {
                title: 'Report as spam photo.',
                label: 'Add a comment',
                placeholder: 'Comment',
                button: 'Continue',
                required: 'Message is required.',
            },
            reportedPhoto: {
                title: 'Photo reported',
                alreadyReported: 'This photo has already been reported',
                message: 'This photo has been reported as inappropriate.\nThank you. We will take your comments into account in the shortest time.\nThank you for your vigilance.',
                button: 'Close',
            },
            suggestChanges: 'Suggest changes',
            saveChangesButton: 'Save changes',
            evaluateTitle: 'Evaluate and rate',
            evaluateTagline: 'Earn points by rating locations',
            addReviewButton: 'Add a review',
            ratings: 'Ratings',
            addPhotos: 'Add photos',
            addMorePhotos: 'Add more photos',
            reviews: 'Reviews',
            moreReviews: 'More reviews',
            yourReviews: 'Your review',
            lastChange: 'Last changed by',
            lastChange2: 'the',
            searchPlacesAround: 'Search for places close by',
            reportProblemTitle: 'Report this place profile',
            reportedProblemTitle: 'The place has been reported.',
            reportedProblemMessage: 'Thank you. We will take your comments into account in the shortest time.',
            reportedProblemEndMessage: 'Thank you for your vigilance.',
            reportedButton: 'Close',
            reportReviewTitle: 'Report as spam comment',
            alreadyReportedTitle: 'Comment reported',
            reportedReviewTitle: 'The comment has been reported.',
            error: 'Error',
            success: 'Success!!',
            closed: 'Permanently closed',
            suggestModification: 'Suggest a modification on Google maps',
        },
        placePhotos: {
            report: {
                title: 'Report as spam photo.',
                label: 'Add a comment',
                placeholder: 'Comment',
                button: 'Continue',
                required: 'Message is required.',
            },
            reported: {
                title: 'Photo reported',
                alreadyReported: 'This photo has already been reported',
                message: 'This photo has been reported as inappropriate.\nThank you. We will take your comments into account in the shortest time.\nThank you for your vigilance.',
                button: 'Close',
            },
            header: 'Photos of this place',
            addPhoto: 'Add more photo',
            successTitle: 'Success',
            photoSuccess: 'Photo add successfully!',
            validationTitle: 'Incorrect file',
            validationDescription: 'Your photo could not be uploaded. The file must have a minimum resolution of 1080x1080 pixels. The file size must be less than or equal to 20 megabytes. Authorized extensions are: PNG, GIF, JPEG, JPG.',
            photoDeleteSuccess: 'Photo deleted successfully!',
        },
        explorePlaces: {
            inputPlaceholder: 'City, country',
        },
        filterPlaces: {
            desktopBar: {
                buttons: {
                    apply: 'Apply saved filters',
                    category: 'Select category',
                    terms: 'My accessibility filters',
                    spaces: 'Spaces without details',
                    save: 'Save',
                },
                spaces: {
                    title: 'Show spaces without details',
                    tagline: 'Vestibulum ornare tincidunt orci, non consequat nisl gravida vel.Nulla ut varius ante.',
                },
            },
            termsSelector: {
                title: 'Accessibility filters',
                inputPlaceholder: 'Start typing here to add accessibility filters',
                showPlacesSwitch: 'Show places with all selected accessibility filters',
            },
            category: {
                placeholder: 'Type something',
                suggestedCategoriesLabel: 'Suggested categories',
                selectedCategoryLabel: 'Selected category',
            },
            categorySelector: {
                label: 'Category',
            },
            filterBar: {
                editButton: 'Edit filters',
                more: 'More',
            },
            footer: {
                clear: 'Clear',
                applyFilters: 'Apply Filters',
            },
            header: {
                title: 'Filters',
            },
            placeWithoutDetailsSwitch: {
                title: 'Places without details',
            },
        },
        reviews: {
            headerTitle: 'All reviews',
            emptyReviews: 'This place do not have any review yet',
            addReviewConflict: 'This comment already exists',
        },
        editPlace: {
            mainPageTitle: 'Suggest changes',
            category: 'Category',
            categoryOfPlace: 'Category of place',
            entrance: 'Entrance',
            access: 'Access',
            doorOpeningSystem: 'Door oppening system',
            ninetyCmAndAbove: '90 cm and above',
            indoors: 'Indoors',
            servicesAndFacilities: 'Services and facilities',
            roomsTile: 'Room(s)',
            rooms: 'Room',
            outside: 'Outside',
            disabledParkingSpaces: 'Disbled parkings spaces',
            pavements: 'Pavements',
            selectACategory: 'Select a category',
            categoryRequired: 'Category is required.',
            successfulFeedback: 'Updated successfully!',
            pointsFeedback: 'You have earned {points} points.',
            accessibilityTitle1: 'Detailed accessibility',
            accessibilityTitle2: 'by',
            accessibilityTitle2Singular: 'person',
            accessibilityTitle2Plural: 'people',
            noAccessibility: 'No information on accessibility for this place yet.',
            success: 'Success!',
            photoAdded: 'Photo added.',
            failure: 'Failure',
            addMorePhotos: 'Add more photos',
        },
        challenges: {
            secretChallenge: {
                text: 'Enter your Secret Code!',
                description: 'To unlock a private challenge, you must enter the secret code that was sent to you.',
                button: 'Access challenge',
                required: 'The code is required',
                validation: 'Oops! :( It looks like your code is invalid, check it again',
                max: 'The code should be 6 chars',
            },
            header: {
                hashtag: '#JACCEDECHALLENGE',
            },
            publicChallengeTitle: 'Public Challenges',
        },
        challengesDetail: {
            selectTeam: 'Select a team below to participate',
            newTeamSection: 'Create and join a new team',
            createNewTeamButton: 'Create a new team',
            challengeStartsOn: 'Challenge starts on',
            challengeEndsOn: 'ends on',
            membersScoreBoard: 'Members scoreboard',
            teamScoreboard: 'Teams scoreboard',
            emptyTeams: 'We do not have teams yet',
        },
        createNewTeam: {
            label: 'Choose the name of your team:',
            placeholder: 'Type your team name',
            cancelButton: 'Cancel',
            button: 'Continue',
            nameIsRequired: 'Name is required',
        },
        challengesMembersTeam: {
            joinTeamSection: {
                title: 'I want to be a part of this team',
                buttonText: 'Join this team',
            },
            placesAroundMeSection: {
                title: 'Explore places',
            },
            memberHeader: {
                inviteButtonText: 'Invite new member',
            },
            title: 'Team members',
        },
        challengeInviteUserTeam: {
            label: 'Email address:',
            placeholder: 'Email address',
            cancelButton: 'Cancel',
            button: 'Continue',
            emailIsRequired: 'Email is required',
            emailFormat: 'Incorrect email format',
            inviteSuccess: 'An email was sent',
        },
    },
    pages: {
        globalScoreboard: {
            title: 'Global Scoreboard',
            description: 'Global Scoreboard Page description to helmet',
            showByPeriod: 'Show by period',
        },
        explorePlacesPage: {
            newPlaceModal: {
                addressTitle: 'Enter the address of the place',
                newPlaceTitle: 'Add a new place',
                suggestionTitle: 'Are you looking for any of these address?',
                nameLabel: 'Name of the place',
                nameValidation: 'The name field is required',
                addressLabel: 'Address',
                addressValidation: 'The address field is required',
                categoryLabel: 'Category',
                categoryPlaceholder: 'Select',
                categoryValidation: 'The category field is required',
                telephoneLabel: 'Telephone',
                telephoneValidation: 'The telephone field is required',
                websiteLabel: 'Website',
                websiteValidation: 'The website field is required',
                continueButton: 'Continue',
                validateButton: 'Validate',
                suggestionButton: 'No, continue',
                validateWebsite: 'Enter a valid link or leave the field blank.',
            },
            addPlaceNotFound: {
                title: 'Didn’t find the place you were looking for?',
                tagline: 'Add the address to the map, earn points and help make the world more accessible!',
            },
            addPlaceNoResults: {
                title: 'Oops! We didn’t find the place you searched for?',
                tagline: 'If you know an accessible place that is not on our app, add it now and help people around the world!',
            },
            chooseLocation: {
                text: 'Choose a location on the map',
                button: 'Select on map',
            },
            collaboration: {
                message: 'Collaborate you too and help the world be accessible to everyone!',
                highlight: 'We have collaborations all over the world!',
            },
            title: 'Explore Places',
            description: 'Explore Places description to helmet',
            sidebarTitle: 'Accessibility of places in and around Region',
            recentContributors: 'Recent contributors',
            filtersRemoved: 'All accessibility filters have been removed.',
            info: 'Info',
            locationPermission: "Jaccede doesn't have permission to use your location.",
        },
        filterPlacesPage: {
            title: 'Filter Places',
            description: 'Filter Places description to helmet',
        },
        reviewsPage: {
            title: 'All reviews',
            description: 'All reviews description to helmet',
        },
        notFoundPage: {
            title: 'Page not Found',
            description: 'Not Found Page description to helmet',
        },
        homePage: {
            title: 'Homepage',
            description: 'Home Page description to helmet',
        },
        signInPage: {
            title: 'Sign in',
            description: 'Sign in and Sign up',
            byEmail: 'or by email',
            dontHaveAccount: 'Don’t you have an account?',
            signUpButton: 'Sign up',
        },
        forgotPasswordPage: {
            title: 'Reset password',
            description: 'Reset Password Page description to helmet',
        },
        changePasswordPage: {
            title: 'Change my password',
            description: 'Change My Password Page description to helmet',
        },
        profilePage: {
            title: 'Profile',
            description: 'My profile',
        },
        signUpPage: {
            title: 'Create account',
            description: 'Register new user account',
            header: 'Create account',
            continueWithFacebook: 'Continue with Facebook',
            alreadyAccount: 'Already have an account?',
            signIn: 'Sign in',
        },
        confirmAccountPage: {
            title: 'Confirm account',
            success: 'Nice!!',
            content: 'We have sent you instructions on how to finish creating your account',
        },
        aboutPage: {
            title: 'About',
            description: 'About the jaccede company that main goal is to persons',
            listGroup: {
                inBrief: {
                    header: 'In brief',
                    item1: 'What is Jaccede?',
                    item2: 'Who is Jaccede for?',
                    item3: 'Background',
                    item4: 'Prizes and awards',
                },
                organization: {
                    header: 'The organization',
                    item1: 'Our vision and mission statement',
                    item2: 'Our governing body',
                    item3: 'Our permanent team',
                    item4: 'Our volunteers',
                    item5: 'Join us',
                    item6: 'Make a donation',
                },
                getInvolved: {
                    header: 'Get involved',
                    item1: 'Online',
                    item2: 'At the grassroots',
                    item3: 'Apply your skills',
                },
                partners: {
                    header: 'Our partners',
                    item1: 'Our partners',
                    item2: 'Get your company involved',
                    item3: 'Get your voluntary organization or school involved',
                    item4: 'Get involved as a local authority',
                    item5: 'List your establishment',
                },
            },
        },
        resendEmailActivationPage: {
            title: 'Resend email activation',
            description: 'Resend the activation e-mail',
        },
        subscribersProfilePage: {
            title: 'Subscriptions & Subscribers',
            description: 'Follow the users',
        },
        editProfilePage: {
            title: 'Edit profile',
            description: 'Edit your profile',
        },
        editFieldProfilePage: {
            title: 'Edit profile',
            description: 'Edit your profile',
        },
        userPage: {
            title: 'Usuário',
            description: 'Contribuidor',
        },
        accountActivatedPage: {
            title: 'Account activated',
            description: 'Account activated',
            paragraph: 'Your account is activated.',
        },
        newsletterPage: {
            title: 'Newsletter',
            description: 'Newsletter page',
        },
        editPlacePage: {
            title: 'Detailed accessibility',
            description: 'Detailed accessibility description',
        },
        placeDetailsPage: {
            title: 'Place details',
            description: 'Place details',
        },
        newReviewPage: {
            title: 'Add a review',
            description: 'Add a review',
            postReviewButton: 'Post review',
            commentRequiredError: 'Review is required',
            commentSizeError: 'Your review must be between 15 and 2000 characters',
            commentSuccess: 'Your review was created with successful',
        },
        placePhotosPage: {
            title: 'Place photos',
            description: 'Place photos details',
        },
        reviewDetailPage: {
            title: 'Review',
            description: 'Review detail',
            notFound: 'Review not found',
            defaultError: 'Error trying to find this review',
        },
        categoryListViewPage: {
            title: 'Region title',
            description: 'This is the CategoryListViewPage page',
        },
        categoryMapViewPage: {
            title: 'Region title',
            description: 'This is the CategoryMapViewPage page',
            seeInListButton: 'See in list',
        },
        challengePage: {
            title: 'Challenges Page',
            description: 'Challenge Page description to helmet',
        },
        challengeDetailPage: {
            title: 'Challenge details',
            description: 'Challenge details description to helmet',
        },
        challengeJoinTeamPage: {
            warning: 'After confirmation, you will not be able to change teams',
            buttonText: 'Join this team',
        },
        challengesMembersTeam: {
            title: 'Team members',
            description: 'Team members description to helmet',
            userJoinedTeam: 'Now, earn points by providing information for the places on the Jaccede app.',
        },
        challengeInviteUserTeamPage: {
            title: 'Invite a new participant',
            subtitle: 'Please enter a colleague’s or a friend’s email address to invite him to participate to this challenge with your team.',
        },
        challengeCreateNewTeamPage: {
            title: 'Registration is irreversible',
            subtitle: 'Vous êtes sur le point de créer et de rejoindere une éqiope pour participer à ce challenge',
            warningAdvice: 'You will not be able to abandon the challenge or leave your team',
        },
        mailSystemPage: {
            title: 'Mail system',
            description: 'This is the Mail System page',
        },
    },
};
