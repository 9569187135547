var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Progress } from 'antd';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var progressBarColor = theme('mode', {
    light: colors.base.primary,
    dark: colors.base.secondary,
});
var backgroundColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint200,
});
export var ProgressWrapper = styled(Progress)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-progress-inner {\n    background-color: ", ";\n  }\n\n  .ant-progress-bg {\n    background-color: ", ";\n  }\n"], ["\n  .ant-progress-inner {\n    background-color: ", ";\n  }\n\n  .ant-progress-bg {\n    background-color: ", ";\n  }\n"])), backgroundColor, progressBarColor);
var templateObject_1;
