export var PasswordScoreDescription;
(function (PasswordScoreDescription) {
    PasswordScoreDescription["VeryWeak"] = "components.passwordScore.veryWeak";
    PasswordScoreDescription["Weak"] = "components.passwordScore.weak";
    PasswordScoreDescription["Fair"] = "components.passwordScore.fair";
    PasswordScoreDescription["Strong"] = "components.passwordScore.strong";
})(PasswordScoreDescription || (PasswordScoreDescription = {}));
export var ProgressBarVariant;
(function (ProgressBarVariant) {
    ProgressBarVariant["Gray"] = "gray";
    ProgressBarVariant["Danger"] = "danger";
    ProgressBarVariant["Warning"] = "warning";
    ProgressBarVariant["Info"] = "info";
    ProgressBarVariant["Success"] = "success";
})(ProgressBarVariant || (ProgressBarVariant = {}));
