import produce from 'immer';
import { AUTH_LOGIN_FAILURE, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_FAILURE, AUTH_LOGOUT_SUCCESS, } from './constants';
export var initialState = {
    sessionToken: '',
    isLoggedIn: false,
    user: undefined,
    hasError: false,
    error: '',
    isLoading: true,
};
var authReducer = produce(function (draft, action) {
    switch (action.type) {
        case AUTH_LOGIN_SUCCESS:
            draft.sessionToken = action.payload.session_token;
            draft.isLoggedIn = true;
            draft.user = action.payload;
            draft.hasError = false;
            draft.isLoading = false;
            break;
        case AUTH_LOGIN_FAILURE:
            draft.isLoading = false;
            break;
        case AUTH_LOGOUT_SUCCESS:
            draft.sessionToken = '';
            draft.isLoggedIn = false;
            draft.user = undefined;
            draft.hasError = false;
            draft.error = undefined;
            draft.isLoading = false;
            break;
        case AUTH_LOGOUT_FAILURE:
            draft.sessionToken = '';
            draft.isLoggedIn = false;
            draft.user = undefined;
            draft.hasError = true;
            draft.error = action.payload;
            draft.isLoading = false;
            break;
        default:
    }
}, initialState);
export default authReducer;
