import { notification } from 'antd';
import { Footer, LoadingIndicator, PageHeader, PageWrapper, Pagination, SubFooter, } from 'components';
import PrivateRoute from 'components/PrivateRoute';
import { fetchSubFooterLinksRequest } from 'components/SubFooter/actions';
import { makeSelectSubFooterLinksSearchByTypeOfPlace } from 'components/SubFooter/selectors';
import { makeSelectIsLoggedIn, makeSelectUser, } from 'containers/Auth/selectors';
import { JoinTeamSection, MembersHeader, MembersList, } from 'containers/ChallengeMembersTeam';
import { PlacesAroundMeSection } from 'containers/ChallengeMembersTeam/PlacesAroundMe';
import { fetchMembersOnTeamRequest } from 'containers/ChallengeMembersTeam/store/getMembersOfTeam/actions';
import makeMembersOnTeam, { makeSelectMembersOnTeamLoading, } from 'containers/ChallengeMembersTeam/store/getMembersOfTeam/selectors';
import { fetchTeamRequest } from 'containers/ChallengeMembersTeam/store/getTeamOnChallenge/actions';
import { 
// makeSelectTeamDetailHasError,
makeSelectTeamDetailItem, makeSelectTeamDetailLoading, } from 'containers/ChallengeMembersTeam/store/getTeamOnChallenge/selectors';
import { uploadCoverImageTeamClearFailure } from 'containers/ChallengeMembersTeam/store/uploadCoverImage/actions';
import { makeSelectUploadCoverImageTeamError, makeSelectUploadCoverImageTeamHasError, } from 'containers/ChallengeMembersTeam/store/uploadCoverImage/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import { ArrowBack } from 'icons/mono';
import isEmpty from 'lodash/isEmpty';
import { ChallengeInviteUserTeamPage } from 'pages/ChallengeInviteUserTeamPage';
import { rem } from 'polished';
import React, { memo, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { colors } from 'styles';
import { Container, Content, PaginationWrapper } from './styles';
export var ChallengeMembersTeamPage = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var locale = useSelector(makeSelectLocale());
    var params = useParams();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var user = useSelector(makeSelectUser());
    var fetchTeam = useAction(function (payload) {
        return fetchTeamRequest(payload);
    });
    var teamIsLoading = useSelector(makeSelectTeamDetailLoading());
    // const teamHasError = useSelector(makeSelectTeamDetailHasError());
    var team = useSelector(makeSelectTeamDetailItem());
    var fetchMembers = useAction(function (payload) {
        return fetchMembersOnTeamRequest(payload);
    });
    var membersIsLoading = useSelector(makeSelectMembersOnTeamLoading());
    var members = useSelector(makeMembersOnTeam());
    var fetchSubFooterLinks = useAction(fetchSubFooterLinksRequest);
    var searchByType = useSelector(makeSelectSubFooterLinksSearchByTypeOfPlace());
    var clearUploadCoverImageFailure = useAction(function () {
        return uploadCoverImageTeamClearFailure();
    });
    var uploadCoverImageHasError = useSelector(makeSelectUploadCoverImageTeamHasError());
    var uploadCoverImageError = useSelector(makeSelectUploadCoverImageTeamError());
    var clearUploadCoverImageFailureCallback = function () {
        return clearUploadCoverImageFailure();
    };
    var handleMembersPage = function (page) {
        var payload = {
            challengeSlug: params.challengeSlug,
            teamSlug: params.teamSlug,
            pagination: {
                page: page,
                perPage: 20,
            },
        };
        fetchMembers(payload);
    };
    var handleGoToJoinTeam = function () {
        return history.push(paths.joinTeamPage
            .replace(':challengeSlug', params.challengeSlug)
            .replace(':teamSlug', params.teamSlug), {
            from: location.pathname,
        });
    };
    var handleGoToChallengeDetailPage = function () {
        return history.push(paths.challengeDetailPage.replace(':challengeSlug', params.challengeSlug), {
            from: location.pathname,
        });
    };
    var checkIsMemberOnAnyTeam = useMemo(function () {
        var _a;
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.inChallenge) && ((_a = user === null || user === void 0 ? void 0 : user.ranks) === null || _a === void 0 ? void 0 : _a.team)) {
            return true;
        }
        return false;
    }, [user]);
    useEffect(function () {
        if (team && team.slug !== params.teamSlug) {
            var payload = {
                challengeSlug: params.challengeSlug,
                teamSlug: params.teamSlug,
            };
            fetchTeam(payload);
        }
    }, [team]);
    useEffect(function () {
        var payload = {
            challengeSlug: params.challengeSlug,
            teamSlug: params.teamSlug,
        };
        fetchTeam(payload);
    }, []);
    useEffect(function () {
        if (isEmpty(members.items) || team.slug !== params.teamSlug) {
            var payload = {
                challengeSlug: params.challengeSlug,
                teamSlug: params.teamSlug,
                pagination: {
                    page: 1,
                    perPage: 20,
                },
            };
            fetchMembers(payload);
        }
    }, []);
    useEffect(function () {
        if (isEmpty(searchByType)) {
            fetchSubFooterLinks();
        }
    }, [searchByType, fetchSubFooterLinks]);
    useEffect(function () {
        if (uploadCoverImageHasError) {
            notification.error({
                message: uploadCoverImageError,
                onClose: clearUploadCoverImageFailureCallback,
            });
        }
    }, [uploadCoverImageHasError]);
    if (teamIsLoading || isEmpty(team)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageWrapper, null,
                React.createElement(Container, null,
                    React.createElement(PageHeader, { title: formatMessage({ id: 'pages.challengesMembersTeam.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: handleGoToChallengeDetailPage, sticky: true }),
                    React.createElement(Content, null,
                        React.createElement(LoadingIndicator, null)),
                    !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                        React.createElement(SubFooter, null),
                        React.createElement(Footer, null)))))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, team.name),
            React.createElement("meta", { name: "description", content: team.description }),
            React.createElement("meta", { property: "og:title", content: team.name }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: team.description }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(Container, null,
                React.createElement(PageHeader, { title: formatMessage({ id: 'pages.challengesMembersTeam.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: handleGoToChallengeDetailPage, sticky: true }),
                React.createElement(Content, null,
                    React.createElement(MembersHeader, { title: team.name, points: team.score, isMember: team.isMember, cover: team.cover, canAddCover: team.canAddCover, isAuthenticated: isAuthenticated }),
                    React.createElement(MembersList, { members: members.items, isLoading: membersIsLoading }),
                    React.createElement(PaginationWrapper, null,
                        React.createElement(Pagination, { hideOnSinglePage: true, showSizeChanger: false, defaultCurrent: 1, pageSize: 20, total: members.total, onChange: handleMembersPage, disabled: members.loading, current: members.currentPage, showLessItems: isMobile || isTabletOrMobile })),
                    !checkIsMemberOnAnyTeam ? (React.createElement(JoinTeamSection, { onClick: handleGoToJoinTeam })) : (React.createElement(PlacesAroundMeSection, null)),
                    React.createElement(Switch, null,
                        React.createElement(PrivateRoute, { path: "/:locale/challenge/team/:challengeSlug/:teamSlug/invite", exact: true, 
                            // @ts-ignore
                            component: ChallengeInviteUserTeamPage, isAuthenticated: isAuthenticated, redirectPath: paths.redirectPage })))),
            !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                React.createElement(SubFooter, null),
                React.createElement(Footer, null))))));
});
