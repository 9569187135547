import { PageHeader } from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderContainer } from './styles';
export var Header = function (_a) {
    var title = _a.title;
    var history = useHistory();
    var handleBackButton = function () { return history.goBack(); };
    return (React.createElement(HeaderContainer, null,
        React.createElement(PageHeader, { onBack: handleBackButton, title: title, sticky: true })));
};
