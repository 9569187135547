var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var MedalPhotoMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0 0.4125 21.8064516 0.4125 21.8064516 20.4165833 0 20.4165833" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-7", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/7", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group", transform: "translate(6.500000, 7.750000)" }),
                    React.createElement("g", { id: "Group-2", transform: "translate(0.000000, 5.166667)" },
                        React.createElement("g", { id: "Group-22" },
                            React.createElement("g", { id: "Group-3", transform: "translate(4.193548, 0.004167)" },
                                React.createElement("mask", { id: "mask-2", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-1" })),
                                React.createElement("g", { id: "Clip-2" }),
                                React.createElement("path", { d: "M19.2903226,3.74991667 L17.6129032,3.74991667 C16.2206452,3.74991667 15.0967742,2.62908333 15.0967742,1.24991667 C15.0967742,0.787416667 14.7193548,0.412416667 14.2580645,0.412416667 L14.2580645,0.416583333 L8.38709677,0.416583333 C7.92580645,0.416583333 7.5483871,0.787416667 7.5483871,1.24575 L7.5483871,1.24991667 C7.5483871,2.62908333 6.42451613,3.74991667 5.03225806,3.74991667 L2.52032258,3.74991667 L0,3.74991667 C1.39225806,3.74991667 2.51612903,4.86658333 2.51612903,6.24991667 L2.51612903,17.9124167 C2.51612903,19.29575 1.39225806,20.4124167 0,20.4124167 L0,20.4165833 L19.2903226,20.4165833 C20.6741935,20.4165833 21.8064516,19.2915833 21.8064516,17.9165833 L21.8064516,6.24991667 C21.8064516,4.87491667 20.6741935,3.74991667 19.2903226,3.74991667", id: "Fill-1", fill: "#545C66", mask: "url(#mask-2)" })),
                            React.createElement("path", { d: "M20.5483871,12.9175 C20.5483871,10.1591667 18.2964516,7.9175 15.516129,7.9175 C12.74,7.9175 10.483871,10.1591667 10.483871,12.9175 C10.483871,15.68 12.74,17.9175 15.516129,17.9175 C18.2964516,17.9175 20.5483871,15.68 20.5483871,12.9175 M22.0203226,11.2629167 C22.1545161,11.7925 22.2258065,12.3466667 22.2258065,12.9175 C22.2258065,13.4883333 22.1545161,14.0466667 22.0203226,14.5754167 C21.273871,17.455 18.6487097,19.5841667 15.516129,19.5841667 C11.8132258,19.5841667 8.80645161,16.6008333 8.80645161,12.9175 C8.80645161,9.23833333 11.8132258,6.25083333 15.516129,6.25083333 C18.6487097,6.25083333 21.273871,8.37958333 22.0203226,11.2629167", id: "Fill-4", fill: "#434A54" }),
                            React.createElement("path", { d: "M6.29443226,2.50408333 C6.52507742,2.50408333 6.7137871,2.69158333 6.7137871,2.92075 L6.7137871,3.7545 C6.7137871,3.98366667 6.52507742,4.17116667 6.29443226,4.17116667 L4.61281935,4.17116667 L3.77410968,4.17116667 L2.9354,4.17116667 C2.70475484,4.17116667 2.51604516,3.98366667 2.51604516,3.7545 L2.51604516,2.92075 C2.51604516,2.69158333 2.70475484,2.50408333 2.9354,2.50408333 L6.29443226,2.50408333 Z", id: "Fill-6", fill: "#AAB2BD" }),
                            React.createElement("path", { d: "M22.2258065,10.42075 C23.1525806,10.42075 23.9032258,11.1665833 23.9032258,12.0874167 C23.9032258,13.00825 23.1525806,13.7540833 22.2258065,13.7540833 C22.1545161,13.7540833 22.0874194,13.7499167 22.0203226,13.7415833 C22.1545161,13.2124167 22.2258065,12.6545 22.2258065,12.0836667 C22.2258065,11.5128333 22.1545161,10.9586667 22.0203226,10.4290833 C22.0874194,10.42075 22.1545161,10.42075 22.2258065,10.42075", id: "Fill-8", fill: "#AAB2BD" }),
                            React.createElement("path", { d: "M15.516129,7.083375 C18.2964516,7.083375 20.5483871,9.32504167 20.5483871,12.083375 C20.5483871,14.845875 18.2964516,17.083375 15.516129,17.083375 C12.74,17.083375 10.483871,14.845875 10.483871,12.083375 C10.483871,9.32504167 12.74,7.083375 15.516129,7.083375", id: "Fill-10", fill: "#5D9CEC" }),
                            React.createElement("path", { d: "M15.516129,7.083375 C15.2292903,7.083375 14.9504194,7.11254167 14.677,7.15879167 C17.0564194,7.557125 18.8692903,9.61004167 18.8692903,12.083375 C18.8692903,14.560875 17.0564194,16.6104583 14.677,17.0079583 C14.9504194,17.0537917 15.2292903,17.083375 15.516129,17.083375 C18.2964516,17.083375 20.5483871,14.845875 20.5483871,12.083375 C20.5483871,9.32504167 18.2964516,7.083375 15.516129,7.083375", id: "Fill-12", fill: "#4A89DC" }),
                            React.createElement("path", { d: "M20.5483871,12.083375 C20.5483871,9.32504167 18.2964516,7.083375 15.516129,7.083375 C12.74,7.083375 10.483871,9.32504167 10.483871,12.083375 C10.483871,14.845875 12.74,17.083375 15.516129,17.083375 C18.2964516,17.083375 20.5483871,14.845875 20.5483871,12.083375 M22.0203226,10.4287917 C22.1545161,10.958375 22.2258065,11.5125417 22.2258065,12.083375 C22.2258065,12.6542083 22.1545161,13.2125417 22.0203226,13.7412917 C21.273871,16.620875 18.6487097,18.7500417 15.516129,18.7500417 C11.8132258,18.7500417 8.80645161,15.7667083 8.80645161,12.083375 C8.80645161,8.40420833 11.8132258,5.41670833 15.516129,5.41670833 C18.6487097,5.41670833 21.273871,7.54545833 22.0203226,10.4287917", id: "Fill-14", fill: "#656D78" }),
                            React.createElement("path", { d: "M6.70967742,6.25408333 L6.70967742,17.9165833 C6.70967742,19.2999167 5.58580645,20.4165833 4.19354839,20.4165833 L4.19354839,20.42075 L2.51612903,20.42075 C1.13225806,20.42075 0,19.29575 0,17.92075 L0,6.25408333 C0,4.87908333 1.13225806,3.75408333 2.51612903,3.75408333 L4.19354839,3.75408333 C5.58580645,3.75408333 6.70967742,4.87075 6.70967742,6.25408333", id: "Fill-16", fill: "#656D78" }),
                            React.createElement("path", { d: "M17.6133226,2.50166667 L13.4189355,2.50166667 C13.1870323,2.50166667 12.9995806,2.31541667 12.9995806,2.085 C12.9995806,1.85458333 13.1870323,1.66833333 13.4189355,1.66833333 L17.6133226,1.66833333 C17.8452258,1.66833333 18.0326774,1.85458333 18.0326774,2.085 C18.0326774,2.31541667 17.8452258,2.50166667 17.6133226,2.50166667", id: "Fill-18", fill: "#656D78" }),
                            React.createElement("path", { d: "M16.3548387,3.75208333 L14.6774194,3.75208333 C14.4455161,3.75208333 14.2580645,3.56583333 14.2580645,3.33541667 C14.2580645,3.105 14.4455161,2.91875 14.6774194,2.91875 L16.3548387,2.91875 C16.5867419,2.91875 16.7741935,3.105 16.7741935,3.33541667 C16.7741935,3.56583333 16.5867419,3.75208333 16.3548387,3.75208333", id: "Fill-20", fill: "#656D78" })))),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
