var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
var desktopBackground = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: ", ";\n  padding: ", " 0;\n  border-top: ", " solid ", ";\n"], ["\n  margin-top: ", ";\n  padding: ", " 0;\n  border-top: ", " solid ", ";\n"])), rem(25), rem(20), rem(1), borderColor);
export var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n"])), rem(16), fonts.weight.semibold, rem(20));
export var LineChartWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: ", ";\n  height: ", ";\n\n  ", ";\n\n  ", ";\n\n  .recharts-responsive-container {\n    display: flex;\n    align-items: center;\n\n    ", ";\n  }\n"], ["\n  border-radius: ", ";\n  height: ", ";\n\n  ",
    ";\n\n  ",
    ";\n\n  .recharts-responsive-container {\n    display: flex;\n    align-items: center;\n\n    ",
    ";\n  }\n"])), rem(10), rem(215), media.lessThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: ", ";\n    border: ", " solid ", ";\n  "], ["\n    background-color: ", ";\n    border: ", " solid ", ";\n  "])), desktopBackground, rem(1), borderColor), media.greaterThan('medium')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding-top: ", ";\n    height: ", ";\n  "], ["\n    padding-top: ", ";\n    height: ", ";\n  "])), rem(10), rem(350)), media.lessThan('medium')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      padding-bottom: ", ";\n    "], ["\n      padding-bottom: ", ";\n    "])), rem(30)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
