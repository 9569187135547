var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Edit = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M29.0304 9.11858C28.7138 8.80196 28.2843 8.62408 27.8366 8.62408C27.3885 8.62439 26.9578 8.8028 26.6414 9.12004L5.75848 30.0543C5.60201 30.2112 5.478 30.3974 5.39336 30.6021L4.12794 33.6655C3.86749 34.2961 4.01206 35.0214 4.49452 35.5038C4.81751 35.8268 5.24924 35.9984 5.68865 35.9984C5.90559 35.9984 6.12445 35.9565 6.33287 35.8704L9.39627 34.6049C9.60101 34.5204 9.7871 34.3963 9.94396 34.2398L30.8782 13.357C31.1955 13.0405 31.3739 12.6109 31.3742 12.1628C31.3745 11.7147 31.1966 11.2848 30.8798 10.968L29.0304 9.11858Z", fill: color }),
        React.createElement("path", { d: "M35.0582 4.99849L35.0006 4.94093C33.8483 3.78863 31.9735 3.7887 30.8214 4.94086L29.917 5.84438C29.6002 6.161 29.4221 6.59051 29.422 7.03835C29.422 7.4862 29.5998 7.91578 29.9165 8.23248L31.7659 10.0819C32.0825 10.3985 32.5118 10.5764 32.9596 10.5764C33.4073 10.5764 33.8368 10.3985 34.1534 10.0819L35.0586 9.1766C36.2095 8.02483 36.2095 6.15079 35.0582 4.99849Z", fill: color })));
});
