var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
export var PhotoMosaicContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-rows: repeat(2, 1fr);\n\n  img {\n    border-radius: ", ";\n    padding: ", ";\n    width: 50%;\n\n    ", ";\n  }\n\n  > div {\n    max-width: 100%;\n\n    &:nth-of-type(1) {\n      align-items: flex-end;\n      display: flex;\n      padding-left: 12%;\n    }\n\n    &:nth-of-type(2) {\n      align-items: flex-start;\n      display: flex;\n      padding-right: 10%;\n    }\n  }\n"], ["\n  display: grid;\n  grid-template-rows: repeat(2, 1fr);\n\n  img {\n    border-radius: ", ";\n    padding: ", ";\n    width: 50%;\n\n    ",
    ";\n  }\n\n  > div {\n    max-width: 100%;\n\n    &:nth-of-type(1) {\n      align-items: flex-end;\n      display: flex;\n      padding-left: 12%;\n    }\n\n    &:nth-of-type(2) {\n      align-items: flex-start;\n      display: flex;\n      padding-right: 10%;\n    }\n  }\n"])), rem(16), rem(8), media.lessThan('large')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      padding: ", ";\n    "], ["\n      padding: ", ";\n    "])), rem(6)));
var templateObject_1, templateObject_2;
