var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'styles';
export var Container = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-bottom: ", ";\n  padding-top: ", ";\n"], ["\n  padding-bottom: ", ";\n  padding-top: ", ";\n"])), rem(40), rem(60));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 auto;\n  max-width: ", ";\n  padding: ", " ", " 0;\n"], ["\n  margin: 0 auto;\n  max-width: ", ";\n  padding: ", " ", " 0;\n"])), breakpoints.medium, rem(24), rem(24));
var templateObject_1, templateObject_2;
