var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, tint } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { colors, fonts } from 'styles';
export var CategoryDropdownContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var InputWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), rem(16));
export var SuggestedWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), rem(16));
export var TermButtonsList = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row wrap;\n  list-style: none;\n  padding-bottom: ", ";\n"], ["\n  display: flex;\n  flex-flow: row wrap;\n  list-style: none;\n  padding-bottom: ", ";\n"])), rem(8));
export var TermButtonListItem = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: ", ";\n  margin-bottom: ", ";\n  max-width: 100%;\n\n  overflow: hidden;\n\n  > button {\n    width: 100%;\n    height: ", ";\n    overflow: hidden;\n\n    > svg {\n      border-radius: ", ";\n      min-width: ", ";\n      min-height: ", ";\n    }\n\n    > span {\n      display: block;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n    }\n  }\n"], ["\n  margin-right: ", ";\n  margin-bottom: ", ";\n  max-width: 100%;\n\n  overflow: hidden;\n\n  > button {\n    width: 100%;\n    height: ", ";\n    overflow: hidden;\n\n    > svg {\n      border-radius: ", ";\n      min-width: ", ";\n      min-height: ", ";\n    }\n\n    > span {\n      display: block;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n    }\n  }\n"])), rem(10), rem(10), rem(30), rem(2), rem(18), rem(18));
export var Scrollbar = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  overflow: auto;\n  max-height: 20vh;\n\n  ", ";\n"], ["\n  overflow: auto;\n  max-height: 20vh;\n\n  ",
    ";\n"])), media.greaterThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    max-height: ", ";\n  "], ["\n    max-height: ", ";\n  "])), rem(325)));
export var InitialLetter = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 50%;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  height: ", ";\n  width: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background: ", ";\n  border-radius: 50%;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  height: ", ";\n  width: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), tint(0.5, colors.base.secondary), colors.space.tint900, rem(14), fonts.weight.semibold, rem(10), rem(25), rem(25));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
