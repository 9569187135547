import { AutoComplete } from 'components';
import { useField } from 'formik';
import map from 'lodash/map';
import React, { memo, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useIntl } from 'react-intl';
import usePlacesAutocomplete from 'use-places-autocomplete';

import messages from './messages';
import { Container, ErrorMessage, LabelWrapper } from './styles';

export const PlacesAutocomplete = memo((props) => {
  const { formatMessage } = useIntl();
  const [options, setOptions] = useState([]);
  const [field, meta, helpers] = useField(props);
  const required = props.required || false;

  const ref = useRef();

  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  useOnclickOutside(ref, () => {
    clearSuggestions();
  });

  const renderSuggestions = () =>
    map(data, (suggestion) => {
      const {
        structured_formatting: {
          main_text: mainText,
          secondary_text: secondaryText,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        place_id,
      } = suggestion;
      const suggestionItem = `${mainText} ${secondaryText || ''}`;
      // eslint-disable-next-line @typescript-eslint/camelcase
      return { value: suggestionItem, label: suggestionItem, id: place_id };
    });

  const handleSearch = (inputValue) => {
    setValue(inputValue);
    if (props.renderSuggestions) {
      setOptions(props.renderSuggestions(data));
    } else {
      setOptions(renderSuggestions());
    }

    if (props.onSearch) props.onSearch(inputValue);
  };

  const handleSelect = (inputValue, suggestion) => {
    if (props.onSelect) {
      props.onSelect(suggestion.id);
    }
    setValue(inputValue, false);
    helpers.setValue(suggestion.id);
    clearSuggestions();
  };

  return (
    <Container validateStatus={!!meta.error && meta.touched ? 'error' : ''}>
      {!props.hideLabel && (
        <LabelWrapper hasError={!!meta.error && meta.touched}>
          <label>
            {formatMessage({
              id: 'components.placesAutocomplete.googlePlaceIdLabel',
            })}
            {required && ' *'}
          </label>
          {props.afterLabel}
        </LabelWrapper>
      )}
      <AutoComplete
        options={options}
        name={field.name}
        onSearch={handleSearch}
        onSelect={(inputValue, option) => handleSelect(inputValue, option)}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onDropdownVisibleChange={props.onDropdownVisibleChange}
        placeholder={
          props.currentPlace ||
          props.placeholder ||
          formatMessage(messages.googlePlaceIdPlaceholder)
        }
      />

      {!!meta.error && meta.touched ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </Container>
  );
});
