var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Skeleton } from 'antd';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
export var SkeletonWrapper = styled(Skeleton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n\n  .ant-skeleton-avatar {\n    border-radius: 35%;\n  }\n"], ["\n  ",
    ";\n\n  .ant-skeleton-avatar {\n    border-radius: 35%;\n  }\n"])), ifProp('vertical', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n    "], ["\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n    "])))));
var templateObject_1, templateObject_2;
