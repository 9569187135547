import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editLocation';
var messages = defineMessages({
    title: { id: scope + ".title" },
    myAddress: { id: scope + ".myAddress" },
    acceptVisibility: { id: scope + ".acceptVisibility" },
    updateMyAddress: { id: scope + ".updateMyAddress" },
    fieldDescription: {
        id: 'containers.editMyProfile.location.fieldDescription',
    },
    fieldInformation: {
        id: 'containers.editMyProfile.location.fieldInformation',
    },
    shareLocalization: {
        id: scope + ".shareLocalization",
    },
    invalidAddress: {
        id: scope + ".invalidAddress",
    },
});
export default messages;
