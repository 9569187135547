import { CHALLENGES_DETAIL_FAILURE, CHALLENGES_DETAIL_REQUEST, CHALLENGES_DETAIL_SUCCESS, } from './constants';
export var fetchChallengeDetailRequest = function (payload) { return ({
    type: CHALLENGES_DETAIL_REQUEST,
    payload: payload,
}); };
export var fetchChallengeDetailFailure = function (payload) { return ({
    type: CHALLENGES_DETAIL_FAILURE,
    payload: payload,
}); };
export var setChallengeDetailSuccess = function (payload) { return ({
    type: CHALLENGES_DETAIL_SUCCESS,
    payload: payload,
}); };
