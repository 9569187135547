var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Eye = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M20.0332 23.9023C22.3848 23.9023 24.291 21.996 24.291 19.6445C24.291 17.293 22.3848 15.3867 20.0332 15.3867C17.6817 15.3867 15.7755 17.293 15.7755 19.6445C15.7755 21.996 17.6817 23.9023 20.0332 23.9023Z", fill: color }),
        React.createElement("path", { d: "M35.6567 18.4869C33.8964 15.7646 31.6125 13.4521 29.0525 11.7988C26.2204 9.96798 23.0935 9 20.0106 9C17.1819 9 14.4003 9.80832 11.7432 11.4023C9.0335 13.0276 6.57861 15.402 4.44639 18.459C3.95408 19.1656 3.93726 20.0995 4.40381 20.8234C6.16082 23.573 8.42211 25.8888 10.9422 27.5194C13.7796 29.3576 16.8332 30.289 20.0106 30.289C23.1182 30.289 26.2516 29.329 29.0718 27.5134C31.6304 25.8655 33.9097 23.5444 35.6634 20.7994C36.1131 20.0935 36.1105 19.1902 35.6567 18.4869ZM20.0332 26.0312C16.506 26.0312 13.6466 23.1718 13.6466 19.6445C13.6466 16.1172 16.506 13.2578 20.0332 13.2578C23.5605 13.2578 26.4199 16.1172 26.4199 19.6445C26.4159 23.1701 23.5589 26.0272 20.0332 26.0312Z", fill: color })));
});
