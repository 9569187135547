/* eslint-disable @typescript-eslint/no-use-before-define */
import { CardHeader, TabsWithPagination } from 'components';
import React, { memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import ContributionsTabTitle from './ContributionsTabTitle';
import FavoritesTabTitle from './FavoritesTabTitle';
import PlaceList from './PlaceList';
import { Container, Title } from './styles';
import { MoreAboutOptions } from './types';
var MoreAbout = function (_a) {
    var _b = _a.perPage, perPage = _b === void 0 ? 5 : _b, contributions = _a.contributions, favorites = _a.favorites, loadFavorites = _a.loadFavorites, loadContributions = _a.loadContributions;
    var params = useParams();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 1096 });
    var handleContributionsPage = function (page) {
        if (loadContributions) {
            loadContributions({
                page: page,
                perPage: perPage,
            });
        }
    };
    var handleFavoritesPage = function (page) {
        if (loadFavorites) {
            loadFavorites({
                page: page,
                perPage: perPage,
            });
        }
    };
    useEffect(function () {
        if (loadContributions) {
            loadContributions({
                page: 1,
                perPage: perPage,
            });
        }
        if (loadFavorites) {
            loadFavorites({
                page: 1,
                perPage: perPage,
            });
        }
    }, [params.userSlug, perPage]);
    var tabs = [
        {
            perPage: perPage,
            total: contributions.total,
            loading: contributions.loading,
            onPageChange: handleContributionsPage,
            currentPage: contributions.currentPage,
            title: React.createElement(ContributionsTabTitle, null),
            key: MoreAboutOptions.Contributions,
            data: (React.createElement(PlaceList, { data: contributions, loading: contributions.loading, emptyListText: React.createElement(FormattedMessage, { id: "containers.profile.moreAbout.contributionEmptyList" }) })),
        },
        {
            perPage: perPage,
            total: favorites.total,
            loading: favorites.loading,
            onPageChange: handleFavoritesPage,
            currentPage: favorites.currentPage,
            title: React.createElement(FavoritesTabTitle, null),
            key: MoreAboutOptions.Favorites,
            data: (React.createElement(PlaceList, { data: favorites, loading: favorites.loading, emptyListText: React.createElement(FormattedMessage, { id: "containers.profile.moreAbout.favoriteEmptyList" }) })),
        },
    ];
    return (React.createElement(Container, null,
        isMobile || isTabletOrMobile ? (React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "containers.profile.moreAbout.header" }))) : (React.createElement(CardHeader, { title: React.createElement(FormattedMessage, { id: "containers.profile.moreAbout.header" }) })),
        React.createElement(TabsWithPagination, { fullSize: isMobile || isTabletOrMobile, defaultActiveKey: MoreAboutOptions.Contributions, tabs: tabs })));
};
export default memo(MoreAbout);
