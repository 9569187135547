/* eslint-disable react/no-danger */
import React from 'react';
import { Content, HeaderContainer, Title } from './styles';
export var ChallengeDetailHeader = function (_a) {
    var title = _a.title, description = _a.description;
    return (React.createElement(HeaderContainer, null,
        React.createElement(Title, null, title),
        React.createElement(Content, { dangerouslySetInnerHTML: {
                __html: description,
            } })));
};
