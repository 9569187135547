import { createSelector } from 'reselect';
import { contributorUserAccountInitialState } from '../reducers/contributorUserAccountReducer';
export var selectContributorUserAccountDomain = function (state) {
    return state.contributorUserAccount || contributorUserAccountInitialState;
};
export var makeSelectContributorUser = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user;
    });
};
export var makeSelectUserEmailStatus = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.emailStatus;
    });
};
export var makeSelectContributorUserEmail = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.email;
    });
};
export var makeSelectContributorUserSlug = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.slug;
    });
};
export var makeSelectContributorUserFirstName = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.firstName;
    });
};
export var makeSelectContributorUserLastName = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.lastName;
    });
};
export var makeSelectContributorUserScore = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.score;
    });
};
export var makeSelectContributorUserMessage = function () {
    return createSelector(selectContributorUserAccountDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.message;
    });
};
export var makeSelectContributorUserAccount = function () {
    return createSelector(selectContributorUserAccountDomain, function (subState) { return subState; });
};
export default makeSelectContributorUserAccount;
