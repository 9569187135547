import produce from 'immer';
import { CLEAR_MAP_LOCATION, REVERSE_GPID, REVERSE_GPID_ERROR, REVERSE_GPID_SUCCESS, } from './constants';
export var initialState = {
    loading: false,
};
var mapReducer = produce(function (draft, action) {
    var _a, _b;
    switch (action.type) {
        case REVERSE_GPID:
            draft.loading = true;
            break;
        case REVERSE_GPID_SUCCESS:
            draft.location = {
                lat: +((_a = action.payload) === null || _a === void 0 ? void 0 : _a.lat),
                lng: +((_b = action.payload) === null || _b === void 0 ? void 0 : _b.lng),
            };
            draft.loading = false;
            break;
        case REVERSE_GPID_ERROR:
            draft.loading = false;
            break;
        case CLEAR_MAP_LOCATION:
            draft.location = undefined;
            break;
        default:
    }
}, initialState);
export default mapReducer;
