var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var background = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n  "], ["\n    background: ", ";\n  "])), rgba(colors.base.black, 0.05)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: ", ";\n  "], ["\n    background: ", ";\n  "])), rgba(colors.base.white, 0.05)),
});
var outline = theme('mode', {
    light: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    outline: ", " solid ", ";\n  "], ["\n    outline: ", " solid ", ";\n  "])), rem(3), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    outline: ", " solid ", ";\n  "], ["\n    outline: ", " solid ", ";\n  "])), rem(3), rgba(colors.base.white, 0.2)),
});
export var Container = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    color: ", ";\n    padding: 0;\n\n    &:first-child {\n      border-top: ", " solid ", " !important;\n    }\n\n    .ant-collapse-content {\n      color: inherit;\n      background-color: transparent;\n    }\n\n    .ant-collapse-header {\n      color: ", " !important;\n    }\n\n    .ant-skeleton-content {\n      .ant-skeleton-title {\n        margin: ", " 0 ", " 0;\n      }\n    }\n  }\n\n  .ant-skeleton-title {\n    margin: ", " auto !important;\n    width: calc(100% - ", ") !important;\n  }\n"], ["\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    color: ", ";\n    padding: 0;\n\n    &:first-child {\n      border-top: ", " solid ", " !important;\n    }\n\n    .ant-collapse-content {\n      color: inherit;\n      background-color: transparent;\n    }\n\n    .ant-collapse-header {\n      color: ", " !important;\n    }\n\n    .ant-skeleton-content {\n      .ant-skeleton-title {\n        margin: ", " 0 ", " 0;\n      }\n    }\n  }\n\n  .ant-skeleton-title {\n    margin: ", " auto !important;\n    width: calc(100% - ", ") !important;\n  }\n"])), rem(1), border, color, rem(1), border, color, rem(3), rem(3), rem(16), rem(32));
export var LinkContainer = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  flex: 1;\n  overflow: hidden;\n  text-decoration: none;\n  transition: background 0.2s;\n\n  &:hover {\n    ", ";\n  }\n\n  &:focus {\n    outline-offset: ", " !important;\n  }\n"], ["\n  color: ", ";\n  flex: 1;\n  overflow: hidden;\n  text-decoration: none;\n  transition: background 0.2s;\n\n  &:hover {\n    ", ";\n  }\n\n  &:focus {\n    outline-offset: ", " !important;\n  }\n"])), color, background, rem(-5));
export var InfoContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  font-size: ", ";\n  overflow: hidden;\n  padding: ", ";\n  width: 100%;\n\n  span {\n    line-height: 1.15;\n  }\n\n  > svg {\n    flex-shrink: 0;\n    margin-right: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  font-size: ", ";\n  overflow: hidden;\n  padding: ", ";\n  width: 100%;\n\n  span {\n    line-height: 1.15;\n  }\n\n  > svg {\n    flex-shrink: 0;\n    margin-right: ", ";\n  }\n"])), rem(16), rem(16), rem(16));
export var HourContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  transition: background 0.2s;\n\n  &:hover {\n    ", ";\n  }\n\n  .ant-collapse {\n    background: none;\n    border-radius: 0;\n    border: 0;\n    color: inherit;\n    flex: 1;\n    font-size: ", ";\n\n    &-content {\n      border: 0;\n\n      &-box {\n        padding-left: ", " !important;\n        padding-top: 0 !important;\n      }\n    }\n\n    > .ant-collapse-item {\n      border: 0;\n      border-radius: 0;\n\n      > .ant-collapse-header {\n        align-items: center;\n        display: flex;\n        padding: ", " ", ";\n\n        > svg {\n          margin-right: ", ";\n        }\n\n        &:focus {\n          ", ";\n          outline-offset: ", ";\n        }\n      }\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  transition: background 0.2s;\n\n  &:hover {\n    ", ";\n  }\n\n  .ant-collapse {\n    background: none;\n    border-radius: 0;\n    border: 0;\n    color: inherit;\n    flex: 1;\n    font-size: ", ";\n\n    &-content {\n      border: 0;\n\n      &-box {\n        padding-left: ", " !important;\n        padding-top: 0 !important;\n      }\n    }\n\n    > .ant-collapse-item {\n      border: 0;\n      border-radius: 0;\n\n      > .ant-collapse-header {\n        align-items: center;\n        display: flex;\n        padding: ", " ", ";\n\n        > svg {\n          margin-right: ", ";\n        }\n\n        &:focus {\n          ", ";\n          outline-offset: ", ";\n        }\n      }\n    }\n  }\n"])), background, rem(16), rem(48), rem(14), rem(16), rem(16), outline, rem(-5));
export var SiteContainer = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])));
export var PermanentlyClosed = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  margin-top: -", ";\n  margin-bottom: -", ";\n"], ["\n  color: ", ";\n  background: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  margin-top: -", ";\n  margin-bottom: -", ";\n"])), colors.base.white, colors.base.danger, rem(5), rem(7), rem(7), rem(7));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
