var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var PinPurple = memo(function (props) { return (React.createElement("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { opacity: ".8", fillRule: "evenodd", clipRule: "evenodd", d: "M20 21.049c-2.165 0-3.926-1.778-3.926-3.964 0-2.185 1.761-3.963 3.926-3.963s3.926 1.778 3.926 3.963c-.002 2.184-1.763 3.961-3.925 3.964zm-9-4.817c0 6.96 8.247 16.198 8.598 16.588a.542.542 0 00.804 0C20.753 32.43 29 23.193 29 16.232 29 11.692 24.962 8 20 8s-9 3.693-9 8.232z", fill: "#773BB3" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.629 13.911c-1.675 0-3.038 1.376-3.038 3.066s1.363 3.065 3.037 3.066c1.673-.002 3.036-1.378 3.038-3.067 0-1.69-1.363-3.065-3.037-3.065zm0 7.305c-2.316 0-4.2-1.901-4.2-4.24 0-2.337 1.884-4.239 4.2-4.239 2.316 0 4.2 1.902 4.2 4.24-.003 2.335-1.887 4.237-4.2 4.24zm0-12.784c-4.668 0-8.466 3.424-8.466 7.632 0 6.092 6.685 14.364 8.466 16.458 1.78-2.094 8.465-10.366 8.465-16.458 0-4.208-3.797-7.632-8.465-7.632zm0 25.568a.58.58 0 01-.43-.192C18.822 33.391 10 23.51 10 16.064c0-4.855 4.32-8.805 9.629-8.805 5.309 0 9.628 3.95 9.628 8.805 0 7.446-8.822 17.327-9.198 17.744a.58.58 0 01-.43.192z", fill: "#552A80" }),
    React.createElement("path", { opacity: ".3", fillRule: "evenodd", clipRule: "evenodd", d: "M10.692 16.346c0-3.25 2.48-5.885 5.539-5.885 3.058 0 5.538 2.635 5.538 5.885s-2.48 5.885-5.538 5.885c-3.059 0-5.539-2.635-5.539-5.885z", fill: "#CA95FF" }))); });
