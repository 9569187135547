import * as api from 'api';
import { Option, Select } from 'components';
import { useField } from 'formik';
import { CaretDown } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Container, ErrorMessage, LabelWrapper } from './styles';

const UsersProfilesField = (props) => {
  const { formatMessage } = useIntl();
  const [field, meta, helpers] = useField(props);
  const [profiles, setProfiles] = useState([]);
  const required = props.required || false;

  const onChange = (selectedOption) => {
    helpers.setValue(selectedOption);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.app.fetchAllProfiles();
        const { data } = response;
        if (data.items.length > 0) {
          setProfiles(data.items);
        } else {
          setProfiles([]);
        }
      } catch (exception) {
        setProfiles([{ id: '1', label: 'Person with limited mobility' }]);
      }
    }
    fetchData();
  }, []);

  return (
    <Container validateStatus={!!meta.error && meta.touched ? 'error' : ''}>
      <LabelWrapper hasError={!!meta.error && meta.touched}>
        <label>
          {props.label ||
            formatMessage({ id: 'components.usersProfilesField.label' })}
          {required && ' *'}
        </label>
      </LabelWrapper>

      <Select
        name={field.name}
        value={profiles.length > 0 && field.value}
        onChange={onChange}
        onBlur={props.onBlur || field.onBlur}
        onFocus={props.onFocus}
        disabled={props.disabled}
        suffixIcon={<CaretDown size={rem(16)} />}
      >
        <Option value="">
          {formatMessage({ id: 'components.usersProfilesField.select' })}
        </Option>
        {map(profiles, (profile) => (
          <Option label={profile.label} key={profile.id} value={profile.id}>
            {profile.label}
          </Option>
        ))}
      </Select>

      {!!meta.error && meta.touched ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </Container>
  );
};

export default memo(UsersProfilesField);
