import produce from 'immer';
import { SIGN_IN_USER_CHANGE_PASSWORD_REQUEST, SIGN_IN_USER_FAILURE, SIGN_IN_USER_REQUEST, SIGN_IN_USER_SUCCESS, } from './constants';
export var initialState = {
    loading: false,
    hasError: false,
    error: undefined,
};
var signInPageReducer = produce(function (draft, action) {
    switch (action.type) {
        case SIGN_IN_USER_REQUEST:
            draft.loading = true;
            break;
        case SIGN_IN_USER_CHANGE_PASSWORD_REQUEST:
            draft.loading = true;
            break;
        case SIGN_IN_USER_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case SIGN_IN_USER_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            break;
        default:
    }
}, initialState);
export default signInPageReducer;
