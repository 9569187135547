var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { authLoginFailure, authLoginSuccess } from 'containers/Auth/actions';
import { fetchMyChallengesRequest } from 'containers/Profile/actions';
import { fetchMyNotificationsRequest } from 'containers/Profile/actions/myNotificationsActions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OneSignalService } from 'utils/onesignal';
import { setDarkMode, setDirection, setFontSize } from './actions';
import { APP_INIT_REQUEST, APP_USER_CURRENT_USER } from './constants';
export function initApp() {
    var resCurrentUser, notificationsRequest, challengesRequest, error_1, oneSignal, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                resCurrentUser = null;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 9, , 11]);
                return [4 /*yield*/, put(setDarkMode(window.localStorage.getItem('dark-mode') === 'true'))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(setDirection(window.localStorage.getItem('is-ltr') === 'true'))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(setFontSize(window.localStorage.getItem('font') || 'sixteen'))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(api.auth.currentUser)];
            case 5:
                resCurrentUser = _a.sent();
                return [4 /*yield*/, put(authLoginSuccess(resCurrentUser.data))];
            case 6:
                _a.sent();
                notificationsRequest = {
                    pagination: {
                        page: 1,
                        perPage: 5,
                    },
                };
                return [4 /*yield*/, put(fetchMyNotificationsRequest(notificationsRequest))];
            case 7:
                _a.sent();
                challengesRequest = {
                    pagination: {
                        page: 1,
                        perPage: 10,
                    },
                };
                return [4 /*yield*/, put(fetchMyChallengesRequest(challengesRequest))];
            case 8:
                _a.sent();
                return [3 /*break*/, 11];
            case 9:
                error_1 = _a.sent();
                return [4 /*yield*/, put(authLoginFailure())];
            case 10:
                _a.sent();
                return [2 /*return*/, false];
            case 11:
                _a.trys.push([11, 14, , 15]);
                oneSignal = OneSignalService.getInstance();
                return [4 /*yield*/, call([oneSignal, 'sendUserId'])];
            case 12:
                _a.sent();
                return [4 /*yield*/, call([oneSignal, 'sendTags'], resCurrentUser.data)];
            case 13:
                _a.sent();
                return [3 /*break*/, 15];
            case 14:
                error_2 = _a.sent();
                console.log(error_2);
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function currentUser() {
    var userResponse, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.auth.currentUser)];
            case 1:
                userResponse = _a.sent();
                return [4 /*yield*/, put(authLoginSuccess(userResponse.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_3 = _a.sent();
                return [4 /*yield*/, put(authLoginFailure())];
            case 4:
                _a.sent();
                return [2 /*return*/, false];
            case 5: return [2 /*return*/];
        }
    });
}
export default function appSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(APP_INIT_REQUEST, initApp)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(APP_USER_CURRENT_USER, currentUser)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
