var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var listBorder = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint200,
});
var textColor = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var iconColor = theme('mode', {
    light: colors.space.tint600,
    dark: colors.base.white,
});
var iconBackground = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint100,
});
var focusBorder = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", " ", " 0;\n\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    padding: ", " 0;\n  }\n"], ["\n  padding: ", " ", " 0;\n\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    padding: ", " 0;\n  }\n"])), rem(30), rem(20), rem(1), listBorder, rem(20));
export var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  a {\n    display: block;\n    text-decoration: none;\n\n    &:focus {\n      outline: 0;\n    }\n\n    ", ";\n  }\n\n  li {\n    display: flex;\n  }\n"], ["\n  a {\n    display: block;\n    text-decoration: none;\n\n    &:focus {\n      outline: 0;\n    }\n\n    ",
    ";\n  }\n\n  li {\n    display: flex;\n  }\n"])), ifProp('isKeyboardMode', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        &:focus {\n          position: relative;\n          outline: 0 !important;\n          outline-offset: 0 !important;\n\n          &::before {\n            ", "\n            content: '';\n            width: calc(100% + ", ");\n            height: calc(100% + ", ");\n            display: block;\n            position: absolute;\n            border-radius: ", ";\n            top: ", ";\n            left: ", ";\n          }\n        }\n      "], ["\n        &:focus {\n          position: relative;\n          outline: 0 !important;\n          outline-offset: 0 !important;\n\n          &::before {\n            ", "\n            content: '';\n            width: calc(100% + ", ");\n            height: calc(100% + ", ");\n            display: block;\n            position: absolute;\n            border-radius: ", ";\n            top: ", ";\n            left: ", ";\n          }\n        }\n      "])), focusBorder, rem(16), rem(-30), rem(10), rem(10), rem(-10))));
export var Header = styled.header(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n"], ["\n  border-bottom: ", " solid ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n"])), rem(1), listBorder, rem(21), fonts.weight.semibold, rem(20));
export var ImageWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  width: ", ";\n  height: ", ";\n"], ["\n  position: relative;\n  overflow: hidden;\n  width: ", ";\n  height: ", ";\n"])), rem(248), rem(170));
export var Image = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: ", ";\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n"], ["\n  border-radius: ", ";\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n"])), rem(5));
export var PlaceWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin-left: ", ";\n  height: ", ";\n  position: relative;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin-left: ", ";\n  height: ", ";\n  position: relative;\n"])), rem(20), rem(170));
export var InfoWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n"])));
export var NameWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var Name = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"])), textColor, rem(18), fonts.weight.semibold);
export var Address = styled.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  padding-top: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  padding-top: ", ";\n"])), textColor, rem(13), rem(18));
export var Category = styled.span(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  line-height: 1;\n"], ["\n  color: ", ";\n  line-height: 1;\n"])), textColor);
export var Rating = styled.span(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n\n  svg {\n    margin: 0 ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n\n  svg {\n    margin: 0 ", ";\n  }\n"])), textColor, rem(14), rem(4), rem(2));
export var Contributor = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  align-self: flex-end;\n  justify-content: center;\n\n  div {\n    position: relative;\n    margin-right: ", ";\n  }\n\n  svg {\n    color: ", ";\n  }\n\n  span {\n    background: ", ";\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1;\n    padding: ", " ", ";\n    border-radius: ", ";\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    position: relative;\n    top: -", ";\n    left: ", ";\n  }\n"], ["\n  display: flex;\n  align-self: flex-end;\n  justify-content: center;\n\n  div {\n    position: relative;\n    margin-right: ", ";\n  }\n\n  svg {\n    color: ", ";\n  }\n\n  span {\n    background: ", ";\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1;\n    padding: ", " ", ";\n    border-radius: ", ";\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    position: relative;\n    top: -", ";\n    left: ", ";\n  }\n"])), rem(20), iconColor, iconBackground, iconColor, rem(13), fonts.weight.semibold, rem(2), rem(5), rem(10), rem(32), rem(10));
export var CategoryIcon = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: ", " solid ", ";\n  overflow: hidden;\n  z-index: 1;\n  position: absolute;\n  bottom: ", ";\n  left: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  border-radius: ", ";\n  border: ", " solid ", ";\n  overflow: hidden;\n  z-index: 1;\n  position: absolute;\n  bottom: ", ";\n  left: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), rem(4), rem(1), colors.base.white, rem(10), rem(10), rem(32), rem(32));
export var BookmarkButtonWrapper = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  position: absolute;\n  bottom: ", ";\n  right: ", ";\n"], ["\n  position: absolute;\n  bottom: ", ";\n  right: ", ";\n"])), rem(10), rem(10));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
