/* eslint-disable lodash/prefer-lodash-method */
import { placeholderPlace } from 'assets/images';
import { paths } from 'containers/RoutesProvider/routes';
import * as categoryId from 'icons/category/id';
import { mapIcons } from 'icons/category/map';
import { rem } from 'polished';
import React, { memo } from 'react';
import { snakeToCamel } from 'utils/CamelChange';
import { CategoryIconWrapper, CategoryLabel, Container, Content, ImageWrapper, PlaceImage, PlaceName, } from './styles';
var Item = memo(function (_a) {
    var mainImage = _a.mainImage, name = _a.name, category = _a.category, placeId = _a.placeId, slug = _a.slug;
    function renderCategoryIcon() {
        return mapIcons(categoryId.square[snakeToCamel(category.name)], 'square', {
            size: rem(30),
        });
    }
    return (React.createElement(Container, { to: paths.placeDetailsPage
            .replace(':placeId', placeId)
            .replace(':placeName', slug) },
        React.createElement(ImageWrapper, null,
            React.createElement(PlaceImage, { src: (mainImage === null || mainImage === void 0 ? void 0 : mainImage.urlThumb) || placeholderPlace }),
            React.createElement(CategoryIconWrapper, null, renderCategoryIcon())),
        React.createElement(Content, null,
            React.createElement(PlaceName, null, name),
            React.createElement(CategoryLabel, null, category.label))));
});
export default Item;
