var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var SuggestionItemContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var IconWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 50%;\n  margin-top: ", ";\n  margin-right: ", ";\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n"], ["\n  background-color: ", ";\n  border-radius: 50%;\n  margin-top: ", ";\n  margin-right: ", ";\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n"])), colors.space.tint300, rem(2), rem(16), rem(30), rem(30));
export var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  strong {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  span {\n    font-size: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  strong {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  span {\n    font-size: ", ";\n  }\n"])), rem(15), fonts.weight.semibold, rem(14));
var templateObject_1, templateObject_2, templateObject_3;
