import { logoImage } from 'assets/svg';
import { Button } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { AddUser } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CoverUpload } from '../CoverUpload';
import { ButtonWrapper, ChallengeImage, EditCoverWrapper, HeaderContainer, HeaderWrapper, ImageContainer, JaccedeLogo, Score, Title, } from './styles';
export var MembersHeader = function (_a) {
    var title = _a.title, _b = _a.points, points = _b === void 0 ? 0 : _b, _c = _a.isMember, isMember = _c === void 0 ? false : _c, _d = _a.cover, cover = _d === void 0 ? null : _d, _e = _a.canAddCover, canAddCover = _e === void 0 ? false : _e, _f = _a.isAuthenticated, isAuthenticated = _f === void 0 ? false : _f;
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var params = useParams();
    var handleGoToInvitePage = function () {
        var url = paths.membersTeamDetailPage
            .replace(':challengeSlug', params.challengeSlug)
            .replace(':teamSlug', params.teamSlug);
        history.push(url + "/invite", {
            from: location.pathname,
        });
    };
    return (React.createElement(React.Fragment, null, isMember ? (React.createElement(HeaderWrapper, null,
        React.createElement(HeaderContainer, { hasCover: !!(cover === null || cover === void 0 ? void 0 : cover.urlStandard) },
            React.createElement(Title, null, title),
            React.createElement(Score, null,
                points,
                " Points"),
            React.createElement(ImageContainer, { hasCover: !!(cover === null || cover === void 0 ? void 0 : cover.urlStandard) }, (cover === null || cover === void 0 ? void 0 : cover.urlStandard) ? (React.createElement(ChallengeImage, { src: cover === null || cover === void 0 ? void 0 : cover.urlStandard, alt: "Challenge cover image" })) : (React.createElement(JaccedeLogo, { src: logoImage, alt: "Challenge cover image" }))),
            canAddCover && isAuthenticated && (React.createElement(EditCoverWrapper, null,
                React.createElement(CoverUpload, null)))),
        isAuthenticated && (React.createElement(ButtonWrapper, null,
            React.createElement(Button, { variant: "ghost", shape: "pill", size: "small", icon: React.createElement(AddUser, { size: rem(18) }), onClick: handleGoToInvitePage }, formatMessage({
                id: 'containers.challengesMembersTeam.memberHeader.inviteButtonText',
            })))))) : (React.createElement(HeaderWrapper, null,
        React.createElement(HeaderContainer, { hasCover: !!(cover === null || cover === void 0 ? void 0 : cover.urlStandard) },
            React.createElement(Title, null, title),
            React.createElement(Score, null,
                points,
                " Points"),
            React.createElement(ImageContainer, { hasCover: !!(cover === null || cover === void 0 ? void 0 : cover.urlStandard) }, (cover === null || cover === void 0 ? void 0 : cover.urlStandard) ? (React.createElement(ChallengeImage, { src: cover === null || cover === void 0 ? void 0 : cover.urlStandard, alt: "Challenge cover image" })) : (React.createElement(JaccedeLogo, { src: logoImage, alt: "Challenge cover image" }))))))));
};
