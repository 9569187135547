var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { colors } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", " 0 ", ";\n\n  .ant-upload {\n    width: 100%;\n  }\n"], ["\n  padding: ", " 0 ", ";\n\n  .ant-upload {\n    width: 100%;\n  }\n"])), rem(80), rem(20));
export var HeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 ", " 0;\n  color: ", ";\n"], ["\n  padding: 0 ", " 0;\n  color: ", ";\n"])), rem(16), colors.space.tint700);
export var Title = styled.h4(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), rem(10));
export var Description = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), rem(14));
export var FieldsContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
