var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  padding: ", ";\n  padding-bottom: 0;\n  text-align: center;\n"], ["\n  font-size: ", ";\n  padding: ", ";\n  padding-bottom: 0;\n  text-align: center;\n"])), rem(15), rem(20));
export var Username = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-decoration: none;\n  color: ", ";\n"], ["\n  text-decoration: none;\n  color: ", ";\n"])), colors.base.primary);
var templateObject_1, templateObject_2;
