var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var MedalCommentDesktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.3005 19.6787H3.95489C2.87969 19.6787 2 20.5536 2 21.6223V34.2561C2 35.325 2.8799 36.1997 3.95489 36.1997H9.29519L12.4828 40.92L13.9921 36.1997H32.3005C33.3757 36.1997 34.2554 35.325 34.2554 34.2561V21.6223C34.2554 20.5534 33.3757 19.6787 32.3005 19.6787Z", fill: "#8EC26E" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.4199 26.058C26.5543 26.058 25.8464 26.7661 25.8464 27.6315C25.8464 28.4969 26.5543 29.2049 27.4199 29.2049C28.2853 29.2049 28.9932 28.4969 28.9932 27.6315C28.9932 26.7661 28.2853 26.058 27.4199 26.058Z", fill: "#709857" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.1277 26.058C17.2623 26.058 16.5542 26.7661 16.5542 27.6315C16.5542 28.4969 17.2623 29.2049 18.1277 29.2049C18.9931 29.2049 19.701 28.4969 19.701 27.6315C19.701 26.7661 18.9931 26.058 18.1277 26.058Z", fill: "#709857" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.83554 26.058C7.97013 26.058 7.26201 26.7661 7.26201 27.6315C7.26201 28.4969 7.97013 29.2049 8.83554 29.2049C9.70095 29.2049 10.4091 28.4969 10.4091 27.6315C10.4091 26.7661 9.70095 26.058 8.83554 26.058Z", fill: "#709857" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M35.9006 6C36.9758 6 37.8552 6.87486 37.8552 7.94355V20.5774C37.8552 21.6463 36.9756 22.5209 35.9006 22.5209H30.5603L27.3727 27.2413L25.8634 22.5209H7.55497C6.47977 22.5209 5.60008 21.6465 5.60008 20.5774V7.94355C5.60008 6.87465 6.47977 6 7.55497 6H35.9006Z", fill: "#BCE2A5" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.5734 12.3C13.439 12.3 14.1469 13.0079 14.1469 13.8733C14.1469 14.7387 13.439 15.4469 12.5734 15.4469C11.708 15.4469 11.0001 14.7387 11.0001 13.8733C11.0001 13.0079 11.708 12.3 12.5734 12.3Z", fill: "#8EC26E" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.8656 12.3C22.731 12.3 23.4389 13.0079 23.4389 13.8733C23.4389 14.7387 22.731 15.4469 21.8656 15.4469C21.0002 15.4469 20.2921 14.7387 20.2921 13.8733C20.2921 13.0079 21.0002 12.3 21.8656 12.3Z", fill: "#8EC26E" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.1577 12.3C32.0231 12.3 32.7312 13.0079 32.7312 13.8733C32.7312 14.7387 32.0231 15.4469 31.1577 15.4469C30.2923 15.4469 29.5844 14.7387 29.5844 13.8733C29.5844 13.0079 30.2923 12.3 31.1577 12.3Z", fill: "#8EC26E" })));
});
