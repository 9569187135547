import { Button } from 'components';
import { Subtitle, Title } from 'containers/Home';
import { paths } from 'containers/RoutesProvider/routes';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { DesktopPhotoMosaic, MobilePhotoMosaic } from './PhotoMosaic';
import { Content, StyledSection, Wrapper } from './styles';
export var BrowsePlaces = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (React.createElement(StyledSection, null,
        React.createElement(Wrapper, null,
            React.createElement(Content, null,
                React.createElement(Title, null, formatMessage({ id: 'containers.homeBrowsePlaces.title' })),
                React.createElement(Subtitle, { style: { width: '100%' } }, formatMessage({ id: 'containers.homeBrowsePlaces.subtitle' })),
                (isMobile || isTabletOrMobile) && React.createElement(MobilePhotoMosaic, null),
                React.createElement(Button, { onClick: function () {
                        return history.push(paths.explore, { from: location.pathname });
                    } }, formatMessage({ id: 'containers.homeBrowsePlaces.button' }))),
            !(isMobile || isTabletOrMobile) && React.createElement(DesktopPhotoMosaic, null))));
});
