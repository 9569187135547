var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Popover } from 'components';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import get from 'lodash/get';
import map from 'lodash/map';
import split from 'lodash/split';
import trim from 'lodash/trim';
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { supportedLangs } from 'translations';
import { useAction, useSelector } from 'utils/hooks';
import { DEFAULT_APP_LOCALE, locales } from 'utils/locale';
import { Button, Content, Target } from './styles';
export var LanguageSwitcher = memo(function () {
    var _a = __read(useState(true), 2), firstLocaleChange = _a[0], setFirstLocaleChange = _a[1];
    var locale = useSelector(makeSelectLocale());
    var changeLocaleCallback = useAction(changeLocale);
    var location = useLocation();
    var history = useHistory();
    var _b = __read(useState(false), 2), visible = _b[0], setVisible = _b[1];
    useEffect(function () {
        if (firstLocaleChange) {
            setFirstLocaleChange(false);
            return;
        }
        window.location.reload(false);
    }, [locale]);
    var handleChange = function (code) {
        changeLocaleCallback(code);
        var updatedUrlLanguage = "/" + code;
        if (location.pathname !== '/') {
            var currentUrlLanguage = split(trim(location.pathname), '/')[1];
            updatedUrlLanguage = location.pathname.replace("/" + currentUrlLanguage + "/", "/" + code + "/");
        }
        // console.log(updatedUrlLanguage);
        // window.location.replace(updatedUrlLanguage);
        history.push(updatedUrlLanguage);
    };
    var content = (React.createElement(Content, null, map(locales, function (code) { return (React.createElement(Button, { key: code, type: "button", value: code, onClick: function () { return handleChange(code); }, role: "menuitem" }, get(supportedLangs, code, DEFAULT_APP_LOCALE))); })));
    return (React.createElement(Popover, { content: content, trigger: "click", visible: visible, placement: "bottomRight", onVisibleChange: function () { return setVisible(!visible); } },
        React.createElement(Target, { variant: "link", tabIndex: 0, "aria-haspopup": "true" }, get(supportedLangs, locale, DEFAULT_APP_LOCALE))));
});
