var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { breakpoints, colors } from 'styles';
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var MembersListContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  flex: 1;\n  margin-top: ", ";\n"])), rem(8));
export var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  padding: ", ";\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  padding: ", ";\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n\n  ",
    "\n"])), rem(20), breakpoints.medium, ifProp('isKeyboardMode', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      > div:focus {\n        outline: 0;\n        position: relative;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% - ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n    "], ["\n      > div:focus {\n        outline: 0;\n        position: relative;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% - ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n    "])), border, rem(10), rem(2), rem(10), rem(-10))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
