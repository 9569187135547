import { avatarImage1 } from 'assets/images';
import { GlobalScoreboardUser, LoadingIndicator, Podium } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import map from 'lodash/map';
import React from 'react';
import { GlobalScoreboardRankContainer, LoadingIndicatorWrapper, UserList, } from './styles';
export var Rank = function (_a) {
    var podiumUsers = _a.podiumUsers, restUsers = _a.restUsers, loggedUserSlug = _a.loggedUserSlug, useContributions = _a.useContributions, isLoading = _a.isLoading, isLoggedIn = _a.isLoggedIn;
    return (React.createElement(GlobalScoreboardRankContainer, null,
        React.createElement(Podium, { users: podiumUsers, loggedUserSlug: loggedUserSlug, useContributions: useContributions }),
        restUsers.length > 0 && (React.createElement(UserList, { isLoggedIn: isLoggedIn },
            map(restUsers, function (user, index) {
                var _a;
                return (React.createElement(GlobalScoreboardUser, { key: String(user.id), position: Number(index) + 4, avatar: ((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.urlThumb) || avatarImage1, firstName: user.firstName, lastName: user.lastName, slug: user.slug, points: useContributions ? user.contributions || 0 : user.points || 0, pageURL: loggedUserSlug === user.slug
                        ? paths.profilePage
                        : paths.usersPage.replace(':userSlug', user.slug) }));
            }),
            isLoading && (React.createElement(LoadingIndicatorWrapper, null,
                React.createElement(LoadingIndicator, null)))))));
};
