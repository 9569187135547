var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var BlockquoteContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: ", ";\n  padding-left: ", ";\n  position: relative;\n\n  &:before {\n    content: '';\n    background-color: ", ";\n    border-radius: ", ";\n    width: ", ";\n    height: calc(100% + ", ");\n    position: absolute;\n    top: ", ";\n    left: 0;\n  }\n"], ["\n  margin-top: ", ";\n  padding-left: ", ";\n  position: relative;\n\n  &:before {\n    content: '';\n    background-color: ", ";\n    border-radius: ", ";\n    width: ", ";\n    height: calc(100% + ", ");\n    position: absolute;\n    top: ", ";\n    left: 0;\n  }\n"])), rem(32), rem(18), colors.base.warning, rem(4), rem(5), rem(16), rem(-8));
export var Title = styled.h5(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n"])), fonts.weight.semibold, rem(18), rem(8));
export var Message = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  line-height: 1.25;\n"], ["\n  font-size: ", ";\n  line-height: 1.25;\n"])), rem(14));
var templateObject_1, templateObject_2, templateObject_3;
