import { Button, EmailField, PasswordField, PasswordScore, PlacesAutocomplete, PseudoField, UsersProfilesField, } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { Form } from 'formik';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { AddressInfo } from './AddressInfo';
import messages from './messages';
import { AgreementText, StyledCheckbox } from './styles';
var SignUpForm = function (_a) {
    var values = _a.values, status = _a.status, handleChange = _a.handleChange, handleBlur = _a.handleBlur, handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting;
    var formatMessage = useIntl().formatMessage;
    var location = useLocation();
    return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
        React.createElement(EmailField, { required: true, showLabel: true, name: "email", value: values.email, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting }),
        React.createElement(PseudoField, { required: true, showLabel: true, name: "pseudo", value: values.pseudo, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting }),
        React.createElement(PasswordField, { required: true, name: "password", value: values.password, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting }),
        values.password.length > 0 && (React.createElement(PasswordScore, { password: values.password })),
        React.createElement(PlacesAutocomplete
        // @ts-ignore
        , { 
            // @ts-ignore
            required: true, type: "text", name: "googlePlaceId", onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting, afterLabel: React.createElement(AddressInfo, null) }),
        React.createElement(UsersProfilesField, { required: true, name: "profileId", value: values.profileId, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting }),
        React.createElement(AgreementText, null,
            formatMessage({ id: 'containers.signUpEmail.termsAndCoditions' }),
            React.createElement(Link, { to: {
                    pathname: paths.termsAndConditionsPage,
                    state: { from: location.pathname },
                } }, formatMessage({
                id: 'containers.signUpEmail.termsAndCoditionsLink',
            }))),
        React.createElement(StyledCheckbox, { name: "acceptsNewsletter", onChange: handleChange }, formatMessage(messages.acceptsNewsletterLabel)),
        (status === null || status === void 0 ? void 0 : status.api) && React.createElement("p", null, status === null || status === void 0 ? void 0 : status.api),
        React.createElement(Button, { type: "submit", variant: "primary", style: { marginBottom: rem(20), marginTop: rem(20) }, full: true }, formatMessage({ id: 'containers.signUpEmail.registrationButton' }))));
};
export default SignUpForm;
