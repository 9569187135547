var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import React, { memo } from 'react';
import { Body, ChildrenWrapper, Container, Content, Fullname, Username, } from './styles';
var UserProfileCard = function (_a) {
    var _b;
    var user = _a.user, _c = _a.hasUsernameParentheses, hasUsernameParentheses = _c === void 0 ? false : _c, _d = _a.size, size = _d === void 0 ? 'medium' : _d, children = _a.children, onClick = _a.onClick, rest = __rest(_a, ["user", "hasUsernameParentheses", "size", "children", "onClick"]);
    return (React.createElement(Container, __assign({}, rest),
        React.createElement(Body, { onClick: onClick },
            React.createElement(Avatar, { src: ((_b = user === null || user === void 0 ? void 0 : user.avatar) === null || _b === void 0 ? void 0 : _b.urlThumb) || avatarImage1, alt: user.firstName
                    ? user.firstName + " profile image"
                    : 'User profile image', size: size }),
            React.createElement(Content, { size: size },
                user.firstName && (React.createElement(Fullname, { size: size }, ((user === null || user === void 0 ? void 0 : user.firstName) || '') + " " + ((user === null || user === void 0 ? void 0 : user.lastName) || ''))),
                user.firstName ? (React.createElement(Username, { size: size }, hasUsernameParentheses ? "(@" + user.slug + ")" : "@" + user.slug)) : (React.createElement(Fullname, { size: size }, hasUsernameParentheses ? "(@" + user.slug + ")" : "@" + user.slug)))),
        React.createElement(ChildrenWrapper, null, children)));
};
export default memo(UserProfileCard);
