/** *
 * ScorePassword
 *
 * Show and hide password and calculate the risky of password based on the score below
 *
 * 0 too guessable: risky password. (guesses < 10^3)
 * 1 very guessable: protection from throttled online attacks. (guesses < 10^6)
 * 2 somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
 * 3 safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
 * 4 very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
 */
import { ProgressBar } from 'components';
import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { colors } from 'styles';
import zxcvbn from 'zxcvbn';

import { Container, PasswordStrength, PasswordTips } from './styles';
import { PasswordScoreDescription, ProgressBarVariant } from './types';

export const PasswordScore = ({ password }) => {
  const [strength, setStrength] = useState(0);
  const [variant, setVariant] = useState('');
  const [description, setDescription] = useState(
    PasswordScoreDescription.VeryWeak,
  );
  const { formatMessage } = useIntl();

  useEffect(() => {
    const { score } = zxcvbn(password);
    switch (score) {
      case 0:
        setVariant(ProgressBarVariant.Gray);
        setDescription(PasswordScoreDescription.VeryWeak);
        break;
      case 1:
        setVariant(ProgressBarVariant.Danger);
        setDescription(PasswordScoreDescription.Weak);
        break;
      case 2:
        setVariant(ProgressBarVariant.Warning);
        setDescription(PasswordScoreDescription.Fair);
        break;
      case 3:
        setVariant(ProgressBarVariant.Info);
        setDescription(PasswordScoreDescription.Fair);
        break;
      case 4:
        setVariant(ProgressBarVariant.Success);
        setDescription(PasswordScoreDescription.Strong);
        break;
      default:
        break;
    }
    setStrength(score);
  }, [password]);

  if (password && password.length === 0) {
    return null;
  }

  return (
    <Container>
      <ProgressBar
        percent={strength * 25}
        showInfo={false}
        strokeColor={colors.base[variant]}
      />

      <PasswordStrength color={variant}>
        <FormattedMessage id={description} />
      </PasswordStrength>

      <PasswordTips>
        {formatMessage(
          { id: 'components.passwordScore.tips' },
          {
            tipsHighlight: (
              <strong key={1}>
                {formatMessage({
                  id: 'components.passwordScore.tipsHighlight',
                })}
              </strong>
            ),
          },
        )}
        <ul>
          <li>{formatMessage({ id: 'components.passwordScore.tipOne' })}</li>
          <li>{formatMessage({ id: 'components.passwordScore.tipTwo' })}</li>
          <li>{formatMessage({ id: 'components.passwordScore.tipThree' })}</li>
        </ul>
      </PasswordTips>
    </Container>
  );
};

export default memo(PasswordScore);
