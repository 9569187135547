var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: colors.space.tint400,
    dark: colors.carbon.tint500,
});
export var SideListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var Title = styled.h5(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  height: ", ";\n  padding: ", " 0 ", " ", ";\n  position: relative;\n  z-index: -1;\n\n  &:before {\n    background-color: ", ";\n    border-radius: ", ";\n    content: '';\n    height: ", ";\n    left: ", ";\n    position: absolute;\n    top: ", ";\n    width: ", ";\n  }\n"], ["\n  border-bottom: ", " solid ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  height: ", ";\n  padding: ", " 0 ", " ", ";\n  position: relative;\n  z-index: -1;\n\n  &:before {\n    background-color: ", ";\n    border-radius: ", ";\n    content: '';\n    height: ", ";\n    left: ", ";\n    position: absolute;\n    top: ", ";\n    width: ", ";\n  }\n"])), rem(1), border, rem(18), fonts.weight.semibold, rem(57), rem(23), rem(13), rem(24), colors.base.secondary, rem(3), rem(18), rem(16), rem(24), rem(3));
export var Text = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: ", ";\n"], ["\n  margin-right: ", ";\n"])), rem(16));
export var ListItem = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n"], ["\n  border-bottom: ", " solid ", ";\n"])), rem(1), border);
export var LinkStyled = styled(NavLink)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  font-size: ", ";\n  padding: ", " ", ";\n  text-decoration: none;\n  transition: background-color 0.3s;\n\n  &:focus {\n    outline-offset: -6px !important;\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  svg {\n    flex-shrink: 0;\n    margin-left: auto;\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  font-size: ", ";\n  padding: ", " ", ";\n  text-decoration: none;\n  transition: background-color 0.3s;\n\n  &:focus {\n    outline-offset: -6px !important;\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  svg {\n    flex-shrink: 0;\n    margin-left: auto;\n  }\n"])), rgba(colors.base.black, 0.05), color, rem(14), rem(12), rem(16), rgba(colors.base.black, 0.1));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
