import { ADD_PLACE, ADD_PLACE_FAILURE, ADD_PLACE_SUCCESS, CLEAR_VALUES, IGNORE_SUGGESTED_PLACES, MATCH_PLACE, MATCH_PLACE_FAILURE, MATCH_PLACE_SUCCESS, } from './constants';
export var matchPlace = function (payload) { return ({
    type: MATCH_PLACE,
    payload: payload,
}); };
export var matchPlaceSuccess = function (payload) { return ({
    type: MATCH_PLACE_SUCCESS,
    payload: payload,
}); };
export var matchPlaceFailure = function () { return ({
    type: MATCH_PLACE_FAILURE,
}); };
export var clearValues = function () { return ({
    type: CLEAR_VALUES,
}); };
export var ignoreSuggestedPlaces = function () { return ({
    type: IGNORE_SUGGESTED_PLACES,
}); };
export var addPlace = function (payload) { return ({
    type: ADD_PLACE,
    payload: payload,
}); };
export var addPlaceFailure = function () { return ({
    type: ADD_PLACE_FAILURE,
}); };
export var addPlaceSuccess = function () { return ({
    type: ADD_PLACE_SUCCESS,
}); };
