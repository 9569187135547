import { createSelector } from 'reselect';
import { homeNewsInitialState } from './reducer';
export var selectHomeNewsDomain = function (state) {
    return state.homeNews || homeNewsInitialState;
};
export var makeSelectHomeNewsLoading = function () {
    return createSelector(selectHomeNewsDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectHomeNewsHasError = function () {
    return createSelector(selectHomeNewsDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectHomeNewsError = function () {
    return createSelector(selectHomeNewsDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectHomeNewsLatestPlacesUpdates = function () {
    return createSelector(selectHomeNewsDomain, function (_a) {
        var latestUpdates = _a.latestUpdates;
        return latestUpdates;
    });
};
export var makeSelectHomeNewsInTheWorld = function () {
    return createSelector(selectHomeNewsDomain, function (_a) {
        var inTheWorld = _a.inTheWorld;
        return inTheWorld;
    });
};
export var makeHomeNews = function () {
    return createSelector(selectHomeNewsDomain, function (subState) { return subState; });
};
export default makeHomeNews;
