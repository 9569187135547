var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { notification } from 'antd';
import * as api from 'api';
import { Button, EmailField, Label } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { inviteUserTeamSchema } from './schemas';
import { ButtonWrapper } from './styles';
export var ChallengeInviteUserTeam = function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var params = useParams();
    var onSubmit = function (value, actions) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, response, error_1, err;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    actions.setSubmitting(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    payload = {
                        emails: value.email,
                    };
                    return [4 /*yield*/, api.challenge.inviteUserOnTeam(params.challengeSlug, params.teamSlug, payload)];
                case 2:
                    response = _b.sent();
                    actions.resetForm();
                    if (response.status === 204) {
                        notification.success({
                            message: formatMessage({
                                id: 'containers.challengeInviteUserTeam.inviteSuccess',
                            }),
                        });
                        history.push(paths.membersTeamDetailPage
                            .replace(':challengeSlug', params.challengeSlug)
                            .replace(':teamSlug', params.teamSlug), { from: location.pathname });
                    }
                    actions.setSubmitting(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    err = error_1;
                    switch ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) {
                        default:
                            actions.setStatus({ api: err.message });
                            actions.setSubmitting(false);
                            break;
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Formik, { validationSchema: function () { return inviteUserTeamSchema(formatMessage); }, onSubmit: function (values, actions) { return onSubmit(values, actions); }, initialValues: { email: '' } }, function (_a) {
        var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur, values = _a.values, isSubmitting = _a.isSubmitting;
        return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
            React.createElement(Label, null, formatMessage({
                id: 'containers.challengeInviteUserTeam.label',
            })),
            React.createElement(EmailField, { value: values.email, required: true, name: "email", onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting, showLabel: false }),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { variant: "ghost", onClick: function () {
                        return history.push(paths.membersTeamDetailPage
                            .replace(':challengeSlug', params.challengeSlug)
                            .replace(':teamSlug', params.teamSlug), { from: location.pathname });
                    } }, formatMessage({
                    id: 'containers.challengeInviteUserTeam.cancelButton',
                })),
                React.createElement(Button, { type: "submit" }, formatMessage({
                    id: 'containers.challengeInviteUserTeam.button',
                })))));
    }));
};
