import { Content, Section, Subtitle, Title } from 'containers/Home';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { Locale } from 'containers/LanguageProvider/types';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'utils/hooks';
import { Blockquote } from './Blockquote';
import { Video } from './styles';
export var LearnMore = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var renderFRVideo = function () { return (React.createElement("iframe", { title: "Jaccede, c'est quoi?", width: "560", height: "315", src: "https://www.youtube.com/embed/fKLBX_GXANU", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })); };
    var renderDEVideo = function () { return (React.createElement("iframe", { title: "Was ist Jaccede?", width: "560", height: "315", src: "https://www.youtube.com/embed/nBN18ZadtWA", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })); };
    var renderENVideo = function () { return (React.createElement("iframe", { title: "What is Jaccede?", width: "727", height: "409", src: "https://www.youtube.com/embed/q7yEDQNozIQ", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })); };
    var renderVideo = function () {
        switch (locale) {
            case Locale.FR:
                return renderFRVideo();
            case Locale.DE:
                return renderDEVideo();
            default:
                return renderENVideo();
        }
    };
    return (React.createElement(Section, { background: "secondary" },
        React.createElement(Title, { underline: "warning" }, formatMessage({ id: 'containers.homeLearnMore.title' })),
        isMobile || isTabletOrMobile ? (React.createElement(Subtitle, null, formatMessage({ id: 'containers.homeLearnMore.mobileSubtitle' }))) : (React.createElement(Subtitle, null, formatMessage({ id: 'containers.homeLearnMore.desktopSubtitle' }))),
        React.createElement(Content, null,
            React.createElement(Video, null, renderVideo())),
        (isMobile || isTabletOrMobile) && React.createElement(Blockquote, null)));
});
