var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { notification } from 'antd';
import { Button, FloatButton, Modal, PlacePhotoScroll, Spin, TextAreaField, } from 'components';
import makeSelectAuth, { makeSelectUser } from 'containers/Auth/selectors';
import { Map } from 'containers/Map';
import { clearNotification, deletePhoto, getPhotos, putPhotos, } from 'containers/PlacePhotos/actions';
import { makeSelectorDeletePhotoFailure, makeSelectorDeletePhotoSuccess, makeSelectPlacePhotos, } from 'containers/PlacePhotos/selectors';
import { ReviewsList } from 'containers/Reviews';
import { Formik } from 'formik';
import { Alert, CamSolid, Edit } from 'icons/mono';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { colors } from 'styles';
import { useAction, useSelector } from 'utils/hooks';
import { placeVisitedEvent } from 'utils/tagEvents';
import { makeSelectIsLoggedIn } from '../Auth/selectors';
import { clearReported, setReportReviewVisible, } from '../Reviews/store/addReview/actions';
import { makeSelectAddReportReviewError } from '../Reviews/store/addReview/selectors';
import { paths } from '../RoutesProvider/routes';
import { Accessibility } from './Accessibility';
import { AccessibilityPhotos } from './AccessibilityPhotos';
import { addPlaceToBookmark, clearReportedPhoto, removePlaceFromBookmark, reportPlace, } from './actions';
import { BookmarkButton } from './BookmarkButton';
import { EvaluateButton } from './EvaluateButton';
import { Information } from './Information';
import { LastUpdatedInfo } from './LastUpdatedInfo';
import { Ratings } from './Ratings';
import { ReportPhotosModal } from './ReportPhotosModal';
import { ReportProblem } from './ReportProblem';
import { SearchPlacesAround } from './SearchPlacesAround';
import { makeSelectorAlreadyReportedPhoto, makeSelectorBookmarkLoading, makeSelectorLoading, } from './selectors';
import { Sharing } from './Sharing';
import { ButtonWrapper, FloatButtonWrapper, MapContainerDesktop, MapContainerMobile, ModalButtonWrapper, ModalMessage, PlaceDetailsContainer, ReportButton, ReviewListWrapper, Sidebar, } from './styles';
export var PlaceDetails = function (_a) {
    var place = _a.place;
    var _b, _c, _d;
    var formatMessage = useIntl().formatMessage;
    var informationLoading = useSelector(makeSelectorLoading());
    var bookmarkLoading = useSelector(makeSelectorBookmarkLoading());
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var user = useSelector(makeSelectUser());
    var photos = useSelector(makeSelectPlacePhotos());
    var auth = useSelector(makeSelectAuth());
    var reportReviewError = useSelector(makeSelectAddReportReviewError());
    var alreadyReported = useSelector(makeSelectorAlreadyReportedPhoto());
    var photoFailure = useSelector(makeSelectorDeletePhotoFailure());
    var photoDeleteSuccess = useSelector(makeSelectorDeletePhotoSuccess());
    var addPlaceToBookmarkCallback = useAction(function (payload, placeName) {
        return addPlaceToBookmark(payload, placeName);
    });
    var removePlaceFromBookmarkCallback = useAction(function (payload, placeName) {
        return removePlaceFromBookmark(payload, placeName);
    });
    var getPhotosCallback = useAction(getPhotos);
    var putPhotosCallback = useAction(putPhotos);
    var reportPlaceCallback = useAction(reportPlace);
    var clearReportedCallback = useAction(clearReported);
    var deletePhotoCallback = useAction(deletePhoto);
    var clearNotificationCallback = useAction(clearNotification);
    var clearReportedPhotoCallback = useAction(clearReportedPhoto);
    var setReportReviewVisibleCallback = useAction(setReportReviewVisible);
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var placeId = useParams().placeId;
    var placeName = useParams().placeName;
    var _e = __read(useState(), 2), mapLoading = _e[0], setMapLoading = _e[1];
    var _f = __read(useState(false), 2), showReportPhotoModal = _f[0], setShowReportPhotoModal = _f[1];
    var _g = __read(useState(), 2), currentPhotoModal = _g[0], setCurrentPhotoModal = _g[1];
    var _h = __read(useState(false), 2), reportModalVisible = _h[0], setReportModalVisible = _h[1];
    var history = useHistory();
    var mapOptions = {
        streetViewControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
        center: {
            lat: parseFloat(place.latitude),
            lng: parseFloat(place.longitude),
        },
    };
    useEffect(function () {
        if (place.id) {
            getPhotosCallback(placeId);
            placeVisitedEvent(place.name);
        }
    }, [place, placeId]);
    var _j = __read(useState(false), 2), showReportModal = _j[0], setShowReportModal = _j[1];
    var handleShowModal = function () {
        setShowReportModal(true);
    };
    var handleCancelModal = function () {
        setShowReportModal(false);
    };
    var handleRedirectAddPhoto = function () {
        var googlePlaceId = place.googlePlaceId;
        var url = paths.PlacePhotosPage.replace(':placeId', "" + googlePlaceId);
        if (!user) {
            return history.push(paths.signInPage, { from: url });
        }
        history.push(url);
    };
    var handleShowReportPhotoModal = function (swiperRef) {
        if (swiperRef.current && swiperRef.current.swiper) {
            var currentPhoto = photos[swiperRef.current.swiper.realIndex];
            setCurrentPhotoModal(currentPhoto.id);
            setShowReportPhotoModal(true);
        }
    };
    var handleSubmitReportProblem = function (_a) {
        var message = _a.message;
        reportPlaceCallback({ placeId: placeId, message: message });
    };
    var success = false;
    useEffect(function () {
        if (reportReviewError) {
            notification.error({
                message: formatMessage({ id: 'containers.placeDetails.error' }),
                description: reportReviewError,
                onClose: function () {
                    clearReportedCallback();
                    setReportReviewVisibleCallback(false);
                },
            });
        }
    }, [reportReviewError]);
    useEffect(function () {
        if (alreadyReported) {
            notification.error({
                message: formatMessage({ id: 'containers.placeDetails.error' }),
                description: formatMessage({
                    id: 'containers.placeDetails.reportedPhoto.alreadyReported',
                }),
                onClose: function () {
                    clearReportedPhotoCallback();
                    setShowReportPhotoModal(false);
                },
            });
        }
    }, [alreadyReported]);
    useEffect(function () {
        if (photoFailure) {
            notification.error({
                message: formatMessage({ id: 'containers.placeDetails.error' }),
                description: photoFailure.message,
                onClose: clearNotificationCallback,
            });
        }
    }, [photoFailure]);
    useEffect(function () {
        if (photoDeleteSuccess) {
            notification.success({
                message: formatMessage({ id: 'containers.placeDetails.success' }),
                description: formatMessage({
                    id: 'containers.editMyProfile.photoDeleteSuccess',
                }),
                onClose: clearNotificationCallback,
            });
            clearNotificationCallback();
        }
    }, [photoDeleteSuccess]);
    var handleDeletePhoto = function (photoId) {
        deletePhotoCallback({ placeId: placeId, photoId: photoId });
    };
    var handleFavority = function (swiperRef) {
        if (swiperRef.current && swiperRef.current.swiper) {
            var currentPhoto = photos[swiperRef.current.swiper.realIndex];
            putPhotosCallback("" + (place === null || place === void 0 ? void 0 : place.googlePlaceId), currentPhoto.id);
        }
    };
    return (React.createElement(PlaceDetailsContainer, null,
        React.createElement(Sidebar, null,
            React.createElement(PlacePhotoScroll, { placeDetails: place, photos: photos, imageHeight: 230, handleFavority: handleFavority, handleModal: handleShowReportPhotoModal, onDeletePhoto: handleDeletePhoto, bookmarkButton: isAuthenticated && (React.createElement(BookmarkButton, { loading: bookmarkLoading, addToBookmark: function () {
                        return addPlaceToBookmarkCallback(placeId, place.name);
                    }, removeFromBookmark: function () {
                        return removePlaceFromBookmarkCallback(placeId, place.name);
                    }, inBookmark: !!(place === null || place === void 0 ? void 0 : place.isFavorite) })) }),
            auth.isLoggedIn && (React.createElement(React.Fragment, null,
                React.createElement(ReportButton, { onClick: function () { return setReportModalVisible(true); }, title: "Report place" },
                    React.createElement("span", null,
                        React.createElement(Alert, { size: rem(24), color: colors.base.white }))),
                React.createElement(ReportProblem, { onSubmit: handleSubmitReportProblem, onClose: function () { return setReportModalVisible(false); }, visible: reportModalVisible }))),
            success ? (React.createElement(Modal, { title: formatMessage({
                    id: 'containers.placeDetails.report.title',
                }), visible: showReportModal, footer: null, onCancel: handleShowModal },
                React.createElement(ModalMessage, null, formatMessage({
                    id: 'containers.placeDetails.reported.message',
                })),
                React.createElement(ModalButtonWrapper, null,
                    React.createElement(Button, { onClick: handleShowModal },
                        React.createElement(FormattedMessage, { id: "containers.placeDetails.reported.button" }))))) : (React.createElement(Modal, { title: formatMessage({
                    id: 'containers.placeDetails.report.title',
                }), visible: showReportModal, footer: null, onCancel: handleCancelModal },
                React.createElement(Formik, { initialValues: {}, onSubmit: function () { } },
                    React.createElement("form", null,
                        React.createElement(TextAreaField, { rows: 4, name: "message", label: formatMessage({
                                id: 'containers.placeDetails.report.label',
                            }), placeholder: formatMessage({
                                id: 'containers.placeDetails.report.placeholder',
                            }), 
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onBlur: function () { }, 
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange: function () { }, value: "" }),
                        React.createElement(ModalButtonWrapper, null,
                            React.createElement(Button, { type: "submit" },
                                React.createElement(FormattedMessage, { id: "containers.placeDetails.report.button" }))))))),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { size: "small", shape: "pill", 
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: function () {
                        handleRedirectAddPhoto();
                    }, icon: React.createElement(CamSolid, { size: rem(16), color: "white" }) },
                    React.createElement(FormattedMessage, { id: "containers.placeDetails.addPhotos" }))),
            React.createElement(ReportPhotosModal, { placeId: place === null || place === void 0 ? void 0 : place.googlePlaceId, mediaId: currentPhotoModal, showReportModal: showReportPhotoModal, setShowReportModal: setShowReportPhotoModal }),
            React.createElement(Information, { placeDetails: place, loading: informationLoading }),
            React.createElement(Accessibility, { values: place === null || place === void 0 ? void 0 : place.accessibility, contributors: place === null || place === void 0 ? void 0 : place.totalContributors, loading: informationLoading }),
            photos && (React.createElement(AccessibilityPhotos, { redirectAddPhoto: handleRedirectAddPhoto, photos: photos, handleFavority: handleFavority, handleModal: handleShowReportPhotoModal, onDeletePhoto: handleDeletePhoto })),
            (isMobile || isTabletOrMobile) && (React.createElement(Spin, { spinning: mapLoading },
                React.createElement(MapContainerMobile, null,
                    React.createElement(Map, { placeId: place === null || place === void 0 ? void 0 : place.googlePlaceId, loadingCallback: setMapLoading, options: mapOptions, place: place })))),
            ((_b = place === null || place === void 0 ? void 0 : place.lastEditor) === null || _b === void 0 ? void 0 : _b.slug) && place.modified && (React.createElement(LastUpdatedInfo, { loggedUserSlug: user === null || user === void 0 ? void 0 : user.slug, slug: (_c = place === null || place === void 0 ? void 0 : place.lastEditor) === null || _c === void 0 ? void 0 : _c.slug, modifiedDate: place.modified })),
            React.createElement(SearchPlacesAround, null),
            React.createElement(Sharing, null),
            React.createElement(Ratings, { value: place === null || place === void 0 ? void 0 : place.rating }),
            React.createElement(EvaluateButton, { showAvatar: auth.isLoggedIn, avatar: (_d = auth.user) === null || _d === void 0 ? void 0 : _d.avatar, placeId: placeId }),
            React.createElement(ReviewListWrapper, null,
                React.createElement(ReviewsList, { place: place, placeId: placeId, showMoreReviewButton: true })),
            React.createElement(FloatButtonWrapper, null,
                React.createElement(FloatButton, { size: "small", shape: "pill", onClick: function () {
                        return history.push(paths.editPlacePage
                            .replace(':placeId', placeId)
                            .replace(':placeName', placeName));
                    }, icon: React.createElement(Edit, { size: rem(16), color: "white" }) },
                    React.createElement(FormattedMessage, { id: "containers.placeDetails.suggestChanges" })))),
        !(isMobile || isTabletOrMobile) && (React.createElement(MapContainerDesktop, null,
            React.createElement(Map, { placeId: place === null || place === void 0 ? void 0 : place.googlePlaceId, loadingCallback: setMapLoading, options: mapOptions, place: place })))));
};
