import React, { memo } from 'react';
export var ElectronicsStoreOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#b3b3b3", style: { fill: '#b3b3b3' }, d: "M15.848 30.171c8.247 0 14.933-6.686 14.933-14.933s-6.686-14.933-14.933-14.933c-8.247 0-14.933 6.686-14.933 14.933s6.686 14.933 14.933 14.933z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M23.401 10.737c-0.861 0-1.561 0.701-1.561 1.562 0 0.247 0.088 0.481 0.191 0.689l-1.612 1.647v1.542c-0.61 0.173-1.204 0.784-1.204 1.511 0 0.861 0.686 1.562 1.547 1.562s1.626-0.701 1.626-1.562c0-0.707-0.446-1.305-1.055-1.497v-1.213l1.292-1.369c0.245 0.158 0.5 0.251 0.812 0.251 0.861 0 1.543-0.7 1.543-1.561s-0.718-1.562-1.579-1.562zM21.481 17.688c0 0.404-0.329 0.732-0.732 0.732s-0.732-0.329-0.732-0.732c0-0.404 0.328-0.732 0.732-0.732s0.732 0.329 0.732 0.732zM23.401 13.031c-0.404 0-0.732-0.329-0.732-0.732s0.329-0.732 0.732-0.732c0.404 0 0.732 0.329 0.732 0.732s-0.329 0.732-0.732 0.732z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M14.019 19.828c1.219-0.020 1.587-0.711 1.587-1.559 0-0.417-0.119-0.809-0.414-1.104s-0.675-0.457-1.093-0.457c-0.417 0-0.803 0.162-1.098 0.457s-0.454 0.687-0.454 1.104c0 0.28 0.076 0.547 0.213 0.783l-1.237 1.238c-0.235-0.137-0.503-0.212-0.783-0.212-0.417 0-0.809 0.162-1.104 0.457s-0.457 0.687-0.457 1.104c0 0.861 0.701 1.561 1.562 1.561 0.417 0 0.809-0.162 1.104-0.457s0.457-0.687 0.457-1.104c0-0.27-0.069-0.529-0.197-0.758l1.222-1.247c0.218 0.121 0.387 0.189 0.692 0.195v0zM13.591 17.751c0.138-0.138 0.32-0.215 0.515-0.215s0.374 0.076 0.513 0.215c0.138 0.138 0.204 0.322 0.204 0.517 0 0.397-0.327 0.721-0.732 0.731-0.188-0.005-0.364-0.080-0.498-0.213-0.138-0.138-0.215-0.322-0.215-0.518s0.075-0.38 0.213-0.518zM11.259 22.177c-0.138 0.138-0.322 0.234-0.518 0.234-0.404 0-0.732-0.348-0.732-0.752 0-0.196 0.076-0.399 0.215-0.537s0.322-0.214 0.518-0.214 0.379 0.081 0.518 0.219c0.138 0.138 0.215 0.327 0.215 0.523s-0.076 0.389-0.215 0.527z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M18.286 20.208v-8.352l-1.421-1.416c0.080-0.188 0.113-0.395 0.113-0.611 0-0.861-0.707-1.561-1.568-1.561s-1.565 0.7-1.565 1.561c0 0.861 0.699 1.562 1.56 1.562 0.342 0 0.638-0.112 0.896-0.299l1.070 1.104v8.019c0 0.072-0.467 0.206-0.657 0.397-0.295 0.295-0.448 0.687-0.448 1.104s0.167 0.809 0.462 1.104 0.689 0.457 1.106 0.457c0.417 0 0.81-0.162 1.106-0.457 0.609-0.609 0.634-1.599 0.025-2.208-0.198-0.198-0.374-0.334-0.679-0.404zM15.404 10.561c-0.404 0-0.732-0.329-0.732-0.732s0.329-0.732 0.732-0.732c0.403 0 0.732 0.329 0.732 0.732s-0.329 0.732-0.732 0.732zM18.354 22.234c-0.138 0.138-0.322 0.214-0.518 0.214s-0.379-0.076-0.518-0.215c-0.138-0.138-0.214-0.322-0.214-0.518s0.076-0.379 0.215-0.518c0.138-0.138 0.322-0.214 0.518-0.214s0.379 0.076 0.518 0.214c0.285 0.286 0.285 0.75 0 1.036z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M11.276 9.004v3.097l1.556 1.534c-0.091 0.202-0.148 0.279-0.144 0.514 0.012 0.849 0.706 1.393 1.555 1.393h0.023c0.417 0 0.806-0.028 1.097-0.327s0.448-0.576 0.442-0.993c-0.012-0.849-0.712-1.422-1.561-1.422h-0.022c-0.324 0-0.569 0.064-0.816 0.237l-1.214-1.285v-2.737c0.61-0.177 1.123-0.876 1.113-1.608-0.012-0.849-0.767-1.616-1.616-1.616h-0.023c-0.417 0-0.807 0.251-1.097 0.55s-0.435 0.732-0.429 1.149c0.010 0.698 0.528 1.327 1.138 1.514zM14.233 13.714h0.011c0.398 0 0.727 0.23 0.732 0.629 0.003 0.196-0.071 0.3-0.207 0.441s-0.319 0.15-0.515 0.15h-0.010c-0.398 0-0.727-0.201-0.732-0.599-0.005-0.404 0.318-0.62 0.722-0.62zM11.162 6.961c0.136-0.14 0.319-0.256 0.515-0.256h0.011c0.398 0 0.726 0.354 0.732 0.752 0.006 0.404-0.318 0.772-0.722 0.772h-0.011c-0.398 0-0.726-0.336-0.732-0.734-0.002-0.196 0.071-0.393 0.208-0.533z" })));
});
