var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var Medal1000DisabledDesktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { opacity: "0.2", fillRule: "evenodd", clipRule: "evenodd", d: "M0.131012 25.4501C0.131012 14.5586 8.96005 5.72959 19.8516 5.72959C30.7425 5.72959 39.5716 14.5586 39.5716 25.4501C39.5716 36.3411 30.7425 45.1701 19.8516 45.1701C8.96005 45.1701 0.131012 36.3411 0.131012 25.4501Z", fill: "black" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.131012 23.5591C0.131012 12.6676 8.96005 3.83858 19.8516 3.83858C30.7425 3.83858 39.5716 12.6676 39.5716 23.5591C39.5716 34.4501 30.7425 43.2791 19.8516 43.2791C8.96005 43.2791 0.131012 34.4501 0.131012 23.5591Z", fill: "#E2F0F4" }),
        React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M-4.37589 30.4341L53.4044 7.94915L54.8844 11.7513L-2.89588 34.2363L-4.37589 30.4341Z", fill: "#CEDADD" })),
        React.createElement("mask", { id: "mask1", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask1)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M-5.74948 26.9041L52.0308 4.41912L52.7711 6.32049L-5.00976 28.8054L-5.74948 26.9041Z", fill: "#CEDADD" })),
        React.createElement("mask", { id: "mask2", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask2)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M-7.1234 23.3745L50.6574 0.888986L51.3972 2.79036L-6.38312 25.2758L-7.1234 23.3745Z", fill: "#CEDADD" })),
        React.createElement("mask", { id: "mask3", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask3)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.131012 48.5393L57.9118 26.0544L58.6516 27.9557L0.870738 50.4407L0.131012 48.5393Z", fill: "#CEDADD" })),
        React.createElement("mask", { id: "mask4", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask4)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.9138 26.4786C29.9138 20.8847 25.379 16.3515 19.785 16.3515C14.1916 16.3515 9.65677 20.8847 9.65677 26.4786C9.65677 32.0726 14.1916 36.6079 19.785 36.6079C25.379 36.6079 29.9138 32.0726 29.9138 26.4786Z", fill: "#CEDADD" })),
        React.createElement("mask", { id: "mask5", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask5)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.2056 9.63359L4.32674 -8.89244C4.23797 -8.99436 4.1092 -9.05463 3.97276 -9.05463H-2.56696C-2.74943 -9.05463 -2.91491 -8.94888 -2.99107 -8.78285C-3.06779 -8.61737 -3.04039 -8.4223 -2.92148 -8.28367L16.2276 14.0571C16.3158 14.1596 16.4456 14.2188 16.5821 14.2188C16.7185 14.2188 16.8478 14.1596 16.9366 14.0571L20.2062 10.2407C20.3558 10.0676 20.3558 9.80893 20.2056 9.63359Z", fill: "#9D66DD" })),
        React.createElement("mask", { id: "mask6", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask6)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.6939 -8.78307C42.6172 -8.9491 42.4522 -9.05485 42.2698 -9.05485H35.7306C35.5941 -9.05485 35.4643 -8.99458 35.3761 -8.89211L16.2276 13.4476C16.0774 13.6235 16.0774 13.8816 16.2276 14.0575L19.4972 17.8717C19.5854 17.9736 19.7147 18.0328 19.8511 18.0328C19.9876 18.0328 20.1169 17.9736 20.2056 17.8717L42.6237 -8.28389C42.7432 -8.42252 42.77 -8.61704 42.6939 -8.78307Z", fill: "#773BB3" })),
        React.createElement("mask", { id: "mask7", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask7)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.1922 25.0397C29.1922 19.8808 25.0103 15.6995 19.8514 15.6995C14.6924 15.6995 10.5105 19.8808 10.5105 25.0397C10.5105 30.1986 14.6924 34.3816 19.8514 34.3816C25.0103 34.3816 29.1922 30.1986 29.1922 25.0397Z", fill: "#F8E831" })),
        React.createElement("mask", { id: "mask8", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask8)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.3034 16.8281C14.7752 16.8281 11.0908 20.5113 11.0908 25.0396C11.0908 29.5683 14.7752 33.2533 19.3034 33.2533C23.8317 33.2533 27.5161 29.5683 27.5161 25.0396C27.5161 20.5113 23.8317 16.8281 19.3034 16.8281ZM19.3034 34.6149C14.024 34.6149 9.72865 30.3185 9.72865 25.0396C9.72865 19.7612 14.024 15.4664 19.3034 15.4664C24.5829 15.4664 28.8782 19.7612 28.8782 25.0396C28.8782 30.3185 24.5829 34.6149 19.3034 34.6149Z", fill: "#F7D131" })),
        React.createElement("mask", { id: "mask9", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask9)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.8514 18.9326L21.6722 22.6219L25.7446 23.2142L22.7983 26.0866L23.493 30.1408L19.8514 28.2263L16.2092 30.1408L16.9051 26.0866L13.9583 23.2142L18.0306 22.6219L19.8514 18.9326Z", fill: "#FF9400" })),
        React.createElement("mask", { id: "mask10", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "40", height: "41" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.130966 23.5589C0.130966 34.4504 8.96056 43.2789 19.8515 43.2789C30.743 43.2789 39.5715 34.4504 39.5715 23.5589C39.5715 12.6674 30.743 3.83836 19.8515 3.83836C8.96056 3.83836 0.130966 12.6674 0.130966 23.5589Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask10)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.8514 16.415C15.0952 16.415 11.2256 20.2835 11.2256 25.0396C11.2256 29.7963 15.0952 33.6659 19.8514 33.6659C24.6076 33.6659 28.4771 29.7963 28.4771 25.0396C28.4771 20.2835 24.6076 16.415 19.8514 16.415ZM19.8514 35.0966C14.3062 35.0966 9.79495 30.5837 9.79495 25.0396C9.79495 19.4955 14.3062 14.9848 19.8514 14.9848C25.396 14.9848 29.9073 19.4955 29.9073 25.0396C29.9073 30.5837 25.396 35.0966 19.8514 35.0966Z", fill: "#F8E831" })),
        React.createElement("g", { style: { mixBlendMode: 'color' } },
            React.createElement("rect", { width: "40", height: "48", fill: "white" }))));
});
