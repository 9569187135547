import produce from 'immer';
import { CHALLENGES_DETAIL_FAILURE, CHALLENGES_DETAIL_REQUEST, CHALLENGES_DETAIL_SUCCESS, } from './constants';
export var challengeDetailInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    item: null,
};
export var challengeDetailReducer = produce(function (draft, action) {
    switch (action.type) {
        case CHALLENGES_DETAIL_REQUEST:
            draft.loading = true;
            break;
        case CHALLENGES_DETAIL_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case CHALLENGES_DETAIL_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.item = action.payload;
            break;
        default:
    }
}, challengeDetailInitialState);
