import { placeholderPlace } from 'assets/images';
import { Avatar } from 'components';
import { format } from 'date-fns';
import React from 'react';
import { CardChallenge, ChallengeContent, ChallengeData, ChallengeDate, ChallengeImage, ChallengeName, } from './styles';
export var MyChallengeCard = function (_a) {
    var item = _a.item, showMobile = _a.showMobile, onClick = _a.onClick;
    var _b, _c;
    return (React.createElement(CardChallenge, { onClick: onClick },
        React.createElement(ChallengeImage, { src: ((_b = item.cover) === null || _b === void 0 ? void 0 : _b.urlLow) || placeholderPlace }),
        React.createElement(ChallengeContent, null,
            React.createElement(Avatar, { size: showMobile ? 'medium' : 'large', src: ((_c = item.logo) === null || _c === void 0 ? void 0 : _c.urlLow) || placeholderPlace, alt: item.description }),
            React.createElement(ChallengeData, null,
                React.createElement(ChallengeName, null, item.title),
                React.createElement(ChallengeDate, null,
                    format(new Date(item.startDatetime), 'MM/dd/yyyy HH:mm'),
                    " to",
                    ' ',
                    format(new Date(item.endDatetime), 'MM/dd/yyyy HH:mm'))))));
};
