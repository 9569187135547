import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editPassword';
var messages = defineMessages({
    title: { id: scope + ".title" },
    password: { id: scope + ".password" },
    confirmPassword: { id: scope + ".confirmPassword" },
    passwordRequiredMessage: { id: scope + ".passwordRequiredMessage" },
    passwordPolicy: { id: scope + ".passwordPolicy" },
    confirmPasswordRequiredMessage: {
        id: scope + ".confirmPasswordRequiredMessage",
    },
    passwordsNotMatching: {
        id: scope + ".passwordsNotMatching",
    },
    changePassword: {
        id: scope + ".changePassword",
    },
    fieldDescription: {
        id: 'containers.editMyProfile.identification.fieldDescription',
    },
    fieldInformation: {
        id: 'containers.editMyProfile.identification.fieldInformation',
    },
});
export default messages;
