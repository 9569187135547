import { LoadingIndicator, PageWrapper } from 'components';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { getPlaceDetails } from 'containers/PlaceDetails/actions';
import makeSelectorPlaceDetails, { makeSelectorLoading, } from 'containers/PlaceDetails/selectors';
import { Header, PlaceRating, ReviewsList } from 'containers/Reviews';
import { useAction, useSelector } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ReviewsListWrapper, ReviewsPageContainer } from './styles';
var ReviewsPage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var placeId = useParams().placeId;
    var placeDetails = useSelector(makeSelectorPlaceDetails());
    var placeDetailsIsLoading = useSelector(makeSelectorLoading());
    var getPlaceDetailsCallback = useAction(getPlaceDetails);
    useEffect(function () {
        if (isEmpty(placeDetails) || (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.googlePlaceId) !== placeId) {
            getPlaceDetailsCallback(placeId);
        }
    }, [placeId]);
    if (placeDetailsIsLoading || isEmpty(placeDetails)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageWrapper, null,
                React.createElement(ReviewsPageContainer, null,
                    React.createElement(Header, { title: formatMessage({ id: 'containers.reviews.headerTitle' }) }),
                    React.createElement(LoadingIndicator, null)))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.reviewsPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.reviewsPage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.reviewsPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.reviewsPage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(ReviewsPageContainer, null,
                React.createElement(Header, { title: formatMessage({ id: 'containers.reviews.headerTitle' }) }),
                React.createElement(ReviewsListWrapper, null,
                    React.createElement(PlaceRating, { value: placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.rating })),
                React.createElement(ReviewsList, { place: placeDetails, placeId: placeId, showPagination: true })))));
};
export default ReviewsPage;
