import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = function (provider) { return ({
    oAuthUrl: versionV4 + "/account/login/" + provider,
}); };
export default {
    getUrl: function (provider, from) {
        var oAuthUrl = endpoints(provider).oAuthUrl;
        var config = {
            params: {
                next: from,
            },
        };
        return axios.get(oAuthUrl, config);
    },
    login: function (request) {
        var provider = request.provider, accessToken = request.accessToken;
        var oAuthUrl = endpoints(provider).oAuthUrl;
        // eslint-disable-next-line @typescript-eslint/camelcase
        var payload = { access_token: accessToken };
        return axios.post(oAuthUrl, payload);
    },
};
