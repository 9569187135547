import map from 'lodash/map';
import React, { memo } from 'react';
import { Content } from '../Content';
import { Header } from '../Header';
import { SideList } from '../SideList';
import { AboutUsContainer, AboutUsMenu } from './styles';
export var Desktop = function (_a) {
    var menuData = _a.menuData, sectionTitle = _a.sectionTitle, sectionContent = _a.sectionContent, listItemSelected = _a.listItemSelected, setListItemSelected = _a.setListItemSelected;
    var Menu = memo(function () { return (React.createElement(AboutUsMenu, null, map(menuData, function (_a, idx) {
        var title = _a.title, items = _a.children;
        return (React.createElement(SideList, { key: idx, title: title, items: items }));
    }))); });
    return (React.createElement(AboutUsContainer, null,
        React.createElement(Header, { setListItemSelected: setListItemSelected, listItemSelected: listItemSelected, sectionTitle: sectionTitle }),
        React.createElement("div", null,
            React.createElement(Menu, null),
            sectionTitle !== '' && (React.createElement(Content, { sectionTitle: sectionTitle, sectionContent: sectionContent, setListItemSelected: setListItemSelected, listItemSelected: listItemSelected })))));
};
