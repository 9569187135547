var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { DEFAULT_CENTER, Maps } from 'components/Maps';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useGeolocation, useSelector } from 'hooks';
import { Locale } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { useRef, useState } from 'react';
import { InfoWindow, Marker } from 'react-google-maps';
import { colors } from 'styles';
import { CardPlaces, CardPlacesLink, UserLocation } from './styles';
export var ExploreMap = function (_a) {
    var lat = _a.lat, lng = _a.lng, customOptions = _a.customOptions, onDragEnd = _a.onDragEnd, places = _a.places, onZoomChanged = _a.onZoomChanged, onUserLocation = _a.onUserLocation, onCardOpen = _a.onCardOpen, onCardClose = _a.onCardClose, onMapReady = _a.onMapReady;
    var _b = __read(useState(''), 2), showInfoID = _b[0], setShowInfoID = _b[1];
    var mapRef = useRef(null);
    var userLocation = useGeolocation();
    var keyboard = useSelector(makeSelectUseKeyboard());
    var getLocation = function () {
        if (lat && lng) {
            return {
                lat: +lat,
                lng: +lng,
            };
        }
        return undefined;
    };
    var options = __assign({ streetViewControl: false, mapTypeControl: false, fullscreenControl: false }, customOptions);
    var handleClickMarker = function (placeId) {
        setShowInfoID(function (prevState) {
            if (prevState === placeId) {
                if (onCardClose)
                    onCardClose();
                return '';
            }
            if (onCardOpen)
                onCardOpen();
            return placeId;
        });
    };
    var renderIcon = function (categoryName, hasEnoughContributions) {
        if (!categoryName)
            return;
        var fileName = hasEnoughContributions
            ? categoryName
            : categoryName + "_off";
        return {
            url: require("../../../icons/category/png/square/" + fileName + ".png"),
        };
    };
    var userLocationToMap = function () {
        if (onUserLocation) {
            onUserLocation();
        }
    };
    var onMounted = function (ref) {
        mapRef.current = ref.current;
        if (onMapReady)
            onMapReady(ref);
    };
    var getUserLocation = function () {
        // @ts-ignore
        var latitude = userLocation.latitude, longitude = userLocation.longitude;
        if (latitude && longitude) {
            return {
                lat: latitude,
                lng: longitude,
            };
        }
        return undefined;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(UserLocation, { onClick: userLocationToMap, tabIndex: 0, isKeyboardMode: keyboard },
            React.createElement(Locale, { size: rem(30), color: colors.space.tint600 })),
        React.createElement(Maps, { center: getLocation() || DEFAULT_CENTER, zoomControl: true, options: options, onDragEnd: onDragEnd, onZoomChanged: onZoomChanged, onMounted: onMounted },
            getUserLocation() && React.createElement(Marker, { position: getUserLocation() }),
            places &&
                map(places, function (place) { return (React.createElement(Marker, { key: place.googlePlaceId, position: {
                        lat: Number(place.latitude),
                        lng: Number(place.longitude),
                    }, icon: renderIcon(place.category.name, place.hasEnoughContributions), onMouseDown: function () { return handleClickMarker(place.googlePlaceId); } }, showInfoID === place.googlePlaceId && (React.createElement(InfoWindow, { onCloseClick: function () { return handleClickMarker(place.googlePlaceId); } },
                    React.createElement(CardPlacesLink, { to: paths.placeDetailsPage
                            .replace(':placeId', place.googlePlaceId)
                            .replace(':placeName', place.slug) },
                        React.createElement(CardPlaces, { key: place.googlePlaceId, place: place, placeStyle: { paddingRight: 0, paddingLeft: 0 }, showCategoryIcon: true, isMapCard: true })))))); }))));
};
