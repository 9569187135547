import { createSelector } from 'reselect';
import { initialState } from './reducer';
export var selectAuthDomain = function (state) {
    return state.auth || initialState;
};
export var makeSelectSessionToken = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var sessionToken = _a.sessionToken;
        return sessionToken;
    });
};
export var makeSelectIsLoggedIn = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var isLoggedIn = _a.isLoggedIn;
        return isLoggedIn;
    });
};
export var makeSelectIsLoading = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var isLoading = _a.isLoading;
        return isLoading;
    });
};
export var makeSelectUser = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user;
    });
};
export var makeSelectUserEmailStatus = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.emailStatus;
    });
};
export var makeSelectUserAvatar = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.avatar;
    });
};
export var makeSelectUserEmail = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.email;
    });
};
export var makeSelectUserSlug = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.slug;
    });
};
export var makeSelectUserFirstName = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.firstName;
    });
};
export var makeSelectUserLastName = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.lastName;
    });
};
export var makeSelectUserScore = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.score;
    });
};
export var makeSelectUserMessage = function () {
    return createSelector(selectAuthDomain, function (_a) {
        var user = _a.user;
        return user === null || user === void 0 ? void 0 : user.message;
    });
};
export var makeSelectAuth = function () {
    return createSelector(selectAuthDomain, function (subState) { return subState; });
};
export default makeSelectAuth;
