var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { breakpoints, colors } from 'styles';
export var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  overflow: hidden;\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", ";\n\n  ", ";\n"], ["\n  background-color: ", ";\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  overflow: hidden;\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ",
    ";\n\n  ",
    ";\n"])), colors.base.secondary, rem(20), rem(20), breakpoints.medium, rem(125), media.between('small', 'medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: ", ";\n  "], ["\n    height: ", ";\n  "])), rem(175)), media.greaterThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: ", ";\n  "], ["\n    height: ", ";\n  "])), rem(225)));
export var ChallengeImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n"], ["\n  display: flex;\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n"])));
export var JaccedeLogo = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  object-fit: contain;\n  width: 100%;\n  height: 60%;\n  opacity: 0.1;\n"], ["\n  display: flex;\n  object-fit: contain;\n  width: 100%;\n  height: 60%;\n  opacity: 0.1;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
