import { createSelector } from 'reselect';
import { subscribeUserInitialState } from './reducer';
export var selectSubscribeUserDomain = function (state) {
    return state.subscribeUser || subscribeUserInitialState;
};
export var makeSelectSubscribeLoading = function () {
    return createSelector(selectSubscribeUserDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSubscribeUser = function () {
    return createSelector(selectSubscribeUserDomain, function (subState) { return subState; });
};
export default makeSubscribeUser;
