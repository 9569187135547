var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { colors } from 'styles';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
var desktopBackground = theme('mode', {
    light: colors.space.tint100,
    dark: colors.carbon.tint500,
});
var mobileBackground = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint500,
});
var mobileHeader = theme('mode', {
    light: colors.base.primary,
    dark: colors.carbon.tint900,
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  background-color: ", ";\n  padding: ", ";\n\n  ", ";\n"], ["\n  display: flex;\n  background-color: ", ";\n  padding: ", ";\n\n  ",
    ";\n"])), desktopBackground, rem(20), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    padding-top: ", ";\n  "], ["\n    background-color: ", ";\n    padding-top: ", ";\n  "])), mobileBackground, rem(10)));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  margin-bottom: ", ";\n"], ["\n  flex: 1;\n  margin-bottom: ", ";\n"])), rem(50));
export var RightContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: ", ";\n\n  ", ";\n"], ["\n  margin-left: ", ";\n\n  ",
    ";\n"])), rem(20), media.lessThan('large')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "]))));
export var Background = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100vw;\n  height: ", ";\n"], ["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100vw;\n  height: ", ";\n"])), mobileHeader, rem(20), rem(20), rem(420));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
