var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var NewsCardContainer = styled.article(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-items: flex-start;\n  padding: ", ";\n  height: ", ";\n  position: relative;\n  overflow: hidden;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    z-index: -1;\n    background: url(", ") no-repeat center;\n    background-size: cover;\n  }\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-items: flex-start;\n  padding: ", ";\n  height: ", ";\n  position: relative;\n  overflow: hidden;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    z-index: -1;\n    background: url(", ") no-repeat center;\n    background-size: cover;\n  }\n"])), rgba(colors.base.black, 0.6), rem(8), colors.base.white, rem(16), rem(284), function (props) { return props.background; });
export var Title = styled.h6(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin: auto 0 ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin: auto 0 ", ";\n"])), rem(18), fonts.weight.semibold, rem(8));
export var Date = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), rem(14), fonts.weight.regular, rem(20));
export var Info = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var Author = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-left: ", ";\n"], ["\n  font-weight: ", ";\n  margin-left: ", ";\n"])), fonts.weight.semibold, rem(10));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
