import { Upload } from 'components';
import { useAction } from 'hooks';
import { Edit } from 'icons/mono';
import find from 'lodash/find';
import { rem } from 'polished';
import React from 'react';
import { useParams } from 'react-router-dom';
import { colors } from 'styles';
import { uploadCoverImageTeamRequest } from '../store/uploadCoverImage/actions';
import { UploadButton, UploadContainer } from './styles';
export var CoverUpload = function () {
    var allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    var params = useParams();
    var uploadCoverImageTeam = useAction(function (payload) {
        return uploadCoverImageTeamRequest(payload);
    });
    var validateImageUpload = function (file) {
        var isAllowedType = find(allowedTypes, function (item) { return file.type === item; });
        if (!isAllowedType) {
            return false;
        }
        return true;
    };
    var uploadImage = function (options) {
        var file = options.file;
        var payload = {
            challengeSlug: params.challengeSlug,
            teamSlug: params.teamSlug,
            file: file,
        };
        uploadCoverImageTeam(payload);
    };
    return (React.createElement(UploadContainer, null,
        React.createElement(Upload, { beforeUpload: function (file) { return validateImageUpload(file); }, showUploadList: false, disabled: false, accept: "image/*", customRequest: uploadImage },
            React.createElement(UploadButton, { type: "button" },
                React.createElement(Edit, { size: rem(18), color: colors.base.white })))));
};
