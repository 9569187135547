import { SUBFOOTER_LINKS_FAILURE, SUBFOOTER_LINKS_FIND_PLACES_IN_EUROPE_SUCCESS, SUBFOOTER_LINKS_FIND_PLACES_IN_FRANCE_SUCCESS, SUBFOOTER_LINKS_FIND_PLACES_IN_WORLD_SUCCESS, SUBFOOTER_LINKS_REQUEST, SUBFOOTER_LINKS_SEARCH_BY_TYPE_OF_PLACE_SUCCESS, } from './constants';
export var fetchSubFooterLinksRequest = function () { return ({
    type: SUBFOOTER_LINKS_REQUEST,
}); };
export var fetchSubFooterLinksFailure = function (payload) { return ({
    type: SUBFOOTER_LINKS_FAILURE,
    payload: payload,
}); };
export var setSubFooterLinksSearchByTypeOfPlaceSuccess = function (payload) { return ({
    type: SUBFOOTER_LINKS_SEARCH_BY_TYPE_OF_PLACE_SUCCESS,
    payload: payload,
}); };
export var setSubFooterLinksFindPlacesInWorldSuccess = function (payload) { return ({
    type: SUBFOOTER_LINKS_FIND_PLACES_IN_WORLD_SUCCESS,
    payload: payload,
}); };
export var setSubFooterLinksFindPlacesInFranceSuccess = function (payload) { return ({
    type: SUBFOOTER_LINKS_FIND_PLACES_IN_FRANCE_SUCCESS,
    payload: payload,
}); };
export var setSubFooterLinksFindPlacesInEuropeSuccess = function (payload) { return ({
    type: SUBFOOTER_LINKS_FIND_PLACES_IN_EUROPE_SUCCESS,
    payload: payload,
}); };
