var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var Medal25000Desktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.34326 28.2758V48.5618L19.9135 41.7056L32.4828 48.5618V28.2758H7.34326Z", fill: "#1B6CEF" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.6284 28.2761V45.134L19.9135 41.705L26.1986 45.134V28.2761H13.6284Z", fill: "#134EAD" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9134 -0.000255346L15.8734 2.724L11.0512 2.02272L8.59337 6.23038L3.94401 7.68911L3.55593 12.5478L0.000183105 15.8814L1.75678 20.4253L0.000183105 24.97L3.55593 28.3027L3.94401 33.1597L8.59337 34.621L11.0512 38.8278L15.8734 38.1266L19.9134 40.8508L23.9542 38.1266L28.7764 38.8278L31.2334 34.621L35.8827 33.1597L36.2717 28.3027L39.8257 24.9708V24.9683L38.07 20.4253L39.8257 15.8831V15.8806L36.2717 12.5478L35.8827 7.68911L31.2334 6.23038L28.7764 2.02272L23.9542 2.724L19.9134 -0.000255346Z", fill: "#F7D131" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.1653 20.4254C34.1653 28.2969 27.784 34.6773 19.9134 34.6773C12.0419 34.6773 5.66144 28.2969 5.66144 20.4254C5.66144 12.5547 12.0419 6.17345 19.9134 6.17345C27.784 6.17345 34.1653 12.5547 34.1653 20.4254Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9128 33.0675C13.1545 33.0675 7.6558 27.5696 7.6558 20.8105C7.6558 14.0522 13.1545 8.5526 19.9128 8.5526C26.672 8.5526 32.1707 14.0522 32.1707 20.8105C32.1707 27.5696 26.672 33.0675 19.9128 33.0675ZM19.9128 5.92536C11.7052 5.92536 5.02856 12.6037 5.02856 20.8105C5.02856 29.0181 11.7052 35.6939 19.9128 35.6939C28.1205 35.6939 34.7971 29.0181 34.7971 20.8105C34.7971 12.6037 28.1205 5.92536 19.9128 5.92536Z", fill: "#F7D131" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9129 33.7C12.5937 33.7 6.63881 27.746 6.63881 20.4251C6.63881 13.1077 12.5937 7.15191 19.9129 7.15191C27.2329 7.15191 33.1877 13.1077 33.1877 20.4251C33.1877 27.746 27.2329 33.7 19.9129 33.7ZM19.9129 4.30681C11.0252 4.30681 3.79456 11.5383 3.79456 20.4251C3.79456 29.3145 11.0252 36.5443 19.9129 36.5443C28.8014 36.5443 36.032 29.3145 36.032 20.4251C36.032 11.5383 28.8014 4.30681 19.9129 4.30681Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.68201 24.1322C8.68201 23.6964 8.7169 23.3134 8.78669 22.9815C8.85647 22.6513 8.96967 22.3534 9.12626 22.0879C9.28286 21.8215 9.48541 21.5849 9.73392 21.3756C9.98158 21.1671 10.2888 20.962 10.6548 20.762C11.1254 20.5092 11.4973 20.3066 11.7714 20.1543C12.0463 20.002 12.2522 19.8777 12.3918 19.7824C12.5314 19.6862 12.6199 19.6062 12.6599 19.5398C12.699 19.4743 12.7186 19.4028 12.7186 19.3254C12.7186 19.1075 12.6488 18.9458 12.5092 18.8411C12.3697 18.7364 12.1782 18.6854 11.9348 18.6854C11.6471 18.6854 11.3748 18.7594 11.1186 18.9066C10.8616 19.0547 10.5943 19.2939 10.3152 19.6258L8.74754 17.7832C8.91265 17.6003 9.12626 17.4334 9.38754 17.2811C9.64882 17.1288 9.93818 16.9977 10.2565 16.8888C10.5739 16.7798 10.9118 16.6956 11.2692 16.6334C11.625 16.573 11.9918 16.5424 12.3663 16.5424C13.3671 16.5424 14.1271 16.7492 14.6454 17.1628C15.1629 17.5773 15.4224 18.1534 15.4224 18.8947C15.4224 19.1637 15.3875 19.4028 15.3178 19.6113C15.248 19.8215 15.1543 20.0105 15.0369 20.1807C14.9195 20.35 14.7782 20.5049 14.6122 20.6437C14.4471 20.7832 14.2726 20.9143 14.0897 21.036C13.8726 21.1841 13.6437 21.3254 13.4046 21.4607C13.1646 21.596 12.9271 21.7449 12.6922 21.9117H15.5663V24.1322H8.68201Z", fill: "#D8B627" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.0205 19.8735C20.4733 19.8735 20.8869 19.9476 21.2614 20.0957C21.6358 20.2437 21.9601 20.4506 22.2341 20.7152C22.5082 20.9825 22.7218 21.2999 22.8741 21.6693C23.0265 22.0395 23.1031 22.4471 23.1031 22.8914C23.1031 23.362 23.0163 23.7918 22.8418 24.1833C22.6673 24.5757 22.4214 24.911 22.1039 25.1893C21.7856 25.4684 21.4069 25.6863 20.9669 25.8429C20.5277 25.9995 20.0418 26.0786 19.5107 26.0786C18.8316 26.0786 18.2197 25.9518 17.6758 25.6991C17.1312 25.4463 16.7107 25.0854 16.4154 24.6148L17.7737 23.0471C18.0265 23.3178 18.3065 23.5289 18.6163 23.6812C18.9252 23.8327 19.2273 23.9093 19.5243 23.9093C19.846 23.9093 20.0988 23.8233 20.2818 23.6489C20.4639 23.4744 20.5558 23.2386 20.5558 22.9425C20.5558 22.6557 20.4733 22.425 20.3073 22.2506C20.1422 22.0761 19.9243 21.9893 19.6546 21.9893C19.4716 21.9893 19.3039 22.031 19.1516 22.1135C18.9992 22.1961 18.8835 22.3076 18.8052 22.4471H16.6767L17.7737 16.6727H22.4239V18.934H19.5635L19.4197 19.9382C19.4537 19.9297 19.5022 19.922 19.5635 19.9127C19.6069 19.9042 19.6673 19.8948 19.7465 19.8871C19.8248 19.8778 19.9158 19.8735 20.0205 19.8735Z", fill: "#D8B627" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.6022 24.1322L27.0481 21.6241L26.5511 22.1722V24.1322H24.0703V14.596H26.5511V19.8088L28.38 17.2615H30.9928L28.6285 20.2649L31.2277 24.1322H28.6022Z", fill: "#D8B627" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.19354 23.1614C9.19354 22.7358 9.22759 22.3622 9.29567 22.038C9.36376 21.7146 9.47439 21.4235 9.62759 21.1631C9.78078 20.9043 9.97907 20.672 10.2216 20.4677C10.4642 20.2635 10.7638 20.0635 11.1212 19.8677C11.5808 19.6209 11.9442 19.4226 12.2131 19.2746C12.4812 19.1256 12.6829 19.0039 12.8191 18.9103C12.9552 18.8175 13.0421 18.7384 13.0812 18.6746C13.1195 18.6099 13.1382 18.5401 13.1382 18.4635C13.1382 18.2507 13.0701 18.0933 12.934 17.9911C12.7978 17.889 12.6106 17.838 12.3723 17.838C12.0914 17.838 11.825 17.9103 11.5748 18.055C11.3229 18.1997 11.0616 18.4337 10.7893 18.7571L9.25737 16.9571C9.41907 16.7784 9.62759 16.615 9.8829 16.466C10.1382 16.3171 10.4208 16.1894 10.7323 16.0831C11.0421 15.9767 11.3723 15.8933 11.7212 15.8337C12.0701 15.7741 12.4276 15.7443 12.7935 15.7443C13.7723 15.7443 14.5144 15.946 15.0216 16.3503C15.5272 16.7554 15.7808 17.3188 15.7808 18.0422C15.7808 18.306 15.7467 18.5401 15.6786 18.7443C15.6106 18.9486 15.5195 19.1341 15.4046 19.3001C15.2897 19.4652 15.151 19.6167 14.9893 19.7529C14.8276 19.889 14.6574 20.0167 14.4786 20.1358C14.2659 20.2805 14.0429 20.4184 13.8089 20.5511C13.574 20.6831 13.3425 20.8294 13.1127 20.9911H15.9212V23.1614H9.19354Z", fill: "#F9F1B7" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.2744 19C20.7169 19 21.1212 19.0723 21.4871 19.217C21.8531 19.3617 22.1706 19.5634 22.4378 19.823C22.7059 20.0826 22.9152 20.394 23.0633 20.7557C23.2123 21.1166 23.2871 21.5149 23.2871 21.9489C23.2871 22.4085 23.202 22.8298 23.0318 23.2119C22.8616 23.5949 22.6208 23.9226 22.311 24.1949C21.9995 24.4681 21.6293 24.6809 21.1995 24.8332C20.7706 24.9864 20.2957 25.0638 19.7765 25.0638C19.1127 25.0638 18.5144 24.9396 17.9833 24.6928C17.4514 24.446 17.0403 24.0928 16.751 23.6332L18.0786 22.1021C18.3254 22.366 18.5995 22.5719 18.9025 22.7209C19.2037 22.8698 19.4999 22.9447 19.7893 22.9447C20.1042 22.9447 20.351 22.8596 20.5297 22.6894C20.7084 22.5191 20.7978 22.2885 20.7978 21.9991C20.7978 21.7183 20.7169 21.4936 20.5552 21.3226C20.3935 21.1523 20.1808 21.0681 19.9169 21.0681C19.7382 21.0681 19.574 21.1089 19.425 21.1889C19.2769 21.2698 19.1637 21.3787 19.0871 21.5149H17.0063L18.0786 15.8715H22.6233V18.0809H19.8276L19.6871 19.063C19.7212 19.0545 19.768 19.0468 19.8276 19.0383C19.8701 19.0298 19.9297 19.0204 20.0063 19.0128C20.0829 19.0043 20.1723 19 20.2744 19Z", fill: "#F9F1B7" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.6616 23.1614L27.1424 20.7103L26.6573 21.2465V23.1614H24.2318V13.8422H26.6573V18.9358L28.4446 16.4465H30.9978L28.6871 19.3826L31.2276 23.1614H28.6616Z", fill: "#F9F1B7" })));
});
