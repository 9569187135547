import { Popover } from 'components';
import { Formik } from 'formik';
import { Alert } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { SuccessFeedbackType } from '../types';
import messages from './messages';
import { CheckboxWrapper, Container, InfoContainer, InfoTrigger, StyledCheckbox, } from './styles';
export var AcceptTerms = function (_a) {
    var profile = _a.profile, onSubmit = _a.onSubmit;
    var formatMessage = useIntl().formatMessage;
    var content = (React.createElement(InfoContainer, null, formatMessage(messages.infoNewsletter)));
    return (React.createElement(Container, null,
        React.createElement(Formik, { initialValues: {
                acceptsEmailcontact: profile === null || profile === void 0 ? void 0 : profile.acceptsEmailcontact,
                acceptsNewsletter: profile === null || profile === void 0 ? void 0 : profile.acceptsNewsletter,
            }, enableReinitialize: true, onSubmit: function (values) { return onSubmit(values, SuccessFeedbackType.Terms); } }, function (_a) {
            var setFieldValue = _a.setFieldValue, values = _a.values, submitForm = _a.submitForm;
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(StyledCheckbox, { name: "acceptsEmailcontact", checked: values.acceptsEmailcontact, onChange: function () {
                            setFieldValue('acceptsEmailcontact', !values.acceptsEmailcontact);
                            submitForm();
                        }, style: { marginBottom: rem(16) } }, formatMessage(messages.contact))),
                React.createElement(CheckboxWrapper, null,
                    React.createElement(StyledCheckbox, { name: "acceptsNewsletter", checked: values.acceptsNewsletter, onChange: function () {
                            setFieldValue('acceptsNewsletter', !values.acceptsNewsletter);
                            submitForm();
                        } },
                        formatMessage(messages.newsletter),
                        React.createElement(Popover, { content: content, trigger: "click", placement: "top" },
                            React.createElement(InfoTrigger, null,
                                React.createElement(Alert, { size: rem(20), color: colors.space.tint600 })))))));
        })));
};
