var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, shade } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { breakpoints, colors } from 'styles';
export var DatesContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  bottom: calc(", " + env(safe-area-inset-bottom, 0));\n  color: ", ";\n  font-size: ", ";\n  position: sticky;\n  z-index: 1;\n  width: 100%;\n\n  ", "\n"], ["\n  background: ", ";\n  bottom: calc(", " + env(safe-area-inset-bottom, 0));\n  color: ", ";\n  font-size: ", ";\n  position: sticky;\n  z-index: 1;\n  width: 100%;\n\n  ",
    "\n"])), colors.base.primary, rem(80), colors.base.white, rem(15), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    bottom: 0;\n  "], ["\n    bottom: 0;\n  "]))));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", " ", ";\n  max-width: ", ";\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  > div {\n    text-align: right;\n  }\n"], ["\n  padding: ", " ", ";\n  max-width: ", ";\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  > div {\n    text-align: right;\n  }\n"])), rem(10), rem(20), breakpoints.medium);
export var Dates = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  & + div {\n    margin-top: ", ";\n  }\n\n  span {\n    border-radius: ", ";\n    border: ", " solid ", ";\n    background: ", ";\n    padding: ", " ", ";\n    display: inline-block;\n  }\n"], ["\n  & + div {\n    margin-top: ", ";\n  }\n\n  span {\n    border-radius: ", ";\n    border: ", " solid ", ";\n    background: ", ";\n    padding: ", " ", ";\n    display: inline-block;\n  }\n"])), rem(8), rem(3), rem(1), shade(0.5, colors.base.primary), shade(0.3, colors.base.primary), rem(3), rem(5));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
