var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Heart = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M19.9999 35.4L19.3077 34.925C16.0171 32.6632 11.8534 30.1069 8.76974 26.3497C5.5199 22.3913 3.96075 18.3261 4.00075 13.9205C4.04921 9.00108 7.88209 5 12.5449 5C16.2455 5 18.7062 7.21667 19.9999 8.81662C21.2937 7.21667 23.7544 5 27.4549 5C32.1178 5 35.9507 9.00108 35.9991 13.9181C36.0422 18.3261 34.4831 22.389 31.2301 26.3473C28.1465 30.1069 23.9828 32.6632 20.6922 34.925L19.9999 35.4Z", fill: color })));
});
