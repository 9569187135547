var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
export var PodiumContainer = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: flex-end;\n  display: flex;\n  justify-content: center;\n  max-width: ", ";\n  width: 90%;\n  margin: 0 auto;\n  transition: all 0.2s;\n\n  ", ";\n"], ["\n  align-items: flex-end;\n  display: flex;\n  justify-content: center;\n  max-width: ", ";\n  width: 90%;\n  margin: 0 auto;\n  transition: all 0.2s;\n\n  ",
    ";\n"])), rem(576), media.greaterThan('large')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: ", ";\n  "], ["\n    max-width: ", ";\n  "])), rem(768)));
var templateObject_1, templateObject_2;
