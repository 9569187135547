import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { useSelector } from 'hooks';
import { Facebook, Linkedin, Twitter, Youtube } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ExternalLink, SocialNetworksContainer, StyledSection, Title, } from './styles';
export var SocialNetworks = memo(function () {
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(StyledSection, { background: "primary" },
        React.createElement(SocialNetworksContainer, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "containers.home.socialNetworks" })),
            React.createElement(ExternalLink, { isKeyboardMode: keyboard, href: "https://twitter.com/Jaccede" },
                React.createElement(Twitter, { size: rem(32) })),
            React.createElement(ExternalLink, { isKeyboardMode: keyboard, href: "https://www.facebook.com/jaccede/" },
                React.createElement(Facebook, { size: rem(28) })),
            React.createElement(ExternalLink, { isKeyboardMode: keyboard, href: "https://www.youtube.com/channel/UCRO-yy5YrhsRaxPQ0oeornw" },
                React.createElement(Youtube, { size: rem(36) })),
            React.createElement(ExternalLink, { isKeyboardMode: keyboard, href: "https://www.linkedin.com/company/jaccede" },
                React.createElement(Linkedin, { size: rem(28) })))));
});
