var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba, shade } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var background = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), rgba(colors.base.white, 0.6)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), rgba(colors.carbon.tint800, 0.6)),
});
var shadow = theme('mode', {
    light: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var StyledLink = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-decoration: none;\n\n  ", "\n"], ["\n  text-decoration: none;\n\n  ",
    "\n"])), ifProp('isKeyboardMode', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      &:focus {\n        outline: 0 !important;\n        outline-offset: 0 !important;\n\n        > div > div:last-child {\n          ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        outline: 0 !important;\n        outline-offset: 0 !important;\n\n        > div > div:last-child {\n          ", ";\n        }\n      }\n    "])), shadow)));
export var CardTeamContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var ChallengeContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin-bottom: ", ";\n  padding: ", ";\n  position: relative;\n"], ["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin-bottom: ", ";\n  padding: ", ";\n  position: relative;\n"])), background, rem(1), border, rem(10), rem(24), rem(12));
export var AvatarWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  position: absolute;\n  top: -", ";\n"], ["\n  display: flex;\n  position: absolute;\n  top: -", ";\n"])), rem(10));
export var InfoWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n"])), rem(70));
export var Name = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), color, rem(15), fonts.weight.semibold, rem(4));
export var MembersCount = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), color, rem(13));
export var RackWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-right: ", ";\n  width: ", ";\n  position: relative;\n"], ["\n  margin-right: ", ";\n  width: ", ";\n  position: relative;\n"])), rem(10), rem(52));
export var RankCount = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  width: ", ";\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  width: ", ";\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n"])), colors.base.secondary, rem(10), colors.base.white, rem(20), fonts.weight.semibold, rem(32), rem(32));
export var MedalWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: 0;\n"], ["\n  position: absolute;\n  top: ", ";\n  right: 0;\n"])), rem(8));
export var Score = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: 0 ", ";\n  height: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  position: absolute;\n  top: ", ";\n  right: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: 0 ", ";\n  height: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  position: absolute;\n  top: ", ";\n  right: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), colors.base.primary, rem(1), shade(0.3, colors.base.primary), rem(12), rem(12), rem(24), colors.base.white, rem(12), fonts.weight.semibold, rem(-12), rem(2));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
