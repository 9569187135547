import routes from 'containers/RoutesProvider/routes';
import get from 'lodash/get';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const getPath = (fullPath) => {
  if (fullPath === '/') return;
  const route = /(?!\/)[a-z]+\/[a-z]+\//.exec(fullPath);
  return get(route, '0');
};

const getRouteProperties = (path) => {
  const routesList = routes();
  const url = routesList.find((route) => getPath(route.path) === path);
  return url;
};

const ScrollToTop = ({ children, location }) => {
  const currentRoute = getPath(location.pathname);
  const routeProperties = getRouteProperties(currentRoute);
  const path = get(routeProperties, 'avoidAutoScrollTop')
    ? currentRoute
    : location.pathname;

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, [path]);

  return children || null;
};

export default withRouter(ScrollToTop);
