var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as api from 'api';
import { paths } from 'containers/RoutesProvider/routes';
import makeSelectRouterProvider from 'containers/RoutesProvider/selectors';
import { useSelector } from 'hooks';
import split from 'lodash/split';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { ExternalContent } from '../ExternalContent';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
export var ListView = function (_a) {
    var menuData = _a.menuData, setTitle = _a.setTitle;
    var _b = __read(useState(false), 2), listItemSelected = _b[0], setListItemSelected = _b[1];
    var _c = __read(useState(''), 2), sectionTitle = _c[0], setSectionTitle = _c[1];
    var _d = __read(useState(React.createElement(React.Fragment, null)), 2), sectionContent = _d[0], setSectionContent = _d[1];
    var router = useSelector(makeSelectRouterProvider());
    var history = useHistory();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var onMode = isMobile || isTabletOrMobile ? 'Mobile' : 'Desktop';
    var selectListItem = function (title, body) {
        setSectionContent(function () { return React.createElement(ExternalContent, { body: body }); });
        setSectionTitle(title);
    };
    useEffect(function () {
        function loadPage() {
            var pathname = router.location.pathname;
            var pathnameSplited = split(pathname, '/');
            if (pathnameSplited.length === 4 && pathnameSplited[3] !== 'index') {
                var slug = pathnameSplited[3];
                api.aboutus
                    .aboutUsSlug(slug)
                    .then(function (response) {
                    setTitle(response.data.title);
                    setListItemSelected(true);
                    selectListItem(response.data.title, response.data.body);
                })
                    .catch(function (err) {
                    var _a;
                    if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        history.push(paths.notFoundPageCustom);
                    }
                });
            }
            else if (pathnameSplited.length === 4 &&
                pathnameSplited[3] === 'index') {
                setListItemSelected(false);
                setSectionContent(React.createElement(React.Fragment, null));
                setSectionTitle('');
            }
            else if (onMode === 'Desktop' &&
                (pathnameSplited.length === 3 ||
                    (pathnameSplited.length === 5 && pathnameSplited[3] === 'page'))) {
                api.aboutus
                    .aboutUsSlug('jaccede-cest-quoi')
                    .then(function (response) {
                    setTitle(response.data.title);
                    setListItemSelected(true);
                    selectListItem(response.data.title, response.data.body);
                })
                    .catch(function (err) {
                    var _a;
                    if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        history.push(paths.notFoundPageCustom);
                    }
                });
            }
            window.scrollTo(0, 0);
        }
        loadPage();
    }, [router.location.pathname]);
    return (React.createElement(React.Fragment, null, isMobile || isTabletOrMobile ? (React.createElement(Mobile, { menuData: menuData, sectionTitle: sectionTitle, listItemSelected: listItemSelected, sectionContent: sectionContent, setListItemSelected: setListItemSelected })) : (React.createElement(Desktop, { menuData: menuData, sectionTitle: sectionTitle, listItemSelected: listItemSelected, sectionContent: sectionContent, setListItemSelected: setListItemSelected }))));
};
