import { Button } from 'components/Button';
import { TextAreaField } from 'components/TextAreaField';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalButtonWrapper } from '../styles';
import ReportFormFormSchema from './validator';
export var ReportForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var formatMessage = useIntl().formatMessage;
    var schema = ReportFormFormSchema(formatMessage);
    return (React.createElement(Formik, { initialValues: {
            message: '',
        }, enableReinitialize: true, validationSchema: schema, onSubmit: onSubmit }, function (_a) {
        var handleBlur = _a.handleBlur, handleChange = _a.handleChange, values = _a.values, handleSubmit = _a.handleSubmit;
        return (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement(TextAreaField, { rows: 4, name: "message", label: formatMessage({
                    id: 'containers.placeDetails.report.label',
                }), placeholder: formatMessage({
                    id: 'containers.placeDetails.report.placeholder',
                }), onBlur: handleBlur, onChange: handleChange, value: values.message }),
            React.createElement(ModalButtonWrapper, null,
                React.createElement(Button, { type: "submit" },
                    React.createElement(FormattedMessage, { id: "containers.placeDetails.report.button" })))));
    }));
};
