import { createSelector } from 'reselect';
import { membersOnTeamInitialState } from './reducer';
export var selectMembersOnTeamDomain = function (state) {
    return state.membersOnTeam || membersOnTeamInitialState;
};
export var makeSelectMembersOnTeamLoading = function () {
    return createSelector(selectMembersOnTeamDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectMembersOnTeamHasError = function () {
    return createSelector(selectMembersOnTeamDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectMembersOnTeamError = function () {
    return createSelector(selectMembersOnTeamDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectMembersOnTeamPages = function () {
    return createSelector(selectMembersOnTeamDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectMembersOnTeamCurrentPage = function () {
    return createSelector(selectMembersOnTeamDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectMembersOnTeamTotal = function () {
    return createSelector(selectMembersOnTeamDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectMembersOnTeamItems = function () {
    return createSelector(selectMembersOnTeamDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeMembersOnTeam = function () {
    return createSelector(selectMembersOnTeamDomain, function (subState) { return subState; });
};
export default makeMembersOnTeam;
