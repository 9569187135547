var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var ButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin: ", " auto 0;\n\n  button {\n    width: calc(50% - ", ");\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin: ", " auto 0;\n\n  button {\n    width: calc(50% - ", ");\n  }\n"])), rem(26), rem(8));
export var ErrorMessage = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"])), colors.base.danger, rem(14), fonts.weight.regular);
var templateObject_1, templateObject_2;
