var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint800,
});
export var AutocompleteContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: transparent;\n  position: fixed;\n  width: 100vw;\n  height: 0;\n  transition-duration: 0.1s;\n\n  display: flex;\n  flex-direction: column;\n\n  ", "\n"], ["\n  background-color: transparent;\n  position: fixed;\n  width: 100vw;\n  height: 0;\n  transition-duration: 0.1s;\n\n  display: flex;\n  flex-direction: column;\n\n  ",
    "\n"])), ifProp('hasFocus', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n      height: calc(100vh - ", ");\n      z-index: 3000;\n    "], ["\n      background-color: ", ";\n      height: calc(100vh - ", ");\n      z-index: 3000;\n    "])), background, rem(60))));
export var SearchBarWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  margin: ", " ", " 0;\n  min-height: ", ";\n\n  display: flex;\n  align-items: center;\n"], ["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  margin: ", " ", " 0;\n  min-height: ", ";\n\n  display: flex;\n  align-items: center;\n"])), colors.base.white, rem(1), colors.space.tint400, rem(10), rem(20), rem(20), rem(50));
export var Input = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  border: none;\n\n  display: flex;\n  font-size: ", ";\n  flex-flow: row wrap;\n  flex-grow: 1;\n\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 100%;\n  height: calc(", " - 2px);\n"], ["\n  align-items: center;\n  border: none;\n\n  display: flex;\n  font-size: ", ";\n  flex-flow: row wrap;\n  flex-grow: 1;\n\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 100%;\n  height: calc(", " - 2px);\n"])), rem(16), rem(50));
export var LeftIconButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: none;\n  border-radius: 0;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  width: ", ";\n\n  &:focus:not(:disabled) {\n    box-shadow: none;\n  }\n\n  > svg {\n    margin: 0;\n    padding: 0;\n    fill: ", ";\n  }\n"], ["\n  border: none;\n  border-radius: 0;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  width: ", ";\n\n  &:focus:not(:disabled) {\n    box-shadow: none;\n  }\n\n  > svg {\n    margin: 0;\n    padding: 0;\n    fill: ", ";\n  }\n"])), rem(50), colors.space.tint750);
export var RightIconButton = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: none;\n  border-left: ", " solid ", ";\n  border-radius: 0;\n  cursor: pointer;\n  padding: ", " ", ";\n  display: flex;\n\n  &:focus:not(:disabled) {\n    box-shadow: none;\n  }\n"], ["\n  border: none;\n  border-left: ", " solid ", ";\n  border-radius: 0;\n  cursor: pointer;\n  padding: ", " ", ";\n  display: flex;\n\n  &:focus:not(:disabled) {\n    box-shadow: none;\n  }\n"])), rem(1), colors.space.tint400, rem(6), rem(16));
export var Content = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: ", ";\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: ", ";\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n"])), function (_a) {
    var height = _a.height;
    return "calc(" + height + " - " + rem(40) + ")";
});
export var SuggestionsWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), rem(10));
export var Footer = styled.footer(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  padding: 0 ", ";\n\n  ", "\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  padding: 0 ", ";\n\n  ",
    "\n"])), rem(16), ifProp('placeNotFound', css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      justify-content: space-between;\n    "], ["\n      justify-content: space-between;\n    "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
