var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var FontSizeWhite = memo(function (props) { return (React.createElement("svg", __assign({ width: "25", height: "20", viewBox: "0 0 25 20", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { d: "M6.39 6.422l-2.192 5.856h4.547L6.39 6.422zm1.96 11.542v-.602l.745-.072c.77-.072 1.073-.265 1.073-.796 0-.53-.28-1.373-1.073-3.277H3.848l-.303.82c-.28.746-.723 1.758-.723 2.337 0 .626.513.892 1.889.988v.602H0v-.602c1.073-.193 1.423-.627 2.309-2.891L7.463 1.336h.653l5.177 12.579c1.19 2.916 1.61 3.446 2.566 3.446v.602h-7.51z", fill: "#fff" }),
    React.createElement("path", { d: "M15.12 11.699l1.858-4.831 1.996 4.83H15.12zm7.707 1.351L18.44 2.673h-.553l-3.64 9.034.486 1.01.092-.243h4.445c.672 1.57.91 2.266.91 2.704 0 .437-.258.596-.91.656l-.632.06v.496H25v-.497c-.81 0-1.166-.437-2.173-2.843z", fill: "#fff", fillOpacity: 0.75 }))); });
