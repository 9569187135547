var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { fonts } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  margin-bottom: ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  margin-bottom: ", ";\n"])), rem(9));
export var SubscriptionButton = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  padding-right: ", ";\n"], ["\n  cursor: pointer;\n  padding-right: ", ";\n"])), rem(10));
export var SubscriptionText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n\n  > strong {\n    font-size: ", ";\n    padding-right: ", ";\n  }\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n\n  > strong {\n    font-size: ", ";\n    padding-right: ", ";\n  }\n"])), rem(13), fonts.weight.semibold, rem(16), rem(3));
var templateObject_1, templateObject_2, templateObject_3;
