import produce from 'immer';
import findIndex from 'lodash/findIndex';
import { SUBSCRIBERS_FAILURE, SUBSCRIBERS_REQUEST, SUBSCRIBERS_SUCCESS, SUBSCRIBERS_TOGGLE_USER_IS_SUBSCRIBED, } from './constants';
export var subscribersInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    pages: 1,
    currentPage: 1,
    total: 0,
    items: undefined,
};
var subscribersReducer = produce(function (draft, action) {
    switch (action.type) {
        case SUBSCRIBERS_REQUEST:
            draft.loading = true;
            break;
        case SUBSCRIBERS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case SUBSCRIBERS_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.currentPage = action.payload.currentPage;
            draft.pages = action.payload.pages;
            draft.total = action.payload.total;
            draft.items = action.payload.items;
            break;
        case SUBSCRIBERS_TOGGLE_USER_IS_SUBSCRIBED: {
            var index = findIndex(draft.items, ['id', action.payload]);
            draft.items[index].isFollowed = !draft.items[index].isFollowed;
            break;
        }
        default:
    }
}, subscribersInitialState);
export default subscribersReducer;
