import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectEditMyProfileStateDomain = function (state) {
    return state.editMyProfile || initialState;
};
var makeSelectLoading = function () {
    return createSelector(selectEditMyProfileStateDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
var makeSelectEditMyProfile = function () {
    return createSelector(selectEditMyProfileStateDomain, function (_a) {
        var profile = _a.profile;
        return profile;
    });
};
var makeSelectSuccessFeedback = function () {
    return createSelector(selectEditMyProfileStateDomain, function (_a) {
        var success = _a.success;
        return success;
    });
};
var makeSelectErrorFeedback = function () {
    return createSelector(selectEditMyProfileStateDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
var makeSelectActiveSection = function () {
    return createSelector(selectEditMyProfileStateDomain, function (_a) {
        var activeSection = _a.activeSection;
        return activeSection;
    });
};
var makeSelectCarousel = function () {
    return createSelector(selectEditMyProfileStateDomain, function (_a) {
        var carousel = _a.carousel;
        return carousel;
    });
};
export default makeSelectEditMyProfile;
export { makeSelectLoading, makeSelectSuccessFeedback, makeSelectErrorFeedback, makeSelectActiveSection, makeSelectCarousel, };
