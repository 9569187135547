import { notification } from 'antd';
import { Label, Switch } from 'components';
import { useAction, useSelector } from 'hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import { setPlacesWithoutDetails } from '../actions';
import { makeSelectorPlacesWithoutDetails, makeSelectorSelectedAccessibilityFilters, } from '../selectors';
import { PlacesWithoutDetailsContainer } from './styles';
export var PlacesWithoutDetailsSwitch = function (_a) {
    var hideTitle = _a.hideTitle;
    var placesWithoutDetails = useSelector(makeSelectorPlacesWithoutDetails());
    var selectedAccessibility = useSelector(makeSelectorSelectedAccessibilityFilters());
    var setPlacesWithoutDetailsCallback = useAction(setPlacesWithoutDetails);
    var formatMessage = useIntl().formatMessage;
    var handleChange = function () {
        setPlacesWithoutDetailsCallback(!placesWithoutDetails);
        if (!placesWithoutDetails && (selectedAccessibility === null || selectedAccessibility === void 0 ? void 0 : selectedAccessibility.length)) {
            notification.info({
                message: formatMessage({
                    id: 'pages.explorePlacesPage.info',
                }),
                description: formatMessage({
                    id: 'pages.explorePlacesPage.filtersRemoved',
                }),
            });
        }
    };
    return (React.createElement(React.Fragment, null, hideTitle ? (React.createElement(Switch, { checked: placesWithoutDetails, onChange: handleChange })) : (React.createElement(PlacesWithoutDetailsContainer, null,
        React.createElement(Label, null, formatMessage({
            id: 'containers.filterPlaces.placeWithoutDetailsSwitch.title',
        })),
        React.createElement(Switch, { checked: placesWithoutDetails, onChange: handleChange })))));
};
