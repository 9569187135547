var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Twitter = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M35.2001 10.7806C34.0597 11.2587 32.8532 11.5737 31.6185 11.7156C32.916 10.9825 33.8918 9.81781 34.3628 8.44002C33.1361 9.13216 31.7962 9.61779 30.4007 9.87613C29.213 8.67514 27.5667 7.99695 25.8463 8.00001C22.3997 8.00001 19.6105 10.6522 19.6105 13.9217C19.6081 14.3764 19.6621 14.8298 19.7714 15.2722C14.774 15.0462 10.1067 12.7988 6.91475 9.08168C6.36095 9.98253 6.06807 11.0104 6.06672 12.0578C6.06672 14.1111 7.17695 15.9261 8.85338 16.9894C7.86014 16.9667 6.88726 16.713 6.01732 16.25V16.3233C6.01732 19.1955 8.17065 21.585 11.0206 22.1289C10.4847 22.2667 9.93244 22.3366 9.37778 22.3366C8.98422 22.3373 8.59154 22.3005 8.20548 22.2266C8.99778 24.5794 11.3037 26.2905 14.0353 26.3394C11.8157 27.99 9.09064 28.8821 6.28838 28.8755C5.79097 28.8748 5.29403 28.8462 4.80005 28.79C7.6508 30.5464 10.9646 31.4755 14.3469 31.4667C25.833 31.4667 32.1081 22.4405 32.1081 14.6122C32.1081 14.3556 32.1012 14.0989 32.0885 13.8483C33.3064 13.0124 34.3601 11.9735 35.2001 10.7806Z", fill: color })));
});
