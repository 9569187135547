import { createSelector } from 'reselect';
import { challengeDetailInitialState } from './reducer';
export var selectChallengeDetailDomain = function (state) {
    return state.challengeDetail || challengeDetailInitialState;
};
export var makeSelectChallengeDetailLoading = function () {
    return createSelector(selectChallengeDetailDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectChallengeDetailHasError = function () {
    return createSelector(selectChallengeDetailDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectChallengeDetailError = function () {
    return createSelector(selectChallengeDetailDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectChallengeDetailItem = function () {
    return createSelector(selectChallengeDetailDomain, function (_a) {
        var item = _a.item;
        return item;
    });
};
export var makeChallengeDetail = function () {
    return createSelector(selectChallengeDetailDomain, function (subState) { return subState; });
};
export default makeChallengeDetail;
