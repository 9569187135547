var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import history from './history';
import createRootReducer from './reducers';
import rootSaga from './sagas';
var persistConfig = {
    key: 'jaccede',
    storage: storage,
    blacklist: ['filterPlaces'],
    whitelist: ['languageProvider'],
};
var persistedReducer = persistReducer(persistConfig, createRootReducer(history));
var sagaMiddleware = createSagaMiddleware();
var middlewares = [sagaMiddleware, routerMiddleware(history)];
var enhancers = [applyMiddleware.apply(void 0, __spread(middlewares))];
var composeEnhancer = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;
// @ts-ignore
var composedEnhancers = composeEnhancer.apply(void 0, __spread(enhancers));
var preloadedState = {};
var store = createStore(persistedReducer, preloadedState, composedEnhancers);
var persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { store, persistor, history };
