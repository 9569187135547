var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var EyeOff = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M31.6757 32.5468C31.3933 32.5473 31.1224 32.4349 30.9232 32.2348L7.50536 8.8169C7.10776 8.3984 7.1162 7.73924 7.52438 7.33106C7.93257 6.92287 8.59172 6.91443 9.01023 7.31203L32.4281 30.7299C32.7323 31.0343 32.8233 31.492 32.6586 31.8896C32.494 32.2873 32.106 32.5468 31.6757 32.5468Z", fill: color }),
        React.createElement("path", { d: "M19.4345 23.7551L15.989 20.3096C15.9485 20.2695 15.887 20.2596 15.836 20.285C15.7849 20.3105 15.7558 20.3656 15.7635 20.4221C16.0489 22.2562 17.4879 23.6952 19.3221 23.9806C19.3786 23.9883 19.4337 23.9592 19.4591 23.9082C19.4845 23.8571 19.4747 23.7956 19.4345 23.7551Z", fill: color }),
        React.createElement("path", { d: "M20.499 15.7917L23.9498 19.2412C23.9902 19.2819 24.0521 19.2922 24.1035 19.2666C24.1548 19.2411 24.184 19.1855 24.176 19.1287C23.8913 17.292 22.4502 15.8509 20.6134 15.5662C20.5565 15.5574 20.5005 15.5862 20.4744 15.6375C20.4484 15.6888 20.4583 15.751 20.499 15.7917Z", fill: color }),
        React.createElement("path", { d: "M35.6008 20.9283C36.0506 20.2224 36.048 19.3192 35.5942 18.6158C33.8338 15.8935 31.5499 13.581 28.9899 11.9278C26.1538 10.0969 23.027 9.12892 19.9454 9.12892C18.3209 9.13115 16.7074 9.39602 15.1674 9.91328C15.0798 9.94243 15.0135 10.0149 14.9923 10.1048C14.9712 10.1947 14.9982 10.2892 15.0636 10.3544L18.2064 13.4971C18.2728 13.5637 18.3694 13.5902 18.4605 13.567C20.627 13.039 22.912 13.6793 24.4888 15.256C26.0655 16.8328 26.7058 19.1178 26.1778 21.2843C26.1548 21.3752 26.1813 21.4714 26.2476 21.5377L30.7682 26.0616C30.8657 26.1593 31.0217 26.1662 31.1275 26.0776C32.8676 24.5944 34.3755 22.8587 35.6008 20.9283Z", fill: color }),
        React.createElement("path", { d: "M19.9667 26.1601C18.0078 26.1602 16.1572 25.2614 14.9463 23.7216C13.7353 22.1819 13.2979 20.1716 13.7597 18.2679C13.7827 18.177 13.7561 18.0807 13.6898 18.0144L9.24307 13.5657C9.14531 13.4678 8.98887 13.4611 8.88316 13.5504C7.26054 14.9348 5.75634 16.6193 4.3812 18.5879C3.8889 19.2945 3.87208 20.2284 4.33862 20.9523C6.09563 23.7019 8.35625 26.0177 10.877 27.6483C13.7171 29.4865 16.7681 30.4179 19.9454 30.4179C21.5864 30.4135 23.2167 30.1543 24.778 29.6495C24.8665 29.6212 24.9338 29.5489 24.9557 29.4587C24.9776 29.3685 24.9508 29.2734 24.8852 29.2078L21.7271 26.0503C21.6608 25.984 21.5645 25.9575 21.4736 25.9805C20.9802 26.1 20.4744 26.1603 19.9667 26.1601Z", fill: color })));
});
