import { Button } from 'components';
import { makeSelectIsLoggedIn } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonWrapper, NewTeamContainer, Title } from './styles';
export var ChallengeDetailNewTeamSection = function (_a) {
    var handleModal = _a.handleModal;
    var formatMessage = useIntl().formatMessage;
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var history = useHistory();
    var location = useLocation();
    var handleClick = function () {
        if (!isAuthenticated) {
            history.push(paths.signInPage, {
                from: location.pathname,
            });
        }
        else {
            handleModal();
        }
    };
    return (React.createElement(NewTeamContainer, null,
        React.createElement(Title, null, formatMessage({ id: 'containers.challengesDetail.newTeamSection' })),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { onClick: handleClick }, formatMessage({
                id: 'containers.challengesDetail.createNewTeamButton',
            })))));
};
