import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editPhoto';
var messages = defineMessages({
    uploadError: {
        id: scope + ".uploadError",
    },
    modalTitle: {
        id: scope + ".modalTitle",
    },
    resolutionRule: {
        id: scope + ".resolutionRule",
    },
    sizeRule: {
        id: scope + ".sizeRule",
    },
    extensionsRule: {
        id: scope + ".extensionsRule",
    },
});
export default messages;
