import { HOME_LATEST_NEWS_FAILURE, HOME_LATEST_NEWS_REQUEST, HOME_LATEST_NEWS_SUCCESS, } from './constants';
export var fetchHomeLatestNewsRequest = function () { return ({
    type: HOME_LATEST_NEWS_REQUEST,
}); };
export var setHomeLatestNewsFailure = function (payload) { return ({
    type: HOME_LATEST_NEWS_FAILURE,
    payload: payload,
}); };
export var setHomeLatestNewsSuccess = function (payload) { return ({
    type: HOME_LATEST_NEWS_SUCCESS,
    payload: payload,
}); };
