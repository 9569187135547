var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Send = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M34.706 17.9367L34.6953 17.932L6.22333 6.12265C5.7369 5.91905 5.18076 5.97314 4.74267 6.26665C4.27977 6.56997 4.00063 7.0859 4 7.63932V15.192C4 15.9603 4.54656 16.6199 5.30133 16.7633L20.83 19.6347C20.9559 19.6585 21.047 19.7685 21.047 19.8967C21.047 20.0248 20.9559 20.1348 20.83 20.1587L5.302 23.03C4.54745 23.173 4.00096 23.832 4 24.6V32.154C3.99964 32.6824 4.26492 33.1756 4.706 33.4667C4.9672 33.6402 5.27374 33.7329 5.58733 33.7333C5.80535 33.7333 6.02113 33.6894 6.222 33.6047L34.6933 21.8627L34.706 21.8567C35.4893 21.52 35.997 20.7493 35.997 19.8967C35.997 19.044 35.4893 18.2733 34.706 17.9367Z", fill: color })));
});
