var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var readLocalStorageObject = function (storeKey) {
    return JSON.parse(window.localStorage.getItem(storeKey) || '{}');
};
export function Cache(_a) {
    var pool = _a.pool;
    return function (target, key) {
        Object.defineProperty(target, key, {
            get: function () { return readLocalStorageObject(pool)[key]; },
            set: function (value) {
                var _a;
                return window.localStorage.setItem(pool, JSON.stringify(__assign(__assign({}, readLocalStorageObject(pool)), (_a = {}, _a[key] = value, _a))));
            },
        });
    };
}
