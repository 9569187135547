var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Footer, LoadingIndicator, PageHeader, PageWrapper, Pagination, SubFooter, } from 'components';
import { fetchSubFooterLinksRequest } from 'components/SubFooter/actions';
import { makeSelectSubFooterLinksSearchByTypeOfPlace } from 'components/SubFooter/selectors';
import { makeSelectIsLoggedIn, makeSelectUser, } from 'containers/Auth/selectors';
import { ChallengeDetailDates, ChallengeDetailHeader, ChallengeDetailNewTeamSection, CoverChallenge, MembersScoreBoard, TeamsScoreBoard, } from 'containers/ChallengeDetail';
import { ChallengeDetailPlaceNearbySection } from 'containers/ChallengeDetail/PlacesAroundMe';
import { fetchChallengeDetailRequest } from 'containers/ChallengeDetail/store/challengeBySlug/actions';
import { makeSelectChallengeDetailItem, makeSelectChallengeDetailLoading, } from 'containers/ChallengeDetail/store/challengeBySlug/selectors';
import { makeSelectMembersScoreBoardOnChallengeItems, makeSelectMembersScoreBoardOnChallengeLoading, } from 'containers/ChallengeDetail/store/membersScoreBoard/selectors';
import { fetchTeamsOnChallengeRequest } from 'containers/ChallengeDetail/store/teamsOnChallenge/actions';
import makeTeamsOnChallenge, { makeSelectTeamsOnChallengeLoading, } from 'containers/ChallengeDetail/store/teamsOnChallenge/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import { ArrowBack } from 'icons/mono';
import isEmpty from 'lodash/isEmpty';
import { rem } from 'polished';
import React, { memo, useCallback, useEffect, useMemo, useState, } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { colors } from 'styles';
import { ChallengeCreateNewTeamPage } from '../ChallengeCreateNewTeamPage';
import { Container, Content, PaginationWrapper } from './styles';
export var ChallengeDetailPage = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var locale = useSelector(makeSelectLocale());
    var params = useParams();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var user = useSelector(makeSelectUser());
    var _a = __read(useState(false), 2), openedCreateModal = _a[0], setOpenedCreateModal = _a[1];
    var handleOpenCreateModal = function () { return setOpenedCreateModal(!openedCreateModal); };
    var fetchChallengeDetail = useAction(function (payload) {
        return fetchChallengeDetailRequest(payload);
    });
    var challengeDetail = useSelector(makeSelectChallengeDetailItem());
    var challengeDetailIsLoading = useSelector(makeSelectChallengeDetailLoading());
    var membersScoreBoard = useSelector(makeSelectMembersScoreBoardOnChallengeItems());
    var membersScoreBoardIsLoading = useSelector(makeSelectMembersScoreBoardOnChallengeLoading());
    var fetchTeamsOnChallenge = useAction(function (payload) {
        return fetchTeamsOnChallengeRequest(payload);
    });
    var teamsIsLoading = useSelector(makeSelectTeamsOnChallengeLoading());
    var teams = useSelector(makeTeamsOnChallenge());
    var fetchSubFooterLinks = useAction(fetchSubFooterLinksRequest);
    var searchByType = useSelector(makeSelectSubFooterLinksSearchByTypeOfPlace());
    var handleTeamsPage = function (page) {
        var payload = {
            challengeSlug: params.challengeSlug,
            pagination: {
                page: page,
                perPage: 20,
            },
        };
        fetchTeamsOnChallenge(payload);
    };
    var checkIsMemberOnAnyTeam = useCallback(function () {
        var _a;
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.inChallenge) && ((_a = user === null || user === void 0 ? void 0 : user.ranks) === null || _a === void 0 ? void 0 : _a.team)) {
            return true;
        }
        return false;
    }, [user]);
    var showCreateTeamButton = useMemo(function () {
        if (isAuthenticated && !(challengeDetail === null || challengeDetail === void 0 ? void 0 : challengeDetail.canCreateTeam)) {
            return false;
        }
        if (isAuthenticated && (challengeDetail === null || challengeDetail === void 0 ? void 0 : challengeDetail.isMember)) {
            return false;
        }
        if (checkIsMemberOnAnyTeam()) {
            return false;
        }
        return true;
    }, [challengeDetail, teams]);
    useEffect(function () {
        if (isEmpty(challengeDetail) ||
            challengeDetail.slug !== params.challengeSlug) {
            fetchChallengeDetail(params.challengeSlug);
        }
    }, [challengeDetail]);
    useEffect(function () {
        if (isEmpty(searchByType)) {
            fetchSubFooterLinks();
        }
    }, [searchByType, fetchSubFooterLinks]);
    if (challengeDetailIsLoading || isEmpty(challengeDetail)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageWrapper, null,
                React.createElement(Container, null,
                    React.createElement(PageHeader, { title: formatMessage({ id: 'pages.challengeDetailPage.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return history.push(paths.challengePage); }, sticky: true }),
                    React.createElement(Content, null,
                        React.createElement(LoadingIndicator, null),
                        !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                            React.createElement(SubFooter, null),
                            React.createElement(Footer, null))))))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, challengeDetail.title),
            React.createElement("meta", { name: "description", content: challengeDetail.description }),
            React.createElement("meta", { property: "og:title", content: challengeDetail.title }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: challengeDetail.description }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(Container, null,
                React.createElement(PageHeader, { title: formatMessage({ id: 'pages.challengeDetailPage.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return history.push(paths.challengePage); }, sticky: true }),
                React.createElement(Content, null,
                    React.createElement(CoverChallenge, { cover: challengeDetail.cover }),
                    React.createElement(ChallengeDetailHeader, { title: challengeDetail.title, description: challengeDetail.description }),
                    challengeDetail.isMember && (React.createElement(MembersScoreBoard, { members: membersScoreBoard, isLoading: membersScoreBoardIsLoading })),
                    React.createElement(TeamsScoreBoard, { isMember: challengeDetail.isMember, teams: teams.items, isLoading: teamsIsLoading }),
                    React.createElement(PaginationWrapper, null,
                        React.createElement(Pagination, { hideOnSinglePage: true, showSizeChanger: false, defaultCurrent: 1, pageSize: 20, total: teams.total, onChange: handleTeamsPage, disabled: teams.loading, current: teams.currentPage, showLessItems: isMobile || isTabletOrMobile })),
                    showCreateTeamButton ? (React.createElement(ChallengeDetailNewTeamSection, { handleModal: handleOpenCreateModal })) : (React.createElement(ChallengeDetailPlaceNearbySection, null)),
                    React.createElement(ChallengeDetailDates, { start: challengeDetail.startDatetime, end: challengeDetail.endDatetime }),
                    React.createElement(ChallengeCreateNewTeamPage, { visible: openedCreateModal, handleModal: handleOpenCreateModal }))),
            !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                React.createElement(SubFooter, null),
                React.createElement(Footer, null))))));
});
