var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Checkbox } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var AgreementText = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n\n  a {\n    color: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n\n  a {\n    color: ", ";\n  }\n"])), color, rem(13), rem(16), color);
export var StyledCheckbox = styled(Checkbox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: space-between;\n\n  .ant-checkbox {\n    height: ", ";\n    width: ", ";\n    order: 1;\n    top: 0;\n    margin-left: ", ";\n\n    & + span {\n      margin-left: 0 !important;\n    }\n  }\n"], ["\n  justify-content: space-between;\n\n  .ant-checkbox {\n    height: ", ";\n    width: ", ";\n    order: 1;\n    top: 0;\n    margin-left: ", ";\n\n    & + span {\n      margin-left: 0 !important;\n    }\n  }\n"])), rem(20), rem(20), rem(16));
var templateObject_1, templateObject_2;
