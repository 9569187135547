var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { colors, fonts } from 'styles';
export var CardContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  img {\n    border-radius: ", ";\n    width: 100%;\n  }\n\n  ", ";\n"], ["\n  img {\n    border-radius: ", ";\n    width: 100%;\n  }\n\n  ",
    ";\n"])), rem(10), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    > button {\n      margin: 0 auto;\n    }\n  "], ["\n    > button {\n      margin: 0 auto;\n    }\n  "]))));
export var ImageWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n"])));
export var ImageTitle = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1.2;\n  position: absolute;\n  top: auto;\n  bottom: ", ";\n  left: ", ";\n  right: ", ";\n\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: 1.2;\n  position: absolute;\n  top: auto;\n  bottom: ", ";\n  left: ", ";\n  right: ", ";\n\n  ",
    ";\n"])), colors.base.white, rem(36), fonts.weight.bold, rem(30), rem(20), rem(20), media.lessThan('small')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: ", ";\n  "], ["\n    font-size: ", ";\n  "])), rem(28)));
export var Title = styled.h4(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n"])), rem(21), fonts.weight.semibold, rem(24), rem(16));
export var Message = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  margin-bottom: ", ";\n"])), rem(16), rem(24));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
