var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { fonts } from 'styles';
export var Heading = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  width: ", ";\n  white-space: pre-line;\n\n  ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  width: ", ";\n  white-space: pre-line;\n\n  ",
    ";\n"])), rem(18), fonts.weight.regular, rem(48), rem(480), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: ", ";\n    text-align: center;\n    margin-bottom: ", ";\n    width: 100%;\n  "], ["\n    font-size: ", ";\n    text-align: center;\n    margin-bottom: ", ";\n    width: 100%;\n  "])), rem(16), rem(32)));
var templateObject_1, templateObject_2;
