var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var HeartLight = memo(function (props) { return (React.createElement("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.755 9.31c-3.001 0-5.469 2.631-5.5 5.867-.07 7.241 5.783 12.373 11.518 16.437.137.1.316.1.454 0 5.735-4.064 11.587-9.196 11.518-16.438-.032-3.235-2.498-5.866-5.5-5.866-3.734 0-5.665 3.906-5.684 3.945a.626.626 0 01-.56.361H20a.625.625 0 01-.56-.361c-.02-.039-1.951-3.945-5.685-3.945zM20 33c-.325 0-.65-.1-.933-.302-6.009-4.26-12.142-9.674-12.066-17.535C7.039 11.213 10.069 8 13.755 8c3.272 0 5.326 2.348 6.245 3.716C20.92 10.348 22.973 8 26.245 8c3.686 0 6.716 3.213 6.754 7.163.076 7.861-6.057 13.276-12.067 17.536-.283.2-.607.301-.932.301z", fill: "#4A4A4A" }),
    React.createElement("path", { opacity: ".3", fillRule: "evenodd", clipRule: "evenodd", d: "M9 15.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z", fill: "#4A4A4A" }))); });
