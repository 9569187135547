import { Rate } from 'components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Content, RateValue, Title } from './styles';
export var Ratings = function (_a) {
    var _b = _a.value, value = _b === void 0 ? 0 : _b;
    return (React.createElement(Container, null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "containers.placeDetails.ratings" })),
        React.createElement(Content, null,
            React.createElement(RateValue, null, value || '0.0'),
            React.createElement(Rate, { value: value, size: 14 }))));
};
