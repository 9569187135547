var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { photoAddedEvent } from 'utils/tagEvents';
import { deletePhotoFailure, deletePhotoSuccess, getPhotos as getPhotosAction, getPlacePhotosFailure, getPlacePhotosSuccess, postPlacePhotosSuccess, putPlacePhotosFailure, putPlacePhotosSuccess, reportMediaPlaceFailure, reportMediaPlaceSuccess, } from './actions';
import { DELETE_PHOTO, GET_PHOTOS_ACTION_REQUEST, POST_PHOTOS_ACTION_REQUEST, PUT_PHOTOS_ACTION_REQUEST, REPORT_MEDIA_PLACE, } from './constants';
export function getPhotos(_a) {
    var response, payloadSuccess, error_1, err, _b;
    var payload = _a.payload;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(api.places.getPlacesPhotos, payload)];
            case 1:
                response = _d.sent();
                payloadSuccess = {
                    currentPage: response.data.currentPage,
                    items: response.data.items,
                };
                return [4 /*yield*/, put(getPlacePhotosSuccess(payloadSuccess))];
            case 2:
                _d.sent();
                return [3 /*break*/, 7];
            case 3:
                error_1 = _d.sent();
                err = error_1;
                _b = (_c = err.response) === null || _c === void 0 ? void 0 : _c.status;
                switch (_b) {
                    case 404: return [3 /*break*/, 4];
                }
                return [3 /*break*/, 4];
            case 4: return [4 /*yield*/, put(getPlacePhotosFailure())];
            case 5:
                _d.sent();
                return [3 /*break*/, 6];
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function postPhotos(_a) {
    var latitude, longitude, responseGeolocalize, response;
    var placeId = _a.placeId, file = _a.file, name = _a.name;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                latitude = (_b = file.get('latitude')) !== null && _b !== void 0 ? _b : 0;
                longitude = (_c = file.get('longitude')) !== null && _c !== void 0 ? _c : 0;
                if (!(latitude === 0 || longitude === 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, call(api.services.geolocalize)];
            case 1:
                responseGeolocalize = _d.sent();
                if (responseGeolocalize.status === 200) {
                    file.append('latitude', responseGeolocalize.data.latitude);
                    file.append('latitude', responseGeolocalize.data.longitude);
                }
                _d.label = 2;
            case 2: return [4 /*yield*/, call(api.places.postPlacesPhotos, placeId, file)];
            case 3:
                response = _d.sent();
                return [4 /*yield*/, put(postPlacePhotosSuccess({
                        created: response.data.created,
                        criterion: response.data.criterion,
                        criterionId: response.data.criterionId,
                        filename: response.data.filename,
                        id: response.data.id,
                        isMain: response.data.isMain,
                        isMine: response.data.isMine,
                        type: response.data.type,
                        url: response.data.url,
                        urlLarge: response.data.urlLarge,
                        urlLow: response.data.urlLow,
                        urlStandard: response.data.urlStandard,
                        urlThumb: response.data.urlThumb,
                        contribution: response.data.contribution,
                    }))];
            case 4:
                _d.sent();
                if (!name) return [3 /*break*/, 6];
                return [4 /*yield*/, call(photoAddedEvent, name)];
            case 5:
                _d.sent();
                _d.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
export function putPhotos(_a) {
    var error_2;
    var placeId = _a.placeId, mediaId = _a.mediaId;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call(api.places.putPlacesPhotos, placeId, mediaId)];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(getPhotosAction(placeId))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(putPlacePhotosSuccess())];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_2 = _b.sent();
                return [4 /*yield*/, put(putPlacePhotosFailure())];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function reportMediaPlace(_a) {
    var _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(api.places.reportMedia, payload)];
            case 1:
                _c.sent();
                return [4 /*yield*/, put(reportMediaPlaceSuccess())];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [4 /*yield*/, put(reportMediaPlaceFailure())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function deletePhoto(_a) {
    var response, payloadSuccess, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(api.places.deletePhoto, payload.placeId, payload.photoId)];
            case 1:
                _c.sent();
                return [4 /*yield*/, call(api.places.getPlacesPhotos, payload.placeId)];
            case 2:
                response = _c.sent();
                payloadSuccess = {
                    currentPage: response.data.currentPage,
                    items: response.data.items,
                };
                return [4 /*yield*/, put(getPlacePhotosSuccess(payloadSuccess))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(deletePhotoSuccess())];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5:
                _b = _c.sent();
                response = _b.response;
                return [4 /*yield*/, put(deletePhotoFailure(response.data.message))];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export default function placePhotosSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(GET_PHOTOS_ACTION_REQUEST, getPhotos)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(POST_PHOTOS_ACTION_REQUEST, postPhotos)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(PUT_PHOTOS_ACTION_REQUEST, putPhotos)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(REPORT_MEDIA_PLACE, reportMediaPlace)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(DELETE_PHOTO, deletePhoto)];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
