import { CONTRIBUTOR_USER_ACCOUNT_FAILURE, CONTRIBUTOR_USER_ACCOUNT_REQUEST, CONTRIBUTOR_USER_ACCOUNT_SUCCESS, } from '../constants';
export var fetchContributorUserAccountRequest = function (userSlug) { return ({
    type: CONTRIBUTOR_USER_ACCOUNT_REQUEST,
    payload: userSlug,
}); };
export var fetchContributorUserAccountSuccess = function (payload) { return ({
    type: CONTRIBUTOR_USER_ACCOUNT_SUCCESS,
    payload: payload,
}); };
export var fetchContributorUserAccountError = function (error) { return ({
    type: CONTRIBUTOR_USER_ACCOUNT_FAILURE,
    payload: error,
}); };
