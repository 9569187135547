import produce from 'immer';
import { ADD_PLACE, ADD_PLACE_SUCCESS, CLEAR_VALUES, contents, IGNORE_SUGGESTED_PLACES, MATCH_PLACE, MATCH_PLACE_FAILURE, MATCH_PLACE_SUCCESS, } from './constants';
export var initialState = {
    loading: false,
    placeExists: false,
    suggestedPlaces: [],
    currentContent: contents.match,
    placeToAdd: {
        name: '',
        lat: 0,
        lng: 0,
    },
};
var addNewPlaceReducer = produce(function (draft, action) {
    var _a;
    switch (action.type) {
        case MATCH_PLACE:
            draft.loading = true;
            draft.placeToAdd = action.payload;
            break;
        case MATCH_PLACE_SUCCESS:
            draft.loading = false;
            draft.placeExists = action.payload.isExisting;
            draft.currentContent = ((_a = action.payload.items) === null || _a === void 0 ? void 0 : _a.length) ? contents.suggestedPlaces
                : contents.addNew;
            draft.suggestedPlaces = action.payload.items;
            break;
        case MATCH_PLACE_FAILURE:
            draft.loading = false;
            break;
        case IGNORE_SUGGESTED_PLACES:
            draft.placeExists = initialState.placeExists;
            draft.suggestedPlaces = initialState.suggestedPlaces;
            draft.currentContent = contents.addNew;
            break;
        case ADD_PLACE:
            draft.loading = true;
            break;
        case CLEAR_VALUES:
        case ADD_PLACE_SUCCESS:
            draft.placeExists = initialState.placeExists;
            draft.currentContent = initialState.currentContent;
            draft.suggestedPlaces = initialState.suggestedPlaces;
            draft.placeToAdd = initialState.placeToAdd;
            draft.loading = false;
            break;
        default:
    }
}, initialState);
export default addNewPlaceReducer;
