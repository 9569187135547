import React, { memo } from 'react';
export var CarWashOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#b3b3b3", style: { fill: '#b3b3b3' }, d: "M16.152 30.171c8.247 0 14.933-6.686 14.933-14.933s-6.686-14.933-14.933-14.933c-8.247 0-14.933 6.686-14.933 14.933s6.686 14.933 14.933 14.933z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M20.658 16.196l-1.105-2.444c-0.221-0.516-0.663-0.952-1.324-0.952h-4.421c-0.515 0-1.255 0.177-1.548 0.879l-1.214 2.501c-0.734 0.184-0.988 0.606-0.988 1.454v3.394h0.914v0.476c0 0.701 0.477 1.179 1.065 1.179 0.589 0 1.068-0.478 1.068-1.142v-0.514h5.486v0.66c0 0.517 0.759 0.996 1.313 0.996 0.478 0 1.125-0.441 1.125-0.996v-0.66h0.914v-3.321c0-0.738-0.366-1.477-1.285-1.511zM11.927 19.144c-0.516 0-0.998-0.442-0.998-0.993 0-0.517 0.443-0.959 0.998-0.959s0.994 0.478 0.994 1.032c-0 0.517-0.406 0.921-0.994 0.921zM12.073 16.152l0.962-1.871c0.146-0.294 0.33-0.568 0.773-0.568h4.162c0.442 0 0.664 0.235 0.775 0.532l0.883 1.906h-7.555zM19.889 19.219c-0.592 0-1.070-0.478-1.070-0.995 0-0.553 0.478-0.997 0.997-0.997 0.627 0 1.030 0.443 1.030 0.959 0 0.592-0.403 1.033-0.956 1.033z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M15.886 10.924c0.692 0.002 1.255-0.557 1.257-1.248 0-0.152-0.030-0.317-0.080-0.432s-1.165-2.321-1.165-2.321l-1.179 2.315c-0.050 0.135-0.082 0.279-0.083 0.43-0.001 0.691 0.558 1.254 1.25 1.256z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M12.19 10.924c0.692 0.002 1.256-0.557 1.257-1.248 0.001-0.152-0.030-0.317-0.080-0.432s-1.165-2.321-1.165-2.321l-1.179 2.315c-0.050 0.135-0.082 0.279-0.083 0.43-0.001 0.691 0.558 1.254 1.25 1.256z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M19.58 10.924c0.692 0.002 1.256-0.557 1.257-1.248 0-0.152-0.030-0.317-0.080-0.432s-1.164-2.321-1.164-2.321l-1.18 2.315c-0.050 0.135-0.082 0.279-0.083 0.43-0.001 0.691 0.558 1.254 1.25 1.256z" })));
});
