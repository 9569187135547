import { PROFILE_MY_CONTRIBUTIONS_FAILURE, PROFILE_MY_CONTRIBUTIONS_REQUEST, PROFILE_MY_CONTRIBUTIONS_SUCCESS, } from '../constants';
export var fetchMyContributionsRequest = function (payload) { return ({
    type: PROFILE_MY_CONTRIBUTIONS_REQUEST,
    payload: payload,
}); };
export var fetchMyContributionsSuccess = function (payload) { return ({
    type: PROFILE_MY_CONTRIBUTIONS_SUCCESS,
    payload: payload,
}); };
export var fetchMyContributionsError = function (error) { return ({
    type: PROFILE_MY_CONTRIBUTIONS_FAILURE,
    payload: error,
}); };
