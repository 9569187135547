var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Button = memo(function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'button' : _b, _c = _a.variant, variant = _c === void 0 ? 'primary' : _c, _d = _a.size, size = _d === void 0 ? 'medium' : _d, _e = _a.shape, shape = _e === void 0 ? 'rounded' : _e, _f = _a.iconPosition, iconPosition = _f === void 0 ? 'left' : _f, Icon = _a.icon, children = _a.children, rest = __rest(_a, ["type", "variant", "size", "shape", "iconPosition", "icon", "children"]);
    return (React.createElement(Container, __assign({ type: type, variant: variant, size: size, shape: shape }, rest),
        iconPosition === 'left' && Icon && Icon,
        children && React.createElement("span", null, children),
        iconPosition === 'right' && Icon && Icon));
});
