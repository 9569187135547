/* eslint-disable @typescript-eslint/camelcase */
import { axios, blogAxios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    homeNew: versionV4 + "/places/home/new",
    homeLinks: versionV4 + "/places/home/links",
    homeLatestNews: "/v2/posts?per_page=3&_embed=",
};
export default {
    homeNews: function () { return axios.get(endpoints.homeNew); },
    homeLinks: function () { return axios.get(endpoints.homeLinks); },
    homeLatestNews: function () { return blogAxios.get(endpoints.homeLatestNews); },
};
