var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Input as AntInput } from 'antd';
import { rem, rgba } from 'polished';
import React, { memo } from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
export var Input = memo(function (props) { return (React.createElement(InputWrapper, null,
    React.createElement(AntInput, __assign({}, props)))); });
var placeholderColor = theme('mode', {
    light: colors.space.tint600,
    dark: colors.carbon.tint300,
});
var InputWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-input {\n    border-radius: ", ";\n    font-size: ", " !important;\n    padding: ", " ", ";\n\n    &::placeholder {\n      color: ", ";\n    }\n\n    &:focus {\n      border-color: ", ";\n      box-shadow: 0 0 0 ", " ", ";\n      outline: 0;\n    }\n  }\n"], ["\n  .ant-input {\n    border-radius: ", ";\n    font-size: ", " !important;\n    padding: ", " ", ";\n\n    &::placeholder {\n      color: ", ";\n    }\n\n    &:focus {\n      border-color: ", ";\n      box-shadow: 0 0 0 ", " ", ";\n      outline: 0;\n    }\n  }\n"])), rem(10), rem(15), rem(8), rem(16), placeholderColor, rgba(colors.base.info, 0.5), rem(2), rgba(colors.base.info, 0.5));
var templateObject_1;
