import * as Yup from 'yup';
var MatchPlaceFormSchema = function (formatMessage) {
    return Yup.object().shape({
        name: Yup.string().required(formatMessage({
            id: 'pages.explorePlacesPage.newPlaceModal.nameValidation',
        })),
        googlePlaceId: Yup.string().required(formatMessage({
            id: 'pages.explorePlacesPage.newPlaceModal.addressValidation',
        })),
    });
};
export default MatchPlaceFormSchema;
