import * as Yup from 'yup';

import messages from './messages';

const signUpNewsletterSchema = (formatMessage) =>
  Yup.object().shape({
    email: Yup.string().email().required(formatMessage(messages.emailRequired)),
  });

export const defaultProps = {
  email: '',
};

export default signUpNewsletterSchema;
