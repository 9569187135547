var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import produce from 'immer';
import { WEEK_USERS_FAILURE, WEEK_USERS_REQUEST, WEEK_USERS_SUCCESS, } from './constants';
export var weekUsersInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    currentPage: 1,
    pages: 1,
    total: 0,
    items: undefined,
};
var weekUsersReducer = produce(function (draft, action) {
    switch (action.type) {
        case WEEK_USERS_REQUEST:
            draft.loading = true;
            break;
        case WEEK_USERS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case WEEK_USERS_SUCCESS:
            draft.loading = false;
            draft.hasError = true;
            draft.error = undefined;
            draft.currentPage = action.payload.currentPage;
            draft.pages = action.payload.pages;
            draft.total = action.payload.total;
            draft.items =
                action.payload.currentPage === 1
                    ? action.payload.items
                    : __spread(draft.items, action.payload.items);
            break;
        default:
    }
}, weekUsersInitialState);
export default weekUsersReducer;
