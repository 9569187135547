import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'utils/hooks';
import { Background, ChallengesHeader, Hashtag, Video } from './styles';
export var Header = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var videoUrl = "https://cdn1.jaccede.com/challenge/videos/tuto_" + locale + ".mp4";
    return (React.createElement(ChallengesHeader, null,
        React.createElement(Background, null),
        React.createElement(Hashtag, null, formatMessage({ id: 'containers.challenges.header.hashtag' })),
        React.createElement(Video, { controls: true, playsInline: true, preload: "auto", width: "100%", height: "100%" },
            React.createElement("source", { src: videoUrl }))));
};
