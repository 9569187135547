var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var TrophyDark = memo(function (props) { return (React.createElement("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.276 13.433h13.488V6.922H13.276v6.511z", fill: "#B9BBBE", fillOpacity: ".3" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.133 11.35a6.196 6.196 0 01-4.53 5.967c.003-.084.014-.166.014-.25V9.612h4.516v1.738zm-5.912 5.717c0 3.42-2.782 6.2-6.2 6.2a6.207 6.207 0 01-6.202-6.2V7.62h12.402v9.448zM7.907 11.35V9.612h4.517v7.455c0 .085.01.166.013.25a6.196 6.196 0 01-4.53-5.967zm16.73 21.563h-9.234a3.27 3.27 0 013.23-2.79h2.774c1.639 0 3 1.214 3.23 2.79zm2.98-24.696V6.224H12.424v1.993H6.512v3.133a7.592 7.592 0 006.118 7.448c.735 3.136 3.413 5.53 6.693 5.83v4.1h-.69c-2.569 0-4.659 2.09-4.659 4.66v.92h12.093v-.92c0-2.57-2.09-4.66-4.66-4.66h-.689v-4.1c3.28-.3 5.957-2.694 6.692-5.83a7.592 7.592 0 006.119-7.448V8.216h-5.912z", fill: "#B9BBBE" }))); });
