import { Footer, PageWrapper, SubFooter } from 'components';
import { fetchSubFooterLinksRequest } from 'components/SubFooter/actions';
import { makeSelectSubFooterLinksSearchByTypeOfPlace } from 'components/SubFooter/selectors';
import { AccessSecretChallenge, Header, PublicChallenges, } from 'containers/Challenges';
import { fetchPublicChallengesRequest } from 'containers/Challenges/store/publicChallenges/actions';
import { makeSelectPublicChallengesHasError, makeSelectPublicChallengesItems, makeSelectPublicChallengesLoading, } from 'containers/Challenges/store/publicChallenges/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { useAction, useSelector } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import React, { memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
export var ChallengePage = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var fetchPublicRequest = useAction(fetchPublicChallengesRequest);
    var publicChallenges = useSelector(makeSelectPublicChallengesItems());
    var publicChallengesIsLoading = useSelector(makeSelectPublicChallengesLoading());
    var publicChallengesHasError = useSelector(makeSelectPublicChallengesHasError());
    var fetchSubFooterLinks = useAction(fetchSubFooterLinksRequest);
    var searchByType = useSelector(makeSelectSubFooterLinksSearchByTypeOfPlace());
    useEffect(function () {
        if (isEmpty(publicChallenges)) {
            fetchPublicRequest();
        }
    }, [publicChallenges, fetchPublicRequest]);
    useEffect(function () {
        if (isEmpty(searchByType)) {
            fetchSubFooterLinks();
        }
    }, [searchByType, fetchSubFooterLinks]);
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.challengePage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({ id: 'pages.challengePage.description' }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.challengePage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({ id: 'pages.challengePage.description' }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(Header, null),
            React.createElement(PublicChallenges, { publicChallenges: publicChallenges, isLoading: publicChallengesIsLoading, hasError: publicChallengesHasError }),
            React.createElement(AccessSecretChallenge, null),
            !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                React.createElement(SubFooter, null),
                React.createElement(Footer, null))))));
});
