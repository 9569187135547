var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var text = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
export var CardHeaderContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  border-bottom: ", " solid ", ";\n  display: flex;\n  margin-bottom: ", ";\n  padding: ", " 0;\n"], ["\n  align-items: center;\n  border-bottom: ", " solid ", ";\n  display: flex;\n  margin-bottom: ", ";\n  padding: ", " 0;\n"])), rem(1), borderColor, rem(16), rem(16));
export var LeftStick = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-top-right-radius: ", ";\n  height: ", ";\n  margin-right: ", ";\n  width: ", ";\n"], ["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-top-right-radius: ", ";\n  height: ", ";\n  margin-right: ", ";\n  width: ", ";\n"])), colors.base.secondary, rem(4), rem(4), rem(35), rem(20), rem(4));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  flex: 1;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  flex: 1;\n"])), text, rem(18), fonts.weight.bold);
var templateObject_1, templateObject_2, templateObject_3;
