import { Button } from 'components';
import React, { memo } from 'react';
import { CardContainer, ImageTitle, ImageWrapper, Message, Title, } from './styles';
export var Card = memo(function (_a) {
    var image = _a.image, alternativeText = _a.alternativeText, imageText = _a.imageText, title = _a.title, buttonText = _a.buttonText, buttonAction = _a.buttonAction, children = _a.children;
    return (React.createElement(CardContainer, null,
        React.createElement(ImageWrapper, null,
            React.createElement(ImageTitle, null, imageText),
            React.createElement("img", { src: image, alt: alternativeText })),
        React.createElement(Title, null, title),
        React.createElement(Message, null, children),
        React.createElement(Button, { variant: "warning", onClick: buttonAction }, buttonText)));
});
