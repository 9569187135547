import { PLACES_AROUND_BOOKMARK, PLACES_AROUND_FAILURE, PLACES_AROUND_REQUEST, PLACES_AROUND_SET_LAT, PLACES_AROUND_SET_LNG, PLACES_AROUND_SUCCESS, } from './constants';
export var fetchPlacesAround = function (payload) { return ({
    type: PLACES_AROUND_REQUEST,
    payload: payload,
}); };
export var fetchPlacesAroundSuccess = function (payload) { return ({
    type: PLACES_AROUND_SUCCESS,
    payload: payload,
}); };
export var fetchPlacesAroundFailure = function (error) { return ({ type: PLACES_AROUND_FAILURE, payload: error }); };
export var setPlacesAroundLat = function (lat) { return ({
    type: PLACES_AROUND_SET_LAT,
    payload: lat,
}); };
export var setPlacesAroundLng = function (lng) { return ({
    type: PLACES_AROUND_SET_LNG,
    payload: lng,
}); };
export var placesAroundBookmark = function (payload) { return ({
    type: PLACES_AROUND_BOOKMARK,
    payload: payload,
}); };
