var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { notification } from 'antd';
import { AlertNewSite, Footer, MobileMenu, Navbar, SubFooter, } from 'components';
import { fetchSubFooterLinksRequest } from 'components/SubFooter/actions';
import { AccountActivated } from 'containers/AccountActivated';
import { accountActivationRequest } from 'containers/AccountActivated/actions';
import { makeSelectUserSlug } from 'containers/Auth/selectors';
import { BrowsePlaces, Contribute, Header, LastPlacesUpdates, LatestNews, LearnMore, MostActive, SignUpNewsletter, SocialNetworks, } from 'containers/Home';
import { fetchHomeNewsRequest } from 'containers/Home/store/homeNews/actions';
import { makeSelectHomeNewsLatestPlacesUpdates, makeSelectHomeNewsLoading, } from 'containers/Home/store/homeNews/selectors';
import { fetchHomeLatestNewsRequest } from 'containers/Home/store/latestNews/actions';
import { makeSelectHomeLatestNewsHasError, makeSelectHomeLatestNewsItems, makeSelectHomeLatestNewsLoading, } from 'containers/Home/store/latestNews/selectors';
import { fetchHomeMostActiveWeeklyRequest } from 'containers/Home/store/mostActive/actions';
import { makeSelectHomeMostActiveUsersItems } from 'containers/Home/store/mostActive/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { useAction, useGeolocation, useQuery, useSelector } from 'hooks';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import slice from 'lodash/slice';
import Qs from 'qs';
import React, { memo, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { HomePageWrapper } from './styles';
export var HomePage = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var queryParams = useQuery();
    var geolocation = useGeolocation();
    var loggedUserSlug = useSelector(makeSelectUserSlug());
    var _a = __read(useState([]), 2), podiumUsers = _a[0], setPodiumUsers = _a[1];
    var _b = __read(useState([]), 2), rankUsers = _b[0], setRankUsers = _b[1];
    var locale = useSelector(makeSelectLocale());
    var location = useLocation();
    var parsedSearch = Qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    var token = get(parsedSearch, 'activate_token', '');
    var handleAccountActivationRequest = useAction(function (payload) { return accountActivationRequest(payload); });
    var mostActiveUsers = useSelector(makeSelectHomeMostActiveUsersItems());
    var fetchHomeNews = useAction(fetchHomeNewsRequest);
    var fetchSubFooterLinks = useAction(fetchSubFooterLinksRequest);
    var latestUpdates = useSelector(makeSelectHomeNewsLatestPlacesUpdates());
    var latestPlacesUpdatesisLoading = useSelector(makeSelectHomeNewsLoading());
    var fetchMostActiveUsers = useAction(fetchHomeMostActiveWeeklyRequest);
    var fetchLatestNews = useAction(fetchHomeLatestNewsRequest);
    var latestNews = useSelector(makeSelectHomeLatestNewsItems());
    var latestNewsIsLoading = useSelector(makeSelectHomeLatestNewsLoading());
    var latestNewsHasError = useSelector(makeSelectHomeLatestNewsHasError());
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    useEffect(function () {
        if (token) {
            handleAccountActivationRequest({ token: token });
        }
    }, [token]);
    useEffect(function () {
        if (isEmpty(latestUpdates)) {
            fetchHomeNews();
        }
    }, [latestUpdates, fetchHomeNews]);
    useEffect(function () {
        if (isEmpty(latestUpdates)) {
            fetchSubFooterLinks();
        }
    }, [latestUpdates, fetchSubFooterLinks]);
    useEffect(function () {
        if (isEmpty(mostActiveUsers)) {
            fetchMostActiveUsers();
        }
    }, [mostActiveUsers, fetchMostActiveUsers]);
    useEffect(function () {
        if (isEmpty(latestNews)) {
            fetchLatestNews();
        }
    }, [latestNews, fetchLatestNews]);
    useEffect(function () {
        var topThreeUsers = slice(mostActiveUsers, 0, 3);
        if (topThreeUsers && topThreeUsers.length > 0) {
            setPodiumUsers(topThreeUsers);
        }
        var restOfTopUsers = slice(mostActiveUsers, 3);
        if (restOfTopUsers && restOfTopUsers.length > 0) {
            setRankUsers(restOfTopUsers);
        }
    }, [mostActiveUsers]);
    useEffect(function () {
        var newsLetterOptin = queryParams.get('nloptin') || false;
        if (newsLetterOptin === 'success') {
            notification.success({
                message: formatMessage({
                    id: 'containers.home.signUpNewsletter.optinSuccess',
                }),
            });
        }
        else if (newsLetterOptin === 'fail') {
            notification.error({
                message: formatMessage({
                    id: 'containers.home.signUpNewsletter.optinFailure',
                }),
            });
        }
    }, []);
    var _c = __read(useState(true), 2), alert = _c[0], setAlert = _c[1];
    var handleAlert = function () {
        setAlert(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.homePage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({ id: 'pages.homePage.description' }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.homePage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({ id: 'pages.homePage.description' }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(HomePageWrapper, null,
            React.createElement(AlertNewSite, { onClick: handleAlert, hasAlert: alert }),
            React.createElement(Navbar, { hasSearchField: false, hasAlert: alert, isTransparent: true }),
            React.createElement(AccountActivated, null),
            React.createElement(Header, { geolocation: geolocation }),
            !(isMobile || isTabletOrMobile) && (React.createElement(LastPlacesUpdates, { isLoading: latestPlacesUpdatesisLoading, latestPlacesUpdates: latestUpdates })),
            mostActiveUsers && (React.createElement(MostActive, { podiumUsers: podiumUsers, rankUsers: rankUsers, loggedUserSlug: loggedUserSlug })),
            React.createElement(LearnMore, null),
            React.createElement(BrowsePlaces, null),
            React.createElement(Contribute, null),
            React.createElement(LatestNews, { latestNews: latestNews, loading: latestNewsIsLoading, hasError: latestNewsHasError }),
            React.createElement(SocialNetworks, null),
            React.createElement(SignUpNewsletter, null),
            !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null,
                React.createElement(SubFooter, { geolocation: geolocation }),
                React.createElement(Footer, null))),
            React.createElement(MobileMenu, null))));
});
