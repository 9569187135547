var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { switchProp } from 'styled-tools';
import { breakpoints, colors, fonts } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
var headerBackground = theme('mode', {
    light: colors.base.secondary,
    dark: colors.carbon.tint900,
});
var footerTitleBackgroundPrimary = theme('mode', {
    light: colors.base.secondary,
    dark: colors.carbon.tint900,
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: calc(100vh - ", ");\n  padding-bottom: ", ";\n  background-color: ", ";\n\n  overflow-x: auto;\n\n  ", "\n\n  > div {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n"], ["\n  width: 100%;\n  height: calc(100vh - ", ");\n  padding-bottom: ", ";\n  background-color: ", ";\n\n  overflow-x: auto;\n\n  ",
    "\n\n  > div {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n"])), rem(140), rem(100), background, media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: calc(100vh - ", ");\n  "], ["\n    height: calc(100vh - ", ");\n  "])), rem(80)));
export var Header = styled.header(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n  width: 100%;\n  max-width: ", ";\n  background-color: ", ";\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 0 0 ", " ", ";\n\n  > h1 {\n    color: ", ";\n    font-size: ", ";\n    margin: 0;\n  }\n"], ["\n  height: ", ";\n  width: 100%;\n  max-width: ", ";\n  background-color: ", ";\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 0 0 ", " ", ";\n\n  > h1 {\n    color: ", ";\n    font-size: ", ";\n    margin: 0;\n  }\n"])), rem(90), breakpoints.medium, headerBackground, rem(20), rem(20), colors.base.white, rem(21));
export var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n  max-width: ", ";\n"], ["\n  width: 100%;\n  position: relative;\n  max-width: ", ";\n"])), rem(480));
export var ContentTitle = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  text-align: center;\n  padding: ", ";\n"], ["\n  font-size: ", ";\n  text-align: center;\n  padding: ", ";\n"])), rem(18), rem(25));
export var Footer = styled.footer(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  display: block;\n  position: fixed;\n  left: 0;\n  bottom: ", ";\n\n  z-index: 200;\n\n  ", "\n"], ["\n  width: 100%;\n  display: block;\n  position: fixed;\n  left: 0;\n  bottom: ", ";\n\n  z-index: 200;\n\n  ",
    "\n"])), rem(80), media.greaterThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    bottom: 0;\n  "], ["\n    bottom: 0;\n  "]))));
export var FooterTitle = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  padding: ", ";\n  display: flex;\n  justify-content: center;\n\n  > span {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  ", "\n"], ["\n  width: 100%;\n  padding: ", ";\n  display: flex;\n  justify-content: center;\n\n  > span {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  ",
    "\n"])), rem(12), rem(14), fonts.weight.bold, switchProp('variant', {
    ghost: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      background-color: ", ";\n\n      > span {\n        color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n\n      > span {\n        color: ", ";\n      }\n    "])), colors.space.tint200, colors.carbon.tint100),
    primary: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      background-color: ", ";\n\n      > span {\n        color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n\n      > span {\n        color: ", ";\n      }\n    "])), footerTitleBackgroundPrimary, colors.base.white),
}));
export var TabPaneTitle = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n"])), fonts.weight.bold, rem(16));
export var GlobalScoreboardUserWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 0 ", ";\n"], ["\n  background-color: ", ";\n  padding: 0 ", ";\n"])), colors.base.white, rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
