var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { AvatarWrapper } from './styles';
export var Avatar = memo(function (_a) {
    var alt = _a.alt, _b = _a.src, src = _b === void 0 ? 'defaultImage.jpg' : _b, _c = _a.size, size = _c === void 0 ? 'medium' : _c, _d = _a.shape, shape = _d === void 0 ? 'rounded' : _d, props = __rest(_a, ["alt", "src", "size", "shape"]);
    return (React.createElement(AvatarWrapper, __assign({ src: src, alt: alt, size: size, shape: shape }, props)));
});
