var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var shadow = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
var buttonColor = theme('mode', {
    light: colors.space.tint700,
    dark: colors.base.white,
});
var buttonBackground = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n"], ["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n"])), rem(1), border, rem(16));
export var SocialNetworks = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-bottom: ", ";\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-bottom: ", ";\n  margin-top: ", ";\n"])), rem(16), rem(8));
export var ExternalLink = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  box-shadow: 0 2px 4px 0 ", ";\n  border-radius: 50%;\n  color: ", ";\n  margin: 0 ", ";\n\n  button {\n    cursor: pointer;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    height: ", ";\n    width: ", ";\n\n    &:focus {\n      ", ";\n      border-radius: 50%;\n    }\n  }\n"], ["\n  background-color: ", ";\n  box-shadow: 0 2px 4px 0 ", ";\n  border-radius: 50%;\n  color: ", ";\n  margin: 0 ", ";\n\n  button {\n    cursor: pointer;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    height: ", ";\n    width: ", ";\n\n    &:focus {\n      ", ";\n      border-radius: 50%;\n    }\n  }\n"])), buttonBackground, rgba(colors.base.black, 0.2), buttonColor, rem(6), rem(32), rem(32), shadow);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
