/* eslint-disable no-underscore-dangle */
import { avatarImage1, avatarImage2, avatarImage3, placeholderNews, wheelchairGirls, wheelchairHand, } from 'assets/images';
import { NewsCard } from 'components';
import moment from 'moment';
import React, { memo } from 'react';
import { Wrapper } from './styles';
export var CardListDesktop = memo(function (_a) {
    var _b = _a.latestNews, latestNews = _b === void 0 ? [] : _b;
    var _c, _d, _e, _f, _g, _h;
    return (React.createElement(React.Fragment, null, latestNews && latestNews.length > 0 && (React.createElement(Wrapper, null,
        React.createElement(NewsCard, { avatar: ((_c = latestNews[0]._embedded) === null || _c === void 0 ? void 0 : _c.author[0].avatar_urls['24']) || avatarImage1, background: placeholderNews, title: latestNews[0].title.rendered, author: (_d = latestNews[0]._embedded) === null || _d === void 0 ? void 0 : _d.author[0].name, date: moment(latestNews[0].date).format('ll') }),
        React.createElement(NewsCard, { avatar: ((_e = latestNews[1]._embedded) === null || _e === void 0 ? void 0 : _e.author[0].avatar_urls['24']) || avatarImage2, background: wheelchairGirls, title: latestNews[1].title.rendered, author: (_f = latestNews[1]._embedded) === null || _f === void 0 ? void 0 : _f.author[0].name, date: moment(latestNews[1].date).format('ll') }),
        React.createElement(NewsCard, { avatar: ((_g = latestNews[2]._embedded) === null || _g === void 0 ? void 0 : _g.author[0].avatar_urls['24']) || avatarImage3, background: wheelchairHand, title: latestNews[2].title.rendered, author: (_h = latestNews[2]._embedded) === null || _h === void 0 ? void 0 : _h.author[0].name, date: moment(latestNews[2].date).format('ll') })))));
});
