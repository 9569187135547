var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal25000DisabledMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, _b = _a.progress, progress = _b === void 0 ? 0 : _b, props = __rest(_a, ["size", "dark", "progress"]);
    var strokeDasharraySize = 190;
    var strokeDasharray = (strokeDasharraySize * progress) / 100;
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-5-gray", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: dark ? colors.carbon.tint100 : colors.space.tint200, strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round", strokeDasharray: strokeDasharray + ", " + strokeDasharraySize }),
                React.createElement("rect", { id: "active-bg", fill: "#E0E0E0", x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/5/Disabled", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-23", strokeWidth: "1", transform: "translate(0.000000, -0.645833)" },
                        React.createElement("polygon", { id: "Fill-1", fill: "#81ACF1", points: "4.77310213 18.2614734 4.77310213 31.3628209 12.9437404 26.9348777 21.1138255 31.3628209 21.1138255 18.2614734" }),
                        React.createElement("polygon", { id: "Fill-2", fill: "#829BC2", points: "8.8584766 18.2616383 8.8584766 29.1490142 12.9437957 26.9344929 17.0291149 29.1490142 17.0291149 18.2616383" }),
                        React.createElement("polygon", { id: "Fill-3", fill: "#F7D131", points: "12.9436851 -0.000164893617 10.3176851 1.75925 7.18330213 1.3063422 5.58568511 4.02378901 2.5636 4.96588121 2.31134468 8.10380674 0.000110638298 10.2567677 1.14189787 13.1913245 0.000110638298 16.1264309 2.31134468 18.2788422 2.5636 21.4156684 5.58568511 22.3594096 7.18330213 25.0763067 10.3176851 24.6233989 12.9436851 26.3828138 15.5702383 24.6233989 18.7046213 25.0763067 20.3016851 22.3594096 23.3237702 21.4156684 23.5765787 18.2788422 25.8867064 16.1269805 25.8867064 16.1253316 24.7454723 13.1913245 25.8867064 10.257867 25.8867064 10.2562181 23.5765787 8.10380674 23.3237702 4.96588121 20.3016851 4.02378901 18.7046213 1.3063422 15.5702383 1.75925" }),
                        React.createElement("g", { id: "Group-22", transform: "translate(2.212766, 2.781041)" },
                            React.createElement("path", { d: "M10.7309191,19.6147004 C5.61445106,19.6147004 1.46717447,15.4940089 1.46717447,10.4103387 C1.46717447,5.32721809 5.61445106,1.20597695 10.7309191,1.20597695 C15.846834,1.20597695 19.9946638,5.32721809 19.9946638,10.4103387 C19.9946638,15.4940089 15.846834,19.6147004 10.7309191,19.6147004 Z", id: "Fill-4", fill: "#F8E831" }),
                            React.createElement("path", { d: "M10.7305872,18.5750461 C6.33769362,18.5750461 2.7635234,15.0243369 2.7635234,10.6590532 C2.7635234,6.29431915 6.33769362,2.74251064 10.7305872,2.74251064 C15.124034,2.74251064 18.6982043,6.29431915 18.6982043,10.6590532 C18.6982043,15.0243369 15.124034,18.5750461 10.7305872,18.5750461 M10.7305872,1.04575532 C5.39560851,1.04575532 1.05582128,5.3588227 1.05582128,10.6590532 C1.05582128,15.9598333 5.39560851,20.2712518 10.7305872,20.2712518 C16.065566,20.2712518 20.4053532,15.9598333 20.4053532,10.6590532 C20.4053532,5.3588227 16.065566,1.04575532 10.7305872,1.04575532", id: "Fill-6", fill: "#F7D131" }),
                            React.createElement("path", { d: "M10.7305872,18.9835426 C5.97314043,18.9835426 2.10245957,15.1382234 2.10245957,10.4101738 C2.10245957,5.6843227 5.97314043,1.83790426 10.7305872,1.83790426 C15.4885872,1.83790426 19.3592681,5.6843227 19.3592681,10.4101738 C19.3592681,15.1382234 15.4885872,18.9835426 10.7305872,18.9835426 M10.7305872,0.000439716312 C4.95360851,0.000439716312 0.253693617,4.6707766 0.253693617,10.4101738 C0.253693617,16.1512199 4.95360851,20.8204574 10.7305872,20.8204574 C16.5081191,20.8204574 21.208034,16.1512199 21.208034,10.4101738 C21.208034,4.6707766 16.5081191,0.000439716312 10.7305872,0.000439716312", id: "Fill-8", fill: "#F8E831" }),
                            React.createElement("path", { d: "M3.4305617,12.8043191 C3.4305617,12.5229007 3.45324255,12.2755603 3.49860426,12.0611986 C3.54396596,11.8479362 3.61754043,11.6555603 3.71932766,11.4840709 C3.82111489,11.3120319 3.95277447,11.1592305 4.11430638,11.0240177 C4.27528511,10.8893546 4.47498723,10.7568901 4.71285957,10.6277234 C5.01877447,10.4644787 5.26051915,10.3336631 5.43864681,10.2352766 C5.61732766,10.1368901 5.7512,10.0566418 5.8419234,9.99508156 C5.93264681,9.93297163 5.99017872,9.88130496 6.01617872,9.83843262 C6.04162553,9.79610993 6.05434894,9.74993972 6.05434894,9.69992199 C6.05434894,9.55921277 6.00898723,9.45478014 5.91826383,9.38717376 C5.82754043,9.31956738 5.70307234,9.28658865 5.54485957,9.28658865 C5.35788085,9.28658865 5.18085957,9.3344078 5.01434894,9.42949645 C4.84728511,9.52513475 4.67358298,9.67958511 4.49213617,9.89394681 L3.47315745,8.70396454 C3.5804766,8.58579078 3.71932766,8.47806028 3.88915745,8.37967376 C4.05898723,8.28128723 4.24707234,8.19664184 4.45396596,8.12628723 C4.66030638,8.05593262 4.8799234,8.00151773 5.11226383,7.96139362 C5.34349787,7.92236879 5.5819234,7.90258156 5.82532766,7.90258156 C6.47588085,7.90258156 6.96988085,8.03614539 7.30677447,8.30327305 C7.64311489,8.57095035 7.8118383,8.94306028 7.8118383,9.42180142 C7.8118383,9.59548936 7.78915745,9.74993972 7.74379574,9.88460284 C7.69843404,10.0203652 7.63758298,10.1423865 7.56124255,10.2523156 C7.48490213,10.361695 7.39307234,10.4617305 7.2852,10.5513227 C7.17788085,10.6414645 7.0644766,10.7261099 6.94554043,10.8047092 C6.8044766,10.9003475 6.65566809,10.9915887 6.50022128,11.0789823 C6.34422128,11.1663759 6.18988085,11.2625638 6.0372,11.3702943 L7.90532766,11.3702943 L7.90532766,12.8043191 L3.4305617,12.8043191 Z", id: "Fill-10", fill: "#D8B627" }),
                            React.createElement("path", { d: "M10.800566,10.0539486 C11.0948638,10.0539486 11.3637149,10.1017677 11.6071191,10.197406 C11.8505234,10.2930443 12.0612894,10.4266082 12.239417,10.5975479 C12.4175447,10.7701365 12.5563957,10.9751543 12.655417,11.2137004 C12.7544383,11.4527961 12.8042255,11.7160762 12.8042255,12.0029911 C12.8042255,12.306945 12.7478,12.584516 12.6343957,12.8373528 C12.5209915,13.0907394 12.3611191,13.3072996 12.1547787,13.4870337 C11.9478851,13.6673174 11.7017149,13.8080266 11.4157149,13.9091613 C11.1302681,14.0102961 10.8143957,14.0614131 10.4692043,14.0614131 C10.0277574,14.0614131 9.63001277,13.979516 9.2765234,13.8162713 C8.92248085,13.6530266 8.64920426,13.419977 8.45724681,13.116023 L9.34014043,12.1035762 C9.5044383,12.2783635 9.6864383,12.4146755 9.8878,12.5130621 C10.0886085,12.6108989 10.2849915,12.660367 10.4780553,12.660367 C10.6871617,12.660367 10.8514596,12.6048528 10.9703957,12.4921755 C11.0887787,12.3794982 11.1485234,12.2272465 11.1485234,12.0359699 C11.1485234,11.8507394 11.0948638,11.7017855 10.9869915,11.5891082 C10.8796723,11.4764309 10.7380553,11.420367 10.5626936,11.420367 C10.4437574,11.420367 10.3347787,11.4472996 10.2357574,11.5006152 C10.1367362,11.5539309 10.0615021,11.6259344 10.0106085,11.7160762 L8.6270766,11.7160762 L9.34014043,7.98673227 L12.3627787,7.98673227 L12.3627787,9.44714007 L10.5035021,9.44714007 L10.4100128,10.0957216 C10.4321404,10.0902252 10.4636723,10.0852784 10.5035021,10.0792323 C10.5317149,10.0737358 10.5709915,10.0676897 10.6224383,10.0627429 C10.6733319,10.0566968 10.7325234,10.0539486 10.800566,10.0539486", id: "Fill-12", fill: "#D8B627" }),
                            React.createElement("polygon", { id: "Fill-14", fill: "#D8B627", points: "16.3786723 12.8043191 15.3685447 11.1845142 15.0454809 11.5384858 15.0454809 12.8043191 13.4329277 12.8043191 13.4329277 6.64554255 15.0454809 6.64554255 15.0454809 10.0121206 16.2342894 8.36703191 17.9325872 8.36703191 16.3958213 10.3067305 18.0852681 12.8043191" }),
                            React.createElement("path", { d: "M3.76302979,12.1773387 C3.76302979,11.902516 3.78515745,11.6612216 3.82941277,11.4518067 C3.87366809,11.2429415 3.94558298,11.0549628 4.04515745,10.8867713 C4.14473191,10.7196791 4.27362553,10.5696259 4.43128511,10.437711 C4.58894468,10.3057961 4.78366809,10.1766294 5.01600851,10.050211 C5.31473191,9.89081383 5.55094468,9.76274645 5.72575319,9.66710816 C5.90000851,9.57092021 6.03111489,9.49232092 6.11962553,9.43185993 C6.20813617,9.37194858 6.2645617,9.32083156 6.29000851,9.27960816 C6.31490213,9.23783511 6.32707234,9.19276418 6.32707234,9.1432961 C6.32707234,9.00588475 6.28281702,8.90420035 6.19430638,8.83824291 C6.10579574,8.77228546 5.98409362,8.73930674 5.8292,8.73930674 C5.64664681,8.73930674 5.47349787,8.7860266 5.31085957,8.87946631 C5.14711489,8.97290603 4.97728511,9.12405851 4.80026383,9.33292376 L3.80451915,8.17042376 C3.90962553,8.05499823 4.04515745,7.94946631 4.21111489,7.85327837 C4.37707234,7.75709043 4.56073191,7.67464362 4.7632,7.60593794 C4.9645617,7.53723227 5.1792,7.48336702 5.40600851,7.44489184 C5.63281702,7.40641667 5.86515745,7.38717908 6.10302979,7.38717908 C6.7392,7.38717908 7.22158298,7.51744504 7.55128511,7.7785266 C7.87988085,8.0401578 8.04473191,8.40402305 8.04473191,8.87122163 C8.04473191,9.0416117 8.02260426,9.19276418 7.97834894,9.32467908 C7.93409362,9.45659397 7.87490213,9.57641667 7.80022128,9.68359752 C7.72554043,9.79022872 7.63537021,9.8880656 7.53026383,9.97600887 C7.42515745,10.0639521 7.31451915,10.1463989 7.19834894,10.2233493 C7.06005106,10.316789 6.91511489,10.4058316 6.76298723,10.4915762 C6.61030638,10.5767713 6.4598383,10.6713103 6.3104766,10.7757429 L8.13600851,10.7757429 L8.13600851,12.1773387 L3.76302979,12.1773387 Z", id: "Fill-16", fill: "#F9F1B7" }),
                            React.createElement("path", { d: "M10.965583,9.48979255 C11.2532426,9.48979255 11.5160085,9.53651241 11.7538809,9.62995213 C11.9917532,9.72339184 12.1980936,9.8536578 12.3717957,10.0212996 C12.5460511,10.1889415 12.6821362,10.3901117 12.7783915,10.623711 C12.8752,10.8567606 12.9238809,11.1139947 12.9238809,11.3943138 C12.9238809,11.6911223 12.8685617,11.9631968 12.7579234,12.2099876 C12.6472851,12.457328 12.4907319,12.6689415 12.2893702,12.844828 C12.0869021,13.0212642 11.8462638,13.1586755 11.5669021,13.2570621 C11.2880936,13.3559982 10.9794128,13.406016 10.641966,13.406016 C10.2104766,13.406016 9.82158298,13.3257677 9.47639149,13.1663706 C9.13064681,13.0069734 8.86345532,12.7788706 8.67537021,12.4820621 L9.53834894,11.49325 C9.69877447,11.6636401 9.87690213,11.7966543 10.0738383,11.8928422 C10.2696681,11.9890301 10.4621787,12.0373989 10.6502638,12.0373989 C10.8549447,12.0373989 11.0153702,11.9824344 11.1315404,11.8725053 C11.2477106,11.7625762 11.3057957,11.6136223 11.3057957,11.4267429 C11.3057957,11.2453599 11.2532426,11.1002535 11.1481362,10.9897748 C11.0430298,10.8798457 10.9047319,10.8254309 10.7332426,10.8254309 C10.6170723,10.8254309 10.5103064,10.8518138 10.4134979,10.9034805 C10.3172426,10.9556968 10.2436681,11.0260514 10.1938809,11.1139947 L8.84132766,11.1139947 L9.53834894,7.4692961 L12.4923915,7.4692961 L12.4923915,8.89617553 L10.6751574,8.89617553 L10.5838809,9.53046631 C10.6060085,9.52496986 10.636434,9.52002305 10.6751574,9.5145266 C10.702817,9.50903014 10.7415404,9.50298404 10.7913277,9.49803723 C10.8411149,9.49254078 10.8992,9.48979255 10.965583,9.48979255", id: "Fill-18", fill: "#F9F1B7" }),
                            React.createElement("polygon", { id: "Fill-20", fill: "#F9F1B7", points: "16.4172851 12.1773387 15.4298383 10.5943599 15.1145191 10.9406365 15.1145191 12.1773387 13.5379234 12.1773387 13.5379234 6.15872163 15.1145191 6.15872163 15.1145191 9.44834929 16.2762213 7.84063652 17.9357957 7.84063652 16.4338809 9.73691312 18.0851574 12.1773387" }))),
                    React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", style: { mixBlendMode: 'color' }, x: "0", y: "-0.645833333", width: "26", height: "31.6458333" })),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: dark ? colors.space.tint500 : colors.space.tint200 })))));
});
