export var marker = {
    accountingOff: 'icon-marker_accounting_off',
    airportOff: 'icon-marker_airport_off',
    amusementParkOff: 'icon-marker_amusement_park_off',
    aquariumOff: 'icon-marker_aquarium_off',
    artGalleryOff: 'icon-marker_art_gallery_off',
    atmOff: 'icon-marker_atm_off',
    bakeryOff: 'icon-marker_bakery_off',
    bankOff: 'icon-marker_bank_off',
    barOff: 'icon-marker_bar_off',
    beautySalonOff: 'icon-marker_beauty_salon_off',
    bicycleStoreOff: 'icon-marker_bicycle_store_off',
    bookStoreOff: 'icon-marker_book_store_off',
    bowlingAlleyOff: 'icon-marker_bowling_alley_off',
    busStationOff: 'icon-marker_bus_station_off',
    cafeOff: 'icon-marker_cafe_off',
    campgroundOff: 'icon-marker_campground_off',
    carDealerOff: 'icon-marker_car_dealer_off',
    carRentalOff: 'icon-marker_car_rental_off',
    carRepairOff: 'icon-marker_car_repair_off',
    carWashOff: 'icon-marker_car_wash_off',
    casinoOff: 'icon-marker_casino_off',
    cemeteryOff: 'icon-marker_cemetery_off',
    churchOff: 'icon-marker_church_off',
    cityHallOff: 'icon-marker_city_hall_off',
    clothingStoreOff: 'icon-marker_clothing_store_off',
    convenienceStoreOff: 'icon-marker_convenience_store_off',
    courthouseOff: 'icon-marker_courthouse_off',
    dentistOff: 'icon-marker_dentist_off',
    departmentStoreOff: 'icon-marker_department_store_off',
    doctorOff: 'icon-marker_doctor_off',
    electricianOff: 'icon-marker_electrician_off',
    electronicsStoreOff: 'icon-marker_electronics_store_off',
    embassyOff: 'icon-marker_embassy_off',
    establishmentOff: 'icon-marker_establishment_off',
    fireStationOff: 'icon-marker_fire_station_off',
    floristOff: 'icon-marker_florist_off',
    funeralHomeOff: 'icon-marker_funeral_home_off',
    furnitureStoreOff: 'icon-marker_furniture_store_off',
    gasStationOff: 'icon-marker_gas_station_off',
    groceryOrSupermarketOff: 'icon-marker_grocery_or_supermarket_off',
    gymOff: 'icon-marker_gym_off',
    hairCareOff: 'icon-marker_hair_care_off',
    hardwareStoreOff: 'icon-marker_hardware_store_off',
    hinduTempleOff: 'icon-marker_hindu_temple_off',
    homeGoodsStoreOff: 'icon-marker_home_goods_store_off',
    hospitalOff: 'icon-marker_hospital_off',
    insuranceAgencyOff: 'icon-marker_insurance_agency_off',
    jewelryStoreOff: 'icon-marker_jewelry_store_off',
    laundryOff: 'icon-marker_laundry_off',
    lawyerOff: 'icon-marker_lawyer_off',
    libraryOff: 'icon-marker_library_off',
    liquorStoreOff: 'icon-marker_liquor_store_off',
    localGovernmentOfficeOff: 'icon-marker_local_government_office_off',
    locksmithOff: 'icon-marker_locksmith_off',
    lodgingOff: 'icon-marker_lodging_off',
    mealDeliveryOff: 'icon-marker_meal_delivery_off',
    mealTakeawayOff: 'icon-marker_meal_takeaway_off',
    mosqueOff: 'icon-marker_mosque_off',
    movieRentalOff: 'icon-marker_movie_rental_off',
    movieTheaterOff: 'icon-marker_movie_theater_off',
    museumOff: 'icon-marker_museum_off',
    nightClubOff: 'icon-marker_night_club_off',
    parkOff: 'icon-marker_park_off',
    parkingOff: 'icon-marker_parking_off',
    petStoreOff: 'icon-marker_pet_store_off',
    pharmacyOff: 'icon-marker_pharmacy_off',
    physiotherapistOff: 'icon-marker_physiotherapist_off',
    policeOff: 'icon-marker_police_off',
    postOfficeOff: 'icon-marker_post_office_off',
    realEstateAgencyOff: 'icon-marker_real_estate_agency_off',
    restaurantOff: 'icon-marker_restaurant_off',
    rvParkOff: 'icon-marker_rv_park_off',
    schoolOff: 'icon-marker_school_off',
    shoeStoreOff: 'icon-marker_shoe_store_off',
    shoppingMallOff: 'icon-marker_shopping_mall_off',
    spaOff: 'icon-marker_spa_off',
    stadiumOff: 'icon-marker_stadium_off',
    storageOff: 'icon-marker_storage_off',
    storeOff: 'icon-marker_store_off',
    subwayStationOff: 'icon-marker_subway_station_off',
    synagogueOff: 'icon-marker_synagogue_off',
    taxiStandOff: 'icon-marker_taxi_stand_off',
    trainStationOff: 'icon-marker_train_station_off',
    transitStationOff: 'icon-marker_transit_station_off',
    travelAgencyOff: 'icon-marker_travel_agency_off',
    universityOff: 'icon-marker_university_off',
    veterinaryCareOff: 'icon-marker_veterinary_care_off',
    zooOff: 'icon-marker_zoo_off',
    accounting: 'icon-marker_accounting',
    airport: 'icon-marker_airport',
    amusementPark: 'icon-marker_amusement_park',
    aquarium: 'icon-marker_aquarium',
    artGallery: 'icon-marker_art_gallery',
    atm: 'icon-marker_atm',
    bakery: 'icon-marker_bakery',
    bank: 'icon-marker_bank',
    bar: 'icon-marker_bar',
    beautySalon: 'icon-marker_beauty_salon',
    bicycleStore: 'icon-marker_bicycle_store',
    bookStore: 'icon-marker_book_store',
    bowlingAlley: 'icon-marker_bowling_alley',
    busStation: 'icon-marker_bus_station',
    cafe: 'icon-marker_cafe',
    campground: 'icon-marker_campground',
    carDealer: 'icon-marker_car_dealer',
    carRental: 'icon-marker_car_rental',
    carRepair: 'icon-marker_car_repair',
    carWash: 'icon-marker_car_wash',
    casino: 'icon-marker_casino',
    cemetery: 'icon-marker_cemetery',
    church: 'icon-marker_church',
    cityHall: 'icon-marker_city_hall',
    clothingStore: 'icon-marker_clothing_store',
    convenienceStore: 'icon-marker_convenience_store',
    courthouse: 'icon-marker_courthouse',
    dentist: 'icon-marker_dentist',
    departmentStore: 'icon-marker_department_store',
    doctor: 'icon-marker_doctor',
    electrician: 'icon-marker_electrician',
    electronicsStore: 'icon-marker_electronics_store',
    embassy: 'icon-marker_embassy',
    establishment: 'icon-marker_establishment',
    fireStation: 'icon-marker_fire_station',
    florist: 'icon-marker_florist',
    funeralHome: 'icon-marker_funeral_home',
    furnitureStore: 'icon-marker_furniture_store',
    gasStation: 'icon-marker_gas_station',
    groceryOrSupermarket: 'icon-marker_grocery_or_supermarket',
    gym: 'icon-marker_gym',
    hairCare: 'icon-marker_hair_care',
    hardwareStore: 'icon-marker_hardware_store',
    hinduTemple: 'icon-marker_hindu_temple',
    homeGoodsStore: 'icon-marker_home_goods_store',
    hospital: 'icon-marker_hospital',
    insuranceAgency: 'icon-marker_insurance_agency',
    jewelryStore: 'icon-marker_jewelry_store',
    laundry: 'icon-marker_laundry',
    lawyer: 'icon-marker_lawyer',
    library: 'icon-marker_library',
    liquorStore: 'icon-marker_liquor_store',
    localGovernmentOffice: 'icon-marker_local_government_office',
    locksmith: 'icon-marker_locksmith',
    lodging: 'icon-marker_lodging',
    mealDelivery: 'icon-marker_meal_delivery',
    mealTakeaway: 'icon-marker_meal_takeaway',
    mosque: 'icon-marker_mosque',
    movieRental: 'icon-marker_movie_rental',
    movieTheater: 'icon-marker_movie_theater',
    museum: 'icon-marker_museum',
    nightClub: 'icon-marker_night_club',
    park: 'icon-marker_park',
    parking: 'icon-marker_parking',
    petStore: 'icon-marker_pet_store',
    pharmacy: 'icon-marker_pharmacy',
    physiotherapist: 'icon-marker_physiotherapist',
    police: 'icon-marker_police',
    postOffice: 'icon-marker_post_office',
    realEstateAgency: 'icon-marker_real_estate_agency',
    restaurant: 'icon-marker_restaurant',
    rvPark: 'icon-marker_rv_park',
    school: 'icon-marker_school',
    shoeStore: 'icon-marker_shoe_store',
    shoppingMall: 'icon-marker_shopping_mall',
    spa: 'icon-marker_spa',
    stadium: 'icon-marker_stadium',
    storage: 'icon-marker_storage',
    store: 'icon-marker_store',
    subwayStation: 'icon-marker_subway_station',
    synagogue: 'icon-marker_synagogue',
    taxiStand: 'icon-marker_taxi_stand',
    trainStation: 'icon-marker_train_station',
    transitStation: 'icon-marker_transit_station',
    travelAgency: 'icon-marker_travel_agency',
    university: 'icon-marker_university',
    veterinaryCare: 'icon-marker_veterinary_care',
    zoo: 'icon-marker_zoo',
};
export var square = {
    accountingOff: 'icon-square_accounting_off',
    airportOff: 'icon-square_airport_off',
    amusementParkOff: 'icon-square_amusement_park_off',
    aquariumOff: 'icon-square_aquarium_off',
    artGalleryOff: 'icon-square_art_gallery_off',
    atmOff: 'icon-square_atm_off',
    bakeryOff: 'icon-square_bakery_off',
    bankOff: 'icon-square_bank_off',
    barOff: 'icon-square_bar_off',
    beautySalonOff: 'icon-square_beauty_salon_off',
    bicycleStoreOff: 'icon-square_bicycle_store_off',
    bookStoreOff: 'icon-square_book_store_off',
    bowlingAlleyOff: 'icon-square_bowling_alley_off',
    busStationOff: 'icon-square_bus_station_off',
    cafeOff: 'icon-square_cafe_off',
    campgroundOff: 'icon-square_campground_off',
    carDealerOff: 'icon-square_car_dealer_off',
    carRentalOff: 'icon-square_car_rental_off',
    carRepairOff: 'icon-square_car_repair_off',
    carWashOff: 'icon-square_car_wash_off',
    casinoOff: 'icon-square_casino_off',
    cemeteryOff: 'icon-square_cemetery_off',
    churchOff: 'icon-square_church_off',
    cityHallOff: 'icon-square_city_hall_off',
    clothingStoreOff: 'icon-square_clothing_store_off',
    convenienceStoreOff: 'icon-square_convenience_store_off',
    courthouseOff: 'icon-square_courthouse_off',
    dentistOff: 'icon-square_dentist_off',
    departmentStoreOff: 'icon-square_department_store_off',
    doctorOff: 'icon-square_doctor_off',
    electricianOff: 'icon-square_electrician_off',
    electronicsStoreOff: 'icon-square_electronics_store_off',
    embassyOff: 'icon-square_embassy_off',
    establishmentOff: 'icon-square_establishment_off',
    fireStationOff: 'icon-square_fire_station_off',
    floristOff: 'icon-square_florist_off',
    funeralHomeOff: 'icon-square_funeral_home_off',
    furnitureStoreOff: 'icon-square_furniture_store_off',
    gasStationOff: 'icon-square_gas_station_off',
    groceryOrSupermarketOff: 'icon-square_grocery_or_supermarket_off',
    gymOff: 'icon-square_gym_off',
    hairCareOff: 'icon-square_hair_care_off',
    hardwareStoreOff: 'icon-square_hardware_store_off',
    hinduTempleOff: 'icon-square_hindu_temple_off',
    homeGoodsStoreOff: 'icon-square_home_goods_store_off',
    hospitalOff: 'icon-square_hospital_off',
    insuranceAgencyOff: 'icon-square_insurance_agency_off',
    jewelryStoreOff: 'icon-square_jewelry_store_off',
    laundryOff: 'icon-square_laundry_off',
    lawyerOff: 'icon-square_lawyer_off',
    libraryOff: 'icon-square_library_off',
    liquorStoreOff: 'icon-square_liquor_store_off',
    localGovernmentOfficeOff: 'icon-square_local_government_office_off',
    locksmithOff: 'icon-square_locksmith_off',
    lodgingOff: 'icon-square_lodging_off',
    mealDeliveryOff: 'icon-square_meal_delivery_off',
    mealTakeawayOff: 'icon-square_meal_takeaway_off',
    mosqueOff: 'icon-square_mosque_off',
    movieRentalOff: 'icon-square_movie_rental_off',
    movieTheaterOff: 'icon-square_movie_theater_off',
    museumOff: 'icon-square_museum_off',
    nightClubOff: 'icon-square_night_club_off',
    parkOff: 'icon-square_park_off',
    parkingOff: 'icon-square_parking_off',
    petStoreOff: 'icon-square_pet_store_off',
    pharmacyOff: 'icon-square_pharmacy_off',
    physiotherapistOff: 'icon-square_physiotherapist_off',
    policeOff: 'icon-square_police_off',
    postOfficeOff: 'icon-square_post_office_off',
    realEstateAgencyOff: 'icon-square_real_estate_agency_off',
    restaurantOff: 'icon-square_restaurant_off',
    rvParkOff: 'icon-square_rv_park_off',
    schoolOff: 'icon-square_school_off',
    shoeStoreOff: 'icon-square_shoe_store_off',
    shoppingMallOff: 'icon-square_shopping_mall_off',
    spaOff: 'icon-square_spa_off',
    stadiumOff: 'icon-square_stadium_off',
    storageOff: 'icon-square_storage_off',
    storeOff: 'icon-square_store_off',
    subwayStationOff: 'icon-square_subway_station_off',
    synagogueOff: 'icon-square_synagogue_off',
    taxiStandOff: 'icon-square_taxi_stand_off',
    trainStationOff: 'icon-square_train_station_off',
    transitStationOff: 'icon-square_transit_station_off',
    travelAgencyOff: 'icon-square_travel_agency_off',
    universityOff: 'icon-square_university_off',
    veterinaryCareOff: 'icon-square_veterinary_care_off',
    zooOff: 'icon-square_zoo_off',
    accounting: 'icon-square_accounting',
    airport: 'icon-square_airport',
    amusementPark: 'icon-square_amusement_park',
    aquarium: 'icon-square_aquarium',
    artGallery: 'icon-square_art_gallery',
    atm: 'icon-square_atm',
    bakery: 'icon-square_bakery',
    bank: 'icon-square_bank',
    bar: 'icon-square_bar',
    beautySalon: 'icon-square_beauty_salon',
    bicycleStore: 'icon-square_bicycle_store',
    bookStore: 'icon-square_book_store',
    bowlingAlley: 'icon-square_bowling_alley',
    busStation: 'icon-square_bus_station',
    cafe: 'icon-square_cafe',
    campground: 'icon-square_campground',
    carDealer: 'icon-square_car_dealer',
    carRental: 'icon-square_car_rental',
    carRepair: 'icon-square_car_repair',
    carWash: 'icon-square_car_wash',
    casino: 'icon-square_casino',
    cemetery: 'icon-square_cemetery',
    church: 'icon-square_church',
    cityHall: 'icon-square_city_hall',
    clothingStore: 'icon-square_clothing_store',
    convenienceStore: 'icon-square_convenience_store',
    courthouse: 'icon-square_courthouse',
    dentist: 'icon-square_dentist',
    departmentStore: 'icon-square_department_store',
    doctor: 'icon-square_doctor',
    electrician: 'icon-square_electrician',
    electronicsStore: 'icon-square_electronics_store',
    embassy: 'icon-square_embassy',
    establishment: 'icon-square_establishment',
    fireStation: 'icon-square_fire_station',
    florist: 'icon-square_florist',
    funeralHome: 'icon-square_funeral_home',
    furnitureStore: 'icon-square_furniture_store',
    gasStation: 'icon-square_gas_station',
    groceryOrSupermarket: 'icon-square_grocery_or_supermarket',
    gym: 'icon-square_gym',
    hairCare: 'icon-square_hair_care',
    hardwareStore: 'icon-square_hardware_store',
    hinduTemple: 'icon-square_hindu_temple',
    homeGoodsStore: 'icon-square_home_goods_store',
    hospital: 'icon-square_hospital',
    insuranceAgency: 'icon-square_insurance_agency',
    jewelryStore: 'icon-square_jewelry_store',
    laundry: 'icon-square_laundry',
    lawyer: 'icon-square_lawyer',
    library: 'icon-square_library',
    liquorStore: 'icon-square_liquor_store',
    localGovernmentOffice: 'icon-square_local_government_office',
    locksmith: 'icon-square_locksmith',
    lodging: 'icon-square_lodging',
    mealDelivery: 'icon-square_meal_delivery',
    mealTakeaway: 'icon-square_meal_takeaway',
    mosque: 'icon-square_mosque',
    movieRental: 'icon-square_movie_rental',
    movieTheater: 'icon-square_movie_theater',
    museum: 'icon-square_museum',
    nightClub: 'icon-square_night_club',
    park: 'icon-square_park',
    parking: 'icon-square_parking',
    petStore: 'icon-square_pet_store',
    pharmacy: 'icon-square_pharmacy',
    physiotherapist: 'icon-square_physiotherapist',
    police: 'icon-square_police',
    postOffice: 'icon-square_post_office',
    realEstateAgency: 'icon-square_real_estate_agency',
    restaurant: 'icon-square_restaurant',
    rvPark: 'icon-square_rv_park',
    school: 'icon-square_school',
    shoeStore: 'icon-square_shoe_store',
    shoppingMall: 'icon-square_shopping_mall',
    spa: 'icon-square_spa',
    stadium: 'icon-square_stadium',
    storage: 'icon-square_storage',
    store: 'icon-square_store',
    subwayStation: 'icon-square_subway_station',
    synagogue: 'icon-square_synagogue',
    taxiStand: 'icon-square_taxi_stand',
    trainStation: 'icon-square_train_station',
    transitStation: 'icon-square_transit_station',
    travelAgency: 'icon-square_travel_agency',
    university: 'icon-square_university',
    veterinaryCare: 'icon-square_veterinary_care',
    zoo: 'icon-square_zoo',
};
export var round = {
    accountingOff: 'icon-round_accounting_off',
    airportOff: 'icon-round_airport_off',
    amusementParkOff: 'icon-round_amusement_park_off',
    aquariumOff: 'icon-round_aquarium_off',
    artGalleryOff: 'icon-round_art_gallery_off',
    atmOff: 'icon-round_atm_off',
    bakeryOff: 'icon-round_bakery_off',
    bankOff: 'icon-round_bank_off',
    barOff: 'icon-round_bar_off',
    beautySalonOff: 'icon-round_beauty_salon_off',
    bicycleStoreOff: 'icon-round_bicycle_store_off',
    bookStoreOff: 'icon-round_book_store_off',
    bowlingAlleyOff: 'icon-round_bowling_alley_off',
    busStationOff: 'icon-round_bus_station_off',
    cafeOff: 'icon-round_cafe_off',
    campgroundOff: 'icon-round_campground_off',
    carDealerOff: 'icon-round_car_dealer_off',
    carRentalOff: 'icon-round_car_rental_off',
    carRepairOff: 'icon-round_car_repair_off',
    carWashOff: 'icon-round_car_wash_off',
    casinoOff: 'icon-round_casino_off',
    cemeteryOff: 'icon-round_cemetery_off',
    churchOff: 'icon-round_church_off',
    cityHallOff: 'icon-round_city_hall_off',
    clothingStoreOff: 'icon-round_clothing_store_off',
    convenienceStoreOff: 'icon-round_convenience_store_off',
    courthouseOff: 'icon-round_courthouse_off',
    dentistOff: 'icon-round_dentist_off',
    departmentStoreOff: 'icon-round_department_store_off',
    doctorOff: 'icon-round_doctor_off',
    electricianOff: 'icon-round_electrician_off',
    electronicsStoreOff: 'icon-round_electronics_store_off',
    embassyOff: 'icon-round_embassy_off',
    establishmentOff: 'icon-round_establishment_off',
    fireStationOff: 'icon-round_fire_station_off',
    floristOff: 'icon-round_florist_off',
    funeralHomeOff: 'icon-round_funeral_home_off',
    furnitureStoreOff: 'icon-round_furniture_store_off',
    gasStationOff: 'icon-round_gas_station_off',
    groceryOrSupermarketOff: 'icon-round_grocery_or_supermarket_off',
    gymOff: 'icon-round_gym_off',
    hairCareOff: 'icon-round_hair_care_off',
    hardwareStoreOff: 'icon-round_hardware_store_off',
    hinduTempleOff: 'icon-round_hindu_temple_off',
    homeGoodsStoreOff: 'icon-round_home_goods_store_off',
    hospitalOff: 'icon-round_hospital_off',
    insuranceAgencyOff: 'icon-round_insurance_agency_off',
    jewelryStoreOff: 'icon-round_jewelry_store_off',
    laundryOff: 'icon-round_laundry_off',
    lawyerOff: 'icon-round_lawyer_off',
    libraryOff: 'icon-round_library_off',
    liquorStoreOff: 'icon-round_liquor_store_off',
    localGovernmentOfficeOff: 'icon-round_local_government_office_off',
    locksmithOff: 'icon-round_locksmith_off',
    lodgingOff: 'icon-round_lodging_off',
    mealDeliveryOff: 'icon-round_meal_delivery_off',
    mealTakeawayOff: 'icon-round_meal_takeaway_off',
    mosqueOff: 'icon-round_mosque_off',
    movieRentalOff: 'icon-round_movie_rental_off',
    movieTheaterOff: 'icon-round_movie_theater_off',
    museumOff: 'icon-round_museum_off',
    nightClubOff: 'icon-round_night_club_off',
    parkOff: 'icon-round_park_off',
    parkingOff: 'icon-round_parking_off',
    petStoreOff: 'icon-round_pet_store_off',
    pharmacyOff: 'icon-round_pharmacy_off',
    physiotherapistOff: 'icon-round_physiotherapist_off',
    policeOff: 'icon-round_police_off',
    postOfficeOff: 'icon-round_post_office_off',
    realEstateAgencyOff: 'icon-round_real_estate_agency_off',
    restaurantOff: 'icon-round_restaurant_off',
    rvParkOff: 'icon-round_rv_park_off',
    schoolOff: 'icon-round_school_off',
    shoeStoreOff: 'icon-round_shoe_store_off',
    shoppingMallOff: 'icon-round_shopping_mall_off',
    spaOff: 'icon-round_spa_off',
    stadiumOff: 'icon-round_stadium_off',
    storageOff: 'icon-round_storage_off',
    storeOff: 'icon-round_store_off',
    subwayStationOff: 'icon-round_subway_station_off',
    synagogueOff: 'icon-round_synagogue_off',
    taxiStandOff: 'icon-round_taxi_stand_off',
    trainStationOff: 'icon-round_train_station_off',
    transitStationOff: 'icon-round_transit_station_off',
    travelAgencyOff: 'icon-round_travel_agency_off',
    universityOff: 'icon-round_university_off',
    veterinaryCareOff: 'icon-round_veterinary_care_off',
    zooOff: 'icon-round_zoo_off',
    accounting: 'icon-round_accounting',
    airport: 'icon-round_airport',
    amusementPark: 'icon-round_amusement_park',
    aquarium: 'icon-round_aquarium',
    artGallery: 'icon-round_art_gallery',
    atm: 'icon-round_atm',
    bakery: 'icon-round_bakery',
    bank: 'icon-round_bank',
    bar: 'icon-round_bar',
    beautySalon: 'icon-round_beauty_salon',
    bicycleStore: 'icon-round_bicycle_store',
    bookStore: 'icon-round_book_store',
    bowlingAlley: 'icon-round_bowling_alley',
    busStation: 'icon-round_bus_station',
    cafe: 'icon-round_cafe',
    campground: 'icon-round_campground',
    carDealer: 'icon-round_car_dealer',
    carRental: 'icon-round_car_rental',
    carRepair: 'icon-round_car_repair',
    carWash: 'icon-round_car_wash',
    casino: 'icon-round_casino',
    cemetery: 'icon-round_cemetery',
    church: 'icon-round_church',
    cityHall: 'icon-round_city_hall',
    clothingStore: 'icon-round_clothing_store',
    convenienceStore: 'icon-round_convenience_store',
    courthouse: 'icon-round_courthouse',
    dentist: 'icon-round_dentist',
    departmentStore: 'icon-round_department_store',
    doctor: 'icon-round_doctor',
    electrician: 'icon-round_electrician',
    electronicsStore: 'icon-round_electronics_store',
    embassy: 'icon-round_embassy',
    establishment: 'icon-round_establishment',
    fireStation: 'icon-round_fire_station',
    florist: 'icon-round_florist',
    funeralHome: 'icon-round_funeral_home',
    furnitureStore: 'icon-round_furniture_store',
    gasStation: 'icon-round_gas_station',
    groceryOrSupermarket: 'icon-round_grocery_or_supermarket',
    gym: 'icon-round_gym',
    hairCare: 'icon-round_hair_care',
    hardwareStore: 'icon-round_hardware_store',
    hinduTemple: 'icon-round_hindu_temple',
    homeGoodsStore: 'icon-round_home_goods_store',
    hospital: 'icon-round_hospital',
    insuranceAgency: 'icon-round_insurance_agency',
    jewelryStore: 'icon-round_jewelry_store',
    laundry: 'icon-round_laundry',
    lawyer: 'icon-round_lawyer',
    library: 'icon-round_library',
    liquorStore: 'icon-round_liquor_store',
    localGovernmentOffice: 'icon-round_local_government_office',
    locksmith: 'icon-round_locksmith',
    lodging: 'icon-round_lodging',
    mealDelivery: 'icon-round_meal_delivery',
    mealTakeaway: 'icon-round_meal_takeaway',
    mosque: 'icon-round_mosque',
    movieRental: 'icon-round_movie_rental',
    movieTheater: 'icon-round_movie_theater',
    museum: 'icon-round_museum',
    nightClub: 'icon-round_night_club',
    park: 'icon-round_park',
    parking: 'icon-round_parking',
    petStore: 'icon-round_pet_store',
    pharmacy: 'icon-round_pharmacy',
    physiotherapist: 'icon-round_physiotherapist',
    police: 'icon-round_police',
    postOffice: 'icon-round_post_office',
    realEstateAgency: 'icon-round_real_estate_agency',
    restaurant: 'icon-round_restaurant',
    rvPark: 'icon-round_rv_park',
    school: 'icon-round_school',
    shoeStore: 'icon-round_shoe_store',
    shoppingMall: 'icon-round_shopping_mall',
    spa: 'icon-round_spa',
    stadium: 'icon-round_stadium',
    storage: 'icon-round_storage',
    store: 'icon-round_store',
    subwayStation: 'icon-round_subway_station',
    synagogue: 'icon-round_synagogue',
    taxiStand: 'icon-round_taxi_stand',
    trainStation: 'icon-round_train_station',
    transitStation: 'icon-round_transit_station',
    travelAgency: 'icon-round_travel_agency',
    university: 'icon-round_university',
    veterinaryCare: 'icon-round_veterinary_care',
    zoo: 'icon-round_zoo',
};
