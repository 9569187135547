import * as Yup from 'yup';

import messages from './messages';

const signUpEmailSchema = (formatMessage) =>
  Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'containers.signInEmail.emailFormat' }))
      .min(1)
      .required(formatMessage(messages.emailRequired)),
    pseudo: Yup.string()
      .min(1)
      .required(formatMessage(messages.pseudoRequired)),
    password: Yup.string()
      .min(8, formatMessage(messages.passwordStrengthRegex))
      .required(formatMessage(messages.passwordRequired))
      .matches(
        /^(?=.*)(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/,
        formatMessage(messages.passwordStrengthRegex),
      ),
    googlePlaceId: Yup.string()
      .min(1)
      .required(formatMessage(messages.googlePlaceIdRequired)),
    profileId: Yup.string().required(formatMessage(messages.profileIdRequired)),
    acceptsNewsletter: Yup.bool(),
  });

export const defaultProps = {
  email: '',
  pseudo: '',
  password: '',
  googlePlaceId: '',
  profileId: '',
  acceptsNewsletter: false,
};

export default signUpEmailSchema;
