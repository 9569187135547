var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { useSelector } from 'hooks';
import { ArrowBack, ArrowNext } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { PaginationWrapper } from './styles';
function itemRender(current, type, element) {
    if (type === 'prev') {
        return (React.createElement("button", { type: "button", className: "ant-pagination-item-link", tabIndex: -1 },
            React.createElement(ArrowBack, { size: rem(14) })));
    }
    if (type === 'next') {
        return (React.createElement("button", { type: "button", className: "ant-pagination-item-link", tabIndex: -1 },
            React.createElement(ArrowNext, { size: rem(14) })));
    }
    return element;
}
export var Pagination = memo(function (props) {
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(PaginationWrapper, __assign({ itemRender: itemRender, isKeyboardMode: keyboard }, props)));
});
