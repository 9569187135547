import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editProfileType';
var messages = defineMessages({
    title: { id: scope + ".title" },
    profileTypeRequiredMessage: { id: scope + ".profileTypeRequiredMessage" },
    updateProfileType: { id: scope + ".updateProfileType" },
    fieldDescription: {
        id: 'containers.editMyProfile.identification.fieldDescription',
    },
    fieldInformation: {
        id: 'containers.editMyProfile.identification.fieldInformation',
    },
});
export default messages;
