import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Spin } from 'components/Spin';
import { ReportForm } from 'containers/PlaceDetails/ReportProblem/ReportForm';
import { useAction, useSelector } from 'hooks';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { clearReported, reportReview } from '../store/addReview/actions';
import { makeSelectorActiveReview, makeSelectorReported, makeSelectorReportReviewLoading, } from '../store/addReview/selectors';
import { ButtonWrapper, ModalContent, Text } from './styles';
export var ReportReview = function (_a) {
    var visible = _a.visible, onClose = _a.onClose;
    var formatMessage = useIntl().formatMessage;
    var placeId = useParams().placeId;
    var reported = useSelector(makeSelectorReported());
    var reportLoading = useSelector(makeSelectorReportReviewLoading());
    var activeReview = useSelector(makeSelectorActiveReview());
    var clearReportedCallback = useAction(clearReported);
    var reportReviewCallback = useAction(reportReview);
    useEffect(function () {
        clearReportedCallback();
        return function () {
            clearReportedCallback();
        };
    }, []);
    var handleClose = function () {
        clearReportedCallback();
        onClose();
    };
    var handleSubmit = function (_a) {
        var message = _a.message;
        reportReviewCallback({ placeId: placeId, reviewId: activeReview, message: message });
    };
    return (React.createElement(Modal, { visible: visible, onCancel: handleClose, footer: null, title: formatMessage({
            id: reported
                ? 'containers.placeDetails.reportedReviewTitle'
                : 'containers.placeDetails.reportReviewTitle',
        }) },
        React.createElement(Spin, { spinning: reportLoading }, reported ? (React.createElement(ModalContent, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: "containers.placeDetails.reportedProblemMessage" })),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: "containers.placeDetails.reportedProblemEndMessage" })),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { onClick: handleClose },
                    React.createElement(FormattedMessage, { id: "containers.placeDetails.reportedButton" }))))) : (React.createElement(ReportForm, { onSubmit: handleSubmit })))));
};
