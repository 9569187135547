var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Divider, FacebookButton, InfoTitle, PageHeader, PageWrapper, } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { SignUpEmail } from 'containers/SignUpEmail';
import { useSelector } from 'hooks';
import { ArrowBack } from 'icons/mono';
import { rem } from 'polished';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from 'styles';
import { Container, Content } from './styles';
var SignUpPage = function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var from = (location.state || { from: '/' }).from;
    var _a = __read(useState(from || '/'), 1), previousPage = _a[0];
    var pushToSignIn = function () {
        return history.push(paths.signInPage, { from: previousPage });
    };
    var locale = useSelector(makeSelectLocale());
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.signUpPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({ id: 'pages.signUpPage.description' }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.signUpPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({ id: 'pages.signUpPage.description' }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(PageHeader, { isKeyboardMode: keyboard, title: formatMessage({ id: 'pages.signUpPage.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return history.push(paths.signInPage); }, sticky: true }),
            React.createElement(Container, null,
                React.createElement(Content, null,
                    React.createElement(FacebookButton, { from: previousPage }),
                    React.createElement(InfoTitle, { style: { marginTop: rem(16) } }, formatMessage({ id: 'pages.signInPage.byEmail' })),
                    React.createElement(SignUpEmail, { from: from }),
                    React.createElement(Divider, null),
                    React.createElement(InfoTitle, null, formatMessage({ id: 'pages.signUpPage.alreadyAccount' })),
                    React.createElement(Button, { type: "button", variant: "secondary", full: true, onClick: pushToSignIn }, formatMessage({ id: 'pages.signUpPage.signIn' })))))));
};
export default SignUpPage;
