import { LoadingIndicator } from 'components';
import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { NotificationsList } from './NotificationsList';
import { LoadingWrapper, NotificationContainer, Title } from './styles';
export var NotificationContent = function (_a) {
    var notifications = _a.notifications, loadMore = _a.loadMore, hasMore = _a.hasMore, loading = _a.loading, _b = _a.currentPage, currentPage = _b === void 0 ? 1 : _b;
    var notificationsContainerRef = useRef(null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "components.notificationMenu.title" })),
        React.createElement(NotificationContainer, { ref: notificationsContainerRef }, notifications && (React.createElement(InfiniteScroll, { initialLoad: false, pageStart: 1, loadMore: loadMore, hasMore: !loading && hasMore, useWindow: false, getScrollParent: function () { return notificationsContainerRef.current; } }, loading && currentPage === 1 ? (React.createElement(LoadingWrapper, null,
            React.createElement(LoadingIndicator, null))) : (React.createElement(NotificationsList, { notifications: notifications })))))));
};
