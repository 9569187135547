import React, { memo } from 'react';
export var BowlingAlley = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M28.038 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#c80000", style: { fill: '#c80000' }, d: "M7.042 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.759 4.759-12.474 0-17.232s-12.474-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M18.951 9.143h2.37c0.052-0.305 0.109-0.6 0.165-0.876 0.268-1.316-0.158-2.476-1.336-2.476h-0.029c-1.176 0-1.603 1.161-1.335 2.476 0.056 0.275 0.113 0.571 0.164 0.876z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M21.090 10.667c0.003-0.305 0.036-0.61 0.089-0.914h-2.086c0.052 0.305 0.086 0.61 0.090 0.914h1.907z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M21.873 13.194c-0.414-0.721-0.633-1.308-0.728-1.918h-2.020c-0.094 0.61-0.313 1.196-0.727 1.918-1.494 2.598-1.076 6.006 0.22 8.749h3.036c1.294-2.743 1.713-6.151 0.219-8.749z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M11.362 13.497c-2.308 0-4.179 1.872-4.179 4.18s1.872 4.179 4.179 4.179c2.308 0 4.179-1.871 4.179-4.179s-1.872-4.18-4.179-4.18zM11.062 18.6c-0.602 0.154-1.216-0.21-1.37-0.814-0.153-0.602 0.21-1.217 0.814-1.372 0.603-0.152 1.217 0.213 1.371 0.814 0.153 0.604-0.212 1.218-0.815 1.372zM11.67 16.012c-0.153-0.602 0.212-1.217 0.816-1.37 0.603-0.154 1.217 0.211 1.368 0.814 0.155 0.602-0.21 1.217-0.813 1.371-0.604 0.153-1.218-0.21-1.372-0.814zM13.481 19.353c-0.602 0.153-1.217-0.21-1.371-0.814-0.155-0.603 0.21-1.217 0.814-1.372 0.603-0.152 1.217 0.213 1.37 0.814 0.154 0.604-0.211 1.218-0.813 1.372z" })));
});
