var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { colors } from 'styles';
export var HomePageWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-top: ", ";\n\n  ", "\n\n  .CookieConsent {\n    background: ", " !important;\n    font-size: ", " !important;\n    padding: ", " !important;\n\n    > div:last-child {\n      display: flex;\n    }\n\n    button {\n      background: ", " !important;\n      color: ", " !important;\n      font-size: ", " !important;\n      border-radius: ", " !important;\n      padding: ", " ", " !important;\n      margin: ", " !important;\n      transition: background 0.2s, color 0.2s;\n\n      &:hover {\n        color: ", " !important;\n      }\n    }\n\n    #rcc-confirm-button {\n      order: 1;\n\n      &:hover {\n        background: ", " !important;\n      }\n    }\n\n    #rcc-decline-button {\n      order: 2;\n      margin-right: 15px !important;\n\n      &:hover {\n        background: ", " !important;\n      }\n    }\n\n    ", ";\n  }\n"], ["\n  padding-top: ", ";\n\n  ",
    "\n\n  .CookieConsent {\n    background: ", " !important;\n    font-size: ", " !important;\n    padding: ", " !important;\n\n    > div:last-child {\n      display: flex;\n    }\n\n    button {\n      background: ", " !important;\n      color: ", " !important;\n      font-size: ", " !important;\n      border-radius: ", " !important;\n      padding: ", " ", " !important;\n      margin: ", " !important;\n      transition: background 0.2s, color 0.2s;\n\n      &:hover {\n        color: ", " !important;\n      }\n    }\n\n    #rcc-confirm-button {\n      order: 1;\n\n      &:hover {\n        background: ", " !important;\n      }\n    }\n\n    #rcc-decline-button {\n      order: 2;\n      margin-right: 15px !important;\n\n      &:hover {\n        background: ", " !important;\n      }\n    }\n\n    ",
    ";\n  }\n"])), rem(80), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-bottom: ", ";\n    padding-top: ", ";\n  "], ["\n    padding-bottom: ", ";\n    padding-top: ", ";\n  "])), rem(80), rem(60)), colors.base.secondary, rem(16), rem(12), colors.base.white, colors.space.tint900, rem(16), rem(10), rem(8), rem(16), rem(8), colors.base.white, colors.base.primary, colors.base.danger, media.lessThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), rem(80)));
var templateObject_1, templateObject_2, templateObject_3;
