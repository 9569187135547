var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { Form } from 'formik';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { breakpoints, colors } from 'styles';
export var StyledForm = styled(Form)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: ", ";\n  margin: 0 auto;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n\n  > div {\n    flex: 1;\n  }\n\n  .ant-form-item-control-input-content span:nth-child(2) {\n    color: ", " !important;\n  }\n\n  .ant-input:focus {\n    border-color: ", " !important;\n    box-shadow: 0 0 0 ", " ", " !important;\n  }\n\n  ", ";\n"], ["\n  max-width: ", ";\n  margin: 0 auto;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n\n  > div {\n    flex: 1;\n  }\n\n  .ant-form-item-control-input-content span:nth-child(2) {\n    color: ", " !important;\n  }\n\n  .ant-input:focus {\n    border-color: ", " !important;\n    box-shadow: 0 0 0 ", " ", " !important;\n  }\n\n  ",
    ";\n"])), breakpoints.medium, colors.base.white, rgba(colors.base.black, 0.2), rem(4), rgba(colors.base.black, 0.2), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n    align-items: center;\n\n    > div {\n      width: 80%;\n    }\n  "], ["\n    flex-direction: column;\n    align-items: center;\n\n    > div {\n      width: 80%;\n    }\n  "]))));
export var StyledButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), media.greaterThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-left: ", ";\n  "], ["\n    margin-left: ", ";\n  "])), rem(12)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
