var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { PageHeader } from 'antd';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors } from 'styles';
var text = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint500,
});
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint800,
});
var shadow = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var PageHeaderWrapper = styled(PageHeader)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  height: ", ";\n  padding: 0 ", " !important;\n\n  ", "\n\n  ", ";\n\n  .ant-page-header-heading,\n  .ant-page-header-compact {\n    flex-wrap: nowrap !important;\n    height: 100%;\n  }\n\n  .ant-page-header-heading-left {\n    margin: 0;\n    width: 100%;\n\n    &:only-child {\n      margin-right: ", ";\n    }\n\n    .anticon {\n      color: ", ";\n      transition: color 0.2s;\n    }\n\n    .ant-page-header-back-button {\n      align-items: center;\n      display: flex !important;\n      justify-content: center;\n      padding: ", " !important;\n\n      &:hover {\n        color: ", " !important;\n      }\n    }\n\n    .ant-page-header-heading-title {\n      color: ", ";\n      font-size: ", ";\n      text-align: center;\n      width: 100%;\n      white-space: pre-wrap;\n      line-height: normal;\n\n      &:only-child {\n        margin-left: ", ";\n      }\n    }\n  }\n\n  .ant-page-header-heading-extra {\n    align-items: center;\n    display: flex;\n    margin: 0;\n\n    .ant-page-header-close {\n      button {\n        align-items: center;\n        background: none;\n        border: 0;\n        cursor: pointer;\n        display: flex;\n        justify-content: center;\n        outline: 0;\n        padding: ", ";\n\n        &:hover {\n          background: none;\n        }\n      }\n    }\n  }\n"], ["\n  border-bottom: ", " solid ", ";\n  height: ", ";\n  padding: 0 ", " !important;\n\n  ",
    "\n\n  ",
    ";\n\n  .ant-page-header-heading,\n  .ant-page-header-compact {\n    flex-wrap: nowrap !important;\n    height: 100%;\n  }\n\n  .ant-page-header-heading-left {\n    margin: 0;\n    width: 100%;\n\n    &:only-child {\n      margin-right: ", ";\n    }\n\n    .anticon {\n      color: ", ";\n      transition: color 0.2s;\n    }\n\n    .ant-page-header-back-button {\n      align-items: center;\n      display: flex !important;\n      justify-content: center;\n      padding: ", " !important;\n\n      &:hover {\n        color: ", " !important;\n      }\n    }\n\n    .ant-page-header-heading-title {\n      color: ", ";\n      font-size: ", ";\n      text-align: center;\n      width: 100%;\n      white-space: pre-wrap;\n      line-height: normal;\n\n      &:only-child {\n        margin-left: ", ";\n      }\n    }\n  }\n\n  .ant-page-header-heading-extra {\n    align-items: center;\n    display: flex;\n    margin: 0;\n\n    .ant-page-header-close {\n      button {\n        align-items: center;\n        background: none;\n        border: 0;\n        cursor: pointer;\n        display: flex;\n        justify-content: center;\n        outline: 0;\n        padding: ", ";\n\n        &:hover {\n          background: none;\n        }\n      }\n    }\n  }\n"])), rem(1), border, rem(60), rem(16), ifProp('sticky', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      background-color: ", " !important;\n      position: fixed !important;\n      top: ", ";\n      width: 100%;\n      z-index: 1000;\n\n      ", "\n    "], ["\n      background-color: ", " !important;\n      position: fixed !important;\n      top: ", ";\n      width: 100%;\n      z-index: 1000;\n\n      ",
    "\n    "])), background, rem(80), media.lessThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        top: ", ";\n      "], ["\n        top: ", ";\n      "])), rem(60)))), ifProp('isKeyboardMode', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      top: ", ";\n\n      .ant-page-header-back-button {\n        &:focus {\n          outline: 0 !important;\n\n          &::before {\n            ", ";\n            content: '';\n            width: ", ";\n            height: ", ";\n            display: block;\n            position: absolute;\n            border-radius: ", ";\n          }\n        }\n      }\n    "], ["\n      top: ", ";\n\n      .ant-page-header-back-button {\n        &:focus {\n          outline: 0 !important;\n\n          &::before {\n            ", ";\n            content: '';\n            width: ", ";\n            height: ", ";\n            display: block;\n            position: absolute;\n            border-radius: ", ";\n          }\n        }\n      }\n    "])), rem(130), shadow, rem(26), rem(26), rem(10))), rem(26), text, rem(4), colors.base.primary, text, rem(16), rem(26), rem(4));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
