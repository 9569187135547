import { HOME_IN_THE_WORLD_SUCCESS, HOME_LATEST_UPDATES_SUCCESS, HOME_NEWS_FAILURE, HOME_NEWS_REQUEST, } from './constants';
export var fetchHomeNewsRequest = function () { return ({
    type: HOME_NEWS_REQUEST,
}); };
export var fetchHomeNewsFailure = function (payload) { return ({
    type: HOME_NEWS_FAILURE,
    payload: payload,
}); };
export var setHomeLatestUpdatesSuccess = function (payload) { return ({
    type: HOME_LATEST_UPDATES_SUCCESS,
    payload: payload,
}); };
export var setHomeInTheWorldSuccess = function (payload) { return ({
    type: HOME_IN_THE_WORLD_SUCCESS,
    payload: payload,
}); };
