var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: calc(100vh - ", ");\n\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: calc(100vh - ", ");\n\n  ",
    ";\n"])), rem(140), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: calc(100vh - ", ");\n  "], ["\n    min-height: calc(100vh - ", ");\n  "])), rem(80)));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  margin-top: ", ";\n"])), rem(60));
export var PaginationWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
