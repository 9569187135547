import { createSelector } from 'reselect';
import { membersScoreBoardOnChallengeInitialState } from './reducer';
export var selectMembersScoreBoardOnChallengeDomain = function (state) {
    return state.membersScoreBoardOnChallenge ||
        membersScoreBoardOnChallengeInitialState;
};
export var makeSelectMembersScoreBoardOnChallengeLoading = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectMembersScoreBoardOnChallengeHasError = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectMembersScoreBoardOnChallengeError = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectMembersScoreBoardOnChallengePages = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectMembersScoreBoardOnChallengeCurrentPage = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectMembersScoreBoardOnChallengeTotal = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectMembersScoreBoardOnChallengeItems = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeMembersScoreBoardOnChallenge = function () {
    return createSelector(selectMembersScoreBoardOnChallengeDomain, function (subState) { return subState; });
};
export default makeMembersScoreBoardOnChallenge;
