import produce from 'immer';
import { v4 as uuid } from 'uuid';
import { ADD_PLACE_TO_BOOKMARK, ADD_PLACE_TO_BOOKMARK_SUCCESS, CLEAR_FEEDBACK, CLEAR_REPORTED, CLEAR_REPORTED_PHOTO, GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_PLACE_CRITERIA, GET_PLACE_CRITERIA_FAILURE, GET_PLACE_CRITERIA_SUCCESS, GET_PLACE_DETAILS, GET_PLACE_DETAILS_FAILURE, GET_PLACE_DETAILS_SUCCESS, REMOVE_PLACE_FROM_BOOKMARK, REMOVE_PLACE_FROM_BOOKMARK_SUCCESS, REPORT_MEDIA_PLACE, REPORT_MEDIA_PLACE_FAILURE, REPORT_MEDIA_PLACE_SUCCESS, REPORT_PLACE, REPORT_PLACE_FAILURE, REPORT_PLACE_SUCCESS, sections, SET_ACTIVE_SECTION, UPDATE_PLACE, UPDATE_PLACE_FAILURE, UPDATE_PLACE_SUCCESS, } from './constants';
export var initialState = {
    placeDetails: {},
    error: undefined,
    hasError: false,
    loading: false,
    bookmarkLoading: false,
    criteriaLoading: false,
    activeSection: sections.options,
    categories: [],
    criteria: [],
    reported: false,
    reportLoading: false,
    reportedPhoto: false,
    reportPhotoLoading: false,
    alreadyReportedPhoto: false,
};
var placeDetailsReducer = produce(function (draft, action) {
    switch (action.type) {
        case GET_PLACE_DETAILS:
            draft.loading = true;
            break;
        case GET_PLACE_DETAILS_SUCCESS: {
            draft.placeDetails = action.payload;
            draft.loading = false;
            break;
        }
        case GET_PLACE_DETAILS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case ADD_PLACE_TO_BOOKMARK:
        case REMOVE_PLACE_FROM_BOOKMARK:
            draft.bookmarkLoading = true;
            break;
        case ADD_PLACE_TO_BOOKMARK_SUCCESS:
        case REMOVE_PLACE_FROM_BOOKMARK_SUCCESS:
            draft.bookmarkLoading = false;
            break;
        case GET_PLACE_CRITERIA:
            draft.criteriaLoading = true;
            break;
        case GET_PLACE_CRITERIA_SUCCESS:
            draft.criteria = action.payload.items;
            draft.criteriaLoading = false;
            break;
        case GET_PLACE_CRITERIA_FAILURE:
            draft.criteriaLoading = false;
            break;
        case SET_ACTIVE_SECTION:
            draft.activeSection = action.payload;
            break;
        case GET_CATEGORIES:
            draft.loading = true;
            break;
        case GET_CATEGORIES_SUCCESS:
            draft.categories = action.payload.items;
            draft.loading = false;
            break;
        case UPDATE_PLACE:
            draft.loading = true;
            break;
        case UPDATE_PLACE_SUCCESS:
            draft.loading = false;
            draft.activeSection = sections.options;
            draft.feedback = {
                id: uuid(),
                object: action.payload,
            };
            break;
        case UPDATE_PLACE_FAILURE:
            draft.loading = false;
            break;
        case CLEAR_FEEDBACK:
            draft.feedback = undefined;
            break;
        case REPORT_PLACE:
            draft.reportLoading = true;
            break;
        case REPORT_PLACE_SUCCESS:
            draft.reportLoading = false;
            draft.reported = true;
            break;
        case REPORT_PLACE_FAILURE:
            draft.reportLoading = false;
            break;
        case REPORT_MEDIA_PLACE:
            draft.reportPhotoLoading = true;
            draft.alreadyReportedPhoto = false;
            break;
        case REPORT_MEDIA_PLACE_SUCCESS:
            draft.reportPhotoLoading = false;
            draft.reportedPhoto = true;
            break;
        case REPORT_MEDIA_PLACE_FAILURE:
            draft.reportPhotoLoading = false;
            draft.reportedPhoto = true;
            draft.alreadyReportedPhoto = true;
            break;
        case CLEAR_REPORTED_PHOTO:
            draft.reportedPhoto = false;
            draft.alreadyReportedPhoto = false;
            break;
        case CLEAR_REPORTED:
            draft.reported = false;
            break;
        default:
    }
}, initialState);
export default placeDetailsReducer;
