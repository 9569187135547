import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editOptions';
var messages = defineMessages({
    name: {
        id: scope + ".name",
    },
    identification: {
        id: scope + ".identification",
    },
    identificationMessage: {
        id: scope + ".identificationMessage",
    },
    editPhoto: {
        id: scope + ".editPhoto",
    },
    editPhotoMessage: {
        id: scope + ".editPhotoMessage",
    },
    profileType: {
        id: scope + ".profileType",
    },
    profileMessage: {
        id: scope + ".profileMessage",
    },
    contactInformation: {
        id: scope + ".contactInformation",
    },
    email: {
        id: scope + ".email",
    },
    telephone: {
        id: scope + ".telephone",
    },
    password: {
        id: scope + ".password",
    },
    location: {
        id: scope + ".location",
    },
    myAddress: {
        id: scope + ".myAddress",
    },
    editPhotoOverlay: {
        id: scope + ".editPhotoOverlay",
    },
});
export default messages;
