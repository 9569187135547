import { Button } from 'components';
import React from 'react';
import { useIntl } from 'react-intl';
import { ButtonsWrapper, FooterContainer } from './styles';
export var Footer = function (_a) {
    var onFilter = _a.onFilter, onClear = _a.onClear;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(FooterContainer, null,
        React.createElement(ButtonsWrapper, null,
            React.createElement(Button, { variant: "ghost", onClick: onClear }, formatMessage({
                id: 'containers.filterPlaces.footer.clear',
            })),
            React.createElement(Button, { onClick: onFilter }, formatMessage({
                id: 'containers.filterPlaces.footer.applyFilters',
            })))));
};
