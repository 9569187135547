import { chat as api } from 'api';
import autobahn from 'autobahn';
import { makeSelectUser } from 'containers/Auth/selectors';
import { addNewMessage, getConversations, setChatConnection, } from 'containers/Chat/actions';
import { useAction, useSelector } from 'hooks';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { DesktopChat } from './Desktop';
import { MobileChat } from './Mobile';
import { MailSystemContainer } from './styles';
export var Chat = function () {
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var user = useSelector(makeSelectUser());
    var setChatConnectionCallback = useAction(setChatConnection);
    var addNewMessageCallback = useAction(addNewMessage);
    var getConversationsCallback = useAction(getConversations);
    useEffect(function () {
        if (user === null || user === void 0 ? void 0 : user.messagingKey) {
            var connection = new autobahn.Connection({
                url: 'wss://crossbar-dev.jaccede.com/ws',
                realm: 'realm1',
            });
            connection.onopen = function (session) {
                var onEvent = function (args) {
                    if (!args[0])
                        return;
                    addNewMessageCallback(args[0]);
                    api
                        .getUnreadCount()
                        .then(function (_a) {
                        var data = _a.data;
                        if ((data === null || data === void 0 ? void 0 : data.total) > 0)
                            getConversationsCallback({ page: 1, silent: true });
                    })
                        .catch(function (error) { return console.warn(error); });
                };
                session.subscribe("messaging.conversation.toui." + (user === null || user === void 0 ? void 0 : user.messagingKey), onEvent);
            };
            connection.open();
            setChatConnectionCallback(connection);
        }
    }, [user]);
    return (React.createElement(MailSystemContainer, null, isMobile || isTabletOrMobile ? React.createElement(MobileChat, null) : React.createElement(DesktopChat, null)));
};
