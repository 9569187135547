import { createSelector } from 'reselect';
import { contributorUserProfileInitialState } from '../reducers/contributorUserProfileReducer';
export var selectUserProfileDomain = function (state) {
    return state.contributorUserProfile || contributorUserProfileInitialState;
};
export var makeSelectUserProfileLoading = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectUserProfileHasError = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectUserProfileError = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectUserProfileContributionStats = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var contributionStats = _a.contributionStats;
        return contributionStats;
    });
};
export var makeSelectUserProfilePointsSerie = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var pointsSerie = _a.pointsSerie;
        return pointsSerie;
    });
};
export var makeSelectUserProfileQuickBadges = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var quickBadges = _a.quickBadges;
        return quickBadges;
    });
};
export var makeSelectUserProfileRocketThreshold = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var rocketThreshold = _a.rocketThreshold;
        return rocketThreshold;
    });
};
export var makeSelectUserProfileTotalFavorites = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var totalFavorites = _a.totalFavorites;
        return totalFavorites;
    });
};
export var makeSelectUserProfileTotalFollowed = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var totalFollowed = _a.totalFollowed;
        return totalFollowed;
    });
};
export var makeSelectUserProfileTotalFollowers = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var totalFollowers = _a.totalFollowers;
        return totalFollowers;
    });
};
export var makeSelectUserProfileTotalPlaces = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var totalPlaces = _a.totalPlaces;
        return totalPlaces;
    });
};
export var makeSelectUserProfileTotalPoints = function () {
    return createSelector(selectUserProfileDomain, function (_a) {
        var totalPoints = _a.totalPoints;
        return totalPoints;
    });
};
export var makeUserProfile = function () {
    return createSelector(selectUserProfileDomain, function (subState) { return subState; });
};
export default makeUserProfile;
