import * as Yup from 'yup';

const forgotPasswordSchema = (formatMessage) =>
  Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'containers.forgotPasswordPage.emailFormat' }))
      .min(1)
      .required(
        formatMessage({ id: 'containers.forgotPasswordPage.emailRequired' }),
      ),
  });

export default forgotPasswordSchema;
