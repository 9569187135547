import { PlacesNearby } from 'containers/ChallengeMembersTeam/PlacesAroundMe/PlacesNearby';
import React from 'react';
import { useIntl } from 'react-intl';
import { ButtonWrapper, NewTeamContainer, Title } from './styles';
export var ChallengeDetailPlaceNearbySection = function () {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(NewTeamContainer, null,
        React.createElement(Title, null, formatMessage({
            id: 'containers.challengesMembersTeam.placesAroundMeSection.title',
        })),
        React.createElement(ButtonWrapper, null,
            React.createElement(PlacesNearby, null))));
};
