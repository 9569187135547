var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var Medal200Desktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.2135 0.872727C10.1553 0.872727 1.99965 9.02662 1.99965 19.0857C1.99965 24.5699 4.43631 29.4764 8.27282 32.8154V46.5523L14.2431 43.2961L20.2135 40.0391L26.1838 43.2961L32.1541 46.5523V32.8163C35.9898 29.4764 38.4264 24.5699 38.4264 19.0857C38.4264 9.02662 30.2725 0.872727 20.2135 0.872727Z", fill: "#FF9400", fillOpacity: "0.2" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.86784 25.9164V44.2253L20.2133 38.0377L31.5587 44.2253V25.9164H8.86784Z", fill: "#EF8E1B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.5406 25.9164V41.1315L20.2133 38.0377L25.886 41.1315V25.9164H14.5406Z", fill: "#E08119" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.2133 1.83229C11.2006 1.83229 3.86886 9.16407 3.86886 18.1767C3.86886 27.1894 11.2006 34.5194 20.2133 34.5194C29.2251 34.5194 36.5569 27.1894 36.5569 18.1767C36.5569 9.16407 29.2251 1.83229 20.2133 1.83229Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.5178 32.5294C11.6048 32.5294 5.16582 26.0913 5.16582 18.1783C5.16582 10.2627 11.6048 3.8263 19.5178 3.8263C27.4308 3.8263 33.8689 10.2627 33.8689 18.1783C33.8689 26.0913 27.4308 32.5294 19.5178 32.5294ZM19.5178 2.03284C10.6012 2.03284 3.37236 9.26077 3.37236 18.1783C3.37236 27.0932 10.6012 34.3238 19.5178 34.3238C28.4345 34.3238 35.6633 27.0932 35.6633 18.1783C35.6633 9.26077 28.4345 2.03284 19.5178 2.03284Z", fill: "#F7D131" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.2133 33.5599C11.7313 33.5599 4.8306 26.6601 4.8306 18.178C4.8306 9.69425 11.7313 2.79535 20.2133 2.79535C28.6945 2.79535 35.5951 9.69425 35.5951 18.178C35.5951 26.6601 28.6945 33.5599 20.2133 33.5599ZM20.2133 0.872727C10.6561 0.872727 2.90799 8.61993 2.90799 18.178C2.90799 27.7335 10.6561 35.4825 20.2133 35.4825C29.7705 35.4825 37.5186 27.7335 37.5186 18.178C37.5186 8.61993 29.7705 0.872727 20.2133 0.872727Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M26.9364 14.9496L26.7776 12.9581L24.8724 12.3603L23.8653 10.6366L21.8895 10.9238L20.2339 9.80753H20.2173L18.5617 10.9238L16.5859 10.6366L15.5788 12.3603L13.6736 12.9581L13.5148 14.9496L12.0573 16.3137L12.7773 18.1779L12.0573 20.0394L13.5148 21.4043L13.6736 23.3959L15.5788 23.9928L16.5859 25.7174L18.5617 25.4302L20.2173 26.5456H20.2339L21.8895 25.4302L23.8653 25.7174L24.8724 23.9928L26.7776 23.3959L26.9364 21.4043L28.393 20.0394L27.673 18.1779L28.393 16.3137L26.9364 14.9496Z", fill: "#EBC72E" })));
});
