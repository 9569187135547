var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var HeaderWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n  display: flex;\n  align-items: center;\n"], ["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n  display: flex;\n  align-items: center;\n"])), rem(1), border, rem(16));
export var UserInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n  overflow: hidden;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n  overflow: hidden;\n"])), rem(16));
export var UserName = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"])), color, rem(14), fonts.weight.semibold, rem(4));
var templateObject_1, templateObject_2, templateObject_3;
