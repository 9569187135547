var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { AvatarWrapper } from 'components/Avatar/styles';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var dateColor = theme('mode', {
    light: colors.space.tint600,
    dark: colors.carbon.tint600,
});
export var ReportButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  top: ", ";\n  z-index: 1;\n\n  button {\n    padding: 0;\n  }\n\n  svg {\n    fill: ", ";\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    right: ", ";\n    top: ", ";\n    width: ", ";\n    height: ", ";\n    display: flex;\n    align-items: center;\n    background: ", ";\n    border: ", " solid ", ";\n    border-radius: ", ";\n    z-index: -1;\n  }\n\n  &:hover::before {\n    content: 'Report spam';\n    color: ", ";\n    font-size: ", ";\n    white-space: nowrap;\n    padding-left: ", ";\n    padding-right: ", ";\n    width: auto;\n  }\n"], ["\n  position: absolute;\n  right: 0;\n  top: ", ";\n  z-index: 1;\n\n  button {\n    padding: 0;\n  }\n\n  svg {\n    fill: ", ";\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    right: ", ";\n    top: ", ";\n    width: ", ";\n    height: ", ";\n    display: flex;\n    align-items: center;\n    background: ", ";\n    border: ", " solid ", ";\n    border-radius: ", ";\n    z-index: -1;\n  }\n\n  &:hover::before {\n    content: 'Report spam';\n    color: ", ";\n    font-size: ", ";\n    white-space: nowrap;\n    padding-left: ", ";\n    padding-right: ", ";\n    width: auto;\n  }\n"])), rem(-22), colors.space.tint600, rem(-1), rem(-1), rem(24), rem(24), colors.base.white, rem(1), colors.space.tint400, rem(12), colors.space.tint800, rem(13), rem(8), rem(24));
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", ";\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", ";\n  width: 100%;\n"])), rem(8));
export var UserName = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n"])), rem(13), fonts.weight.semibold, rem(4), rem(4));
export var MessageText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-self: flex-start;\n  display: inline-block;\n  font-size: ", ";\n  background: ", ";\n  color: ", ";\n  border-radius: ", ";\n  border-top-left-radius: ", ";\n  padding: ", ";\n  max-width: 70%;\n  position: relative;\n"], ["\n  align-self: flex-start;\n  display: inline-block;\n  font-size: ", ";\n  background: ", ";\n  color: ", ";\n  border-radius: ", ";\n  border-top-left-radius: ", ";\n  padding: ", ";\n  max-width: 70%;\n  position: relative;\n"])), rem(15), colors.space.tint200, colors.space.tint900, rem(13), rem(3), rem(12));
export var MessageDate = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-self: flex-start;\n  display: inline-block;\n  color: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n"], ["\n  align-self: flex-start;\n  display: inline-block;\n  color: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n"])), dateColor, rem(12), rem(6));
var modifiers = {
    sender: function () { return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    ", " {\n      align-self: flex-end;\n      background: ", ";\n      color: ", ";\n      border-top-left-radius: ", ";\n      border-top-right-radius: ", ";\n    }\n\n    ", " {\n      align-self: flex-end;\n    }\n  "], ["\n    ", " {\n      align-self: flex-end;\n      background: ", ";\n      color: ", ";\n      border-top-left-radius: ", ";\n      border-top-right-radius: ", ";\n    }\n\n    ", " {\n      align-self: flex-end;\n    }\n  "])), MessageText, colors.base.secondary, colors.base.white, rem(13), rem(3), MessageDate); },
};
export var MessageBoxWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  /* display: flex; */\n\n  ", " {\n    margin-right: ", ";\n  }\n\n  ", "\n"], ["\n  /* display: flex; */\n\n  ", " {\n    margin-right: ", ";\n  }\n\n  ",
    "\n"])), AvatarWrapper, rem(8), function (_a) {
    var sender = _a.sender;
    return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), sender && modifiers.sender());
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
