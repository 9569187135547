var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem, rgba } from 'polished';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint500,
});
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint800,
});
export var Container = styled(ScrollContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom: ", " solid ", ";\n  padding: 0 ", ";\n  height: 100%;\n  width: 100%;\n\n  display: flex;\n  align-items: center;\n\n  div {\n    height: ", " !important;\n  }\n\n  button {\n    white-space: nowrap;\n    flex-shrink: 0;\n\n    &:not(:first-child) {\n      margin-right: ", ";\n    }\n  }\n\n  ", ";\n"], ["\n  background-color: ", ";\n  border-bottom: ", " solid ", ";\n  padding: 0 ", ";\n  height: 100%;\n  width: 100%;\n\n  display: flex;\n  align-items: center;\n\n  div {\n    height: ", " !important;\n  }\n\n  button {\n    white-space: nowrap;\n    flex-shrink: 0;\n\n    &:not(:first-child) {\n      margin-right: ", ";\n    }\n  }\n\n  ",
    ";\n"])), background, rem(1), border, rem(16), rem(34), rem(12), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "]))));
export var DropdownContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  margin-top: ", ";\n  width: 440px;\n  min-height: 310px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  margin-top: ", ";\n  width: 440px;\n  min-height: 310px;\n  display: flex;\n  flex-direction: column;\n"])), colors.base.white, rem(1), colors.space.tint300, rem(15), rem(16));
export var DropdownContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", ";\n  padding-bottom: 0;\n  display: flex;\n  justify-content: space-between;\n  flex: 1;\n"], ["\n  padding: ", ";\n  padding-bottom: 0;\n  display: flex;\n  justify-content: space-between;\n  flex: 1;\n"])), rem(20));
export var DropdownFooter = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  padding: ", ";\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  border-top: ", " solid ", ";\n  padding: ", ";\n  display: flex;\n  justify-content: flex-end;\n"])), rem(1), colors.space.tint300, rem(20));
export var Title = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), rem(21), fonts.weight.semibold, rem(8));
export var Tagline = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  line-height: 1.2;\n  display: flex;\n  width: 80%;\n"], ["\n  font-size: ", ";\n  line-height: 1.2;\n  display: flex;\n  width: 80%;\n"])), rem(16));
export var SwitchWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), rem(6));
export var StyledButton = styled(Button)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  &.ant-dropdown-open {\n    background: ", ";\n    border-color: ", ";\n    box-shadow: 0 0 0 ", " ", ";\n  }\n"], ["\n  &.ant-dropdown-open {\n    background: ", ";\n    border-color: ", ";\n    box-shadow: 0 0 0 ", " ", ";\n  }\n"])), colors.base.white, rgba(colors.base.info, 0.8), rem(2), rgba(colors.base.info, 0.8));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
