var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
export var ChallengeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  cursor: pointer;\n  display: flex;\n  padding: ", ";\n  position: relative;\n\n  a {\n    text-decoration: none;\n\n    &:focus {\n      outline: 0;\n      outline-offset: 0;\n    }\n  }\n\n  & + div {\n    margin-top: ", ";\n  }\n"], ["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  cursor: pointer;\n  display: flex;\n  padding: ", ";\n  position: relative;\n\n  a {\n    text-decoration: none;\n\n    &:focus {\n      outline: 0;\n      outline-offset: 0;\n    }\n  }\n\n  & + div {\n    margin-top: ", ";\n  }\n"])), rem(1), border, rem(10), rem(12), rem(16));
export var AvatarWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  position: absolute;\n  top: -", ";\n"], ["\n  display: flex;\n  position: absolute;\n  top: -", ";\n"])), rem(10));
export var InfoWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n"])), rem(70));
export var Name = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), color, rem(15), fonts.weight.semibold, rem(4));
export var Dates = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), color, rem(13));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
