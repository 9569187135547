var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
import { Section } from '../Section';
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var StyledSection = styled(Section)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-top: ", ";\n  padding-bottom: ", ";\n"], ["\n  padding-top: ", ";\n  padding-bottom: ", ";\n"])), rem(30), rem(30));
export var SocialNetworksContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var Title = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: ", ";\n"])), colors.base.white, rem(17), fonts.weight.semibold, rem(4));
export var ExternalLink = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  margin-left: ", ";\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n\n  ", "\n"], ["\n  color: ", ";\n  cursor: pointer;\n  margin-left: ", ";\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n\n  ",
    "\n"])), colors.base.white, rem(8), rem(32), rem(32), ifProp('isKeyboardMode', css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      &:focus {\n        position: relative;\n        outline: 0 !important;\n        outline-offset: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: 100%;\n          height: 100%;\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        position: relative;\n        outline: 0 !important;\n        outline-offset: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: 100%;\n          height: 100%;\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          left: ", ";\n        }\n      }\n    "])), border, rem(10), rem(-4))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
