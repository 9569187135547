var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var MedalCriterionDisabledDesktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { opacity: "0.138067", fillRule: "evenodd", clipRule: "evenodd", d: "M37.8306 13.9662L15.1269 5.70271C14.3549 5.42171 13.4921 5.82353 13.2107 6.59673L2.44761 36.1681C2.1664 36.9407 2.56879 37.8037 3.34085 38.0847L26.0445 46.3481C26.8166 46.6291 27.6789 46.2265 27.9602 45.4539L38.7233 15.8825C39.0047 15.1093 38.6026 14.2472 37.8306 13.9662Z", fill: "#656D78" }),
        React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "-2", width: "43", height: "50" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.86325 37.183L28.5219 47.2499L42.7142 8.25686L15.0556 -1.81005L0.86325 37.183Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.13157 35.2206L25.1324 42.8643L35.3544 14.7796L14.3536 7.13588L4.13157 35.2206ZM37.0237 12.8434L14.3206 4.58011C13.5479 4.2989 12.6858 4.70093 12.4043 5.47412L1.64124 35.0455C1.36003 35.8181 1.76186 36.6809 2.53448 36.9621L25.2376 45.2253C26.0102 45.5065 26.8726 45.1039 27.1538 44.3313L37.9169 14.7599C38.1983 13.9867 37.7963 13.1246 37.0237 12.8434Z", fill: "#B2BBC8" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.13212 35.2208L25.1329 42.8645L35.355 14.7798L14.3541 7.13609L4.13212 35.2208Z", fill: "#E6E6E6" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.1666 4.54389C28.2783 4.94852 28.8513 6.1783 28.4462 7.29116C28.0416 8.40287 26.812 8.9772 25.7003 8.57257C24.5886 8.16794 24.0158 6.93759 24.4204 5.82587C24.8254 4.71302 26.0549 4.13926 27.1666 4.54389ZM32.1425 9.18931L30.071 8.43533C30.1327 8.30831 30.1889 8.17673 30.2384 8.04075C31.0061 5.93168 29.9198 3.60015 27.8119 2.83293C25.7039 2.0657 23.3726 3.15331 22.6049 5.26238C22.5554 5.39836 22.514 5.53532 22.4796 5.67229L20.4086 4.91852C19.8949 4.73153 19.3227 4.99879 19.1355 5.51312L17.9055 8.89264C17.7185 9.4064 17.9848 9.97946 18.4985 10.1665L30.2324 14.4372C30.7456 14.624 31.3185 14.3564 31.5055 13.8426L32.7356 10.4631C32.9227 9.9488 32.6557 9.37609 32.1425 9.18931Z", fill: "#C8D2DF" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.8396 8.04714C20.6886 8.4619 20.2302 8.6752 19.8166 8.52465C19.4024 8.3739 19.1883 7.91584 19.3393 7.50108C19.4902 7.08632 19.9486 6.87302 20.3628 7.02378C20.7765 7.17432 20.9905 7.63238 20.8396 8.04714Z", fill: "#E6ECF4" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.3403 11.8691C31.1893 12.2838 30.7309 12.4971 30.3167 12.3464C29.9031 12.1958 29.6884 11.7376 29.8394 11.3228C29.9903 10.9081 30.4493 10.695 30.863 10.8455C31.2771 10.9963 31.4912 11.4543 31.3403 11.8691Z", fill: "#E6ECF4" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M20.6103 17.7048L37.092 23.7037L37.4773 22.6449L20.9956 16.646L20.6103 17.7048Z", fill: "#82ADDD" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M19.8557 19.778L36.3374 25.7769L36.7226 24.7187L20.2408 18.7198L19.8557 19.778Z", fill: "#82ADDD" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M12.7221 17.7344L17.1075 19.3306L18.7043 14.9435L14.3189 13.3473L12.7221 17.7344Z", fill: "#5D9CEC" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M10.1788 24.7222L14.5642 26.3184L16.1612 21.9307L11.7758 20.3345L10.1788 24.7222Z", fill: "#5D9CEC" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M18.0866 24.6385L27.5305 28.0758L27.9157 27.0176L18.4718 23.5803L18.0866 24.6385Z", fill: "#82ADDD" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M17.332 26.7117L26.7759 30.149L27.1613 29.0902L17.7174 25.6529L17.332 26.7117Z", fill: "#82ADDD" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M7.59588 31.8186L11.9813 33.4148L13.5783 29.0271L9.19287 27.4309L7.59588 31.8186Z", fill: "#5D9CEC" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M15.5035 31.7355L24.9474 35.1728L25.3328 34.114L15.8889 30.6767L15.5035 31.7355Z", fill: "#82ADDD" }),
            React.createElement("path", { opacity: "0.524484", fillRule: "evenodd", clipRule: "evenodd", d: "M14.7489 33.8087L24.1928 37.246L24.578 36.1878L15.1341 32.7505L14.7489 33.8087Z", fill: "#82ADDD" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.024 17.9358L14.5297 14.1265L15.729 13.9702L15.9034 15.3127L18.713 12.4507L19.5759 13.2983L15.024 17.9358Z", fill: "#E2F0FF" })),
        React.createElement("g", { style: { mixBlendMode: 'color' } },
            React.createElement("rect", { width: "40", height: "48", fill: "white" }))));
});
