import { PROFILE_MY_CHALLENGES_FAILURE, PROFILE_MY_CHALLENGES_REQUEST, PROFILE_MY_CHALLENGES_SUCCESS, } from '../constants';
export var fetchMyChallengesRequest = function (payload) { return ({
    type: PROFILE_MY_CHALLENGES_REQUEST,
    payload: payload,
}); };
export var fetchMyChallengesSuccess = function (payload) { return ({
    type: PROFILE_MY_CHALLENGES_SUCCESS,
    payload: payload,
}); };
export var fetchMyChallengesError = function (error) { return ({
    type: PROFILE_MY_CHALLENGES_FAILURE,
    payload: error,
}); };
