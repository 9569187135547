var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Paragraph } from 'components';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { HideButton } from './styles';
export var Ellipsis = function (_a) {
    var _b = _a.rows, rows = _b === void 0 ? 2 : _b, expandable = _a.expandable, canHide = _a.canHide, children = _a.children;
    var _c = __read(useState({
        expanded: false,
        counter: 0,
    }), 2), expand = _c[0], setExpand = _c[1];
    var formatMessage = useIntl().formatMessage;
    function handleOnExpand() {
        setExpand(function (prevState) { return ({
            expanded: true,
            counter: !prevState.expanded
                ? prevState.counter + 0
                : prevState.counter + 1,
        }); });
    }
    function handleHideMessage() {
        setExpand(function (prevState) { return ({
            expanded: false,
            counter: !prevState.expanded
                ? prevState.counter + 0
                : prevState.counter + 1,
        }); });
    }
    return (React.createElement("div", { key: expand.counter },
        React.createElement(Paragraph, { ellipsis: {
                rows: rows,
                expandable: expandable,
                symbol: formatMessage({
                    id: 'components.ellipsis.more',
                }),
                onExpand: handleOnExpand,
            } },
            children,
            canHide && expand.expanded && (React.createElement(HideButton, { onClick: handleHideMessage }, formatMessage({
                id: 'components.ellipsis.less',
            }))))));
};
