var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Skeleton as SkeletonComponent } from 'components/Skeleton';
import { SubscribeButton as SubscribeButtonComponent } from 'components/SubscribeButton';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var bgColor = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint700,
});
var textColor = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint900,
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: ", ";\n  border: 1px solid ", ";\n  padding-top: ", ";\n  position: relative;\n  z-index: 50;\n  max-width: 90vw;\n\n  ", ";\n"], ["\n  background-color: ", ";\n  border-radius: ", ";\n  border: 1px solid ", ";\n  padding-top: ", ";\n  position: relative;\n  z-index: 50;\n  max-width: 90vw;\n\n  ",
    ";\n"])), bgColor, rem(32), borderColor, rem(46), ifProp('hasSubscribeButton', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      padding-top: ", ";\n    "], ["\n      padding-top: ", ";\n    "])), rem(56))));
export var UserContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n"])));
export var UserMessage = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", " ", ";\n  text-align: center;\n  white-space: pre-wrap;\n\n  .ant-typography {\n    color: ", ";\n    font-size: ", ";\n  }\n"], ["\n  padding: ", " ", ";\n  text-align: center;\n  white-space: pre-wrap;\n\n  .ant-typography {\n    color: ", ";\n    font-size: ", ";\n  }\n"])), rem(16), rem(50), textColor, rem(15));
export var UserName = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding-top: ", ";\n  max-width: 90%;\n  text-align: center;\n\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding-top: ", ";\n  max-width: 90%;\n  text-align: center;\n\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n"])), textColor, rem(16), fonts.weight.bold, rem(12));
export var UserSlug = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  max-width: 90%;\n  text-align: center;\n\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  max-width: 90%;\n  text-align: center;\n\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  ",
    ";\n"])), textColor, rem(14), ifProp('hasNotFirstName', css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      font-size: ", ";\n      font-weight: ", ";\n      padding-top: ", ";\n    "], ["\n      font-size: ", ";\n      font-weight: ", ";\n      padding-top: ", ";\n    "])), rem(16), fonts.weight.bold, rem(12))));
export var UserPoints = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  margin: ", " 0;\n\n  > span {\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    margin: 0 ", ";\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  margin: ", " 0;\n\n  > span {\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    margin: 0 ", ";\n  }\n"])), rem(10), textColor, rem(14), fonts.weight.bold, rem(4));
export var SubscribeButton = styled(SubscribeButtonComponent)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  right: ", ";\n  top: ", ";\n"], ["\n  position: absolute;\n  right: ", ";\n  top: ", ";\n"])), rem(20), rem(20));
export var Skeleton = styled(SkeletonComponent)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
