var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { PageWrapper } from 'components';
import { makeSelectIsLoggedIn } from 'containers/Auth/selectors';
import { fetchContributorUserAccountRequest, fetchContributorUserContributionsRequest, fetchContributorUserFavoriteRequest, fetchContributorUserProfileRequest, } from 'containers/Contributors/actions';
import { makeSelectContributorUser } from 'containers/Contributors/selectors/contributorUserAccountSelectors';
import { makeContributorUserContributions } from 'containers/Contributors/selectors/contributorUserContributionsSelectors';
import { makeContributorUserFavorites } from 'containers/Contributors/selectors/contributorUserFavoritesSelectors';
import { makeSelectUserProfileContributionStats, makeSelectUserProfileLoading, makeSelectUserProfilePointsSerie, makeSelectUserProfileQuickBadges, makeSelectUserProfileTotalFollowed, makeSelectUserProfileTotalFollowers, makeSelectUserProfileTotalPoints, } from 'containers/Contributors/selectors/userProfileSelectors';
import { GlobalScoreboardCard } from 'containers/GlobalScoreboard';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { Achievements, Activities, MobileBackground, MoreAbout, NextAchievements, UserProfile, } from 'containers/Profile';
import { fetchMyProfileRequest } from 'containers/Profile/actions';
import makeMyProfile from 'containers/Profile/selectors/myProfileSelectors';
import { paths } from 'containers/RoutesProvider/routes';
import { subscribeUserRequest } from 'containers/Subscribers/store/subscribe/actions';
import { makeSelectSubscribeLoading } from 'containers/Subscribers/store/subscribe/selectors';
import { unsubscribeUserRequest } from 'containers/Subscribers/store/unsubscribe/actions';
import { makeSelectUnsubscribeLoading } from 'containers/Subscribers/store/unsubscribe/selectors';
import { useAction, useSelector } from 'hooks';
import replace from 'lodash/replace';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Container, Content, RightContent } from './styles';
var ContributorUserPage = function () {
    /** Component States */
    var _a = __read(useState(false), 2), isFollowed = _a[0], setIsFollowed = _a[1];
    var params = useParams();
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var history = useHistory();
    var formatMessage = useIntl().formatMessage;
    /** Selectors */
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var locale = useSelector(makeSelectLocale());
    var user = useSelector(makeSelectContributorUser());
    var contributions = useSelector(makeContributorUserContributions());
    var totalPoints = useSelector(makeSelectUserProfileTotalPoints());
    var favorites = useSelector(makeContributorUserFavorites());
    var userProfileLoading = useSelector(makeSelectUserProfileLoading());
    var loggedUserProfileData = useSelector(makeMyProfile());
    var followingCount = useSelector(makeSelectUserProfileTotalFollowed());
    var followersCount = useSelector(makeSelectUserProfileTotalFollowers());
    var unsubscribeLoading = useSelector(makeSelectUnsubscribeLoading());
    var subscribeLoading = useSelector(makeSelectSubscribeLoading());
    var series = useSelector(makeSelectUserProfilePointsSerie());
    var contributionsStats = useSelector(makeSelectUserProfileContributionStats());
    var quickBadges = useSelector(makeSelectUserProfileQuickBadges());
    var userSlug = (user === null || user === void 0 ? void 0 : user.slug) || '';
    var subscriptionsLink = replace(paths.subscribersProfilePage, ':slug', userSlug);
    /** Fetches */
    var loadLoggedUserProfileData = useAction(fetchMyProfileRequest);
    var fetchContributions = useAction(function (payload) {
        return fetchContributorUserContributionsRequest(payload);
    });
    var fetchFavorites = useAction(function (payload) {
        return fetchContributorUserFavoriteRequest(payload);
    });
    var fetchContributorUserProfileCallback = useAction(function (payload) {
        return fetchContributorUserProfileRequest(payload);
    });
    var fetchContributorUserAccountCallback = useAction(function (payload) {
        return fetchContributorUserAccountRequest(payload);
    });
    var followUserRequestCallback = useAction(function (payload) {
        return subscribeUserRequest(payload);
    });
    var unFollowUserRequestCallback = useAction(function (payload) { return unsubscribeUserRequest(payload); });
    /** Effects */
    useEffect(function () {
        if (isAuthenticated && !loggedUserProfileData.requested) {
            loadLoggedUserProfileData();
        }
    }, []);
    useEffect(function () {
        fetchContributorUserProfileCallback(params.userSlug);
        fetchContributorUserAccountCallback(params.userSlug);
    }, [params.userSlug]);
    useEffect(function () {
        if (unsubscribeLoading || subscribeLoading)
            return;
        fetchContributorUserProfileCallback(params.userSlug);
    }, [params.userSlug, isFollowed, unsubscribeLoading, subscribeLoading]);
    useEffect(function () {
        if (user) {
            setIsFollowed(user.isFollowed);
        }
    }, [user]);
    var toggleFollow = useCallback(function () {
        if (user) {
            if (!isAuthenticated) {
                history.push(paths.signInPage);
                return;
            }
            if (isFollowed) {
                unFollowUserRequestCallback({ from: 'profile', id: user.id });
                setIsFollowed(false);
            }
            else {
                followUserRequestCallback({ from: 'profile', id: user.id });
                setIsFollowed(true);
            }
        }
    }, [user, isFollowed]);
    /** More About - Contributions and Favorites */
    function loadFavorites(pagination) {
        fetchFavorites({ userSlug: params.userSlug, pagination: pagination });
    }
    function loadContributions(pagination) {
        fetchContributions({ userSlug: params.userSlug, pagination: pagination });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.userPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({ id: 'pages.userPage.description' }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.userPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({ id: 'pages.userPage.description' }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(Container, null,
                React.createElement(Content, null,
                    React.createElement(Achievements, { handleToggleSubscribe: toggleFollow, isSubscribed: isFollowed, showSubscribeButton: true, loadingUser: userProfileLoading, subscribeButtonLoading: unsubscribeLoading || subscribeLoading },
                        (isMobile || isTabletOrMobile) && React.createElement(MobileBackground, null),
                        React.createElement(UserProfile, { user: user, followingCount: followingCount, followersCount: followersCount, subscriptionsLink: subscriptionsLink + "?tab=subscriptions", subscribersLink: subscriptionsLink + "?tab=subscribers", contributionsStats: contributionsStats, badges: quickBadges, showSubscribeButton: true, isFollowed: isFollowed, handleToggleFollow: toggleFollow, totalPoints: totalPoints, showSendMessageButton: true, loading: userProfileLoading }),
                        !(isMobile || isTabletOrMobile) && (React.createElement(NextAchievements, { totalPoints: totalPoints || 0, contributionsStats: contributionsStats })),
                        React.createElement(Activities, { series: series })),
                    React.createElement(MoreAbout, { perPage: isMobile || isTabletOrMobile ? 4 : 10, contributions: contributions, favorites: favorites, loadFavorites: loadFavorites, loadContributions: loadContributions })),
                React.createElement(RightContent, null,
                    React.createElement(GlobalScoreboardCard, null))))));
};
export default ContributorUserPage;
