var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal5000Mobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-3", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/3", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-19", transform: "translate(0.000000, -0.645833)" },
                        React.createElement("polygon", { id: "Fill-1", fill: "#EF8E1B", points: "4.77310213 18.2619681 4.77310213 31.362766 12.9437404 26.934273 21.1138255 31.362766 21.1138255 18.2619681" }),
                        React.createElement("polygon", { id: "Fill-2", fill: "#E08119", points: "8.8584766 18.2619681 8.8584766 29.1487943 12.9437957 26.934273 17.0291149 29.1487943 17.0291149 18.2619681" }),
                        React.createElement("polygon", { id: "Fill-3-Copy", fillOpacity: "0.1", fill: "#000000", points: "10.3174085 2.40524823 7.18357872 1.95234043 5.58540851 4.66868794 2.5633234 5.61187943 2.31106809 8.74925532 -0.000165957447 10.9016667 1.14217447 13.836773 -0.000165957447 16.7724291 2.31106809 18.9248404 2.5633234 22.0616667 5.58540851 23.0048582 7.18357872 25.722305 10.3174085 25.2688475 12.9434085 27.0282624 15.5699617 25.2688475 18.7043447 25.722305 20.3019617 23.0048582 23.3240468 22.0616667 23.5768553 18.9248404 25.886983 16.7729787 25.886983 16.7707801 24.7451957 13.836773 25.886983 10.9033156 25.886983 10.9016667 23.5768553 8.74925532 23.3240468 5.61187943 20.3019617 4.66868794 18.7043447 1.95234043 15.5699617 2.40524823 12.9434085 0.645833333" }),
                        React.createElement("polygon", { id: "Fill-3", fill: "#F7D131", points: "10.3174085 1.75990957 7.18357872 1.30700177 5.58540851 4.02334929 2.5633234 4.96654078 2.31106809 8.10391667 -0.000165957447 10.256328 1.14217447 13.1914344 -0.000165957447 16.1270904 2.31106809 18.2795018 2.5633234 21.416328 5.58540851 22.3595195 7.18357872 25.0769663 10.3174085 24.6235089 12.9434085 26.3829238 15.5699617 24.6235089 18.7043447 25.0769663 20.3019617 22.3595195 23.3240468 21.416328 23.5768553 18.2795018 25.886983 16.1276401 25.886983 16.1254415 24.7451957 13.1914344 25.886983 10.257977 25.886983 10.256328 23.5768553 8.10391667 23.3240468 4.96654078 20.3019617 4.02334929 18.7043447 1.30700177 15.5699617 1.75990957 12.9434085 0.000494680851" }),
                        React.createElement("g", { id: "Group-18", transform: "translate(2.212766, 2.781041)" },
                            React.createElement("path", { d: "M19.9946638,10.4106684 C19.9946638,15.493789 15.846834,19.6144805 10.7309191,19.6144805 C5.61445106,19.6144805 1.46717447,15.493789 1.46717447,10.4106684 C1.46717447,5.32699823 5.61445106,1.20630674 10.7309191,1.20630674 C15.846834,1.20630674 19.9946638,5.32699823 19.9946638,10.4106684", id: "Fill-4", fill: "#F8E831" }),
                            React.createElement("path", { d: "M10.7305872,18.5748262 C6.33769362,18.5748262 2.7635234,15.024117 2.7635234,10.6588333 C2.7635234,6.29409929 6.33769362,2.74229078 10.7305872,2.74229078 C15.124034,2.74229078 18.6982043,6.29409929 18.6982043,10.6588333 C18.6982043,15.024117 15.124034,18.5748262 10.7305872,18.5748262 M10.7305872,1.04608511 C5.39560851,1.04608511 1.05582128,5.35860284 1.05582128,10.6588333 C1.05582128,15.9596135 5.39560851,20.2715816 10.7305872,20.2715816 C16.065566,20.2715816 20.4053532,15.9596135 20.4053532,10.6588333 C20.4053532,5.35860284 16.065566,1.04608511 10.7305872,1.04608511", id: "Fill-6", fill: "#F7D131" }),
                            React.createElement("path", { d: "M10.7305872,18.9833227 C5.97314043,18.9833227 2.10245957,15.1380035 2.10245957,10.4105035 C2.10245957,5.68410284 5.97314043,1.8376844 10.7305872,1.8376844 C15.4885872,1.8376844 19.3592681,5.68410284 19.3592681,10.4105035 C19.3592681,15.1380035 15.4885872,18.9833227 10.7305872,18.9833227 M10.7305872,0.000769503546 C4.95360851,0.000769503546 0.253693617,4.67110638 0.253693617,10.4105035 C0.253693617,16.1515496 4.95360851,20.8202376 10.7305872,20.8202376 C16.5081191,20.8202376 21.208034,16.1515496 21.208034,10.4105035 C21.208034,4.67110638 16.5081191,0.000769503546 10.7305872,0.000769503546", id: "Fill-8", fill: "#F8E831" }),
                            React.createElement("path", { d: "M7.61866383,12.017227 L7.69777021,11.8765177 C7.72211064,11.8347447 7.75751489,11.8006667 7.80840851,11.773734 C7.86483404,11.7435035 7.9262383,11.7286631 7.99594043,11.7286631 C8.11874894,11.7286631 8.17517447,11.7709858 8.21002553,11.8072624 C8.24542979,11.8446383 8.29189787,11.9100461 8.29189787,12.0551525 C8.29189787,12.2008085 8.24100426,12.2656667 8.20338723,12.3019433 C8.07172766,12.4272624 7.76028085,12.421766 7.4582383,12.2750106 C7.32602553,12.2101525 7.20155745,12.1233085 7.08704681,12.017227 L7.61866383,12.017227 Z M9.82700426,10.4518369 C9.62730213,10.2600106 9.3888766,10.1088582 9.11836596,10.0027766 C8.83236596,9.89064894 8.48606809,9.83018794 8.17130213,9.84392908 L8.17794043,9.7994078 L10.0316851,9.7994078 L10.0316851,7.82288298 L6.57202553,7.82288298 L5.76989787,12.017227 L6.52555745,12.017227 L5.59453617,13.0840887 L5.70406809,13.257227 C5.91981277,13.5980071 6.22904681,13.8623865 6.62236596,14.0443191 C7.00351489,14.2202057 7.43445106,14.3092482 7.90466383,14.3092482 C8.27142979,14.3092482 8.61385532,14.253734 8.92253617,14.1443546 C9.23342979,14.0344255 9.50670638,13.8783262 9.73462128,13.6799043 C9.96585532,13.4781844 10.1473021,13.2324929 10.2734298,12.9499752 C10.3995574,12.6691064 10.4631745,12.3563582 10.4631745,12.0227234 C10.4631745,11.7083262 10.4073021,11.4131667 10.297217,11.1471383 C10.1865787,10.8800106 10.028366,10.6464113 9.82700426,10.4518369 L9.82700426,10.4518369 Z", id: "Fill-10", fill: "#D8B627" }),
                            React.createElement("polygon", { id: "Fill-12", fill: "#D8B627", points: "14.0386723 10.3779096 15.7679489 8.19416844 13.3969702 8.19416844 12.654034 9.22255496 12.654034 6.51225355 10.5242468 6.51225355 10.5242468 13.080516 12.654034 13.080516 12.654034 11.6822181 13.5258638 13.080516 15.8669702 13.080516" }),
                            React.createElement("path", { d: "M8.31607234,9.56850177 C8.60373191,9.56850177 8.86649787,9.61522163 9.10437021,9.70866135 C9.34224255,9.80210106 9.54858298,9.93236702 9.72228511,10.1000089 C9.89654043,10.2676507 10.0326255,10.4688209 10.1288809,10.7024202 C10.2256894,10.9354699 10.2743702,11.1927039 10.2743702,11.473023 C10.2743702,11.7698316 10.2190511,12.041906 10.1084128,12.2886968 C9.99777447,12.5360372 9.84122128,12.7476507 9.63985957,12.9235372 C9.43739149,13.0999734 9.19675319,13.2373848 8.91739149,13.3357713 C8.63858298,13.4347074 8.32990213,13.4847252 7.99245532,13.4847252 C7.56096596,13.4847252 7.17207234,13.404477 6.82688085,13.2450798 C6.48113617,13.0856826 6.21394468,12.8575798 6.02585957,12.5607713 L6.8888383,11.5719592 C7.04926383,11.7423493 7.22739149,11.8753635 7.42432766,11.9715514 C7.62015745,12.0677394 7.81266809,12.1161082 8.00075319,12.1161082 C8.20543404,12.1161082 8.36585957,12.0611436 8.48202979,11.9512145 C8.5982,11.8412855 8.65628511,11.6923316 8.65628511,11.5054521 C8.65628511,11.3240691 8.60373191,11.1789628 8.49862553,11.068484 C8.39351915,10.958555 8.25522128,10.9041401 8.08373191,10.9041401 C7.9675617,10.9041401 7.86079574,10.930523 7.76398723,10.9821897 C7.66773191,11.034406 7.59415745,11.1047606 7.54437021,11.1927039 L6.19181702,11.1927039 L6.8888383,7.54800532 L9.84288085,7.54800532 L9.84288085,8.97488475 L8.02564681,8.97488475 L7.93437021,9.60917553 C7.95649787,9.60367908 7.9869234,9.59873227 8.02564681,9.59323582 C8.05330638,9.58773936 8.09202979,9.58169326 8.14181702,9.57674645 C8.19160426,9.57125 8.24968936,9.56850177 8.31607234,9.56850177", id: "Fill-14", fill: "#F9F1B7" }),
                            React.createElement("polygon", { id: "Fill-16", fill: "#F9F1B7", points: "13.7677745 12.2560479 12.7803277 10.6730691 12.4650085 11.0193457 12.4650085 12.2560479 10.8884128 12.2560479 10.8884128 6.23743085 12.4650085 6.23743085 12.4650085 9.52705851 13.6267106 7.91934574 15.2862851 7.91934574 13.7843702 9.81562234 15.4356468 12.2560479" })))),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
