import produce from 'immer';
import { SUBSCRIBE_USER_FAILURE, SUBSCRIBE_USER_REQUEST, SUBSCRIBE_USER_SUCCESS, } from './constants';
export var subscribeUserInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
};
var subscribeUserReducer = produce(function (draft, action) {
    switch (action.type) {
        case SUBSCRIBE_USER_REQUEST:
            draft.loading = true;
            break;
        case SUBSCRIBE_USER_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case SUBSCRIBE_USER_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            break;
        default:
    }
}, subscribeUserInitialState);
export default subscribeUserReducer;
