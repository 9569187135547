import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { colors } from 'styles';
import { IconWrapper, SuggestionItemContainer, TextWrapper } from './styles';
export var SuggestionItem = function (_a) {
    var title = _a.title, subtitle = _a.subtitle;
    return (React.createElement(SuggestionItemContainer, null,
        React.createElement(IconWrapper, null,
            React.createElement(FaMapMarkerAlt, { size: 15, color: colors.space.tint700 })),
        React.createElement(TextWrapper, null,
            React.createElement("strong", null, title),
            React.createElement("span", null, subtitle))));
};
