var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { PageHeader } from 'components';
import { ArrowBack } from 'icons/mono';
import { rem } from 'polished';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { ChatConversation } from '../ChatConversation';
import { ChatList } from '../ChatList';
import conversation from '../mockData/person-conversation.json'; // delete after backend integration
import details from '../mockData/person-details.json'; // delete after backend integration
// import conversation from '../mockData/team-conversation.json'; // delete after backend integration
// import details from '../mockData/team-details.json'; // delete after backend integration
import { SettingsMenu } from '../SettingsMenu';
export var MobileChat = function () {
    var formatMessage = useIntl().formatMessage;
    var _a = __read(useState(true), 2), active = _a[0], setActive = _a[1];
    return (React.createElement(React.Fragment, null, active ? (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { sticky: true, title: formatMessage({ id: 'pages.mailSystemPage.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: function () { return setActive(false); }, extra: React.createElement(SettingsMenu, { isTeam: !!details.team }) }),
        React.createElement(ChatConversation, { details: details, conversation: conversation }))) : (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { sticky: true, title: formatMessage({ id: 'pages.mailSystemPage.title' }) }),
        React.createElement(ChatList, null)))));
};
