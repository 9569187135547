import { avatarImage1 } from 'assets/images';
import { Avatar, Spin } from 'components';
import { ArrowNext } from 'icons/mono';
import { rem } from 'polished';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { useSelector } from 'utils/hooks';
import { sections } from '../constants';
import { makeSelectLoading } from '../selectors';
import { LoadableDescription } from './LoadableDescription';
import messages from './messages';
import { AvatarOverlay, AvatarWrapper, Container, Description, ListItem, ListItemContent, MainTitle, SubTitle, Title, } from './styles';
export var EditOptions = function (_a) {
    var profile = _a.profile, onSelectSection = _a.onSelectSection, userAvatar = _a.userAvatar;
    var _b, _c;
    var formatMessage = useIntl().formatMessage;
    var isLoading = useSelector(makeSelectLoading());
    var profileMessageRef = useRef();
    var formatProfileMessage = function () {
        var element = profileMessageRef.current;
        if (!element)
            return;
        if (element.offsetWidth < element.scrollWidth) {
            element.innerText = "\"" + element.innerText;
            return;
        }
        element.innerText = "\"" + element.innerText + "\"";
    };
    useEffect(function () {
        formatProfileMessage();
    }, [profile === null || profile === void 0 ? void 0 : profile.message]);
    return (React.createElement(Container, null, profile && (React.createElement(React.Fragment, null,
        React.createElement(ListItem, null,
            React.createElement(ListItemContent, null,
                React.createElement(MainTitle, null, formatMessage(messages.identification)),
                React.createElement(Description, { style: { whiteSpace: 'normal' } }, formatMessage(messages.identificationMessage)))),
        React.createElement(ListItem, { onClick: function () { return onSelectSection(sections.photo); } },
            React.createElement(ListItemContent, null,
                React.createElement(Title, { style: { marginBottom: rem(4) } }, formatMessage(messages.editPhoto)),
                React.createElement(Description, { style: { whiteSpace: 'normal' } }, formatMessage(messages.editPhotoMessage))),
            React.createElement(AvatarWrapper, null,
                !isLoading && (React.createElement(AvatarOverlay, null,
                    React.createElement("b", null, "Edit"))),
                React.createElement(Spin, { spinning: isLoading },
                    React.createElement(Avatar, { size: "medium", src: (userAvatar === null || userAvatar === void 0 ? void 0 : userAvatar.url) || avatarImage1, alt: "Avatar 1" })))),
        React.createElement(ListItem, { onClick: function () { return onSelectSection(sections.name); } },
            React.createElement(ListItemContent, null,
                React.createElement(SubTitle, null, formatMessage(messages.name)),
                !profile.firstName && !profile.lastName ? (React.createElement(LoadableDescription, { rows: 2 },
                    "@",
                    profile.slug)) : (React.createElement(LoadableDescription, { rows: 2, removeWrapper: true },
                    React.createElement(Description, null,
                        profile.firstName,
                        " ",
                        profile.lastName),
                    React.createElement("small", null,
                        "(@",
                        profile.slug,
                        ")")))),
            React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 })),
        React.createElement(ListItem, { onClick: function () { return onSelectSection(sections.profileType); } },
            React.createElement(ListItemContent, null,
                React.createElement(SubTitle, null, "PROFILE TYPE"),
                React.createElement(LoadableDescription, { rows: 1 }, (_b = profile.profile) === null || _b === void 0 ? void 0 : _b.label)),
            React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 })),
        React.createElement(ListItem, { onClick: function () { return onSelectSection(sections.profileMessage); } },
            React.createElement(ListItemContent, null,
                React.createElement(SubTitle, null, "PROFILE MESSAGE"),
                React.createElement(LoadableDescription, { rows: 1, descriptionProps: { ref: profileMessageRef } }, profile.message)),
            React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 })),
        React.createElement(ListItem, { style: { cursor: 'initial' } },
            React.createElement(ListItemContent, null,
                React.createElement(Title, null, "Contact Information"),
                React.createElement(SubTitle, null, "EMAIL"),
                React.createElement(LoadableDescription, { rows: 1 }, profile.email))),
        React.createElement(ListItem, { onClick: function () { return onSelectSection(sections.telephone); } },
            React.createElement(ListItemContent, null,
                React.createElement(SubTitle, null, "TELEPHONE"),
                React.createElement(LoadableDescription, null, profile.phone)),
            React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 })),
        React.createElement(ListItem, { onClick: function () { return onSelectSection(sections.password); } },
            React.createElement(ListItemContent, null,
                React.createElement(SubTitle, null, "PASSWORD"),
                React.createElement(LoadableDescription, { rows: 1 }, "******")),
            React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 })),
        React.createElement(ListItem, { onClick: function () { return onSelectSection(sections.location); } },
            React.createElement(ListItemContent, null,
                React.createElement(Title, null, "Location"),
                React.createElement(SubTitle, null, "MY ADDRESS"),
                React.createElement(LoadableDescription, { rows: 1 }, (_c = profile.address) === null || _c === void 0 ? void 0 : _c.fullAddress)),
            React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 }))))));
};
