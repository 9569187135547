import { PageHeader, PageWrapper } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { usePush } from 'hooks';
import { Close } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { Container, Content, Tagline, Title } from './styles';
export var ConfirmAccountPage = function () {
    var formatMessage = useIntl().formatMessage;
    var goToSignIn = usePush("" + paths.signInPage);
    return (React.createElement(PageWrapper, null,
        React.createElement(PageHeader, { sticky: true, title: formatMessage({ id: 'pages.confirmAccountPage.title' }), extra: [
                React.createElement("div", { key: 1, className: "ant-page-header-close" },
                    React.createElement("button", { type: "button", onClick: goToSignIn },
                        React.createElement(Close, { size: rem(16), color: colors.space.tint600 }))),
            ] }),
        React.createElement(Container, null,
            React.createElement(Content, null,
                React.createElement(Title, null, formatMessage({ id: 'pages.confirmAccountPage.success' })),
                React.createElement(Tagline, null, formatMessage({
                    id: 'pages.confirmAccountPage.content',
                }))))));
};
export default memo(ConfirmAccountPage);
