var _a;
// @ts-nocheck
import includes from 'lodash/includes';
import keys from 'lodash/keys';
export var EN_LANGUAGE = 'en';
export var FR_LANGUAGE = 'fr';
export var ES_LANGUAGE = 'es';
export var IT_LANGUAGE = 'it';
export var DE_LANGUAGE = 'de';
export var locales = [
    EN_LANGUAGE,
    FR_LANGUAGE,
    ES_LANGUAGE,
    IT_LANGUAGE,
    DE_LANGUAGE,
];
export var messages = (_a = {},
    _a[EN_LANGUAGE] = EN_LANGUAGE,
    _a[FR_LANGUAGE] = FR_LANGUAGE,
    _a[ES_LANGUAGE] = ES_LANGUAGE,
    _a[IT_LANGUAGE] = IT_LANGUAGE,
    _a[DE_LANGUAGE] = DE_LANGUAGE,
    _a);
export var DEFAULT_APP_LOCALE = FR_LANGUAGE;
export var DEFAULT_BROWSER_LOCALE = process.env.BUILD_TARGET === 'client'
    ? (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage
    : DEFAULT_APP_LOCALE;
export var DEFAULT_USER_LOCALE = function (availableTranslations) {
    return includes(keys(availableTranslations), DEFAULT_BROWSER_LOCALE)
        ? DEFAULT_BROWSER_LOCALE
        : DEFAULT_APP_LOCALE;
};
