var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Call = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M29.6411 36C28.2465 36 26.2875 35.5112 23.3541 33.9238C19.7869 31.9863 17.0278 30.1975 13.4799 26.7702C10.0592 23.4591 8.3946 21.3154 6.06484 17.2092C3.43287 12.573 3.88153 10.1428 4.38306 9.10414C4.98033 7.86274 5.86194 7.12025 7.00146 6.3833C7.6487 5.97257 8.33364 5.62048 9.04759 5.3315C9.11903 5.30175 9.18547 5.27338 9.24477 5.24778C9.59842 5.09347 10.1342 4.86027 10.8129 5.10938C11.2659 5.27407 11.6703 5.61106 12.3033 6.21654C13.6014 7.45655 15.3753 10.2182 16.0297 11.5745C16.4691 12.4886 16.7599 13.092 16.7606 13.7687C16.7606 14.561 16.3491 15.172 15.8497 15.8315C15.7561 15.9554 15.6632 16.0737 15.5732 16.1886C15.0295 16.8805 14.9102 17.0805 14.9888 17.4376C15.1481 18.1551 16.3362 20.2913 18.2888 22.1783C20.2413 24.0653 22.3832 25.1434 23.1269 25.297C23.5113 25.3766 23.722 25.2562 24.4593 24.7109C24.565 24.6327 24.6736 24.5517 24.7872 24.4708C25.5488 23.922 26.1504 23.5338 26.9491 23.5338H26.9534C27.6485 23.5338 28.2437 23.8259 29.2296 24.3075C30.5155 24.9358 33.4526 26.6318 34.7407 27.8905C35.3673 28.5022 35.7166 28.8925 35.8874 29.3305C36.1446 29.9899 35.9024 30.5069 35.7445 30.8528C35.718 30.9103 35.6887 30.9732 35.658 31.0431C35.3573 31.7334 34.9916 32.3954 34.5657 33.0208C33.8062 34.121 33.0368 34.9728 31.7522 35.552C31.0926 35.8542 30.3707 36.0073 29.6411 36Z", fill: color })));
});
