import { toggleDarkMode, toggleDirection, toggleFontSize, } from 'containers/App/actions';
import { makeSelectDarkMode, makeSelectDirection, } from 'containers/App/selectors';
import { useAction, useSelector } from 'hooks';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { DarkModeIcon, FontSizeIcon, ReadingHandIcon } from '../Icons';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { Button, Container, CustomScroll } from './styles';
export var SettingsMenu = memo(function (_a) {
    var isVisible = _a.isVisible;
    var formatMessage = useIntl().formatMessage;
    var darkMode = useSelector(makeSelectDarkMode());
    var direction = useSelector(makeSelectDirection());
    var handleFontSize = useAction(toggleFontSize);
    var handleDarkMode = useAction(toggleDarkMode);
    var handleDirection = useAction(toggleDirection);
    var reading = direction ? 'left' : 'right';
    return (React.createElement(Container, { isVisible: isVisible, isRtl: direction },
        React.createElement(CustomScroll, { hideScrollbars: false },
            React.createElement(Button, { onClick: handleFontSize },
                React.createElement(FontSizeIcon, { title: formatMessage({
                        id: 'components.bottomMenu.submenu.fontSize',
                    }) })),
            React.createElement(Button, { onClick: handleDirection },
                React.createElement(ReadingHandIcon, { isActive: direction, title: formatMessage({
                        id: "components.bottomMenu.submenu." + reading + "Hand",
                    }) })),
            React.createElement(LanguageSwitcher, { title: formatMessage({
                    id: 'components.bottomMenu.submenu.defaultLanguage',
                }) }),
            React.createElement(Button, { onClick: handleDarkMode },
                React.createElement(DarkModeIcon, { isActive: darkMode, title: formatMessage({
                        id: 'components.bottomMenu.submenu.darkMode',
                    }) })))));
});
