import produce from 'immer';
import { ACTIVE_REVIEW, ADD_REVIEW_CLEAR_FAILURE, ADD_REVIEW_CLEAR_SUCCESS, ADD_REVIEW_FAILURE, ADD_REVIEW_REQUEST, ADD_REVIEW_SUCCESS, CLEAR_REPORTED, REPORT_REVIEW, REPORT_REVIEW_FAILURE, REPORT_REVIEW_SUCCESS, RESET_ADD_REVIEW_STATE, SET_REPORT_REVIEW_VISIBLE, } from './constants';
export var addReviewInitialState = {
    loading: false,
    hasError: false,
    hasSuccess: false,
    error: undefined,
    item: undefined,
    reportReviewLoading: false,
    reportReviewVisible: false,
    reported: false,
    reportError: undefined,
    activeReview: undefined,
};
var addReviewReducer = produce(function (draft, action) {
    switch (action.type) {
        case ADD_REVIEW_REQUEST:
            draft.loading = true;
            break;
        case ADD_REVIEW_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case ADD_REVIEW_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.hasSuccess = true;
            draft.item = action.payload;
            break;
        case ADD_REVIEW_CLEAR_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.hasSuccess = false;
            break;
        case ADD_REVIEW_CLEAR_FAILURE:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.hasSuccess = false;
            break;
        case RESET_ADD_REVIEW_STATE:
            draft.loading = addReviewInitialState.loading;
            draft.hasError = addReviewInitialState.hasError;
            draft.error = addReviewInitialState.error;
            draft.item = addReviewInitialState.item;
            draft.hasSuccess = false;
            break;
        case REPORT_REVIEW:
            draft.reportReviewLoading = true;
            break;
        case REPORT_REVIEW_SUCCESS:
            draft.reportReviewLoading = false;
            draft.reported = true;
            break;
        case REPORT_REVIEW_FAILURE:
            draft.reportReviewLoading = false;
            draft.reportError = action.payload;
            draft.reported = false;
            break;
        case CLEAR_REPORTED:
            draft.reported = false;
            draft.reportError = undefined;
            break;
        case SET_REPORT_REVIEW_VISIBLE:
            draft.reportReviewVisible = action.payload;
            break;
        case ACTIVE_REVIEW:
            draft.activeReview = action.payload;
            break;
        default:
    }
}, addReviewInitialState);
export default addReviewReducer;
