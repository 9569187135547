var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
var borderColor = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint900,
});
var desktopBackground = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n\n  ", ";\n"], ["\n  border-top: ", " solid ", ";\n\n  ",
    ";\n"])), rem(1), borderColor, media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-top: ", ";\n    border-radius: ", ";\n    border: ", " solid ", ";\n    background-color: ", ";\n  "], ["\n    margin-top: ", ";\n    border-radius: ", ";\n    border: ", " solid ", ";\n    background-color: ", ";\n  "])), rem(20), rem(10), rem(1), borderColor, desktopBackground));
export var Title = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"])), rem(16), fonts.weight.semibold, rem(20));
export var PaginationWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
