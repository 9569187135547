import { PageHeader, Spin } from 'components';
import { makeSelectorConversation, makeSelectorConversationLoading, makeSelectorConversationsLoading, } from 'containers/Chat/selectors';
import { useSelector } from 'hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import { ChatConversation } from '../ChatConversation';
import { ChatList } from '../ChatList';
import { SettingsMenu } from '../SettingsMenu';
import { DesktopView, MainContent, Sidebar } from './styles';
export var DesktopChat = function () {
    var formatMessage = useIntl().formatMessage;
    var conversationLoading = useSelector(makeSelectorConversationLoading());
    var conversationsLoading = useSelector(makeSelectorConversationsLoading());
    var conversation = useSelector(makeSelectorConversation());
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { sticky: true, title: formatMessage({ id: 'pages.mailSystemPage.title' }), extra: conversation ? React.createElement(SettingsMenu, { isTeam: !!conversation.team }) : null }),
        React.createElement(DesktopView, null,
            React.createElement(Sidebar, null,
                React.createElement(Spin, { spinning: conversationsLoading, style: { minHeight: 'calc(100vh - 200px)' } },
                    React.createElement(ChatList, null))),
            React.createElement(Spin, { spinning: conversationLoading },
                React.createElement(MainContent, null,
                    React.createElement(ChatConversation, null))))));
};
