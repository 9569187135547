import { CardHeader } from 'components';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { AchievementsContainer, Content, SubscribeButton } from './styles';
var Achievements = function (_a) {
    var children = _a.children, handleToggleSubscribe = _a.handleToggleSubscribe, isSubscribed = _a.isSubscribed, loadingUser = _a.loadingUser, showSubscribeButton = _a.showSubscribeButton, subscribeButtonLoading = _a.subscribeButtonLoading;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (React.createElement(AchievementsContainer, null,
        !(isMobile || isTabletOrMobile) && (React.createElement(CardHeader, { title: React.createElement(FormattedMessage, { id: "containers.profile.achievements.header" }) }, showSubscribeButton && (React.createElement(SubscribeButton, { size: "small", isSubscribed: isSubscribed, onClick: handleToggleSubscribe, disabled: loadingUser || subscribeButtonLoading })))),
        React.createElement(Content, null, children)));
};
export default memo(Achievements);
