import { createSelector } from 'reselect';
import { addReviewInitialState } from './reducer';
export var selectAddReviewDomain = function (state) {
    return state.addReview || addReviewInitialState;
};
export var makeSelectAddReviewLoading = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectAddReviewHasError = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectAddReviewHasSuccess = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var hasSuccess = _a.hasSuccess;
        return hasSuccess;
    });
};
export var makeSelectAddReviewError = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectAddReviewItem = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var item = _a.item;
        return item;
    });
};
export var makeAddReview = function () {
    return createSelector(selectAddReviewDomain, function (subState) { return subState; });
};
export var makeSelectorReportReviewLoading = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var reportReviewLoading = _a.reportReviewLoading;
        return reportReviewLoading;
    });
};
export var makeSelectorReported = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var reported = _a.reported;
        return reported;
    });
};
export var makeSelectAddReportReviewError = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var reportError = _a.reportError;
        return reportError;
    });
};
export var makeSelectorReportReviewVisible = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var reportReviewVisible = _a.reportReviewVisible;
        return reportReviewVisible;
    });
};
export var makeSelectorActiveReview = function () {
    return createSelector(selectAddReviewDomain, function (_a) {
        var activeReview = _a.activeReview;
        return activeReview;
    });
};
export default makeAddReview;
