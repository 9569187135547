var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
export var ModalContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Text = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  font-size: ", ";\n\n  &:not(:last-of-type) {\n    margin-bottom: ", ";\n  }\n"], ["\n  display: block;\n  font-size: ", ";\n\n  &:not(:last-of-type) {\n    margin-bottom: ", ";\n  }\n"])), rem(16), rem(8));
export var ButtonWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(24));
var templateObject_1, templateObject_2, templateObject_3;
