import produce from 'immer';
import { CLEAR_FILTER, GET_ACCESSIBILITY_FILTERS, GET_ACCESSIBILITY_FILTERS_SUCCESS, SET_ACCESSIBILITY_FILTERS, SET_BOOLEAN_FILTER, SET_FILTER_MOBILE_VISIBLE, SET_PLACES_WITHOUT_DETAILS, SET_SELECTED_CATEGORY, } from './contants';
export var initialState = {
    accessibilityFilters: [],
    loading: false,
    placeWithoutDetails: true,
    selectedAccessibilityFilters: [],
    filterMobileVisible: false,
    booleanFilter: false,
};
var filterPlacesReducer = produce(function (draft, action) {
    var _a;
    switch (action.type) {
        case GET_ACCESSIBILITY_FILTERS:
            draft.loading = true;
            break;
        case GET_ACCESSIBILITY_FILTERS_SUCCESS:
            draft.accessibilityFilters = action.payload;
            draft.loading = false;
            break;
        case SET_PLACES_WITHOUT_DETAILS:
            draft.placeWithoutDetails = action.payload;
            draft.selectedAccessibilityFilters = [];
            break;
        case SET_SELECTED_CATEGORY:
            draft.selectedCategory = action.payload;
            break;
        case SET_ACCESSIBILITY_FILTERS:
            draft.selectedAccessibilityFilters = action.payload;
            if ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.length) {
                draft.placeWithoutDetails = false;
            }
            break;
        case SET_FILTER_MOBILE_VISIBLE:
            draft.filterMobileVisible = action.payload;
            break;
        case SET_BOOLEAN_FILTER:
            draft.booleanFilter = action.payload;
            break;
        case CLEAR_FILTER:
            draft.booleanFilter = false;
            draft.selectedAccessibilityFilters = [];
            draft.selectedCategory = undefined;
            draft.placeWithoutDetails = false;
            break;
        default:
    }
}, initialState);
export default filterPlacesReducer;
