var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { worldwideLandscapes } from 'assets/svg';
import { rem, rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp, switchProp } from 'styled-tools';
import { colors } from 'styles';
var text = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
export var PodiumContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  padding: 0 ", ";\n  justify-content: center;\n\n  background-image: url(", ");\n  background-color: ", ";\n  background-blend-mode: overlay;\n  background-position: 0 100%;\n  background-repeat: no-repeat;\n  background-size: 100%;\n\n  ", "\n"], ["\n  display: flex;\n  padding: 0 ", ";\n  justify-content: center;\n\n  background-image: url(", ");\n  background-color: ", ";\n  background-blend-mode: overlay;\n  background-position: 0 100%;\n  background-repeat: no-repeat;\n  background-size: 100%;\n\n  ",
    "\n"])), rem(30), worldwideLandscapes, rgba(colors.base.white, 0.8), ifProp('dark', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), rgba(colors.carbon.tint600, 0.9))));
export var Place = styled(Link)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-decoration: none;\n\n  width: 33%;\n  max-width: 33%;\n\n  overflow: hidden;\n\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-decoration: none;\n\n  width: 33%;\n  max-width: 33%;\n\n  overflow: hidden;\n\n  ",
    "\n"])), function (_a) {
    var position = _a.position, hasFirstName = _a.hasFirstName;
    switch (position) {
        case 1:
            return hasFirstName
                ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n              padding-top: ", ";\n            "], ["\n              padding-top: ", ";\n            "])), rem(10)) : css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n              padding-top: ", ";\n            "], ["\n              padding-top: ", ";\n            "])), rem(25));
        case 2:
            return hasFirstName
                ? css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n              padding-top: ", ";\n            "], ["\n              padding-top: ", ";\n            "])), rem(25)) : css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n              padding-top: ", ";\n            "], ["\n              padding-top: ", ";\n            "])), rem(40));
        case 3:
            return hasFirstName
                ? css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n              padding-top: ", ";\n            "], ["\n              padding-top: ", ";\n            "])), rem(35)) : css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n              padding-top: ", ";\n            "], ["\n              padding-top: ", ";\n            "])), rem(50));
        default:
            return css(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
    }
});
export var UserName = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  overflow: hidden;\n  margin-top: ", ";\n  width: 80%;\n  display: block;\n\n  > strong {\n    color: ", ";\n    font-size: ", ";\n    text-align: center;\n\n    display: block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  > span {\n    color: ", ";\n    font-size: ", ";\n    text-align: center;\n\n    display: block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n"], ["\n  overflow: hidden;\n  margin-top: ", ";\n  width: 80%;\n  display: block;\n\n  > strong {\n    color: ", ";\n    font-size: ", ";\n    text-align: center;\n\n    display: block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  > span {\n    color: ", ";\n    font-size: ", ";\n    text-align: center;\n\n    display: block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n"])), rem(16), text, rem(16), text, rem(13));
export var UserPoints = styled.strong(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: ", ";\n  margin-bottom: ", ";\n  color: ", ";\n"], ["\n  font-size: ", ";\n  margin-bottom: ", ";\n  color: ", ";\n"])), rem(17), rem(16), text);
export var Box = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 80%;\n  border-radius: ", " ", " 0 0;\n\n  display: flex;\n  justify-content: center;\n\n  > strong {\n    color: ", ";\n    font-size: ", ";\n    padding-top: ", ";\n  }\n\n  ", ";\n"], ["\n  width: 80%;\n  border-radius: ", " ", " 0 0;\n\n  display: flex;\n  justify-content: center;\n\n  > strong {\n    color: ", ";\n    font-size: ", ";\n    padding-top: ", ";\n  }\n\n  ",
    ";\n"])), rem(20), rem(20), colors.base.white, rem(28), rem(16), switchProp('position', {
    1: css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      height: ", ";\n      background-color: ", ";\n    "], ["\n      height: ", ";\n      background-color: ", ";\n    "])), rem(135), colors.base.secondary),
    2: css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      height: ", ";\n      background-color: ", ";\n    "], ["\n      height: ", ";\n      background-color: ", ";\n    "])), rem(120), colors.base.primary),
    3: css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      height: ", ";\n      background-color: ", ";\n    "], ["\n      height: ", ";\n      background-color: ", ";\n    "])), rem(110), colors.base.warning),
}));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
