import { PageHeader, Spin } from 'components';
import { ArrowBack } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { colors } from 'styles';
import { Container, FieldsContainer } from './styles';
var Template = function (props) { return (React.createElement(Container, null,
    React.createElement(PageHeader, { title: props.pageTitle, backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: props.onBack, sticky: true }),
    React.createElement(Spin, { spinning: props.loading },
        React.createElement(FieldsContainer, null, props.children)))); };
Template.defaultProps = {
    loading: false,
};
export { Template };
