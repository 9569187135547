import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import React, { memo } from 'react';
import { HeaderWrapper, UserInfo, UserName } from './styles';
export var Header = memo(function (_a) {
    var data = _a.data;
    var _b, _c;
    return (React.createElement(HeaderWrapper, null, data.team ? (React.createElement(React.Fragment, null,
        React.createElement(Avatar, { src: ((_b = data.team.cover) === null || _b === void 0 ? void 0 : _b.urlThumb) || avatarImage1, alt: (data.name || data.slug) + "'s avatar", size: "tiny" }),
        React.createElement(UserInfo, null,
            React.createElement(UserName, null, data.name)))) : (React.createElement(React.Fragment, null,
        React.createElement(Avatar, { src: ((_c = data.targets[0].avatar) === null || _c === void 0 ? void 0 : _c.urlThumb) || avatarImage1, alt: (data.targets[0].pseudo || data.targets[0].slug) + "'s avatar", size: "tiny" }),
        React.createElement(UserInfo, null, data.targets[0].pseudo ? (React.createElement(UserName, null, "" + (data.targets[0].pseudo || ''))) : (React.createElement(UserName, null, "@" + data.targets[0].slug)))))));
});
