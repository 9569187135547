var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { EmailField } from 'components/EmailField';
import { PasswordField } from 'components/PasswordField';
import { Form } from 'formik';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
var SignInForm = function (_a) {
    var values = _a.values, status = _a.status, handleChange = _a.handleChange, handleBlur = _a.handleBlur, handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
        React.createElement(EmailField, { required: true, showLabel: true, name: "email", value: values.email, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting }),
        React.createElement(PasswordField, { required: true, showForgotLink: true, name: "password", value: values.password, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting }),
        (status === null || status === void 0 ? void 0 : status.api) && (React.createElement(ErrorMessage, null, formatMessage({ id: "containers.signInEmail." + (status === null || status === void 0 ? void 0 : status.api) }))),
        React.createElement(Button, { type: "submit", variant: "primary", style: { marginBottom: rem(20), marginTop: rem(20) }, full: true }, formatMessage({ id: 'containers.signInEmail.signInButton' }))));
};
export default SignInForm;
var ErrorMessage = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"])), colors.base.danger, rem(14), fonts.weight.regular, rem(6));
var templateObject_1;
