import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectChatStateDomain = function (state) { return state.chat || initialState; };
var makeSelectorConversations = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var items = _a.conversations.items;
        return items;
    });
};
var makeSelectorPaginationMetadata = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var _b = _a.conversations, currentPage = _b.currentPage, pages = _b.pages, total = _b.total;
        return ({
            currentPage: currentPage,
            pages: pages,
            total: total,
        });
    });
};
var makeSelectorConversation = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var conversation = _a.conversation;
        return conversation;
    });
};
var makeSelectorConversationsLoading = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var conversationsLoading = _a.conversationsLoading;
        return conversationsLoading;
    });
};
var makeSelectorConversationLoading = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var conversationLoading = _a.conversationLoading;
        return conversationLoading;
    });
};
var makeSelectorConversationOptionsLoading = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var conversationOptionsLoading = _a.conversationOptionsLoading;
        return conversationOptionsLoading;
    });
};
var makeSelectorMessages = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var messages = _a.messages;
        return messages;
    });
};
var makeSelectorHistory = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var history = _a.history;
        return history;
    });
};
var makeSelectorChatConnection = function () {
    return createSelector(selectChatStateDomain, function (_a) {
        var chatConnection = _a.chatConnection;
        return chatConnection;
    });
};
export { makeSelectorConversations, makeSelectorPaginationMetadata, makeSelectorConversation, makeSelectorConversationsLoading, makeSelectorConversationLoading, makeSelectorConversationOptionsLoading, makeSelectorMessages, makeSelectorHistory, makeSelectorChatConnection, };
