var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var contentTheme = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    border: 1px solid ", ";\n  "], ["\n    background: ", ";\n    border: 1px solid ", ";\n  "])), colors.base.white, colors.space.tint300),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: ", ";\n    border: 1px solid ", ";\n  "], ["\n    background: ", ";\n    border: 1px solid ", ";\n  "])), colors.carbon.tint700, colors.carbon.tint800),
});
var buttonTheme = theme('mode', {
    light: colors.space.tint800,
    dark: colors.base.white,
});
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  border-radius: ", ";\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", ";\n  width: ", ";\n"], ["\n  ", ";\n  border-radius: ", ";\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", ";\n  width: ", ";\n"])), contentTheme, rem(4), rem(5), rem(200));
export var Button = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 0;\n  padding: 0 ", ";\n\n  span {\n    flex: 0;\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1.2;\n    margin-left: ", ";\n    text-align: left;\n  }\n\n  img {\n    width: ", ";\n    margin-right: ", ";\n  }\n"], ["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 0;\n  padding: 0 ", ";\n\n  span {\n    flex: 0;\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1.2;\n    margin-left: ", ";\n    text-align: left;\n  }\n\n  img {\n    width: ", ";\n    margin-right: ", ";\n  }\n"])), buttonTheme, rem(12), rem(12), fonts.weight.semibold, rem(4), rem(22), rem(6));
export var ListButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: ", ";\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: ", " ", ";\n  transition: color 0.2s;\n  white-space: nowrap;\n  position: relative;\n\n  ", ";\n\n  & + button {\n    margin-top: ", ";\n  }\n\n  span {\n    margin-right: ", ";\n    pointer-events: none;\n  }\n\n  img {\n    width: ", ";\n    pointer-events: none;\n  }\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: ", ";\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: ", " ", ";\n  transition: color 0.2s;\n  white-space: nowrap;\n  position: relative;\n\n  ",
    ";\n\n  & + button {\n    margin-top: ", ";\n  }\n\n  span {\n    margin-right: ", ";\n    pointer-events: none;\n  }\n\n  img {\n    width: ", ";\n    pointer-events: none;\n  }\n\n  &:hover {\n    color: ", ";\n  }\n"])), buttonTheme, rem(13), fonts.weight.semibold, rem(8), rem(16), ifProp('isActive', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      &::before {\n        background: ", ";\n        border-bottom-right-radius: ", ";\n        border-top-right-radius: ", ";\n        content: '';\n        display: block;\n        height: 80%;\n        left: -1px;\n        position: absolute;\n        position: absolute;\n        width: 4px;\n      }\n    "], ["\n      &::before {\n        background: ", ";\n        border-bottom-right-radius: ", ";\n        border-top-right-radius: ", ";\n        content: '';\n        display: block;\n        height: 80%;\n        left: -1px;\n        position: absolute;\n        position: absolute;\n        width: 4px;\n      }\n    "])), colors.base.secondary, rem(1), rem(1))), rem(4), rem(8), rem(22), colors.base.primary);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
