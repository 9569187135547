import produce from 'immer';
import { v4 as uuid } from 'uuid';
import { CLEAR_ERROR, CLEAR_SUCCESS, EDIT_MY_PROFILE_ERROR, EDIT_MY_PROFILE_FIELDS, EDIT_MY_PROFILE_FIELDS_ERROR, EDIT_MY_PROFILE_FIELDS_SUCCESS, EDIT_MY_PROFILE_PASSWORD, EDIT_MY_PROFILE_PASSWORD_ERROR, EDIT_MY_PROFILE_PASSWORD_SUCCESS, EDIT_MY_PROFILE_REQUEST, EDIT_MY_PROFILE_SUCCESS, PHOTO_DELETE, PHOTO_DELETE_ERROR, PHOTO_DELETE_SUCCESS, PHOTO_UPLOAD, PHOTO_UPLOAD_ERROR, PHOTO_UPLOAD_SUCCESS, sections, SET_ACTIVE_AVATAR, SET_ACTIVE_SECTION, } from './constants';
import { SuccessFeedbackType, } from './types';
import { avatarToNumber, getCarouselMargin, getDefaultAvatarFileNameFromRenamedFile, } from './utils';
export var initialState = {
    profile: {
        email: '',
        firstName: '',
        lastName: '',
        message: '',
        pseudo: '',
        slug: '',
        phone: '',
        profileId: 0,
        googlePlaceId: '',
        profile: { label: '' },
        acceptsEmailcontact: false,
        acceptsNewsletter: false,
        acceptsLocalization: false,
    },
    activeSection: sections.options,
    carousel: {
        avatar: 2,
        margin: 0,
        loadedFromBack: false,
    },
    hasError: false,
    loading: false,
};
var editMyProfileReducer = produce(function (draft, action) {
    var _a;
    switch (action.type) {
        case EDIT_MY_PROFILE_REQUEST:
        case EDIT_MY_PROFILE_FIELDS:
        case EDIT_MY_PROFILE_PASSWORD:
        case PHOTO_UPLOAD:
        case PHOTO_DELETE:
            draft.loading = true;
            break;
        case EDIT_MY_PROFILE_SUCCESS: {
            draft.profile = action.payload;
            var foundAvatarName = getDefaultAvatarFileNameFromRenamedFile((_a = action.payload.avatar) === null || _a === void 0 ? void 0 : _a.filename);
            if (foundAvatarName) {
                var avatarNumber = avatarToNumber(foundAvatarName);
                if (avatarNumber) {
                    draft.carousel = {
                        avatar: avatarNumber,
                        margin: getCarouselMargin(avatarNumber),
                        loadedFromBack: true,
                    };
                }
            }
            draft.loading = false;
            break;
        }
        case EDIT_MY_PROFILE_FIELDS_SUCCESS:
            draft.loading = false;
            draft.success = {
                id: uuid(),
                type: action.payload,
            };
            break;
        case EDIT_MY_PROFILE_ERROR:
        case EDIT_MY_PROFILE_FIELDS_ERROR:
        case EDIT_MY_PROFILE_PASSWORD_ERROR:
        case PHOTO_DELETE_ERROR:
        case PHOTO_UPLOAD_ERROR:
            draft.hasError = true;
            draft.loading = false;
            draft.error = {
                id: uuid(),
                message: action.payload,
            };
            break;
        case PHOTO_UPLOAD_SUCCESS:
            draft.success = {
                id: uuid(),
                type: action.payload,
            };
            draft.carousel = initialState.carousel;
            draft.loading = false;
            break;
        case PHOTO_DELETE_SUCCESS:
            draft.success = {
                id: uuid(),
                type: SuccessFeedbackType.PhotoDelete,
            };
            draft.loading = false;
            break;
        case EDIT_MY_PROFILE_PASSWORD_SUCCESS:
            draft.success = {
                id: uuid(),
                type: SuccessFeedbackType.Password,
            };
            draft.loading = false;
            break;
        case CLEAR_SUCCESS:
            draft.success = undefined;
            break;
        case CLEAR_ERROR:
            draft.error = undefined;
            break;
        case SET_ACTIVE_SECTION:
            draft.activeSection = action.payload;
            break;
        case SET_ACTIVE_AVATAR:
            draft.carousel = {
                avatar: action.payload,
                margin: getCarouselMargin(action.payload),
                loadedFromBack: false,
            };
            break;
        default:
    }
}, initialState);
export default editMyProfileReducer;
