var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n  padding-bottom: 0;\n"], ["\n  padding: ", ";\n  padding-bottom: 0;\n"])), rem(20));
export var SafeArea = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: ", ";\n"], ["\n  padding-top: ", ";\n"])), rem(60));
export var LoadingWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 100vh;\n  max-height: calc(100vh - ", ");\n\n  ", ";\n"], ["\n  height: 100vh;\n  max-height: calc(100vh - ", ");\n\n  ",
    ";\n"])), rem(140), media.greaterThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    max-height: calc(100vh - ", ");\n  "], ["\n    max-height: calc(100vh - ", ");\n  "])), rem(80)));
export var ErrorMessage = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: ", ";\n\n  span {\n    width: 100%;\n    text-align: center;\n  }\n"], ["\n  padding: ", ";\n\n  span {\n    width: 100%;\n    text-align: center;\n  }\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
