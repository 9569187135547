import { PageWrapper } from 'components';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { ResendEmailActivation } from 'containers/ResendEmailActivation/index';
import { useSelector } from 'hooks';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
var ResendEmailActivationPage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.resendEmailActivationPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.resendEmailActivationPage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({
                    id: 'pages.resendEmailActivationPage.title',
                }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.resendEmailActivationPage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement("h1", null,
                React.createElement(FormattedMessage, { id: "pages.resendEmailActivationPage.title" })),
            React.createElement(ResendEmailActivation, null))));
};
export default ResendEmailActivationPage;
