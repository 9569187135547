var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Button, Checkbox, Input, Popover, Radio } from 'components';
import { useSelector } from 'hooks';
import { Alert } from 'icons/mono';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import set from 'lodash/set';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from 'styles';
import { makeSelectorLoading } from '../../selectors';
import { Template } from '../Template';
import { CriterionPhoto } from './CriterionPhoto';
import { ButtonWrapper, ChildContainer, CriteriaContainer, CriteriaLabel, CriteriaPopover, CriteriaTitle, PopoverTrigger, } from './styles';
export var EditCriteria = function (_a) {
    var onBack = _a.onBack, criterion = _a.criterion, onUpdate = _a.onUpdate, allCriteria = _a.allCriteria;
    var _b = __read(useState(), 2), tree = _b[0], setTree = _b[1];
    var _c = __read(useState([]), 2), formattedCriteria = _c[0], setFormattedCriteria = _c[1];
    var loading = useSelector(makeSelectorLoading());
    var getTree = function (criteria) {
        var createNodes = function (innerCriteria) {
            return map(innerCriteria, function (c) { return ({
                id: c.id,
                label: c.label,
                value: c.type === 'array' || c.type === 'enum'
                    ? true
                    : c.selected || c.value,
                type: c.type,
                children: createNodes(c.children),
            }); }) || [];
        };
        return createNodes(criteria);
    };
    var getFieldValue = function (path) { return get(tree, path + ".value"); };
    var fieldBooleanChange = function (path, value) {
        return setTree(set(cloneDeep(tree), path + ".value", !(value || getFieldValue(path))));
    };
    var fieldValueChange = function (path, value) {
        return setTree(set(cloneDeep(tree), path + ".value", value));
    };
    var fieldCheck = function (path, parentPath) {
        var newTree = cloneDeep(tree);
        map(get(tree, parentPath), function (_, index) {
            set(newTree, parentPath + "[" + index + "].value", false);
        });
        setTree(set(newTree, path + ".value", true));
    };
    var getPopover = function (description) { return (React.createElement(Popover, { content: React.createElement("div", null, description), trigger: "click", placement: "top", arrowPointAtCenter: true },
        React.createElement(PopoverTrigger, null,
            React.createElement(Alert, { size: rem(20), color: colors.space.tint600 })))); };
    var blockEvt = function (evt) {
        return evt.key.toUpperCase() === 'E' && evt.preventDefault();
    };
    var formatCriteria = function (criteria, path) {
        return map(criteria, function (c, index) {
            switch (c.type) {
                case 'array':
                case 'enum':
                    return (React.createElement(CriteriaContainer, { key: c.id },
                        React.createElement(CriteriaPopover, null,
                            React.createElement(CriteriaTitle, null, c.label),
                            c.description && getPopover(c.description)),
                        React.createElement("div", null, formatCriteria(c.children, path + "[" + index + "].children"))));
                case 'boolean':
                    return (React.createElement(CriteriaContainer, { key: c.id },
                        React.createElement("div", null,
                            React.createElement(Checkbox, { checked: getFieldValue(path + "[" + index + "]"), onChange: function () { return fieldBooleanChange(path + "[" + index + "]"); } },
                                React.createElement(CriteriaLabel, null,
                                    React.createElement("span", null, c.label),
                                    c.description && getPopover(c.description)))),
                        getFieldValue(path + "[" + index + "]") && c.children.length > 0 && (React.createElement(ChildContainer, null, formatCriteria(c.children, path + "[" + index + "].children")))));
                case 'option':
                    return (React.createElement(CriteriaContainer, { key: c.id },
                        React.createElement(Radio, { checked: getFieldValue(path + "[" + index + "]"), onChange: function () { return fieldCheck(path + "[" + index + "]", path); } },
                            React.createElement(CriteriaLabel, null,
                                React.createElement("span", { style: { marginTop: rem(-1) } }, c.label),
                                c.description && getPopover(c.description))),
                        getFieldValue(path + "[" + index + "]") && c.children.length > 0 && (React.createElement(ChildContainer, null, formatCriteria(c.children, path + "[" + index + "].children")))));
                case 'integer':
                case 'float':
                case 'string':
                    return (React.createElement(CriteriaContainer, { key: c.id },
                        React.createElement(CriteriaTitle, null, c.label),
                        React.createElement("div", null,
                            React.createElement(Input, { type: c.type === 'string' ? 'text' : 'number', onChange: function (e) {
                                    return fieldValueChange(path + "[" + index + "]", e.target.value);
                                }, value: getFieldValue(path + "[" + index + "]"), onKeyDown: c.type !== 'string' ? blockEvt : noop }))));
                default:
                    return React.createElement("div", { key: c.id }, c.label);
            }
        });
    };
    useEffect(function () {
        setFormattedCriteria(formatCriteria(criterion === null || criterion === void 0 ? void 0 : criterion.children, ''));
    }, [tree]);
    useEffect(function () {
        setTree(getTree(criterion === null || criterion === void 0 ? void 0 : criterion.children));
    }, [criterion === null || criterion === void 0 ? void 0 : criterion.children]);
    var updateValues = function () {
        var valuesToUpdate = [];
        var addValues = function (localTree) {
            map(localTree, function (t) {
                if (t.type !== 'array' && t.type !== 'enum' && t.value) {
                    if (typeof t.value === 'boolean') {
                        valuesToUpdate.push({ criterionId: t.id });
                    }
                    if (Number.isNaN(Number(t.value))) {
                        valuesToUpdate.push({ criterionId: t.id, value: Number(t.value) });
                    }
                    if (typeof t.value === 'string') {
                        valuesToUpdate.push({ criterionId: t.id, value: t.value });
                    }
                }
                if (t.value) {
                    addValues(t.children);
                }
            });
        };
        var currentValues = getTree(filter(allCriteria, function (c) { return c.id !== criterion.id; }));
        addValues(__spread(currentValues, tree));
        onUpdate({ criteria: valuesToUpdate });
    };
    return (React.createElement(Template, { pageTitle: criterion.label, onBack: onBack, loading: loading },
        React.createElement("div", null, formattedCriteria),
        criterion.picture && React.createElement(CriterionPhoto, { criterionId: criterion.id }),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { onClick: updateValues },
                React.createElement(FormattedMessage, { id: "containers.placeDetails.saveChangesButton" })))));
};
