import { createSelector } from 'reselect';
import { placesAroundInitialState } from './reducer';
export var selectPlacesAroundDomain = function (state) {
    return state.placesAround || placesAroundInitialState;
};
export var makeSelectPlacesAroundLoading = function () {
    return createSelector(selectPlacesAroundDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectPlacesAroundHasError = function () {
    return createSelector(selectPlacesAroundDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectPlacesAroundError = function () {
    return createSelector(selectPlacesAroundDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectPlacesAroundPages = function () {
    return createSelector(selectPlacesAroundDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectPlacesAroundCurrentPage = function () {
    return createSelector(selectPlacesAroundDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectPlacesAroundTotal = function () {
    return createSelector(selectPlacesAroundDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectPlacesAroundItems = function () {
    return createSelector(selectPlacesAroundDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makePlacesAround = function () {
    return createSelector(selectPlacesAroundDomain, function (subState) { return subState; });
};
export default makePlacesAround;
