var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var background = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint200,
});
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var activeBorder = theme('mode', {
    light: colors.space.tint400,
    dark: colors.space.tint700,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var textColor = theme('mode', {
    light: colors.space.tint700,
    dark: colors.space.tint500,
});
export var UserCardWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  ", "\n"], ["\n  border-bottom: ", " solid ", ";\n  padding: ", ";\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  ",
    "\n"])), rem(1), border, rem(16), ifProp('isActive', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
    "\n    "])), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        background: ", ";\n        border-bottom: ", " solid ", ";\n      "], ["\n        background: ", ";\n        border-bottom: ", " solid ", ";\n      "])), background, rem(1), activeBorder))));
export var UserInfo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n  overflow: hidden;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", ";\n  overflow: hidden;\n"])), rem(16));
export var UserName = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n"])), color, rem(14), fonts.weight.semibold, rem(4));
export var MessagesBadge = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  display: flex;\n  flex-shrink: 0;\n  margin-left: auto;\n"], ["\n  background: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  display: flex;\n  flex-shrink: 0;\n  margin-left: auto;\n"])), colors.base.primary, rem(14), rem(14));
export var LastMessageDate = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-left: auto;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-left: auto;\n"])), colors.space.tint600, rem(12));
export var LastMessageText = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), textColor, rem(12));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
