var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { avatarImage1 } from 'assets/images';
import { Avatar, Button, Ellipsis, Modal, Skeleton } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { Medal200Desktop } from 'icons/medal';
import { Edit, Send } from 'icons/mono';
import { rem } from 'polished';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import PointsInfo from '../PointsInfo';
import Medals from './Medals';
import { Container, Content, Fullname, Message, Slug, UserData, UserName, UserPoints, } from './styles';
import SubscriptionButtons from './SubscriptionButtons';
var UserInfo = function (_a) {
    var user = _a.user, subscriptionsLink = _a.subscriptionsLink, subscribersLink = _a.subscribersLink, _b = _a.subscriptionsSize, subscriptionsSize = _b === void 0 ? 0 : _b, _c = _a.subscribersSize, subscribersSize = _c === void 0 ? 0 : _c, _d = _a.totalPoints, totalPoints = _d === void 0 ? 0 : _d, contributionsStats = _a.contributionsStats, showEditMyProfileButton = _a.showEditMyProfileButton, showSendMessageButton = _a.showSendMessageButton, loading = _a.loading;
    var _e;
    var history = useHistory();
    var location = useLocation();
    var userSlug = (user === null || user === void 0 ? void 0 : user.firstName) ? "(@" + (user === null || user === void 0 ? void 0 : user.slug) + ")" : "@" + (user === null || user === void 0 ? void 0 : user.slug);
    var _f = __read(useState(false), 2), modalVisible = _f[0], setModalVisible = _f[1];
    var handleEditMyProfile = function () {
        return history.push(paths.editProfilePage, { from: location.pathname });
    };
    var handleSendMessage = function () {
        // eslint-disable-next-line no-console
        console.log('TODO: redirect to send a message page');
        setModalVisible(true);
    };
    return (React.createElement(Container, null,
        React.createElement(Skeleton, { loading: loading, active: true, avatar: { size: 78, shape: 'square' } },
            React.createElement(Content, null,
                React.createElement(Avatar, { src: ((_e = user === null || user === void 0 ? void 0 : user.avatar) === null || _e === void 0 ? void 0 : _e.urlThumb) || avatarImage1, alt: "User profile image", size: "large" }),
                React.createElement(UserData, null,
                    React.createElement(UserName, null,
                        (user === null || user === void 0 ? void 0 : user.firstName) && (React.createElement(Fullname, null, ((user === null || user === void 0 ? void 0 : user.firstName) || '') + " " + ((user === null || user === void 0 ? void 0 : user.lastName) || ''))),
                        React.createElement(Slug, { hasNotFirstName: !(user === null || user === void 0 ? void 0 : user.firstName) }, userSlug)),
                    React.createElement(SubscriptionButtons, { subscribersLink: subscribersLink, subscriptionsLink: subscriptionsLink, subscribersSize: subscribersSize, subscriptionsSize: subscriptionsSize }),
                    (user === null || user === void 0 ? void 0 : user.message) && (React.createElement(Message, null,
                        React.createElement("i", null,
                            React.createElement(Ellipsis, { expandable: true, canHide: true }, "\"" + (user === null || user === void 0 ? void 0 : user.message) + "\"" || '')))),
                    React.createElement(UserPoints, null,
                        React.createElement(Medal200Desktop, { size: rem(22) }),
                        React.createElement("span", null,
                            totalPoints,
                            ' ',
                            React.createElement(FormattedMessage, { id: "containers.profile.user.points" })),
                        React.createElement(PointsInfo, null)),
                    showEditMyProfileButton && (React.createElement(Button, { shape: "pill", size: "small", onClick: handleEditMyProfile, icon: React.createElement(Edit, { size: 16, color: "white" }) },
                        React.createElement(FormattedMessage, { id: "containers.profile.user.editMyProfile.desktop" }))),
                    showSendMessageButton && (React.createElement(Button, { shape: "pill", size: "small", onClick: handleSendMessage, icon: React.createElement(Send, { size: 16, color: "white" }) },
                        React.createElement(FormattedMessage, { id: "containers.profile.user.sendMessage" }))),
                    React.createElement(Modal, { visible: modalVisible, onOk: function () { return setModalVisible(false); }, cancelButtonProps: { style: { display: 'none' } }, closable: false },
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "containers.profile.user.chatModalText" })))),
                React.createElement(Medals, { totalPoints: totalPoints, contributionsStats: contributionsStats })))));
};
export default UserInfo;
