import { Title } from 'containers/Home';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { CardListDesktop, CardListMobile } from './CardList';
import { Container, StyledSection } from './styles';
export var Contribute = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (React.createElement(StyledSection, { background: "secondary" },
        React.createElement(Title, { underline: "warning" }, formatMessage({ id: 'containers.contribute.title' })),
        React.createElement(Container, null, isMobile || isTabletOrMobile ? (React.createElement(CardListMobile, null)) : (React.createElement(CardListDesktop, null)))));
});
