import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { formatDistance } from 'date-fns';
import * as locales from 'date-fns/locale';
import React from 'react';
import { useSelector } from 'utils/hooks';
import { LastMessageDate, LastMessageText, MessagesBadge, UserCardWrapper, UserInfo, UserName, } from './styles';
export var UserCard = function (_a) {
    var conversation = _a.conversation, _b = _a.isActive, isActive = _b === void 0 ? false : _b, onSelect = _a.onSelect;
    var _c, _d, _e, _f, _g, _h, _j, _k;
    var currentLocale = useSelector(makeSelectLocale());
    var getLastMessageTime = function (eventDate) {
        return formatDistance(new Date(), new Date(eventDate), {
            includeSeconds: true,
            locale: locales[currentLocale],
        });
    };
    return (React.createElement(UserCardWrapper, { isActive: isActive, onClick: onSelect }, conversation.team ? (React.createElement(React.Fragment, null,
        React.createElement(Avatar, { src: ((_c = conversation.team.cover) === null || _c === void 0 ? void 0 : _c.urlThumb) || avatarImage1, alt: (conversation.name || conversation.slug) + "'s avatar", size: "tiny" }),
        React.createElement(UserInfo, null,
            React.createElement(UserName, null, conversation.name),
            ((_d = conversation === null || conversation === void 0 ? void 0 : conversation.lastMessage) === null || _d === void 0 ? void 0 : _d.me) ? (React.createElement(LastMessageText, null, "Me: " + ((_e = conversation === null || conversation === void 0 ? void 0 : conversation.lastMessage) === null || _e === void 0 ? void 0 : _e.body))) : (React.createElement(LastMessageText, null, (((_f = conversation.lastMessage) === null || _f === void 0 ? void 0 : _f.source.pseudo) || ((_g = conversation.lastMessage) === null || _g === void 0 ? void 0 : _g.source.slug)) + ": " + ((_h = conversation.lastMessage) === null || _h === void 0 ? void 0 : _h.body)))),
        conversation.hasUnreadMessage ? (React.createElement(MessagesBadge, null)) : (React.createElement(LastMessageDate, null, conversation.lastMessage &&
            getLastMessageTime(conversation.lastMessage.created))))) : (React.createElement(React.Fragment, null,
        React.createElement(Avatar, { src: ((_j = conversation.targets[0].avatar) === null || _j === void 0 ? void 0 : _j.urlThumb) || avatarImage1, alt: (conversation.targets[0].pseudo || conversation.targets[0].slug) + "'s avatar", size: "tiny" }),
        React.createElement(UserInfo, null,
            conversation.targets[0].pseudo ? (React.createElement(UserName, null, "" + (conversation.targets[0].pseudo || ''))) : (React.createElement(UserName, null, "@" + conversation.targets[0].slug)),
            React.createElement(LastMessageText, null, (_k = conversation.lastMessage) === null || _k === void 0 ? void 0 : _k.body)),
        conversation.hasUnreadMessage ? (React.createElement(MessagesBadge, null)) : (React.createElement(LastMessageDate, null, conversation.lastMessage &&
            getLastMessageTime(conversation.lastMessage.created)))))));
};
