var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { DividerWrapper } from 'components/Divider/styles';
import { rem } from 'polished';
import styled from 'styled-components';
import { colors } from 'styles';
export var FiltersWrapperContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  z-index: 4000;\n  background: ", ";\n  position: fixed;\n\n  width: 100vw;\n  height: calc(100vh - ", ");\n  height: calc(", " - ", ");\n  -webkit-overflow-scrollin: touch;\n\n  ", " {\n    margin: ", " 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  z-index: 4000;\n  background: ", ";\n  position: fixed;\n\n  width: 100vw;\n  height: calc(100vh - ", ");\n  height: calc(", " - ", ");\n  -webkit-overflow-scrollin: touch;\n\n  ", " {\n    margin: ", " 0;\n  }\n"])), colors.base.white, rem(60), function (_a) {
    var height = _a.height;
    return height;
}, rem(60), DividerWrapper, rem(8));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  overflow: auto;\n  flex: 1;\n  margin-top: ", ";\n"], ["\n  position: relative;\n  overflow: auto;\n  flex: 1;\n  margin-top: ", ";\n"])), rem(60));
export var ContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  height: 100%;\n  padding: ", ";\n"], ["\n  flex: 1;\n  height: 100%;\n  padding: ", ";\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3;
