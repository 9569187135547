import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { colors } from 'styles';
import { Container, IconWrapper, TextWrapper } from './styles';
export var SuggestionItem = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, hasFocus = _a.hasFocus, onClick = _a.onClick;
    return (React.createElement(Container, { onClick: onClick, hasFocus: hasFocus },
        React.createElement(IconWrapper, null,
            React.createElement(FaMapMarkerAlt, { size: 15, color: colors.space.tint700 })),
        React.createElement(TextWrapper, null,
            React.createElement("strong", null, title),
            React.createElement("span", null, subtitle))));
};
