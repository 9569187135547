import { Modal } from 'components/Modal';
import { CreateNewTeam } from 'containers/ChallengeCreateNewTeam';
import { Alert } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { Subtitle, WarningAdvice } from './styles';
export var ChallengeCreateNewTeamPage = function (_a) {
    var _b = _a.visible, visible = _b === void 0 ? false : _b, handleModal = _a.handleModal;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Modal, { visible: visible, onCancel: handleModal, footer: null, title: formatMessage({ id: 'pages.challengeCreateNewTeamPage.title' }) },
        React.createElement(Subtitle, null, formatMessage({ id: 'pages.challengeCreateNewTeamPage.subtitle' })),
        React.createElement(WarningAdvice, null,
            React.createElement(Alert, { size: rem(36), color: colors.space.tint600 }),
            formatMessage({
                id: 'pages.challengeCreateNewTeamPage.warningAdvice',
            })),
        React.createElement(CreateNewTeam, { handleModal: handleModal })));
};
