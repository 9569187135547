import { PROFILE_MY_FAVORITES_FAILURE, PROFILE_MY_FAVORITES_REQUEST, PROFILE_MY_FAVORITES_SUCCESS, } from '../constants';
export var fetchMyFavoritesRequest = function (payload) { return ({
    type: PROFILE_MY_FAVORITES_REQUEST,
    payload: payload,
}); };
export var fetchMyFavoritesSuccess = function (payload) { return ({
    type: PROFILE_MY_FAVORITES_SUCCESS,
    payload: payload,
}); };
export var fetchMyFavoritesError = function (error) { return ({
    type: PROFILE_MY_FAVORITES_FAILURE,
    payload: error,
}); };
