import { LoadingIndicator, Modal } from 'components';
import { useAction, useSelector } from 'hooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { accountActivationToggleVisible } from './actions';
import { makeSelectError, makeSelectHasError, makeSelectLoading, makeSelectVisible, } from './selectors';
export var AccountActivated = memo(function () {
    var toggleVisible = useAction(accountActivationToggleVisible);
    var loading = useSelector(makeSelectLoading());
    var hasError = useSelector(makeSelectHasError());
    var error = useSelector(makeSelectError());
    var visible = useSelector(makeSelectVisible());
    var handleOk = function () { return toggleVisible(); };
    return (React.createElement(Modal, { title: React.createElement(FormattedMessage, { id: "pages.accountActivatedPage.title" }), visible: visible, onOk: handleOk, closable: false, okButtonProps: { disabled: loading }, cancelButtonProps: { style: { display: 'none' } } },
        loading && React.createElement(LoadingIndicator, null),
        !loading && !error && (React.createElement("p", null,
            React.createElement(FormattedMessage, { id: "pages.accountActivatedPage.paragraph" }))),
        !loading && hasError && React.createElement("p", null, error)));
});
