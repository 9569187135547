export var fonts = {
    family: {
        primary: '"Proxima Nova", "Helvetica Neue", Arial, sans-serif',
        code: 'Consolas, Menlo, Courier, monospace',
    },
    weight: {
        light: 300,
        regular: 400,
        semibold: 600,
        bold: 700,
    },
};
