export default {
    components: {
        podium: {
            points: 'pts',
        },
        userReview: {
            datePattern: "MMMM d',' yyyy",
        },
        ellipsis: {
            more: 'mas',
            less: 'menos',
        },
        loadingIndicator: {
            loading: 'Cargando...',
        },
        nav: {
            explore: 'Explorar',
            challenge: 'Challenge',
            donation: 'Hacer un donativo',
            aboutUs: 'Acerca de Jaccede',
            myProfile: 'Mi perfil',
            changeFontSize: 'cambiar el tamaño de la fuente',
            darkMode: 'Cambiar el contraste',
            signIn: 'Conectarse',
            logout: 'Desconectarse',
            mailSystem: 'Mensajes',
            homePage: 'Pagina principal',
        },
        facebookButton: {
            continueWithFacebook: 'Conexion con Facebook',
        },
        usersProfilesField: {
            label: 'Tipo de perfil',
            select: 'Seleccionador una opcion',
            placeholder: 'Seleccionador un typo de perfil',
        },
        passwordField: {
            label: 'Contrasena',
            placeholder: 'Inserta su contrasena',
            showPassword: 'Mostrar  la contrasena',
            hidePassword: 'Ocultar la contraseña',
            forgotPassword: '¿Has olvidado tu contraseña?',
        },
        emailField: {
            label: 'Email',
            placeholder: 'Inserte su email',
        },
        searchField: {
            button: 'Buscar',
            placeholder: 'Encuentra lugares accesibles',
        },
        profileMenu: {
            profile: 'Mi perfil',
            mailSystem: 'Sistema de correo',
            logout: 'Desconectarse',
        },
        passwordScore: {
            tips: '{tipsHighlight} La contraseña debe responder a los siguientes criterios :',
            tipsHighlight: 'Astuce :',
            tipOne: '1 - mínimo una letra mayúscula',
            tipTwo: '2 - mínimo una cifra',
            tipThree: '3 - mínimo 8 caracteres',
            veryWeak: 'muy bajo',
            weak: 'bajo',
            fair: 'sentido',
            strong: 'Fuerte',
        },
        placesAutocomplete: {
            googlePlaceIdLabel: 'Mi dirección',
            googlePlaceIdPlaceholder: 'Dirección',
        },
        aboutJaccede: {
            container: {
                definition: {
                    headline: 'Jaccedista [ʒaksɛdœʁ/øz]',
                    description: "\n            Sin importar cual sea su nombre, edad o sexo.\n            Es un usuario, o colaborador, de Jaccede de esp\u00EDritu positivo,que trabaja mano a mano\n            on sus semejantes en todo el mundo para completar y actualizar la\n            u\u00EDa Jaccede, con el fin de difundir entre todos la informaci\u00F3n\n            y sensibilizar sobre la importancia de la accesibilidad.\n\n            ",
                },
                headline: "Jaccede \n c'est quoi",
            },
            headline: '¿Qué es Jaccede? ?',
            subtitle: 'En fonción de mi movilidad, ¿a qué lugar puedo acceder?',
            firstParagraph: "\n        Jaccede, es una plataforma colaborativa (p\u00E1gina web y aplicaci\u00F3n para m\u00F3vil) en la que todos pueden\n        informar sobre la accesibilidad de locales y establecimientos, permitiendo a las personas con movilidad\n        reducida identificar qu\u00E9 locales responden mejor a sus necesidades en funci\u00F3n de su accesibilidad.\n      ",
            secondParagraph: "\n        Una de las principales barreras con las que se encuentran las personas con movilidad reducida\n        es la falta de informaci\u00F3n sobre la accesibilidad. Compartir esta informaci\u00F3n en Jaccede\n        tiene un impacto social importante e inmediato.\n      ",
            thirdParagraph: "\n        Adem\u00E1s, Jaccede organiza eventos durante todo el a\u00F1o donde se re\u00FAnen los Jaccedistas,\n        con el objetivo de sensibilizar al gran p\u00FAblico, y ampliar su gu\u00EDa. \u00A1Se han\n        realizado ya un buen centenar de manifestaciones en Francia y el extranjero!\n      ",
        },
        emailNotConfirmed: {
            message: 'Confirme su correo electrónico',
        },
        subscribeButton: {
            subscribe: 'Suscribirse ',
            unsubscribe: 'Anular la suscripción ',
        },
        subFooter: {
            searchByType: {
                title: 'Búsqueda por typo de lugar',
            },
            findPlacesInFrance: {
                title: ' Buscar lugares en Francia',
            },
            findPlacesInEurope: {
                title: 'Buscar lugares en Europa',
            },
            findPlacesInTheWorld: {
                title: 'Buscar lugares en el mundo',
            },
        },
        bottomMenu: {
            languages: {
                english: 'English (UK)',
                french: 'Français',
                spanish: 'Español',
                italian: 'Italiano',
                german: 'Deutsche',
            },
            submenu: {
                fontSize: 'Tamaño de fuente',
                rightHand: 'Mano derecha',
                leftHand: 'Mano izquierda',
                defaultLanguage: 'Idioma por defecto',
                darkMode: 'Alto contraste',
            },
            challenge: 'Challenge',
            explore: 'Explorar',
            donate: 'Hacer un donativo',
            settings: 'Parámetros',
            menu: 'Menú ',
        },
        footer: {
            copyRight: '2020 copyright. Jaccede. Reservados todos los derechos.',
            privacyTerms: 'Datos personales · condiciones generales',
            sitemap: 'Mapa del sitio',
        },
        addPlaceToMap: {
            button: 'Añadir un lugar que falta',
            altImage: 'Hombre con una mujer en silla de ruedas',
        },
        notificationMenu: {
            title: 'Notificaciones',
            empty: 'Ninguna notificación',
        },
        keyboardMode: {
            tabButton: 'Ver el contenido',
            escButton: 'Toque ESCAPE para cerrar el modo de teclado',
        },
        alertNewSite: {
            message: 'Welcome to the beta version of the new Jaccede platform! Do not hesitate to send us your',
            email: 'suggestions',
        },
    },
    containers: {
        app: {
            title: 'Jaccede',
            cookieConsentText: 'Jaccede utiliza cookies para mejorar su experiencia en el sitio respetando su información personal.',
            cookieConsentButtonOk: 'De acuerdo',
            cookieConsentButtonDecline: 'Más tarde',
        },
        signInEmail: {
            emailFormat: 'Formato de correo electrónico incorrecto',
            emailRequired: 'Correo electronico es requerido',
            signInButton: 'Conexión',
            passwordRequired: 'Se requiere contraseña',
            invalidSignin: 'Correo o contraseña incorrectos',
        },
        signUpEmail: {
            header: 'O con un correo electrónico',
            emailLabel: 'Correo electrónico',
            emailPlaceholder: 'Inserta SU email',
            emailRequired: 'Correo electronico es requerido',
            pseudoLabel: 'Seudónimo',
            pseudoPlaceholder: 'Seudónimo',
            pseudoRequired: 'Se requiere un apodo',
            acceptsNewsletterLabel: 'Deseo recibir el boletín mensual',
            termsAndCoditions: 'Al crear una cuenta, acepta la ',
            termsAndCoditionsLink: 'Condiciones Generales de Uso',
            registrationButton: 'Registro',
            profileIdRequired: 'Se requiere el tipo de perfil',
            googlePlaceIdRequired: 'Mi direccion es requerida',
            passwordRequired: 'se requiere contraseña',
            passwordStrengthRegex: 'Este campo debe contener: 1 mayúscula, 1 número y mínimo 8 caracteres',
        },
        home: {
            lastPlacesUpdates: {
                title: 'Los últimos lugares actualizados',
            },
            signUpNewsletter: {
                title: 'Suscríbete a nuestro boletín',
                submitButton: 'Suscribir',
                emailRequired: 'Correo electronico es requerido',
                optinSuccess: 'Tu correo electrónico ha sido registrado',
                optinFailure: 'Lo sentimos, se produjo un error al suscribirse a nuestro boletín. Gracias, inténtalo más tarde.',
            },
            socialNetworks: 'Síguenos:',
        },
        contribute: {
            title: "\n        \u00A1Contribuye a\n        Jaccede!\n      ",
            collaborateParagraph: "\n        Convertirse en miembro es la primera forma de mostrar su apoyo a\n        Jaccede. Su membres\u00EDa ayuda a promover la causa de las personas en\n        movilidad reducida y tambi\u00E9n est\u00E1 proporcionando apoyo moral y econ\u00F3mico\n        a la asociaci\u00F3n.\n      ",
            collaborateAlt: 'Persona en silla de ruedas',
            collaborateImage: '¡Únete a la aventura!',
            collaborateTitle: 'Afiliarse a la asociación',
            collaborateButtonText: 'Afiliarse!',
            donateAlt: 'Persona en silla de ruedas',
            donateImage: '¡Ayúdanos a triunfar!',
            donateTitle: 'Hacer un donativo',
            donateButtonText: 'Hacer un donativo',
            donateParagraph: "\n        Tu inter\u00E9s en contribuir a la mejora de la vida de las personas con\n        movilidad reducido te llev\u00F3 a Jaccede. Ay\u00FAdanos a lograr este objetivo y\n        tomar medidas para educar al p\u00FAblico en general sobre los beneficios de\n        la accesibilidad.\n      ",
        },
        homeHeader: {
            title: "\n        Encuentra los lugares que\n        son accesibles para ti\n      ",
            placeholder: 'Encuentra lugares accesibles',
            seeMore: 'Ver más',
        },
        homeSearch: {
            button: 'Buscar',
            placeholder: 'Encuentra un establecimiento o una dirección',
        },
        homeLearnMore: {
            title: "\n        Saber m\u00E1s\n        de nosotros\n      ",
            desktopSubtitle: 'Descubra cómo Jaccede trabaja hacia la accesibilidad para todos, para todos.',
            mobileSubtitle: 'Jaccede es una plataforma interactiva donde cualquiera puede agregar detalles sobre lugares abiertos al público.',
            videoAlt: 'Acerca de nosotros miniatura de video',
            blockquoteTitle: 'Jaccede, ¿qué es?',
            blockquoteParagraph: "\n        Mira c\u00F3mo Jaccede trabaja para lograr la accesibilidad para todos,\n        por todos.\n      ",
        },
        homeBrowsePlaces: {
            title: "\n        Buscar ubicaciones\n        por categor\u00EDa\n      ",
            subtitle: "\n        \u00BFEst\u00E1 buscando un tipo de lugar en particular? Lugares p\u00FAblicos y sus\n        Los detalles de accesibilidad se pueden ver por categor\u00EDa con Jaccede.\n      ",
            button: 'Explore los lugares',
            dogAndOwnerAlt: 'Perro guía con su dueño en un parque',
            wheelchairHandAlt: 'Persona en silla de ruedas',
            guideDogsAlt: 'Perros guía con correa',
            peopleSmilingAlt: 'Gente charlando',
        },
        homeMostActive: {
            title: "\n        Los contribuyentes m\u00E1s\n        activos de la semana\n      ",
            viewAllRank: 'Ver todas las clasificaciones',
        },
        homeLatestNews: {
            title: 'Noticias de Jaccede',
            subtitle: '¡Siga las últimas noticias de la comunidad y la asociación Jaccede navegando por nuestro blog!',
            buttonText: 'Ver blog',
            note: 'solo en frances',
        },
        oAuth: {
            errorPageTitle: 'Se ha producido un error',
            completeAccountTitle: 'Completa tu cuenta',
            successNotification: '¡Estás conectado correctamente!',
        },
        resendEmailActivation: {
            header: 'Reenviar correo electronico de activación',
            submitButton: 'Reenviar',
            emailRequired: 'correo electronico es requerido',
            emailformat: 'Formato de correo electrónico incorrecto',
        },
        profile: {
            achievements: {
                header: 'Éxito',
            },
            user: {
                editMyProfile: {
                    mobile: 'Editar',
                    desktop: 'Editar mi perfil',
                },
                pointsInfo: {
                    criterion: '1 criterio editado una vez = 1 punto',
                    photo: '1 foto agregada por ubicación = 4 puntos',
                    comment: '1 comentario por ubicación = 2 puntos',
                    place: '1 ubicación faltante agregada = 1 punto',
                },
                subscriptions: 'Suscripciones',
                subscribers: 'Suscriptores',
                points: 'Puntos',
                sendMessage: 'Enviar un mensaje',
                readMore: 'Más',
                medal200: 'Insignia 200 puntos',
                medal1000: 'Insignia de 1000 puntos',
                medal5000: 'Insignia de 5000 puntos',
                medal10000: 'Insignia de 10,000 puntos',
                medal25000: 'Insignia de 25.000 puntos',
                medalCriterion: 'Insignia de criterios',
                medalPhoto: 'Insignia con foto',
                medalComment: 'Insignia de comentarios',
                medalPlace: 'Insignia de ubicaciones faltantes',
            },
            activities: {
                header: 'Actividad de las últimas semanas',
            },
            myActiveChallenges: {
                header: 'Mi desafío activo',
                challengesEmptyList: 'El usuario no está participando en ningún desafío por el momento',
            },
            moreAbout: {
                header: 'Más información',
                contributions: 'Contribuciones',
                favorites: 'Favoritos',
                contributionEmptyList: 'Sin contribución por el momento',
                favoriteEmptyList: 'Aún no hay favoritos',
            },
            nextAchievements: {
                medals: {
                    medal200: {
                        title: '200 puntos de insignia',
                        description: 'Para ganar esta insignia debes ganar 200 puntos',
                    },
                    medal1000: {
                        title: '1000 puntos de insignia',
                        description: 'Para ganar esta insignia, debes acumular 1000 puntos ',
                    },
                    medal5000: {
                        title: '5000 puntos de insignia',
                        description: 'Para ganar esta insignia, debes acumular 5,000 puntos ',
                    },
                    medal10000: {
                        title: '10,000 puntos de insignia',
                        description: 'Para ganar esta insignia, debes acumular 10,000 puntos ',
                    },
                    medal25000: {
                        title: '25.000 puntos de insignia',
                        description: 'Para ganar esta insignia, debes acumular 25.000 puntos ',
                    },
                    medalCriterion: {
                        title: 'Insignia de criterios',
                        description: 'Para obtener esta insignia, debe informar 5000 criterios de accesibilidad del sitio',
                    },
                    medalPhoto: {
                        title: 'Insignia con foto',
                        description: 'Para ganar esta insignia, debes agregar 1000 fotos de lugares',
                    },
                    medalComment: {
                        title: 'Insignia de comentarios',
                        description: 'Para obtener esta insignia, debe realizar 1000 comentarios en el sitio.',
                    },
                    medalPlace: {
                        title: 'Insignia de ubicaciones faltantes',
                        description: "Para ganar esta insignia, debe agregar 500 lugares que no exist\u00EDan en el sitio de Jaccede",
                    },
                },
                header: 'Siguiente éxito',
            },
        },
        forgotPasswordPage: {
            emailLabel: 'Correo electrónico',
            submitButton: 'Solicite el enlace para restablecer su contraseña',
            notReset: 'Si no puede restablecer su contraseña,',
            contatUs: 'Contáctenos.',
            emailFormat: 'Formato de correo electrónico incorrecto',
            emailRequired: 'correo electronico es requerido',
            verifyEmail: 'Se le han enviado instrucciones para restablecer su contraseña.',
            success: 'Terminado !!',
            error: 'Error !!',
            passwordIsExpired: 'El enlace ha caducado. Vuelva a aplicar.',
        },
        changePasswordPage: {
            newPasswordLabel: 'Nueva contraseña',
            newPasswordRequired: 'Nueva contraseña requerida',
            confirmPasswordRequired: 'Se requiere la confirmación de una nueva contraseña',
            newPasswordStrengthRegex: 'El campo debe contener: 1 mayúscula, 1 número y mínimo 8 caracteres',
            confirmPasswordValidation: 'Las dos nuevas contraseñas deben coincidir',
            confirmPasswordLabel: 'Confirma la nueva contraseña',
            submitButton: 'Editar',
            success: '¡¡Éxito !!',
            successfully: '¡La contraseña ha sido cambiada con éxito!',
            error: 'Error !!',
            errorMessage: 'Perdón, algo salió mal',
            checkingPasswordIsLoading: 'Comprobamos la validez del token',
            linkForgotPassword: 'Haga clic aquí',
        },
        subscribers: {
            subscriptions: 'Suscripciones',
            subscribers: 'Suscriptores',
            loggedUserNoSubscribers: '¡No tienes suscriptores!',
            loggedUserNoSubscriptions: '¡No tienes suscripciones! ',
            noSubscribers: '¡No se encontraron suscriptores!',
            noSubscriptions: 'No se encontró ninguna suscripción',
        },
        globalScoreboard: {
            tabWeek: {
                title: 'Semanal',
                footerTitleWithPoints: 'Estos son tus puntos del mes',
                footerTitleWithoutPoints: 'No ganaste puntos esta semana',
            },
            tabMonth: {
                title: 'Mensual',
                footerTitleWithPoints: 'Estos son tus puntos del mes',
                footerTitleWithoutPoints: 'No ganaste puntos este mes',
            },
            tabAll: {
                title: 'Total',
                footerTitleWithPoints: 'Aquí están tus puntos',
                footerTitleWithoutPoints: 'No obtuviste ningún punto',
            },
            headerTitle: 'Clasificación general',
            emptyList: "No tenemos puntajes para el mes actual, \u00BFqu\u00E9 crees que cambie eso?\u00A1Empiece a hacer el mundo m\u00E1s accesible ahora!\n      ",
        },
        editMyProfile: {
            identification: {
                fieldDescription: 'Identificación',
                fieldInformation: 'Información clave sobre su cuenta',
            },
            location: {
                fieldDescription: 'Ubicación',
                fieldInformation: 'Especifico mi ubicación para beneficiarme de información más relevante',
            },
            message: {
                fieldDescription: 'Mensaje',
                fieldInformation: 'En pocas palabras, ¡un mensaje para todos los Jaccedista!',
            },
            editOptions: {
                identification: 'Identificación',
                identificationMessage: 'Edite su perfil público para que la gente pueda saber un poco más sobre usted',
                name: 'apellido y seudónimo',
                editPhoto: 'Editar foto',
                editPhotoMessage: 'Edite su foto que se utilizará en la comunidad de Jaccede',
                profileType: 'TIPO DE PERFIL',
                profileMessage: 'MENSAJE DE PERFIL',
                contactInformation: 'Información del contacto',
                email: 'CORREO ELECTRÓNICO',
                telephone: 'TELÉFONO',
                password: 'CONTRASEÑA',
                location: 'Ciudad',
                myAddress: 'MI DIRECCIÓN',
                editPhotoOverlay: 'Editar',
            },
            editPhoto: {
                uploadError: 'No se pudo cargar tu foto',
                modalTitle: 'Editar mi foto',
                resolutionRule: 'Resolución mínima de ',
                sizeRule: 'Tamaño menor o igual a',
                extensionsRule: 'Extensiones autorizadas : ',
            },
            editName: {
                name: 'Nombre de pila',
                title: ' Nombre y seudónimo',
                lastName: 'Nombre',
                username: 'Seudónimo',
                usernameRequiredMessage: 'Se requiere un apodo',
                updateName: 'Cambiar nombre y apodo',
            },
            editEmail: {
                title: 'Correo electrónico',
                email: 'Correo electrónico',
                emailRequiredMessage: 'correo electronico es requerido',
                invalidEmailMessage: 'Formato de correo electrónico incorrecto',
                updateEmail: 'Editar correo electrónico',
            },
            editTelephone: {
                title: 'Teléfono',
                telephone: 'Teléfono',
                updateTelephone: 'Editar teléfono',
            },
            editProfileMessage: {
                title: 'Mensaje de perfil',
                profileMessage: 'Mensaje de perfil',
                maxSizeProfileMessage: 'Máximo de 500 caracteres',
                updateProfileMessage: 'Editar mensaje de perfil',
            },
            editProfileType: {
                title: 'Tipo de perfil',
                profileTypeRequiredMessage: 'el tipo de perfil se requiere',
                updateProfileType: 'cambiar el tipo de perfil',
            },
            editPassword: {
                title: 'Contraseña',
                password: 'Nueva contraseña',
                confirmPassword: 'Confirmar nueva contraseña',
                passwordRequiredMessage: 'Se requiere una nueva contraseña',
                confirmPasswordRequiredMessage: 'Se requiere la confirmación de una nueva contraseña',
                passwordsNotMatching: 'Las dos nuevas contraseñas deben coincidir',
                changePassword: 'Cambiar contraseña',
                passwordPolicy: 'Este campo debe contener: 1 mayúscula, 1 número y mínimo 8 caracteres',
            },
            editLocation: {
                title: 'Mi dirección',
                myAddress: 'Mi dirección',
                acceptVisibility: 'Acepto ser visible para los demás Jaccedista',
                updateMyAddress: 'Cambiar mi dirección',
                shareLocalization: 'Acepto ser visible para los demás Jaccedista',
                invalidAddress: 'Dirección no válida',
            },
            acceptTerms: {
                contact: 'Acepto ser contactado por otros Jaccedista',
                newsletter: 'Deseo recibir el boletín mensual',
                infoNewsletter: 'Su correo electrónico solo se utiliza para enviarle nuestro boletín mensualmente así como información sobre las actividades de Jaccede. Puede utilizar el enlace para darse de baja integrado en el boletín en cualquier momento.',
            },
            pageTitle: 'Editar mi perfil',
            passwordUpdateSuccess: 'La contraseña se ha cambiado con exito !',
            photoUploadSuccess: 'Cambio fotográfico con éxito!',
            defaultAvatarUpdatedSuccess: 'Foto subida con éxito !',
            photoDeleteSuccess: 'Foto eliminada con éxito !',
            nameUpdateSuccess: 'Nombre y apellido se han cambiado con éxito !',
            profileMessageUpdateSuccess: 'El mensaje de perfil se ha modificado con exito !',
            profileTypeUpdateSuccess: 'Su tipo de perfil se ha cambiado correctamente !',
            telephoneUpdateSuccess: 'El número de teléfono se ha cambiado con éxito !',
            locationUpdateSuccess: 'Su dirección ha sido cambiada con éxito!',
            termsUpdateSuccess: 'Los términos se han modificado con éxito !',
            success: 'Éxito',
            error: 'Error',
        },
        placeDetails: {
            report: {
                title: 'Denunciar una foto como spam',
                label: 'Anadir un comentario',
                placeholder: 'Comentario',
                button: 'Seguir ',
                required: 'El mensaje es obligatorio.',
                reportPhotoButtonTitle: 'Report photo',
            },
            reported: {
                title: 'Foto denunciada',
                message: 'Esta foto fue denunciada como inapropiada.\n gracias. Tomaremos en cuenta sus comentarios Lo antes posible. \nGracias por su vigilancia.',
                button: 'Fermer',
            },
            reportPhoto: {
                title: 'Denunciar una foto como spam.',
                label: 'Anadir un comentario',
                placeholder: 'Hacer un comentario',
                button: 'Continuar',
                required: 'El mensaje es obligatorio.',
            },
            reportedPhoto: {
                title: 'Foto denunciada',
                alreadyReported: 'Esta foto ya ha sido denunciada',
                message: 'Esta foto fue denunciada como inapropiada .\n Gracias. Tomaremos en cuenta sus comentarios en Lo antes posible. \nGracias por su vigilancia.',
                button: 'Fermer',
            },
            suggestChanges: 'Sugerir un cambio',
            saveChangesButton: 'Guardar cambios',
            evaluateTitle: 'Evaluar y valorar',
            evaluateTagline: 'Gane puntos señalando ubicaciones',
            addReviewButton: 'Añadir un comentario',
            ratings: 'Puntuación',
            addPhotos: 'Añadir fotos',
            addMorePhotos: 'Añadir más fotos',
            reviews: 'Opinión',
            moreReviews: 'Más opiniones',
            yourReviews: 'Votre commentaire',
            lastChange: 'El último cambio por',
            lastChange2: 'El',
            searchPlacesAround: 'Búsqueda de lugares cercanos',
            reportProblemTitle: 'Informe de este perfil de ubicación',
            reportedProblemTitle: 'La ubicación ha sido denunciada.',
            reportedProblemMessage: 'Gracias. Tomaremos en cuenta sus comentarios lo antes posible.',
            reportedProblemEndMessage: 'Gracias por su vigilancia.',
            reportedButton: 'Cerca',
            reportReviewTitle: 'Informar de un comentario como spam',
            reportedReviewTitle: 'El comentario fue denunciado.',
            error: 'Error',
            success: 'Succes!!',
            closed: 'Definitivamente cerrado',
            suggestModification: 'Sugerir un cambio en los mapas de Google',
        },
        placePhotos: {
            report: {
                title: 'Informe como una foto de spam.',
                label: 'Añadir un comentario',
                placeholder: 'Hacer un comentario',
                button: 'Continuar',
                required: 'El mensaje es necesario.',
            },
            reported: {
                title: 'Foto denunciada',
                alreadyReported: 'Esta foto ya ha sido reportada',
                message: 'Esta foto fue denunciada como inapropiada.\n Gracias. Tomaremos en cuenta sus comentarios lo antes posible. \nMerci de votre vigilance.',
                button: 'Cerrar',
            },
            header: 'Fotos de este lugar',
            addPhoto: 'Descargar una foto',
            successTitle: 'Éxito',
            photoSuccess: 'Foto añadida con éxito!',
            validationTitle: 'Archivo incorrecto',
            validationDescription: 'No se ha podido descargar la foto. El archivo debe tener una resolución mínima de 1080x1080 píxeles. El archivo debe ser menor o igual que 20MB. Las extensiones autorizadas son: PNG, GIF, JPEG, JPG.',
        },
        explorePlaces: {
            inputPlaceholder: 'Búsqueda de lugares accesibles',
        },
        filterPlaces: {
            desktopBar: {
                buttons: {
                    apply: 'Aplicar filtros guardados',
                    category: 'Seleccione una categoría',
                    terms: 'Añadir términos al filtro',
                    spaces: 'Ubicaciones no detalladas',
                    save: 'Salvar',
                },
                spaces: {
                    title: 'Ver ubicaciones no detalladas',
                    tagline: 'Vestibulum ornare tincidunt orci, non consequat nisl gravida vel.Nulla ut varius ante.',
                },
            },
            termsSelector: {
                title: 'Accesibilidad',
                inputPlaceholder: 'Comience a escribir aquí para agregar términos',
                showPlacesSwitch: 'Ver lugares con todos los términos seleccionados',
            },
            category: {
                placeholder: 'Escribir aquí',
                suggestedCategoriesLabel: 'Categorías sugeridas',
                selectedCategoryLabel: 'Seleccione una categoría',
            },
            categorySelector: {
                label: 'Categorías',
            },
            filterBar: {
                editButton: 'Cambiar filtros',
                more: 'Más',
            },
            footer: {
                clear: 'Filtros claros',
                applyFilters: 'Aplicar filtros',
            },
            header: {
                title: 'Filtros',
            },
            placeWithoutDetailsSwitch: {
                title: 'Ubicaciones no detalladas',
            },
        },
        reviews: {
            headerTitle: 'Todas las opiniones',
            emptyReviews: 'Este lugar no tiene ningún comentario todavía',
            addReviewConflict: 'Este comentario ya existe',
        },
        editPlace: {
            mainPageTitle: 'Sugerencia de cambios',
            category: 'Categoría',
            categoryOfPlace: 'Categoría de lugar',
            entrance: 'Entrada',
            access: 'Acceso',
            doorOpeningSystem: 'Apertura de puertas',
            ninetyCmAndAbove: '90 cm y más',
            indoors: 'Interior',
            servicesAndFacilities: 'Servicios y equipos',
            roomsTile: 'Habitacione(s)',
            rooms: 'Habitación',
            outside: 'Fuera',
            disabledParkingSpaces: 'Pplaza de aparcamiento "discapacitado"',
            pavements: 'Aceras',
            selectACategory: 'Seleccione una categoría',
            categoryRequired: 'Se requiere categoría.',
            successfulFeedback: 'Modificado con éxito!',
            pointsFeedback: 'Has ganado puntos.',
            accessibilityTitle1: 'Accesibilidad detallada',
            accessibilityTitle2: 'Por',
            accessibilityTitle2Singular: 'Persona',
            accessibilityTitle2Plural: 'Persona',
            noAccessibility: 'Este lugar aún no contiene información sobre su accesibilidad.',
            success: '¡Éxito!',
            photoAdded: 'Foto añadida.',
            failure: 'Fracaso',
            addMorePhotos: 'Agregar más fotos',
        },
        challenges: {
            secretChallenge: {
                text: 'Ingrese su código secreto!',
                description: 'Para desbloquear un desafío privado, debe ingresar el código secreto que se le envió.',
                button: 'Accede al desafío',
                required: 'Se requiere código',
                validation: 'Oups ! :( Parece que tu código no es válido, compruébalo de nuevo',
                max: 'El código debe tener 6 caracteres',
            },
            header: {
                hashtag: '#JACCEDECHALLENGE2020',
                description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
            },
            publicChallengeTitle: 'Challenges publics',
        },
        challengesDetail: {
            selectTeam: 'Seleccione un equipo a continuación para participar',
            newTeamSection: 'Crea y únete a un nuevo equipo',
            createNewTeamButton: 'Crea un nuevo equipo',
            challengeStartsOn: 'El desafío comienza en',
            challengeEndsOn: 'termina en',
            membersScoreBoard: 'Clasificación de membresía',
            teamScoreboard: 'Clasificación del equipo',
            emptyTeams: 'Todavía no tenemos equipos',
        },
        createNewTeam: {
            label: 'Elige el nombre de tu equipo :',
            placeholder: 'Escriba el nombre de su equipo',
            cancelButton: 'Anular',
            button: 'No continuar',
            nameIsRequired: 'Se requiere el nombre',
        },
        challengesMembersTeam: {
            joinTeamSection: {
                title: 'Quiero ser parte de este equipo',
                buttonText: 'Únete a este equipo',
            },
            placesAroundMeSection: {
                title: 'Explore places',
            },
            memberHeader: {
                inviteButtonText: 'Invita a un nuevo miembro',
            },
            title: 'Miembros del equipo',
        },
        challengeInviteUserTeam: {
            label: 'Email :',
            placeholder: 'Email',
            cancelButton: 'Anular',
            button: 'No continuar',
            emailIsRequired: 'correo electronico es requerido',
            emailFormat: 'Formato de correo electrónico incorrecto',
            inviteSuccess: 'Un correo electronico ha sido enviado',
        },
    },
    pages: {
        globalScoreboard: {
            title: 'Clasificación general',
            description: 'Página de descripción del casco de clasificación general',
            showByPeriod: 'Visualización por período',
        },
        explorePlacesPage: {
            newPlaceModal: {
                addressTitle: 'Ingrese la dirección de la ubicación',
                newPlaceTitle: 'Agregar una nueva ubicación',
                suggestionTitle: '¿Está buscando una de estas direcciones?',
                nameLabel: 'nombre del establecimiento',
                nameValidation: 'Se requiere el nombre',
                addressLabel: 'Dirección',
                addressValidation: 'La dirección es necesaria',
                categoryLabel: 'Categoría',
                categoryPlaceholder: 'seleccionar',
                categoryValidation: 'la categoria es requerida',
                telephoneLabel: 'Teléfono',
                telephoneValidation: 'Se requiere el número de teléfono',
                websiteLabel: 'Sitio web',
                websiteValidation: 'El sitio web es obligatorio',
                continueButton: 'Seguir',
                validateButton: 'Validar',
                suggestionButton: 'No, continuar',
                validateWebsite: 'Ingrese un enlace válido o deje el campo en blanco.',
            },
            addPlaceNotFound: {
                title: '¿No encontró el lugar que buscaba?',
                tagline: '¡Agrega la dirección al mapa, gana puntos y ayuda a que el mundo sea más accesible!',
            },
            addPlaceNoResults: {
                title: '¡Ups! No encontramos la ubicación que buscaba.',
                tagline: 'Si conoce un lugar accesible que no está en nuestra aplicación, agréguelo ahora y ayude a personas de todo el mundo.',
            },
            chooseLocation: {
                text: 'Elija una ubicación en el mapa',
                button: 'Elige en el mapa',
            },
            collaboration: {
                message: '¿Por qué no trabajar juntos y ayudar a que el mundo sea accesible para todos?',
                highlight: '¡Tenemos colaboraciones en todo el mundo!',
            },
            title: 'Explore los lugares',
            description: 'Explorar descripción de lugares para helmet',
            sidebarTitle: 'Accesibilidad de lugares en y alrededor de la región',
            recentContributors: 'Colaboradores recientes ',
        },
        filterPlacesPage: {
            title: 'Filtrar los lugares',
            description: 'Filtrar descripción de lugares para helmet',
        },
        reviewsPage: {
            title: 'Todos los comentarios',
            description: 'Descripción de todos los comentarios para helmet',
        },
        notFoundPage: {
            title: 'Página no encontrada',
            description: 'Página no encontrada descripción para helmet',
        },
        homePage: {
            title: 'Acogida',
            description: 'página principal helmet',
        },
        signInPage: {
            title: 'Conexión',
            description: 'Inicio de sesión y creación de cuenta',
            byEmail: 'o con un correo electrónico',
            dontHaveAccount: '¿No tienes cuenta?',
            signUpButton: 'Crear una cuenta',
        },
        forgotPasswordPage: {
            title: 'Restablecer la contraseña',
            description: 'Restablecer descripción de contraseña parahelmet',
        },
        changePasswordPage: {
            title: 'Cambiar mi contraseña',
            description: 'Cambiar la descripción de mi contraseña para helmet',
        },
        profilePage: {
            title: 'Perfil',
            description: 'Mi perfil',
        },
        signUpPage: {
            title: 'Crear una cuenta',
            description: 'Registre una nueva cuenta',
            header: 'Crear una cuenta',
            continueWithFacebook: 'Conexión con Facebook',
            alreadyAccount: 'Ya tienes una cuenta ?',
            signIn: 'Conexión',
        },
        confirmAccountPage: {
            title: 'Confirme su cuenta',
            success: 'Perfecto !',
            content: 'Le hemos enviado instrucciones sobre cómo completar la creación de su cuenta.',
        },
        aboutPage: {
            title: 'Sobre',
            description: 'Sobre la organización Jaccede, nuestro objetivo es involucrar a las personas desde un punto de vista local.',
            listGroup: {
                inBrief: {
                    header: 'En breve',
                    item1: 'Jaccede, ¿qué es?',
                    item2: '¿quién puede utilizar Jaccede?',
                    item3: 'Nuestra historia',
                    item4: 'Premios y galardones',
                },
                organization: {
                    header: 'La asociacion',
                    item1: 'Visión y objetivos',
                    item2: 'Consejo de administración',
                    item3: 'Equipo permanente',
                    item4: 'Nuestros voluntarios',
                    item5: 'Afiliarse a la asociación',
                    item6: 'Hacer un donativo',
                },
                getInvolved: {
                    header: '¿CÓMO COLABORAR?',
                    item1: 'En línea',
                    item2: 'Sobre el terreno',
                    item3: 'Colabora con lo que sabes hacer',
                },
                partners: {
                    header: 'EMPRESAS COLABORADORAS',
                    item1: 'Empresas colaboradoras',
                    item2: 'Involucra a tu empresa',
                    item3: 'Moviliza a tu asociación o escuela',
                    item4: 'Participa como ente público',
                    item5: 'Referencia tu establecimiento',
                },
            },
        },
        resendEmailActivationPage: {
            title: "Devolver l'email d'activation",
            description: 'Renvoyer E-Mail de activación',
        },
        subscribersProfilePage: {
            title: 'Suscripciones y suscriptores',
            description: 'Seguimiento de usuarios',
        },
        editProfilePage: {
            title: 'Editar perfil',
            description: 'Editar perfil',
        },
        editFieldProfilePage: {
            title: 'Editar perfil',
            description: 'Editar perfil',
        },
        userPage: {
            title: 'Usuario',
            description: 'Contribuyente',
        },
        accountActivatedPage: {
            title: 'Cuenta activada',
            description: 'Cuenta activada',
            paragraph: 'Su cuenta esta activada',
        },
        newsletterPage: {
            title: 'Boletin informativo',
            description: 'Página del boletín informativo',
        },
        editPlacePage: {
            title: 'Accesibilidad detallada',
            description: 'Descripción detallada de accesibilidad',
        },
        placeDetailsPage: {
            title: 'Detalles de lugar',
            description: 'Detalles de lugar',
        },
        newReviewPage: {
            title: 'Añadir un comentario',
            description: 'Añadir un comentario',
            postReviewButton: 'Publicar',
            commentRequiredError: 'Se requiere un comentario',
            commentSizeError: 'Su comentario debe tener entre 15 y 2000 caracteres.',
            commentSuccess: 'Gracias, tu comentario se ha tenido en cuenta',
        },
        placePhotosPage: {
            title: 'Fotos del lugar',
            description: 'Fotos del lugar',
        },
        reviewDetailPage: {
            title: 'Aviso',
            description: 'Detalles del comentario',
            notFound: 'Comentario no encontrado',
            defaultError: 'Se produjo un error al encontrar este comentario.',
        },
        categoryListViewPage: {
            title: 'Nombre de la región',
            description: 'Esta es la página CategoryListViewPage',
        },
        categoryMapViewPage: {
            title: 'Nombre de la región',
            description: 'Esta es la página CategoryMapViewPage',
            seeInListButton: 'Ver lista',
        },
        challengePage: {
            title: 'Página de challenge',
            description: 'Página de challenge descripción para helmet',
        },
        challengeDetailPage: {
            title: 'Detalles del desafío',
            description: 'Descripción de los detalles del desafío para helmet',
        },
        challengeJoinTeamPage: {
            warning: 'Después de la confirmación, no podrá cambiar de equipo.',
            buttonText: 'Únete a este equipo',
        },
        challengesMembersTeam: {
            title: 'Miembros del equipo',
            description: 'Descripción de los miembros del equipo para helmet',
        },
        challengeInviteUserTeamPage: {
            title: 'Invita a un nuevo participante',
            subtitle: 'Ingrese la dirección de correo electrónico de un colega o amigo para invitarlo a participar en este challenge.',
        },
        challengeCreateNewTeamPage: {
            title: 'El registro es irreversible',
            subtitle: 'Estás a punto de crear y unirte a un equipo para participar en este desafío.',
            warningAdvice: 'No podrás abandonar el desafío ni dejar tu equipo.',
        },
        mailSystemPage: {
            title: 'Sistema de correo electrónico',
            description: 'Esta es la página del sistema de correo electronico',
        },
    },
};
