import { TEAMS_ON_CHALLENGE_FAILURE, TEAMS_ON_CHALLENGE_REQUEST, TEAMS_ON_CHALLENGE_SUCCESS, } from './constants';
export var fetchTeamsOnChallengeRequest = function (payload) { return ({
    type: TEAMS_ON_CHALLENGE_REQUEST,
    payload: payload,
}); };
export var fetchTeamsOnChallengeFailure = function (payload) { return ({
    type: TEAMS_ON_CHALLENGE_FAILURE,
    payload: payload,
}); };
export var setTeamsOnChallengeSuccess = function (payload) { return ({
    type: TEAMS_ON_CHALLENGE_SUCCESS,
    payload: payload,
}); };
