var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import { colors } from 'styles';
export var Slider = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.swiper-slide {\n    width: calc(100% - ", ") !important;\n    margin: 0 ", ";\n  }\n"], ["\n  &.swiper-slide {\n    width: calc(100% - ", ") !important;\n    margin: 0 ", ";\n  }\n"])), rem(40), rem(20));
export var navigationButtonBase = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex !important;\n  align-items: center !important;\n  justify-content: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  position: absolute !important;\n  z-index: 10 !important;\n  top: 90% !important;\n  width: ", " !important;\n  height: ", " !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  display: flex !important;\n  align-items: center !important;\n  justify-content: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  position: absolute !important;\n  z-index: 10 !important;\n  top: 90% !important;\n  width: ", " !important;\n  height: ", " !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), colors.base.white, rem(1), colors.space.tint100, rem(2), rem(6), rgba(colors.base.black, 0.2), rem(40), rem(40));
export var PrevButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  left: ", " !important;\n"], ["\n  ", "\n  left: ", " !important;\n"])), navigationButtonBase, rem(10));
export var NextButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  right: ", " !important;\n"], ["\n  ", "\n  right: ", " !important;\n"])), navigationButtonBase, rem(10));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
