var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { breakpoints, colors } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint700,
});
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-top: ", " solid ", ";\n  color: ", ";\n  min-width: calc(320px - ", ");\n  padding: ", " 0 ", ";\n\n  button {\n    white-space: nowrap;\n  }\n"], ["\n  background: ", ";\n  border-top: ", " solid ", ";\n  color: ", ";\n  min-width: calc(320px - ", ");\n  padding: ", " 0 ", ";\n\n  button {\n    white-space: nowrap;\n  }\n"])), background, rem(7), border, color, rem(32), rem(40), rem(44));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  max-width: ", ";\n  margin: 0 auto;\n"], ["\n  display: flex;\n  max-width: ", ";\n  margin: 0 auto;\n"])), breakpoints.medium);
export var Title = styled.strong(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), rem(15));
export var Tagline = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  margin-bottom: ", ";\n  margin-top: ", ";\n"], ["\n  font-size: ", ";\n  margin-bottom: ", ";\n  margin-top: ", ";\n"])), rem(13), rem(16), rem(10));
export var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: ", ";\n  max-width: ", ";\n"], ["\n  margin-left: ", ";\n  max-width: ", ";\n"])), rem(16), rem(192));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
