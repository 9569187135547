var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'antd/lib/grid/style/index.css';
import { Col, Row } from 'antd';
import styled from 'styled-components';
export var RowWrapper = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ColWrapper = styled(Col)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2;
