import { PLACE_REVIEWS_FAILURE, PLACE_REVIEWS_REQUEST, PLACE_REVIEWS_SUCCESS, } from './constants';
export var fetchPlaceReviews = function (payload) { return ({
    type: PLACE_REVIEWS_REQUEST,
    payload: payload,
}); };
export var fetchPlaceReviewsSuccess = function (payload) { return ({
    type: PLACE_REVIEWS_SUCCESS,
    payload: payload,
}); };
export var fetchPlaceReviewsError = function (error) { return ({
    type: PLACE_REVIEWS_FAILURE,
    payload: error,
}); };
