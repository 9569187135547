var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  padding: ", ";\n\n  ", ";\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  padding: ", ";\n\n  ",
    ";\n"])), rem(20), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: ", " 0;\n  "], ["\n    padding: ", " 0;\n  "])), rem(10)));
export var EmoticonImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  object-fit: contain;\n"], ["\n  width: ", ";\n  height: ", ";\n  object-fit: contain;\n"])), rem(16), rem(16));
var templateObject_1, templateObject_2, templateObject_3;
