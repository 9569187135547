var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Form } from 'antd';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Container = styled(Form.Item)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", " !important;\n\n  span:first-child {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: ", ";\n\n    label {\n      white-space: nowrap;\n    }\n\n    a {\n      color: ", ";\n      font-size: ", ";\n      font-weight: ", ";\n      text-align: right;\n    }\n  }\n\n  input {\n    font-size: ", ";\n  }\n\n  &.ant-form-item-has-error {\n    .ant-input-password {\n      border-color: ", ";\n      box-shadow: 0 0 0 ", " ", ";\n    }\n  }\n"], ["\n  margin-bottom: ", " !important;\n\n  span:first-child {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: ", ";\n\n    label {\n      white-space: nowrap;\n    }\n\n    a {\n      color: ", ";\n      font-size: ", ";\n      font-weight: ", ";\n      text-align: right;\n    }\n  }\n\n  input {\n    font-size: ", ";\n  }\n\n  &.ant-form-item-has-error {\n    .ant-input-password {\n      border-color: ", ";\n      box-shadow: 0 0 0 ", " ", ";\n    }\n  }\n"])), rem(16), rem(3), color, rem(14), fonts.weight.semibold, rem(16), rgba(colors.base.danger, 0.5), rem(2), rgba(colors.base.danger, 0.5));
export var LabelWrapper = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  ", ";\n\n  a {\n    outline-offset: 0 !important;\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  ",
    ";\n\n  a {\n    outline-offset: 0 !important;\n  }\n"])), color, rem(14), fonts.weight.semibold, ifProp('hasError', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), colors.base.danger)));
export var ErrorMessage = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n"])), colors.base.danger, rem(14), fonts.weight.regular, rem(6));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
