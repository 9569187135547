/**
 * ChangePasswordPage Messages
 *
 * This contains all the text for the ChangePasswordPage container.
 */
import { defineMessages } from 'react-intl';
export var scope = 'containers.changePasswordPage';
var messages = defineMessages({
    header: {
        id: scope + ".header",
    },
    newPasswordLabel: {
        id: scope + ".newPasswordLabel",
    },
    confirmPasswordLabel: {
        id: scope + ".confirmPasswordLabel",
    },
    newPasswordRequired: {
        id: scope + ".newPasswordRequired",
    },
    newPasswordStrengthRegex: {
        id: scope + ".newPasswordStrengthRegex",
    },
    confirmPasswordValidation: {
        id: scope + ".confirmPasswordValidation",
    },
    confirmPasswordRequired: {
        id: scope + ".confirmPasswordRequired",
    },
    success: {
        id: scope + ".success",
    },
    successfully: {
        id: scope + ".successfully",
    },
    error: {
        id: scope + ".error",
    },
    errorMessage: {
        id: scope + ".errorMessage",
    },
    submitButton: {
        id: scope + ".submitButton",
    },
    checkingPasswordIsLoading: {
        id: scope + ".checkingPasswordIsLoading",
    },
    passwordIsExpired: {
        id: scope + ".passwordIsExpired",
    },
    linkForgotPassword: {
        id: scope + ".linkForgotPassword",
    },
});
export default messages;
