var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { push } from 'connected-react-router';
import { authLoginSuccess } from 'containers/Auth/actions';
import { fetchMyChallengesRequest } from 'containers/Profile/actions';
import { fetchMyNotificationsRequest } from 'containers/Profile/actions/myNotificationsActions';
import { paths } from 'containers/RoutesProvider/routes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OneSignalService } from 'utils/onesignal';
import { signInUserSuccess } from './actions';
import { SIGN_IN_USER_CHANGE_PASSWORD_REQUEST, SIGN_IN_USER_REQUEST, } from './constants';
export function signInUser(_a) {
    var resetForm, setErrors, setSubmitting, setStatus, resCurrentUser, notificationsRequest, challengesRequest, _b, error_1, err, _c, oneSignal, error_2;
    var payload = _a.payload, actions = _a.actions, meta = _a.meta;
    var _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                resetForm = actions.resetForm, setErrors = actions.setErrors, setSubmitting = actions.setSubmitting, setStatus = actions.setStatus;
                resCurrentUser = null;
                _f.label = 1;
            case 1:
                _f.trys.push([1, 13, , 20]);
                return [4 /*yield*/, call(api.auth.signInUser, payload)];
            case 2:
                resCurrentUser = _f.sent();
                return [4 /*yield*/, call(resetForm)];
            case 3:
                _f.sent();
                return [4 /*yield*/, put(authLoginSuccess(resCurrentUser.data))];
            case 4:
                _f.sent();
                return [4 /*yield*/, put(signInUserSuccess())];
            case 5:
                _f.sent();
                notificationsRequest = {
                    pagination: {
                        page: 1,
                        perPage: 5,
                    },
                };
                return [4 /*yield*/, put(fetchMyNotificationsRequest(notificationsRequest))];
            case 6:
                _f.sent();
                challengesRequest = {
                    pagination: {
                        page: 1,
                        perPage: 10,
                    },
                };
                return [4 /*yield*/, put(fetchMyChallengesRequest(challengesRequest))];
            case 7:
                _f.sent();
                _b = meta.from;
                switch (_b) {
                    case paths.signUpPage: return [3 /*break*/, 8];
                    case paths.signInPage: return [3 /*break*/, 8];
                }
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, put(push('/'))];
            case 9:
                _f.sent();
                return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, put(push(meta.from))];
            case 11:
                _f.sent();
                return [3 /*break*/, 12];
            case 12: return [3 /*break*/, 20];
            case 13:
                error_1 = _f.sent();
                err = error_1;
                _c = (_d = error_1.response) === null || _d === void 0 ? void 0 : _d.status;
                switch (_c) {
                    case 400: return [3 /*break*/, 14];
                }
                return [3 /*break*/, 16];
            case 14: return [4 /*yield*/, call(setErrors, __assign({}, (_e = err.response) === null || _e === void 0 ? void 0 : _e.data.fields_error))];
            case 15:
                _f.sent();
                return [3 /*break*/, 18];
            case 16: return [4 /*yield*/, call(setStatus, { api: 'invalidSignin' })];
            case 17:
                _f.sent();
                return [3 /*break*/, 18];
            case 18: return [4 /*yield*/, call(setSubmitting, false)];
            case 19:
                _f.sent();
                return [2 /*return*/, false];
            case 20:
                _f.trys.push([20, 23, , 24]);
                oneSignal = OneSignalService.getInstance();
                return [4 /*yield*/, call([oneSignal, 'sendUserId'])];
            case 21:
                _f.sent();
                return [4 /*yield*/, call([oneSignal, 'sendTags'], resCurrentUser.data)];
            case 22:
                _f.sent();
                return [3 /*break*/, 24];
            case 23:
                error_2 = _f.sent();
                console.log(error_2);
                return [3 /*break*/, 24];
            case 24: return [2 /*return*/];
        }
    });
}
export function signInUserChangePassword(_a) {
    var resCurrentUser, notificationsRequest, challengesRequest, error_3, oneSignal, error_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                resCurrentUser = null;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 7, , 8]);
                return [4 /*yield*/, call(api.auth.signInUser, payload)];
            case 2:
                resCurrentUser = _b.sent();
                return [4 /*yield*/, put(authLoginSuccess(resCurrentUser.data))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(signInUserSuccess())];
            case 4:
                _b.sent();
                notificationsRequest = {
                    pagination: {
                        page: 1,
                        perPage: 5,
                    },
                };
                return [4 /*yield*/, put(fetchMyNotificationsRequest(notificationsRequest))];
            case 5:
                _b.sent();
                challengesRequest = {
                    pagination: {
                        page: 1,
                        perPage: 10,
                    },
                };
                return [4 /*yield*/, put(fetchMyChallengesRequest(challengesRequest))];
            case 6:
                _b.sent();
                return [3 /*break*/, 8];
            case 7:
                error_3 = _b.sent();
                console.log(error_3);
                return [3 /*break*/, 8];
            case 8:
                _b.trys.push([8, 11, , 12]);
                oneSignal = OneSignalService.getInstance();
                return [4 /*yield*/, call([oneSignal, 'sendUserId'])];
            case 9:
                _b.sent();
                return [4 /*yield*/, call([oneSignal, 'sendTags'], resCurrentUser.data)];
            case 10:
                _b.sent();
                return [3 /*break*/, 12];
            case 11:
                error_4 = _b.sent();
                console.log(error_4);
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export default function signInPageSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(SIGN_IN_USER_REQUEST, signInUser)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(SIGN_IN_USER_CHANGE_PASSWORD_REQUEST, signInUserChangePassword)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
