import { CONTRIBUTOR_USER_CONTRIBUTIONS_FAILURE, CONTRIBUTOR_USER_CONTRIBUTIONS_REQUEST, CONTRIBUTOR_USER_CONTRIBUTIONS_SUCCESS, } from '../constants';
export var fetchContributorUserContributionsRequest = function (payload) { return ({
    type: CONTRIBUTOR_USER_CONTRIBUTIONS_REQUEST,
    payload: payload,
}); };
export var fetchContributorUserContributionsSuccess = function (payload) { return ({
    type: CONTRIBUTOR_USER_CONTRIBUTIONS_SUCCESS,
    payload: payload,
}); };
export var fetchContributorUserContributionsError = function (error) { return ({
    type: CONTRIBUTOR_USER_CONTRIBUTIONS_FAILURE,
    payload: error,
}); };
