import { Button, Field } from 'components';
import { fieldBlur, fieldOnFocus } from 'containers/App/actions';
import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAction } from 'utils/hooks';
import { Template } from '../Template';
import { SuccessFeedbackType } from '../types';
import messages from './messages';
import { ButtonWrapper } from './styles';
export var EditTelephone = function (_a) {
    var onSubmit = _a.onSubmit, onBack = _a.onBack, profile = _a.profile;
    var formatMessage = useIntl().formatMessage;
    var fieldOnFocusActionCallback = useAction(fieldOnFocus);
    var fieldBlurActionCallback = useAction(fieldBlur);
    return (React.createElement(Template, { onBack: onBack, pageTitle: formatMessage(messages.title), fieldDescription: formatMessage(messages.fieldDescription), fieldInformation: formatMessage(messages.fieldInformation) },
        React.createElement(Formik, { initialValues: {
                phone: profile === null || profile === void 0 ? void 0 : profile.phone,
            }, enableReinitialize: true, onSubmit: function (values) {
                return onSubmit(values, SuccessFeedbackType.Telephone);
            } }, function (_a) {
            var isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, values = _a.values;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Field, { name: "phone", label: formatMessage(messages.telephone), onFocus: fieldOnFocusActionCallback, onBlur: fieldBlurActionCallback, onChange: handleChange, value: values.phone }),
                React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { type: "submit", disabled: isSubmitting }, formatMessage(messages.updateTelephone)))));
        })));
};
