import { Button, LoadingIndicator } from 'components';
import { Title } from 'containers/Home';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { CardListDesktop, CardListMobile } from './CardList';
import { ButtonWrapper, LoadingWrapper, Note, StyledSection, StyledSubtitle, } from './styles';
export var LatestNews = memo(function (_a) {
    var _b = _a.latestNews, latestNews = _b === void 0 ? [] : _b, _c = _a.loading, loading = _c === void 0 ? false : _c, _d = _a.hasError, hasError = _d === void 0 ? false : _d;
    var formatMessage = useIntl().formatMessage;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    function handleOpenBlog() {
        window.open('https://blog.jaccede.com', '_blank');
    }
    if (loading) {
        return (React.createElement(LoadingWrapper, null,
            React.createElement(LoadingIndicator, null)));
    }
    if (hasError) {
        return null;
    }
    return (React.createElement(StyledSection, null,
        React.createElement(Title, null, formatMessage({ id: 'containers.homeLatestNews.title' })),
        React.createElement(StyledSubtitle, null, formatMessage({ id: 'containers.homeLatestNews.subtitle' })),
        isMobile || isTabletOrMobile ? (React.createElement(CardListMobile, { latestNews: latestNews })) : (React.createElement(CardListDesktop, { latestNews: latestNews })),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { onClick: handleOpenBlog }, formatMessage({ id: 'containers.homeLatestNews.buttonText' }))),
        React.createElement(Note, null, formatMessage({ id: 'containers.homeLatestNews.note' }))));
});
