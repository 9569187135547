import { createSelector } from 'reselect';
import { uploadCoverImageTeamReducer } from './reducer';
export var selectUploadCoverImageTeamDomain = function (state) {
    return state.uploadCoverImageOnTeam || uploadCoverImageTeamReducer;
};
export var makeSelectUploadCoverImageTeamLoading = function () {
    return createSelector(selectUploadCoverImageTeamDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectUploadCoverImageTeamHasError = function () {
    return createSelector(selectUploadCoverImageTeamDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectUploadCoverImageTeamError = function () {
    return createSelector(selectUploadCoverImageTeamDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectUploadCoverImageTeamItem = function () {
    return createSelector(selectUploadCoverImageTeamDomain, function (_a) {
        var item = _a.item;
        return item;
    });
};
export var makeUploadCoverImageTeam = function () {
    return createSelector(selectUploadCoverImageTeamDomain, function (subState) { return subState; });
};
export default makeUploadCoverImageTeam;
