var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Alert } from 'antd';
import { rem, shade } from 'polished';
import styled from 'styled-components';
import { colors } from 'styles';
export var AlertWrapper = styled(Alert)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  border-radius: ", ";\n\n  &.ant-alert {\n    position: fixed;\n    z-index: 5000;\n    border-radius: ", ";\n    top: ", ";\n    right: ", ";\n    left: ", ";\n    padding: ", " !important;\n  }\n\n  .ant-alert-message {\n    color: ", ";\n  }\n\n  &.ant-alert-success {\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  &.ant-alert-info {\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  &.ant-alert-warning {\n    background-color: ", ";\n    border-color: ", ";\n\n    .ant-alert-message {\n      color: ", ";\n    }\n  }\n\n  &.ant-alert-error {\n    background-color: ", ";\n    border-color: ", ";\n  }\n"], ["\n  font-size: ", ";\n  border-radius: ", ";\n\n  &.ant-alert {\n    position: fixed;\n    z-index: 5000;\n    border-radius: ", ";\n    top: ", ";\n    right: ", ";\n    left: ", ";\n    padding: ", " !important;\n  }\n\n  .ant-alert-message {\n    color: ", ";\n  }\n\n  &.ant-alert-success {\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  &.ant-alert-info {\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  &.ant-alert-warning {\n    background-color: ", ";\n    border-color: ", ";\n\n    .ant-alert-message {\n      color: ", ";\n    }\n  }\n\n  &.ant-alert-error {\n    background-color: ", ";\n    border-color: ", ";\n  }\n"])), rem(16), rem(5), rem(10), rem(30), rem(30), rem(30), rem(20), colors.base.white, colors.base.primary, shade(0.2, colors.base.primary), colors.base.info, shade(0.2, colors.base.info), colors.base.warning, shade(0.2, colors.base.warning), colors.space.tint900, colors.base.danger, shade(0.2, colors.base.danger));
var templateObject_1;
