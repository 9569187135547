import { createSelector } from 'reselect';
import { weekUsersInitialState } from './reducer';
export var selectWeekUsersDomain = function (state) {
    return state.weekUsers || weekUsersInitialState;
};
export var makeSelectWeekUsersLoading = function () {
    return createSelector(selectWeekUsersDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectWeekUsersHasError = function () {
    return createSelector(selectWeekUsersDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectWeekUsersError = function () {
    return createSelector(selectWeekUsersDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectWeekUsersPages = function () {
    return createSelector(selectWeekUsersDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectWeekUsersCurrentPage = function () {
    return createSelector(selectWeekUsersDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectWeekUsersTotal = function () {
    return createSelector(selectWeekUsersDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectWeekUsersItems = function () {
    return createSelector(selectWeekUsersDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeWeekUsers = function () {
    return createSelector(selectWeekUsersDomain, function (subState) { return subState; });
};
export default makeWeekUsers;
