var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var textColor = theme('mode', {
    light: colors.space.tint600,
    dark: colors.space.tint200,
});
var boxShadowColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  border-radius: ", ";\n  box-shadow: ", " ", " ", " ", ";\n  display: flex;\n  margin-top: ", ";\n  padding: ", ";\n"], ["\n  align-items: center;\n  border-radius: ", ";\n  box-shadow: ", " ", " ", " ", ";\n  display: flex;\n  margin-top: ", ";\n  padding: ", ";\n"])), rem(16), rem(0), rem(2), rem(3), boxShadowColor, rem(20), rem(20));
export var MedalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(10));
export var AchievementContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  padding-right: ", ";\n"], ["\n  flex: 1;\n  padding-right: ", ";\n"])), rem(20));
export var AchievementTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: ", ";\n"])), rem(18), fonts.weight.bold, rem(8));
export var AchievementDescription = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), textColor, rem(16));
export var ProgressContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: ", ";\n  margin-right: ", ";\n"], ["\n  width: ", ";\n  margin-right: ", ";\n"])), rem(175), rem(25));
export var ProgressText = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n"], ["\n  font-size: ", ";\n  color: ", ";\n"])), rem(15), textColor);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
