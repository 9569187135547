var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { colors, fonts } from 'styles';
export var NewsletterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n\n  > div {\n    max-width: ", ";\n    padding: ", " 0;\n  }\n"], ["\n  background-color: ", ";\n\n  > div {\n    max-width: ", ";\n    padding: ", " 0;\n  }\n"])), colors.base.secondary, rem(427), rem(110));
export var Title = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n\n  ",
    ";\n"])), colors.base.white, rem(36), fonts.weight.semibold, rem(16), media.lessThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: ", ";\n  "], ["\n    font-size: ", ";\n  "])), rem(17)));
var templateObject_1, templateObject_2, templateObject_3;
