import { Section } from 'containers/Home';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConnectNewsletter } from './Newsletter';
import { NewsletterContainer, Title } from './styles.';
export var SignUpNewsletter = memo(function () { return (React.createElement(React.Fragment, null,
    React.createElement(Section, { background: "secondary" },
        React.createElement(NewsletterContainer, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "containers.home.signUpNewsletter.title" })),
            React.createElement(ConnectNewsletter, null))))); });
