/* eslint-disable no-unused-expressions */
/* eslint-disable lodash/prefer-lodash-method */
import { avatarImage1 } from 'assets/images';
import { Avatar, Ellipsis, Rate } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { makeSelectIsLoggedIn, makeSelectUserSlug, } from 'containers/Auth/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { ReportReview } from 'containers/Reviews/ReportReview';
import { activeReview, setReportReviewVisible, } from 'containers/Reviews/store/addReview/actions';
import { makeSelectorReportReviewVisible } from 'containers/Reviews/store/addReview/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import format from 'date-fns/format';
import * as locales from 'date-fns/locale';
import { useAction } from 'hooks';
import { Medal200Desktop } from 'icons/medal';
import { Alert } from 'icons/mono';
import { rem } from 'polished';
import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { colors } from 'styles';
import { useSelector } from 'utils/hooks';
import { AvatarWrapper, Container, Fullname, Header, MedalWrapper, ReportReviewButton, ReviewDate, ReviewText, UserInfo, Username, UserWrapper, } from './styles';
export var UserReview = memo(function (_a) {
    var review = _a.review, rows = _a.rows, clickable = _a.clickable, placeId = _a.placeId;
    var _b, _c, _d, _e, _f, _g, _h;
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var isAuthenticated = useSelector(makeSelectIsLoggedIn());
    var keyboard = useSelector(makeSelectUseKeyboard());
    var currentLocale = useSelector(makeSelectLocale());
    var userSlug = useSelector(makeSelectUserSlug());
    var reportVisible = useSelector(makeSelectorReportReviewVisible());
    var setReportReviewVisibleCallback = useAction(setReportReviewVisible);
    var activeReviewCallback = useAction(activeReview);
    var allowedLocales = locales;
    var reviewPageURL = paths.reviewDetailPage
        .replace(':placeId', String(placeId))
        .replace(':reviewId', String(review.id));
    var usersPageURL = userSlug === review.creator.slug
        ? paths.profilePage
        : paths.usersPage.replace(':userSlug', review.creator.slug);
    var datePattern = formatMessage({
        id: 'components.userReview.datePattern',
    });
    var formattedDate = format(new Date(review.created), datePattern, {
        locale: allowedLocales[currentLocale],
    });
    var openReport = function (reviewId) {
        if (!isAuthenticated) {
            history.push(paths.signInPage, {
                from: location.pathname,
            });
        }
        else {
            activeReviewCallback(reviewId);
            setReportReviewVisibleCallback(true);
        }
    };
    var closeReport = function () {
        setReportReviewVisibleCallback(false);
    };
    useEffect(function () {
        var rate = window.document.querySelectorAll('.ant-rate, .ant-rate-star > div');
        rate === null || rate === void 0 ? void 0 : rate.forEach(function (node) { return node.removeAttribute('tabindex'); });
    }, [review]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { isKeyboardMode: keyboard },
            React.createElement(Header, null,
                React.createElement(UserWrapper, { to: usersPageURL, tabIndex: 0 },
                    React.createElement(AvatarWrapper, null,
                        React.createElement(Avatar, { src: ((_c = (_b = review.creator) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.urlThumb) || avatarImage1, alt: "Avatar 1", size: "small", shape: "rounded" }),
                        React.createElement(MedalWrapper, null,
                            React.createElement(Medal200Desktop, { size: rem(14) }))),
                    React.createElement(UserInfo, null,
                        ((_d = review.creator) === null || _d === void 0 ? void 0 : _d.pseudo) !== review.creator.slug && (React.createElement(Fullname, null, (_e = review.creator) === null || _e === void 0 ? void 0 : _e.pseudo)),
                        ((_f = review.creator) === null || _f === void 0 ? void 0 : _f.pseudo) !== review.creator.slug ? (React.createElement(Username, null,
                            "(@", (_g = review.creator) === null || _g === void 0 ? void 0 :
                            _g.slug,
                            ")")) : (React.createElement(Fullname, null,
                            "@", (_h = review.creator) === null || _h === void 0 ? void 0 :
                            _h.slug)))),
                React.createElement(ReviewDate, null,
                    React.createElement("span", null, formattedDate),
                    isAuthenticated && review.creator.slug !== userSlug && (React.createElement(ReportReviewButton, { onClick: function () { return openReport(review.id); }, tabIndex: 0, title: "Report comment" },
                        React.createElement(Alert, { size: rem(18), color: colors.space.tint650 }))))),
            clickable && placeId ? (React.createElement(Link, { to: reviewPageURL, tabIndex: 0 },
                React.createElement(Rate, { value: review.rating, size: 13 }),
                React.createElement(ReviewText, null, rows ? (React.createElement(Ellipsis, { rows: rows }, review.body)) : (review.body)))) : (React.createElement(React.Fragment, null,
                React.createElement(Rate, { value: review.rating, size: 13 }),
                React.createElement(ReviewText, null, rows ? (React.createElement(Ellipsis, { rows: rows }, review.body)) : (review.body))))),
        reportVisible && (React.createElement(ReportReview, { onClose: closeReport, visible: !!reportVisible }))));
});
