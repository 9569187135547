import { PageHeader, PageWrapper, PlacePhotoScroll } from 'components';
import { AddPlaceToMap } from 'containers/ExplorePlaces';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { BookmarkButton } from 'containers/PlaceDetails/BookmarkButton';
import { useSelector } from 'hooks';
import { ArrowBack } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { AddPlaceWrapper, Container, MapWrapper, PlacesWrapper, } from './styles';
export var CategoryListViewPage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    // mock data
    var places = [
        {
            id: 1,
            name: 'Hotel Ibis Oujda',
            images: [
                'https://cdn1.jaccede.com/uploads/places/imgs/Hotel_Sofitel_Jequitimar_Guaruja_1511284347548_20171121-1232-d47a2eb1_large.jpeg',
                'https://cdn1.jaccede.com/uploads/places/imgs/Hotel_Sofitel_Jequitimar_Guaruja_1511285921932_20171121-3847-e379bfa3_large.jpeg',
                'https://cdn1.jaccede.com/uploads/places/imgs/Hotel_Sofitel_Jequitimar_Guaruja_1511283981417_20171121-0648-c3273d22_large.jpeg',
            ],
            category: 'Lodging',
            rating: 4.5,
            votes: 440,
        },
        {
            id: 2,
            name: 'Cabaña Del Primo',
            images: [
                'https://cdn1.jaccede.com/uploads/places/imgs/exterieur_58_20150223-3712-OHI_large.jpg',
                'https://cdn1.jaccede.com/uploads/places/imgs/chambre_bas_7_20150223-3527-CB6_large.jpg',
                'https://cdn1.jaccede.com/uploads/places/imgs/chambre_bas_sdb_20150223-3232-CFH_large.jpg',
            ],
            category: 'Cultural Center',
            rating: 4,
            votes: 243,
        },
        {
            id: 3,
            name: 'Ouro Minas Palace Hotel',
            images: [
                'https://cdn1.jaccede.com/uploads/places/imgs/ACCORH_20160120_novotel_santos_0029_low_20171128-0733-1cc578db_large.jpeg',
                'https://cdn1.jaccede.com/uploads/places/imgs/ACCORH_20160408_novotel_santos_0091_20171128-0516-b4d3fc40_large.jpeg',
                'https://cdn1.jaccede.com/uploads/places/imgs/ACCORH_20160408_novotel_santos_0108_20171128-0257-2b8d72a3_large.jpeg',
            ],
            category: 'Hotel',
            rating: 5,
            votes: 412,
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.categoryListViewPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.categoryListViewPage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.categoryListViewPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.categoryListViewPage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(PageHeader, { title: formatMessage({ id: 'pages.categoryListViewPage.title' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), 
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onBack: function () { }, sticky: true }),
            React.createElement(Container, null,
                React.createElement(MapWrapper, null, "Map"),
                map(places, function (place) { return (React.createElement(PlacesWrapper, { key: place.id },
                    React.createElement(PlacePhotoScroll
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    , { 
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        handleFavority: function () { }, 
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        handleModal: function () { }, photos: [], bookmarkButton: React.createElement(BookmarkButton, { loading: false, 
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            addToBookmark: function () { }, 
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            removeFromBookmark: function () { }, inBookmark: false }) }))); }),
                React.createElement(AddPlaceWrapper, null,
                    React.createElement(AddPlaceToMap, { title: formatMessage({
                            id: 'pages.explorePlacesPage.addPlaceNotFound.title',
                        }), tagline: formatMessage({
                            id: 'pages.explorePlacesPage.addPlaceNotFound.tagline',
                        }) }))))));
};
