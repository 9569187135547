var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var DarkModePurple = memo(function (props) { return (React.createElement("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.848 32.731a11.87 11.87 0 01-8.447-3.499c-4.659-4.658-4.659-12.238 0-16.896a11.87 11.87 0 018.447-3.499c3.192 0 6.192 1.243 8.448 3.5a11.869 11.869 0 013.5 8.448c0 3.19-1.243 6.19-3.5 8.447a11.868 11.868 0 01-8.448 3.5z", fill: "#773BB3", fillOpacity: ".8" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.848 10.232a10.482 10.482 0 00-7.461 3.091c-4.114 4.114-4.114 10.808 0 14.922a10.483 10.483 0 007.461 3.09c2.819 0 5.468-1.096 7.461-3.09 4.114-4.114 4.114-10.808 0-14.922a10.48 10.48 0 00-7.46-3.09zm0 22.5a11.87 11.87 0 01-8.447-3.5c-4.659-4.658-4.659-12.238 0-16.896a11.87 11.87 0 018.447-3.499c3.192 0 6.192 1.243 8.448 3.5a11.869 11.869 0 013.5 8.448c0 3.19-1.243 6.19-3.5 8.447a11.868 11.868 0 01-8.448 3.5z", fill: "#552A80" }),
    React.createElement("path", { opacity: ".3", fillRule: "evenodd", clipRule: "evenodd", d: "M12.757 27.876c-3.916-3.917-3.916-10.266 0-14.183 3.916-3.916 10.266-3.916 14.183 0", fill: "#BCBCBC" }))); });
