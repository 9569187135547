var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Linkedin = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M32.6806 6H8.47794C7.15547 6 6 6.95156 6 8.2585V32.5149C6 33.829 7.15547 35 8.47794 35H32.6734C34.003 35 35 33.8212 35 32.5149V8.2585C35.0077 6.95156 34.003 6 32.6806 6ZM14.9893 30.1729H10.8348V17.2556H14.9893V30.1729ZM13.0558 15.2917H13.026C11.6964 15.2917 10.8355 14.3019 10.8355 13.0629C10.8355 11.8013 11.7191 10.8348 13.0784 10.8348C14.4378 10.8348 15.2696 11.7942 15.2994 13.0629C15.2987 14.3019 14.4378 15.2917 13.0558 15.2917ZM30.1728 30.1729H26.0184V23.11C26.0184 21.4179 25.4138 20.2618 23.9107 20.2618C22.7623 20.2618 22.0827 21.0386 21.7804 21.7953C21.6671 22.0672 21.6366 22.4374 21.6366 22.8155V30.1729H17.4822V17.2556H21.6366V19.0532C22.2412 18.1923 23.1857 16.9533 25.3833 16.9533C28.1105 16.9533 30.1735 18.7509 30.1735 22.6265L30.1728 30.1729Z", fill: color })));
});
