import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Score, UserName } from './styles';
var UserListItem = function (_a) {
    var user = _a.user, _b = _a.scoreKind, scoreKind = _b === void 0 ? 'default' : _b, _c = _a.points, points = _c === void 0 ? 0 : _c, redirectLink = _a.redirectLink, highlightedBackground = _a.highlightedBackground;
    var _d;
    var history = useHistory();
    var location = useLocation();
    function handleUserClicked() {
        history.push(redirectLink, { from: location.pathname });
    }
    return user ? (React.createElement(Container, { key: user.id, highlightedBackground: highlightedBackground, onClick: handleUserClicked },
        React.createElement(Avatar, { src: ((_d = user === null || user === void 0 ? void 0 : user.avatar) === null || _d === void 0 ? void 0 : _d.urlThumb) || avatarImage1, size: "small", alt: "User profile image" }),
        React.createElement(UserName, null, user.firstName
            ? (user.firstName || '') + " " + (user.lastName || '')
            : "@" + user.slug),
        React.createElement(Score, { kind: scoreKind }, points))) : null;
};
export default UserListItem;
