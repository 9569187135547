import { emoticonFlyingLeaves } from 'assets/images';
import { EmptyListText, LoadingIndicator } from 'components';
import map from 'lodash/map';
import React from 'react';
import PlaceListItem from '../PlaceListItem';
import { Container, EmoticonImage } from './styles';
var Items = function (_a) {
    var data = _a.data, _b = _a.loading, loading = _b === void 0 ? true : _b, emptyListText = _a.emptyListText;
    var _c;
    return (React.createElement(React.Fragment, null, loading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(Container, null, ((_c = data === null || data === void 0 ? void 0 : data.items) === null || _c === void 0 ? void 0 : _c.length) > 0 ? (React.createElement(React.Fragment, null, map(data.items, function (place) { return (React.createElement(PlaceListItem, { key: place.id, mainImage: place.mainImage, placeId: place.googlePlaceId, name: place.name, category: place.category, slug: place.slug })); }))) : (React.createElement(EmptyListText, null,
        React.createElement("span", null,
            emptyListText,
            " "),
        React.createElement(EmoticonImage, { src: emoticonFlyingLeaves, alt: "emoticon" })))))));
};
export default Items;
