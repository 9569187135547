import map from 'lodash/map';
import React, { memo } from 'react';
import { Content } from '../Content';
import { SideList } from '../SideList';
import { AboutUsContainer, Section } from './styles';
export var Mobile = function (_a) {
    var menuData = _a.menuData, listItemSelected = _a.listItemSelected, sectionContent = _a.sectionContent, setListItemSelected = _a.setListItemSelected, sectionTitle = _a.sectionTitle;
    var Menu = memo(function () { return (React.createElement(Section, { opened: listItemSelected }, map(menuData, function (_a, idx) {
        var title = _a.title, items = _a.children;
        return (React.createElement(SideList, { key: idx, title: title, items: items }));
    }))); });
    return (React.createElement(AboutUsContainer, { opened: listItemSelected },
        React.createElement(Menu, null),
        React.createElement(Content, { sectionTitle: sectionTitle, sectionContent: sectionContent, setListItemSelected: setListItemSelected, listItemSelected: listItemSelected })));
};
