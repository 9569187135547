var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Modal, Popover, Spin } from 'components';
import { lockConversationNotification, quitGroupConversation, unlockConversationNotification, } from 'containers/Chat/actions';
import { makeSelectorConversation, makeSelectorConversationOptionsLoading, } from 'containers/Chat/selectors';
import { useAction, useSelector } from 'hooks';
import { Bell, Block, EllipsisHorizontal, User } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { memo, useState } from 'react';
import { Content, ModalContent, PopoverItem, Target } from './styles';
export var SettingsMenu = memo(function (_a) {
    var isTeam = _a.isTeam;
    var _b = __read(useState(false), 2), visible = _b[0], setVisible = _b[1];
    var _c = __read(useState(false), 2), showReportModal = _c[0], setShowReportModal = _c[1];
    var isOptionsLoading = useSelector(makeSelectorConversationOptionsLoading());
    var conversation = useSelector(makeSelectorConversation());
    var lockNotificationCallback = useAction(lockConversationNotification);
    var unlockNotificationCallback = useAction(unlockConversationNotification);
    var quitConversationCallback = useAction(quitGroupConversation);
    var handleShowModal = function () {
        setShowReportModal(true);
    };
    var handleCancelModal = function () {
        setShowReportModal(false);
    };
    var toggleNotification = function () {
        if (conversation.acceptNotification) {
            lockNotificationCallback(conversation.id);
            return;
        }
        unlockNotificationCallback(conversation.id);
    };
    var content = (React.createElement(Spin, { spinning: isOptionsLoading },
        React.createElement(Content, null,
            React.createElement(React.Fragment, null,
                isTeam && (React.createElement(PopoverItem, { role: "button", "aria-hidden": "false", onClick: function () { return quitConversationCallback(conversation.id); } },
                    "Leave the group ",
                    React.createElement(Block, { size: rem(18) }))),
                !isTeam && (React.createElement(PopoverItem, { role: "button", "aria-hidden": "false" },
                    "Block user ",
                    React.createElement(Block, { size: rem(18) }))),
                React.createElement(PopoverItem, { role: "button", "aria-hidden": "false", onClick: toggleNotification },
                    !conversation.acceptNotification
                        ? 'Enable notifications'
                        : 'Disable notifications',
                    ' ',
                    React.createElement(Bell, { size: rem(18) })),
                isTeam && (React.createElement(PopoverItem, { role: "button", "aria-hidden": "false", onClick: handleShowModal },
                    "Participants ",
                    React.createElement(User, { size: rem(18) })))))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { content: content, trigger: "click", visible: visible, placement: "bottomRight", onVisibleChange: function () { return setVisible(!visible); } },
            React.createElement(Target, { variant: "link", tabIndex: 0, "aria-haspopup": "true" },
                React.createElement(EllipsisHorizontal, { size: rem(18) }))),
        React.createElement(Modal, { visible: showReportModal, onCancel: handleCancelModal, title: "List of participants in the conversation", footer: null },
            React.createElement(ModalContent, null,
                React.createElement("ol", null, map(conversation === null || conversation === void 0 ? void 0 : conversation.targets, function (t, index) { return (React.createElement("li", { key: index }, t.pseudo)); }))))));
});
