import React, { memo } from 'react';
export var HomeGoodsStore = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#000", opacity: "0.15", style: { fill: '#000' }, d: "M27.733 27.276c0 2.104-5.39 3.81-12.038 3.81s-12.038-1.706-12.038-3.81c0-2.104 5.39-3.81 12.038-3.81s12.038 1.706 12.038 3.81z" }),
        React.createElement("path", { fill: "#936f3e", style: { fill: '#936f3e' }, d: "M7.042 6.007c-4.758 4.759-4.758 12.474 0 17.232l8.617 8.616 8.616-8.616c4.759-4.759 4.759-12.474 0-17.232s-12.474-4.759-17.233 0z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M10.75 18.601c0.060 0.65-0.064 1.319-0.348 1.624-0.272 0.293-0.813 0.336-1.157 0.016-0.28-0.261-0.433-0.736-0.387-1.193l0.553-5.862c-0.020-0.382-0.79-0.848-0.802-1.93l-0.020-2.277c-0.003-0.123 0.030-0.211 0.090-0.274 0.072-0.076 0.181-0.093 0.27-0.010 0.063 0.059 0.079 0.17 0.084 0.268l0.007 1.956c0.010 0.27 0.063 0.392 0.14 0.464 0.114 0.106 0.337 0.123 0.456-0.004 0.058-0.063 0.080-0.138 0.074-0.335l-0.015-2.152c0.006-0.185 0.027-0.26 0.075-0.311 0.070-0.076 0.218-0.081 0.32 0.013 0.064 0.059 0.080 0.145 0.087 0.317l-0.012 2.13c-0.005 0.185 0.047 0.281 0.11 0.34 0.115 0.108 0.323 0.114 0.432-0.002 0.059-0.062 0.067-0.126 0.061-0.322l0.036-2.155c-0.004-0.098 0.007-0.161 0.054-0.211 0.083-0.089 0.218-0.082 0.307 0.001 0.051 0.048 0.067 0.133 0.070 0.256l-0.003 2.351c-0.003 0.972-0.772 1.312-0.798 1.966l0.316 5.337z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M19.553 19.2h-6.909c0 1.829 0.524 3.657 1.335 4.876h4.155c0.26-0.61 0.489-0.914 0.682-1.219h0.72c1.12 0 2.031-0.825 2.031-1.829s-0.904-1.829-2.015-1.829zM19.576 21.943h-0.441c0.15-0.61 0.253-1.044 0.311-1.841 0 0 1.27-0.011 1.269 0.89-0.001 0.492-0.521 0.952-1.139 0.952z" }),
        React.createElement("path", { fill: "#fff", style: { fill: '#fff' }, d: "M18.606 13.634h4.063l-1.182-7.194h-6.904l-1.183 7.194h4.179v2.967h-3.278v0.691h7.466v-0.691h-3.162v-2.967z" })));
});
