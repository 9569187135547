var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Menu = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M34.3631 11.3333H5.63686C4.73331 11.3333 4 10.5867 4 9.66667C4 8.74667 4.73331 8 5.63686 8H34.3631C35.2667 8 36 8.74667 36 9.66667C36 10.5867 35.2667 11.3333 34.3631 11.3333Z", fill: color }),
        React.createElement("path", { d: "M34.3631 22H5.63686C4.73331 22 4 21.2533 4 20.3333C4 19.4133 4.73331 18.6667 5.63686 18.6667H34.3631C35.2667 18.6667 36 19.4133 36 20.3333C36 21.2533 35.2667 22 34.3631 22Z", fill: color }),
        React.createElement("path", { d: "M34.3631 32H5.63686C4.73331 32 4 31.2533 4 30.3333C4 29.4133 4.73331 28.6667 5.63686 28.6667H34.3631C35.2667 28.6667 36 29.4133 36 30.3333C36 31.2533 35.2667 32 34.3631 32Z", fill: color })));
});
