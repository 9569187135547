var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import { colors } from 'styles';
export var CarouselContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  display: flex;\n"], ["\n  position: relative;\n  width: ", ";\n  display: flex;\n"])), rem(201));
var transitionSpeed = 0.5;
export var AvatarsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  position: relative;\n  transition: ", "s;\n  width: ", ";\n  width: 100%;\n\n  .selected-avatar {\n    height: ", ";\n    transition: ", "s;\n    width: ", ";\n  }\n\n  .not-selected-avatar {\n    height: ", ";\n    transition: ", "s;\n    width: ", ";\n  }\n\n  .avatar {\n    margin-left: ", ";\n\n    &:first-child {\n      margin-left: 0;\n    }\n  }\n\n  img {\n    border-radius: 30%;\n    height: 100%;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  position: relative;\n  transition: ", "s;\n  width: ", ";\n  width: 100%;\n\n  .selected-avatar {\n    height: ", ";\n    transition: ", "s;\n    width: ", ";\n  }\n\n  .not-selected-avatar {\n    height: ", ";\n    transition: ", "s;\n    width: ", ";\n  }\n\n  .avatar {\n    margin-left: ", ";\n\n    &:first-child {\n      margin-left: 0;\n    }\n  }\n\n  img {\n    border-radius: 30%;\n    height: 100%;\n    width: 100%;\n  }\n"])), transitionSpeed, rem(201), rem(79), transitionSpeed, rem(77), rem(59), transitionSpeed, rem(57), rem(5));
export var AvatarOverflow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n  overflow: hidden;\n  position: relative;\n  width: ", ";\n"], ["\n  height: ", ";\n  overflow: hidden;\n  position: relative;\n  width: ", ";\n"])), rem(79), rem(201));
export var navigationButtonBase = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  background: ", ";\n  border-radius: 100%;\n  border: ", " solid ", ";\n  box-shadow: 0 ", " ", " ", ";\n  cursor: pointer;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  margin-top: -", ";\n  position: absolute;\n  top: 50%;\n  width: ", ";\n  z-index: 10;\n"], ["\n  align-items: center;\n  background: ", ";\n  border-radius: 100%;\n  border: ", " solid ", ";\n  box-shadow: 0 ", " ", " ", ";\n  cursor: pointer;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  margin-top: -", ";\n  position: absolute;\n  top: 50%;\n  width: ", ";\n  z-index: 10;\n"])), colors.base.white, rem(1), colors.space.tint100, rem(2), rem(6), rgba(colors.base.black, 0.2), rem(30), rem(15), rem(30));
export var PrevButton = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  left: -", ";\n"], ["\n  ", "\n  left: -", ";\n"])), navigationButtonBase, rem(24));
export var NextButton = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  right: -", ";\n"], ["\n  ", "\n  right: -", ";\n"])), navigationButtonBase, rem(24));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
