import { rem } from 'polished';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, } from 'recharts';
import { colors } from 'styles';
import CustomChartTooltip from './CustomChartTooltip';
import { Container, LineChartWrapper, Title } from './styles';
var Activities = function (_a) {
    var series = _a.series;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var aspect = isMobile || isTabletOrMobile ? 1.6 : 4;
    var height = isMobile || isTabletOrMobile ? rem(230) : rem(300);
    function renderCustomChartTooltip(_a) {
        var active = _a.active, payload = _a.payload;
        return React.createElement(CustomChartTooltip, { active: active, payload: payload });
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { id: "containers.profile.activities.header" })),
        React.createElement(LineChartWrapper, null, series && series.length > 0 && (React.createElement(ResponsiveContainer, { width: "99%", aspect: aspect, height: height },
            React.createElement(LineChart, { data: series, margin: {
                    top: 30,
                    right: 30,
                    left: 20,
                    bottom: 5,
                } },
                React.createElement(CartesianGrid, { strokeDasharray: "3 3", horizontal: false }),
                React.createElement(XAxis, { dataKey: "date", axisLine: false }),
                React.createElement(Tooltip, { content: renderCustomChartTooltip }),
                React.createElement(Legend, null),
                React.createElement(Line, { type: "monotone", dataKey: "value", stroke: colors.base.secondary, strokeWidth: rem(4), activeDot: {
                        r: 8,
                        strokeWidth: 4,
                        fill: colors.base.white,
                        stroke: colors.base.secondary,
                    } })))))));
};
export default memo(Activities);
