import subFooterLinksReducer from 'components/SubFooter/reducer';
import { connectRouter } from 'connected-react-router';
import accountActivationReducer from 'containers/AccountActivated/reducer';
import appReducer from 'containers/App/reducer';
import { AUTH_LOGOUT_SUCCESS } from 'containers/Auth/constants';
import authReducer from 'containers/Auth/reducer';
import { membersScoreBoardOnChallengeReducer } from 'containers/ChallengeDetail/store/membersScoreBoard/reducer';
import { challengeDetailReducer } from 'containers/ChallengeDetail/store/reducer';
import { teamsOnChallengeReducer } from 'containers/ChallengeDetail/store/teamsOnChallenge/reducer';
import { addCurrentUserJoinTeamReducer } from 'containers/ChallengeJoinTeam/store/joinTeam/reducer';
import { membersOnTeamReducer } from 'containers/ChallengeMembersTeam/store/getMembersOfTeam/reducer';
import { teamDetailReducer } from 'containers/ChallengeMembersTeam/store/getTeamOnChallenge/reducer';
import { uploadCoverImageTeamReducer } from 'containers/ChallengeMembersTeam/store/uploadCoverImage/reducer';
import { publicChallengesReducer } from 'containers/Challenges/store/reducers';
import chatReducer from 'containers/Chat/reducer';
import { contributorUserAccountReducer, contributorUserContributionsReducer, contributorUserFavoritesReducer, contributorUserProfileReducer, } from 'containers/Contributors/reducers';
import editMyProfileReducer from 'containers/EditMyProfile/reducer';
import addNewPlaceReducer from 'containers/ExplorePlaces/AddNewPlace/reducer';
import { placesAroundReducer } from 'containers/ExplorePlaces/store/reducers';
import filterPlacesReducer from 'containers/FilterPlaces/reducer';
import { allUsersReducer, monthUsersReducer, weekUsersReducer, } from 'containers/GlobalScoreboard/store/reducers';
import signUpNewsLetterReducer from 'containers/Home/SignUpNewsletter/reducer';
import homeNewsReducer from 'containers/Home/store/homeNews/reducer';
import homeLatestNewsReducer from 'containers/Home/store/latestNews/reducer';
import homeMostActiveUsersReducer from 'containers/Home/store/mostActive/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import mapReducer from 'containers/Map/reducer';
import oAuthLoginPageReducer from 'containers/OAuth/reducer';
import placeDetailsReducer from 'containers/PlaceDetails/reducer';
import placePhotosReducer from 'containers/PlacePhotos/reducer';
import { myChallengesReducer, myContributionsReducer, myFavoritesReducer, myProfileReducer, } from 'containers/Profile/reducers';
import { myNotificationsReducer } from 'containers/Profile/reducers/myNotifications';
import resendEmailActivationReducer from 'containers/ResendEmailActivation/reducer';
import { addReviewReducer, placeReviewsReducer, } from 'containers/Reviews/store/reducers';
import signInEmailReducer from 'containers/SignInEmail/reducer';
import signUpEmailReducer from 'containers/SignUpEmail/reducer';
import { subscribersReducer, subscribeUserReducer, subscriptionsReducer, unsubscribeUserReducer, } from 'containers/Subscribers/store/reducers';
import { combineReducers } from 'redux';
// @ts-ignore
var generalReducer = function (history) {
    return combineReducers({
        accountActivation: accountActivationReducer,
        addNewPlace: addNewPlaceReducer,
        addReview: addReviewReducer,
        app: appReducer,
        auth: authReducer,
        contributorUserAccount: contributorUserAccountReducer,
        contributorUserContributions: contributorUserContributionsReducer,
        contributorUserFavorites: contributorUserFavoritesReducer,
        contributorUserProfile: contributorUserProfileReducer,
        editMyProfile: editMyProfileReducer,
        homeLatestNews: homeLatestNewsReducer,
        homeMostActiveUsers: homeMostActiveUsersReducer,
        homeNews: homeNewsReducer,
        languageProvider: languageProviderReducer,
        map: mapReducer,
        monthUsers: monthUsersReducer,
        myContributions: myContributionsReducer,
        myFavorites: myFavoritesReducer,
        myProfile: myProfileReducer,
        oAuthLogin: oAuthLoginPageReducer,
        placeDetails: placeDetailsReducer,
        placePhotos: placePhotosReducer,
        placeReviews: placeReviewsReducer,
        placesAround: placesAroundReducer,
        resendEmailActivation: resendEmailActivationReducer,
        router: connectRouter(history),
        signInEmail: signInEmailReducer,
        signUpEmail: signUpEmailReducer,
        subFooterLinks: subFooterLinksReducer,
        subscribers: subscribersReducer,
        subscribeUser: subscribeUserReducer,
        subscriptions: subscriptionsReducer,
        unsubscribeUser: unsubscribeUserReducer,
        weekUsers: weekUsersReducer,
        allUsers: allUsersReducer,
        signUpNewsLetter: signUpNewsLetterReducer,
        myChallenges: myChallengesReducer,
        publicChallenges: publicChallengesReducer,
        challengeDetail: challengeDetailReducer,
        teamsOnChallenge: teamsOnChallengeReducer,
        teamDetail: teamDetailReducer,
        membersOnTeam: membersOnTeamReducer,
        addCurrentUserJoinTeam: addCurrentUserJoinTeamReducer,
        uploadCoverImageOnTeam: uploadCoverImageTeamReducer,
        membersScoreBoardOnChallenge: membersScoreBoardOnChallengeReducer,
        filterPlaces: filterPlacesReducer,
        myNotifications: myNotificationsReducer,
        chat: chatReducer,
    });
};
// @ts-ignore
var rootReducer = function (history) { return function (state, action) {
    if (action.type === AUTH_LOGOUT_SUCCESS) {
        // eslint-disable-next-line no-param-reassign
        // state = {};
    }
    return generalReducer(history)(state, action);
}; };
export default rootReducer;
