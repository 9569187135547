var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Input as AntInput } from 'antd';
import { rem, rgba } from 'polished';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors } from 'styles';
export var InputPassword = memo(function (props) { return (React.createElement(PasswordWrapper, __assign({}, props))); });
var placeholderColor = theme('mode', {
    light: colors.space.tint600,
    dark: colors.carbon.tint300,
});
var errorColor = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-color: ", ";\n  box-shadow: 0 0 0 ", " ", ";\n"], ["\n  border-color: ", ";\n  box-shadow: 0 0 0 ", " ", ";\n"])), rgba(colors.base.danger, 0.5), rem(2), rgba(colors.base.danger, 0.5));
var PasswordWrapper = styled(AntInput.Password)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &.ant-input-password {\n    border-radius: ", ";\n    font-size: ", " !important;\n    padding: ", " ", ";\n\n    input::placeholder {\n      color: ", ";\n    }\n  }\n\n  &.ant-input-affix-wrapper-focused {\n    border-color: ", ";\n    box-shadow: 0 0 0 ", " ", ";\n    outline: 0;\n  }\n\n  ", ";\n"], ["\n  &.ant-input-password {\n    border-radius: ", ";\n    font-size: ", " !important;\n    padding: ", " ", ";\n\n    input::placeholder {\n      color: ", ";\n    }\n  }\n\n  &.ant-input-affix-wrapper-focused {\n    border-color: ", ";\n    box-shadow: 0 0 0 ", " ", ";\n    outline: 0;\n  }\n\n  ",
    ";\n"])), rem(10), rem(15), rem(8), rem(18), placeholderColor, rgba(colors.base.info, 0.5), rem(2), rgba(colors.base.info, 0.5), ifProp('hasError', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      &.ant-input-password,\n      &.ant-input-affix-wrapper-focused {\n        ", ";\n      }\n    "], ["\n      &.ant-input-password,\n      &.ant-input-affix-wrapper-focused {\n        ", ";\n      }\n    "])), errorColor)));
var templateObject_1, templateObject_2, templateObject_3;
