var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CardPlaces as CardPlacesComponent } from 'components/Cards/CardPlaces';
import { rem, rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { colors } from 'styles';
export var CardPlacesLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: none;\n\n  > div {\n    > div:nth-child(1) {\n      height: ", " !important;\n    }\n\n    > div:nth-child(2) {\n      padding: ", " !important;\n    }\n  }\n"], ["\n  text-decoration: none;\n\n  > div {\n    > div:nth-child(1) {\n      height: ", " !important;\n    }\n\n    > div:nth-child(2) {\n      padding: ", " !important;\n    }\n  }\n"])), rem(120), rem(16));
export var CardPlaces = styled(CardPlacesComponent)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n  max-height: ", ";\n"], ["\n  width: ", ";\n  max-height: ", ";\n"])), rem(240), rem(240));
export var UserLocation = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  bottom: ", ";\n  right: ", ";\n  background: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  box-shadow: 0 ", " ", " ", ";\n  z-index: 9999;\n  cursor: pointer;\n  transition: box-shadow 0.2s ease;\n\n  svg {\n    margin-top: ", ";\n    margin-left: ", ";\n  }\n\n  ", ";\n"], ["\n  position: absolute;\n  bottom: ", ";\n  right: ", ";\n  background: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  box-shadow: 0 ", " ", " ", ";\n  z-index: 9999;\n  cursor: pointer;\n  transition: box-shadow 0.2s ease;\n\n  svg {\n    margin-top: ", ";\n    margin-left: ", ";\n  }\n\n  ",
    ";\n"])), rem(120), rem(10), colors.base.white, rem(40), rem(40), rem(8), rem(1), rem(4), rgba(colors.base.black, 0.15), rem(5), rem(5), ifProp('isKeyboardMode', css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      &:focus {\n        box-shadow: 0 ", " ", " ", ",\n          0 0 0 ", " ", ";\n      }\n    "], ["\n      &:focus {\n        box-shadow: 0 ", " ", " ", ",\n          0 0 0 ", " ", ";\n      }\n    "])), rem(1), rem(4), rgba(colors.base.black, 0.15), rem(4), rgba(colors.base.black, 0.2))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
