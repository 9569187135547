var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal1000Mobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283 L0.0851232877,13.0727283 Z", id: "path-1" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283 L0.0851232877,13.0727283 Z", id: "path-3" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283 L0.0851232877,13.0727283 Z", id: "path-5" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283 L0.0851232877,13.0727283 Z", id: "path-7" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283", id: "path-9" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283", id: "path-11" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283", id: "path-13" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283", id: "path-15" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283", id: "path-17" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283 L0.0851232877,13.0727283 Z", id: "path-19" }),
            React.createElement("path", { d: "M0.0851232877,13.0727283 C0.0851232877,20.1068265 5.82435616,25.8085616 12.9034795,25.8085616 L12.9034795,25.8085616 C19.9829589,25.8085616 25.7214795,20.1068265 25.7214795,13.0727283 L25.7214795,13.0727283 C25.7214795,6.03863014 19.9829589,0.336541096 12.9034795,0.336541096 L12.9034795,0.336541096 C5.82435616,0.336541096 0.0851232877,6.03863014 0.0851232877,13.0727283", id: "path-21" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-2", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/2", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-40", transform: "translate(0.000000, 1.937500)" },
                        React.createElement("g", { id: "Group-5", transform: "translate(0.000000, 0.204897)" },
                            React.createElement("path", { d: "M0.0851589041,14.2941495 C0.0851589041,7.26005137 5.82403562,1.55796233 12.9035151,1.55796233 C19.9826384,1.55796233 25.7215151,7.26005137 25.7215151,14.2941495 C25.7215151,21.3278938 19.9826384,27.0299829 12.9035151,27.0299829 C5.82403562,27.0299829 0.0851589041,21.3278938 0.0851589041,14.2941495", id: "Fill-1", fill: "#000000", opacity: "0.2" }),
                            React.createElement("path", { d: "M0.0851589041,13.0728699 C0.0851589041,6.03877169 5.82403562,0.336682648 12.9035151,0.336682648 C19.9826384,0.336682648 25.7215151,6.03877169 25.7215151,13.0728699 C25.7215151,20.1066142 19.9826384,25.8087032 12.9035151,25.8087032 C5.82403562,25.8087032 0.0851589041,20.1066142 0.0851589041,13.0728699", id: "Fill-3", fill: "#E2F0F4" })),
                        React.createElement("g", { id: "Group-39", transform: "translate(0.000000, 0.204897)" },
                            React.createElement("g", { id: "Group-8" },
                                React.createElement("mask", { id: "mask-2", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-1" })),
                                React.createElement("g", { id: "Clip-7" }),
                                React.createElement("polygon", { id: "Fill-6", fill: "#CEDADD", mask: "url(#mask-2)", points: "-2.84432877 17.5129475 34.7128493 2.99142922 35.6748493 5.44701142 -1.88232877 19.9685297" })),
                            React.createElement("g", { id: "Group-11" },
                                React.createElement("mask", { id: "mask-4", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-3" })),
                                React.createElement("g", { id: "Clip-10" }),
                                React.createElement("polygon", { id: "Fill-9", fill: "#CEDADD", mask: "url(#mask-4)", points: "-3.73716164 15.2331381 33.8200164 0.711619863 34.3011945 1.9395879 -3.25633973 16.4611062" })),
                            React.createElement("g", { id: "Group-14" },
                                React.createElement("mask", { id: "mask-6", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-5" })),
                                React.createElement("g", { id: "Clip-13" }),
                                React.createElement("polygon", { id: "Fill-12", fill: "#CEDADD", mask: "url(#mask-6)", points: "-4.63020822 12.9536119 32.927326 -1.56826027 33.4081479 -0.340292237 -4.14903014 14.1815799" })),
                            React.createElement("g", { id: "Group-17" },
                                React.createElement("mask", { id: "mask-8", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-7" })),
                                React.createElement("g", { id: "Clip-16" }),
                                React.createElement("polygon", { id: "Fill-15", fill: "#CEDADD", mask: "url(#mask-8)", points: "0.0851589041 29.2058927 37.6426932 14.6843744 38.1235151 15.9123425 0.565980822 30.4338607" })),
                            React.createElement("g", { id: "Group-20" },
                                React.createElement("mask", { id: "mask-10", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-9" })),
                                React.createElement("g", { id: "Clip-19" }),
                                React.createElement("path", { d: "M19.4439397,14.9583847 C19.4439397,11.3456107 16.4963233,8.41795091 12.8602411,8.41795091 C9.22451507,8.41795091 6.27689863,11.3456107 6.27689863,14.9583847 C6.27689863,18.5711587 9.22451507,21.500234 12.8602411,21.500234 C16.4963233,21.500234 19.4439397,18.5711587 19.4439397,14.9583847", id: "Fill-18", fill: "#CEDADD", mask: "url(#mask-10)" })),
                            React.createElement("g", { id: "Group-23" },
                                React.createElement("mask", { id: "mask-12", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-11" })),
                                React.createElement("g", { id: "Clip-22" }),
                                React.createElement("path", { d: "M13.1336685,4.07929566 L2.81238082,-7.88543037 C2.75468219,-7.95125228 2.67098356,-7.99017922 2.58229863,-7.99017922 L-1.66852329,-7.99017922 C-1.78712603,-7.99017922 -1.89468767,-7.92188014 -1.94419452,-7.81465411 C-1.99405753,-7.70778196 -1.97624932,-7.58180023 -1.89896164,-7.49226826 L10.5479151,6.93617922 C10.6052575,7.00235502 10.6896685,7.0405742 10.7783534,7.0405742 C10.8670384,7.0405742 10.9510932,7.00235502 11.0087918,6.93617922 L13.1340247,4.47139612 C13.2312575,4.35956963 13.2312575,4.19253767 13.1336685,4.07929566", id: "Fill-21", fill: "#9D66DD", mask: "url(#mask-12)" })),
                            React.createElement("g", { id: "Group-26" },
                                React.createElement("mask", { id: "mask-14", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-13" })),
                                React.createElement("g", { id: "Clip-25" }),
                                React.createElement("path", { d: "M27.751011,-7.81479566 C27.7011479,-7.92202169 27.5939425,-7.99032078 27.4753397,-7.99032078 L23.224874,-7.99032078 C23.136189,-7.99032078 23.0517781,-7.95139384 22.9944356,-7.88521804 L10.5479151,6.54252169 C10.450326,6.65611758 10.450326,6.82279566 10.5479151,6.93639155 L12.6731479,9.39975913 C12.7304904,9.46558105 12.8145452,9.50380023 12.9032301,9.50380023 C12.9919151,9.50380023 13.0759699,9.46558105 13.1336685,9.39975913 L27.7054219,-7.49240982 C27.7830658,-7.58194178 27.8005178,-7.70756963 27.751011,-7.81479566", id: "Fill-24", fill: "#773BB3", mask: "url(#mask-14)" })),
                            React.createElement("g", { id: "Group-29" },
                                React.createElement("mask", { id: "mask-16", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-15" })),
                                React.createElement("g", { id: "Clip-28" }),
                                React.createElement("path", { d: "M18.9749068,14.0290925 C18.9749068,10.6973002 16.2566603,7.99683219 12.9033726,7.99683219 C9.55008493,7.99683219 6.83183836,10.6973002 6.83183836,14.0290925 C6.83183836,17.3608847 9.55008493,20.0624144 12.9033726,20.0624144 C16.2566603,20.0624144 18.9749068,17.3608847 18.9749068,14.0290925", id: "Fill-27", fill: "#F8E831", mask: "url(#mask-16)" })),
                            React.createElement("g", { id: "Group-32" },
                                React.createElement("mask", { id: "mask-18", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-17" })),
                                React.createElement("g", { id: "Clip-31" }),
                                React.createElement("path", { d: "M12.5472438,8.72572146 C9.60390137,8.72572146 7.20905205,11.1045114 7.20905205,14.0289863 C7.20905205,16.9538151 9.60390137,19.3336667 12.5472438,19.3336667 C15.4905863,19.3336667 17.8854356,16.9538151 17.8854356,14.0289863 C17.8854356,11.1045114 15.4905863,8.72572146 12.5472438,8.72572146 M12.5472438,20.2130616 C9.1156,20.2130616 6.3236274,17.4382785 6.3236274,14.0289863 C6.3236274,10.6200479 9.1156,7.84632648 12.5472438,7.84632648 C15.9788877,7.84632648 18.7708603,10.6200479 18.7708603,14.0289863 C18.7708603,17.4382785 15.9788877,20.2130616 12.5472438,20.2130616", id: "Fill-30", fill: "#F7D131", mask: "url(#mask-18)" })),
                            React.createElement("g", { id: "Group-35" },
                                React.createElement("mask", { id: "mask-20", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-19" })),
                                React.createElement("g", { id: "Clip-34" }),
                                React.createElement("polygon", { id: "Fill-33", fill: "#FF9400", mask: "url(#mask-20)", points: "12.9034082 10.0849087 14.0869425 12.4675913 16.7339562 12.850137 14.8188603 14.7051826 15.2704767 17.3235502 12.9034082 16.087089 10.5359836 17.3235502 10.9883123 14.7051826 9.07286027 12.850137 11.719874 12.4675913" })),
                            React.createElement("g", { id: "Group-38" },
                                React.createElement("mask", { id: "mask-22", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-21" })),
                                React.createElement("g", { id: "Clip-37" }),
                                React.createElement("path", { d: "M12.9034082,8.45893037 C9.81190137,8.45893037 7.29666849,10.9573322 7.29666849,14.0290217 C7.29666849,17.1010651 9.81190137,19.6001747 12.9034082,19.6001747 C15.9949151,19.6001747 18.5101479,17.1010651 18.5101479,14.0290217 C18.5101479,10.9573322 15.9949151,8.45893037 12.9034082,8.45893037 M12.9034082,20.5241587 C9.29902466,20.5241587 6.36672329,17.6095925 6.36672329,14.0290217 C6.36672329,10.4484509 9.29902466,7.53530023 12.9034082,7.53530023 C16.5074356,7.53530023 19.439737,10.4484509 19.439737,14.0290217 C19.439737,17.6095925 16.5074356,20.5241587 12.9034082,20.5241587", id: "Fill-36", fill: "#F8E831", mask: "url(#mask-22)" }))))),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
