import { format } from 'date-fns';
import React, { memo } from 'react';
import { InfoMessageWrapper, MessageText } from './styles';
export var InfoMessage = memo(function (_a) {
    var data = _a.data;
    return (React.createElement(InfoMessageWrapper, null,
        React.createElement(MessageText, null, format(new Date(data.created), "dd MMMM 'at' h:mma") + ": " + (data.source.pseudo || data.source.slug)),
        React.createElement("br", null),
        React.createElement(MessageText, null, "has joined the conversation")));
});
