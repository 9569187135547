import { SIGN_IN_USER_CHANGE_PASSWORD_REQUEST, SIGN_IN_USER_FAILURE, SIGN_IN_USER_REQUEST, SIGN_IN_USER_SUCCESS, } from './constants';
export var signInUser = function (payload, actions, meta) { return ({
    type: SIGN_IN_USER_REQUEST,
    payload: payload,
    actions: actions,
    meta: meta,
}); };
export var signInUserChangePassword = function (payload) { return ({
    type: SIGN_IN_USER_CHANGE_PASSWORD_REQUEST,
    payload: payload,
}); };
export var signInUserSuccess = function () { return ({
    type: SIGN_IN_USER_SUCCESS,
}); };
export var signInUserError = function (error) { return ({
    type: SIGN_IN_USER_FAILURE,
    payload: error,
}); };
