import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectAddNewPlaceStateDomain = function (state) {
    return state.addNewPlace || initialState;
};
var makeSelectorPlaceExists = function () {
    return createSelector(selectAddNewPlaceStateDomain, function (_a) {
        var placeExists = _a.placeExists;
        return placeExists;
    });
};
var makeSelectorLoading = function () {
    return createSelector(selectAddNewPlaceStateDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
var makeSelectorSuggestedPlaces = function () {
    return createSelector(selectAddNewPlaceStateDomain, function (_a) {
        var suggestedPlaces = _a.suggestedPlaces;
        return suggestedPlaces;
    });
};
var makeSelectorCurrentContent = function () {
    return createSelector(selectAddNewPlaceStateDomain, function (_a) {
        var currentContent = _a.currentContent;
        return currentContent;
    });
};
var makeSelectorPlaceToAdd = function () {
    return createSelector(selectAddNewPlaceStateDomain, function (_a) {
        var placeToAdd = _a.placeToAdd;
        return placeToAdd;
    });
};
export { selectAddNewPlaceStateDomain, makeSelectorLoading, makeSelectorPlaceExists, makeSelectorSuggestedPlaces, makeSelectorCurrentContent, makeSelectorPlaceToAdd, };
