var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { makeSelectDirection, makeSelectFieldOnFocus, } from 'containers/App/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { Close, Menu } from 'icons/mono';
import { rem } from 'polished';
import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Drawer } from './Drawer';
import { CloseButton, Footer } from './Drawer/styles';
import { ChallengeIcon, DonateIcon, ExploreIcon, SettingsIcon } from './Icons';
import { SettingsMenu } from './SettingsMenu';
import { BottomMenuContainer, Button, Wrapper } from './styles';
export var BottomMenu = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var direction = useSelector(makeSelectDirection());
    var isFieldOnFocus = useSelector(makeSelectFieldOnFocus());
    var _a = __read(useState(false), 2), isVisibleSettings = _a[0], setVisibleSettings = _a[1];
    var _b = __read(useState(false), 2), isVisibleDrawer = _b[0], setVisibleDrawer = _b[1];
    function handleGoToChallenge() {
        history.push(paths.challengePage, { from: location.pathname });
    }
    function handleGoToExplore() {
        history.push(paths.explore, { from: location.pathname });
    }
    function handleGoToDonation() {
        history.push(paths.faireUnDon, { from: location.pathname });
    }
    var hangleToggleSettings = function () {
        setVisibleSettings(!isVisibleSettings);
    };
    var hangleToggleDrawer = function () {
        setVisibleDrawer(!isVisibleDrawer);
    };
    var footer = (React.createElement(Footer, { isRtl: direction },
        React.createElement(CloseButton, { onClick: hangleToggleDrawer },
            React.createElement(Close, { size: "1.5rem" }))));
    return (React.createElement(React.Fragment, null, !isFieldOnFocus && (React.createElement(React.Fragment, null,
        React.createElement(Wrapper, null,
            React.createElement(BottomMenuContainer, { isRtl: direction },
                React.createElement(Button, { onClick: handleGoToExplore },
                    React.createElement(ExploreIcon, { isActive: location.pathname === paths.explore, title: formatMessage({ id: 'components.bottomMenu.explore' }) })),
                React.createElement(Button, { onClick: handleGoToChallenge },
                    React.createElement(ChallengeIcon, { isActive: location.pathname === paths.challengePage, title: formatMessage({
                            id: 'components.bottomMenu.challenge',
                        }) })),
                React.createElement(Button, { onClick: handleGoToDonation },
                    React.createElement(DonateIcon, { isActive: location.pathname === paths.faireUnDon, title: formatMessage({ id: 'components.bottomMenu.donate' }) })),
                React.createElement(Button, { onClick: function () { return hangleToggleSettings(); } },
                    React.createElement(SettingsIcon, { isActive: isVisibleSettings, title: formatMessage({
                            id: 'components.bottomMenu.settings',
                        }) })),
                React.createElement(Button, { onClick: function () { return hangleToggleDrawer(); } },
                    React.createElement(Menu, { size: rem(28), title: formatMessage({ id: 'components.bottomMenu.menu' }) }))),
            React.createElement(SettingsMenu, { isVisible: isVisibleSettings })),
        React.createElement(Drawer, { placement: direction ? 'left' : 'right', closable: false, visible: isVisibleDrawer, footer: footer, onClose: function () { return hangleToggleDrawer(); }, hangleToggleDrawer: hangleToggleDrawer, width: rem(300), zIndex: 2000, destroyOnClose: true })))));
});
