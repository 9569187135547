import { ACCOUNT_ACTIVATION_FAILURE, ACCOUNT_ACTIVATION_REQUEST, ACCOUNT_ACTIVATION_SUCCESS, ACCOUNT_ACTIVATION_TOGGLE_VISIBLE, } from './constants';
export var accountActivationRequest = function (payload) { return ({
    type: ACCOUNT_ACTIVATION_REQUEST,
    payload: payload,
}); };
export var accountActivationSuccess = function () { return ({
    type: ACCOUNT_ACTIVATION_SUCCESS,
}); };
export var accountActivationError = function (payload) { return ({
    type: ACCOUNT_ACTIVATION_FAILURE,
    payload: payload,
}); };
export var accountActivationToggleVisible = function () { return ({
    type: ACCOUNT_ACTIVATION_TOGGLE_VISIBLE,
}); };
