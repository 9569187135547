import { placeholderPlace } from 'assets/images';
import { logoImage } from 'assets/svg';
import { Avatar } from 'components';
import React from 'react';
import { AvatarWrapper, ChallengeImage, HeaderContainer, HeaderWrapper, ImageContainer, JaccedeLogo, Title, } from './styles';
export var JoinTeamHeader = function (_a) {
    var title = _a.title, logo = _a.logo, _b = _a.cover, cover = _b === void 0 ? null : _b;
    return (React.createElement(HeaderWrapper, null,
        React.createElement(HeaderContainer, { hasCover: !!(cover === null || cover === void 0 ? void 0 : cover.urlStandard) },
            React.createElement(Title, null, title),
            React.createElement(ImageContainer, { hasCover: !!(cover === null || cover === void 0 ? void 0 : cover.urlStandard) }, (cover === null || cover === void 0 ? void 0 : cover.urlStandard) ? (React.createElement(ChallengeImage, { src: cover === null || cover === void 0 ? void 0 : cover.urlStandard, alt: "Challenge cover image" })) : (React.createElement(JaccedeLogo, { src: logoImage, alt: "Challenge cover image" })))),
        React.createElement(AvatarWrapper, null,
            React.createElement(Avatar, { size: "medium", src: (logo === null || logo === void 0 ? void 0 : logo.urlThumb) || placeholderPlace, alt: title || 'Team logo' }))));
};
