var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Select } from 'antd';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var placeholderColor = theme('mode', {
    light: colors.space.tint600,
    dark: colors.carbon.tint300,
});
export var SelectWrapper = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-select-selector {\n    border-radius: ", " !important;\n    font-size: ", " !important;\n    height: ", " !important;\n  }\n\n  &.ant-select-show-arrow {\n    .ant-select-selection-item,\n    .ant-select-selection-placeholder {\n      margin-right: ", " !important;\n    }\n  }\n\n  &.ant-select-focused {\n    .ant-select-selector {\n      border-color: ", " !important;\n      box-shadow: 0 0 0 ", " ", " !important;\n      outline: 0;\n    }\n  }\n\n  .ant-select-selection-placeholder {\n    color: ", ";\n  }\n\n  .ant-select-arrow {\n    color: ", ";\n    top: 47%;\n    right: ", ";\n  }\n"], ["\n  .ant-select-selector {\n    border-radius: ", " !important;\n    font-size: ", " !important;\n    height: ", " !important;\n  }\n\n  &.ant-select-show-arrow {\n    .ant-select-selection-item,\n    .ant-select-selection-placeholder {\n      margin-right: ", " !important;\n    }\n  }\n\n  &.ant-select-focused {\n    .ant-select-selector {\n      border-color: ", " !important;\n      box-shadow: 0 0 0 ", " ", " !important;\n      outline: 0;\n    }\n  }\n\n  .ant-select-selection-placeholder {\n    color: ", ";\n  }\n\n  .ant-select-arrow {\n    color: ", ";\n    top: 47%;\n    right: ", ";\n  }\n"])), rem(10), rem(15), rem(41.567), rem(16), rgba(colors.base.info, 0.5), rem(2), rgba(colors.base.info, 0.5), placeholderColor, colors.space.tint600, rem(18));
export var OptionWrapper = styled(Select.Option)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2;
