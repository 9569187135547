var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
export var GlobalScoreboardRankContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var UserList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: ", " ", " 0 0;\n  background-color: ", ";\n  z-index: 100;\n  padding: ", " ", ";\n\n  width: 100%;\n  position: absolute;\n  top: ", ";\n  padding-bottom: calc(", " + env(safe-area-inset-bottom, 0));\n\n  box-shadow: 0 ", " ", " 0 ", ";\n\n  ", "\n"], ["\n  border-radius: ", " ", " 0 0;\n  background-color: ", ";\n  z-index: 100;\n  padding: ", " ", ";\n\n  width: 100%;\n  position: absolute;\n  top: ", ";\n  padding-bottom: calc(", " + env(safe-area-inset-bottom, 0));\n\n  box-shadow: 0 ", " ", " 0 ", ";\n\n  ",
    "\n"])), rem(20), rem(20), background, rem(8), rem(16), rem(395), rem(175), rem(-30), rem(30), rgba(colors.base.black, 0.1), ifProp('isLoggedIn', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      padding-bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    "], ["\n      padding-bottom: calc(", " + env(safe-area-inset-bottom, 0));\n    "])), rem(225))));
export var LoadingIndicatorWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", " 0;\n"], ["\n  padding: ", " 0;\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
