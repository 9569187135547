var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Input, Label } from 'components';
import { makeSelectorCategories } from 'containers/PlaceDetails/selectors';
import Fuse from 'fuse.js';
import { useAction, useSelector } from 'hooks';
import * as categoryId from 'icons/category/id';
import { mapIcons } from 'icons/category/map';
import forIn from 'lodash/forIn';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import take from 'lodash/take';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useIntl } from 'react-intl';
import { snakeToCamel } from 'utils/CamelChange';
import { setSelectedCategory } from '../actions';
import { makeSelectorSelectedCategory } from '../selectors';
import { CategoryDropdownContainer, InitialLetter, InputWrapper, Scrollbar, SuggestedWrapper, TermButtonListItem, TermButtonsList, } from './styles';
export var getCategoryIcon = function (categoryName) {
    var icon = mapIcons(categoryId.square[snakeToCamel(categoryName)], 'square', {
        size: rem(18),
    });
    if (icon === undefined) {
        return mapIcons(categoryId.square[snakeToCamel('establishment')], 'square', {
            size: rem(18),
        });
    }
    return icon;
};
export var CategoryDropdown = function (_a) {
    var style = _a.style, onSelect = _a.onSelect;
    var formatMessage = useIntl().formatMessage;
    var categoriesSelector = useSelector(makeSelectorCategories());
    var selectedCategory = useSelector(makeSelectorSelectedCategory());
    var setSelectedCategoryCallback = useAction(setSelectedCategory);
    var _b = __read(useState([]), 2), categories = _b[0], setCategories = _b[1];
    // @ts-ignore
    var _c = __read(useState(), 2), fuse = _c[0], setFuse = _c[1];
    var getGroupedCategoryByFirstChar = function () {
        return groupBy(categories, function (c) { return c.label.charAt(0); });
    };
    var getCategoriesToState = function () {
        return map(categoriesSelector, function (category) { return ({
            id: category.id,
            label: category.label,
            checked: false,
            icon: getCategoryIcon(category.name),
        }); });
    };
    useEffect(function () {
        if (categoriesSelector === null || categoriesSelector === void 0 ? void 0 : categoriesSelector.length) {
            var categoriesToState = getCategoriesToState();
            setCategories(categoriesToState);
            setFuse(new Fuse(categoriesToState, { keys: ['label'], threshold: 0.3 }));
        }
    }, [categoriesSelector]);
    var getSuggestedCategories = function () { return take(categories, 3); };
    var selectCategory = function (category) {
        if ((selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id) === category.id) {
            setSelectedCategoryCallback();
        }
        else {
            setSelectedCategoryCallback(category);
        }
        onSelect();
    };
    var handleSearch = function (event) {
        var value = event.currentTarget.value;
        if (!value) {
            setCategories(getCategoriesToState());
            return;
        }
        setCategories(map(fuse === null || fuse === void 0 ? void 0 : fuse.search(value), function (_a) {
            var item = _a.item;
            return item;
        }));
    };
    var getCategoryButtonType = function (category) {
        return category.id === (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id) ? 'primary' : 'ghost';
    };
    var mountCategoriesGrouped = function () {
        var items = [];
        forIn(getGroupedCategoryByFirstChar(), function (value, key) {
            items.push(React.createElement("div", { key: key },
                React.createElement(InitialLetter, null, key),
                React.createElement(TermButtonsList, null, map(value, function (category) { return (React.createElement(TermButtonListItem, { key: category.label },
                    React.createElement(Button, { size: "small", shape: "pill", variant: getCategoryButtonType(category), icon: category.icon, onClick: function () { return selectCategory(category); } }, category.label))); }))));
        });
        return items;
    };
    return (React.createElement(CategoryDropdownContainer, { style: style, onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(InputWrapper, null,
            React.createElement(Input, { type: "search", placeholder: formatMessage({
                    id: 'containers.filterPlaces.category.placeholder',
                }), onChange: handleSearch })),
        React.createElement(Scrollbar, null,
            React.createElement(SuggestedWrapper, null,
                React.createElement(Label, null, !isEmpty(getSuggestedCategories()) &&
                    formatMessage({
                        id: "containers.filterPlaces.category." + (selectedCategory
                            ? 'selectedCategoryLabel'
                            : 'suggestedCategoriesLabel'),
                    })),
                React.createElement(TermButtonsList, null, selectedCategory ? (React.createElement(Button, { size: "small", shape: "pill", variant: getCategoryButtonType(selectedCategory), icon: selectedCategory.icon, onClick: function () { return selectCategory(selectedCategory); } }, selectedCategory.label)) : (map(getSuggestedCategories(), function (category) { return (React.createElement(TermButtonListItem, { key: category.label },
                    React.createElement(Button, { size: "small", shape: "pill", variant: getCategoryButtonType(category), icon: category.icon, onClick: function () { return selectCategory(category); } }, category.label))); })))),
            React.createElement(ScrollContainer, { vertical: true, hideScrollbars: false }, !isEmpty(getGroupedCategoryByFirstChar()) &&
                mountCategoriesGrouped()))));
};
