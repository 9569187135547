import { createSelector } from 'reselect';
import { allUsersInitialState } from './reducer';
export var selectAllUsersDomain = function (state) {
    return state.allUsers || allUsersInitialState;
};
export var makeSelectAllUsersLoading = function () {
    return createSelector(selectAllUsersDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectAllUsersHasError = function () {
    return createSelector(selectAllUsersDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectAllUsersError = function () {
    return createSelector(selectAllUsersDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectAllUsersPages = function () {
    return createSelector(selectAllUsersDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectAllUsersCurrentPage = function () {
    return createSelector(selectAllUsersDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectAllUsersTotal = function () {
    return createSelector(selectAllUsersDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectAllUsersItems = function () {
    return createSelector(selectAllUsersDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeAllUsers = function () {
    return createSelector(selectAllUsersDomain, function (subState) { return subState; });
};
export default makeAllUsers;
