var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal5000DisabledMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, _b = _a.progress, progress = _b === void 0 ? 0 : _b, props = __rest(_a, ["size", "dark", "progress"]);
    var strokeDasharraySize = 190;
    var strokeDasharray = (strokeDasharraySize * progress) / 100;
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-3-gray", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: dark ? colors.carbon.tint100 : colors.space.tint200, strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round", strokeDasharray: strokeDasharray + ", " + strokeDasharraySize }),
                React.createElement("rect", { id: "active-bg", fill: "#E0E0E0", x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/3/Disabled", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-19", strokeWidth: "1", transform: "translate(0.000000, -0.632653)" },
                        React.createElement("polygon", { id: "Fill-1", fill: "#EF8E1B", points: "4.77310213 17.8892749 4.77310213 31.1399913 12.9437404 26.6608211 21.1138255 31.1399913 21.1138255 17.8892749" }),
                        React.createElement("polygon", { id: "Fill-2", fill: "#E08119", points: "8.8584766 17.8892749 8.8584766 28.9712028 12.9437957 26.7169953 17.0291149 28.9712028 17.0291149 17.8892749" }),
                        React.createElement("polygon", { id: "Fill-3-Copy", fillOpacity: "0.1", fill: "#000000", points: "10.3174085 2.38398959 7.18357872 1.93316132 5.58540851 4.63703671 2.5633234 5.57589752 2.31106809 8.69886813 -0.000165957447 10.8413967 1.14217447 13.7630265 -0.000165957447 16.6852034 2.31106809 18.827732 2.5633234 21.9501554 5.58540851 22.8890163 7.18357872 25.5939859 10.3174085 25.1426105 12.9434085 26.893947 15.5699617 25.1426105 18.7043447 25.5939859 20.3019617 22.8890163 23.3240468 21.9501554 23.5768553 18.827732 25.886983 16.6857505 25.886983 16.6835621 24.7451957 13.7630265 25.886983 10.843038 25.886983 10.8413967 23.5768553 8.69886813 23.3240468 5.57589752 20.3019617 4.63703671 18.7043447 1.93316132 15.5699617 2.38398959 12.9434085 0.632653061" }),
                        React.createElement("polygon", { id: "Fill-3", fill: "#F7D131", points: "10.3174085 1.75182111 7.18357872 1.30099284 5.58540851 4.00486823 2.5633234 4.94372905 2.31106809 8.06669965 -0.000165957447 10.2092282 1.14217447 13.130858 -0.000165957447 16.0530349 2.31106809 18.1955635 2.5633234 21.317987 5.58540851 22.2568478 7.18357872 24.9618174 10.3174085 24.510442 12.9434085 26.2617785 15.5699617 24.510442 18.7043447 24.9618174 20.3019617 22.2568478 23.3240468 21.317987 23.5768553 18.1955635 25.886983 16.0535821 25.886983 16.0513936 24.7451957 13.130858 25.886983 10.2108696 25.886983 10.2092282 23.5768553 8.06669965 23.3240468 4.94372905 20.3019617 4.00486823 18.7043447 1.30099284 15.5699617 1.75182111 12.9434085 0.000484585323" }),
                        React.createElement("g", { id: "Group-18", transform: "translate(2.212766, 2.724285)" },
                            React.createElement("path", { d: "M19.9946638,10.1982058 C19.9946638,15.1775892 15.846834,19.214185 10.7309191,19.214185 C5.61445106,19.214185 1.46717447,15.1775892 1.46717447,10.1982058 C1.46717447,5.21828398 5.61445106,1.18168823 10.7309191,1.18168823 C15.846834,1.18168823 19.9946638,5.21828398 19.9946638,10.1982058", id: "Fill-4", fill: "#F8E831" }),
                            React.createElement("path", { d: "M10.7305872,18.1957482 C6.33769362,18.1957482 2.7635234,14.7175024 2.7635234,10.4413061 C2.7635234,6.16564828 6.33769362,2.68632566 10.7305872,2.68632566 C15.124034,2.68632566 18.6982043,6.16564828 18.6982043,10.4413061 C18.6982043,14.7175024 15.124034,18.1957482 10.7305872,18.1957482 M10.7305872,1.02473643 C5.39560851,1.02473643 1.05582128,5.2492436 1.05582128,10.4413061 C1.05582128,15.6339071 5.39560851,19.8578758 10.7305872,19.8578758 C16.065566,19.8578758 20.4053532,15.6339071 20.4053532,10.4413061 C20.4053532,5.2492436 16.065566,1.02473643 10.7305872,1.02473643", id: "Fill-6", fill: "#F7D131" }),
                            React.createElement("path", { d: "M10.7305872,18.5959079 C5.97314043,18.5959079 2.10245957,14.8290647 2.10245957,10.1980443 C2.10245957,5.56810074 5.97314043,1.80018063 10.7305872,1.80018063 C15.4885872,1.80018063 19.3592681,5.56810074 19.3592681,10.1980443 C19.3592681,14.8290647 15.4885872,18.5959079 10.7305872,18.5959079 M10.7305872,0.000753799392 C4.95360851,0.000753799392 0.253693617,4.57577768 0.253693617,10.1980443 C0.253693617,15.8219262 4.95360851,20.3953348 10.7305872,20.3953348 C16.5081191,20.3953348 21.208034,15.8219262 21.208034,10.1980443 C21.208034,4.57577768 16.5081191,0.000753799392 10.7305872,0.000753799392", id: "Fill-8", fill: "#F8E831" }),
                            React.createElement("path", { d: "M7.61866383,11.7719774 L7.69777021,11.6341398 C7.72211064,11.5932193 7.75751489,11.5598367 7.80840851,11.5334538 C7.86483404,11.5038402 7.9262383,11.4893026 7.99594043,11.4893026 C8.11874894,11.4893026 8.17517447,11.5307616 8.21002553,11.5662979 C8.24542979,11.602911 8.29189787,11.6669839 8.29189787,11.809129 C8.29189787,11.9518124 8.24100426,12.0153469 8.20338723,12.0508832 C8.07172766,12.1736448 7.76028085,12.1682605 7.4582383,12.0245002 C7.32602553,11.9609657 7.20155745,11.8758941 7.08704681,11.7719774 L7.61866383,11.7719774 Z M9.82700426,10.2385341 C9.62730213,10.0506227 9.3888766,9.90255493 9.11836596,9.7986383 C8.83236596,9.68879896 8.48606809,9.62957186 8.17130213,9.64303257 L8.17794043,9.59941989 L10.0316851,9.59941989 L10.0316851,7.66323231 L6.57202553,7.66323231 L5.76989787,11.7719774 L6.52555745,11.7719774 L5.59453617,12.8170664 L5.70406809,12.9866713 C5.91981277,13.3204967 6.22904681,13.5794807 6.62236596,13.7577004 C7.00351489,13.9299974 7.43445106,14.0172228 7.90466383,14.0172228 C8.27142979,14.0172228 8.61385532,13.9628415 8.92253617,13.8556943 C9.23342979,13.7480087 9.50670638,13.5950951 9.73462128,13.4007225 C9.96585532,13.2031194 10.1473021,12.962442 10.2734298,12.68569 C10.3995574,12.4105532 10.4631745,12.1041876 10.4631745,11.7773617 C10.4631745,11.4693808 10.4073021,11.1802449 10.297217,10.9196457 C10.1865787,10.6579696 10.028366,10.4291376 9.82700426,10.2385341 L9.82700426,10.2385341 Z", id: "Fill-10", fill: "#D8B627" }),
                            React.createElement("polygon", { id: "Fill-12", fill: "#D8B627", points: "14.0386723 10.1661155 15.7679489 8.02694051 13.3969702 8.02694051 12.654034 9.03433956 12.654034 6.37935041 10.5242468 6.37935041 10.5242468 12.8135667 12.654034 12.8135667 12.654034 11.4438055 13.5258638 12.8135667 15.8669702 12.8135667" }),
                            React.createElement("path", { d: "M8.31607234,9.37322623 C8.60373191,9.37322623 8.86649787,9.41899262 9.10437021,9.5105254 C9.34224255,9.60205818 9.54858298,9.72966565 9.72228511,9.89388624 C9.89654043,10.0581068 10.0326255,10.2551715 10.1288809,10.4840035 C10.2256894,10.712297 10.2743702,10.9642814 10.2743702,11.2388797 C10.2743702,11.5296309 10.2190511,11.7961528 10.1084128,12.0379071 C9.99777447,12.2801997 9.84122128,12.4874946 9.63985957,12.6597916 C9.43739149,12.832627 9.19675319,12.967234 8.91739149,13.0636127 C8.63858298,13.1605297 8.32990213,13.2095267 7.99245532,13.2095267 C7.56096596,13.2095267 7.17207234,13.1309162 6.82688085,12.974772 C6.48113617,12.8186279 6.21394468,12.5951802 6.02585957,12.304429 L6.8888383,11.3357968 C7.04926383,11.5027095 7.22739149,11.6330091 7.42432766,11.727234 C7.62015745,11.821459 7.81266809,11.8688406 8.00075319,11.8688406 C8.20543404,11.8688406 8.36585957,11.8149978 8.48202979,11.7073122 C8.5982,11.5996266 8.65628511,11.4537125 8.65628511,11.270647 C8.65628511,11.0929657 8.60373191,10.9508207 8.49862553,10.8425966 C8.39351915,10.734911 8.25522128,10.6816066 8.08373191,10.6816066 C7.9675617,10.6816066 7.86079574,10.7074512 7.76398723,10.7580634 C7.66773191,10.8092141 7.59415745,10.8781329 7.54437021,10.9642814 L6.19181702,10.9642814 L6.8888383,7.39396439 L9.84288085,7.39396439 L9.84288085,8.79172384 L8.02564681,8.79172384 L7.93437021,9.41306991 C7.95649787,9.40768563 7.9869234,9.40283977 8.02564681,9.39745549 C8.05330638,9.39207121 8.09202979,9.3861485 8.14181702,9.38130265 C8.19160426,9.37591837 8.24968936,9.37322623 8.31607234,9.37322623", id: "Fill-14", fill: "#F9F1B7" }),
                            React.createElement("polygon", { id: "Fill-16", fill: "#F9F1B7", points: "13.7677745 12.0059244 12.7803277 10.4552514 12.4650085 10.7944611 12.4650085 12.0059244 10.8884128 12.0059244 10.8884128 6.11013634 12.4650085 6.11013634 12.4650085 9.33262875 13.6267106 7.75772644 15.2862851 7.75772644 13.7843702 9.61530352 15.4356468 12.0059244" }))),
                    React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", style: { mixBlendMode: 'color' }, x: "0", y: "-1.26530612", width: "26", height: "32.2653061" })),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: dark ? colors.space.tint500 : colors.space.tint200 })))));
});
