/* eslint-disable lodash/prefer-lodash-method */
import { avatarImage1 } from 'assets/images';
import { Avatar, Button } from 'components';
import { makeSelectIsLoggedIn } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Container, Tagline, Title } from './styles';
export var EvaluateButton = function (_a) {
    var _b = _a.placeId, placeId = _b === void 0 ? '' : _b, showAvatar = _a.showAvatar, avatar = _a.avatar;
    var history = useHistory();
    var isLoggedIn = useSelector(makeSelectIsLoggedIn());
    var addReviewPath = paths.newReviewPage.replace(':placeId', placeId);
    var handleAddReview = function () {
        return isLoggedIn
            ? history.push(addReviewPath)
            : history.push(paths.signInPage, { from: addReviewPath });
    };
    return (React.createElement(Container, { showAvatar: showAvatar },
        showAvatar && (React.createElement(Avatar, { src: (avatar === null || avatar === void 0 ? void 0 : avatar.urlThumb) || avatarImage1, alt: "User Avatar", size: "small" })),
        React.createElement(Title, { showAvatar: showAvatar },
            React.createElement(FormattedMessage, { id: "containers.placeDetails.evaluateTitle" })),
        React.createElement(Tagline, null,
            React.createElement(FormattedMessage, { id: "containers.placeDetails.evaluateTagline" })),
        React.createElement(Button, { onClick: handleAddReview },
            React.createElement(FormattedMessage, { id: "containers.placeDetails.addReviewButton" }))));
};
