var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { MapContainer, mapsThemingStyles } from './styles';
export var DEFAULT_CENTER = { lat: 48.856614, lng: 2.3522219 };
var DEFAULT_ZOOM = 18;
var RenderMap = function (props) {
    var defaultOptions = {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        styles: mapsThemingStyles,
        gestureHandling: 'greedy',
    };
    var mapRef = React.createRef();
    useEffect(function () {
        if (mapRef.current !== null && props.onMounted) {
            props.onMounted(mapRef);
        }
    }, [mapRef.current]);
    return (React.createElement(GoogleMap, { onDragEnd: function () { return props.onDragEnd && props.onDragEnd(mapRef); }, onZoomChanged: function () { return props.onZoomChanged && props.onZoomChanged(mapRef); }, defaultZoom: DEFAULT_ZOOM, defaultCenter: DEFAULT_CENTER, center: props.center, ref: mapRef, options: __assign(__assign({}, defaultOptions), props.options) }, props.children));
};
export var Maps = compose(withProps({
    googleMapURL: process.env.RAZZLE_APP_GOOGLE_MAP_URL + "&key=" + process.env.RAZZLE_APP_GOOGLE_API_KEY,
    loadingElement: React.createElement("div", { style: { height: "100%" } }),
    containerElement: React.createElement(MapContainer, null),
    mapElement: React.createElement("div", { id: "map", style: { height: "100%", width: '100%' } }),
}), withScriptjs, withGoogleMap)(RenderMap);
