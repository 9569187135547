import { ProgressBar } from 'components';
import React, { memo } from 'react';
import { AchievementContent, AchievementDescription, AchievementTitle, Container, MedalWrapper, ProgressContainer, ProgressText, } from './styles';
var CardAchievement = memo(function (_a) {
    var medal = _a.medal, title = _a.title, description = _a.description, percent = _a.percent, goalPoints = _a.goalPoints, totalPoints = _a.totalPoints;
    return (React.createElement(Container, null,
        React.createElement(MedalWrapper, null, medal),
        React.createElement(AchievementContent, null,
            React.createElement(AchievementTitle, null, title),
            React.createElement(AchievementDescription, null, description)),
        React.createElement(ProgressContainer, null,
            React.createElement(ProgressBar, { percent: percent, showInfo: false }),
            React.createElement(ProgressText, null,
                totalPoints,
                " / ",
                goalPoints))));
});
export default CardAchievement;
