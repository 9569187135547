var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Bell = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.6909 30.2286C31.8802 28.6569 30.2432 26.179 31.8603 19.9828C33.06 15.3976 30.9247 10.5848 26.7733 8.50671L27.1027 7.23856C27.3795 6.16889 26.7619 5.07294 25.7212 4.78545C24.6805 4.49943 23.61 5.13569 23.3317 6.20537L23.0009 7.47351C18.3923 7.13495 14.1997 10.2054 13.0057 14.7934C11.3886 20.9955 8.76627 22.2943 7.29964 23.2385C6.88222 23.5026 6.57839 23.9215 6.45345 24.4074C6.17518 25.48 6.7942 26.5803 7.83631 26.8664C7.83773 26.8664 7.83915 26.8678 7.83915 26.8678L30.459 33.1006C31.4997 33.391 32.573 32.7576 32.8542 31.6865C32.8542 31.6865 32.8556 31.685 32.8556 31.6836C32.9862 31.1962 32.9266 30.6737 32.6909 30.2286ZM17.6328 35.7988C19.7141 36.3724 21.8552 35.1013 22.4131 32.9619L14.8699 30.8824C14.3119 33.0247 15.5471 35.2253 17.6313 35.7988C17.6313 35.7988 17.6313 35.7988 17.6328 35.7988Z", fill: color })));
});
