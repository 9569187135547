import React, { memo } from 'react';
export var PostOfficeOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#b3b3b3", style: { fill: '#b3b3b3' }, d: "M31.39 0.305h-30.476v30.476h30.476v-30.476z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M23.886 16.989l-1.527-7.605-3.312 4.623 4.838 2.983z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M22.066 7.752l-14.432 2.899 8.571 5.283 5.861-8.183z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M18.356 14.974l-1.829 2.553-2.676-1.651-4.035 5.629 14.435-2.896-5.896-3.635z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M7.93 12.227l1.55 7.71 3.357-4.686-4.906-3.024z" })));
});
