var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var Medal5000Desktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.34323 28.2766V48.5617L19.9134 41.7047L32.4828 48.5617V28.2766H7.34323Z", fill: "#EF8E1B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.6284 28.2766V45.1336L19.9135 41.7047L26.1986 45.1336V28.2766H13.6284Z", fill: "#E08119" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.8729 3.72426L11.0517 3.02298L8.59295 7.22894L3.94359 8.68936L3.5555 13.5472L-0.000244141 16.88L1.7572 21.4247L-0.000244141 25.9702L3.5555 29.303L3.94359 34.16L8.59295 35.6204L11.0517 39.8281L15.8729 39.126L19.9129 41.8502L23.9538 39.126L28.7759 39.8281L31.2338 35.6204L35.8832 34.16L36.2721 29.303L39.8261 25.9711V25.9677L38.0695 21.4247L39.8261 16.8826V16.88L36.2721 13.5472L35.8832 8.68936L31.2338 7.22894L28.7759 3.02298L23.9538 3.72426L19.9129 1L15.8729 3.72426Z", fill: "black", fillOpacity: "0.1" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.8729 2.72502L11.0517 2.02374L8.59295 6.2297L3.94359 7.69013L3.5555 12.548L-0.000244141 15.8808L1.7572 20.4254L-0.000244141 24.971L3.5555 28.3037L3.94359 33.1608L8.59295 34.6212L11.0517 38.8289L15.8729 38.1267L19.9129 40.851L23.9538 38.1267L28.7759 38.8289L31.2338 34.6212L35.8832 33.1608L36.2721 28.3037L39.8261 24.9718V24.9684L38.0695 20.4254L39.8261 15.8833V15.8808L36.2721 12.548L35.8832 7.69013L31.2338 6.2297L28.7759 2.02374L23.9538 2.72502L19.9129 0.000765979L15.8729 2.72502Z", fill: "#F7D131" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.1653 20.4259C34.1653 28.2965 27.784 34.6769 19.9134 34.6769C12.0419 34.6769 5.66147 28.2965 5.66147 20.4259C5.66147 12.5544 12.0419 6.17396 19.9134 6.17396C27.784 6.17396 34.1653 12.5544 34.1653 20.4259Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9128 33.0671C13.1546 33.0671 7.65583 27.5693 7.65583 20.8101C7.65583 14.0518 13.1546 8.55226 19.9128 8.55226C26.672 8.55226 32.1707 14.0518 32.1707 20.8101C32.1707 27.5693 26.672 33.0671 19.9128 33.0671ZM19.9129 5.92587C11.7052 5.92587 5.02859 12.6033 5.02859 20.8101C5.02859 29.0178 11.7052 35.6944 19.9129 35.6944C28.1205 35.6944 34.7971 29.0178 34.7971 20.8101C34.7971 12.6033 28.1205 5.92587 19.9129 5.92587Z", fill: "#F7D131" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9129 33.6997C12.5937 33.6997 6.63881 27.7456 6.63881 20.4256C6.63881 13.1073 12.5937 7.15157 19.9129 7.15157C27.2329 7.15157 33.1877 13.1073 33.1877 20.4256C33.1877 27.7456 27.2329 33.6997 19.9129 33.6997ZM19.9129 4.30732C11.0252 4.30732 3.79456 11.5388 3.79456 20.4256C3.79456 29.315 11.0252 36.5439 19.9129 36.5439C28.8014 36.5439 36.032 29.315 36.032 20.4256C36.032 11.5388 28.8014 4.30732 19.9129 4.30732Z", fill: "#F8E831" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.1253 22.9134L15.247 22.6956C15.2844 22.6309 15.3389 22.5781 15.4172 22.5364C15.504 22.4896 15.5985 22.4666 15.7057 22.4666C15.8946 22.4666 15.9814 22.5322 16.0351 22.5883C16.0895 22.6462 16.161 22.7475 16.161 22.9722C16.161 23.1977 16.0827 23.2981 16.0248 23.3543C15.8223 23.5483 15.3431 23.5398 14.8785 23.3126C14.6751 23.2122 14.4836 23.0777 14.3074 22.9134H15.1253ZM18.5227 20.4896C18.2155 20.1926 17.8487 19.9586 17.4325 19.7943C16.9925 19.6207 16.4597 19.5271 15.9755 19.5483L15.9857 19.4794H18.8376V16.419H13.5151L12.281 22.9134H13.4436L12.0112 24.5654L12.1797 24.8334C12.5117 25.3611 12.9874 25.7705 13.5925 26.0522C14.1789 26.3245 14.8419 26.4624 15.5653 26.4624C16.1295 26.4624 16.6563 26.3764 17.1312 26.2071C17.6095 26.0369 18.03 25.7951 18.3806 25.4879C18.7363 25.1756 19.0155 24.7951 19.2095 24.3577C19.4036 23.9228 19.5014 23.4386 19.5014 22.922C19.5014 22.4351 19.4155 21.9781 19.2461 21.5662C19.0759 21.1526 18.8325 20.7909 18.5227 20.4896Z", fill: "#D8B627" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.0022 20.3751L27.6627 16.9939H24.015L22.872 18.5862V14.3896H19.5954V24.5598H22.872V22.3947L24.2133 24.5598H27.815L25.0022 20.3751Z", fill: "#D8B627" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.1982 19.1219C16.6408 19.1219 17.045 19.1942 17.411 19.3389C17.7769 19.4836 18.0944 19.6853 18.3616 19.9449C18.6297 20.2044 18.8391 20.5159 18.9871 20.8776C19.1361 21.2385 19.211 21.6368 19.211 22.0708C19.211 22.5304 19.1259 22.9517 18.9557 23.3338C18.7854 23.7168 18.5446 24.0444 18.2348 24.3168C17.9233 24.59 17.5531 24.8027 17.1233 24.9551C16.6944 25.1083 16.2195 25.1857 15.7003 25.1857C15.0365 25.1857 14.4382 25.0614 13.9071 24.8146C13.3752 24.5678 12.9642 24.2146 12.6748 23.7551L14.0025 22.224C14.2493 22.4878 14.5233 22.6938 14.8263 22.8427C15.1276 22.9917 15.4237 23.0666 15.7131 23.0666C16.028 23.0666 16.2748 22.9814 16.4535 22.8112C16.6323 22.641 16.7216 22.4104 16.7216 22.121C16.7216 21.8402 16.6408 21.6155 16.4791 21.4444C16.3174 21.2742 16.1046 21.19 15.8408 21.19C15.662 21.19 15.4978 21.2308 15.3488 21.3108C15.2008 21.3917 15.0876 21.5006 15.011 21.6368H12.9301L14.0025 15.9934H18.5471V18.2027H15.7514L15.611 19.1849C15.645 19.1763 15.6918 19.1687 15.7514 19.1602C15.794 19.1517 15.8535 19.1423 15.9301 19.1346C16.0067 19.1261 16.0961 19.1219 16.1982 19.1219Z", fill: "#F9F1B7" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.5855 23.2832L23.0663 20.8322L22.5812 21.3683V23.2832H20.1557V13.9641H22.5812V19.0577L24.3684 16.5683H26.9216L24.611 19.5045L27.1514 23.2832H24.5855Z", fill: "#F9F1B7" })));
});
