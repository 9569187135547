var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
export var Video = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 auto;\n  max-width: ", ";\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n\n  border-radius: ", ";\n\n  ", ";\n\n  &::after {\n    padding-top: 56.25%;\n    display: block;\n    content: '';\n  }\n\n  iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n"], ["\n  margin: 0 auto;\n  max-width: ", ";\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n\n  border-radius: ", ";\n\n  ",
    ";\n\n  &::after {\n    padding-top: 56.25%;\n    display: block;\n    content: '';\n  }\n\n  iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n"])), rem(768), rem(10), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-radius: ", ";\n  "], ["\n    border-radius: ", ";\n  "])), rem(20)));
var templateObject_1, templateObject_2;
