var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { selectLanguageProviderDomain } from 'containers/LanguageProvider/selectors';
import { store } from 'store';
var xApiKey = process.env.RAZZLE_APP_X_API_KEY;
export var configInterceptors = function (axios) {
    axios.interceptors.request.use(function (config) {
        // @ts-ignore
        config.headers.common['X-Api-Key'] = xApiKey;
        // @ts-ignore
        var locale = selectLanguageProviderDomain(store.getState()).locale;
        if (locale) {
            config.params = __assign(__assign({}, config.params), { lang: locale });
        }
        return config;
    });
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        // Do something with response error
        return Promise.reject(error);
    });
};
