var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ListItemMetaWrapper, ListItemWrapper, ListWrapper } from './styles';
var List = function (props) { return React.createElement(ListWrapper, __assign({}, props)); };
var ListItem = function (props) { return React.createElement(ListItemWrapper, __assign({}, props)); };
var ListItemMeta = function (props) { return (React.createElement(ListItemMetaWrapper, __assign({}, props))); };
ListItem.Meta = ListItemMeta;
List.Item = ListItem;
export { List, ListItem, ListItemMeta };
export default List;
