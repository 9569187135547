import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectLanguageProviderDomain = function (state) {
    return state.languageProvider || initialState;
};
var makeSelectLocale = function () {
    return createSelector(selectLanguageProviderDomain, function (_a) {
        var locale = _a.locale;
        return locale;
    });
};
var makeSelectLanguageProvider = function () {
    return createSelector(selectLanguageProviderDomain, function (subState) { return subState; });
};
export default makeSelectLanguageProvider;
export { selectLanguageProviderDomain, makeSelectLocale };
