var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var MedalCommentMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-8", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/8", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group-2", transform: "translate(1.300000, 3.883090)" },
                        React.createElement("g", { id: "Group-3" },
                            React.createElement("path", { d: "M19.695312,8.85263699 L1.2706785,8.85263699 C0.5717985,8.85263699 0,9.41883031 0,10.1104668 L0,18.2868366 C0,18.978609 0.571935,19.5446664 1.2706785,19.5446664 L4.7418735,19.5446664 L6.813807,22.5996096 L7.7948325,19.5446664 L19.695312,19.5446664 C20.394192,19.5446664 20.9659905,18.978609 20.9659905,18.2868366 L20.9659905,10.1104668 C20.9659905,9.41869441 20.394192,8.85263699 19.695312,8.85263699", id: "Fill-1", fill: "#8EC26E" }),
                            React.createElement("path", { d: "M16.5229428,12.981173 L16.5229428,12.981173 C15.9602898,12.981173 15.5001483,13.4394552 15.5001483,13.9995327 C15.5001483,14.5596101 15.9602898,15.0177565 16.5229428,15.0177565 L16.5229428,15.0177565 C17.0854593,15.0177565 17.5456008,14.5596101 17.5456008,13.9995327 C17.5456008,13.4394552 17.0854593,12.981173 16.5229428,12.981173", id: "Fill-4", fill: "#709857" }),
                            React.createElement("path", { d: "M10.4830225,12.981173 L10.4830225,12.981173 C9.92050605,12.981173 9.46022805,13.4394552 9.46022805,13.9995327 C9.46022805,14.5596101 9.92050605,15.0177565 10.4830225,15.0177565 C11.045539,15.0177565 11.5056805,14.5596101 11.5056805,13.9995327 C11.5056805,13.4394552 11.045539,12.981173 10.4830225,12.981173", id: "Fill-6", fill: "#709857" }),
                            React.createElement("path", { d: "M4.4431023,12.981173 L4.4431023,12.981173 C3.8805858,12.981173 3.4203078,13.4394552 3.4203078,13.9995327 C3.4203078,14.5596101 3.8805858,15.0177565 4.4431023,15.0177565 C5.0056188,15.0177565 5.4658968,14.5596101 5.4658968,13.9995327 C5.4658968,13.4394552 5.0056188,12.981173 4.4431023,12.981173", id: "Fill-8", fill: "#709857" }),
                            React.createElement("path", { d: "M22.0353666,-2.06932592e-15 C22.7342466,-2.06932592e-15 23.3059086,0.566193319 23.3059086,1.25782985 L23.3059086,9.43419958 C23.3059086,10.125972 22.7341101,10.6920294 22.0353666,10.6920294 L18.5641716,10.6920294 L16.4922381,13.7469727 L15.5112126,10.6920294 L3.6107331,10.6920294 C2.9118531,10.6920294 2.3400546,10.1261079 2.3400546,9.43419958 L2.3400546,1.25782985 C2.3400546,0.566057411 2.9118531,-2.06932592e-15 3.6107331,-2.06932592e-15 L22.0353666,-2.06932592e-15 Z", id: "Fill-10", fill: "#BCE2A5" }),
                            React.createElement("path", { d: "M6.8727126,4.07724426 L6.8727126,4.07724426 C7.4353656,4.07724426 7.8955071,4.53539061 7.8955071,5.09546806 C7.8955071,5.65554551 7.4353656,6.11382777 6.8727126,6.11382777 L6.8727126,6.11382777 C6.3101961,6.11382777 5.8500546,5.65554551 5.8500546,5.09546806 C5.8500546,4.53539061 6.3101961,4.07724426 6.8727126,4.07724426", id: "Fill-12", fill: "#8EC26E" }),
                            React.createElement("path", { d: "M12.9126328,4.07724426 L12.9126328,4.07724426 C13.4751493,4.07724426 13.9352908,4.53539061 13.9352908,5.09546806 C13.9352908,5.65554551 13.4751493,6.11382777 12.9126328,6.11382777 C12.3501163,6.11382777 11.8898383,5.65554551 11.8898383,5.09546806 C11.8898383,4.53539061 12.3501163,4.07724426 12.9126328,4.07724426", id: "Fill-14", fill: "#8EC26E" }),
                            React.createElement("path", { d: "M18.9524985,4.07724426 L18.9524985,4.07724426 C19.515015,4.07724426 19.975293,4.53539061 19.975293,5.09546806 C19.975293,5.65554551 19.515015,6.11382777 18.9524985,6.11382777 L18.9524985,6.11382777 C18.389982,6.11382777 17.9298405,5.65554551 17.9298405,5.09546806 C17.9298405,4.53539061 18.389982,4.07724426 18.9524985,4.07724426", id: "Fill-16", fill: "#8EC26E" }))),
                    React.createElement("g", { id: "Group", transform: "translate(6.500000, 7.766180)" })),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
