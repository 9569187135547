var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var containerTheme = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    border-top: ", " solid ", ";\n  "], ["\n    background: ", ";\n    border-top: ", " solid ", ";\n  "])), colors.base.white, rem(1), colors.space.tint400),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: ", ";\n    border-top: ", " solid ", ";\n  "], ["\n    background: ", ";\n    border-top: ", " solid ", ";\n  "])), colors.carbon.tint700, rem(1), colors.carbon.tint800),
});
var buttonTheme = theme('mode', {
    light: colors.space.tint800,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  align-items: center;\n  bottom: env(safe-area-inset-bottom, ", ");\n  display: flex;\n  height: ", ";\n  padding: ", " 0;\n  position: fixed;\n  transition: bottom 0.2s;\n  width: 100%;\n  z-index: 1600;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"], ["\n  ", ";\n  align-items: center;\n  bottom: env(safe-area-inset-bottom, ", ");\n  display: flex;\n  height: ", ";\n  padding: ", " 0;\n  position: fixed;\n  transition: bottom 0.2s;\n  width: 100%;\n  z-index: 1600;\n\n  ",
    ";\n\n  ",
    ";\n\n  ",
    ";\n"])), containerTheme, rem(20), rem(60), rem(4), media.greaterThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "]))), ifProp('isVisible', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      bottom: calc(", " + env(safe-area-inset-bottom, ", "));\n    "], ["\n      bottom: calc(", " + env(safe-area-inset-bottom, ", "));\n    "])), rem(80), rem(20))), ifProp('isRtl', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      .indiana-scroll-container button {\n        &:nth-child(1) {\n          order: 4;\n        }\n\n        &:nth-child(2) {\n          order: 3;\n        }\n\n        &:nth-child(3) {\n          order: 2;\n        }\n\n        &:nth-child(4) {\n          order: 1;\n          margin-left: auto;\n        }\n      }\n    "], ["\n      .indiana-scroll-container button {\n        &:nth-child(1) {\n          order: 4;\n        }\n\n        &:nth-child(2) {\n          order: 3;\n        }\n\n        &:nth-child(3) {\n          order: 2;\n        }\n\n        &:nth-child(4) {\n          order: 1;\n          margin-left: auto;\n        }\n      }\n    "])))));
export var CustomScroll = styled(ScrollContainer)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 100%;\n"], ["\n  display: flex;\n  height: 100%;\n  width: 100%;\n"])));
export var Button = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 0;\n  justify-content: center;\n  padding-left: ", ";\n  padding-right: ", ";\n\n  span {\n    flex: 0;\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1.2;\n    margin-left: ", ";\n    text-align: left;\n  }\n"], ["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 0;\n  justify-content: center;\n  padding-left: ", ";\n  padding-right: ", ";\n\n  span {\n    flex: 0;\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1.2;\n    margin-left: ", ";\n    text-align: left;\n  }\n"])), buttonTheme, rem(8), rem(12), rem(11), fonts.weight.semibold, rem(4));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
