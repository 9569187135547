import { notification } from 'antd';
import { EmailField } from 'components';
import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { StyledButton, StyledForm } from './styles';
export var NewsletterForm = memo(function (_a) {
    var values = _a.values, status = _a.status, handleChange = _a.handleChange, handleBlur = _a.handleBlur, handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting, setStatus = _a.setStatus;
    var _b;
    var formatMessage = useIntl().formatMessage;
    useEffect(function () {
        var _a;
        if (((_a = status === null || status === void 0 ? void 0 : status.api) === null || _a === void 0 ? void 0 : _a.name) === 'added') {
            notification.success({
                message: formatMessage({ id: 'containers.placeDetails.success' }),
                description: formatMessage({
                    id: 'containers.home.signUpNewsletter.optinSuccess',
                }),
                onClose: function () { return setStatus(undefined); },
            });
        }
    }, [(_b = status === null || status === void 0 ? void 0 : status.api) === null || _b === void 0 ? void 0 : _b.id]);
    return (React.createElement(StyledForm, { noValidate: true, onSubmit: handleSubmit },
        React.createElement(EmailField, { showLabel: false, name: "email", value: values.email, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting }),
        React.createElement(StyledButton, { variant: "warning", type: "submit" }, formatMessage(messages.submitButton))));
});
