var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { manWithCrutches, worldwideLandscapes } from 'assets/svg';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { breakpoints, colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var opacity = theme('mode', {
    light: '0.2',
    dark: '0.05',
});
export var NewTeamContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  max-width: ", ";\n  margin: auto auto 0;\n  padding-bottom: ", ";\n  position: relative;\n  width: 100%;\n\n  &::before {\n    background: url(", ") no-repeat 0% bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: ", ";\n    opacity: ", ";\n    position: absolute;\n    z-index: -1;\n\n    ", ";\n\n    ", ";\n\n    ", ";\n  }\n\n  &::after {\n    background: url(", ") no-repeat 90% bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: 100%;\n    position: absolute;\n    width: 100%;\n    z-index: -1;\n  }\n"], ["\n  color: ", ";\n  max-width: ", ";\n  margin: auto auto 0;\n  padding-bottom: ", ";\n  position: relative;\n  width: 100%;\n\n  &::before {\n    background: url(", ") no-repeat 0% bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: ", ";\n    opacity: ", ";\n    position: absolute;\n    z-index: -1;\n\n    ",
    ";\n\n    ",
    ";\n\n    ",
    ";\n  }\n\n  &::after {\n    background: url(", ") no-repeat 90% bottom;\n    bottom: 0;\n    content: '';\n    display: block;\n    height: 100%;\n    position: absolute;\n    width: 100%;\n    z-index: -1;\n  }\n"])), color, breakpoints.medium, rem(64), worldwideLandscapes, rem(320), opacity, media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-size: contain;\n      background-position: 50% bottom;\n      width: 140%;\n    "], ["\n      background-size: contain;\n      background-position: 50% bottom;\n      width: 140%;\n    "]))), media.between('small', 'medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-size: cover;\n      width: 110%;\n    "], ["\n      background-size: cover;\n      width: 110%;\n    "]))), media.lessThan('small')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-size: cover;\n      width: 100%;\n    "], ["\n      background-size: cover;\n      width: 100%;\n    "]))), manWithCrutches);
export var Title = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: ", ";\n"])), rem(18), fonts.weight.semibold, rem(20));
export var ButtonWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
