import React, { memo } from 'react';
export var LibraryOff = memo(function (_a) {
    var size = _a.size;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 32 32" },
        React.createElement("path", { fill: "#b3b3b3", style: { fill: '#b3b3b3' }, d: "M31.39 0.305h-30.476v30.476h30.476v-30.476z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M7.924 10.971h-1.524v8.838h1.524v-8.838z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M19.449 10.911l-1.865-0.139-0.67 8.963 1.865 0.139 0.67-8.963z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M21.3 10.176l-1.699-0.056-0.322 9.759 1.699 0.056 0.322-9.759z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M10.667 12.19h-2.743v7.619h2.743v-7.619z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M23.162 10.971h-1.829v8.838h1.829v-8.838z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M25.295 10.057h-1.524v9.752h1.524v-9.752z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M12.376 9.922l-1.785 0.217 1.185 9.745 1.785-0.217-1.185-9.745z" }),
        React.createElement("path", { fill: "#373737", style: { fill: '#373737' }, d: "M14.319 10.825l-1.72 0.521 2.609 8.609 1.72-0.521-2.609-8.609z" })));
});
