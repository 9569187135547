var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var EllipsisHorizontal = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M20 23.3846C22.0392 23.3846 23.6923 21.7315 23.6923 19.6923C23.6923 17.6531 22.0392 16 20 16C17.9608 16 16.3077 17.6531 16.3077 19.6923C16.3077 21.7315 17.9608 23.3846 20 23.3846Z", fill: color }),
        React.createElement("path", { d: "M32.3077 23.3846C34.3469 23.3846 36 21.7315 36 19.6923C36 17.6531 34.3469 16 32.3077 16C30.2685 16 28.6154 17.6531 28.6154 19.6923C28.6154 21.7315 30.2685 23.3846 32.3077 23.3846Z", fill: color }),
        React.createElement("path", { d: "M7.69231 23.3846C9.73151 23.3846 11.3846 21.7315 11.3846 19.6923C11.3846 17.6531 9.73151 16 7.69231 16C5.6531 16 4 17.6531 4 19.6923C4 21.7315 5.6531 23.3846 7.69231 23.3846Z", fill: color })));
});
