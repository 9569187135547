var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { UserProfileCard } from 'components';
import { SubscribeButton as SubscribeButtonComponent } from 'components/SubscribeButton';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var text = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var bgSeparator = theme('mode', {
    light: colors.space.tint200,
    dark: colors.space.tint700,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 ", ";\n"], ["\n  padding: 0 ", ";\n"])), rem(20));
export var UserItem = styled(UserProfileCard)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", " 0;\n  border-bottom: ", " solid ", ";\n\n  color: ", ";\n\n  cursor: pointer;\n"], ["\n  padding: ", " 0;\n  border-bottom: ", " solid ", ";\n\n  color: ", ";\n\n  cursor: pointer;\n"])), rem(12), rem(1), bgSeparator, text);
export var TabTitleContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var TabTitle = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: ", ";\n"], ["\n  margin-left: ", ";\n"])), rem(6));
export var WithoutFollow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: ", ";\n  text-align: center;\n\n  > span {\n    font-size: ", ";\n    font-weight: ", ";\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  margin-top: ", ";\n  text-align: center;\n\n  > span {\n    font-size: ", ";\n    font-weight: ", ";\n    color: ", ";\n  }\n"])), rem(30), rem(16), fonts.weight.bold, text);
export var SubscribeButton = styled(SubscribeButtonComponent)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: ", ";\n  margin-right: ", ";\n\n  ", ";\n"], ["\n  width: ", ";\n  margin-right: ", ";\n\n  ",
    ";\n"])), rem(150), rem(4), media.lessThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: ", ";\n  "], ["\n    width: ", ";\n  "])), rem(110)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
