var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint100,
});
export var MailSystemContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: ", ";\n  height: calc(100vh - ", ");\n\n  ", "\n"], ["\n  padding-top: ", ";\n  height: calc(100vh - ", ");\n\n  ",
    "\n"])), rem(60), rem(140), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: calc(100vh - ", ");\n  "], ["\n    height: calc(100vh - ", ");\n  "])), rem(80)));
export var DesktopView = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr;\n  height: 100%;\n\n  ", ";\n\n  ", "\n"], ["\n  display: grid;\n  grid-template-columns: 1fr;\n  height: 100%;\n\n  ",
    ";\n\n  ",
    "\n"])), media.between('medium', 'large')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    grid-template-columns: 300px 1fr;\n  "], ["\n    grid-template-columns: 300px 1fr;\n  "]))), media.greaterThan('large')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    grid-template-columns: 400px 1fr;\n  "], ["\n    grid-template-columns: 400px 1fr;\n  "]))));
export var Sidebar = styled.aside(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  overflow: hidden auto;\n\n  ", "\n"], ["\n  overflow: hidden auto;\n\n  ",
    "\n"])), media.greaterThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    border-right: ", " solid ", ";\n  "], ["\n    border-right: ", " solid ", ";\n  "])), rem(1), border));
export var MainContent = styled.main(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var Target = styled(Button)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  &:focus {\n    box-shadow: none !important;\n  }\n"], ["\n  &:focus {\n    box-shadow: none !important;\n  }\n"])));
export var Content = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    svg {\n      margin-left: 0.5rem;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    svg {\n      margin-left: 0.5rem;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
