var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var MedalCriterionDesktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { opacity: "0.458194", fillRule: "evenodd", clipRule: "evenodd", d: "M37.8839 13.9836L15.1497 5.70903C14.3766 5.42765 13.5127 5.83001 13.2309 6.60425L2.45331 36.2154C2.17172 36.9891 2.57466 37.8532 3.34775 38.1346L26.082 46.4092C26.8551 46.6906 27.7186 46.2874 28.0002 45.5138L38.7778 15.9026C39.0596 15.1283 38.657 14.265 37.8839 13.9836Z", fill: "#656D78" }),
        React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "-2", width: "43", height: "50" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.866801 37.2317L28.5626 47.3122L42.7741 8.26661L15.0782 -1.81385L0.866801 37.2317Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.13952 35.2667L25.1686 42.9206L35.4044 14.7981L14.3753 7.14413L4.13952 35.2667ZM37.0759 12.8593L14.3422 4.58492C13.5686 4.30333 12.7052 4.7059 12.4234 5.48013L1.64584 35.0913C1.36425 35.865 1.76661 36.7289 2.54028 37.0105L25.274 45.2849C26.0476 45.5665 26.9111 45.1633 27.1927 44.3896L37.9703 14.7784C38.2521 14.0042 37.8496 13.1409 37.0759 12.8593Z", fill: "#656D78" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.14009 35.2669L25.1692 42.9209L35.405 14.7983L14.3759 7.14433L4.14009 35.2669Z", fill: "white" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.2056 4.54864C28.3188 4.95382 28.8925 6.18526 28.4869 7.29961C28.0818 8.41283 26.8505 8.98793 25.7373 8.58275C24.624 8.17758 24.0505 6.94557 24.4557 5.83236C24.8613 4.718 26.0923 4.14347 27.2056 4.54864ZM32.1882 9.20032L30.1139 8.44533C30.1756 8.31814 30.232 8.18638 30.2815 8.05022C31.0502 5.93831 29.9625 3.60364 27.8517 2.83538C25.7409 2.06712 23.4064 3.1562 22.6378 5.26811C22.5882 5.40427 22.5467 5.54142 22.5122 5.67857L20.4385 4.92379C19.924 4.73654 19.3511 5.00417 19.1637 5.51918L17.932 8.90325C17.7447 9.4177 18.0114 9.99154 18.5258 10.1788L30.2755 14.4553C30.7894 14.6424 31.3631 14.3744 31.5503 13.8599L32.782 10.4758C32.9695 9.96083 32.702 9.38736 32.1882 9.20032Z", fill: "#545C66" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.87 8.05662C20.7189 8.47193 20.2598 8.68551 19.8456 8.53477C19.4309 8.38381 19.2165 7.92513 19.3677 7.50982C19.5189 7.0945 19.9779 6.88092 20.3927 7.03187C20.8068 7.18262 21.0212 7.6413 20.87 8.05662Z", fill: "#AAB2BD" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.3849 11.8837C31.2337 12.299 30.7747 12.5126 30.3599 12.3616C29.9457 12.2109 29.7308 11.752 29.882 11.3367C30.0331 10.9214 30.4927 10.708 30.9069 10.8588C31.3217 11.0097 31.536 11.4684 31.3849 11.8837Z", fill: "#AAB2BD" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.6404 17.7273L37.1443 23.7342L37.5302 22.674L21.0263 16.6671L20.6404 17.7273Z", fill: "#8ABFF9" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.8848 19.8033L36.3887 25.8102L36.7744 24.7506L20.2705 18.7436L19.8848 19.8033Z", fill: "#8ABFF9" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.7416 17.757L17.1329 19.3553L18.7319 14.9622L14.3406 13.3639L12.7416 17.757Z", fill: "#5D9CEC" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.1949 24.7541L14.5862 26.3524L16.1853 21.9588L11.794 20.3605L10.1949 24.7541Z", fill: "#5D9CEC" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.1134 24.6703L27.57 28.1122L27.9556 27.0526L18.499 23.6107L18.1134 24.6703Z", fill: "#8ABFF9" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.3577 26.7463L26.8143 30.1883L27.2002 29.128L17.7436 25.6861L17.3577 26.7463Z", fill: "#8ABFF9" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.6085 31.8601L11.9998 33.4584L13.599 29.0648L9.20764 27.4665L7.6085 31.8601Z", fill: "#5D9CEC" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.5268 31.7769L24.9834 35.2188L25.3693 34.1586L15.9127 30.7166L15.5268 31.7769Z", fill: "#8ABFF9" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.7712 33.8529L24.2278 37.2948L24.6135 36.2352L15.1569 32.7932L14.7712 33.8529Z", fill: "#8ABFF9" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.0466 17.9586L14.5517 14.1442L15.7526 13.9877L15.9272 15.3319L18.7406 12.4661L19.6047 13.3148L15.0466 17.9586Z", fill: "#8ABFF9" }))));
});
