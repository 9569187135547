import { PageHeader, PageWrapper } from 'components';
import ChangePassword from 'containers/ChangePassword';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { usePush, useSelector } from 'hooks';
import { Close } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { Container, Content } from './styles';
var ChangePasswordPage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var goToSignIn = usePush("" + paths.signInPage);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.changePasswordPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.changePasswordPage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.changePasswordPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.changePasswordPage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(PageHeader, { sticky: true, title: formatMessage({ id: 'pages.changePasswordPage.title' }), extra: [
                    React.createElement("div", { key: 1, className: "ant-page-header-close" },
                        React.createElement("button", { type: "button", onClick: goToSignIn },
                            React.createElement(Close, { size: rem(16), color: colors.space.tint600 }))),
                ] }),
            React.createElement(Container, null,
                React.createElement(Content, null,
                    React.createElement(ChangePassword, null))))));
};
export default ChangePasswordPage;
