import { paths } from 'containers/RoutesProvider/routes';
import { ArrowBack } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { BackButton, Container, Title } from './styles';
export var Header = function (_a) {
    var setListItemSelected = _a.setListItemSelected, listItemSelected = _a.listItemSelected, sectionTitle = _a.sectionTitle;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (React.createElement(Container, null,
        (isMobile || isTabletOrMobile) && listItemSelected && (React.createElement(BackButton, { to: paths.aboutPageMobileIndex, onClick: function () { return setListItemSelected(false); } },
            React.createElement(ArrowBack, { size: rem(18) }))),
        React.createElement(Title, null, sectionTitle)));
};
