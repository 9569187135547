var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { notification } from 'antd';
import { PageWrapper, Pagination } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { AddNewPlace, ExploreMap, HeaderDesktop, HeaderMobile, PlacesListDesktop, PlacesListMobile, } from 'containers/ExplorePlaces';
import { fetchPlacesAround } from 'containers/ExplorePlaces/store/placesAround/actions';
import makePlacesAround from 'containers/ExplorePlaces/store/placesAround/selectors';
import { clearFilter, getAccessibilityFilters, setBooleanFilter, setFilterMobileVisible, setPlacesWithoutDetails, setSelectedAccessibilityFilters, setSelectedCategory, } from 'containers/FilterPlaces/actions';
import { FiltersWrapperMobile } from 'containers/FilterPlaces/FiltersWrapperMobile';
import { makeSelectorAccessibilityFilters, makeSelectorBooleanFilter, makeSelectorFilterMobileVisible, makeSelectorFilterState, makeSelectorPlacesWithoutDetails, makeSelectorSelectedAccessibilityFilters, makeSelectorSelectedCategory, } from 'containers/FilterPlaces/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { getCategories } from 'containers/PlaceDetails/actions';
import { makeSelectorCategories } from 'containers/PlaceDetails/selectors';
import { useGeolocation, useQuery, useSelector } from 'hooks';
import { Medal200Desktop } from 'icons/medal';
import find from 'lodash/find';
import join from 'lodash/join';
import map from 'lodash/map';
import split from 'lodash/split';
import { rem } from 'polished';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useAction } from 'utils/hooks';
import { queryParamsStringToArray, updateQueryParams } from 'utils/queryString';
import { ColaborationMessage, ExplorePlacesContainer, IconWrapper, MapWrapper, PaginationWrapper, Sidebar, } from './styles';
var ExplorePlacesPage = function () {
    var PER_PAGE_DESKTOP = 50;
    var PER_PAGE_MOBILE = 20;
    var DEFAULT_ZOOM = 18;
    var PARIS_LOCATION = {
        lat: 48.856614,
        lng: 2.3522219,
    };
    var formatMessage = useIntl().formatMessage;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var location = useLocation();
    var queryParams = useQuery();
    var geolocation = useGeolocation();
    var _a = __read(useState('0px'), 2), containerHeight = _a[0], setContainerHeight = _a[1];
    var _b = __read(useState(Number(queryParams.get('zoom')) || DEFAULT_ZOOM), 2), mapZoom = _b[0], setMapZoom = _b[1];
    var isDefaultSearch = useRef(false);
    var prevSearch = useRef('');
    var googleMapRef = useRef(null);
    var keyboard = useSelector(makeSelectUseKeyboard());
    var placesAround = useSelector(makePlacesAround());
    var filtersMobileVisible = useSelector(makeSelectorFilterMobileVisible());
    var locale = useSelector(makeSelectLocale());
    var filterState = useSelector(makeSelectorFilterState());
    var booleanFilterResult = useSelector(makeSelectorBooleanFilter());
    var placesWithoutDetails = useSelector(makeSelectorPlacesWithoutDetails());
    var categoriesSelector = useSelector(makeSelectorCategories());
    var selectedCategory = useSelector(makeSelectorSelectedCategory());
    var accessibilityFilters = useSelector(makeSelectorAccessibilityFilters());
    var selectedAccessibility = useSelector(makeSelectorSelectedAccessibilityFilters());
    var setSelectedCategoryCallback = useAction(setSelectedCategory);
    var getCategoriesCallback = useAction(getCategories);
    var getAccessibilityFiltersCallback = useAction(getAccessibilityFilters);
    var setSelectedAccessibilityFiltersCallback = useAction(setSelectedAccessibilityFilters);
    var clearFilterCallback = useAction(clearFilter);
    var fetchPlacesAroundRequest = useAction(function (payload) {
        return fetchPlacesAround(payload);
    });
    var setFilterMobileVisibleCallback = useAction(setFilterMobileVisible);
    var setBooleanFilterCallback = useAction(setBooleanFilter);
    var setPlacesWithoutDetailsCallback = useAction(setPlacesWithoutDetails);
    var updateSearch = function (newParams, without) {
        return updateQueryParams(newParams, queryParamsStringToArray(window.location.search), without);
    };
    var setMapCenter = function (lat, lng) {
        if (!googleMapRef.current)
            return;
        googleMapRef.current.panTo({ lat: lat, lng: lng });
    };
    var loadPlacesAround = function (lat, lng, page, booleanFilter, google, category, accessibility) {
        if (booleanFilter === void 0) { booleanFilter = 0; }
        if (google === void 0) { google = 0; }
        var perPage = isMobile || isTabletOrMobile ? PER_PAGE_MOBILE : PER_PAGE_DESKTOP;
        var accessibilityArray;
        if (accessibility) {
            accessibilityArray = split(accessibility, ',');
        }
        var payload = {
            page: page,
            perPage: perPage,
            lat: lat,
            lng: lng,
            booleanFilter: booleanFilter,
            google: google,
            category: category,
            accessibility: accessibilityArray,
        };
        fetchPlacesAroundRequest(payload);
    };
    var loadCategories = function () {
        if (!(categoriesSelector === null || categoriesSelector === void 0 ? void 0 : categoriesSelector.length)) {
            getCategoriesCallback();
        }
    };
    var loadAccessibilityFilters = function () {
        if (!(accessibilityFilters === null || accessibilityFilters === void 0 ? void 0 : accessibilityFilters.length)) {
            getAccessibilityFiltersCallback();
        }
    };
    var updateURL = function (newParams, without) {
        var search = updateSearch(newParams, without);
        window.history.pushState({}, '', location.pathname + "?" + search);
    };
    var getAccessibilityFiltersAsString = function (filters) {
        return join(map(filters, 'criterionId'), ',');
    };
    var getFilters = function () {
        var _a;
        var category;
        var accessibility;
        if (filterState.selectedCategory) {
            category = filterState.selectedCategory.id;
        }
        if ((_a = filterState.selectedAccessibilityFilters) === null || _a === void 0 ? void 0 : _a.length) {
            accessibility = getAccessibilityFiltersAsString(filterState.selectedAccessibilityFilters);
        }
        var booleanFilter = filterState.booleanFilter ? 1 : 0;
        var placeWithoutDetails = filterState.placeWithoutDetails ? 1 : 0;
        return { category: category, accessibility: accessibility, booleanFilter: booleanFilter, placeWithoutDetails: placeWithoutDetails };
    };
    var setCategoryParam = function (categoryId) {
        var _a = getFilters(), accessibility = _a.accessibility, booleanFilter = _a.booleanFilter, placeWithoutDetails = _a.placeWithoutDetails;
        if (categoryId) {
            updateURL([
                ['category', categoryId],
                ['page', '1'],
            ], []);
        }
        else {
            updateURL([['page', '1']], ['category']);
        }
        loadPlacesAround(placesAround.lat, placesAround.lng, 1, booleanFilter, placeWithoutDetails, categoryId ? Number(categoryId) : undefined, accessibility);
    };
    var updateContainerHeight = function () {
        var desktopHeight = "calc(" + window.innerHeight + "px - " + rem(80) + ")";
        var mobileHeight = "calc(" + window.innerHeight + "px - " + rem(60) + ")";
        setContainerHeight(isTabletOrMobile ? mobileHeight : desktopHeight);
    };
    var searchWithGPSLocation = function () {
        // @ts-ignore
        var latitude = geolocation.latitude, longitude = geolocation.longitude;
        if (latitude !== 0 && longitude !== 0) {
            isDefaultSearch.current = false;
            var _a = getFilters(), category = _a.category, accessibility = _a.accessibility, booleanFilter = _a.booleanFilter, placeWithoutDetails = _a.placeWithoutDetails;
            var params = [
                ['page', '1'],
                ['lat', String(latitude)],
                ['lng', String(longitude)],
                ['google', String(placeWithoutDetails)],
                ['boolean_filter', String(booleanFilter)],
            ];
            updateURL(params, []);
            loadPlacesAround(latitude, longitude, 1, booleanFilter, placeWithoutDetails, category, accessibility);
            setMapCenter(latitude, longitude);
        }
    };
    var searchDefaultPlaces = function () {
        // @ts-ignore
        if (geolocation.latitude !== 0 && geolocation.longitude !== 0) {
            searchWithGPSLocation();
            return;
        }
        var _a = getFilters(), category = _a.category, accessibility = _a.accessibility, booleanFilter = _a.booleanFilter, placeWithoutDetails = _a.placeWithoutDetails;
        var lat = PARIS_LOCATION.lat, lng = PARIS_LOCATION.lng;
        var params = [
            ['lat', String(lat)],
            ['lng', String(lng)],
            ['page', '1'],
            ['boolean_filter', String(booleanFilter)],
            ['google', String(placeWithoutDetails)],
        ];
        if (category)
            params.push(['category', String(category)]);
        if (accessibility)
            params.push(['accessibility', String(accessibility)]);
        updateURL(params, []);
        isDefaultSearch.current = true;
        loadPlacesAround(lat, lng, 1, booleanFilter, placeWithoutDetails, category, accessibility);
        setMapCenter(lat, lng);
    };
    var searchPlacesByURL = function () {
        var _a = getFilters(), categoryState = _a.category, accessibilityState = _a.accessibility, booleanFiltersState = _a.booleanFilter, googleState = _a.placeWithoutDetails;
        var lat = Number(queryParams.get('lat')) || 0;
        var lng = Number(queryParams.get('lng')) || 0;
        var page = Number(queryParams.get('page')) || 1;
        var booleanFilter = Number(queryParams.get('boolean_filter')) || booleanFiltersState || 0;
        var google = Number(queryParams.get('google')) || googleState || 0;
        var category;
        var accessibility;
        var params = [
            ['boolean_filter', String(booleanFilter)],
            ['google', String(google)],
        ];
        if (queryParams.has('category'))
            category = Number(queryParams.get('category'));
        else if (categoryState) {
            category = categoryState;
            params.push(['category', String(categoryState)]);
        }
        if (queryParams.has('accessibility'))
            accessibility = String(queryParams.get('accessibility'));
        else if (accessibilityState) {
            accessibility = accessibilityState;
            params.push(['accessibility', accessibilityState]);
        }
        if (lat === 0 || lng === 0)
            return;
        prevSearch.current = location.search;
        updateURL(params, []);
        loadPlacesAround(lat, lng, page, booleanFilter, google, category, accessibility);
        setMapCenter(lat, lng);
    };
    var checkURLFilters = function () {
        if (queryParams.has('boolean_filter')) {
            var value = Number(queryParams.get('boolean_filter'));
            if (booleanFilterResult !== !!value) {
                setBooleanFilterCallback(!!value);
            }
        }
        if (queryParams.has('google')) {
            var value = Number(queryParams.get('google'));
            if (placesWithoutDetails !== !!value) {
                setPlacesWithoutDetailsCallback(!!value);
            }
        }
    };
    var handleMapReady = function (mapRef) {
        googleMapRef.current = mapRef.current;
    };
    var handleUserLocationToMap = function () {
        // @ts-ignore
        var latitude = geolocation.latitude, longitude = geolocation.longitude;
        if (latitude && longitude) {
            var _a = getFilters(), category = _a.category, accessibility = _a.accessibility, booleanFilter = _a.booleanFilter, placeWithoutDetails = _a.placeWithoutDetails;
            var params = [
                ['lat', String(latitude)],
                ['lng', String(longitude)],
                ['page', '1'],
            ];
            updateURL(params, []);
            setFilterMobileVisibleCallback(false);
            loadPlacesAround(latitude, longitude, 1, booleanFilter, placeWithoutDetails, category, accessibility);
            setMapCenter(latitude, longitude);
            return;
        }
        notification.info({
            message: formatMessage({
                id: 'pages.explorePlacesPage.info',
            }),
            description: formatMessage({
                id: 'pages.explorePlacesPage.locationPermission',
            }),
        });
    };
    var handleFilter = function () {
        var _a = getFilters(), category = _a.category, accessibility = _a.accessibility, booleanFilter = _a.booleanFilter, placeWithoutDetails = _a.placeWithoutDetails;
        var newParams = [
            ['boolean_filter', String(booleanFilter)],
            ['google', String(placeWithoutDetails)],
        ];
        var without = [];
        if (category) {
            newParams.push(['category', String(category)]);
        }
        else {
            without.push('category');
        }
        if (accessibility) {
            newParams.push(['accessibility', accessibility]);
        }
        else {
            without.push('accessibility');
        }
        updateURL(newParams, without);
        setFilterMobileVisibleCallback(false);
        loadPlacesAround(placesAround.lat, placesAround.lng, 1, booleanFilter, placeWithoutDetails, category, accessibility);
    };
    var handleClear = function () {
        clearFilterCallback();
        // Updating URL
        var withoutParams = [
            'accessibility',
            'category',
            'boolean_filter',
            'google',
        ];
        updateURL([['page', '1']], withoutParams);
        loadPlacesAround(placesAround.lat, placesAround.lng, 1);
    };
    var handleAutocompleteSelect = function (_a) {
        var latitude = _a.latitude, longitude = _a.longitude;
        var _b = getFilters(), category = _b.category, accessibility = _b.accessibility, booleanFilter = _b.booleanFilter, placeWithoutDetails = _b.placeWithoutDetails;
        // Updating URL
        var params = [
            ['lat', String(latitude)],
            ['lng', String(longitude)],
            ['page', '1'],
        ];
        updateURL(params, []);
        loadPlacesAround(latitude, longitude, 1, booleanFilter, placeWithoutDetails, category, accessibility);
        setMapCenter(latitude, longitude);
    };
    var handleZoomChange = function (mapRef) {
        if (!mapRef.current)
            return;
        var params = [['zoom', String(mapRef.current.getZoom())]];
        updateURL(params, []);
        setMapZoom(mapRef.current.getZoom());
    };
    var handleMapDragEnd = function (mapRef) {
        if (!mapRef.current)
            return;
        var _a = getFilters(), category = _a.category, accessibility = _a.accessibility, booleanFilter = _a.booleanFilter, placeWithoutDetails = _a.placeWithoutDetails;
        var _b = mapRef.current.getCenter(), lat = _b.lat, lng = _b.lng;
        var params = [
            ['page', '1'],
            ['lat', String(lat())],
            ['lng', String(lng())],
            ['zoom', String(mapRef.current.getZoom())],
        ];
        updateURL(params, []);
        loadPlacesAround(lat(), lng(), 1, booleanFilter, placeWithoutDetails, category, accessibility);
    };
    var handlePageChange = function (page) {
        var _a = getFilters(), category = _a.category, accessibility = _a.accessibility, booleanFilter = _a.booleanFilter, placeWithoutDetails = _a.placeWithoutDetails;
        updateURL([['page', String(page)]], []);
        loadPlacesAround(placesAround.lat, placesAround.lng, page, booleanFilter, placeWithoutDetails, category, accessibility);
    };
    useEffect(function () {
        updateContainerHeight();
        window.addEventListener('resize', updateContainerHeight);
        checkURLFilters();
        loadCategories();
        loadAccessibilityFilters();
    }, []);
    useEffect(function () {
        if (!googleMapRef.current)
            return;
        if (queryParams.has('lat') && queryParams.has('lng')) {
            searchPlacesByURL();
        }
        else {
            searchDefaultPlaces();
        }
    }, [googleMapRef.current]);
    useEffect(function () {
        if (isDefaultSearch.current) {
            searchWithGPSLocation();
        }
    }, [geolocation]);
    useEffect(function () {
        if (queryParams.has('category')) {
            var value = Number(queryParams.get('category'));
            if ((selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id) !== value && (categoriesSelector === null || categoriesSelector === void 0 ? void 0 : categoriesSelector.length)) {
                var foundCategory = find(categoriesSelector, { id: value });
                if (foundCategory)
                    setSelectedCategoryCallback(foundCategory);
            }
        }
    }, [categoriesSelector]);
    useEffect(function () {
        if (queryParams.has('accessibility')) {
            var value = queryParams.get('accessibility');
            if (value &&
                getAccessibilityFiltersAsString(selectedAccessibility || []) !== value) {
                var filters_1 = [];
                map(split(value, ','), function (v) {
                    var found = find(accessibilityFilters, { criterionId: Number(v) });
                    if (found)
                        filters_1.push(found);
                });
                setSelectedAccessibilityFiltersCallback(filters_1);
            }
        }
    }, [accessibilityFilters]);
    var renderPlaceListMobile = useCallback(function () { return (React.createElement(PlacesListMobile, { places: placesAround.items || [], currentPage: placesAround.currentPage, perPage: PER_PAGE_MOBILE, total: placesAround.total, loading: placesAround.loading, handlePageChange: handlePageChange })); }, [placesAround.items]);
    var renderHeaderMobile = useCallback(function () { return (React.createElement(HeaderMobile, { onSelect: handleAutocompleteSelect, onSelectCategory: setCategoryParam, height: containerHeight })); }, [containerHeight]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.explorePlacesPage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({
                    id: 'pages.explorePlacesPage.description',
                }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.explorePlacesPage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({
                    id: 'pages.explorePlacesPage.description',
                }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, { style: { paddingBottom: 0 }, onAutocompleteSelect: handleAutocompleteSelect },
            React.createElement(ExplorePlacesContainer, { id: "main", isKeyboardMode: keyboard, style: filtersMobileVisible ? { display: 'none' } : {}, height: containerHeight },
                isMobile || isTabletOrMobile ? (renderHeaderMobile()) : (React.createElement(HeaderDesktop, { onFilter: handleFilter })),
                isMobile || isTabletOrMobile ? (renderPlaceListMobile()) : (React.createElement(Sidebar, { isKeyboardMode: keyboard },
                    React.createElement(ColaborationMessage, null,
                        React.createElement(IconWrapper, null,
                            React.createElement(Medal200Desktop, { size: rem(42) })),
                        React.createElement("span", null,
                            React.createElement("b", null,
                                formatMessage({
                                    id: 'pages.explorePlacesPage.collaboration.highlight',
                                }),
                                ' '),
                            formatMessage({
                                id: 'pages.explorePlacesPage.collaboration.message',
                            }))),
                    React.createElement(PlacesListDesktop, { places: placesAround.items || [] }),
                    React.createElement(PaginationWrapper, null,
                        React.createElement(Pagination, { hideOnSinglePage: true, showLessItems: true, current: placesAround.currentPage, defaultCurrent: 1, pageSize: PER_PAGE_DESKTOP, showSizeChanger: false, total: placesAround.total, onChange: handlePageChange, disabled: placesAround.loading, isKeyboardMode: keyboard })))),
                React.createElement(AddNewPlace, { hasPlaceList: placesAround.total > 0 }),
                React.createElement(MapWrapper, { hasPlaceList: placesAround.total > 0, isKeyboardMode: keyboard },
                    React.createElement(ExploreMap, { onDragEnd: handleMapDragEnd, customOptions: {
                            zoom: mapZoom,
                        }, onUserLocation: handleUserLocationToMap, places: placesAround.items || [], onZoomChanged: handleZoomChange, onMapReady: handleMapReady }))),
            filtersMobileVisible && (React.createElement(FiltersWrapperMobile, { onFilter: handleFilter, onClear: handleClear })))));
};
export default ExplorePlacesPage;
