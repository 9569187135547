var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var Bookmark = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M28.9571 4H14.9844C13.164 4.0015 11.5744 5.2322 11.1169 6.99415H25.3392C27.8885 6.99415 29.9552 9.0608 29.9552 11.6101V30.6286L31.3275 31.7265C31.6771 32.0063 32.1703 32.0191 32.5339 31.7577C32.7991 31.5618 32.9537 31.2502 32.9494 30.9205V7.9922C32.9494 5.78737 31.1619 4 28.9571 4Z", fill: color }),
        React.createElement("path", { d: "M23.9668 8.99025H10.9922C8.78736 8.99025 6.99987 10.7776 6.99987 12.9825V34.9159C6.995 35.2441 7.1482 35.5547 7.41168 35.7505C7.78296 36.0201 8.29024 36.0018 8.64116 35.7062L17.4795 28.2601L26.3179 35.7031C26.6664 35.9967 27.1695 36.017 27.5405 35.7524C27.8073 35.557 27.9632 35.2447 27.9591 34.914V12.9825C27.9591 10.7776 26.1717 8.99025 23.9668 8.99025Z", fill: color })));
});
