var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var MedalPlaceMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0 0 5.90862581 0 5.90862581 4.41709845 0 4.41709845" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-9", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/9", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group", transform: "translate(6.500000, 7.468912)" }),
                    React.createElement("g", { id: "Group-19", transform: "translate(0.000000, 4.356865)" },
                        React.createElement("polygon", { id: "Fill-1", fill: "#A0D468", points: "4.19350645 13.6528899 3.07424839 15.2591075 -4.19354839e-05 19.676206 3.71124839 19.676206 22.2890871 19.676206 25.9999581 19.676206 24.8802806 18.0699883 23.7647968 16.4637707 21.8064097 13.6528899" }),
                        React.createElement("polygon", { id: "Fill-2", fill: "#8CC152", points: "24.8803645 18.0699482 22.368429 18.0699482 22.3642355 18.0699482 20.6868161 16.4637306 23.7648806 16.4637306" }),
                        React.createElement("polygon", { id: "Fill-3", fill: "#8CC152", points: "15.5578968 17.2668394 17.3737032 15.6606218 19.6554129 19.2746114 9.69950968 19.2746114 8.02209032 17.6683938" }),
                        React.createElement("polygon", { id: "Fill-4", fill: "#E6E9ED", points: "21.4879935 18.069908 22.2889613 19.6765272 26.0002516 19.6765272 24.8801548 18.069908" }),
                        React.createElement("g", { id: "Group-18" },
                            React.createElement("path", { d: "M13,16.4637306 C13,16.4637306 13.3480645,16.1705959 13.8764516,15.6606218 L13.8806452,15.6606218 L17.373871,15.6606218 L17.9777419,17.6683938 L8.02225806,17.6683938 L8.62612903,15.6606218 L12.1193548,15.6606218 C12.6519355,16.1705959 13,16.4637306 13,16.4637306", id: "Fill-5", fill: "#E6E9ED" }),
                            React.createElement("g", { id: "Group-9", transform: "translate(0.000000, 15.259067)" },
                                React.createElement("mask", { id: "mask-2", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-1" })),
                                React.createElement("g", { id: "Clip-8" }),
                                React.createElement("polygon", { id: "Fill-7", fill: "#E6E9ED", mask: "url(#mask-2)", points: "5.90862581 0 3.71120645 4.41709845 -8.38709677e-05 4.41709845 3.0737871 0" })),
                            React.createElement("polygon", { id: "Fill-10", fill: "#8CC152", points: "7.58604516 17.0697127 6.18749677 20.238342 3.71120645 20.238342 5.90862581 15.2590674" }),
                            React.createElement("polygon", { id: "Fill-12", fill: "#81B53E", points: "26 20.238342 26 21.8445596 0 21.8445596 0 20.238342 3.71129032 20.238342 22.2887097 20.238342" }),
                            React.createElement("polygon", { id: "Fill-14", fill: "#E6E9ED", points: "23.7647968 16.4637306 20.6909258 16.4637306 20.6867323 16.4637306 19.2902806 13.6528497 21.8064097 13.6528497" }),
                            React.createElement("path", { d: "M13,9.16439508 C11.5767097,9.16439508 10.4230645,8.05971891 10.4230645,6.69684326 C10.4230645,5.33436917 11.5767097,4.22969301 13,4.22969301 C14.4232903,4.22969301 15.5769355,5.33436917 15.5769355,6.69684326 C15.5769355,8.05971891 14.4232903,9.16439508 13,9.16439508 M13,0.000120466321 C9.52354839,0.000120466321 6.70967742,2.69816451 6.70967742,6.02343653 C6.70967742,8.81825518 8.55483871,11.6488122 10.2444194,13.6529702 C10.9447419,14.4801723 11.616129,15.1704443 12.1193548,15.6607422 C12.6519355,16.1703148 12.9995806,16.463851 12.9995806,16.463851 C12.9995806,16.463851 13.3476452,16.1703148 13.8760323,15.6607422 L13.8802258,15.6607422 C14.3834516,15.1704443 15.0548387,14.4801723 15.7551613,13.6529702 C17.4451613,11.6488122 19.2903226,8.81825518 19.2903226,6.02343653 C19.2903226,2.69816451 16.4764516,0.000120466321 13,0.000120466321", id: "Fill-16", fill: "#ED5565" })))),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
