import { SIGN_UP_FAILURE, SIGN_UP_REQUEST, SIGN_UP_SUCCESS } from './constants';
export var signUp = function (payload, actions, meta) { return ({
    type: SIGN_UP_REQUEST,
    payload: payload,
    actions: actions,
    meta: meta,
}); };
export var signUpSuccess = function (payload) { return ({
    type: SIGN_UP_SUCCESS,
    payload: payload,
}); };
export var signUpFailure = function (payload) { return ({
    type: SIGN_UP_FAILURE,
    payload: payload,
}); };
