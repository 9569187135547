import { List } from 'components';
import { ArrowNext } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';
import { sections } from '../../constants';
import { EditOptionsContainer } from './styles';
var EditOptions = function (_a) {
    var criteriaMenu = _a.criteriaMenu, onSelectOption = _a.onSelectOption, setActiveCriteria = _a.setActiveCriteria;
    var formatMessage = useIntl().formatMessage;
    var messages = function (messagePath) {
        return formatMessage({ id: "containers.editPlace." + messagePath });
    };
    return (React.createElement(EditOptionsContainer, null,
        React.createElement(List, null,
            React.createElement(List.Item, { onClick: function () { return onSelectOption(sections.category); } },
                React.createElement(List.Item.Meta, { title: messages('category') }),
                React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 })),
            map(criteriaMenu, function (item) { return (React.createElement(List.Item, { key: item.id, onClick: function () {
                    onSelectOption(sections.criteria);
                    setActiveCriteria(item.id);
                } },
                React.createElement(List.Item.Meta, { title: item.title }),
                React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 }))); }))));
};
EditOptions.defaultProps = { criteriaMenu: [] };
export { EditOptions };
