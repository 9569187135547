var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
export var FontSizeGray = memo(function (props) { return (React.createElement("svg", __assign({ width: "31", height: "31", viewBox: "0 0 31 31", fill: "none" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.918 11.505l-2.373 6.339h4.923l-2.55-6.34zM11.038 24v-.652l.809-.078c.833-.078 1.161-.287 1.161-.861 0-.574-.303-1.487-1.161-3.548H6.166l-.328.887c-.303.809-.783 1.904-.783 2.53 0 .679.555.966 2.045 1.07V24H2v-.652c1.161-.209 1.54-.678 2.5-3.13L10.08 6h.706l5.605 13.618c1.288 3.156 1.742 3.73 2.778 3.73V24h-8.13z", fill: "#585858" }),
    React.createElement("path", { opacity: ".3", fillRule: "evenodd", clipRule: "evenodd", d: "M18.37 17.217l2.01-5.23 2.161 5.23H18.37zm8.342 1.464L21.964 7.446h-.6l-3.94 9.78.526 1.093.1-.262h4.812c.727 1.7.984 2.453.984 2.927 0 .473-.278.645-.984.71l-.685.065v.538h6.888v-.538c-.877 0-1.262-.474-2.353-3.078z", fill: "#585858" }))); });
