var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { avatarImage1 } from 'assets/images';
import { Avatar, Ellipsis, FloatButton, Modal } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { Medal200Desktop } from 'icons/medal';
import { Edit, Send } from 'icons/mono';
import { rem } from 'polished';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import PointsInfo from '../PointsInfo';
import Medals from './Medals';
import { Container, Skeleton, SubscribeButton, UserContainer, UserMessage, UserName, UserPoints, UserSlug, } from './styles';
import SubscriptionButtons from './SubscriptionButtons';
var Mobile = function (_a) {
    var contributionsStats = _a.contributionsStats, darkMode = _a.darkMode, handleToggleSubscribe = _a.handleToggleSubscribe, isSubscribed = _a.isSubscribed, loading = _a.loading, showEditMyProfileButton = _a.showEditMyProfileButton, showSendMessageButton = _a.showSendMessageButton, showSubscribeButton = _a.showSubscribeButton, subscribersLink = _a.subscribersLink, subscribersSize = _a.subscribersSize, subscriptionsLink = _a.subscriptionsLink, subscriptionsSize = _a.subscriptionsSize, _b = _a.totalPoints, totalPoints = _b === void 0 ? 0 : _b, user = _a.user;
    var _c;
    var history = useHistory();
    var location = useLocation();
    var userSlug = (user === null || user === void 0 ? void 0 : user.firstName) ? "(@" + (user === null || user === void 0 ? void 0 : user.slug) + ")" : "@" + (user === null || user === void 0 ? void 0 : user.slug);
    var _d = __read(useState(false), 2), modalVisible = _d[0], setModalVisible = _d[1];
    function handleEditMyProfile() {
        history.push(paths.editProfilePage, { from: location.pathname });
    }
    function handleSendMessage() {
        // eslint-disable-next-line no-console
        console.log('TODO: redirect to send a message page');
        setModalVisible(true);
    }
    return (React.createElement(Container, { hasSubscribeButton: showSubscribeButton },
        React.createElement(UserContainer, null,
            React.createElement(Skeleton, { loading: loading, active: true, vertical: true, paragraph: { rows: 4 }, avatar: { shape: 'square', size: 60 } },
                React.createElement(Avatar, { src: ((_c = user === null || user === void 0 ? void 0 : user.avatar) === null || _c === void 0 ? void 0 : _c.urlThumb) || avatarImage1, alt: "User profile image" }),
                (user === null || user === void 0 ? void 0 : user.firstName) && (React.createElement(UserName, null, ((user === null || user === void 0 ? void 0 : user.firstName) || '') + " " + ((user === null || user === void 0 ? void 0 : user.lastName) || ''))),
                React.createElement(UserSlug, { hasNotFirstName: !(user === null || user === void 0 ? void 0 : user.firstName) }, userSlug),
                React.createElement(UserPoints, null,
                    React.createElement(Medal200Desktop, { size: 20 }),
                    React.createElement("span", null,
                        totalPoints,
                        ' ',
                        React.createElement(FormattedMessage, { id: "containers.profile.user.points" })),
                    React.createElement(PointsInfo, null)),
                (user === null || user === void 0 ? void 0 : user.message) && (React.createElement(UserMessage, null,
                    React.createElement("i", null,
                        React.createElement(Ellipsis, { expandable: true, canHide: true }, "\"" + (user === null || user === void 0 ? void 0 : user.message) + "\"" || ''))))),
            showSubscribeButton && (React.createElement(SubscribeButton, { size: "mini", isSubscribed: isSubscribed, onClick: handleToggleSubscribe, disabled: loading }))),
        showEditMyProfileButton && (React.createElement(FloatButton, { size: "small", shape: "pill", onClick: handleEditMyProfile, icon: React.createElement(Edit, { size: rem(16), color: "white" }) },
            React.createElement(FormattedMessage, { id: "containers.profile.user.editMyProfile.mobile" }))),
        showSendMessageButton && (React.createElement(FloatButton, { size: "small", shape: "pill", onClick: handleSendMessage, icon: React.createElement(Send, { size: rem(16), color: "white" }) },
            React.createElement(FormattedMessage, { id: "containers.profile.user.sendMessage" }))),
        React.createElement(Modal, { visible: modalVisible, onOk: function () { return setModalVisible(false); }, cancelButtonProps: { style: { display: 'none' } }, closable: false },
            React.createElement("p", null,
                React.createElement(FormattedMessage, { id: "containers.profile.user.chatModalText" }))),
        React.createElement(Medals, { darkMode: darkMode, totalPoints: totalPoints, contributionsStats: contributionsStats }),
        React.createElement(SubscriptionButtons, { subscribersSize: subscribersSize, subscriptionsSize: subscriptionsSize, subscribersLink: subscribersLink, subscriptionsLink: subscriptionsLink })));
};
export default Mobile;
