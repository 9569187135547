import { Modal } from 'components/Modal';
import { ChallengeInviteUserTeam } from 'containers/ChallengeInviteUserTeam';
import { paths } from 'containers/RoutesProvider/routes';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Message } from './styles';
export var ChallengeInviteUserTeamPage = function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var params = useParams();
    var handleClose = function () {
        return history.push(paths.membersTeamDetailPage
            .replace(':challengeSlug', params.challengeSlug)
            .replace(':teamSlug', params.teamSlug), { from: location.pathname });
    };
    return (React.createElement(Modal, { visible: true, onCancel: handleClose, footer: null, title: formatMessage({ id: 'pages.challengeInviteUserTeamPage.title' }) },
        React.createElement(Message, null, formatMessage({ id: 'pages.challengeInviteUserTeamPage.subtitle' })),
        React.createElement(ChallengeInviteUserTeam, null)));
};
