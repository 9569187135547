import { paths } from 'containers/RoutesProvider/routes';
import map from 'lodash/map';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
export var NavTypePlace = memo(function (_a) {
    var _b = _a.items, items = _b === void 0 ? [] : _b, _c = _a.geolocation, geolocation = _c === void 0 ? null : _c;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement("nav", null,
        map(items, function (item) {
            var url = item.href;
            if (geolocation) {
                var latitude = geolocation.latitude, longitude = geolocation.longitude;
                url += "&lat=" + latitude + "&lng=" + longitude;
                if (latitude === 0 || longitude === 0) {
                    url += '&zoom=2';
                }
            }
            return (React.createElement("a", { key: item.href, href: url }, item.label));
        }),
        React.createElement("a", { href: paths.explore }, formatMessage({ id: 'containers.homeHeader.seeMore' }))));
});
