var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n  "], ["\n    border: ", " solid ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var PodiumItemContainer = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin: 0 ", ";\n  padding-top: ", ";\n  width: calc(min(30%, ", ") - ", ");\n  min-width: calc(30% - ", ");\n  text-decoration: none;\n\n  ", ";\n\n  ", "\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin: 0 ", ";\n  padding-top: ", ";\n  width: calc(min(30%, ", ") - ", ");\n  min-width: calc(30% - ", ");\n  text-decoration: none;\n\n  ",
    ";\n\n  ",
    "\n"])), rem(8), rem(16), rem(150), rem(16), rem(16), media.greaterThan('large')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 0 ", ";\n    width: calc(min(30%, ", ") - ", ");\n    min-width: calc(30% - ", ");\n  "], ["\n    margin: 0 ", ";\n    width: calc(min(30%, ", ") - ", ");\n    min-width: calc(30% - ", ");\n  "])), rem(16), rem(200), rem(32), rem(32)), ifProp('isKeyboardMode', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      &:focus {\n        position: relative;\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          top: ", ";\n          left: ", ";\n        }\n      }\n    "], ["\n      &:focus {\n        position: relative;\n        outline: 0 !important;\n\n        &::before {\n          ", ";\n          content: '';\n          width: calc(100% + ", ");\n          height: calc(100% + ", ");\n          display: block;\n          position: absolute;\n          border-radius: ", ";\n          top: ", ";\n          left: ", ";\n        }\n      }\n    "])), border, rem(10), rem(10), rem(10), rem(-8), rem(-9))));
export var UserName = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  white-space: nowrap;\n  text-align: center;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  white-space: nowrap;\n  text-align: center;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 100%;\n"])), color, rem(17), fonts.weight.semibold, rem(8));
export var UserSlug = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n  white-space: nowrap;\n  text-align: center;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 100%;\n\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-top: ", ";\n  white-space: nowrap;\n  text-align: center;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 100%;\n\n  ",
    ";\n"])), color, rem(14), rem(8), ifProp('hasNotFirstName', css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      font-size: ", ";\n      font-weight: ", ";\n    "], ["\n      font-size: ", ";\n      font-weight: ", ";\n    "])), rem(16), fonts.weight.bold)));
export var Score = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n"])), color, rem(17), fonts.weight.bold, rem(4), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
