var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), rem(31));
export var MedalRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var MedalWrapper = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: ", ";\n  margin-right: ", ";\n\n  &:last-child {\n    margin-right: 0;\n  }\n"], ["\n  margin-top: ", ";\n  margin-right: ", ";\n\n  &:last-child {\n    margin-right: 0;\n  }\n"])), rem(10), rem(10));
var templateObject_1, templateObject_2, templateObject_3;
