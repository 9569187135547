import produce from 'immer';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import { PLACES_AROUND_BOOKMARK, PLACES_AROUND_FAILURE, PLACES_AROUND_REQUEST, PLACES_AROUND_SET_LAT, PLACES_AROUND_SET_LNG, PLACES_AROUND_SUCCESS, } from './constants';
export var placesAroundInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    pages: 1,
    currentPage: 1,
    total: 0,
    lat: 0,
    lng: 0,
    items: [],
};
export var placesAroundReducer = produce(function (draft, action) {
    var _a;
    switch (action.type) {
        case PLACES_AROUND_REQUEST:
            draft.loading = true;
            draft.lat = action.payload.lat;
            draft.lng = action.payload.lng;
            break;
        case PLACES_AROUND_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case PLACES_AROUND_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.currentPage = action.payload.currentPage;
            draft.pages = action.payload.pages;
            draft.total = action.payload.total;
            draft.items = action.payload.items;
            break;
        case PLACES_AROUND_SET_LAT:
            draft.lat = action.payload;
            break;
        case PLACES_AROUND_SET_LNG:
            draft.lng = action.payload;
            break;
        case PLACES_AROUND_BOOKMARK: {
            if ((_a = draft.items) === null || _a === void 0 ? void 0 : _a.length) {
                var index = findIndex(draft.items, function (i) { return i.googlePlaceId === action.payload.googlePlaceId; });
                draft.items = set(draft.items, "[" + index + "].isFavorite", action.payload.isFavorite);
            }
            break;
        }
        default:
    }
}, placesAroundInitialState);
