var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.space.tint900,
});
export var Container = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background: ", ";\n  border-bottom: 1px solid ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: ", ";\n  justify-content: center;\n  line-height: 1.2;\n  position: sticky;\n  top: 0;\n  width: 100%;\n"], ["\n  align-items: center;\n  background: ", ";\n  border-bottom: 1px solid ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: ", ";\n  justify-content: center;\n  line-height: 1.2;\n  position: sticky;\n  top: 0;\n  width: 100%;\n"])), background, rgba(colors.base.black, 0.125), color, fonts.weight.semibold, rem(60));
export var BackButton = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  left: ", ";\n  opacity: 0.8;\n  padding: 0;\n  position: absolute;\n  transition: opacity 0.2s;\n\n  &:hover {\n    opacity: 1;\n  }\n"], ["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  left: ", ";\n  opacity: 0.8;\n  padding: 0;\n  position: absolute;\n  transition: opacity 0.2s;\n\n  &:hover {\n    opacity: 1;\n  }\n"])), color, rem(12));
var templateObject_1, templateObject_2;
