import { makeSelectUseKeyboard } from 'containers/App/selectors';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'utils/hooks';
import { AlertNewSiteContainer } from './styles';
export var AlertNewSite = function (_a) {
    var onClick = _a.onClick, hasAlert = _a.hasAlert;
    var formatMessage = useIntl().formatMessage;
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(AlertNewSiteContainer, { role: "button", isKeyboardMode: keyboard, onClick: onClick, hasAlert: hasAlert },
        formatMessage({ id: 'components.alertNewSite.message' }),
        React.createElement("a", { href: "mailto:beta@jaccede.com" }, formatMessage({ id: 'components.alertNewSite.email' })),
        "!"));
};
