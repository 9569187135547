var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { SubscribeButton as SubscribeButtonComponent } from 'components/SubscribeButton';
import { rem } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { colors } from 'styles';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
var bgColor = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
export var AchievementsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), media.greaterThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    border-radius: ", ";\n    border: ", " solid ", ";\n  "], ["\n    background-color: ", ";\n    border-radius: ", ";\n    border: ", " solid ", ";\n  "])), bgColor, rem(10), rem(1), borderColor));
export var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), media.greaterThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: ", ";\n  "], ["\n    padding: ", ";\n  "])), rem(20)));
export var SubscribeButton = styled(SubscribeButtonComponent)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: ", ";\n"], ["\n  margin-right: ", ";\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
