var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
export var getSearchWithout = function (without, entries) {
    var entriesFiltered = entries.filter(function (value) { return !without.includes(value[0]); });
    var search = '';
    entriesFiltered.forEach(function (value, index) {
        search =
            index === 0 ? value.join('=') : search + "&" + value[0] + "=" + value[1];
    });
    return search;
};
export var updateQueryParams = function (newEntries, oldEntries, withoutEntries) {
    var without = newEntries.map(function (values) { return values[0]; });
    if (withoutEntries) {
        without = __spread(without, withoutEntries);
    }
    var search = getSearchWithout(without, oldEntries);
    var newParams = newEntries.map(function (values) { return values.join('='); }).join('&');
    return search.length > 0 ? search + "&" + newParams : newParams;
};
export var changedQueryParams = function (oldQueryParam, newQueryParams) {
    var oldParams = oldQueryParam.startsWith('?')
        ? oldQueryParam.substring(1).split('&')
        : oldQueryParam.split('&');
    var newParams = newQueryParams.startsWith('?')
        ? newQueryParams.substring(1).split('&')
        : newQueryParams.split('&');
    var result = [];
    newParams.forEach(function (newParam) {
        var _a = __read(newParam.split('='), 2), newParamName = _a[0], newParamValue = _a[1];
        var paramFounded = false;
        oldParams.forEach(function (oldParam) {
            var _a = __read(oldParam.split('='), 2), oldParamName = _a[0], oldParamValue = _a[1];
            if (newParamName === oldParamName) {
                paramFounded = true;
                if (newParamValue !== oldParamValue)
                    result.push(newParamName);
            }
        });
        if (!paramFounded)
            result.push(newParamName);
    });
    return result;
};
export function queryParamsStringToArray(queryParams) {
    var queryParamsArray = queryParams.startsWith('?')
        ? queryParams.substring(1).split('&')
        : queryParams.split('&');
    var result = [];
    if (queryParams.length === 0 ||
        (queryParams.startsWith('?') && queryParams.length === 1))
        return result;
    queryParamsArray.forEach(function (q) {
        var _a = __read(q.split('='), 2), key = _a[0], value = _a[1];
        result.push([key, value]);
    });
    return result;
}
