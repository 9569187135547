var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CamSolid } from 'icons/mono';
import { darken, rem, shade, tint } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { breakpoints, colors, fonts } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: ", ";\n"])), rem(20));
export var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-top: ", ";\n  padding-bottom: ", ";\n\n  ", "\n"], ["\n  padding-top: ", ";\n  padding-bottom: ", ";\n\n  ",
    "\n"])), rem(80), rem(16), media.lessThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding-bottom: ", ";\n    padding-top: ", ";\n  "], ["\n    padding-bottom: ", ";\n    padding-top: ", ";\n  "])), rem(32), rem(60)));
export var ContainerButtons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(16));
export var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  max-width: ", ";\n"], ["\n  max-width: ", ";\n"])), breakpoints.medium);
export var PhotoList = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n\n  img {\n    width: ", ";\n    height: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n\n  img {\n    width: ", ";\n    height: ", ";\n  }\n"])), rem(150), rem(150));
export var PhotoCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: ", ";\n  transition: all 0.2s;\n  position: relative;\n"], ["\n  margin: ", ";\n  transition: all 0.2s;\n  position: relative;\n"])), rem(8));
export var PhotoActions = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  bottom: ", ";\n  right: ", ";\n\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    border: ", " solid ", ";\n  }\n"], ["\n  position: absolute;\n  bottom: ", ";\n  right: ", ";\n\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    border: ", " solid ", ";\n  }\n"])), rem(10), rem(10), rem(1), colors.space.tint300);
export var InputUpload = styled.input.attrs({
    type: 'file',
    name: 'file',
    id: 'file',
})(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1;\n"], ["\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1;\n"])));
export var CamSolidStyled = styled(CamSolid)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-right: ", ";\n"], ["\n  margin-right: ", ";\n"])), rem(8));
export var AddButton = styled.label.attrs({
    htmlFor: 'file',
})(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: inline-flex;\n  padding: ", " ", ";\n  justify-content: center;\n  align-items: center;\n  border-radius: ", ";\n  cursor: pointer;\n  font-weight: ", ";\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  display: inline-flex;\n  padding: ", " ", ";\n  justify-content: center;\n  align-items: center;\n  border-radius: ", ";\n  cursor: pointer;\n  font-weight: ", ";\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), rem(6), rem(12), rem(24), fonts.weight.semibold, colors.base.white, colors.base.primary, shade(0.2, colors.base.primary), tint(0.2, colors.base.primary));
export var AddButtonRedirect = styled.button.attrs({
    name: 'file',
    id: 'file',
})(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
export var CustomButton = styled.button(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 50%;\n  cursor: pointer;\n  position: relative;\n  transition: all 0.2s;\n\n  margin-left: ", ";\n  outline: none;\n  overflow: hidden;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: ", ";\n  height: ", ";\n  z-index: 1;\n\n  &:hover:not(:disabled) {\n    color: ", ";\n  }\n\n  &:active:not(:disabled) {\n    color: ", ";\n  }\n\n  &:after {\n    background: ", ";\n    border-radius: 50%;\n    content: '';\n    position: absolute;\n    top: ", ";\n    left: ", ";\n    width: ", ";\n    height: ", ";\n  }\n\n  > svg {\n    flex-shrink: 0;\n    z-index: 1;\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 50%;\n  cursor: pointer;\n  position: relative;\n  transition: all 0.2s;\n\n  margin-left: ", ";\n  outline: none;\n  overflow: hidden;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: ", ";\n  height: ", ";\n  z-index: 1;\n\n  &:hover:not(:disabled) {\n    color: ", ";\n  }\n\n  &:active:not(:disabled) {\n    color: ", ";\n  }\n\n  &:after {\n    background: ", ";\n    border-radius: 50%;\n    content: '';\n    position: absolute;\n    top: ", ";\n    left: ", ";\n    width: ", ";\n    height: ", ";\n  }\n\n  > svg {\n    flex-shrink: 0;\n    z-index: 1;\n  }\n"])), colors.space.tint100, rem(10), rem(25), rem(25), tint(0.2, colors.space.tint200), darken(0.05, colors.space.tint200), function (p) { return (p.isDelete ? 'transparent' : colors.base.black); }, rem(4), rem(4), rem(16), rem(16));
export var ModalMessage = styled.p(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  font-size: ", ";\n  white-space: pre-line;\n"], ["\n  font-size: ", ";\n  white-space: pre-line;\n"])), rem(16));
export var ModalButtonWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(26));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
