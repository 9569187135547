import { createSelector } from 'reselect';
import { initialState } from './reducer';
/**
 * Direct selector to the placePhotos state domain
 */
var selectPlacePhotosStateDomain = function (state) {
    return state.placePhotos || initialState;
};
var makeSelectPlacePhotos = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
var makeSelectPostPlacePhotosHasError = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
var makeSelectPostPlacePhotosHasSuccess = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var hasSuccess = _a.hasSuccess;
        return hasSuccess;
    });
};
var makeSelectPostPlacePhotosCreated = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var itemCreated = _a.itemCreated;
        return itemCreated;
    });
};
var makeSelectorReported = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var reported = _a.reported;
        return reported;
    });
};
var makeSelectorAlreadyReported = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var alreadyReported = _a.alreadyReported;
        return alreadyReported;
    });
};
var makeSelectorReportLoading = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var reportLoading = _a.reportLoading;
        return reportLoading;
    });
};
var makeSelectorDeletePhotoLoading = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var deleteLoading = _a.deleteLoading;
        return deleteLoading;
    });
};
var makeSelectorDeletePhotoFailure = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
var makeSelectorDeletePhotoSuccess = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var deletePhotoSuccess = _a.deletePhotoSuccess;
        return deletePhotoSuccess;
    });
};
var makeSelectorLoadingUpload = function () {
    return createSelector(selectPlacePhotosStateDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export { makeSelectPlacePhotos, makeSelectorReported, makeSelectorReportLoading, makeSelectorAlreadyReported, makeSelectorDeletePhotoLoading, makeSelectorDeletePhotoFailure, makeSelectorDeletePhotoSuccess, makeSelectPostPlacePhotosHasError, makeSelectPostPlacePhotosHasSuccess, makeSelectPostPlacePhotosCreated, makeSelectorLoadingUpload, };
