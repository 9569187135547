var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Table } from 'antd';
import styled from 'styled-components';
export var TableWrapper = styled(Table)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ColumnWrapper = styled(Table.Column)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var ColumnGroupWrapper = styled(Table.ColumnGroup)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3;
