import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectAppDomain = function (state) { return state.app || initialState; };
var makeSelectDarkMode = function () {
    return createSelector(selectAppDomain, function (_a) {
        var darkMode = _a.darkMode;
        return darkMode;
    });
};
var makeSelectDirection = function () {
    return createSelector(selectAppDomain, function (_a) {
        var isLtr = _a.isLtr;
        return isLtr;
    });
};
var makeSelectFont = function () {
    return createSelector(selectAppDomain, function (_a) {
        var font = _a.font;
        return font;
    });
};
var makeSelectUseKeyboard = function () {
    return createSelector(selectAppDomain, function (_a) {
        var useKeyboard = _a.useKeyboard;
        return useKeyboard;
    });
};
var makeSelectApp = function () {
    return createSelector(selectAppDomain, function (subState) { return subState; });
};
var makeSelectFieldOnFocus = function () {
    return createSelector(selectAppDomain, function (_a) {
        var isFieldOnFocus = _a.isFieldOnFocus;
        return isFieldOnFocus;
    });
};
export default makeSelectApp;
export { selectAppDomain, makeSelectDarkMode, makeSelectDirection, makeSelectFont, makeSelectUseKeyboard, makeSelectFieldOnFocus, };
