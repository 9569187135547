var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import './css/custom-antd.css';
import './css/overrides-antd.css';
import 'swiper/css/swiper.css';
import { normalize, rem, rgba } from 'polished';
import { createGlobalStyle, css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from '.';
import { fontFace } from './font-face';
var colorMode = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    color: ", ";\n  "], ["\n    background: ", ";\n    color: ", ";\n  "])), colors.base.white, colors.space.tint900),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: ", ";\n    color: ", ";\n  "], ["\n    background: ", ";\n    color: ", ";\n  "])), colors.carbon.tint800, colors.base.white),
});
var outlineColor = theme('mode', {
    light: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    outline: ", " solid ", ";\n  "], ["\n    outline: ", " solid ", ";\n  "])), rem(3), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    outline: ", " solid ", ";\n  "], ["\n    outline: ", " solid ", ";\n  "])), rem(3), rgba(colors.base.white, 0.2)),
});
var fontSize = theme('text', {
    eight: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 8px;\n  "], ["\n    font-size: 8px;\n  "]))),
    sixteen: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 16px;\n  "], ["\n    font-size: 16px;\n  "]))),
    twentyFour: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 24px;\n  "], ["\n    font-size: 24px;\n  "]))),
});
var heartColor = theme('mode', {
    light: colors.space.tint400,
    dark: colors.carbon.tint100,
});
var boxShadow = theme('mode', {
    light: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var GlobalStyle = createGlobalStyle(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", "\n  ", "\n\n  * {\n    box-sizing: border-box;\n    margin: 0;\n    outline: 0;\n    padding: 0;\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-font-smoothing: antialiased;\n  }\n\n  html {\n    ", ";\n    ", ";\n\n    height: 100%;\n  }\n\n  body {\n    font-family: ", ";\n    height: 100%;\n    overflow-x: hidden;\n\n    ", "\n\n  }\n\n  #root {\n    height: 100%;\n    max-width: 100vw;\n    min-width: 320px;\n  }\n\n  input, button, .ant-input, .ant-select {\n    appearance: none;\n    font-family: ", ";\n    font-size: inherit;\n    line-height: 1.5;\n  }\n\n  .ant-select-selection-item,\n  .ant-select-selection-placeholder {\n    height: ", " !important;\n    line-height: ", " !important;\n    opacity: 1;\n    padding: 0 ", " !important;\n  }\n\n  .ant-select-selection-search-item,\n  .ant-select-selection-search-input {\n    height: ", " !important;\n    padding: 0 ", " !important;\n  }\n\n  .ant-select-single {\n    &.ant-select-show-arrow {\n      .ant-select-selection-item,\n      .ant-select-selection-placeholder {\n        padding-left: ", " !important;\n      }\n    }\n\n    .ant-select-selector .ant-select-selection-item,\n    .ant-select-selector .ant-select-selection-placeholder {\n      line-height: ", " !important;\n    }\n  }\n\n  .ant-rate-star-first,\n  .ant-rate-star-second {\n    color: ", ";\n  }\n\n  a.ant-typography,\n  .ant-typography a {\n    color: ", " !important;\n  }\n"], ["\n  ", "\n  ", "\n\n  * {\n    box-sizing: border-box;\n    margin: 0;\n    outline: 0;\n    padding: 0;\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-font-smoothing: antialiased;\n  }\n\n  html {\n    ", ";\n    ", ";\n\n    height: 100%;\n  }\n\n  body {\n    font-family: ", ";\n    height: 100%;\n    overflow-x: hidden;\n\n    ",
    "\n\n  }\n\n  #root {\n    height: 100%;\n    max-width: 100vw;\n    min-width: 320px;\n  }\n\n  input, button, .ant-input, .ant-select {\n    appearance: none;\n    font-family: ", ";\n    font-size: inherit;\n    line-height: 1.5;\n  }\n\n  .ant-select-selection-item,\n  .ant-select-selection-placeholder {\n    height: ", " !important;\n    line-height: ", " !important;\n    opacity: 1;\n    padding: 0 ", " !important;\n  }\n\n  .ant-select-selection-search-item,\n  .ant-select-selection-search-input {\n    height: ", " !important;\n    padding: 0 ", " !important;\n  }\n\n  .ant-select-single {\n    &.ant-select-show-arrow {\n      .ant-select-selection-item,\n      .ant-select-selection-placeholder {\n        padding-left: ", " !important;\n      }\n    }\n\n    .ant-select-selector .ant-select-selection-item,\n    .ant-select-selector .ant-select-selection-placeholder {\n      line-height: ", " !important;\n    }\n  }\n\n  .ant-rate-star-first,\n  .ant-rate-star-second {\n    color: ", ";\n  }\n\n  a.ant-typography,\n  .ant-typography a {\n    color: ", " !important;\n  }\n"])), normalize(), fontFace, colorMode, fontSize, fonts.family.primary, media.greaterThan('medium')(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      &.keyboard {\n        padding-top: ", ";\n\n        a:focus {\n          ", ";\n          outline-offset: ", ";\n        }\n\n        .ant-checkbox-input {\n          &:focus + span {\n            ", ";\n          }\n        }\n      }\n    "], ["\n      &.keyboard {\n        padding-top: ", ";\n\n        a:focus {\n          ", ";\n          outline-offset: ", ";\n        }\n\n        .ant-checkbox-input {\n          &:focus + span {\n            ", ";\n          }\n        }\n      }\n    "])), rem(50), outlineColor, rem(2), boxShadow), fonts.family.primary, rem(41.567), rem(41.567), rem(8), rem(41.567), rem(8), rem(8), rem(41.567), heartColor, colors.base.secondary);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
