var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { colaborateWithUs, helpAchieveGoals } from 'assets/images';
import { paths } from 'containers/RoutesProvider/routes';
import { ChevronLeft, ChevronRight } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import Swiper from 'react-id-swiper';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from 'styles';
import { Card } from '../../Card';
import { NextButton, PrevButton, Slider } from './styles';
export var CardListMobile = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var params = {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        renderPrevButton: function () { return (React.createElement(PrevButton, { size: "icon", variant: "ghost", icon: React.createElement(ChevronLeft, { size: rem(24), color: colors.space.tint800 }), className: "swiper-button-prev" })); },
        renderNextButton: function () { return (React.createElement(NextButton, { size: "icon", variant: "ghost", icon: React.createElement(ChevronRight, { size: rem(24), color: colors.space.tint800 }), className: "swiper-button-next" })); },
    };
    return (React.createElement(Swiper, __assign({}, params),
        React.createElement(Slider, null,
            React.createElement(Card, { image: colaborateWithUs, alternativeText: formatMessage({
                    id: 'containers.contribute.collaborateAlt',
                }), imageText: formatMessage({
                    id: 'containers.contribute.collaborateImage',
                }), title: formatMessage({
                    id: 'containers.contribute.collaborateTitle',
                }), buttonText: formatMessage({
                    id: 'containers.contribute.collaborateButtonText',
                }), buttonAction: function () {
                    return history.push(paths.adhereALassociation, {
                        from: location.pathname,
                    });
                } }, formatMessage({
                id: 'containers.contribute.collaborateParagraph',
            }))),
        React.createElement(Slider, null,
            React.createElement(Card, { image: helpAchieveGoals, alternativeText: formatMessage({
                    id: 'containers.contribute.donateAlt',
                }), imageText: formatMessage({
                    id: 'containers.contribute.donateImage',
                }), title: formatMessage({
                    id: 'containers.contribute.donateTitle',
                }), buttonText: formatMessage({
                    id: 'containers.contribute.donateButtonText',
                }), buttonAction: function () {
                    return history.push(paths.faireUnDon, { from: location.pathname });
                } }, formatMessage({
                id: 'containers.contribute.donateParagraph',
            })))));
});
