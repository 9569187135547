var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal10000Mobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0.3289 0.09784375 12.4583333 0.09784375 12.4583333 10.7637813 0.3289 10.7637813" }),
            React.createElement("polygon", { id: "path-3", points: "0.333558333 0.4010625 21.6665583 0.4010625 21.6665583 26.1553781 0.333558333 26.1553781" }),
            React.createElement("polygon", { id: "path-5", points: "0 0.503803819 9.33335 0.503803819 9.33335 9.77797049 0 9.77797049" }),
            React.createElement("polygon", { id: "path-7", points: "0 26.298441 26 26.298441 26 0.538302083 0 0.538302083" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-4", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/4", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("path", { d: "M4.55,3.875 L26,3.875 L26,17.7083333 C26,24.3357503 20.627417,29.7083333 14,29.7083333 L4.55,29.7083333 L4.55,29.7083333 L4.55,3.875 Z", id: "Rectangle", fillOpacity: "0.15", fill: "#000000" }),
                    React.createElement("g", { id: "Group-28", strokeWidth: "1", transform: "translate(0.000000, 2.583333)" },
                        React.createElement("g", { id: "Group-3", transform: "translate(13.541667, 15.319274)" },
                            React.createElement("mask", { id: "mask-2", fill: "white" },
                                React.createElement("use", { xlinkHref: "#path-1" })),
                            React.createElement("g", { id: "Clip-2" }),
                            React.createElement("path", { d: "M6.08085833,4.646125 C6.94860833,10.3547535 1.46044167,10.7427917 0.3289,10.7627049 L3.32485833,10.7627049 L3.32485833,10.7637813 C8.37319167,10.7637813 12.4584417,6.70525694 12.4584417,1.68928472 L12.4584417,1.68820833 L12.4584417,0.09784375 C12.4584417,5.11381597 6.08085833,4.646125 6.08085833,4.646125", id: "Fill-1", fill: "#8EC26E", mask: "url(#mask-2)" })),
                        React.createElement("g", { id: "Group-6", transform: "translate(4.333333, 0.142191)" },
                            React.createElement("mask", { id: "mask-4", fill: "white" },
                                React.createElement("use", { xlinkHref: "#path-3" })),
                            React.createElement("g", { id: "Clip-5" }),
                            React.createElement("path", { d: "M0.333558333,0.400735547 L0.333558333,26.1553781 L9.33280833,26.1553781 L9.53701667,26.1553781 C10.6691,26.135216 16.156725,25.7423273 15.288975,19.9617961 C15.288975,19.9617961 21.6665583,20.4358781 21.6665583,15.3566613 L21.6665583,0.400735547 L0.333558333,0.400735547 Z", id: "Fill-4", fill: "#BCE2A5", mask: "url(#mask-4)" })),
                        React.createElement("polygon", { id: "Fill-7", fill: "#773BB3", points: "7.3334625 11.1247483 7.3334625 17.6697309 4.6668375 16.3799956 4.6668375 11.1247483" }),
                        React.createElement("polygon", { id: "Fill-9", fill: "#8F6BB3", points: "2.00010417 11.1247483 2.00010417 17.6697309 4.66672917 16.3799956 4.66672917 11.1247483" }),
                        React.createElement("path", { d: "M8.9336,8.51208333 C8.9336,10.8537674 7.02314167,12.7519792 4.66689167,12.7519792 C2.3101,12.7519792 0.400183333,10.8537674 0.400183333,8.51208333 C0.400183333,6.1709375 2.3101,4.27272569 4.66689167,4.27272569 C7.02314167,4.27272569 8.9336,6.1709375 8.9336,8.51208333", id: "Fill-11", fill: "#F8E831" }),
                        React.createElement("g", { id: "Group-15", transform: "translate(0.000000, 3.371358)" },
                            React.createElement("mask", { id: "mask-6", fill: "white" },
                                React.createElement("use", { xlinkHref: "#path-5" })),
                            React.createElement("g", { id: "Clip-14" }),
                            React.createElement("path", { d: "M4.66689167,1.29871701 C2.53489167,1.29871701 0.799933333,3.02201563 0.799933333,5.14088715 C0.799933333,7.25922049 2.53489167,8.98305729 4.66689167,8.98305729 C6.79889167,8.98305729 8.53330833,7.25922049 8.53330833,5.14088715 C8.53330833,3.02201563 6.79889167,1.29871701 4.66689167,1.29871701 M4.66689167,9.77797049 C2.093975,9.77797049 -0.000108333333,7.69784896 -0.000108333333,5.14088715 C-0.000108333333,2.58392535 2.093975,0.503803819 4.66689167,0.503803819 C7.23980833,0.503803819 9.33335,2.58392535 9.33335,5.14088715 C9.33335,7.69784896 7.23980833,9.77797049 4.66689167,9.77797049", id: "Fill-13", fill: "#F7D131", mask: "url(#mask-6)" })),
                        React.createElement("path", { d: "M4.66705417,10.3667014 C3.63572083,10.3667014 2.79992917,9.53626736 2.79992917,8.51208333 C2.79992917,7.48789931 3.63572083,6.65746528 4.66705417,6.65746528 C5.69784583,6.65746528 6.5336375,7.48789931 6.5336375,8.51208333 C6.5336375,9.53626736 5.69784583,10.3667014 4.66705417,10.3667014 Z", id: "Fill-16", fill: "#F7D131" }),
                        React.createElement("mask", { id: "mask-8", fill: "white" },
                            React.createElement("use", { xlinkHref: "#path-7" })),
                        React.createElement("g", { id: "Clip-19" }),
                        React.createElement("polygon", { id: "Fill-18", fill: "#8BB074", mask: "url(#mask-8)", points: "12.666875 4.64526389 18.000125 4.64526389 18.000125 3.8498125 12.666875 3.8498125" }),
                        React.createElement("polygon", { id: "Fill-20", fill: "#8BB074", mask: "url(#mask-8)", points: "16.8669583 7.82491667 22.267375 7.82491667 22.267375 7.03000347 16.8669583 7.03000347" }),
                        React.createElement("polygon", { id: "Fill-21", fill: "#8BB074", mask: "url(#mask-8)", points: "11.0667917 7.82491667 15.266875 7.82491667 15.266875 7.03000347 11.0667917 7.03000347" }),
                        React.createElement("polygon", { id: "Fill-22", fill: "#8BB074", mask: "url(#mask-8)", points: "19.1332917 11.0051076 22.267375 11.0051076 22.267375 10.2096563 19.1332917 10.2096563" }),
                        React.createElement("polygon", { id: "Fill-23", fill: "#8BB074", mask: "url(#mask-8)", points: "11.0673333 11.0051076 17.53375 11.0051076 17.53375 10.2096563 11.0673333 10.2096563" }),
                        React.createElement("polygon", { id: "Fill-24", fill: "#8BB074", mask: "url(#mask-8)", points: "15.0447917 14.2923993 22.267375 14.2923993 22.267375 13.3898472 15.0447917 13.3898472" }),
                        React.createElement("polygon", { id: "Fill-25", fill: "#8BB074", mask: "url(#mask-8)", points: "9.99970833 14.2923993 13.4441667 14.2923993 13.4441667 13.3898472 9.99970833 13.3898472" }),
                        React.createElement("polygon", { id: "Fill-26", fill: "#8BB074", mask: "url(#mask-8)", points: "9.99970833 17.4720521 18.000125 17.4720521 18.000125 16.6771389 9.99970833 16.6771389" }),
                        React.createElement("polygon", { id: "Fill-27", fill: "#8BB074", mask: "url(#mask-8)", points: "7.866625 20.6517049 14.2664167 20.6517049 14.2664167 19.8567917 7.866625 19.8567917" }))),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
