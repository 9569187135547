import produce from 'immer';
import { PLACE_REVIEWS_FAILURE, PLACE_REVIEWS_REQUEST, PLACE_REVIEWS_SUCCESS, } from './constants';
export var placeReviewsInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    pages: 1,
    currentPage: 1,
    total: 0,
    items: [],
};
var backToInitialState = function (draft) {
    draft.currentPage = placeReviewsInitialState.currentPage;
    draft.pages = placeReviewsInitialState.pages;
    draft.total = placeReviewsInitialState.total;
    draft.items = placeReviewsInitialState.items;
};
var placeReviewsReducer = produce(function (draft, action) {
    switch (action.type) {
        case PLACE_REVIEWS_REQUEST:
            draft.loading = true;
            backToInitialState(draft);
            break;
        case PLACE_REVIEWS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            backToInitialState(draft);
            break;
        case PLACE_REVIEWS_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.currentPage = action.payload.currentPage;
            draft.pages = action.payload.pages;
            draft.total = action.payload.total;
            draft.items = action.payload.items;
            break;
        default:
    }
}, placeReviewsInitialState);
export default placeReviewsReducer;
