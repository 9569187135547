var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var AddPlace = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.3649 16.5893H21.6274V20.3451H19.2298V16.5893H15.4921V14.21H19.2298V10.4724H21.6274V14.21H25.3649V16.5893ZM20.4286 4C14.1193 4 9 8.60786 9 14.2857C9 23.4286 20.4286 36 20.4286 36C20.4286 36 31.8571 23.4286 31.8571 14.2857C31.8571 8.60786 26.7379 4 20.4286 4Z", fill: color })));
});
