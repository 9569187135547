var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { notification } from 'antd';
import * as api from 'api';
import { PageHeader } from 'components/PageHeader';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useGeolocation, useSelector } from 'hooks';
import { ArrowBack } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { colors } from 'styles';
import { clearFeedback, getCategories, getPlaceCriteria, getPlaceDetails, setActiveSection, updatePlace, } from '../actions';
import { sections } from '../constants';
import makeSelectorPlaceDetails, { makeSelectorActiveSection, makeSelectorCategories, makeSelectorCriteria, makeSelectorFeedback, makeSelectorLoading, } from '../selectors';
import { EditCategory } from './EditCategory';
import { EditCriteria } from './EditCriteria';
import { EditOptions } from './EditOptions';
export var EditPlace = function () {
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var placeId = useParams().placeId;
    var placeName = useParams().placeName;
    var geolocation = useGeolocation();
    var placeDetails = useSelector(makeSelectorPlaceDetails());
    var placeCriteria = useSelector(makeSelectorCriteria());
    var activeSection = useSelector(makeSelectorActiveSection());
    var categories = useSelector(makeSelectorCategories());
    var loading = useSelector(makeSelectorLoading());
    var feedback = useSelector(makeSelectorFeedback());
    var getPlaceDetailsCallback = useAction(getPlaceDetails);
    var getCriteriaCallback = useAction(getPlaceCriteria);
    var setActiveSectionActionCallback = useAction(setActiveSection);
    var getCategoriesCallback = useAction(getCategories);
    var updatePlaceCallback = useAction(updatePlace);
    var clearFeedbackCallback = useAction(clearFeedback);
    var _a = __read(useState(), 2), criteriaMenu = _a[0], setCriteriaMenu = _a[1];
    var _b = __read(useState(0), 2), activeCriteria = _b[0], setActiveCriteria = _b[1];
    useEffect(function () {
        if (!(placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.googlePlaceId) !== placeId) {
            getPlaceDetailsCallback(placeId);
        }
        getCriteriaCallback(placeId);
        if (!(categories === null || categories === void 0 ? void 0 : categories.length)) {
            getCategoriesCallback();
        }
    }, []);
    var createMenu = function (criteria) {
        return map(criteria, function (criterion, index) { return ({
            id: index,
            title: criterion.label,
        }); });
    };
    useEffect(function () {
        if (placeCriteria && placeCriteria.length > 0) {
            setCriteriaMenu(createMenu(placeCriteria));
        }
    }, [placeCriteria]);
    var backToPlaceDetails = function () {
        history.push(paths.placeDetailsPage
            .replace(':placeId', placeId)
            .replace(':placeName', placeName));
    };
    // @ts-ignore
    var getCriterion = function () { return placeCriteria[activeCriteria]; };
    var handleUpdatePlace = function (form, object) { return __awaiter(void 0, void 0, void 0, function () {
        var contributorPosition, latitude, longitude, responseGeolocalize;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    contributorPosition = {};
                    latitude = geolocation.latitude, longitude = geolocation.longitude;
                    if (!(latitude === 0 || longitude === 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, api.services.geolocalize()];
                case 1:
                    responseGeolocalize = _a.sent();
                    if (responseGeolocalize.status === 200) {
                        contributorPosition = {
                            latitude: responseGeolocalize.data.latitude,
                            longitude: responseGeolocalize.data.longitude,
                        };
                    }
                    return [3 /*break*/, 3];
                case 2:
                    contributorPosition = { latitude: latitude, longitude: longitude };
                    _a.label = 3;
                case 3:
                    updatePlaceCallback({
                        placeId: placeId,
                        form: __assign(__assign({}, form), { contributorPosition: contributorPosition }),
                        object: object,
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var formatFeedback = function (message) {
        if (!message)
            return formatMessage({ id: 'containers.editPlace.successfulFeedback' });
        return (React.createElement("div", null,
            React.createElement("div", null, formatMessage({ id: 'containers.editPlace.successfulFeedback' })),
            React.createElement("div", null,
                React.createElement("p", null, formatMessage({ id: 'containers.editPlace.pointsFeedback' }, { points: message.points })))));
    };
    useEffect(function () {
        if (feedback) {
            notification.success({
                message: formatMessage({ id: 'containers.editPlace.success' }),
                description: formatFeedback(feedback.object),
                onClose: clearFeedbackCallback,
            });
        }
    }, [feedback]);
    return (React.createElement(React.Fragment, null,
        activeSection === sections.options && (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: formatMessage({ id: 'containers.editPlace.mainPageTitle' }), backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: backToPlaceDetails, sticky: true }),
            React.createElement(EditOptions, { criteriaMenu: criteriaMenu || [], onSelectOption: setActiveSectionActionCallback, setActiveCriteria: setActiveCriteria }))),
        activeSection === sections.category && (React.createElement(EditCategory, { category: placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.category, categories: categories, onBack: function () { return setActiveSectionActionCallback(sections.options); }, onSubmit: handleUpdatePlace, loading: loading })),
        activeSection === sections.criteria && (React.createElement(EditCriteria, { criterion: getCriterion(), allCriteria: placeCriteria || [], onUpdate: handleUpdatePlace, onBack: function () { return setActiveSectionActionCallback(sections.options); } }))));
};
