import { useSelector } from 'hooks';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import makeSelectOAuthLoginState from './selectors';
export var OAuthError = function () {
    var formatMessage = useIntl().formatMessage;
    var oAuthState = useSelector(makeSelectOAuthLoginState());
    return (React.createElement("div", null,
        React.createElement("span", null, formatMessage(messages.errorPageTitle)),
        oAuthState.hasError && React.createElement("p", null, oAuthState.error)));
};
export default memo(OAuthError);
