var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { InputPassword } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import { useField } from 'formik';
import { Eye, EyeOff } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Container, ErrorMessage, LabelWrapper } from './styles';
export var PasswordField = memo(function (props) {
    var formatMessage = useIntl().formatMessage;
    var location = useLocation();
    var _a = __read(useField(props), 2), field = _a[0], meta = _a[1];
    var required = props.required || false;
    var showForgotLink = props.showForgotLink || false;
    var showMessageError = props.showMessageError !== false;
    return (React.createElement(Container, { validateStatus: !!meta.error && meta.touched ? 'error' : '' },
        React.createElement(LabelWrapper, { hasError: !!meta.error && meta.touched },
            React.createElement("label", null,
                props.label ||
                    formatMessage({ id: 'components.passwordField.label' }),
                required && ' *'),
            showForgotLink && (React.createElement(Link, { to: {
                    pathname: paths.forgotPasswordPage,
                    state: { from: location.pathname },
                } }, formatMessage({ id: 'components.passwordField.forgotPassword' })))),
        React.createElement(InputPassword, { placeholder: props.placeholder ||
                formatMessage({
                    id: 'components.passwordField.placeholder',
                }), name: field.name, value: field.value, onChange: field.onChange, onBlur: props.onBlur || field.onBlur, disabled: props.disabled, autoComplete: "off", autoCapitalize: "off", onFocus: props.onFocus, iconRender: function (visible) {
                return visible ? (React.createElement(Eye, { size: rem(18), title: formatMessage({
                        id: 'components.passwordField.hidePassword',
                    }) })) : (React.createElement(EyeOff, { size: rem(18), title: formatMessage({
                        id: 'components.passwordField.showPassword',
                    }) }));
            } }),
        showMessageError && !!meta.error && meta.touched ? (React.createElement(ErrorMessage, null, meta.error)) : null));
});
