import { emoticonFlyingLeaves } from 'assets/images';
import { EmptyListText, LoadingIndicator } from 'components';
import map from 'lodash/map';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { CardTeam } from '../CardTeam';
import { EmoticonImage, TeamsListContainer } from './styles';
export var TeamsList = function (_a) {
    var _b = _a.teams, teams = _b === void 0 ? [] : _b, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c;
    var formatMessage = useIntl().formatMessage;
    var params = useParams();
    if (isLoading) {
        return React.createElement(LoadingIndicator, null);
    }
    return (React.createElement(React.Fragment, null, teams.length > 0 ? (React.createElement(TeamsListContainer, null, map(teams, function (item) { return (React.createElement(CardTeam, { challengeSlug: params.challengeSlug, key: item.slug, item: item })); }))) : (React.createElement(EmptyListText, null,
        React.createElement("span", null, formatMessage({
            id: 'containers.challengesDetail.emptyTeams',
        })),
        React.createElement(EmoticonImage, { src: emoticonFlyingLeaves, alt: "emoticon" })))));
};
