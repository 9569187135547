import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    myProfile: versionV4 + "/users/me",
    updateMyProfile: versionV4 + "/account",
    updateMyProfilePassword: versionV4 + "/account/password",
    photoUpload: versionV4 + "/account/medias",
    reverseGpid: versionV4 + "/reversegeocoding",
};
export default {
    getEditMyProfile: function () { return axios.get(endpoints.myProfile); },
    updateMyProfileFields: function (fields) {
        return axios.put(endpoints.updateMyProfile, fields);
    },
    updateMyProfilePassword: function (passwordForm) {
        return axios.post(endpoints.updateMyProfilePassword, passwordForm);
    },
    photoUpload: function (file) {
        var formData = new FormData();
        formData.append('file', file);
        var config = { headers: { 'content-type': 'multipart/form-data' } };
        return axios.post(endpoints.photoUpload, formData, config);
    },
    photoDelete: function (id) { return axios.delete(endpoints.photoUpload + "/" + id); },
    getReverseGpid: function (gpid) {
        var config = {
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                google_place_id: gpid,
            },
        };
        return axios.get(endpoints.reverseGpid, config);
    },
};
