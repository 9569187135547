import { Button, TextAreaField } from 'components';
import { fieldBlur, fieldOnFocus } from 'containers/App/actions';
import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAction } from 'utils/hooks';
import { Template } from '../Template';
import { SuccessFeedbackType } from '../types';
import messages from './messages';
import { ButtonWrapper } from './styles';
import EditNameFormSchema from './validator';
export var EditProfileMessage = function (_a) {
    var onSubmit = _a.onSubmit, onBack = _a.onBack, profile = _a.profile;
    var formatMessage = useIntl().formatMessage;
    var schema = EditNameFormSchema(formatMessage);
    var fieldOnFocusActionCallback = useAction(fieldOnFocus);
    var fieldBlurActionCallback = useAction(fieldBlur);
    return (React.createElement(Template, { onBack: onBack, pageTitle: formatMessage(messages.title), fieldInformation: formatMessage(messages.fieldInformation), fieldDescription: formatMessage(messages.fieldDescription) },
        React.createElement(Formik, { initialValues: {
                message: profile === null || profile === void 0 ? void 0 : profile.message,
            }, validationSchema: schema, enableReinitialize: true, onSubmit: function (values) {
                return onSubmit(values, SuccessFeedbackType.ProfileMessage);
            } }, function (_a) {
            var isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, values = _a.values;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(TextAreaField, { rows: 4, name: "message", onFocus: fieldOnFocusActionCallback, onBlur: fieldBlurActionCallback, onChange: handleChange, value: values.message, label: formatMessage(messages.profileMessage) }),
                React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { type: "submit", disabled: isSubmitting }, formatMessage(messages.updateProfileMessage)))));
        })));
};
