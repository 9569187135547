/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import { ArrowNext } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { CardContainer, Fullname, InfoWrapper, Rank, Score, Slug, } from './styles';
export var CardMember = memo(function (_a) {
    var user = _a.user, onClick = _a.onClick;
    var _b;
    var userName = (user === null || user === void 0 ? void 0 : user.firstName) ? "(" + (user === null || user === void 0 ? void 0 : user.pseudo) + ")" : user === null || user === void 0 ? void 0 : user.pseudo;
    return (React.createElement(CardContainer, { onClick: onClick, tabIndex: 0 },
        React.createElement(Rank, null,
            user.ranks.team,
            "\u00BA"),
        React.createElement(Avatar, { size: "small", src: ((_b = user.avatar) === null || _b === void 0 ? void 0 : _b.urlThumb) || avatarImage1, alt: "Member avatar" }),
        React.createElement(InfoWrapper, null,
            (user === null || user === void 0 ? void 0 : user.firstName) && (React.createElement(Fullname, null, (user.firstName || '') + " " + (user.lastName || ''))),
            React.createElement(Slug, { hasNotFirstName: !(user === null || user === void 0 ? void 0 : user.firstName) }, userName)),
        React.createElement(Score, null, user.score),
        React.createElement(ArrowNext, { size: rem(24) })));
});
