/* eslint-disable react/button-has-type */
import { Button } from 'components';
import { toggleDarkMode } from 'containers/App/actions';
import { useAction } from 'hooks';
import { DarkModeWhite } from 'icons/color';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
export var DarkModeSwitcher = memo(function () {
    var handleDarkMode = useAction(toggleDarkMode);
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Button, { variant: "link", onClick: handleDarkMode },
        React.createElement(DarkModeWhite, { title: formatMessage({ id: 'components.nav.darkMode' }), width: rem(20), height: rem(20) })));
});
