import { createSelector } from 'reselect';
import { myFavoritesInitialState } from '../reducers/favoritesReducer';
export var selectMyFavoritesDomain = function (state) {
    return state.myFavorites || myFavoritesInitialState;
};
export var makeSelectMyFavoritesLoading = function () {
    return createSelector(selectMyFavoritesDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectMyFavoritesHasError = function () {
    return createSelector(selectMyFavoritesDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectMyFavoritesError = function () {
    return createSelector(selectMyFavoritesDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectMyFavoritesPages = function () {
    return createSelector(selectMyFavoritesDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectMyFavoritesCurrentPage = function () {
    return createSelector(selectMyFavoritesDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectMyFavoritesTotal = function () {
    return createSelector(selectMyFavoritesDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectMyFavoritesItems = function () {
    return createSelector(selectMyFavoritesDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeMyFavorites = function () {
    return createSelector(selectMyFavoritesDomain, function (subState) { return subState; });
};
export default makeMyFavorites;
