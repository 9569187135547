var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, shade } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
export var CardContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  border-bottom: ", " solid ", ";\n  display: flex;\n  align-items: center;\n  padding: ", " 0;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  & + div {\n    border-top: 0;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  border-top: ", " solid ", ";\n  border-bottom: ", " solid ", ";\n  display: flex;\n  align-items: center;\n  padding: ", " 0;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  & + div {\n    border-top: 0;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), rem(1), border, rem(1), border, rem(16));
export var InfoWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  margin-left: ", ";\n  margin-right: ", ";\n  overflow: hidden;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  margin-left: ", ";\n  margin-right: ", ";\n  overflow: hidden;\n"])), rem(8), rem(8));
export var Fullname = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n"])), color, rem(14), fonts.weight.semibold, rem(4));
export var Slug = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  ",
    ";\n"])), color, rem(13), ifProp('hasNotFirstName', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      font-size: ", ";\n      font-weight: ", ";\n    "], ["\n      font-size: ", ";\n      font-weight: ", ";\n    "])), rem(14), fonts.weight.semibold)));
export var Rank = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  width: ", ";\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: ", ";\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  width: ", ";\n  height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: ", ";\n"])), colors.base.secondary, rem(10), colors.base.white, rem(20), fonts.weight.semibold, rem(32), rem(32), rem(8));
export var Score = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: 0 ", ";\n  height: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: 0 ", ";\n  height: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), colors.base.primary, rem(1), shade(0.3, colors.base.primary), rem(12), rem(12), rem(24), colors.base.white, rem(14), fonts.weight.semibold, rem(8));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
