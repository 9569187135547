var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import { fonts } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: calc(100vw - ", ");\n"], ["\n  display: flex;\n  align-items: center;\n  width: calc(100vw - ", ");\n"])), rem(40));
export var Body = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  overflow: hidden;\n  flex: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  overflow: hidden;\n  flex: 1;\n"])));
export var Content = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  width: 100%;\n  overflow: hidden;\n\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  width: 100%;\n  overflow: hidden;\n\n  ",
    ";\n"])), switchProp('size', {
    small: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-left: ", ";\n    "], ["\n      margin-left: ", ";\n    "])), rem(12)),
    medium: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      margin-left: ", ";\n    "], ["\n      margin-left: ", ";\n    "])), rem(20)),
    large: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      margin-left: ", ";\n    "], ["\n      margin-left: ", ";\n    "])), rem(24)),
}));
export var Fullname = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-weight: ", ";\n  text-decoration: none;\n\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  ", "\n"], ["\n  font-weight: ", ";\n  text-decoration: none;\n\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  ",
    "\n"])), fonts.weight.bold, switchProp('size', {
    small: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      font-size: ", ";\n    "], ["\n      font-size: ", ";\n    "])), rem(14)),
    medium: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      font-size: ", ";\n    "], ["\n      font-size: ", ";\n    "])), rem(17)),
    large: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      font-size: ", ";\n    "], ["\n      font-size: ", ";\n    "])), rem(20)),
}));
export var Username = styled.span(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  text-decoration: none;\n\n  ", ";\n"], ["\n  text-decoration: none;\n\n  ",
    ";\n"])), switchProp('size', {
    small: css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      font-size: ", ";\n    "], ["\n      font-size: ", ";\n    "])), rem(12)),
    medium: css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      font-size: ", ";\n    "], ["\n      font-size: ", ";\n    "])), rem(15)),
    large: css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      font-size: ", ";\n    "], ["\n      font-size: ", ";\n    "])), rem(18)),
}));
export var ChildrenWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-left: ", ";\n"], ["\n  margin-left: ", ";\n"])), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
