var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var color = theme('mode', {
    light: colors.space.tint800,
    dark: colors.space.tint100,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    padding: ", " 0 ", ";\n  }\n"], ["\n  padding: ", ";\n\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    padding: ", " 0 ", ";\n  }\n"])), rem(16), rem(1), border, rem(20), rem(4));
export var Title = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), rem(16), fonts.weight.semibold, rem(14));
export var PaginationContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin: ", " 0;\n"])), rem(20));
export var ButtonWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n\n  button {\n    background: none;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n\n  button {\n    background: none;\n    color: ", ";\n  }\n"])), rem(16), color);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
