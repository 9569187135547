import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import { Facebook, Linkedin, Twitter, Youtube } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { CopyContainer, ExternalLink, FooterContainer, SocialNetworksContainer, } from './styles';
export var Footer = memo(function () {
    var formatMessage = useIntl().formatMessage;
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(FooterContainer, null,
        React.createElement(CopyContainer, null,
            React.createElement("p", null,
                React.createElement("span", null, "\u00A9"),
                formatMessage({
                    id: 'components.footer.copyRight',
                })),
            React.createElement("span", null, "\u00B7"),
            React.createElement(ExternalLink, { href: paths.termsAndConditionsPage }, formatMessage({
                id: 'components.footer.privacyTerms',
            })),
            React.createElement("span", null, "\u00B7"),
            React.createElement(ExternalLink, { href: "/" }, formatMessage({
                id: 'components.footer.sitemap',
            }))),
        React.createElement(SocialNetworksContainer, { isKeyboardMode: keyboard },
            React.createElement(ExternalLink, { href: "https://twitter.com/Jaccede" },
                React.createElement(Twitter, { size: rem(28) })),
            React.createElement(ExternalLink, { href: "https://www.facebook.com/jaccede/" },
                React.createElement(Facebook, { size: rem(24) })),
            React.createElement(ExternalLink, { href: "https://www.youtube.com/channel/UCRO-yy5YrhsRaxPQ0oeornw" },
                React.createElement(Youtube, { size: rem(28) })),
            React.createElement(ExternalLink, { href: "https://www.linkedin.com/company/jaccede" },
                React.createElement(Linkedin, { size: rem(24) })))));
});
