var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { dotDetail, worldwideLandscapes } from 'assets/svg';
import { Button } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { fonts } from 'styles';
import { Section } from '../Section';
var opacityImage = theme('mode', {
    light: '0.1',
    dark: '0.05',
});
var opacityDots = theme('mode', {
    light: '0.4',
    dark: '1',
});
export var StyledSection = styled(Section)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  padding-bottom: 0;\n\n  ", ";\n\n  &::before {\n    background: url(", ") no-repeat 70% bottom;\n    content: '';\n    bottom: 0;\n    position: absolute;\n    display: block;\n    height: ", ";\n    opacity: ", ";\n    z-index: -1;\n\n    ", ";\n\n    ", ";\n\n    ", ";\n  }\n\n  &::after {\n    background: url(", ") no-repeat 70% bottom;\n    content: '';\n    top: 15%;\n    left: -10%;\n    position: absolute;\n    display: block;\n    width: ", ";\n    height: ", ";\n    opacity: ", ";\n    z-index: -1;\n  }\n"], ["\n  position: relative;\n  padding-bottom: 0;\n\n  ",
    ";\n\n  &::before {\n    background: url(", ") no-repeat 70% bottom;\n    content: '';\n    bottom: 0;\n    position: absolute;\n    display: block;\n    height: ", ";\n    opacity: ", ";\n    z-index: -1;\n\n    ",
    ";\n\n    ",
    ";\n\n    ",
    ";\n  }\n\n  &::after {\n    background: url(", ") no-repeat 70% bottom;\n    content: '';\n    top: 15%;\n    left: -10%;\n    position: absolute;\n    display: block;\n    width: ", ";\n    height: ", ";\n    opacity: ", ";\n    z-index: -1;\n  }\n"])), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-left: 0;\n    padding-right: 0;\n  "], ["\n    padding-left: 0;\n    padding-right: 0;\n  "]))), worldwideLandscapes, rem(320), opacityImage, media.greaterThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-size: contain;\n      width: 140%;\n    "], ["\n      background-size: contain;\n      width: 140%;\n    "]))), media.between('small', 'medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-size: cover;\n      width: 110%;\n    "], ["\n      background-size: cover;\n      width: 110%;\n    "]))), media.lessThan('small')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      background-size: cover;\n      width: 100%;\n    "], ["\n      background-size: cover;\n      width: 100%;\n    "]))), dotDetail, rem(320), rem(320), opacityDots);
export var ViewRanks = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-self: center;\n  font-weight: ", ";\n  margin-top: ", ";\n"], ["\n  align-self: center;\n  font-weight: ", ";\n  margin-top: ", ";\n"])), fonts.weight.semibold, rem(34));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
