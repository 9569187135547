import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editProfileMessage';
var messages = defineMessages({
    title: { id: scope + ".title" },
    profileMessage: { id: scope + ".profileMessage" },
    maxSizeProfileMessage: { id: scope + ".maxSizeProfileMessage" },
    updateProfileMessage: { id: scope + ".updateProfileMessage" },
    fieldDescription: {
        id: 'containers.editMyProfile.message.fieldDescription',
    },
    fieldInformation: {
        id: 'containers.editMyProfile.message.fieldInformation',
    },
});
export default messages;
