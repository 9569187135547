var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { optionsChangedSuccess, showConversation, showConversations, showHistory, } from './actions';
import { GET_CONVERSATION, GET_CONVERSATIONS, GET_HISTORY, LOCK_CONVERSATION_NOTIFICATION, QUIT_GROUP_CONVERSATION, UNLOCK_CONVERSATION_NOTIFICATION, } from './contants';
export function getConversations(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(api.chat.getConversations, payload.page)];
            case 1:
                response = _c.sent();
                return [4 /*yield*/, put(showConversations(response.data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 4];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function getConversation(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(api.chat.getConversation, payload)];
            case 1:
                response = _c.sent();
                return [4 /*yield*/, put(showConversation(response.data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 4];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function lockConversationNotification(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 5, , 6]);
                return [4 /*yield*/, call(api.chat.lockConversationNotification, payload)];
            case 1:
                _c.sent();
                return [4 /*yield*/, call(api.chat.getConversation, payload)];
            case 2:
                response = _c.sent();
                return [4 /*yield*/, put(showConversation(response.data))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(optionsChangedSuccess())];
            case 4:
                _c.sent();
                return [3 /*break*/, 6];
            case 5:
                _b = _c.sent();
                response = _b.response;
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function unlockConversationNotification(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 5, , 6]);
                return [4 /*yield*/, call(api.chat.unlockConversationNotification, payload)];
            case 1:
                _c.sent();
                return [4 /*yield*/, call(api.chat.getConversation, payload)];
            case 2:
                response = _c.sent();
                return [4 /*yield*/, put(showConversation(response.data))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(optionsChangedSuccess())];
            case 4:
                _c.sent();
                return [3 /*break*/, 6];
            case 5:
                _b = _c.sent();
                response = _b.response;
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function quitGroupConversation(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 7]);
                return [4 /*yield*/, call(api.chat.quitGroupConversation, 1)];
            case 1:
                _c.sent();
                return [4 /*yield*/, put(showConversation(undefined))];
            case 2:
                _c.sent();
                return [4 /*yield*/, call(api.chat.getConversations, payload)];
            case 3:
                response = _c.sent();
                return [4 /*yield*/, put(showConversations(response.data))];
            case 4:
                _c.sent();
                return [4 /*yield*/, put(optionsChangedSuccess())];
            case 5:
                _c.sent();
                return [3 /*break*/, 7];
            case 6:
                _b = _c.sent();
                response = _b.response;
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function getHistory(_a) {
    var response, _b, response;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(api.chat.getHistory, payload.id, payload.page, payload.perPage)];
            case 1:
                response = _c.sent();
                return [4 /*yield*/, put(showHistory(response.data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 4];
            case 3:
                _b = _c.sent();
                response = _b.response;
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export default function chatSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(GET_CONVERSATIONS, getConversations)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(GET_CONVERSATION, getConversation)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(LOCK_CONVERSATION_NOTIFICATION, lockConversationNotification)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(UNLOCK_CONVERSATION_NOTIFICATION, unlockConversationNotification)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(QUIT_GROUP_CONVERSATION, quitGroupConversation)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(GET_HISTORY, getHistory)];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
