var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as api from 'api';
import { push } from 'connected-react-router';
import { authLoginSuccess } from 'containers/Auth/actions';
import { fetchMyChallengesRequest } from 'containers/Profile/actions';
import { fetchMyNotificationsRequest } from 'containers/Profile/actions/myNotificationsActions';
import { paths } from 'containers/RoutesProvider/routes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OneSignalService } from 'utils/onesignal';
import { loginByFacebookEvent } from 'utils/tagEvents';
import { oAuthLoginFailure, oAuthLoginSuccess } from './actions';
import { OAUTH_LOGIN_REQUEST } from './constants';
export function oAuthLogin(_a) {
    var response, oneSignal, notificationsRequest, challengesRequest, next, _b, error_1, error_2, err, _c, message;
    var payload = _a.payload;
    var _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _f.trys.push([0, 18, , 24]);
                return [4 /*yield*/, call(api.oauth.login, payload)];
            case 1:
                response = _f.sent();
                return [4 /*yield*/, put(authLoginSuccess(response.data))];
            case 2:
                _f.sent();
                oneSignal = OneSignalService.getInstance();
                return [4 /*yield*/, call([oneSignal, 'sendUserId'])];
            case 3:
                _f.sent();
                return [4 /*yield*/, call([oneSignal, 'sendTags'], response.data)];
            case 4:
                _f.sent();
                return [4 /*yield*/, put(oAuthLoginSuccess())];
            case 5:
                _f.sent();
                return [4 /*yield*/, call(loginByFacebookEvent)];
            case 6:
                _f.sent();
                notificationsRequest = {
                    pagination: {
                        page: 1,
                        perPage: 5,
                    },
                };
                return [4 /*yield*/, put(fetchMyNotificationsRequest(notificationsRequest))];
            case 7:
                _f.sent();
                challengesRequest = {
                    pagination: {
                        page: 1,
                        perPage: 10,
                    },
                };
                return [4 /*yield*/, put(fetchMyChallengesRequest(challengesRequest))];
            case 8:
                _f.sent();
                _f.label = 9;
            case 9:
                _f.trys.push([9, 15, , 17]);
                next = JSON.parse(payload.state).next;
                _b = next;
                switch (_b) {
                    case paths.signUpPage: return [3 /*break*/, 10];
                    case paths.signInPage: return [3 /*break*/, 10];
                }
                return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, put(push('/'))];
            case 11:
                _f.sent();
                return [3 /*break*/, 14];
            case 12: return [4 /*yield*/, put(push(next))];
            case 13:
                _f.sent();
                return [3 /*break*/, 14];
            case 14: return [3 /*break*/, 17];
            case 15:
                error_1 = _f.sent();
                return [4 /*yield*/, put(push('/'))];
            case 16:
                _f.sent();
                return [3 /*break*/, 17];
            case 17: return [3 /*break*/, 24];
            case 18:
                error_2 = _f.sent();
                err = error_2;
                _c = (_d = err.response) === null || _d === void 0 ? void 0 : _d.status;
                switch (_c) {
                    case 400: return [3 /*break*/, 19];
                    case 401: return [3 /*break*/, 19];
                    case 404: return [3 /*break*/, 19];
                }
                return [3 /*break*/, 21];
            case 19:
                message = ((_e = err.response) === null || _e === void 0 ? void 0 : _e.data).message;
                return [4 /*yield*/, put(oAuthLoginFailure(message))];
            case 20:
                _f.sent();
                return [3 /*break*/, 23];
            case 21: return [4 /*yield*/, put(oAuthLoginFailure('Contact the administrator.'))];
            case 22:
                _f.sent();
                return [3 /*break*/, 23];
            case 23: return [3 /*break*/, 24];
            case 24: return [2 /*return*/];
        }
    });
}
// Individual exports for testing
export default function oAuthLoginSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(OAUTH_LOGIN_REQUEST, oAuthLogin)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
