import { OAUTH_LOGIN_FAILURE, OAUTH_LOGIN_REQUEST, OAUTH_LOGIN_SUCCESS, } from './constants';
export var oAuthLoginRequest = function (payload) { return ({
    type: OAUTH_LOGIN_REQUEST,
    payload: payload,
}); };
export var oAuthLoginFailure = function (payload) { return ({
    type: OAUTH_LOGIN_FAILURE,
    payload: payload,
}); };
export var oAuthLoginSuccess = function () { return ({
    type: OAUTH_LOGIN_SUCCESS,
}); };
