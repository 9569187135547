import { Button, Modal, TextAreaField } from 'components';
import { Spin } from 'components/Spin';
import { clearReportedPhoto, reportMediaPlace, } from 'containers/PlaceDetails/actions';
import { makeSelectorAlreadyReportedPhoto, makeSelectorReportedPhoto, makeSelectorReportPhotoLoading, } from 'containers/PlaceDetails/selectors';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'utils/hooks';
import { ModalButtonWrapper, ModalMessage } from './styles';
import ReportFormFormSchema from './validator';
export var ReportPhotosModal = function (_a) {
    var placeId = _a.placeId, mediaId = _a.mediaId, showReportModal = _a.showReportModal, setShowReportModal = _a.setShowReportModal;
    var dispatch = useDispatch();
    var formatMessage = useIntl().formatMessage;
    var reported = useSelector(makeSelectorReportedPhoto());
    var reportLoading = useSelector(makeSelectorReportPhotoLoading());
    var alreadyReported = useSelector(makeSelectorAlreadyReportedPhoto());
    var schema = ReportFormFormSchema(formatMessage);
    var handleCancelModal = function () {
        setShowReportModal(false);
        dispatch(clearReportedPhoto());
    };
    var onSubmit = function (values) {
        if (values.message) {
            dispatch(reportMediaPlace({
                placeId: placeId,
                mediaId: mediaId,
                message: values.message,
            }));
        }
    };
    return (React.createElement(Modal, { title: formatMessage({
            id: reported
                ? 'containers.placeDetails.reportedPhoto.title'
                : 'containers.placeDetails.reportPhoto.title',
        }), visible: showReportModal, footer: null, onCancel: handleCancelModal },
        React.createElement(Spin, { spinning: reportLoading }, reported && !alreadyReported ? (React.createElement("div", null,
            React.createElement(ModalMessage, null, formatMessage({
                id: 'containers.placeDetails.reportedPhoto.message',
            })),
            React.createElement(ModalButtonWrapper, null,
                React.createElement(Button, { onClick: handleCancelModal },
                    React.createElement(FormattedMessage, { id: "containers.placeDetails.reportedPhoto.button" }))))) : (React.createElement(Formik, { initialValues: {
                message: '',
            }, enableReinitialize: true, validationSchema: schema, onSubmit: onSubmit }, function (_a) {
            var handleBlur = _a.handleBlur, handleChange = _a.handleChange, values = _a.values, handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(TextAreaField, { rows: 4, name: "message", label: formatMessage({
                        id: 'containers.placeDetails.reportPhoto.label',
                    }), placeholder: formatMessage({
                        id: 'containers.placeDetails.reportPhoto.placeholder',
                    }), onBlur: handleBlur, onChange: handleChange, value: values.message }),
                React.createElement(ModalButtonWrapper, null,
                    React.createElement(Button, { type: "submit" },
                        React.createElement(FormattedMessage, { id: "containers.placeDetails.reportPhoto.button" })))));
        })))));
};
