var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: ", ";\n"])), rem(8));
export var Title = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n"])), fonts.weight.bold, rem(14), colors.base.black);
export var Description = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n"], ["\n  font-size: ", ";\n  color: ", ";\n"])), rem(13), colors.space.tint600);
var templateObject_1, templateObject_2, templateObject_3;
