var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Skeleton } from 'antd';
import React from 'react';
import { useSelector } from 'utils/hooks';
import { makeSelectLoading } from '../../selectors';
import { Description } from '../styles';
export var LoadableDescription = function (_a) {
    var rows = _a.rows, removeWrapper = _a.removeWrapper, descriptionProps = _a.descriptionProps, children = _a.children;
    var isLoading = useSelector(makeSelectLoading());
    return (React.createElement(Skeleton, { loading: isLoading, active: true, title: false, paragraph: { rows: rows || 1 } }, removeWrapper ? (children) : (React.createElement(Description, __assign({}, descriptionProps), children))));
};
