import { createSelector } from 'reselect';
import { monthUsersInitialState } from './reducer';
export var selectMonthUsersDomain = function (state) {
    return state.monthUsers || monthUsersInitialState;
};
export var makeSelectMonthUsersLoading = function () {
    return createSelector(selectMonthUsersDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectMonthUsersHasError = function () {
    return createSelector(selectMonthUsersDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectMonthUsersError = function () {
    return createSelector(selectMonthUsersDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectMonthUsersPages = function () {
    return createSelector(selectMonthUsersDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectMonthUsersCurrentPage = function () {
    return createSelector(selectMonthUsersDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectMonthUsersTotal = function () {
    return createSelector(selectMonthUsersDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectMonthUsersItems = function () {
    return createSelector(selectMonthUsersDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeMonthUsers = function () {
    return createSelector(selectMonthUsersDomain, function (subState) { return subState; });
};
export default makeMonthUsers;
