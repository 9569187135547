/* eslint-disable lodash/prefer-lodash-method */
export var openNewWindow = function (url) {
    var width = window.screen.availWidth;
    var height = window.screen.availHeight;
    if (navigator.userAgent.match(/Edge\/\d+/g)) {
        width = window.screen.width;
        height = window.screen.height;
    }
    var newWindow = window.open(url, '_blank', 
    // `toolbar=0,location=0,menubar=0,modal=yes,scrollbars=no,width=${width},height=${height}`,
    "toolbar=0,location=0,menubar=0,modal=yes,directories=no,width=" + width + ",height=" + height, false);
    // @ts-ignore
    newWindow.opener = null;
};
export var redirectWindow = function (url) { return window.location.replace(url); };
