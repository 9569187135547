import { makeSelectUserEmail } from 'containers/Auth/selectors';
import { Form } from 'formik';
import { useSelector } from 'hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
var ResendEmailForm = function (_a) {
    var status = _a.status, handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting;
    var formatMessage = useIntl().formatMessage;
    var email = useSelector(makeSelectUserEmail());
    return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
        "Your current e-mail is ",
        email,
        (status === null || status === void 0 ? void 0 : status.api) && React.createElement("p", null, status === null || status === void 0 ? void 0 : status.api),
        React.createElement("br", null),
        React.createElement("button", { type: "submit", disabled: isSubmitting }, formatMessage(messages.submitButton))));
};
export default ResendEmailForm;
