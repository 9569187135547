import { register } from 'razzle-plugin-workbox/service-worker';
import React from 'react';
import { hydrate } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './containers/App';
import { persistor, store } from './store';

const tagManagerArgs = {
  gtmId: process.env.RAZZLE_APP_GOOGLE_TAG_MANAGER,
};

TagManager.initialize(tagManagerArgs);

hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}

register();
