var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint200,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var EditOptionsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: ", ";\n  padding: 0 ", " ", ";\n\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    color: ", ";\n    padding: ", ";\n    cursor: pointer;\n    transition-duration: 0.2s;\n  }\n\n  .ant-list-item-meta-title {\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1.15;\n    margin-bottom: 0;\n  }\n\n  > svg {\n    flex-shrink: 0;\n    margin-left: ", ";\n  }\n"], ["\n  margin-top: ", ";\n  padding: 0 ", " ", ";\n\n  .ant-list-item {\n    border-bottom: ", " solid ", " !important;\n    color: ", ";\n    padding: ", ";\n    cursor: pointer;\n    transition-duration: 0.2s;\n  }\n\n  .ant-list-item-meta-title {\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 1.15;\n    margin-bottom: 0;\n  }\n\n  > svg {\n    flex-shrink: 0;\n    margin-left: ", ";\n  }\n"])), rem(60), rem(20), rem(20), rem(1), border, color, rem(16), rem(17), fonts.weight.semibold, rem(16));
export var Description = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  b {\n    display: block;\n    text-transform: uppercase;\n  }\n"], ["\n  b {\n    display: block;\n    text-transform: uppercase;\n  }\n"])));
var templateObject_1, templateObject_2;
