import { createSelector } from 'reselect';
import { subscribersInitialState } from './reducer';
export var selectSubscribersDomain = function (state) {
    return state.subscribers || subscribersInitialState;
};
export var makeSelectSubscribersLoading = function () {
    return createSelector(selectSubscribersDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectSubscribersHasError = function () {
    return createSelector(selectSubscribersDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectSubscribersError = function () {
    return createSelector(selectSubscribersDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectSubscribersPages = function () {
    return createSelector(selectSubscribersDomain, function (_a) {
        var pages = _a.pages;
        return pages;
    });
};
export var makeSelectSubscribersCurrentPage = function () {
    return createSelector(selectSubscribersDomain, function (_a) {
        var currentPage = _a.currentPage;
        return currentPage;
    });
};
export var makeSelectSubscribersTotal = function () {
    return createSelector(selectSubscribersDomain, function (_a) {
        var total = _a.total;
        return total;
    });
};
export var makeSelectSubscribersItems = function () {
    return createSelector(selectSubscribersDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeSubscribers = function () {
    return createSelector(selectSubscribersDomain, function (subState) { return subState; });
};
export default makeSubscribers;
