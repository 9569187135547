import { ALL_USERS_FAILURE, ALL_USERS_REQUEST, ALL_USERS_SUCCESS, } from './constants';
export var fetchAllUsers = function (payload) { return ({
    type: ALL_USERS_REQUEST,
    payload: payload,
}); };
export var fetchAllUsersSuccess = function (payload) { return ({
    type: ALL_USERS_SUCCESS,
    payload: payload,
}); };
export var fetchAllUsersError = function (error) { return ({
    type: ALL_USERS_FAILURE,
    payload: error,
}); };
