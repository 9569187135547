var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import MaskedInput from 'react-maskedinput';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { breakpoints, colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var border = theme('mode', {
    light: colors.space.tint300,
    dark: colors.carbon.tint300,
});
export var SecretChallengeContainer = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  padding: ", ";\n\n  ", ";\n"], ["\n  border-top: ", " solid ", ";\n  padding: ", ";\n\n  ",
    ";\n"])), rem(1), border, rem(20), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-bottom: ", ";\n  "], ["\n    margin-bottom: ", ";\n  "])), rem(32)));
export var MessageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", ";\n  margin: 0 auto;\n  max-width: ", ";\n  display: flex;\n"], ["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: ", ";\n  margin: 0 auto;\n  max-width: ", ";\n  display: flex;\n"])), rem(1), border, rem(5), rem(20), breakpoints.medium);
export var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var MessageWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: ", ";\n"])), rem(24));
export var Text = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), color, rem(13), fonts.weight.semibold, rem(8));
export var Description = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  width: 80%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  width: 80%;\n"])), color, rem(13));
export var ButtonWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var SecretCodeField = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  padding: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"])), rem(20));
export var SecretCodeInput = styled(MaskedInput)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid transparent;\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  padding: ", ";\n  width: ", ";\n  height: ", ";\n  transition: all 0.2s;\n  text-align: center;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:focus {\n    background: ", ";\n    border: ", " solid ", ";\n  }\n\n  ", ";\n"], ["\n  background: ", ";\n  border: ", " solid transparent;\n  border-radius: ", ";\n  color: ", ";\n  font-size: ", ";\n  padding: ", ";\n  width: ", ";\n  height: ", ";\n  transition: all 0.2s;\n  text-align: center;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:focus {\n    background: ", ";\n    border: ", " solid ", ";\n  }\n\n  ",
    ";\n"])), rgba(colors.space.tint500, 0.2), rem(2), rem(5), color, rem(36), rem(20), rem(200), rem(70), rgba(colors.base.info, 0.2), rem(2), rgba(colors.base.info, 0.3), ifProp('hasError', css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      background: ", ";\n      border: ", " solid ", ";\n    "], ["\n      background: ", ";\n      border: ", " solid ", ";\n    "])), rgba(colors.base.danger, 0.2), rem(2), rgba(colors.base.danger, 0.3))));
export var ErrorMessage = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  text-align: center;\n  width: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  text-align: center;\n  width: ", ";\n"])), colors.base.danger, rem(15), fonts.weight.regular, rem(12), rem(200));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
