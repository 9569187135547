var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var border = theme('mode', {
    light: colors.space.tint400,
    dark: colors.carbon.tint400,
});
var title = theme('mode', {
    light: colors.space.tint700,
    dark: colors.space.tint300,
});
var description = theme('mode', {
    light: colors.space.tint750,
    dark: colors.space.tint300,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  padding: ", ";\n\n  padding-top: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  padding: ", ";\n\n  padding-top: ", ";\n"])), title, rem(14), rem(20), rem(60));
export var MainTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), title, rem(17), fonts.weight.semibold, rem(16));
export var Title = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), title, rem(16), fonts.weight.semibold, rem(20));
export var SubTitle = styled.h4(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), colors.space.tint600, rem(14), fonts.weight.semibold, rem(4));
export var Description = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])), description, rem(13));
export var ListItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: flex-end;\n  border-bottom: ", " solid ", ";\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  padding: ", " 0;\n\n  &:nth-child(1) {\n    border-bottom: 0;\n    cursor: initial;\n  }\n\n  &:nth-child(2) {\n    align-items: flex-start;\n  }\n\n  svg {\n    flex-shrink: 0;\n    margin-left: auto;\n  }\n"], ["\n  align-items: flex-end;\n  border-bottom: ", " solid ", ";\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  padding: ", " 0;\n\n  &:nth-child(1) {\n    border-bottom: 0;\n    cursor: initial;\n  }\n\n  &:nth-child(2) {\n    align-items: flex-start;\n  }\n\n  svg {\n    flex-shrink: 0;\n    margin-left: auto;\n  }\n"])), rem(1), border, rem(16));
export var ListItemContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-right: ", ";\n  overflow: hidden;\n"], ["\n  margin-right: ", ";\n  overflow: hidden;\n"])), rem(16));
export var AvatarWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-left: auto;\n  position: relative;\n"], ["\n  margin-left: auto;\n  position: relative;\n"])));
export var AvatarOverlay = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  background: ", ";\n  border-radius: 35%;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  position: absolute;\n  right: 0;\n  width: ", ";\n  z-index: 10;\n\n  b {\n    align-items: center;\n    color: ", ";\n    display: flex;\n    justify-content: center;\n  }\n"], ["\n  align-items: center;\n  background: ", ";\n  border-radius: 35%;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  position: absolute;\n  right: 0;\n  width: ", ";\n  z-index: 10;\n\n  b {\n    align-items: center;\n    color: ", ";\n    display: flex;\n    justify-content: center;\n  }\n"])), rgba(colors.base.black, 0.4), rem(58), rem(57), colors.base.white);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
