var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var MedalPlaceDisabledMobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, _b = _a.progress, progress = _b === void 0 ? 0 : _b, props = __rest(_a, ["size", "dark", "progress"]);
    var strokeDasharraySize = 190;
    var strokeDasharray = (strokeDasharraySize * progress) / 100;
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0 0 5.90862581 0 5.90862581 4.48979592 0 4.48979592" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-9-gray", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: dark ? colors.carbon.tint100 : colors.space.tint200, strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round", strokeDasharray: strokeDasharray + ", " + strokeDasharraySize }),
                React.createElement("rect", { id: "active-bg", fill: "#E0E0E0", x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/9/disabled", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", style: { mixBlendMode: 'color' }, x: "0", y: "0", width: "26", height: "31" }),
                    React.createElement("g", { id: "Group-19", strokeWidth: "1", transform: "translate(0.000000, 4.428571)" },
                        React.createElement("polygon", { id: "Fill-1", fill: "#C2EC96", points: "4.19350645 13.8775918 3.07424839 15.5102449 -4.19354839e-05 20.0000408 3.71124839 20.0000408 22.2890871 20.0000408 25.9999581 20.0000408 24.8802806 18.3673878 23.7647968 16.7347347 21.8064097 13.8775918" }),
                        React.createElement("polygon", { id: "Fill-2", fill: "#ABD978", points: "24.8803645 18.3673469 22.368429 18.3673469 22.3642355 18.3673469 20.6868161 16.7346939 23.7648806 16.7346939" }),
                        React.createElement("polygon", { id: "Fill-3", fill: "#8CC152", opacity: "0.551130022", points: "15.5578968 17.5510204 17.3737032 15.9183673 19.6554129 19.5918367 9.69950968 19.5918367 8.02209032 17.9591837" }),
                        React.createElement("polygon", { id: "Fill-4", fill: "#E6E9ED", points: "21.4879935 18.3673061 22.2889613 20.0003673 26.0002516 20.0003673 24.8801548 18.3673061" }),
                        React.createElement("g", { id: "Group-18" },
                            React.createElement("path", { d: "M13,16.7346939 C13,16.7346939 13.3480645,16.4367347 13.8764516,15.9183673 L13.8806452,15.9183673 L17.373871,15.9183673 L17.9777419,17.9591837 L8.02225806,17.9591837 L8.62612903,15.9183673 L12.1193548,15.9183673 C12.6519355,16.4367347 13,16.7346939 13,16.7346939", id: "Fill-5", fill: "#E6E9ED" }),
                            React.createElement("g", { id: "Group-9", transform: "translate(0.000000, 15.510204)" },
                                React.createElement("mask", { id: "mask-2", fill: "white" },
                                    React.createElement("use", { xlinkHref: "#path-1" })),
                                React.createElement("g", { id: "Clip-8" }),
                                React.createElement("polygon", { id: "Fill-7", fill: "#E6E9ED", mask: "url(#mask-2)", points: "5.90862581 0 3.71120645 4.48979592 -8.38709677e-05 4.48979592 3.0737871 0" })),
                            React.createElement("polygon", { id: "Fill-10", fill: "#ABD978", points: "7.58604516 17.3506494 6.18749677 20.5714286 3.71120645 20.5714286 5.90862581 15.5102041" }),
                            React.createElement("polygon", { id: "Fill-12", fill: "#A8D273", points: "26 20.5714286 26 22.2040816 0 22.2040816 0 20.5714286 3.71129032 20.5714286 22.2887097 20.5714286" }),
                            React.createElement("polygon", { id: "Fill-14", fill: "#E6E9ED", points: "23.7647968 16.7346939 20.6909258 16.7346939 20.6867323 16.7346939 19.2902806 13.877551 21.8064097 13.877551" }),
                            React.createElement("path", { d: "M13,9.31522449 C11.5767097,9.31522449 10.4230645,8.19236735 10.4230645,6.80706122 C10.4230645,5.42216327 11.5767097,4.29930612 13,4.29930612 C14.4232903,4.29930612 15.5769355,5.42216327 15.5769355,6.80706122 C15.5769355,8.19236735 14.4232903,9.31522449 13,9.31522449 M13,0.00012244898 C9.52354839,0.00012244898 6.70967742,2.74257143 6.70967742,6.12257143 C6.70967742,8.96338776 8.55483871,11.8405306 10.2444194,13.8776735 C10.9447419,14.7184898 11.616129,15.4201224 12.1193548,15.9184898 C12.6519355,16.436449 12.9995806,16.7348163 12.9995806,16.7348163 C12.9995806,16.7348163 13.3476452,16.436449 13.8760323,15.9184898 L13.8802258,15.9184898 C14.3834516,15.4201224 15.0548387,14.7184898 15.7551613,13.8776735 C17.4451613,11.8405306 19.2903226,8.96338776 19.2903226,6.12257143 C19.2903226,2.74257143 16.4764516,0.00012244898 13,0.00012244898", id: "Fill-16", fill: "#FF8A96" }))),
                    React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", style: { mixBlendMode: 'color' }, x: "0", y: "0", width: "26", height: "31" })),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: dark ? colors.space.tint500 : colors.space.tint200 })))));
});
