import { PROFILE_MY_PROFILE_FAILURE, PROFILE_MY_PROFILE_REQUEST, PROFILE_MY_PROFILE_SUCCESS, } from '../constants';
export var fetchMyProfileRequest = function () { return ({
    type: PROFILE_MY_PROFILE_REQUEST,
}); };
export var fetchMyProfileSuccess = function (payload) { return ({
    type: PROFILE_MY_PROFILE_SUCCESS,
    payload: payload,
}); };
export var fetchMyProfileError = function (error) { return ({
    type: PROFILE_MY_PROFILE_FAILURE,
    payload: error,
}); };
