var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable lodash/prefer-lodash-method */
import { LoadingIndicator } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { Footer, FooterTitle, UsersContainer } from '../styles';
import UserList from '../UserList';
import UserListItem from '../UserListItem';
var TabWeek = function (_a) {
    var users = _a.users, loggedUser = _a.loggedUser, loadMore = _a.loadMore, hasMore = _a.hasMore, loading = _a.loading, _b = _a.currentPage, currentPage = _b === void 0 ? 1 : _b, _c = _a.weeklyPoints, weeklyPoints = _c === void 0 ? 0 : _c;
    var usersContainerRef = useRef(null);
    var loggedUserCopy = __assign(__assign({}, loggedUser), { contributions: weeklyPoints });
    return (React.createElement(React.Fragment, null,
        React.createElement(UsersContainer, { ref: usersContainerRef }, users && (React.createElement(InfiniteScroll, { initialLoad: false, pageStart: 1, loadMore: loadMore, hasMore: !loading && hasMore, useWindow: false, getScrollParent: function () { return usersContainerRef.current; } }, loading && currentPage === 1 ? (React.createElement(LoadingIndicator, null)) : (React.createElement(UserList, { users: users, loggedUserId: loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.id, isLoading: loading && currentPage > 1 }))))),
        loggedUser && (React.createElement(Footer, null,
            React.createElement(FooterTitle, { noPoints: weeklyPoints === 0 }, weeklyPoints === 0 ? (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabWeek.footerTitleWithoutPoints" })) : (React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabWeek.footerTitleWithPoints" }))),
            React.createElement(UserListItem, { user: loggedUserCopy, scoreKind: weeklyPoints === 0 ? 'noPoints' : 'success', redirectLink: paths.profilePage, points: weeklyPoints })))));
};
export default TabWeek;
