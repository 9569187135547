export var SuccessFeedbackType;
(function (SuccessFeedbackType) {
    SuccessFeedbackType[SuccessFeedbackType["Password"] = 0] = "Password";
    SuccessFeedbackType[SuccessFeedbackType["Photo"] = 1] = "Photo";
    SuccessFeedbackType[SuccessFeedbackType["DefaultAvatar"] = 2] = "DefaultAvatar";
    SuccessFeedbackType[SuccessFeedbackType["PhotoDelete"] = 3] = "PhotoDelete";
    SuccessFeedbackType[SuccessFeedbackType["Name"] = 4] = "Name";
    SuccessFeedbackType[SuccessFeedbackType["ProfileType"] = 5] = "ProfileType";
    SuccessFeedbackType[SuccessFeedbackType["ProfileMessage"] = 6] = "ProfileMessage";
    SuccessFeedbackType[SuccessFeedbackType["Telephone"] = 7] = "Telephone";
    SuccessFeedbackType[SuccessFeedbackType["Location"] = 8] = "Location";
    SuccessFeedbackType[SuccessFeedbackType["Terms"] = 9] = "Terms";
})(SuccessFeedbackType || (SuccessFeedbackType = {}));
