/* eslint-disable @typescript-eslint/no-use-before-define */
import { Tooltip, TooltipContent } from 'components';
import { Medal200DisabledMobile, Medal200Mobile, Medal1000DisabledMobile, Medal1000Mobile, Medal5000DisabledMobile, Medal5000Mobile, Medal10000DisabledMobile, Medal10000Mobile, Medal25000DisabledMobile, Medal25000Mobile, MedalCommentDisabledMobile, MedalCommentMobile, MedalCriterionDisabledMobile, MedalCriterionMobile, MedalPhotoDisabledMobile, MedalPhotoMobile, MedalPlaceDisabledMobile, MedalPlaceMobile, } from 'icons/medal';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { calculatePercentage, getDescription, getMedalValue, } from 'utils/achievements';
import { Container, MedalRow, MedalWrapper } from './styles';
var MEDAL_SIZE = rem(55);
var Medals = function (_a) {
    var _b = _a.contributionsStats, contributionsStats = _b === void 0 ? [] : _b, darkMode = _a.darkMode, _c = _a.totalPoints, totalPoints = _c === void 0 ? 0 : _c;
    var criterionMedal = getMedalValue(contributionsStats, 'criterion');
    var photoMedal = getMedalValue(contributionsStats, 'photo');
    var commentMedal = getMedalValue(contributionsStats, 'comment');
    var placeMedal = getMedalValue(contributionsStats, 'place');
    var firstRow = [
        {
            title: 'medal200',
            description: totalPoints >= 200 ? '200' : totalPoints + " / 200",
            goal: 200,
            percent: calculatePercentage(totalPoints, 200),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal200DisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(Medal200Mobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
        {
            title: 'medal1000',
            description: totalPoints >= 1000 ? '1000' : totalPoints + " / 1000",
            goal: 1000,
            percent: calculatePercentage(totalPoints, 1000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal1000DisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(Medal1000Mobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
        {
            title: 'medal5000',
            description: totalPoints >= 5000 ? '5000' : totalPoints + " / 5000",
            goal: 5000,
            percent: calculatePercentage(totalPoints, 5000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal5000DisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(Medal5000Mobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
    ];
    var secondRow = [
        {
            title: 'medal10000',
            description: totalPoints >= 10000 ? '10000' : totalPoints + " / 10000",
            goal: 10000,
            percent: calculatePercentage(totalPoints, 10000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal10000DisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(Medal10000Mobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
        {
            title: 'medal25000',
            description: totalPoints >= 25000 ? '25000' : totalPoints + " / 25000",
            goal: 25000,
            percent: calculatePercentage(totalPoints, 25000),
            icon: function (p) {
                return p < 100 ? (React.createElement(Medal25000DisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(Medal25000Mobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
        {
            title: 'medalCriterion',
            description: getDescription(criterionMedal),
            goal: criterionMedal.goal,
            percent: criterionMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalCriterionDisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(MedalCriterionMobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
        {
            title: 'medalPhoto',
            description: getDescription(photoMedal),
            goal: photoMedal.goal,
            percent: photoMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalPhotoDisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(MedalPhotoMobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
    ];
    var thirdRow = [
        {
            title: 'medalComment',
            description: getDescription(commentMedal),
            goal: commentMedal.goal,
            percent: commentMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalCommentDisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(MedalCommentMobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
        {
            title: 'medalPlace',
            description: getDescription(placeMedal),
            goal: placeMedal.goal,
            percent: placeMedal.percent,
            icon: function (p) {
                return p < 100 ? (React.createElement(MedalPlaceDisabledMobile, { size: MEDAL_SIZE, progress: p, dark: darkMode })) : (React.createElement(MedalPlaceMobile, { size: MEDAL_SIZE, dark: darkMode }));
            },
        },
    ];
    var medals = [firstRow, secondRow, thirdRow];
    return (React.createElement(Container, null, map(medals, function (medalRow, i) { return (React.createElement(MedalRow, { key: i }, map(medalRow, function (medal, j) { return (React.createElement(Tooltip, { key: j, trigger: "click", title: React.createElement(TooltipContent, { title: React.createElement(FormattedMessage, { id: "containers.profile.user." + medal.title }), description: React.createElement("span", null,
                medal.description,
                ' ',
                React.createElement(FormattedMessage, { id: "containers.profile.user.points" })) }) },
        React.createElement(MedalWrapper, null, medal.icon(medal.percent)))); }))); })));
};
export default Medals;
