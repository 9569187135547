export function calculatePercentage(total, value) {
    return (total * 100) / value;
}
export function getPercentage(contributionsStats, type) {
    var _a;
    return ((_a = contributionsStats === null || contributionsStats === void 0 ? void 0 : contributionsStats.find(function (cs) { return cs.type === type; })) === null || _a === void 0 ? void 0 : _a.percent) || 0;
}
export function getTotalPoints(contributionsStats, type) {
    var _a;
    return ((_a = contributionsStats === null || contributionsStats === void 0 ? void 0 : contributionsStats.find(function (cs) { return cs.type === type; })) === null || _a === void 0 ? void 0 : _a.score) || 0;
}
export function getGoal(contributionsStats, type) {
    var _a;
    return ((_a = contributionsStats === null || contributionsStats === void 0 ? void 0 : contributionsStats.find(function (cs) { return cs.type === type; })) === null || _a === void 0 ? void 0 : _a.goal) || 0;
}
export function getMedalValue(contributionsStats, type) {
    return {
        goal: getGoal(contributionsStats, type),
        totalPoints: getTotalPoints(contributionsStats, type),
        percent: getPercentage(contributionsStats, type),
    };
}
export function getDescription(medal) {
    return medal.totalPoints > 0 && medal.totalPoints >= medal.goal
        ? "" + medal.goal
        : medal.totalPoints + " / " + medal.goal;
}
