import TagManager from 'react-gtm-module';
export var photoAddedEvent = function (title) {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'location',
            action: 'photo_added',
            Type: title,
        },
    });
};
export var placeVisitedEvent = function (title) {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'location',
            action: 'visited',
            Type: title,
        },
    });
};
export var addCommentEvent = function (title) {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'location',
            action: 'commented',
            Type: title,
        },
    });
};
export var addFavoritesEvent = function (title) {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'location',
            action: 'added_to_favorites',
            Type: title,
        },
    });
};
export var removeFavoritesEvent = function (title) {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'location',
            action: 'removed_to_favorites',
            Type: title,
        },
    });
};
export var createPlaceEvent = function (title) {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'location',
            action: 'created',
            Type: title,
        },
    });
};
export var updatePlaceEvent = function (title) {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'location',
            action: 'updated',
            Type: title,
        },
    });
};
export var activateAccountEvent = function () {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'creation_compte',
            step: 'complete',
            method: 'Web',
        },
    });
};
export var loginByFacebookEvent = function () {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'creation_compte',
            step: 'complete',
            method: 'Facebook',
        },
    });
};
export var createAccountStartEvent = function () {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'creation_compte',
            step: 'start',
            method: 'Web',
        },
    });
};
export var createAccountCompleteEvent = function () {
    return TagManager.dataLayer({
        dataLayer: {
            event: 'creation_compte',
            step: 'submitted',
            method: 'Web',
        },
    });
};
