var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Divider } from 'antd';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var background = theme('mode', {
    light: rgba(colors.base.black, 0.1),
    dark: rgba(colors.base.white, 0.1),
});
export var DividerWrapper = styled(Divider)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: ", " solid ", ";\n  color: ", ";\n  margin: ", " 0;\n\n  &.ant-divider-vertical {\n    background: ", ";\n    border: 0;\n    display: flex;\n    height: calc(100% - ", ");\n    margin: 0 0.75rem;\n    max-width: ", ";\n    min-height: ", ";\n    min-width: ", ";\n  }\n\n  &.ant-divider-horizontal {\n    border-top: ", " solid ", ";\n  }\n"], ["\n  border-top: ", " solid ", ";\n  color: ", ";\n  margin: ", " 0;\n\n  &.ant-divider-vertical {\n    background: ", ";\n    border: 0;\n    display: flex;\n    height: calc(100% - ", ");\n    margin: 0 0.75rem;\n    max-width: ", ";\n    min-height: ", ";\n    min-width: ", ";\n  }\n\n  &.ant-divider-horizontal {\n    border-top: ", " solid ", ";\n  }\n"])), rem(2), background, background, rem(16), background, rem(32), rem(2), rem(16), rem(2), rem(1), background);
var templateObject_1;
