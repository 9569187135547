var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tabs } from 'antd';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors } from 'styles';
var inactiveTabColor = theme('mode', {
    light: colors.space.tint500,
    dark: colors.space.tint600,
});
var activeTabColor = theme('mode', {
    light: colors.space.tint700,
    dark: colors.base.white,
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
export var TabsWrapper = styled(Tabs)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n\n  .ant-tabs-nav {\n    margin: 0;\n\n    &:before {\n      border-bottom-color: ", " !important;\n    }\n  }\n\n  .ant-tabs-nav-list {\n    .ant-tabs-tab {\n      justify-content: center;\n      color: ", ";\n    }\n\n    .ant-tabs-tab-active {\n      color: ", ";\n    }\n\n    .ant-tabs-ink-bar {\n      background: ", ";\n      border-top-left-radius: ", ";\n      border-top-right-radius: ", ";\n      height: ", " !important;\n    }\n  }\n"], ["\n  ",
    ";\n\n  .ant-tabs-nav {\n    margin: 0;\n\n    &:before {\n      border-bottom-color: ", " !important;\n    }\n  }\n\n  .ant-tabs-nav-list {\n    .ant-tabs-tab {\n      justify-content: center;\n      color: ", ";\n    }\n\n    .ant-tabs-tab-active {\n      color: ", ";\n    }\n\n    .ant-tabs-ink-bar {\n      background: ", ";\n      border-top-left-radius: ", ";\n      border-top-right-radius: ", ";\n      height: ", " !important;\n    }\n  }\n"])), ifProp('fullSize', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      .ant-tabs-nav-more {\n        display: none;\n      }\n\n      .ant-tabs-nav-list {\n        width: 100%;\n\n        .ant-tabs-tab {\n          margin: 0;\n          width: 100%;\n        }\n      }\n    "], ["\n      .ant-tabs-nav-more {\n        display: none;\n      }\n\n      .ant-tabs-nav-list {\n        width: 100%;\n\n        .ant-tabs-tab {\n          margin: 0;\n          width: 100%;\n        }\n      }\n    "])))), borderColor, inactiveTabColor, activeTabColor, colors.base.secondary, rem(2), rem(2), rem(3));
export var TabPaneWrapper = styled(Tabs.TabPane)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3;
