var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var border = theme('mode', {
    light: colors.space.tint400,
    dark: colors.carbon.tint500,
});
export var AboutUsMenu = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-right: ", " solid ", ";\n  width: 20%;\n  min-width: ", ";\n"], ["\n  border-right: ", " solid ", ";\n  width: 20%;\n  min-width: ", ";\n"])), rem(1), border, rem(300));
export var AboutUsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  > div {\n    display: flex;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  > div {\n    display: flex;\n  }\n"])));
var templateObject_1, templateObject_2;
