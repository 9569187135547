import * as Yup from 'yup';

export const inviteUserTeamSchema = (formatMessage) =>
  Yup.object().shape({
    email: Yup.string()
      .email(
        formatMessage({ id: 'containers.challengeInviteUserTeam.emailFormat' }),
      )
      .required(
        formatMessage({
          id: 'containers.challengeInviteUserTeam.emailIsRequired',
        }),
      ),
  });

export const defaultProps = {
  email: '',
};

export default inviteUserTeamSchema;
