var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { breakpoints, colors } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint800,
});
var shadow = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.black, 0.2)),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0 0 0 ", " ", ";\n  "], ["\n    box-shadow: 0 0 0 ", " ", ";\n  "])), rem(4), rgba(colors.base.white, 0.2)),
});
export var PlaceDetailsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n\n  ", "\n"], ["\n  display: flex;\n\n  ",
    "\n"])), media.greaterThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    overflow: hidden;\n  "], ["\n    overflow: hidden;\n  "]))));
export var Sidebar = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: ", ";\n  position: relative;\n  width: 100%;\n\n  ", "\n\n  ", "\n"], ["\n  background-color: ", ";\n  position: relative;\n  width: 100%;\n\n  ",
    "\n\n  ",
    "\n"])), background, media.lessThan('medium')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex: 1;\n  "], ["\n    flex: 1;\n  "]))), media.greaterThan('medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    height: calc(100vh - ", ");\n    overflow-y: scroll;\n    width: ", ";\n  "], ["\n    height: calc(100vh - ", ");\n    overflow-y: scroll;\n    width: ", ";\n  "])), rem(80), breakpoints.medium));
export var MapContainerMobile = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 60vw;\n  justify-content: center;\n  max-height: ", ";\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 60vw;\n  justify-content: center;\n  max-height: ", ";\n"])), colors.space.tint200, rem(300));
export var MapContainerDesktop = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex: 1;\n  justify-content: center;\n\n  ", "\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex: 1;\n  justify-content: center;\n\n  ",
    "\n"])), colors.space.tint200, media.lessThan('medium')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "]))));
export var FloatButtonWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), media.greaterThan('medium')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    button {\n      right: calc(100vw - ", " + ", ")\n    }\n  "], ["\n    button {\n      right: calc(100vw - ", " + ", ")\n    }\n  "])), breakpoints.medium, rem(24)));
export var ReportButton = styled.button(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  align-items: center;\n  background: ", ";\n  border-radius: 50%;\n  border: ", " solid ", ";\n  cursor: pointer;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  outline: none;\n  overflow: hidden;\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  width: ", ";\n  z-index: 1;\n\n  &:after {\n    background: ", ";\n    border-radius: 50%;\n    content: '';\n    height: 70%;\n    left: 15%;\n    position: absolute;\n    top: 15%;\n    width: 70%;\n  }\n\n  > span {\n    display: flex;\n    z-index: 1;\n\n    svg {\n      flex-shrink: 0;\n    }\n  }\n\n  &:focus {\n    ", ";\n  }\n"], ["\n  align-items: center;\n  background: ", ";\n  border-radius: 50%;\n  border: ", " solid ", ";\n  cursor: pointer;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  outline: none;\n  overflow: hidden;\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  width: ", ";\n  z-index: 1;\n\n  &:after {\n    background: ", ";\n    border-radius: 50%;\n    content: '';\n    height: 70%;\n    left: 15%;\n    position: absolute;\n    top: 15%;\n    width: 70%;\n  }\n\n  > span {\n    display: flex;\n    z-index: 1;\n\n    svg {\n      flex-shrink: 0;\n    }\n  }\n\n  &:focus {\n    ", ";\n  }\n"])), colors.base.white, rem(1), colors.space.tint300, rem(24), rem(24), rem(28), rem(24), colors.base.black, shadow);
export var ButtonWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  z-index: 1;\n"], ["\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  z-index: 1;\n"])), rem(24), rem(204));
export var ModalButtonWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: ", ";\n"])), rem(26));
export var ModalMessage = styled.p(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: ", ";\n  white-space: pre-line;\n"], ["\n  font-size: ", ";\n  white-space: pre-line;\n"])), rem(16));
export var ReviewListWrapper = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), rem(60));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
