import produce from 'immer';
import { SUBFOOTER_LINKS_FAILURE, SUBFOOTER_LINKS_FIND_PLACES_IN_EUROPE_SUCCESS, SUBFOOTER_LINKS_FIND_PLACES_IN_FRANCE_SUCCESS, SUBFOOTER_LINKS_FIND_PLACES_IN_WORLD_SUCCESS, SUBFOOTER_LINKS_REQUEST, SUBFOOTER_LINKS_SEARCH_BY_TYPE_OF_PLACE_SUCCESS, } from './constants';
export var subFooterLinksInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    searchByTypeOfPlace: undefined,
    findPlacesInFrance: undefined,
    findPlacesInEurope: undefined,
    findPlacesInWorld: undefined,
};
var subFooterLinksReducer = produce(function (draft, action) {
    switch (action.type) {
        case SUBFOOTER_LINKS_REQUEST:
            draft.loading = true;
            break;
        case SUBFOOTER_LINKS_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case SUBFOOTER_LINKS_SEARCH_BY_TYPE_OF_PLACE_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.searchByTypeOfPlace = action.payload;
            break;
        case SUBFOOTER_LINKS_FIND_PLACES_IN_FRANCE_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.findPlacesInFrance = action.payload;
            break;
        case SUBFOOTER_LINKS_FIND_PLACES_IN_EUROPE_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.findPlacesInEurope = action.payload;
            break;
        case SUBFOOTER_LINKS_FIND_PLACES_IN_WORLD_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.findPlacesInWorld = action.payload;
            break;
        default:
    }
}, subFooterLinksInitialState);
export default subFooterLinksReducer;
