var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable @typescript-eslint/no-use-before-define */
import { emoticonThoughtful } from 'assets/images';
import { CardHeader, LoadingIndicator, TabPane, Tabs } from 'components';
import { makeSelectUser } from 'containers/Auth/selectors';
import makeMyProfile from 'containers/Profile/selectors/myProfileSelectors';
import { useAction, useSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchMonthUsers } from '../store/monthUsers/actions';
import makeMonthUsers from '../store/monthUsers/selectors';
import { fetchWeekUsers } from '../store/weekUsers/actions';
import makeWeekUsers from '../store/weekUsers/selectors';
import { Container, EmptyListContainer, TabPaneTitle } from './styles';
import TabMonth from './TabMonth';
import TabWeek from './TabWeek';
export var GlobalScoreboardCard = function () {
    var _a, _b;
    var _c = __read(useState(1), 2), weekPage = _c[0], setWeekPage = _c[1];
    var _d = __read(useState(1), 2), monthPage = _d[0], setMonthPage = _d[1];
    var PER_PAGE = 12;
    var profileData = useSelector(makeMyProfile());
    var loggedUser = useSelector(makeSelectUser());
    var weekUsers = useSelector(makeWeekUsers());
    var monthUsers = useSelector(makeMonthUsers());
    var fetchWeekUsersRequest = useAction(function (payload) {
        return fetchWeekUsers(payload);
    });
    var fetchMonthUsersRequest = useAction(function (payload) {
        return fetchMonthUsers(payload);
    });
    var isLoadingTabs = (weekUsers.loading && weekPage === 1) ||
        (monthUsers.loading && monthPage === 1);
    var loadWeekUsers = function () {
        var payload = {
            pagination: {
                page: weekPage,
                perPage: PER_PAGE,
            },
            period: 'weekly',
        };
        fetchWeekUsersRequest(payload);
    };
    var loadMonthUsers = function () {
        var payload = {
            pagination: {
                page: monthPage,
                perPage: PER_PAGE,
            },
            period: 'monthly',
        };
        fetchMonthUsersRequest(payload);
    };
    useEffect(function () {
        if (weekPage <= weekUsers.pages)
            loadWeekUsers();
    }, [weekPage]);
    useEffect(function () {
        if (monthPage <= monthUsers.pages)
            loadMonthUsers();
    }, [monthPage]);
    return (React.createElement(Container, null,
        React.createElement(CardHeader, { title: React.createElement(FormattedMessage, { id: "containers.globalScoreboard.headerTitle" }) }),
        isLoadingTabs ? (React.createElement(LoadingIndicator, null)) : (React.createElement(React.Fragment, null, ((_a = monthUsers === null || monthUsers === void 0 ? void 0 : monthUsers.items) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement(Tabs, { defaultActiveKey: "weekly" },
            ((_b = weekUsers === null || weekUsers === void 0 ? void 0 : weekUsers.items) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(TabPane, { tab: React.createElement(TabPaneTitle, null,
                    React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabWeek.title" })), key: "weekly" },
                React.createElement(TabWeek, { users: weekUsers.items, loggedUser: loggedUser, hasMore: weekPage <= weekUsers.pages, loading: weekUsers.loading, currentPage: weekPage, loadMore: function (page) { return setWeekPage(page); }, weeklyPoints: profileData.weeklyPoints }))),
            React.createElement(TabPane, { tab: React.createElement(TabPaneTitle, null,
                    React.createElement(FormattedMessage, { id: "containers.globalScoreboard.tabMonth.title" })), key: "monthly" },
                React.createElement(TabMonth, { users: monthUsers.items, loggedUser: loggedUser, hasMore: monthPage <= monthUsers.pages, loading: monthUsers.loading, currentPage: monthPage, loadMore: function (page) { return setMonthPage(page); }, monthlyPoints: profileData.monthlyPoints })))) : (React.createElement(EmptyListContainer, null,
            React.createElement("img", { src: emoticonThoughtful, alt: "emoticonThoughtful" }),
            React.createElement("br", null),
            React.createElement(FormattedMessage, { id: "containers.globalScoreboard.emptyList" })))))));
};
