var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { colors, fonts } from 'styles';
export var ChallengesHeader = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n  position: relative;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"], ["\n  padding: ", ";\n  position: relative;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"])), rem(20));
export var Hashtag = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), colors.base.white, rem(18), fonts.weight.semibold, rem(16));
export var Description = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n  max-width: 60%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-bottom: ", ";\n  max-width: 60%;\n"])), colors.base.white, rem(14), rem(16));
export var Video = styled.video(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  width: min(100%, ", ");\n  max-width: 80%;\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  width: min(100%, ", ");\n  max-width: 80%;\n"])), colors.base.success, rem(7), rem(576));
export var Background = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  position: absolute;\n  width: 100%;\n  top: 0;\n  left: 0;\n  z-index: -1;\n\n  height: min(", ", 35vw);\n\n  ", ";\n\n  ", ";\n"], ["\n  background-color: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  position: absolute;\n  width: 100%;\n  top: 0;\n  left: 0;\n  z-index: -1;\n\n  height: min(", ", 35vw);\n\n  ",
    ";\n\n  ",
    ";\n"])), colors.base.secondary, rem(20), rem(20), rem(260), media.lessThan('small')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    height: ", ";\n  "], ["\n    height: ", ";\n  "])), rem(130)), media.between('small', 'medium')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    height: min(", ", 30vw);\n  "], ["\n    height: min(", ", 30vw);\n  "])), rem(260)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
