import { SUBSCRIBERS_FAILURE, SUBSCRIBERS_REQUEST, SUBSCRIBERS_SUCCESS, SUBSCRIBERS_TOGGLE_USER_IS_SUBSCRIBED, } from './constants';
export var fetchSubscribers = function (payload) { return ({
    type: SUBSCRIBERS_REQUEST,
    payload: payload,
}); };
export var fetchSubscribersSuccess = function (payload) { return ({
    type: SUBSCRIBERS_SUCCESS,
    payload: payload,
}); };
export var fetchSubscribersError = function (error) { return ({
    type: SUBSCRIBERS_FAILURE,
    payload: error,
}); };
export var subscribersToggleUserIsSubscribed = function (payload) { return ({
    type: SUBSCRIBERS_TOGGLE_USER_IS_SUBSCRIBED,
    payload: payload,
}); };
