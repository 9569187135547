var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button as TargetButton } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var Target = styled(TargetButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  margin-left: ", ";\n  white-space: nowrap;\n  padding: 0 !important;\n  border-radius: 35% !important;\n"], ["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  margin-left: ", ";\n  white-space: nowrap;\n  padding: 0 !important;\n  border-radius: 35% !important;\n"])), rem(8));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n  font-weight: ", ";\n"])), colors.space.tint800, rem(16), fonts.weight.regular);
export var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  padding: ", " ", ";\n  transition: color 0.2s;\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  padding: ", " ", ";\n  transition: color 0.2s;\n\n  &:hover {\n    color: ", ";\n  }\n"])), colors.space.tint800, rem(16), fonts.weight.regular, rem(4), rem(8), colors.base.primary);
var templateObject_1, templateObject_2, templateObject_3;
