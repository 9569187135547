var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { AutoComplete as AutocompleteComponent } from 'components/AutoComplete';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import { colors } from 'styles';
var media = generateMedia({
    large: '1128px',
    medium: '768px',
});
export var SearchFieldContainer = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Autocomplete = styled(AutocompleteComponent)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n\n  .ant-input-prefix {\n    margin-left: ", ";\n  }\n\n  .ant-select-selection-placeholder {\n    color: ", ";\n    transition: all 0.2s;\n  }\n\n  .ant-input-affix-wrapper {\n    border-radius: ", ";\n    border: 0;\n    font-size: ", ";\n    margin-right: ", ";\n    padding: ", ";\n    z-index: 1000;\n\n    .ant-input {\n      align-items: center;\n      background: none;\n      border-radius: 0;\n      color: ", ";\n      font-size: ", ";\n      margin-left: ", ";\n      transition: all 0.2s;\n    }\n\n    &.ant-input-affix-wrapper-focused {\n      box-shadow: 0 0 0 ", " ", ";\n    }\n\n    ", ";\n\n    ", ";\n  }\n"], ["\n  width: 100%;\n\n  .ant-input-prefix {\n    margin-left: ", ";\n  }\n\n  .ant-select-selection-placeholder {\n    color: ", ";\n    transition: all 0.2s;\n  }\n\n  .ant-input-affix-wrapper {\n    border-radius: ", ";\n    border: 0;\n    font-size: ", ";\n    margin-right: ", ";\n    padding: ", ";\n    z-index: 1000;\n\n    .ant-input {\n      align-items: center;\n      background: none;\n      border-radius: 0;\n      color: ", ";\n      font-size: ", ";\n      margin-left: ", ";\n      transition: all 0.2s;\n    }\n\n    &.ant-input-affix-wrapper-focused {\n      box-shadow: 0 0 0 ", " ", ";\n    }\n\n    ",
    ";\n\n    ",
    ";\n  }\n"])), rem(4), colors.space.tint600, rem(10), rem(16), rem(10), rem(12), colors.space.tint900, rem(16), rem(6), rem(3), rgba(colors.base.info, 0.8), media.greaterThan('large')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      width: ", ";\n    "], ["\n      width: ", ";\n    "])), rem(390)), media.lessThan('medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      display: none;\n    "], ["\n      display: none;\n    "]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
