export default {
    components: {
        podium: {
            points: 'pts',
        },
        userReview: {
            datePattern: "MMMM d',' yyyy",
        },
        ellipsis: {
            more: 'Mehr',
            less: 'weniger',
        },
        loadingIndicator: {
            loading: 'Wird geladen...',
        },
        nav: {
            explore: 'Erforschen',
            challenge: 'Challenges',
            donation: 'Spenden',
            aboutUs: 'Über Jaccede',
            myProfile: 'Mein Profil',
            changeFontSize: 'Ändern Sie die Schriftgröße',
            darkMode: 'Ändern Sie den Kontrast',
            signIn: 'Einloggen',
            logout: 'Ausloggen',
            mailSystem: 'Messaging',
            homePage: 'Zuhause',
        },
        facebookButton: {
            continueWithFacebook: 'Mit Facebook einloggen',
        },
        usersProfilesField: {
            label: 'Profiltyp',
            select: 'Wähle eine Option',
            placeholder: 'Wählen Sie einen Profiltyp',
        },
        passwordField: {
            label: 'Passwort',
            placeholder: 'Geben Sie Ihr Passwort ein',
            showPassword: 'Passwort anzeigen',
            hidePassword: 'Passwort verbergen',
            forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
        },
        emailField: {
            label: 'Email',
            placeholder: ' Fügen Sie Ihre E-Mail ein',
        },
        searchField: {
            button: 'Suche',
            placeholder: 'Finden Sie zugängliche Orte',
        },
        profileMenu: {
            profile: 'Mein Profil',
            mailSystem: 'Messaging-System',
            logout: 'Ausloggen',
        },
        passwordScore: {
            tips: '{tipsHighlight} Das Passwort muss die folgenden Kriterien erfüllen :',
            tipsHighlight: 'Trick :',
            tipOne: '1 - mindestens ein Großbuchstabe',
            tipTwo: '2 - mindestens eine Ziffer',
            tipThree: '3 - mindestens 8 Zeichen',
            veryWeak: 'Sehr schwach',
            weak: 'Niedrig',
            fair: 'Weg',
            strong: 'Stark',
        },
        placesAutocomplete: {
            googlePlaceIdLabel: 'Meine Adresse',
            googlePlaceIdPlaceholder: 'Adresse',
        },
        aboutJaccede: {
            container: {
                definition: {
                    headline: 'Jaccedeurin[ʒaksɛdœʁ/øz]',
                    description: "\n            Name, Alter und Geschlecht - unwichtigJaccedeure sind Jaccede-Nutzer\n            oder \u2013Beitr\u00E4ger, also positiv eingestellte Menschen, die Hand in\n            Hand mit Gleichgesinnten auf der ganzen Welt zusammenarbeiten, um\n            den Jaccede-F\u00FChrer zu erg\u00E4nzen oder zu aktualisieren. So sorgen sie\n            daf\u00FCr, dass alle Zugang zu diesen Informationen haben. Au\u00DFerdem\n            wollen sie die \u00D6ffentlichkeit daf\u00FCr sensibilisieren, wie wichtig\n            Barrierefreiheit ist.",
                },
                headline: 'Was ist Jaccede?',
            },
            headline: 'Was ist Jaccede? ?',
            subtitle: 'In Hinsicht auf meine Mobilität, welcher Ort ist für mich barrierefrei?',
            firstParagraph: "\n        Jaccede ist ein interaktiver Guide (im Internet und als App auf dem\n        Handy), auf dem jedermann die Barrierefreiheit von \u00F6fftenlich zug\u00E4nglichen\n        Orten beschreiben kann. So sollen Menschen mit eingeschr\u00E4nkter Mobilit\u00E4t\n        genau die Einrichtungen finden k\u00F6nnen, die ihren Anforderungen an\n        Barrierefreiheit am besten gerecht werden.\n      ",
            secondParagraph: "\n        Eines der gr\u00F6\u00DFten Hindernisse f\u00FCr Menschen mit eingeschr\u00E4nkter Mobilit\u00E4t\n        ist der Mangel an Informationen zur Barrierefreiheit. Diese Informationen\n        \u00FCber Jaccede teilen hilft also unmittelbar auf gesellschaftliche\n        Rahmenbedingungen einzuwirken.\n      ",
            thirdParagraph: "\n        Au\u00DFerdem k\u00F6nnen mit Jaccede das ganze Jahr \u00FCber Events organisiert\n        werden \u2013 um die \u00D6ffentlichkeit zu sensibiliseren, um gemeinsam Infos f\u00FCr\n        die Plattform zu sammeln oder sich ganz einfach mit anderen \u201EJaccedeuren\u201C\n        zu treffen. Hunderte Treffen haben bereits stattgefunden, sowohl in\n        Frankreich als auch im Ausland!\n      ",
        },
        emailNotConfirmed: {
            message: 'Bitte bestätigen Sie Ihre E-Mail-Adresse',
        },
        subscribeButton: {
            subscribe: 'Abonnieren',
            unsubscribe: 'Abmelden',
        },
        subFooter: {
            searchByType: {
                title: 'Suche nach Ortstyp',
            },
            findPlacesInFrance: {
                title: 'Orte in Frankreich suchen',
            },
            findPlacesInEurope: {
                title: 'Orte in Europa suchen',
            },
            findPlacesInTheWorld: {
                title: 'Orte auf der Welt suchen',
            },
        },
        bottomMenu: {
            languages: {
                english: 'English (UK)',
                french: 'Français',
                spanish: 'Español',
                italian: 'Italiano',
                german: 'Deutsche',
            },
            submenu: {
                fontSize: 'Schriftgröße',
                rightHand: 'Rechte Hand',
                leftHand: 'Linke Hand',
                defaultLanguage: 'Standardsprache',
                darkMode: 'Hoher Kontrast',
            },
            challenge: 'Challenge',
            explore: 'Erforschen',
            donate: 'Spende',
            settings: 'die Einstellungen',
            menu: 'Speisekarte',
        },
        footer: {
            copyRight: '2020 Urheberrecht. Jaccede. Alle Rechte vorbehalten.',
            privacyTerms: 'Personenbezogene Daten · Allgemeine Geschäftsbedingungen',
            sitemap: 'Seitenverzeichnis',
        },
        addPlaceToMap: {
            button: 'Fügen Sie einen fehlenden Ort hinzu',
            altImage: 'Mann mit Frau im Rollstuhl',
        },
        notificationMenu: {
            title: 'Benachrichtigungen',
            empty: 'Keine Benachrichtigung',
        },
        keyboardMode: {
            tabButton: 'Inhalt anzeigen',
            escButton: 'Drücken Sie ESCAPE, um den Tastaturmodus zu schließen',
        },
        alertNewSite: {
            message: 'Welcome to the beta version of the new Jaccede platform! Do not hesitate to send us your',
            email: 'suggestions',
        },
    },
    containers: {
        app: {
            title: 'Jaccede',
            cookieConsentText: 'Jaccede verwendet Cookies, um Ihre Erfahrung auf der Website zu verbessern und gleichzeitig Ihre persönlichen Daten zu respektieren.',
            cookieConsentButtonOk: 'Ich nehme an',
            cookieConsentButtonDecline: 'Später',
        },
        signInEmail: {
            emailFormat: 'Falsches E-Mail-Format',
            emailRequired: 'E-Mail ist erforderlich',
            signInButton: 'Einloggen',
            passwordRequired: 'Passwort wird benötigt',
            invalidSignin: 'falsche Email oder Passwort',
        },
        signUpEmail: {
            header: 'oder mit einer E-Mail',
            emailLabel: 'Email',
            emailPlaceholder: 'Fügen Sie Ihre E-Mail ein',
            emailRequired: 'E-Mail ist erforderlich',
            pseudoLabel: 'Pseudonym',
            pseudoPlaceholder: 'Pseudonym',
            pseudoRequired: 'Spitzname ist erforderlich',
            acceptsNewsletterLabel: 'Ich möchte den monatlichen Newsletter erhalten',
            termsAndCoditions: 'Durch das Erstellen eines Kontos akzeptieren Sie die ',
            termsAndCoditionsLink: 'Nutzungsbedingungen',
            registrationButton: 'Anmeldung',
            profileIdRequired: 'Profiltyp ist erforderlich',
            googlePlaceIdRequired: 'Meine Adresse ist erforderlich',
            passwordRequired: 'Passwort wird benötigt',
            passwordStrengthRegex: 'Dieses Feld muss Folgendes enthalten: 1 Großbuchstaben, 1 Zahl und mindestens 8 Zeichen',
        },
        home: {
            lastPlacesUpdates: {
                title: 'Die letzten Orte wurden aktualisiert',
            },
            signUpNewsletter: {
                title: 'Abonniere unseren Newsletter',
                submitButton: 'Abonnieren',
                emailRequired: 'E-Mail ist erforderlich',
                optinSuccess: 'Ihre E-Mail wurde registriert',
                optinFailure: 'Beim Abonnieren unseres Newsletters ist leider ein Fehler aufgetreten. Vielen Dank, dass Sie es später versuchen.',
            },
            socialNetworks: 'Folge uns:',
        },
        contribute: {
            title: "\n        Tragen Sie zu\n        Jaccede bei!\n      ",
            collaborateParagraph: "\n        Mitglied zu werden ist der erste Weg, um Ihre Unterst\u00FCtzung f\u00FCr zu zeigen\n        Jaccede. Ihre Mitgliedschaft hilft, die Sache derer bei zu f\u00F6rdern\n        eingeschr\u00E4nkte Mobilit\u00E4t und bietet dem Verein moralische und\n        finanzielle Unterst\u00FCtzung.\n      ",
            collaborateAlt: 'Person im Rollstuhl',
            collaborateImage: 'Mach mit beim Abenteuer!',
            collaborateTitle: 'Treten Sie dem Verein bei',
            collaborateButtonText: 'Beitreten!',
            donateAlt: 'Person im Rollstuhl',
            donateImage: 'Helfen Sie uns, erfolgreich zu sein!',
            donateTitle: 'Spenden',
            donateButtonText: 'Spenden Sie!',
            donateParagraph: "\n        Ihr Interesse daran, zur Verbesserung des Lebens von Menschen mit\n        Mobilit\u00E4t beizutragen reduziert f\u00FChrte Sie nach Jaccede. Helfen Sie uns,\n        dieses Ziel zu erreichen und Ma\u00DFnahmen ergreifen, um die \u00D6ffentlichkeit\n        f\u00FCr die Vorteile der Zug\u00E4nglichkeit.\n      ",
        },
        homeHeader: {
            title: "\n        Finden Sie die Orte,\n        die f\u00FCr Sie zug\u00E4nglich sind\n      ",
            placeholder: 'Finden Sie zugängliche Orte',
            seeMore: 'Mehr sehen',
        },
        homeSearch: {
            button: 'Suche',
            placeholder: 'Finden Sie eine Einrichtung oder eine Adresse',
        },
        homeLearnMore: {
            title: "\n        Erfahren Sie\n        mehr \u00FCber uns\n      ",
            desktopSubtitle: 'Finden Sie heraus, wie Jaccede auf die Zugänglichkeit für alle hinarbeitet.',
            mobileSubtitle: 'Jaccede ist eine interaktive Plattform, auf der jeder Details zu öffentlich zugänglichen Orten hinzufügen kann.',
            videoAlt: 'Über uns Video Thumbnail',
            blockquoteTitle: 'Was ist Jaccede?',
            blockquoteParagraph: "\n        Beobachten Sie, wie Jaccede auf die Zug\u00E4nglichkeit f\u00FCr alle hinarbeitet.\n        von allen.\n      ",
        },
        homeBrowsePlaces: {
            title: "\n        Durchsuchen Sie\n        Standorte nach Kategorie\n      ",
            subtitle: "\n        Suchen Sie einen bestimmten Ort? \u00D6ffentliche Pl\u00E4tze und ihre Details zur\n        Barrierefreiheit k\u00F6nnen mit Jaccede nach Kategorien angezeigt werden.\n      ",
            button: 'Erkunde die Orte',
            dogAndOwnerAlt: 'Blindenhund mit seinem Besitzer in einem Park',
            wheelchairHandAlt: 'Person im Rollstuhl',
            guideDogsAlt: 'Blindenhunde an der Leine',
            peopleSmilingAlt: 'Leute, die sich unterhalten',
        },
        homeMostActive: {
            title: "\n        Die aktivsten\n        Mitwirkenden der Woche\n      ",
            viewAllRank: 'Alle Ranglisten anzeigen',
        },
        homeLatestNews: {
            title: 'Jaccede Nachrichten',
            subtitle: 'Verfolgen Sie die neuesten Nachrichten aus der Community und dem Jaccede-Verband, indem Sie in unserem Blog stöbern! ',
            buttonText: 'Blog anzeigen',
            note: 'nur auf französisch',
        },
        oAuth: {
            errorPageTitle: 'Ein Fehler ist aufgetreten',
            completeAccountTitle: 'Vervollständigen Sie Ihr Konto',
            successNotification: 'Sie sind erfolgreich verbunden!',
        },
        resendEmailActivation: {
            header: 'Aktivierungs-E-Mail erneut senden',
            submitButton: 'Erneut senden',
            emailRequired: 'E-Mail ist erforderlich',
            emailformat: 'Falsches E-Mail-Format',
        },
        profile: {
            achievements: {
                header: 'Erfolg',
            },
            user: {
                editMyProfile: {
                    mobile: 'Bearbeiten',
                    desktop: 'Mein Profil bearbeiten',
                },
                pointsInfo: {
                    criterion: '1 Kriterium einmal bearbeitet = 1 Punkt',
                    photo: '1 Foto pro Standort hinzugefügt = 4 Punkte',
                    comment: '1 Kommentar pro Ort = 2 Punkte',
                    place: '1 fehlender Ort hinzugefügt = 1 Punkt',
                },
                subscriptions: 'Abonnements',
                subscribers: 'Abonnenten',
                points: 'Punkte',
                sendMessage: 'Eine Nachricht schicken',
                readMore: 'Mehr',
                medal200: 'Abzeichen 200 Punkte',
                medal1000: '1000 Punkte Abzeichen',
                medal5000: '5000 Punkte Abzeichen',
                medal10000: '10.000 Punkte Abzeichen',
                medal25000: 'Abzeichen 25.000 Punkte',
                medalCriterion: 'Kriterienabzeichen',
                medalPhoto: 'Fotoabzeichen',
                medalComment: 'Feedback-Abzeichen',
                medalPlace: 'Abzeichen für fehlende Standorte',
            },
            activities: {
                header: 'Aktivität der letzten Wochen',
            },
            myActiveChallenges: {
                header: 'Meine aktive Herausforderung',
                challengesEmptyList: 'Der Benutzer nimmt momentan an keiner Herausforderung teil',
            },
            moreAbout: {
                header: 'Mehr erfahren',
                contributions: 'Beiträge',
                favorites: 'Favoriten',
                contributionEmptyList: 'Im Moment kein Beitrag',
                favoriteEmptyList: 'Noch keine Favoriten',
            },
            nextAchievements: {
                medals: {
                    medal200: {
                        title: '200 Abzeichenpunkte',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 200 Punkte verdienen',
                    },
                    medal1000: {
                        title: '1000 Abzeichenpunkte',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 1000 Punkte sammeln ',
                    },
                    medal5000: {
                        title: '5.000 Abzeichenpunkte',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 5.000 Punkte sammeln ',
                    },
                    medal10000: {
                        title: '10.000 Abzeichenpunkte',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 10.000 Punkte sammeln ',
                    },
                    medal25000: {
                        title: '25.000 Abzeichenpunkte',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 25.000 Punkte sammeln ',
                    },
                    medalCriterion: {
                        title: 'Kriterienabzeichen',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 5.000 Kriterien für die Barrierefreiheit der Website angeben',
                    },
                    medalPhoto: {
                        title: 'Fotoabzeichen',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 1000 Fotos von Orten hinzufügen',
                    },
                    medalComment: {
                        title: 'Feedback-Abzeichen',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 1000 Site-Kommentare abgeben',
                    },
                    medalPlace: {
                        title: 'Abzeichen für fehlende Standorte',
                        description: 'Um dieses Abzeichen zu erhalten, müssen Sie 500 Plätze hinzufügen, die auf der Jaccede-Website nicht vorhanden waren',
                    },
                },
                header: 'Nächster Erfolg',
            },
        },
        forgotPasswordPage: {
            emailLabel: 'Email',
            submitButton: 'Fordern Sie den Link an, um Ihr Passwort zurückzusetzen',
            notReset: 'Wenn Sie Ihr Passwort nicht zurücksetzen können,',
            contatUs: 'Kontaktiere uns.',
            emailFormat: 'Falsches E-Mail-Format',
            emailRequired: 'E-Mail ist erforderlich',
            verifyEmail: 'Anweisungen zum Zurücksetzen Ihres Passworts wurden an Sie gesendet.',
            success: 'Fertig !!',
            error: 'Fertig !!',
            passwordIsExpired: 'Der Link ist abgelaufen. Bitte erneut bewerben.',
        },
        changePasswordPage: {
            newPasswordLabel: 'Neues Kennwort',
            newPasswordRequired: 'Neues Passwort erforderlich',
            confirmPasswordRequired: 'Die Bestätigung eines neuen Passworts ist erforderlich',
            newPasswordStrengthRegex: 'Dieses Feld muss Folgendes enthalten: 1 Großbuchstaben, 1 Zahl und mindestens 8 Zeichen',
            confirmPasswordValidation: 'Die beiden neuen Passwörter müssen übereinstimmen',
            confirmPasswordLabel: 'Bestätigen Sie das neue Passwort',
            submitButton: 'Bearbeiten',
            success: 'Erfolg !!',
            successfully: 'Passwort wurde erfolgreich geändert!',
            error: 'Erreur !!',
            errorMessage: 'Entschuldigung, etwas ist schief gelaufen',
            checkingPasswordIsLoading: 'Wir überprüfen die Gültigkeit des Tokens',
            linkForgotPassword: 'Klicke hier',
        },
        subscribers: {
            subscriptions: 'Abonnements',
            subscribers: 'Abonnenten',
            loggedUserNoSubscribers: 'Sie haben keine Abonnenten!',
            loggedUserNoSubscriptions: 'Sie haben keine Abonnements! ',
            noSubscribers: 'Keine Abonnenten gefunden!',
            noSubscriptions: 'Kein Abonnement gefunden',
        },
        globalScoreboard: {
            tabWeek: {
                title: 'Wöchentlich',
                footerTitleWithPoints: 'Hier sind deine Punkte für die Woche',
                footerTitleWithoutPoints: 'Sie haben diese Woche keine Punkte verdient',
            },
            tabMonth: {
                title: 'Monatlich',
                footerTitleWithPoints: 'Hier sind Ihre Punkte des Monats',
                footerTitleWithoutPoints: 'Sie haben diesen Monat keine Punkte verdient',
            },
            tabAll: {
                title: 'Gesamt',
                footerTitleWithPoints: 'Hier sind deine Punkte',
                footerTitleWithoutPoints: 'Sie haben keine Punkte bekommen',
            },
            headerTitle: 'Allgemeine Einteilung',
            emptyList: 'Wir haben keine Ergebnisse für den aktuellen Monat. Was denkst du, um das zu ändern? Machen Sie die Welt jetzt zugänglicher!',
        },
        editMyProfile: {
            identification: {
                fieldDescription: 'Identifizierung',
                fieldInformation: 'Wichtige Informationen zu Ihrem Konto',
            },
            location: {
                fieldDescription: 'Ort',
                fieldInformation: 'Ich gebe meinen Standort an, um von relevanteren Informationen zu profitieren',
            },
            message: {
                fieldDescription: 'Botschaft',
                fieldInformation: 'In wenigen Worten eine Nachricht für alle Jaccedeurs!',
            },
            editOptions: {
                identification: 'Identifizierung',
                identificationMessage: 'Bearbeiten Sie Ihr öffentliches Profil, damit die Leute ein bisschen mehr über Sie wissen',
                name: 'NAME & AUCH',
                editPhoto: 'Foto bearbeiten',
                editPhotoMessage: 'Bearbeiten Sie Ihr Foto, das in der Jaccede-Community verwendet wird',
                profileType: 'Art des Profils',
                profileMessage: 'PROFILMELDUNG',
                contactInformation: 'Kontakt Informationen',
                email: 'EMAIL',
                telephone: 'TELEFON',
                password: 'PASSWORT',
                location: 'Stadt',
                myAddress: 'Stadt',
                editPhotoOverlay: 'Bearbeiten',
            },
            editPhoto: {
                uploadError: 'Ihr Foto konnte nicht hochgeladen werden',
                modalTitle: 'Bearbeiten Sie mein Foto',
                resolutionRule: 'Mindestauflösung von ',
                sizeRule: 'Größe kleiner oder gleich ',
                extensionsRule: 'Autorisierte Erweiterungen : ',
            },
            editName: {
                name: 'Vorname',
                title: 'Name & Pseudonym',
                lastName: 'Familienname, Nachname',
                username: 'Pseudonym',
                usernameRequiredMessage: 'Spitzname ist erforderlich',
                updateName: 'Ändern Sie den Namen und den Spitznamen',
            },
            editEmail: {
                title: 'Email',
                email: 'Email',
                emailRequiredMessage: 'E-Mail ist erforderlich',
                invalidEmailMessage: 'Falsches E-Mail-Format',
                updateEmail: 'E-Mail bearbeiten',
            },
            editTelephone: {
                title: 'Telefon',
                telephone: 'Telefon',
                updateTelephone: 'Telefon bearbeiten',
            },
            editProfileMessage: {
                title: 'Profilnachricht',
                profileMessage: 'Profilnachricht',
                maxSizeProfileMessage: 'Maximal 500 Zeichen',
                updateProfileMessage: 'Profilnachricht bearbeiten',
            },
            editProfileType: {
                title: 'Profiltyp',
                profileTypeRequiredMessage: 'Profiltyp ist erforderlich',
                updateProfileType: 'Profiltyp ändern',
            },
            editPassword: {
                title: 'Passwort',
                password: 'Neues Kennwort',
                confirmPassword: 'Neues Passwort bestätigen',
                passwordRequiredMessage: 'Neues Passwort erforderlich',
                confirmPasswordRequiredMessage: 'Bestätigung eines neuen Passworts ist erforderlich',
                passwordsNotMatching: 'Die beiden neuen Passwörter müssen passen',
                changePassword: 'Passwort ändern',
                passwordPolicy: 'Dieses Feld muss enthalten: 1 Großbuchstabe, 1 Ziffer und mindestens 8 Zeichen',
            },
            editLocation: {
                title: 'Meine Adresse',
                myAddress: 'Meine Adresse',
                acceptVisibility: 'Ich bin damit einverstanden, für die anderen Jaccedeurs sichtbar zu sein.',
                updateMyAddress: 'Meine Adresse ändern',
                shareLocalization: 'Ich bin damit einverstanden, für die anderen Jaccedeurs sichtbar zu sein.',
                invalidAddress: 'Ungültige Anschrift',
            },
            acceptTerms: {
                contact: 'Ich bin damit einverstanden, von den anderen Jaccedeurs kontaktiert zu werden.',
                newsletter: 'Ich möchte den monatlichen Newsletter erhalten',
                infoNewsletter: 'Ihre E-Mail wird nur verwendet, um Ihnen unseren monatlichen Newsletter sowie Informationen über die Aktivitäten von Jaccede zu senden. Sie können jederzeit den im Newsletter integrierten Abmeldelink verwenden.',
            },
            pageTitle: 'Mein Profil ändern',
            passwordUpdateSuccess: 'Das Passwort wurde erfolgreich geändert!',
            photoUploadSuccess: 'Foto erfolgreich heruntergeladen!',
            defaultAvatarUpdatedSuccess: 'Foto erfolgreich heruntergeladen!',
            photoDeleteSuccess: 'Foto erfolgreich gelöscht!',
            nameUpdateSuccess: 'Name und Vorname wurden erfolgreich geändert!',
            profileMessageUpdateSuccess: 'Profil-Nachricht wurde erfolgreich geändert!',
            profileTypeUpdateSuccess: 'Ihr Profiltyp wurde erfolgreich geändert!',
            telephoneUpdateSuccess: 'Telefonnummer wurde erfolgreich geändert!',
            locationUpdateSuccess: 'Ihre Adresse wurde erfolgreich geändert!',
            termsUpdateSuccess: 'Die Begriffe wurden erfolgreich geändert!',
            success: 'Erfolgreicher Erfolg',
            error: 'Fehler',
        },
        placeDetails: {
            report: {
                title: 'Ein Foto als Spam melden',
                label: 'Einen Kommentar hinzufügen',
                placeholder: 'Kommentar',
                button: 'Weiter',
                required: 'Die Nachricht ist erforderlich.',
                reportPhotoButtonTitle: 'Report photo',
            },
            reported: {
                title: 'Foto gekennzeichnet',
                message: 'Dieses Foto wurde als unpassend gekennzeichnet. /n Danke. Wir werden Ihre Kommentare so bald wie möglich berücksichtigen. nn Ihre Wachsamkeit.',
                button: 'Schließen',
            },
            reportPhoto: {
                title: 'Als Spam-Foto melden.',
                label: 'Einen Kommentar hinzufügen',
                placeholder: 'Einen Kommentar abgeben',
                button: 'Weiter',
                required: 'Die Nachricht ist erforderlich..',
            },
            reportedPhoto: {
                title: 'Foto gekennzeichnet',
                alreadyReported: 'Dieses Foto wurde bereits gemeldet.',
                message: 'Dieses Foto wurde als unpassend gekennzeichnet./n Danke. Wir werden Ihre Kommentare so bald wie möglich berücksichtigen. /n Ihre Wachsamkeit.',
                button: 'Schließen',
            },
            suggestChanges: 'Schließen',
            saveChangesButton: 'Änderung vorschlagen',
            evaluateTitle: 'Bewertung und Bewertung',
            evaluateTagline: 'Sammeln Sie Punkte, indem Sie die Orte notieren',
            addReviewButton: 'Einen Kommentar hinzufügen',
            ratings: 'Ergebnis',
            addPhotos: 'Fotos hinzufügen',
            addMorePhotos: 'Weitere Fotos hinzufügen',
            reviews: 'Stellungnahme',
            moreReviews: 'Mehr Stellungnahmen',
            yourReviews: 'Ihr Kommentar',
            lastChange: 'Letzte Änderung durch',
            lastChange2: 'der',
            searchPlacesAround: 'Orte in der Nähe suchen',
            reportProblemTitle: 'Melden Sie dieses Standortprofil',
            reportedProblemTitle: 'Der Ort wurde gemeldet.',
            reportedProblemMessage: 'Danke. Wir werden Ihre Kommentare so bald wie möglich berücksichtigen.',
            reportedProblemEndMessage: 'Vielen Dank für Ihre Wachsamkeit.',
            reportedButton: 'Schließen',
            reportReviewTitle: 'Kommentar als Spam melden',
            reportedReviewTitle: 'Der Kommentar wurde gemeldet.',
            error: 'Fehler',
            success: 'Viel Glück!!',
            closed: 'Endgültig geschlossen',
            suggestModification: 'Eine Änderung auf Google maps vorschlagen',
        },
        placePhotos: {
            report: {
                title: 'Als Spam-Foto melden.',
                label: 'Einen Kommentar hinzufügen',
                placeholder: 'Einen Kommentar abgeben',
                button: 'Weiter',
                required: 'Die Nachricht ist erforderlich.',
            },
            reported: {
                title: 'Foto gekennzeichnet',
                alreadyReported: 'Dieses Foto wurde bereits gemeldet.',
                message: 'Dieses Foto wurde als unpassend gekennzeichnet./n Danke. Wir werden Ihre Kommentare so bald wie möglich berücksichtigen. /n Ihre Wachsamkeit.',
                button: 'Fermer',
            },
            header: 'Bilder von diesem Ort',
            addPhoto: 'Foto herunterladen',
            successTitle: 'Erfolgreicher Erfolg',
            photoSuccess: 'Foto mit Erfolg hinzugefügt!',
            validationTitle: 'Ungültige Datei',
            validationDescription: 'Ihr Foto konnte nicht hochgeladen werden. Die Datei muss eine Auflösung von mindestens 1080x1080 Pixel haben. Die Datei muss kleiner als 20 MB sein. Die erlaubten Erweiterungen sind: PNG, GIF, JPEG, JPG.',
        },
        explorePlaces: {
            inputPlaceholder: 'Barrierefreie Orte finden',
        },
        filterPlaces: {
            desktopBar: {
                buttons: {
                    apply: 'Gespeicherte Filter anwenden',
                    category: 'Kategorie auswählen',
                    terms: 'Ajouter des termes au filtre',
                    spaces: 'Nicht nähere Orte',
                    save: 'Sichern',
                },
                spaces: {
                    title: 'Nicht nähere Orte anzeigen',
                    tagline: 'Vestibulum ornare tincidunt orci, non consequat nisl gravida vel.Nulla ut varius ante.',
                },
            },
            termsSelector: {
                title: 'Accessibilités',
                inputPlaceholder: 'Tippen Sie hier, um Begriffe hinzuzufügen',
                showPlacesSwitch: 'Orte mit allen ausgewählten Begriffen anzeigen',
            },
            category: {
                placeholder: 'Schreiben Sie hier',
                suggestedCategoriesLabel: 'Empfohlene Kategorien',
                selectedCategoryLabel: 'Wählen Sie eine Kategorie',
            },
            categorySelector: {
                label: 'Kategorien',
            },
            filterBar: {
                editButton: 'Filter bearbeiten',
                more: 'Mehr',
            },
            footer: {
                clear: 'Filter löschen',
                applyFilters: 'Filter anwenden',
            },
            header: {
                title: 'Filter',
            },
            placeWithoutDetailsSwitch: {
                title: 'Nicht nähere Orte',
            },
        },
        reviews: {
            headerTitle: 'Alle Stellungnahmen',
            emptyReviews: 'Dieser Ort hat noch keine Kommentare.',
            addReviewConflict: 'Dieser Kommentar gibt es bereits',
        },
        editPlace: {
            mainPageTitle: 'Änderungen vorschlagen',
            category: 'Kategorie',
            categoryOfPlace: 'Ortskategorie',
            entrance: 'Einlass',
            access: 'Zugang',
            doorOpeningSystem: 'Türöffnen',
            ninetyCmAndAbove: '90 cm und mehr',
            indoors: 'Innern',
            servicesAndFacilities: 'Dienstleistungen und Ausrüstungen',
            roomsTile: 'Saal (s)',
            rooms: 'Saal',
            outside: 'Äußerer',
            disabledParkingSpaces: '"Behindertenparkplatz"',
            pavements: 'Gehwege',
            selectACategory: 'Wählen Sie eine Kategorie',
            categoryRequired: 'Die Kategorie ist erforderlich.',
            successfulFeedback: 'Erfolgreich verändert!',
            pointsFeedback: 'Sie haben {Punkte} Punkte gewonnen.',
            accessibilityTitle1: 'Ausführliche Zugänglichkeit',
            accessibilityTitle2: 'durch',
            accessibilityTitle2Singular: 'Person',
            accessibilityTitle2Plural: 'Personen',
            noAccessibility: 'Dieser Ort enthält noch keine Informationen über seine Zugänglichkeit.',
            success: 'Ein Erfolg!',
            photoAdded: 'Foto hinzugefügt.',
            failure: 'Misserfolg',
            addMorePhotos: 'Weitere Fotos hinzufügen',
        },
        challenges: {
            secretChallenge: {
                text: 'Geben Sie Ihren Geheimcode ein!',
                description: 'Um eine private Herausforderung zu entsperren, müssen Sie den Geheimcode eingeben, der Ihnen übergeben wurde..',
                button: 'Geht zur Challenge',
                required: 'Der Code ist erforderlich.',
                validation: '- Hoppla! :( Es scheint, dass Ihr Code ungültig ist, bitte überprüfen Sie es noch einmal.',
                max: 'Der Code muss 6 Zeichen enthalten',
            },
            header: {
                hashtag: '#JACCEDECHALLENGE2020',
                description: 'Lorem Ipsum ist einfach Dummy-Text der Druck- und Satzindustrie. ',
            },
            publicChallengeTitle: 'Öffentliche Herausforderungen',
        },
        challengesDetail: {
            selectTeam: 'Wählen Sie ein Team unten für die Teilnahme',
            newTeamSection: 'Ein neues Team erstellen und sich anschließen',
            createNewTeamButton: 'Ein neues Team einrichten',
            challengeStartsOn: 'Die Herausforderung beginnt am',
            challengeEndsOn: 'wenn ich die',
            membersScoreBoard: 'Rangfolge der Mitglieder',
            teamScoreboard: 'Platzierung der Teams',
            emptyTeams: 'Wir haben noch keine Mannschaften',
        },
        createNewTeam: {
            label: 'Wählen Sie den Namen Ihres Teams :',
            placeholder: 'Geben Sie den Namen Ihres Teams ein.',
            cancelButton: 'Annullieren',
            button: 'Nein, weiter',
            nameIsRequired: 'Der Name ist erforderlich',
        },
        challengesMembersTeam: {
            joinTeamSection: {
                title: 'Ich will teil dieses Teams sein.',
                buttonText: 'Dieser Mannschaft beitreten',
            },
            placesAroundMeSection: {
                title: 'Orte erkunden',
            },
            memberHeader: {
                inviteButtonText: 'Ein neues Mitglied einladen',
            },
            title: 'Mitglieder des Teams',
        },
        challengeInviteUserTeam: {
            label: 'Email :',
            placeholder: 'Email',
            cancelButton: 'Annullieren',
            button: 'Nein, weiter',
            emailIsRequired: 'E-Mail ist erforderlich',
            emailFormat: 'Format der falschen E-Mail',
            inviteSuccess: 'Eine E-Mail wurde gesendet.',
        },
    },
    pages: {
        globalScoreboard: {
            title: 'Allgemeines Ranking',
            description: 'Allgemeine Klassifizierung Seite der Beschreibung von helmet',
            showByPeriod: 'Nach Periode anzeigen',
        },
        explorePlacesPage: {
            newPlaceModal: {
                addressTitle: 'Geben Sie die Adresse des Ortes ein',
                newPlaceTitle: 'Einen neuen Ort hinzufügen',
                suggestionTitle: 'Suchen Sie nach einer dieser Adressen?',
                nameLabel: 'Name des Betriebs',
                nameValidation: 'Der Name ist erforderlich',
                addressLabel: 'Anschrift',
                addressValidation: 'Die Anschrift ist erforderlich',
                categoryLabel: 'Kategorie',
                categoryPlaceholder: 'Auswählen',
                categoryValidation: 'Die Kategorie ist erforderlich',
                telephoneLabel: 'Telefon',
                telephoneValidation: 'Die Telefonnummer ist erforderlich',
                websiteLabel: 'Website-Web',
                websiteValidation: 'Die Website ist erforderlich',
                continueButton: 'Weiter',
                validateButton: 'Validieren',
                suggestionButton: 'Nein, weiter',
                validateWebsite: 'Geben Sie einen gültigen Link ein oder lassen Sie das Feld leer.',
            },
            addPlaceNotFound: {
                title: 'Haben Sie den Ort, nach dem Sie gesucht haben, nicht gefunden?',
                tagline: 'Addieren Sie die Adresse zur Karte, sammeln Sie Punkte und helfen Sie, die Welt zugänglicher zu machen!',
            },
            addPlaceNoResults: {
                title: '- Hoppla! Wir haben den Ort, nach dem Sie gesucht haben, nicht gefunden.',
                tagline: 'Wenn Sie einen zugänglichen Ort kennen, der nicht auf unserer App ist, fügen Sie ihn jetzt hinzu und helfen Sie den Menschen auf der ganzen Welt! ',
            },
            chooseLocation: {
                text: 'Wählen Sie einen Ort auf der Karte',
                button: 'Wählen Sie auf der Karte',
            },
            collaboration: {
                message: 'Warum arbeiten Sie nicht auch zusammen und helfen Sie der Welt, für alle zugänglich zu sein!',
                highlight: 'Wir arbeiten auf der ganzen Welt zusammen!',
            },
            title: 'Den Ort erkunden',
            description: 'Erkunden Sie die Orte Beschreibung für helmet',
            sidebarTitle: 'Barrierefreiheit in und um die Region',
            recentContributors: 'Die jüngsten Beitragszahler (e)',
        },
        filterPlacesPage: {
            title: 'Ort filtern',
            description: 'Filtern Sie die Beschreibungsorte für helmet',
        },
        reviewsPage: {
            title: 'Alle Kommentare',
            description: 'Alle Kommentare Beschreibung für helmet',
        },
        notFoundPage: {
            title: 'Seite nicht gefunden',
            description: 'Seite nicht gefunden Beschreibung für helmet',
        },
        homePage: {
            title: 'Startseite',
            description: 'Beschreibungsseite fürhelmet',
        },
        signInPage: {
            title: 'Verbindungsanschluss',
            description: 'Verbindung und Kontoerstellung',
            byEmail: 'oder mit einer E-Mail',
            dontHaveAccount: 'Haben Sie kein Konto?',
            signUpButton: 'Ein Konto erstellen',
        },
        forgotPasswordPage: {
            title: 'Passwort zurücksetzen',
            description: 'Das Passwort zurücksetzen Beschreibung für helmet',
        },
        changePasswordPage: {
            title: 'Modifier mon mot de passe',
            description: 'Ändern Sie mein Passwort Beschreibung für helmet',
        },
        profilePage: {
            title: 'Profil',
            description: 'Mein Profil',
        },
        signUpPage: {
            title: 'Ein Konto erstellen',
            description: 'Ein neues Konto speichern',
            header: 'Ein Konto erstellen',
            continueWithFacebook: 'Verbindung mit Facebook',
            alreadyAccount: 'Haben Sie schon ein Konto?',
            signIn: 'Verbindungsanschluss',
        },
        confirmAccountPage: {
            title: 'Bestätigen Sie Ihr Konto',
            success: 'Perfekt. !',
            content: 'Nous vous avons envoyé des instructions sur la procédure à suivre pour terminer la création de votre compte',
        },
        aboutPage: {
            title: 'Übrigens.',
            description: 'Was die Organisation Jaccede betrifft, so ist es unser Ziel, die Menschen in einem ortsansässigen Bereich zu erreichen.',
            listGroup: {
                inBrief: {
                    header: 'Kurzum:',
                    item1: 'Jaccede, was ist das?',
                    item2: 'Jaccede, für wen ist das?',
                    item3: 'Für die kleine Geschichte',
                    item4: 'Preise und Auszeichnungen',
                },
                organization: {
                    header: 'Der Verein',
                    item1: 'Unsere Vision und unsere Missionen',
                    item2: 'Unser Vorstand',
                    item3: 'Unser ständiges Team',
                    item4: 'Unsere freiwilligen Helfer',
                    item5: 'Dem Verein beitreten',
                    item6: 'Eine Spende machen',
                },
                getInvolved: {
                    header: 'Wie kann ich dazu beitragen?',
                    item1: 'Online',
                    item2: 'Vor Ort',
                    item3: 'Indem Sie Ihre Fähigkeiten einbringen',
                },
                partners: {
                    header: 'Partner',
                    item1: 'Unsere Partner',
                    item2: 'Nehmen Sie Ihr Unternehmen mit ein',
                    item3: 'Mobilisieren Sie Ihre Vereinigung oder Schule',
                    item4: 'Nehmen Sie als Gemeinschaft teil',
                    item5: 'Beziehen Sie Ihre Einrichtung',
                },
            },
        },
        resendEmailActivationPage: {
            title: 'Die Aktivierungs-E-Mail zurückgeben',
            description: 'Die Aktivierungs-E-Mail zurückgeben',
        },
        subscribersProfilePage: {
            title: 'Abonnements & Abonnenten',
            description: 'Den Benutzern folgen',
        },
        editProfilePage: {
            title: 'Profil bearbeiten',
            description: 'Ihr Profil ändern',
        },
        editFieldProfilePage: {
            title: 'Profil bearbeiten',
            description: 'Ihr Profil ändern',
        },
        userPage: {
            title: 'Benutzer',
            description: 'Beitragszahler',
        },
        accountActivatedPage: {
            title: 'Konto aktiviert',
            description: 'Konto aktiviert',
            paragraph: 'Ihr Konto ist aktiviert.',
        },
        newsletterPage: {
            title: 'Newsletter',
            description: 'Seite des Newsletters',
        },
        editPlacePage: {
            title: 'Ausführliche Zugänglichkeit',
            description: 'Beschreibung der Barrierefreiheit',
        },
        placeDetailsPage: {
            title: 'Details des Ortes',
            description: 'Details des Ortes',
        },
        newReviewPage: {
            title: 'Einen Kommentar hinzufügen',
            description: 'Einen Kommentar hinzufügen',
            postReviewButton: 'Veröffentlichung',
            commentRequiredError: 'Ein Kommentar ist erforderlich',
            commentSizeError: 'Ihr Kommentar muss zwischen 15 und 2000 Zeichen haben.',
            commentSuccess: 'Danke, Ihr Kommentar wurde berücksichtigt.',
        },
        placePhotosPage: {
            title: 'Bilder vom Ort',
            description: 'Bilder vom Ort',
        },
        reviewDetailPage: {
            title: 'Stellungnahme',
            description: 'Einzelheiten des Kommentars',
            notFound: 'Kommentar nicht gefunden',
            defaultError: 'Ein Fehler ist aufgetreten, als wir diesen Kommentar gefunden haben.',
        },
        categoryListViewPage: {
            title: 'Name der Region',
            description: 'Dies ist die Seite CategoryListViewPage',
        },
        categoryMapViewPage: {
            title: 'Name der Region',
            description: 'Dies ist die Seite CategoryMapViewPage',
            seeInListButton: 'Siehe Liste',
        },
        challengePage: {
            title: 'Seite der Herausforderung',
            description: 'Challenge-Seite Beschreibung für helmet',
        },
        challengeDetailPage: {
            title: 'Details der Herausforderung',
            description: 'Details der Challenge-Beschreibung für helmet',
        },
        challengeJoinTeamPage: {
            warning: 'Nach der Bestätigung können Sie das Team nicht mehr wechseln.',
            buttonText: 'Dieser Mannschaft beitreten',
        },
        challengesMembersTeam: {
            title: 'Mitglieder des Teams',
            description: 'Mitglieder des Teams Beschreibung für helmet',
        },
        challengeInviteUserTeamPage: {
            title: 'Einen neuen Teilnehmer einladen',
            subtitle: 'Bitte geben Sie die E-Mail-Adresse eines Kollegen oder Freundes ein, um ihn zur Teilnahme an dieser Herausforderung einzuladen.',
        },
        challengeCreateNewTeamPage: {
            title: 'Die Inschrift ist unumkehrbar',
            subtitle: 'Vous êtes sur le point de créer et de rejoindere une équipe pour participer à ce challenge',
            warningAdvice: 'Sie können die Herausforderung nicht aufgeben oder Ihr Team verlassen.',
        },
        mailSystemPage: {
            title: 'System de ́email',
            description: 'Dies ist die Seite Mail System',
        },
    },
};
