import produce from 'immer';
import { CHALLENGE_GET_TEAM_FAILURE, CHALLENGE_GET_TEAM_REQUEST, CHALLENGE_GET_TEAM_SUCCESS, } from './constants';
export var teamDetailInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    item: null,
};
export var teamDetailReducer = produce(function (draft, action) {
    switch (action.type) {
        case CHALLENGE_GET_TEAM_REQUEST:
            draft.loading = true;
            break;
        case CHALLENGE_GET_TEAM_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case CHALLENGE_GET_TEAM_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.item = action.payload;
            break;
        default:
    }
}, teamDetailInitialState);
