var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var AddPlaceMap = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M8.8126 4C7.98973 4 7.32266 4.68172 7.32266 5.52267V8.1971C7.32266 9.44774 6.33062 10.4616 5.10687 10.4616H2.48993C1.66706 10.4616 1 11.1433 1 11.9843C1 12.8252 1.66706 13.5069 2.48993 13.5069H5.10687C6.33062 13.5069 7.32266 14.5208 7.32266 15.7714V18.4458C7.32266 19.2868 7.98973 19.9685 8.8126 19.9685C9.63546 19.9685 10.3025 19.2868 10.3025 18.4458V15.7714C10.3025 14.5208 11.2946 13.5069 12.5183 13.5069H15.1353C15.9581 13.5069 16.6252 12.8252 16.6252 11.9843C16.6252 11.1433 15.9581 10.4616 15.1353 10.4616H12.5183C11.2946 10.4616 10.3025 9.44774 10.3025 8.1971V5.52267C10.3025 4.68172 9.63546 4 8.8126 4Z", fill: color }),
        React.createElement("path", { d: "M20.5403 6.03826C20.38 5.93184 20.1755 5.92346 20.0074 6.0164C19.8393 6.10934 19.7344 6.28873 19.734 6.48399V30.3069C19.7335 30.4875 19.8227 30.656 19.9708 30.7546L26.2334 34.9135C26.3938 35.0203 26.5986 35.0288 26.767 34.9357C26.9354 34.8425 27.0403 34.6626 27.0403 34.4671V10.6595C27.0408 10.4785 26.9514 10.3097 26.8029 10.2111L20.5403 6.03826Z", fill: color }),
        React.createElement("path", { d: "M15.1353 8.27519C16.1107 8.27519 16.9962 8.66844 17.6484 9.30828V6.51397C17.6489 6.31608 17.5421 6.13418 17.3709 6.04152C17.1997 5.94885 16.9923 5.96068 16.8323 6.07224L13.6828 8.27519H15.1353Z", fill: color }),
        React.createElement("path", { d: "M8.7744 32.6015V22.1547C8.78712 22.1548 8.79985 22.1549 8.8126 22.1549C10.817 22.1549 12.4419 20.4943 12.4419 18.4458V15.7714C12.4419 15.7283 12.4761 15.6933 12.5183 15.6933H15.1353C16.1107 15.6933 16.9962 15.3001 17.6484 14.6602V30.3269C17.6488 30.5075 17.5597 30.676 17.4116 30.7746L11.9227 34.4424C11.8959 34.4604 11.8685 34.4764 11.8405 34.4917C11.1924 34.843 10.4113 34.8209 9.78316 34.4337C9.15502 34.0465 8.77204 33.3509 8.7744 32.6015Z", fill: color }),
        React.createElement("path", { d: "M34.9357 6.45667C35.5822 6.10167 36.3646 6.12399 36.9907 6.5153C37.6259 6.91834 38.0086 7.63114 37.9999 8.39483V28.1162C38.0011 28.8905 37.5914 29.6046 36.9293 29.9818L29.9446 34.8761C29.7845 34.9881 29.5769 35.0001 29.4055 34.9074C29.2341 34.8147 29.1271 34.6325 29.1278 34.4344V10.6182C29.1274 10.4376 29.2165 10.2691 29.3646 10.1704L34.8542 6.50598C34.8804 6.48788 34.9076 6.47142 34.9357 6.45667Z", fill: color })));
});
