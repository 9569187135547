import { PageWrapper } from 'components';
import { makeSelectUser } from 'containers/Auth/selectors';
import { GlobalScoreboardCard } from 'containers/GlobalScoreboard';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { Achievements, Activities, MobileBackground, MoreAbout, MyChallenge, NextAchievements, UserProfile, } from 'containers/Profile';
import { fetchMyChallengesRequest, fetchMyContributionsRequest, fetchMyFavoritesRequest, } from 'containers/Profile/actions';
import { fetchMyProfileRequest } from 'containers/Profile/actions/profileActions';
import { makeMyContributions } from 'containers/Profile/selectors/contributionsSelectors';
import { makeMyFavorites } from 'containers/Profile/selectors/favoritesSelectors';
import { makeMyChallenges } from 'containers/Profile/selectors/myChallengesSelectors';
import { makeSelectMyProfileContributionStats, makeSelectMyProfileLoading, makeSelectMyProfilePointsSerie, makeSelectMyProfileQuickBadges, makeSelectMyProfileTotalFollowed, makeSelectMyProfileTotalFollowers, makeSelectMyProfileTotalPoints, } from 'containers/Profile/selectors/myProfileSelectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useAction, useSelector } from 'hooks';
import replace from 'lodash/replace';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Container, Content, RightContent } from './styles';
var ProfilePage = function () {
    var formatMessage = useIntl().formatMessage;
    var locale = useSelector(makeSelectLocale());
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var fetchMyProfile = useAction(fetchMyProfileRequest);
    var myProfileLoading = useSelector(makeSelectMyProfileLoading());
    var followingCount = useSelector(makeSelectMyProfileTotalFollowed());
    var followersCount = useSelector(makeSelectMyProfileTotalFollowers());
    var user = useSelector(makeSelectUser());
    var series = useSelector(makeSelectMyProfilePointsSerie());
    var contributionsStats = useSelector(makeSelectMyProfileContributionStats());
    var totalPoints = useSelector(makeSelectMyProfileTotalPoints());
    var quickBadges = useSelector(makeSelectMyProfileQuickBadges());
    /** More About - Contributions and Favorites */
    var contributions = useSelector(makeMyContributions());
    var favorites = useSelector(makeMyFavorites());
    var challenges = useSelector(makeMyChallenges());
    var fetchMyFavorites = useAction(function (payload) {
        return fetchMyFavoritesRequest(payload);
    });
    var fetchMyContributions = useAction(function (payload) {
        return fetchMyContributionsRequest(payload);
    });
    var fetchMyChallenges = useAction(function (payload) {
        return fetchMyChallengesRequest(payload);
    });
    var loadFavorites = function (pagination) {
        return fetchMyFavorites({ pagination: pagination });
    };
    var loadContributions = function (pagination) {
        return fetchMyContributions({ pagination: pagination });
    };
    var loadMyChallenges = function (pagination) {
        return fetchMyChallenges({ pagination: pagination });
    };
    var userSlug = (user === null || user === void 0 ? void 0 : user.slug) || '';
    var subscriptionsLink = "" + replace(paths.subscribersProfilePage, ':slug', userSlug);
    useEffect(function () {
        fetchMyProfile();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, formatMessage({ id: 'pages.profilePage.title' })),
            React.createElement("meta", { name: "description", content: formatMessage({ id: 'pages.profilePage.description' }) }),
            React.createElement("meta", { property: "og:title", content: formatMessage({ id: 'pages.profilePage.title' }) }),
            React.createElement("meta", { property: "og:site_name", content: formatMessage({ id: 'containers.app.title' }) }),
            React.createElement("meta", { property: "og:description", content: formatMessage({ id: 'pages.profilePage.description' }) }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:secure_url", content: "" }),
            React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
            React.createElement("meta", { property: "og:image:width", content: "800" }),
            React.createElement("meta", { property: "og:image:height", content: "600" }),
            React.createElement("html", { lang: locale })),
        React.createElement(PageWrapper, null,
            React.createElement(Container, null,
                React.createElement(Content, null,
                    React.createElement(Achievements, { loadingUser: myProfileLoading },
                        (isMobile || isTabletOrMobile) && React.createElement(MobileBackground, null),
                        React.createElement(UserProfile, { user: user, followingCount: followingCount, followersCount: followersCount, subscriptionsLink: subscriptionsLink + "?tab=subscriptions", subscribersLink: subscriptionsLink + "?tab=subscribers", contributionsStats: contributionsStats, badges: quickBadges, totalPoints: totalPoints || 0, showEditMyProfileButton: true, loading: myProfileLoading }),
                        !(isMobile || isTabletOrMobile) && (React.createElement(NextAchievements, { totalPoints: totalPoints || 0, contributionsStats: contributionsStats })),
                        React.createElement(Activities, { series: series })),
                    React.createElement(MyChallenge, { perPage: isMobile || isTabletOrMobile ? 4 : 10, challenges: challenges, loadMyChallenges: loadMyChallenges }),
                    React.createElement(MoreAbout, { perPage: isMobile || isTabletOrMobile ? 4 : 10, contributions: contributions, favorites: favorites, loadFavorites: loadFavorites, loadContributions: loadContributions })),
                React.createElement(RightContent, null,
                    React.createElement(GlobalScoreboardCard, null))))));
};
export default ProfilePage;
