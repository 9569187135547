import { selectLanguageProviderDomain } from 'containers/LanguageProvider/selectors';
import { format } from 'date-fns';
import { de, enIN, es, fr, it } from 'date-fns/locale';
import { store } from 'store';
export var formatWithLocal = function (date, formatStr) {
    // @ts-ignore
    var locale = selectLanguageProviderDomain(store.getState()).locale;
    var locales = { enIN: enIN, fr: fr, de: de, it: it, es: es };
    return format(date, formatStr, {
        locale: locales[locale],
    });
};
