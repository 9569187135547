var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { breakpoints, colors } from 'styles';
var titleColor = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
var descriptionColor = theme('mode', {
    light: colors.space.tint700,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", " 0 ", ";\n"], ["\n  padding: ", " 0 ", ";\n"])), rem(80), rem(20));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: ", ";\n  margin: 0 auto;\n"], ["\n  max-width: ", ";\n  margin: 0 auto;\n"])), breakpoints.medium);
export var HeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  padding: 0 ", " 0;\n"], ["\n  color: ", ";\n  padding: 0 ", " 0;\n"])), titleColor, rem(16));
export var Title = styled.h4(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  margin-bottom: ", ";\n"])), titleColor, rem(10));
export var Description = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), descriptionColor, rem(14));
export var FieldsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), rem(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
