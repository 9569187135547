var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { avatarImage1 } from 'assets/images';
import { Avatar, Button } from 'components';
import { makeSelectUser } from 'containers/Auth/selectors';
import { useSelector } from 'hooks';
import { Alert } from 'icons/mono';
import { rem } from 'polished';
import React, { memo, useRef, useState } from 'react';
import { useOutsideClick } from 'utils/hooks';
import { Content, MessageBoxWrapper, MessageDate, MessageText, ReportButton, UserName, Wrapper, } from './styles';
export var MessageBox = memo(function (_a) {
    var data = _a.data;
    var _b, _c;
    var ref = useRef(null);
    var _d = __read(useState(false), 2), isOpen = _d[0], setIsOpen = _d[1];
    useOutsideClick(ref, function () { return setIsOpen(false); });
    var user = useSelector(makeSelectUser());
    return (React.createElement(MessageBoxWrapper, { sender: data.me || data.sourceId === (user === null || user === void 0 ? void 0 : user.id) }, data.me || data.sourceId === (user === null || user === void 0 ? void 0 : user.id) ? (React.createElement(Content, null,
        React.createElement(MessageText, null, data.body),
        React.createElement(MessageDate, null, "7 days ago"))) : (React.createElement(Wrapper, null,
        React.createElement(Avatar, { size: "mini", src: ((_b = data.source.avatar) === null || _b === void 0 ? void 0 : _b.url_thumb) || ((_c = data.source.avatar) === null || _c === void 0 ? void 0 : _c.urlThumb) ||
                avatarImage1, alt: data.source.pseudo || data.source.slug }),
        React.createElement(Content, null,
            React.createElement(UserName, null, data.source.pseudo || data.source.slug),
            React.createElement(MessageText, { role: "button", onClick: function () { return setIsOpen(!isOpen); }, ref: ref },
                isOpen && (React.createElement(ReportButton, { message: "Report spam" },
                    React.createElement(Button, { variant: "link", shape: "circle", 
                        // eslint-disable-next-line no-alert
                        onClick: function () { return alert('report'); }, icon: React.createElement(Alert, { size: rem(22) }) }))),
                data.body),
            React.createElement(MessageDate, null, "7 days ago"))))));
});
