var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LoadingIndicator } from 'components/LoadingIndicator';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
var PrivateRoute = function (_a) {
    var path = _a.path, Component = _a.component, exact = _a.exact, isAuthenticated = _a.isAuthenticated, redirectPath = _a.redirectPath, isLoading = _a.isLoading;
    return isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(Route, { path: path, exact: exact, render: function (props) {
            return isAuthenticated ? (React.createElement(Component, __assign({}, props))) : (React.createElement(Redirect, { to: { pathname: redirectPath, state: { from: props.location } } }));
        } }));
};
export default PrivateRoute;
