import { MEMBERS_SCOREBOARD_FAILURE, MEMBERS_SCOREBOARD_REQUEST, MEMBERS_SCOREBOARD_SUCCESS, } from './constants';
export var fetchMembersScoreBoardOnChallengeRequest = function (payload) { return ({
    type: MEMBERS_SCOREBOARD_REQUEST,
    payload: payload,
}); };
export var fetchMembersScoreBoardOnChallengeFailure = function (payload) { return ({
    type: MEMBERS_SCOREBOARD_FAILURE,
    payload: payload,
}); };
export var setMembersScoreBoardOnChallengeSuccess = function (payload) { return ({
    type: MEMBERS_SCOREBOARD_SUCCESS,
    payload: payload,
}); };
