var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { darken, rem, shade, tint } from 'polished';
import styled from 'styled-components';
import { colors } from 'styles';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-modal,\n  .ant-modal-body {\n    line-height: inherit !important;\n  }\n"], ["\n  .ant-modal,\n  .ant-modal-body {\n    line-height: inherit !important;\n  }\n"])));
export var RulesContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", " 0 ", " 0;\n  text-align: center;\n"], ["\n  padding: ", " 0 ", " 0;\n  text-align: center;\n"])), rem(20), rem(16));
export var UploadButtonContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-bottom: ", ";\n"])), rem(30));
export var ActionButtonsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n\n  button {\n    flex: 1;\n  }\n\n  button:first-of-type {\n    margin-right: ", ";\n  }\n\n  button:last-of-type {\n    margin-left: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n\n  button {\n    flex: 1;\n  }\n\n  button:first-of-type {\n    margin-right: ", ";\n  }\n\n  button:last-of-type {\n    margin-left: ", ";\n  }\n"])), rem(8), rem(8));
export var AvatarContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: ", ";\n  margin: 0 auto;\n  position: relative;\n  width: ", ";\n\n  img {\n    object-fit: cover;\n  }\n"], ["\n  height: ", ";\n  margin: 0 auto;\n  position: relative;\n  width: ", ";\n\n  img {\n    object-fit: cover;\n  }\n"])), rem(99), rem(97));
export var RemovePhoto = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  border-radius: 50%;\n  border-style: solid;\n  border-width: 1px;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  position: absolute;\n  right: -", ";\n  top: -", ";\n  transition: all 0.2s ease;\n  width: ", ";\n  z-index: 10;\n\n  background-color: ", ";\n  border-color: ", ";\n\n  &:hover:not(:disabled) {\n    background-color: ", ";\n  }\n\n  &:active:not(:disabled) {\n    background-color: ", ";\n  }\n"], ["\n  align-items: center;\n  border-radius: 50%;\n  border-style: solid;\n  border-width: 1px;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  position: absolute;\n  right: -", ";\n  top: -", ";\n  transition: all 0.2s ease;\n  width: ", ";\n  z-index: 10;\n\n  background-color: ", ";\n  border-color: ", ";\n\n  &:hover:not(:disabled) {\n    background-color: ", ";\n  }\n\n  &:active:not(:disabled) {\n    background-color: ", ";\n  }\n"])), colors.base.white, rem(32), rem(8), rem(8), rem(32), colors.base.danger, shade(0.2, colors.base.danger), tint(0.2, colors.base.danger), darken(0.05, colors.base.danger));
export var PhotoError = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n"], ["\n  color: ", ";\n  text-align: center;\n"])), colors.base.danger);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
