import { guideDogs, peopleSmiling, wheelchairPeople } from 'assets/images';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { PhotoMosaicContainer } from './styles';
export var MobilePhotoMosaic = memo(function () {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(PhotoMosaicContainer, null,
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("img", { src: peopleSmiling, alt: formatMessage({
                        id: 'containers.homeBrowsePlaces.peopleSmilingAlt',
                    }) })),
            React.createElement("div", null,
                React.createElement("img", { src: guideDogs, alt: formatMessage({
                        id: 'containers.homeBrowsePlaces.guideDogsAlt',
                    }) }))),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("img", { src: wheelchairPeople, alt: formatMessage({
                        id: 'containers.homeBrowsePlaces.wheelchairHandAlt',
                    }) })))));
});
