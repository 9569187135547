var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Input } from 'antd';
import { Logo } from 'assets/svg';
import { Divider, NotificationMenu, ProfileMenu, Search as SearchField, } from 'components';
import { makeSelectFont, makeSelectUseKeyboard, } from 'containers/App/selectors';
import { makeSelectIsLoggedIn } from 'containers/Auth/selectors';
import { makeSelectorFilterState } from 'containers/FilterPlaces/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import { Search } from 'icons/mono/Search';
import join from 'lodash/join';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { memo, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { DarkModeSwitcher } from './DarkModeSwitcher';
import { LanguageSwitcher } from './LanguageSwitcher';
import { HomeLink, MenuItem, NavbarContainer, Navigation, SearchFieldWrapper, UserMenu, UserSignIn, } from './styles';
import { ToggleFontSize } from './ToggleFontSize';
export var Navbar = memo(function (_a) {
    var _b = _a.hasSearchField, hasSearchField = _b === void 0 ? true : _b, _c = _a.isTransparent, isTransparent = _c === void 0 ? false : _c, onAutocompleteSelect = _a.onAutocompleteSelect, hasAlert = _a.hasAlert;
    var formatMessage = useIntl().formatMessage;
    var isLoggedIn = useSelector(makeSelectIsLoggedIn());
    var keyboard = useSelector(makeSelectUseKeyboard());
    var location = useLocation();
    var history = useHistory();
    var fontSize = useSelector(makeSelectFont());
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var _d = __read(useState(false), 2), hasFocus = _d[0], setHasFocus = _d[1];
    var filterState = useSelector(makeSelectorFilterState());
    var handleFocus = function () {
        setHasFocus(true);
    };
    var handleBlur = function () {
        setHasFocus(false);
    };
    var _e = __read(useState(false), 2), isScroll = _e[0], setScroll = _e[1];
    var getAccessibilityFiltersAsString = function (filters) {
        return join(map(filters, 'criterionId'), ',');
    };
    var getFilters = function () {
        var _a;
        var category;
        var accessibility;
        if (filterState.selectedCategory) {
            category = filterState.selectedCategory.id;
        }
        if ((_a = filterState.selectedAccessibilityFilters) === null || _a === void 0 ? void 0 : _a.length) {
            accessibility = getAccessibilityFiltersAsString(filterState.selectedAccessibilityFilters);
        }
        var booleanFilter = filterState.booleanFilter ? 1 : 0;
        var placeWithoutDetails = filterState.placeWithoutDetails
            ? 1
            : 0;
        return { category: category, accessibility: accessibility, booleanFilter: booleanFilter, placeWithoutDetails: placeWithoutDetails };
    };
    var handleAutocompleteSelect = function (_a, place) {
        var latitude = _a.latitude, longitude = _a.longitude;
        if (place === null || place === void 0 ? void 0 : place.isplace) {
            history.push(paths.placeRedirectPage.replace(':placeId', place.id));
            return;
        }
        if (onAutocompleteSelect)
            onAutocompleteSelect({ latitude: latitude, longitude: longitude });
        if (!location.pathname.startsWith(paths.explore)) {
            var _b = getFilters(), category = _b.category, accessibility = _b.accessibility, booleanFilter = _b.booleanFilter, google = _b.placeWithoutDetails;
            var url = paths.explore + "?lat=" + latitude + "&lng=" + longitude + "&boolean_filter=" + booleanFilter + "&google=" + google;
            if (category) {
                url = url + "&category=" + category;
            }
            if (accessibility) {
                url = url + "&accessibility=" + accessibility;
            }
            history.push(url);
        }
    };
    useEffect(function () {
        var navbar = document.getElementById('navbar');
        var scrollCallback = window.addEventListener('scroll', function () {
            if (!!navbar && window.pageYOffset > navbar.offsetTop) {
                setScroll(true);
            }
            else {
                setScroll(false);
            }
        });
        return function () {
            window.removeEventListener('scroll', scrollCallback);
        };
    }, []);
    return (React.createElement(NavbarContainer, { id: "navbar", isTransparent: isTransparent, isScroll: isScroll, isKeyboardMode: keyboard, hasAlert: hasAlert },
        React.createElement(HomeLink, { to: paths.homePage, tabIndex: 0, isKeyboardMode: keyboard },
            React.createElement(Logo, { title: "Jaccede" })),
        !(isMobile || isTabletOrMobile) && hasSearchField && (React.createElement(SearchFieldWrapper, { hasFocus: hasFocus, fontSize: fontSize === 'twentyFour', tabIndex: 0 },
            React.createElement(SearchField, { onSelect: handleAutocompleteSelect },
                React.createElement(Input, { onFocus: handleFocus, onBlur: handleBlur, placeholder: formatMessage({
                        id: 'components.searchField.placeholder',
                    }), prefix: React.createElement(Search, { size: rem(16) }) })))),
        React.createElement(Navigation, { isKeyboardMode: keyboard },
            React.createElement(MenuItem, { tabIndex: 0, to: {
                    pathname: paths.explore,
                    state: { from: location.pathname },
                }, exact: true }, formatMessage({ id: 'components.nav.explore' })),
            React.createElement(MenuItem, { tabIndex: 0, to: {
                    pathname: paths.challengePage,
                    state: { from: location.pathname },
                }, exact: true }, formatMessage({ id: 'components.nav.challenge' })),
            React.createElement(MenuItem, { tabIndex: 0, to: {
                    pathname: paths.faireUnDon,
                    state: { from: location.pathname },
                }, exact: true }, formatMessage({ id: 'components.nav.donation' })),
            React.createElement(MenuItem, { tabIndex: 0, to: {
                    pathname: paths.aboutPageIndex,
                    state: { from: location.pathname },
                }, exact: true }, formatMessage({ id: 'components.nav.aboutUs' })),
            React.createElement(Divider, { type: "vertical", style: { marginRight: rem(4), marginLeft: rem(4) }, "aria-hidden": "true" }),
            React.createElement(LanguageSwitcher, null),
            React.createElement(DarkModeSwitcher, null),
            React.createElement(ToggleFontSize, null),
            React.createElement(Divider, { type: "vertical", style: { marginRight: rem(4), marginLeft: rem(4) }, "aria-hidden": "true" })),
        (isLoggedIn && (React.createElement(UserMenu, null,
            React.createElement(NotificationMenu, null),
            React.createElement(ProfileMenu, null)))) || (React.createElement(UserSignIn, null,
            React.createElement(MenuItem, { isKeyboardMode: keyboard, to: {
                    pathname: paths.signInPage,
                    state: { from: location.pathname },
                }, exact: true }, formatMessage({ id: 'components.nav.signIn' }))))));
});
