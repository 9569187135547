import { Button, Spin } from 'components';
import { makeSelectLoading, makeSelectLocation, } from 'containers/Map/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { Eye } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { redirectWindow } from 'utils/browser';
import { useSelector } from 'utils/hooks';
import { Container } from './styles';
export var SearchPlacesAround = function () {
    var formatMessage = useIntl().formatMessage;
    var loading = useSelector(makeSelectLoading());
    var location = useSelector(makeSelectLocation());
    return (React.createElement(Container, null,
        React.createElement(Spin, { spinning: loading },
            React.createElement(Button, { shape: "pill", size: "small", icon: React.createElement(Eye, { size: rem(24) }), onClick: function () {
                    return redirectWindow(paths.explore + "?lat=" + (location === null || location === void 0 ? void 0 : location.lat) + "&lng=" + (location === null || location === void 0 ? void 0 : location.lng));
                } }, formatMessage({
                id: 'containers.placeDetails.searchPlacesAround',
            })))));
};
