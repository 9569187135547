var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { flagFrance, flagGermany, flagItaly, flagSpain, flagUnitedKingdom, } from 'assets/images';
import React from 'react';
export var FlagIcon = function (_a) {
    var flag = _a.flag, text = _a.text, props = __rest(_a, ["flag", "text"]);
    var image = '';
    switch (flag) {
        case 'fr':
            image = flagFrance;
            break;
        case 'de':
            image = flagGermany;
            break;
        case 'it':
            image = flagItaly;
            break;
        case 'es':
            image = flagSpain;
            break;
        case 'en':
            image = flagUnitedKingdom;
            break;
        default:
            break;
    }
    return React.createElement("img", __assign({ src: image, alt: text }, props));
};
