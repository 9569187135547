import { defineMessages } from 'react-intl';
export var scope = 'containers.editMyProfile.editTelephone';
var messages = defineMessages({
    title: { id: scope + ".title" },
    telephone: { id: scope + ".telephone" },
    updateTelephone: { id: scope + ".updateTelephone" },
    fieldDescription: {
        id: 'containers.editMyProfile.identification.fieldDescription',
    },
    fieldInformation: {
        id: 'containers.editMyProfile.identification.fieldInformation',
    },
});
export default messages;
