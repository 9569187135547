var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { myProfile as MyProfileAPI } from 'api';
import * as api from 'api';
import { makeSelectUser } from 'containers/Auth/selectors';
import { useGeolocation, useSelector } from 'hooks';
import { ArrowBack, Close, Filter, Search } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { colors } from 'styles';
import { v4 as uuid } from 'uuid';
import { paths } from '../../RoutesProvider/routes';
import { AddPlaceToMap } from '../AddPlaceToMap';
import { AutocompleteContainer, Content, Footer, Input, LeftIconButton, RightIconButton, SearchBarWrapper, SuggestionsWrapper, } from './styles';
import { SuggestionItem } from './SuggestionItem';
export var Autocomplete = function (_a) {
    var onSelect = _a.onSelect, onFilter = _a.onFilter, onFocus = _a.onFocus, onBlur = _a.onBlur, height = _a.height;
    var formatMessage = useIntl().formatMessage;
    var user = useSelector(makeSelectUser());
    var _b = __read(useState(false), 2), hasFocus = _b[0], setHasFocus = _b[1];
    var _c = __read(useState(-1), 2), focusedItem = _c[0], setFocusedItem = _c[1];
    var _d = __read(useState(''), 2), inputValue = _d[0], setInputValue = _d[1];
    var _e = __read(useState(true), 2), emptyQuery = _e[0], setEmptyQuery = _e[1];
    var _f = __read(useState([]), 2), suggestions = _f[0], setSuggestions = _f[1];
    var _g = __read(useState(), 2), searchDelayTimer = _g[0], setSearchDelayTimer = _g[1];
    var geolocation = useGeolocation();
    var history = useHistory();
    var inputRef = useRef(null);
    var placeNoResults = suggestions.length === 0 && inputValue.length > 2;
    var getUserPosition = function () {
        var latitude = geolocation.latitude, longitude = geolocation.longitude;
        if (latitude && longitude)
            return { latitude: latitude, longitude: longitude };
        return undefined;
    };
    var handleBack = function () {
        setHasFocus(false);
        setSuggestions([]);
    };
    var handleClearText = function () {
        setInputValue('');
        setSuggestions([]);
    };
    var handleSearchButton = function () {
        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    };
    var getPosition = function (googlePlaceID) { return __awaiter(void 0, void 0, Promise, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, MyProfileAPI.getReverseGpid(googlePlaceID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, {
                            latitude: Number(response.data.lat),
                            longitude: Number(response.data.lng),
                        }];
                case 2:
                    error_1 = _a.sent();
                    return [2 /*return*/, {
                            latitude: 0,
                            longitude: 0,
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSelect = function (suggestion) { return __awaiter(void 0, void 0, void 0, function () {
        var placePosition;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInputValue(suggestion === null || suggestion === void 0 ? void 0 : suggestion.name);
                    setHasFocus(false);
                    setSuggestions([]);
                    if (inputRef.current !== null) {
                        inputRef.current.blur();
                    }
                    if (suggestion === null || suggestion === void 0 ? void 0 : suggestion.isPlace) {
                        history.push(paths.placeRedirectPage.replace(':placeId', suggestion.id));
                        return [2 /*return*/];
                    }
                    if (!onSelect) return [3 /*break*/, 2];
                    return [4 /*yield*/, getPosition(suggestion === null || suggestion === void 0 ? void 0 : suggestion.id)];
                case 1:
                    placePosition = _a.sent();
                    onSelect(placePosition, suggestion === null || suggestion === void 0 ? void 0 : suggestion.id);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleKeyDown = function (e) {
        var KEY_DOWN = 40;
        var KEY_UP = 38;
        var KEY_ENTER = 13;
        switch (e.keyCode) {
            case KEY_DOWN:
                setFocusedItem(function (prevState) {
                    var next = prevState + 1;
                    return next > (suggestions === null || suggestions === void 0 ? void 0 : suggestions.length) - 1 ? 0 : next;
                });
                break;
            case KEY_UP:
                setFocusedItem(function (prevState) {
                    var next = prevState - 1;
                    return next < 0 ? (suggestions === null || suggestions === void 0 ? void 0 : suggestions.length) - 1 : next;
                });
                break;
            case KEY_ENTER:
                if (focusedItem >= 0 && focusedItem <= (suggestions === null || suggestions === void 0 ? void 0 : suggestions.length) - 1) {
                    var suggestion = suggestions[focusedItem];
                    handleSelect(suggestion);
                }
                break;
            default:
                break;
        }
    };
    var search = function (query) {
        var latLng = getUserPosition();
        var params = __assign({ token: query, 
            // eslint-disable-next-line @typescript-eslint/camelcase
            session_token: (user === null || user === void 0 ? void 0 : user.sessionToken) || uuid() }, latLng);
        api.places
            .search(params)
            .then(function (response) {
            setSuggestions(map(response.data, function (p) { return ({
                id: p.gpid,
                value: p.name,
                name: p.name,
                fullAddress: p.fullAddress,
                isPlace: p.isPlace,
            }); }));
        })
            .catch(function (_a) {
            var response = _a.response;
            console.warn(response);
        });
    };
    var handleChange = function (event) {
        clearTimeout(searchDelayTimer);
        var value = event.target.value;
        setInputValue(value);
        if (!value) {
            setEmptyQuery(true);
            setSuggestions([]);
            return;
        }
        setEmptyQuery(false);
        if (value.length >= 3) {
            setSearchDelayTimer(setTimeout(function () {
                search(value);
            }, 700));
        }
    };
    var handleFocus = function () {
        setFocusedItem(-1);
        setHasFocus(true);
        search(inputValue);
    };
    var renderSuggestions = function () {
        return map(suggestions, function (suggestion, index) { return (React.createElement(SuggestionItem, { key: suggestion.id, title: suggestion.name, subtitle: suggestion.fullAddress, hasFocus: focusedItem === index, onClick: function () {
                setFocusedItem(index);
                handleSelect(suggestion);
            } })); });
    };
    useEffect(function () {
        if (hasFocus && onFocus)
            onFocus();
        else if (!hasFocus && onBlur)
            onBlur();
    }, [hasFocus]);
    return (React.createElement(AutocompleteContainer, { hasFocus: hasFocus },
        React.createElement(SearchBarWrapper, { hasFocus: hasFocus },
            hasFocus ? (React.createElement(LeftIconButton, { variant: "link", onClick: handleBack },
                React.createElement(ArrowBack, { size: rem(18) }))) : (React.createElement(LeftIconButton, { variant: "link", onClick: onFilter },
                React.createElement(Filter, { size: rem(22), color: colors.space.tint600 }))),
            React.createElement(Input, { ref: inputRef, onChange: handleChange, onFocus: handleFocus, onKeyDown: handleKeyDown, value: inputValue, placeholder: formatMessage({
                    id: 'containers.explorePlaces.inputPlaceholder',
                }) }),
            hasFocus && inputValue.length > 0 ? (React.createElement(RightIconButton, { variant: "link", onClick: handleClearText },
                React.createElement(Close, { size: rem(18), color: colors.space.tint750 }))) : (React.createElement(RightIconButton, { variant: "link", onClick: handleSearchButton },
                React.createElement(Search, { size: rem(18), color: colors.space.tint750 })))),
        React.createElement(Content, { height: height },
            React.createElement(SuggestionsWrapper, null, emptyQuery ? '' : renderSuggestions()),
            hasFocus && (React.createElement(Footer, { placeNotFound: placeNoResults }, inputValue.length > 2 && (React.createElement(AddPlaceToMap, { style: placeNoResults ? { borderTop: 0, paddingTop: rem(16) } : {}, title: formatMessage({
                    id: "pages.explorePlacesPage." + (placeNoResults ? 'addPlaceNoResults' : 'addPlaceNotFound') + ".title",
                }), tagline: formatMessage({
                    id: "pages.explorePlacesPage." + (placeNoResults ? 'addPlaceNoResults' : 'addPlaceNotFound') + ".tagline",
                }) })))))));
};
