import { makeSelectIsLoggedIn, makeSelectUserEmail, makeSelectUserEmailStatus, } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import messages from './messages';
import { StyledAlert } from './styles';
var EmailNotConfirmed = function () {
    var isLoggedIn = useSelector(makeSelectIsLoggedIn());
    var location = useLocation();
    var emailStatus = useSelector(makeSelectUserEmailStatus());
    var email = useSelector(makeSelectUserEmail());
    var formatMessage = useIntl().formatMessage;
    if (isLoggedIn && emailStatus !== 'OK') {
        return (React.createElement(StyledAlert, { message: React.createElement(React.Fragment, null,
                formatMessage(messages.message),
                ' ',
                React.createElement(Link, { to: {
                        pathname: paths.resendEmailActivationPage,
                        state: { from: location.pathname },
                    } }, email)) }));
    }
    return null;
};
export default memo(EmailNotConfirmed);
