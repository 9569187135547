var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable indent */
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var containerTheme = theme('mode', {
    light: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    border-top: 1px solid ", ";\n  "], ["\n    background: ", ";\n    border-top: 1px solid ", ";\n  "])), colors.base.white, colors.space.tint400),
    dark: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: ", ";\n    border-top: 1px solid ", ";\n  "], ["\n    background: ", ";\n    border-top: 1px solid ", ";\n  "])), colors.carbon.tint700, colors.carbon.tint800),
});
var buttonTheme = theme('mode', {
    light: colors.space.tint800,
    dark: colors.base.white,
});
var menuTheme = theme('mode', {
    light: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background: ", ";\n    border-left: 1px solid ", ";\n  "], ["\n    background: ", ";\n    border-left: 1px solid ", ";\n  "])), colors.space.tint200, colors.space.tint400),
    dark: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background: ", ";\n    border-left: 1px solid ", ";\n  "], ["\n    background: ", ";\n    border-left: 1px solid ", ";\n  "])), colors.carbon.tint700, colors.carbon.tint800),
});
export var BottomMenuContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  bottom: 0;\n  display: flex;\n  left: 0;\n  position: fixed;\n  right: 0;\n  z-index: 2000;\n\n  ", "\n"], ["\n  ", ";\n  bottom: 0;\n  display: flex;\n  left: 0;\n  position: fixed;\n  right: 0;\n  z-index: 2000;\n\n  ",
    "\n"])), containerTheme, ifProp('isRtl', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      flex-direction: row-reverse;\n    "], ["\n      flex-direction: row-reverse;\n    "])))));
export var Wrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var Button = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n  font-weight: ", ";\n  height: calc(", " + env(safe-area-inset-bottom, 0));\n  justify-content: center;\n  min-width: 64px;\n  padding-bottom: env(safe-area-inset-bottom, 0);\n  width: 20vw;\n\n  &:nth-last-of-type(1) {\n    ", ";\n  }\n"], ["\n  align-items: center;\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n  font-weight: ", ";\n  height: calc(", " + env(safe-area-inset-bottom, 0));\n  justify-content: center;\n  min-width: 64px;\n  padding-bottom: env(safe-area-inset-bottom, 0);\n  width: 20vw;\n\n  &:nth-last-of-type(1) {\n    ", ";\n  }\n"])), buttonTheme, rem(11), fonts.weight.semibold, rem(80), menuTheme);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
