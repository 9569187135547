var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchSubFooterLinksRequest } from 'components/SubFooter/actions';
import { fetchChallengeDetailRequest } from 'containers/ChallengeDetail/store/challengeBySlug/actions';
import { fetchTeamsOnChallengeRequest } from 'containers/ChallengeDetail/store/teamsOnChallenge/actions';
import { fetchMembersOnTeamRequest } from 'containers/ChallengeMembersTeam/store/getMembersOfTeam/actions';
import { fetchTeamRequest } from 'containers/ChallengeMembersTeam/store/getTeamOnChallenge/actions';
import { fetchPublicChallengesRequest } from 'containers/Challenges/store/publicChallenges/actions';
import { fetchHomeNewsRequest } from 'containers/Home/store/homeNews/actions';
import { fetchHomeLatestNewsRequest } from 'containers/Home/store/latestNews/actions';
import { fetchHomeMostActiveWeeklyRequest } from 'containers/Home/store/mostActive/actions';
import { getPlaceDetails } from 'containers/PlaceDetails/actions';
import { CategoryListViewPage, CategoryMapViewPage, NotFoundPage, PlacePhotosPage, } from 'pages';
import AboutPage from 'pages/AboutPage';
import { ChallengeDetailPage } from 'pages/ChallengeDetailPage';
import { ChallengeJoinTeamPage } from 'pages/ChallengeJoinTeamPage';
import { ChallengeMembersTeamPage } from 'pages/ChallengeMembersTeamPage';
import { ChallengePage } from 'pages/ChallengePage';
import ChangePasswordPage from 'pages/ChangePasswordPage';
import ConfirmAccountPage from 'pages/ConfirmAccountPage';
import ContributorUserPage from 'pages/ContributorUserPage';
import EditPlacePage from 'pages/EditPlacePage';
import EditProfilePage from 'pages/EditProfilePage';
import ExplorePlacesPage from 'pages/ExplorePlacesPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import GlobalScoreboardPage from 'pages/GlobalScoreboardPage';
import { HomePage } from 'pages/HomePage';
import { MailSystemPage } from 'pages/MailSystemPage';
import NewReviewPage from 'pages/NewReviewPage';
import { NewsletterPage } from 'pages/NewsletterPage';
import OAuthPage from 'pages/OAuthPage';
import PlaceDetailsPage from 'pages/PlaceDetailsPage';
import { PlaceRedirect } from 'pages/PlaceRedirect';
import ProfilePage from 'pages/ProfilePage';
import ResendEmailActivationPage from 'pages/ResendEmailActivationPage';
import ReviewDetailPage from 'pages/ReviewDetailPage';
import ReviewsPage from 'pages/ReviewsPage';
import SignInPage from 'pages/SignInPage';
import SignUpPage from 'pages/SignUpPage';
import SubscribersProfilePage from 'pages/SubscribersProfilePage';
import { store as reduxStore } from 'store';
import { selectLanguageProviderDomain } from '../LanguageProvider/selectors';
var localPaths = {
    aboutPageMobileIndex: '/a/index',
    aboutDetailPage: '/a/:slug',
    aboutPage: '/a',
    aboutPageIndex: '/a/page/jaccede-cest-quoi',
    adhereALassociation: '/a/adherer-a-lassociation',
    confirmAccountPage: '/confirm-account',
    editFieldProfilePage: '/my-profile/edit/:field',
    editProfilePage: '/my-profile/edit',
    placeRedirectPage: '/p/:placeId',
    explore: '/p/s',
    addPlace: '/p/add-place',
    faireUnDon: '/a/faire-un-don',
    homePage: '/',
    joinUsPage: '/join-us',
    placeDetailsPage: '/p/:placeId/:placeName',
    editPlacePage: '/p/:placeId/:placeName/description',
    reviewsPage: '/p/:placeId/reviews/all',
    reviewDetailPage: '/p/:placeId/reviews/:reviewId',
    newReviewPage: '/p/:placeId/reviews/new',
    profilePage: '/my-profile',
    signInPage: '/sign-in',
    signUpPage: '/sign-up',
    subscribersProfilePage: '/u/:slug/followers',
    usersPage: '/u/:userSlug/contributions',
    PlacePhotosPage: '/place-photos/:placeId',
    termsAndConditionsPage: '/a/conditions-generales',
    oAuthPage: '/login/:provider',
    resendEmailActivationPage: '/resend-email-activation',
    categoryListViewPage: '/category-list-view',
    categoryMapViewPage: '/category-map-view',
    mailSystemPage: '/messaging',
    redirectPage: '/sign-in',
    notFoundPage: '',
    forgotPasswordPage: '/forgot-password',
    changePasswordPage: '/change-password',
    newsletterPage: '/newsletter',
    challengePage: '/challenge',
    challengeDetailPage: '/challenge/home/:challengeSlug',
    membersTeamDetailPage: '/challenge/team/:challengeSlug/:teamSlug',
    joinTeamPage: '/challenge/team/:challengeSlug/:teamSlug/join',
    globalScoreboardPage: '/global-scoreboard',
    notFoundPageCustom: '/not-found',
};
var handler = {
    get: function (target, name) {
        // @ts-ignore
        var locale = selectLanguageProviderDomain(reduxStore.getState()).locale;
        return name in target ? "/" + locale + target[name] : undefined;
    },
};
export var paths = new Proxy(localPaths, handler);
var routes = function () { return [
    {
        path: localPaths.homePage,
        component: HomePage,
        exact: true,
        auth: false,
        fetchData: function (store) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch(fetchHomeNewsRequest())];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch(fetchHomeLatestNewsRequest())];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch(fetchHomeMostActiveWeeklyRequest())];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch(fetchSubFooterLinksRequest())];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        path: localPaths.aboutPage,
        component: AboutPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.aboutPageIndex,
        component: AboutPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.aboutDetailPage,
        component: AboutPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.signInPage,
        component: SignInPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.forgotPasswordPage,
        component: ForgotPasswordPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.changePasswordPage,
        component: ChangePasswordPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.signUpPage,
        component: SignUpPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.profilePage,
        component: ProfilePage,
        exact: true,
        auth: true,
    },
    {
        path: localPaths.subscribersProfilePage,
        component: SubscribersProfilePage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.editProfilePage,
        component: EditProfilePage,
        exact: true,
        auth: true,
    },
    {
        path: localPaths.confirmAccountPage,
        component: ConfirmAccountPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.oAuthPage,
        component: OAuthPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.resendEmailActivationPage,
        component: ResendEmailActivationPage,
        exact: true,
        auth: true,
    },
    {
        path: localPaths.usersPage,
        component: ContributorUserPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.newsletterPage,
        component: NewsletterPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.placeDetailsPage,
        component: PlaceDetailsPage,
        exact: true,
        auth: false,
        fetchData: function (store, params) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch(getPlaceDetails(params.placeId))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        path: localPaths.editPlacePage,
        component: EditPlacePage,
        exact: true,
        auth: true,
    },
    {
        path: localPaths.addPlace,
        component: ExplorePlacesPage,
        exact: true,
        auth: true,
    },
    {
        path: localPaths.explore,
        component: ExplorePlacesPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.reviewsPage,
        component: ReviewsPage,
        exact: true,
        auth: false,
        fetchData: function (store, params) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch(getPlaceDetails(params.placeId))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        path: localPaths.newReviewPage,
        component: NewReviewPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.reviewDetailPage,
        component: ReviewDetailPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.PlacePhotosPage,
        component: PlacePhotosPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.categoryMapViewPage,
        component: CategoryMapViewPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.categoryListViewPage,
        component: CategoryListViewPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.globalScoreboardPage,
        component: GlobalScoreboardPage,
        exact: true,
        auth: false,
    },
    {
        path: localPaths.challengePage,
        component: ChallengePage,
        avoidAutoScrollTop: true,
        exact: true,
        auth: false,
        fetchData: function (store) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch(fetchPublicChallengesRequest())];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        path: localPaths.challengeDetailPage,
        component: ChallengeDetailPage,
        avoidAutoScrollTop: true,
        exact: true,
        auth: false,
        fetchData: function (store, params) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch(fetchChallengeDetailRequest(params.challengeSlug))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch(fetchTeamsOnChallengeRequest(params.challengeSlug))];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        path: localPaths.joinTeamPage,
        component: ChallengeJoinTeamPage,
        exact: true,
        auth: true,
        fetchData: function (store, params) { return __awaiter(void 0, void 0, void 0, function () {
            var payloadTeam;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payloadTeam = {
                            challengeSlug: params.challengeSlug,
                            teamSlug: params.teamSlug,
                        };
                        return [4 /*yield*/, store.dispatch(fetchTeamRequest(payloadTeam))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        path: localPaths.membersTeamDetailPage,
        component: ChallengeMembersTeamPage,
        exact: false,
        auth: false,
        fetchData: function (store, params) { return __awaiter(void 0, void 0, void 0, function () {
            var payloadTeam, payloadMembers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payloadTeam = {
                            challengeSlug: params.challengeSlug,
                            teamSlug: params.teamSlug,
                        };
                        payloadMembers = {
                            challengeSlug: params.challengeSlug,
                            teamSlug: params.teamSlug,
                            pagination: {
                                page: 1,
                                perPage: 20,
                            },
                        };
                        return [4 /*yield*/, store.dispatch(fetchTeamRequest(payloadTeam))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch(fetchMembersOnTeamRequest(payloadMembers))];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        path: localPaths.placeRedirectPage,
        component: PlaceRedirect,
    },
    {
        path: localPaths.mailSystemPage,
        component: MailSystemPage,
        exact: true,
        auth: true,
    },
    {
        path: localPaths.notFoundPageCustom,
        component: NotFoundPage,
        exact: true,
        auth: false,
    },
    // Keep NotFoundPage as last path when adding pages manually
    {
        path: localPaths.notFoundPage,
        component: NotFoundPage,
    },
]; };
export default routes;
