var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Field, Option, Select, Spin } from 'components';
import { Button } from 'components/Button';
import { FieldTemplate } from 'components/Field';
import { makeSelectLoading } from 'containers/Map/selectors';
import { getCategories } from 'containers/PlaceDetails/actions';
import makeSelectorPlaceDetails, { makeSelectorCategories, } from 'containers/PlaceDetails/selectors';
import { Field as FormikField, Formik } from 'formik';
import { useAction, useGeolocation, useSelector } from 'hooks';
import includes from 'lodash/includes';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { addPlace } from '../actions';
import { makeSelectorLoading, makeSelectorPlaceToAdd } from '../selectors';
import { ModalButtonWrapper } from '../styles';
import MatchPlaceFormSchema from './validator';
export var NewPlaceForm = function () {
    var formatMessage = useIntl().formatMessage;
    var geolocation = useGeolocation();
    var schema = MatchPlaceFormSchema(formatMessage);
    var getCategoriesCallback = useAction(getCategories);
    var addPlaceCallback = useAction(addPlace);
    var loadingLocation = useSelector(makeSelectLoading());
    var loadingForm = useSelector(makeSelectorLoading());
    var categories = useSelector(makeSelectorCategories());
    var placeToAdd = useSelector(makeSelectorPlaceToAdd());
    var place = useSelector(makeSelectorPlaceDetails());
    useEffect(function () {
        if (!(categories === null || categories === void 0 ? void 0 : categories.length)) {
            getCategoriesCallback();
        }
    }, []);
    var handleSubmitNewPlace = function (values) {
        var _a;
        var latitude = geolocation.latitude, longitude = geolocation.longitude;
        var contributorPosition = {
            latitude: latitude,
            longitude: longitude,
        };
        var newPlace = {};
        if (values.phone)
            newPlace = { phone: values.phone };
        if (values.website)
            newPlace = __assign({ website: values.website }, newPlace);
        if ((_a = place === null || place === void 0 ? void 0 : place.address) === null || _a === void 0 ? void 0 : _a.full)
            newPlace = __assign({ fullAddress: place.address.full }, newPlace);
        addPlaceCallback(__assign(__assign(__assign({ categoryId: values.categoryId }, placeToAdd), newPlace), { contributorPosition: contributorPosition }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { spinning: loadingLocation || loadingForm },
            React.createElement(Formik, { initialValues: {
                    name: placeToAdd.name,
                    categoryId: '',
                    phone: '',
                    website: '',
                }, validationSchema: schema, enableReinitialize: true, onSubmit: handleSubmitNewPlace }, function (_a) {
                var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, values = _a.values, handleBlur = _a.handleBlur, setFieldValue = _a.setFieldValue;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: "name", required: true, label: formatMessage({
                            id: 'pages.explorePlacesPage.newPlaceModal.nameLabel',
                        }), value: values.name, onBlur: handleBlur, onChange: handleChange, disabled: false }),
                    React.createElement(FormikField, { name: "categoryId" }, function (_a) {
                        var field = _a.field, meta = _a.meta;
                        return (React.createElement(FieldTemplate, { required: true, error: meta.error, touched: meta.touched, label: formatMessage({
                                id: "containers.editPlace.category",
                            }) },
                            React.createElement(Select, __assign({}, field, { showSearch: true, value: values.categoryId, filterOption: function (input, option) {
                                    return includes(toLower(option === null || option === void 0 ? void 0 : option.children), toLower(input));
                                }, onChange: function (value) {
                                    return setFieldValue('categoryId', value);
                                } }),
                                React.createElement(Option, { value: "" }, formatMessage({
                                    id: 'pages.explorePlacesPage.newPlaceModal.categoryPlaceholder',
                                })),
                                map(categories, function (c) { return (React.createElement(Option, { key: c.id, value: c.id }, c.label)); }))));
                    }),
                    React.createElement(Field, { name: "phone", label: formatMessage({
                            id: 'pages.explorePlacesPage.newPlaceModal.telephoneLabel',
                        }), onChange: handleChange, onBlur: handleBlur, value: values.phone }),
                    React.createElement(Field, { name: "website", label: formatMessage({
                            id: 'pages.explorePlacesPage.newPlaceModal.websiteLabel',
                        }), value: values.website, onChange: handleChange, onBlur: handleBlur }),
                    React.createElement(ModalButtonWrapper, null,
                        React.createElement(Button, { type: "submit" },
                            React.createElement(FormattedMessage, { id: "pages.explorePlacesPage.newPlaceModal.validateButton" })))));
            }))));
};
