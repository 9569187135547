import { emoticonFlyingLeaves } from 'assets/images';
import { EmptyListText, LoadingIndicator } from 'components';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { CardPublicChallenge } from 'containers/Challenges/CardPublicChallenge';
import { EmoticonImage } from 'containers/Profile/MoreAbout/PlaceList/styles';
import { useSelector } from 'hooks';
import map from 'lodash/map';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { ChallengesContainer, ChallengesList, Content, LoadingWrapper, Title, } from './styles';
export var PublicChallenges = memo(function (_a) {
    var publicChallenges = _a.publicChallenges, isLoading = _a.isLoading;
    var formatMessage = useIntl().formatMessage;
    var keyboard = useSelector(makeSelectUseKeyboard());
    return (React.createElement(React.Fragment, null, isLoading ? (React.createElement(LoadingWrapper, null,
        React.createElement(LoadingIndicator, null))) : (React.createElement(ChallengesContainer, null,
        React.createElement(Content, null,
            React.createElement(Title, null, formatMessage({
                id: 'containers.challenges.publicChallengeTitle',
            })),
            (publicChallenges === null || publicChallenges === void 0 ? void 0 : publicChallenges.length) > 0 ? (React.createElement(ChallengesList, { isKeyboardMode: keyboard }, map(publicChallenges, function (item) { return (React.createElement(CardPublicChallenge, { key: item.slug, item: item })); }))) : (React.createElement(EmptyListText, null,
                React.createElement("span", null, "Sorry"),
                React.createElement(EmoticonImage, { src: emoticonFlyingLeaves, alt: "emoticon" }))))))));
});
