var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint600,
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
var textColor = theme('mode', {
    light: colors.space.tint700,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  border: ", " solid ", ";\n\n  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: ", ";\n  }\n"], ["\n  width: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  border: ", " solid ", ";\n\n  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: ", ";\n  }\n"])), rem(400), background, rem(10), rem(1), borderColor, textColor);
export var TabPaneTitle = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n"])), fonts.weight.bold, rem(16));
export var UsersContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: auto;\n  max-height: ", ";\n\n  ::-webkit-scrollbar {\n    width: ", ";\n    background-color: ", ";\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: ", ";\n  }\n"], ["\n  overflow: auto;\n  max-height: ", ";\n\n  ::-webkit-scrollbar {\n    width: ", ";\n    background-color: ", ";\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: ", ";\n  }\n"])), rem(500), rem(4), background, colors.space.tint500, rem(4));
export var Footer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  > a {\n    padding: ", " ", ";\n  }\n"], ["\n  > a {\n    padding: ", " ", ";\n  }\n"])), rem(16), rem(20));
export var FooterTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: ", " ", ";\n  text-align: center;\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n\n  ", ";\n"], ["\n  padding: ", " ", ";\n  text-align: center;\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n\n  ",
    ";\n"])), rem(12), rem(30), colors.base.secondary, colors.base.white, fonts.weight.bold, rem(16), ifProp('noPoints', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n    "])), colors.space.tint500, colors.carbon.tint100)));
export var EmptyListContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: ", ";\n  text-align: center;\n  width: 100%;\n\n  height: calc(100vh - 200px);\n\n  > img {\n    width: ", ";\n    height: ", ";\n    object-fit: contain;\n    margin-bottom: ", ";\n  }\n"], ["\n  padding: ", ";\n  text-align: center;\n  width: 100%;\n\n  height: calc(100vh - 200px);\n\n  > img {\n    width: ", ";\n    height: ", ";\n    object-fit: contain;\n    margin-bottom: ", ";\n  }\n"])), rem(30), rem(40), rem(40), rem(30));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
