var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Checkbox } from 'components';
import { rem } from 'polished';
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var MapContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", ";\n  position: relative;\n  width: 100%;\n"], ["\n  height: ", ";\n  position: relative;\n  width: 100%;\n"])), rem(300));
export var CheckboxWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", " 0;\n"], ["\n  padding: ", " 0;\n"])), rem(20));
export var ButtonWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var StyledCheckbox = styled(Checkbox)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  justify-content: space-between;\n\n  .ant-checkbox {\n    height: ", ";\n    width: ", ";\n    order: 1;\n    top: 0;\n    margin-left: ", ";\n\n    & + span {\n      margin-left: 0 !important;\n    }\n  }\n"], ["\n  justify-content: space-between;\n\n  .ant-checkbox {\n    height: ", ";\n    width: ", ";\n    order: 1;\n    top: 0;\n    margin-left: ", ";\n\n    & + span {\n      margin-left: 0 !important;\n    }\n  }\n"])), rem(20), rem(20), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
