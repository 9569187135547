import produce from 'immer';
import { RESEND_EMAIL_ACTIVATION_FAILURE, RESEND_EMAIL_ACTIVATION_REQUEST, RESEND_EMAIL_ACTIVATION_SUCCESS, } from './constants';
export var initialState = {
    loading: false,
    hasError: false,
    error: undefined,
};
var resendEmailActivationReducer = produce(function (draft, action) {
    switch (action.type) {
        case RESEND_EMAIL_ACTIVATION_REQUEST:
            draft.loading = true;
            break;
        case RESEND_EMAIL_ACTIVATION_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case RESEND_EMAIL_ACTIVATION_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            break;
        default:
    }
}, initialState);
export default resendEmailActivationReducer;
