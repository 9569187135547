var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from '../styles';
export var MedalPlaceDisabledDesktop = memo(function (_a) {
    var size = _a.size, props = __rest(_a, ["size"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { style: { mixBlendMode: 'color' } },
            React.createElement("rect", { width: "40", height: "48", fill: "white" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.45155 28.9355L4.72962 31.5162L-6.10352e-05 38.613H5.70962H34.2909H39.9999L38.2774 36.0323L36.5612 33.4517L33.5483 28.9355H6.45155Z", fill: "#C2EC96" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M38.2775 36.0323H34.413H34.4065L31.8259 33.4516H36.5614L38.2775 36.0323Z", fill: "#ABD978" }),
        React.createElement("path", { opacity: "0.55113", fillRule: "evenodd", clipRule: "evenodd", d: "M23.9352 34.7419L26.7288 32.1613L30.2391 37.9677H14.9223L12.3417 35.3871L23.9352 34.7419Z", fill: "#8CC152" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.0584 36.0322L34.2907 38.6135H40.0004L38.2772 36.0322H33.0584Z", fill: "#E6E9ED" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 33.4516C20 33.4516 20.5355 32.9806 21.3484 32.1613H21.3549H26.729L27.6581 35.3871H12.3419L13.271 32.1613H18.6452C19.4645 32.9806 20 33.4516 20 33.4516Z", fill: "#E6E9ED" }),
        React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "0", y: "31", width: "10", height: "8" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 31.5161H9.09019V38.6129H0V31.5161Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.0902 31.5161L5.70956 38.6129H-0.00012207L4.72891 31.5161H9.0902Z", fill: "#E6E9ED" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.6708 34.0968L9.51921 38.6129H5.70953L9.09018 31.5161L11.6708 34.0968Z", fill: "#ABD978" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M40 38.6129V41.1935H0V38.6129H5.70968H34.2903H40Z", fill: "#A8D273" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36.5612 33.4516H31.8322H31.8258L29.6774 28.9355H33.5483L36.5612 33.4516Z", fill: "#E6E9ED" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 21.7241C17.8103 21.7241 16.0355 19.9492 16.0355 17.7595C16.0355 15.5705 17.8103 13.7957 20 13.7957C22.1897 13.7957 23.9645 15.5705 23.9645 17.7595C23.9645 19.9492 22.1897 21.7241 20 21.7241ZM20 7.00019C14.6516 7.00019 10.3226 11.335 10.3226 16.6776C10.3226 21.1679 13.1613 25.7157 15.7606 28.9357C16.8381 30.2647 17.871 31.3737 18.6452 32.1615C19.4645 32.9802 19.9993 33.4518 19.9993 33.4518C19.9993 33.4518 20.5348 32.9802 21.3477 32.1615H21.3542C22.1284 31.3737 23.1613 30.2647 24.2387 28.9357C26.8387 25.7157 29.6774 21.1679 29.6774 16.6776C29.6774 11.335 25.3484 7.00019 20 7.00019Z", fill: "#FF8A96" }),
        React.createElement("g", { style: { mixBlendMode: 'color' } },
            React.createElement("rect", { width: "40", height: "48", fill: "white" }))));
});
