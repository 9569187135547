import React from 'react';
import { useIntl } from 'react-intl';
import { PlacesNearby } from './PlacesNearby';
import { Content, JoinTeamContainer, Title } from './styles';
export var PlacesAroundMeSection = function () {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(JoinTeamContainer, null,
        React.createElement(Content, null,
            React.createElement(Title, null, formatMessage({
                id: 'containers.challengesMembersTeam.placesAroundMeSection.title',
            })),
            React.createElement(PlacesNearby, null))));
};
