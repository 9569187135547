import { PageHeader, Spin } from 'components';
import { ArrowBack } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { colors } from 'styles';
import { useSelector } from '../../../utils/hooks';
import { makeSelectLoading } from '../selectors';
import { Container, Content, Description, FieldsContainer, HeaderContainer, Title, } from './styles';
export var Template = function (props) {
    var isLoading = useSelector(makeSelectLoading());
    return (React.createElement(Container, null,
        React.createElement(PageHeader, { title: props.pageTitle, backIcon: React.createElement(ArrowBack, { size: rem(18), color: colors.space.tint600 }), onBack: props.onBack, sticky: true }),
        React.createElement(Content, null,
            React.createElement(HeaderContainer, null,
                React.createElement(Title, null, props.fieldDescription),
                React.createElement(Description, null, props.fieldInformation)),
            React.createElement(Spin, { spinning: isLoading },
                React.createElement(FieldsContainer, null, props.children)))));
};
