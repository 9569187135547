var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { colors } from 'styles';
import { Section } from '../Section';
export var StyledSection = styled(Section)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-left: 0;\n    padding-right: 0;\n  "], ["\n    padding-left: 0;\n    padding-right: 0;\n  "]))));
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var Wrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n\n  > div {\n    width: 45%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n\n  > div {\n    width: 45%;\n  }\n"])));
export var Slider = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &.swiper-slide {\n    width: calc(100% - ", ") !important;\n    margin: 0 ", ";\n  }\n"], ["\n  &.swiper-slide {\n    width: calc(100% - ", ") !important;\n    margin: 0 ", ";\n  }\n"])), rem(40), rem(20));
export var navigationButtonBase = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  display: flex !important;\n  height: ", " !important;\n  justify-content: center !important;\n  position: absolute !important;\n  top: 50% !important;\n  width: ", " !important;\n  z-index: 10 !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  align-items: center !important;\n  background: ", " !important;\n  border-radius: 100% !important;\n  border: ", " solid ", " !important;\n  box-shadow: 0 ", " ", " ", " !important;\n  display: flex !important;\n  height: ", " !important;\n  justify-content: center !important;\n  position: absolute !important;\n  top: 50% !important;\n  width: ", " !important;\n  z-index: 10 !important;\n\n  &:after {\n    content: '' !important;\n  }\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), colors.base.white, rem(1), colors.space.tint100, rem(2), rem(6), rgba(colors.base.black, 0.2), rem(40), rem(40));
export var PrevButton = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  left: ", " !important;\n"], ["\n  ", "\n  left: ", " !important;\n"])), navigationButtonBase, rem(16));
export var NextButton = styled(Button)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n  right: ", " !important;\n"], ["\n  ", "\n  right: ", " !important;\n"])), navigationButtonBase, rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
