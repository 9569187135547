import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    geolocalize: versionV4 + "/services/geolocalize",
    autocomplete: versionV4 + "/autocomplete",
};
export default {
    autocomplete: function (token) {
        var config = {
            params: {
                token: token,
            },
        };
        return axios.get(endpoints.autocomplete, config);
    },
    geolocalize: function () { return axios.get(endpoints.geolocalize); },
};
