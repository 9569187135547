import { createSelector } from 'reselect';
import { initialState } from './reducer';
var selectPlaceDetailsStateDomain = function (state) {
    return state.placeDetails || initialState;
};
var makeSelectorPlaceDetails = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var placeDetails = _a.placeDetails;
        return placeDetails;
    });
};
var makeSelectorLoading = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
var makeSelectorHasError = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
var makeSelectorError = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
var makeSelectorBookmarkLoading = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var bookmarkLoading = _a.bookmarkLoading;
        return bookmarkLoading;
    });
};
var makeSelectorCriteria = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var criteria = _a.criteria;
        return criteria;
    });
};
var makeSelectorCriteriaLoading = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var criteriaLoading = _a.criteriaLoading;
        return criteriaLoading;
    });
};
var makeSelectorActiveSection = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var activeSection = _a.activeSection;
        return activeSection;
    });
};
var makeSelectorCategories = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var categories = _a.categories;
        return categories;
    });
};
var makeSelectorFeedback = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var feedback = _a.feedback;
        return feedback;
    });
};
var makeSelectorReportLoading = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var reportLoading = _a.reportLoading;
        return reportLoading;
    });
};
var makeSelectorReported = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var reported = _a.reported;
        return reported;
    });
};
var makeSelectorReportedPhoto = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var reportedPhoto = _a.reportedPhoto;
        return reportedPhoto;
    });
};
var makeSelectorAlreadyReportedPhoto = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var alreadyReportedPhoto = _a.alreadyReportedPhoto;
        return alreadyReportedPhoto;
    });
};
var makeSelectorReportPhotoLoading = function () {
    return createSelector(selectPlaceDetailsStateDomain, function (_a) {
        var reportPhotoLoading = _a.reportPhotoLoading;
        return reportPhotoLoading;
    });
};
export default makeSelectorPlaceDetails;
export { selectPlaceDetailsStateDomain, makeSelectorLoading, makeSelectorBookmarkLoading, makeSelectorCriteria, makeSelectorCriteriaLoading, makeSelectorActiveSection, makeSelectorCategories, makeSelectorFeedback, makeSelectorReportLoading, makeSelectorReported, makeSelectorReportedPhoto, makeSelectorAlreadyReportedPhoto, makeSelectorReportPhotoLoading, makeSelectorHasError, makeSelectorError, };
