import { PlacesAutocomplete, Spin } from 'components';
import { Button } from 'components/Button';
import { Field } from 'components/Field';
import { reverseGpid } from 'containers/Map/actions';
import { makeSelectLoading, makeSelectLocation, } from 'containers/Map/selectors';
import { getPlaceDetails } from 'containers/PlaceDetails/actions';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAction, useSelector } from 'utils/hooks';
import { matchPlace } from '../actions';
import { makeSelectorLoading } from '../selectors';
import { ModalButtonWrapper } from '../styles';
import MatchPlaceFormSchema from './validator';
export var MatchPlaceForm = function () {
    var formatMessage = useIntl().formatMessage;
    var schema = MatchPlaceFormSchema(formatMessage);
    var reverseGpidCallback = useAction(reverseGpid);
    var matchPlaceCallback = useAction(matchPlace);
    var getPlaceDetailsCallback = useAction(getPlaceDetails);
    var loadingLocation = useSelector(makeSelectLoading());
    var location = useSelector(makeSelectLocation());
    var loadingForm = useSelector(makeSelectorLoading());
    var placeLoading = useSelector(makeSelectorLoading());
    var handleSubmitMatch = function (_a) {
        var name = _a.name;
        var _b = location || { lat: 0, lng: 0 }, lat = _b.lat, lng = _b.lng;
        matchPlaceCallback({ name: name, lat: lat, lng: lng });
    };
    var handleSelect = function (placeId) {
        reverseGpidCallback(placeId);
        getPlaceDetailsCallback(placeId);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { spinning: loadingLocation || loadingForm || placeLoading },
            React.createElement(Formik, { initialValues: {
                    name: '',
                    googlePlaceId: '',
                }, validationSchema: schema, enableReinitialize: true, onSubmit: handleSubmitMatch }, function (_a) {
                var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, values = _a.values, handleBlur = _a.handleBlur;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: "name", required: true, label: formatMessage({
                            id: 'pages.explorePlacesPage.newPlaceModal.nameLabel',
                        }), value: values.name, onBlur: handleBlur, onChange: handleChange, disabled: false }),
                    React.createElement(PlacesAutocomplete
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        name: "googlePlaceId", required: true, label: formatMessage({
                            id: 'pages.explorePlacesPage.newPlaceModal.addressLabel',
                        }), onBlur: handleBlur, onSelect: handleSelect }),
                    React.createElement(ModalButtonWrapper, null,
                        React.createElement(Button, { type: "submit" },
                            React.createElement(FormattedMessage, { id: "pages.explorePlacesPage.newPlaceModal.continueButton" })))));
            }))));
};
