var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export var useGeolocation = function (callback, configs) {
    if (configs === void 0) { configs = {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 5000,
    }; }
    var _a = __read(useState({
        accuracy: 0,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        latitude: 0,
        longitude: 0,
        speed: null,
        timestamp: 0,
        error: null,
    }), 2), coordinates = _a[0], setCoordinates = _a[1];
    useEffect(function () {
        var didCancel;
        var updateCoordinates = function (_a, error) {
            var coords = _a.coords, timestamp = _a.timestamp;
            if (error === void 0) { error = null; }
            var accuracy = coords.accuracy, altitude = coords.altitude, altitudeAccuracy = coords.altitudeAccuracy, heading = coords.heading, latitude = coords.latitude, longitude = coords.longitude, speed = coords.speed;
            if (!didCancel) {
                setCoordinates({
                    accuracy: accuracy,
                    altitude: altitude,
                    altitudeAccuracy: altitudeAccuracy,
                    heading: heading,
                    latitude: latitude,
                    longitude: longitude,
                    speed: speed,
                    timestamp: timestamp,
                    error: error,
                });
                if (callback) {
                    callback({
                        accuracy: accuracy,
                        altitude: altitude,
                        altitudeAccuracy: altitudeAccuracy,
                        heading: heading,
                        latitude: latitude,
                        longitude: longitude,
                        speed: speed,
                        timestamp: timestamp,
                        error: error,
                    });
                }
            }
        };
        var setError = function (error) {
            if (!didCancel) {
                updateCoordinates({
                    coords: {
                        accuracy: 0,
                        altitude: null,
                        altitudeAccuracy: null,
                        heading: null,
                        latitude: 0,
                        longitude: 0,
                        speed: null,
                    },
                    timestamp: 0,
                }, error);
            }
        };
        var watchId;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(updateCoordinates, setError, configs);
            watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
                enableHighAccuracy: configs === null || configs === void 0 ? void 0 : configs.enableHighAccuracy,
                maximumAge: configs === null || configs === void 0 ? void 0 : configs.maximumAge,
                timeout: configs === null || configs === void 0 ? void 0 : configs.timeout,
            });
        }
        /* Component Will Unmount */
        return function () {
            if (watchId) {
                navigator.geolocation.clearWatch(watchId);
            }
            didCancel = true;
        };
    }, []);
    return coordinates;
};
