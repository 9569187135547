import { Button, PasswordField, PasswordScore } from 'components';
import { fieldBlur, fieldOnFocus } from 'containers/App/actions';
import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAction } from 'utils/hooks';
import { Template } from '../Template';
import messages from './messages';
import { ButtonWrapper } from './styles';
import EditNameFormSchema from './validator';
export var EditPassword = function (_a) {
    var onSubmit = _a.onSubmit, onBack = _a.onBack;
    var formatMessage = useIntl().formatMessage;
    var schema = EditNameFormSchema(formatMessage);
    var fieldOnFocusActionCallback = useAction(fieldOnFocus);
    var fieldBlurActionCallback = useAction(fieldBlur);
    return (React.createElement(Template, { onBack: onBack, pageTitle: formatMessage(messages.title), fieldDescription: formatMessage(messages.fieldDescription), fieldInformation: formatMessage(messages.fieldInformation) },
        React.createElement(Formik, { initialValues: {
                password: '',
                passwordConfirm: '',
            }, validationSchema: schema, enableReinitialize: true, onSubmit: onSubmit }, function (_a) {
            var isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, values = _a.values, handleChange = _a.handleChange;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(PasswordField, { required: true, name: "password", value: values.password, onChange: handleChange, onFocus: fieldOnFocusActionCallback, onBlur: fieldBlurActionCallback, disabled: isSubmitting }),
                values.password.length > 0 && (React.createElement(PasswordScore, { password: values.password })),
                React.createElement(PasswordField, { required: true, label: formatMessage(messages.confirmPassword), name: "passwordConfirm", value: values.passwordConfirm, onChange: handleChange, onFocus: fieldOnFocusActionCallback, onBlur: fieldBlurActionCallback, disabled: isSubmitting }),
                React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { type: "submit", disabled: isSubmitting }, formatMessage(messages.changePassword)))));
        })));
};
