import { dogAndOwner, guideDogs, wheelchairHand, wheelchairPeople, } from 'assets/images';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { PhotoMosaicContainer } from './styles';
export var DesktopPhotoMosaic = memo(function () {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(PhotoMosaicContainer, null,
        React.createElement("div", null,
            React.createElement("img", { src: dogAndOwner, alt: formatMessage({
                    id: 'containers.homeBrowsePlaces.dogAndOwnerAlt',
                }) }),
            React.createElement("img", { src: wheelchairHand, alt: formatMessage({
                    id: 'containers.homeBrowsePlaces.wheelchairHandAlt',
                }) })),
        React.createElement("div", null,
            React.createElement("img", { src: guideDogs, alt: formatMessage({
                    id: 'containers.homeBrowsePlaces.guideDogsAlt',
                }) }),
            React.createElement("img", { src: wheelchairPeople, alt: formatMessage({
                    id: 'containers.homeBrowsePlaces.wheelchairHandAlt',
                }) }))));
});
