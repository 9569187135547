var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/camelcase */
import { axios } from 'api';
import join from 'lodash/join';
var versionV4 = '/v4';
export var endpoints = {
    addReview: function (id) { return versionV4 + "/places/" + id + "/comments"; },
    categories: versionV4 + "/categories",
    criteria: function (placeId) { return versionV4 + "/places/" + placeId + "/criteria_schema"; },
    match: versionV4 + "/places/search/match",
    placeDetails: function (id) { return versionV4 + "/places/" + id; },
    placeFavorites: function (id) { return versionV4 + "/account/favorites/" + id; },
    placeMedia: function (id) { return versionV4 + "/places/" + id + "/medias"; },
    placeReviews: function (id) { return versionV4 + "/places/" + id + "/comments"; },
    places: versionV4 + "/places",
    placesAround: function () { return versionV4 + "/places/search"; },
    reviewDetail: function (reviewId) {
        return versionV4 + "/places/comments/" + reviewId;
    },
    placeMediaFavority: function (id, mediaId) {
        return versionV4 + "/places/" + id + "/medias/" + mediaId;
    },
    report: function (id) { return versionV4 + "/places/" + id + "/report"; },
    reportMedia: function (placeId, mediaId) {
        return versionV4 + "/places/" + placeId + "/medias/" + mediaId + "/report";
    },
    reportReview: function (placeId, reviewId) {
        return versionV4 + "/places/" + placeId + "/comments/" + reviewId + "/report";
    },
    accessibilityFilters: versionV4 + "/accessibility_filters",
    deletePhoto: function (placeId, photoId) {
        return versionV4 + "/places/" + placeId + "/medias/" + photoId + "?page=1&per_page=20";
    },
    search: versionV4 + "/autocomplete",
};
export default {
    addPlace: function (place) { return axios.post(endpoints.places, place); },
    getPlacesDetails: function (id) { return axios.get(endpoints.placeDetails(id)); },
    addPlaceToBookmark: function (id) { return axios.post(endpoints.placeFavorites(id)); },
    removePlaceFromBookmark: function (id) {
        return axios.delete(endpoints.placeFavorites(id));
    },
    getPlaceReviews: function (_a) {
        var placeId = _a.placeId, page = _a.page, perPage = _a.perPage;
        var url = endpoints.placeReviews(placeId);
        var config = {
            params: {
                page: page,
                per_page: perPage,
            },
        };
        return axios.get(url, config);
    },
    addReview: function (_a) {
        var placeId = _a.placeId, review = __rest(_a, ["placeId"]);
        return axios.post(endpoints.addReview(placeId), review);
    },
    getReviewDetail: function (reviewId) {
        return axios.get(endpoints.reviewDetail(reviewId));
    },
    getPlacesPhotos: function (placeId) {
        var url = endpoints.placeMedia(placeId);
        return axios.get(url);
    },
    postPlacesPhotos: function (placeId, file) {
        var url = endpoints.placeMedia(placeId);
        return axios.post(url, file);
    },
    putPlacesPhotos: function (placeId, mediaId) {
        var url = endpoints.placeMediaFavority(placeId, mediaId);
        return axios.put(url, { id: mediaId });
    },
    getPlaceCriteria: function (id) { return axios.get("" + endpoints.criteria(id)); },
    getCategories: function () { return axios.get(endpoints.categories); },
    updatePlace: function (_a) {
        var placeId = _a.placeId, form = _a.form;
        return axios.put(endpoints.placeDetails(placeId), form);
    },
    match: function (params) { return axios.get(endpoints.match, { params: params }); },
    getPlacesAround: function (params) {
        var _a;
        var url = endpoints.placesAround();
        var optionalParams = {};
        if (params.category)
            optionalParams.category = params.category;
        if ((_a = params.accessibility) === null || _a === void 0 ? void 0 : _a.length) {
            url += "?filter=" + join(params.accessibility, '&filter=');
        }
        var config = {
            params: __assign(__assign({}, optionalParams), { response_type: params.responseType || 'places', google: params.google || '0', boolean_filter: params.booleanFilter || '0', page: params.page, per_page: params.perPage, lat: params.lat, lng: params.lng }),
        };
        return axios.get(url, config);
    },
    report: function (_a) {
        var placeId = _a.placeId, message = _a.message;
        return axios.post(endpoints.report(placeId), { message: message });
    },
    reportMedia: function (_a) {
        var placeId = _a.placeId, mediaId = _a.mediaId, message = _a.message;
        return axios.post(endpoints.reportMedia(placeId, mediaId), { message: message });
    },
    reportReview: function (_a) {
        var placeId = _a.placeId, reviewId = _a.reviewId, message = _a.message;
        return axios.post(endpoints.reportReview(placeId, reviewId), { message: message });
    },
    uploadCriterionPhoto: function (form, placeId) {
        var config = { headers: { 'content-type': 'multipart/form-data' } };
        return axios.post(endpoints.placeMedia(placeId), form, config);
    },
    getAccessibilityFilters: function () { return axios.get(endpoints.accessibilityFilters); },
    deletePhoto: function (placeId, photoId) {
        return axios.delete(endpoints.deletePhoto(placeId, photoId));
    },
    search: function (params) { return axios.get(endpoints.search, { params: params }); },
};
