var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { TextArea as TextAreaComponent } from 'components/Input';
import { rem } from 'polished';
import styled from 'styled-components';
import media from 'styled-media-query';
import { colors, fonts } from 'styles';
export var LoadingWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n"], ["\n  height: ", ";\n"])), rem(100));
export var NewReviewContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: calc(100% - ", ");\n  padding: ", " 0;\n\n  ", ";\n"], ["\n  height: calc(100% - ", ");\n  padding: ", " 0;\n\n  ",
    ";\n"])), rem(120), rem(20), media.greaterThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: 100%;\n  "], ["\n    height: 100%;\n  "]))));
export var ContentWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: ", ";\n  width: 100%;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  z-index: 100;\n"], ["\n  margin-top: ", ";\n  width: 100%;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  z-index: 100;\n"])), rem(60));
export var UserInfo = styled.form(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  overflow: hidden;\n\n  width: 100%;\n  max-width: ", ";\n  padding: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  overflow: hidden;\n\n  width: 100%;\n  max-width: ", ";\n  padding: ", ";\n"])), rem(768), rem(20));
export var ButtonWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n\n  margin-top: ", ";\n"], ["\n  width: 100%;\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n\n  margin-top: ", ";\n"])), rem(50));
export var TextArea = styled(TextAreaComponent)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  &.ant-input {\n    height: 125px;\n    min-height: 125px;\n    margin-top: ", ";\n  }\n"], ["\n  &.ant-input {\n    height: 125px;\n    min-height: 125px;\n    margin-top: ", ";\n  }\n"])), rem(25));
export var Content = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  overflow: hidden;\n  max-width: min(100vw, ", ");\n\n  > strong {\n    font-size: ", ";\n    margin-top: ", ";\n    max-width: 70%;\n    text-align: center;\n\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n\n  > span {\n    font-size: ", ";\n    margin-bottom: ", ";\n    max-width: 70%;\n    text-align: center;\n\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  overflow: hidden;\n  max-width: min(100vw, ", ");\n\n  > strong {\n    font-size: ", ";\n    margin-top: ", ";\n    max-width: 70%;\n    text-align: center;\n\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n\n  > span {\n    font-size: ", ";\n    margin-bottom: ", ";\n    max-width: 70%;\n    text-align: center;\n\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n"])), rem(768), rem(16), rem(10), rem(14), rem(20));
export var ErrorMessage = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  text-align: left;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  text-align: left;\n  width: 100%;\n"])), colors.base.danger, rem(14), fonts.weight.regular, rem(6));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
