import { MobileMenu, Navbar } from 'components';
import React from 'react';
import { PageWrapperContainer } from './styles';
export var PageWrapper = function (_a) {
    var style = _a.style, children = _a.children, _b = _a.mobileMenu, mobileMenu = _b === void 0 ? true : _b, onAutocompleteSelect = _a.onAutocompleteSelect;
    return (React.createElement(PageWrapperContainer, { style: style },
        React.createElement(Navbar, { onAutocompleteSelect: onAutocompleteSelect }),
        children,
        mobileMenu && React.createElement(MobileMenu, null)));
};
