import { SUBSCRIPTIONS_FAILURE, SUBSCRIPTIONS_REMOVE_USER, SUBSCRIPTIONS_REQUEST, SUBSCRIPTIONS_SUCCESS, SUBSCRIPTIONS_TOGGLE_USER_IS_SUBSCRIBED, } from './constants';
export var fetchSubscriptions = function (payload) { return ({
    type: SUBSCRIPTIONS_REQUEST,
    payload: payload,
}); };
export var fetchSubscriptionsSuccess = function (payload) { return ({
    type: SUBSCRIPTIONS_SUCCESS,
    payload: payload,
}); };
export var fetchSubscriptionsError = function (error) { return ({
    type: SUBSCRIPTIONS_FAILURE,
    payload: error,
}); };
export var subscriptionsToggleUserIsSubscribed = function (payload) { return ({
    type: SUBSCRIPTIONS_TOGGLE_USER_IS_SUBSCRIBED,
    payload: payload,
}); };
export var subscriptionsRemoveUser = function (payload) { return ({
    type: SUBSCRIPTIONS_REMOVE_USER,
    payload: payload,
}); };
