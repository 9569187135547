import { paths } from 'containers/RoutesProvider/routes';
import { ArrowNext } from 'icons/mono';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { colors } from 'styles';
import { LinkStyled, ListItem, SideListContainer, Text, Title } from './styles';
export var SideList = function (_a) {
    var title = _a.title, items = _a.items;
    var location = useLocation();
    return (React.createElement(SideListContainer, null,
        React.createElement(Title, null, title),
        React.createElement("ul", null, map(items, function (_a, idx) {
            var text = _a.title, slug = _a.slug;
            return (React.createElement(ListItem, null,
                React.createElement(LinkStyled, { to: {
                        pathname: paths.aboutDetailPage.replace(':slug', slug),
                        state: { from: location.pathname },
                    }, key: idx },
                    React.createElement(Text, null, text),
                    React.createElement(ArrowNext, { size: rem(18), color: colors.space.tint600 }))));
        }))));
};
