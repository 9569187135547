var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Drawer } from 'antd';
import { rem, rgba, shade } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { colors, fonts } from 'styles';
export var Container = styled(Drawer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-drawer {\n    &-body {\n      background-color: ", ";\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n      padding: 0;\n    }\n\n    &-content {\n      background-color: transparent !important;\n    }\n\n    &-footer {\n      border: 0;\n      padding: 0;\n    }\n\n    &-mask {\n      background-color: ", ";\n    }\n  }\n"], ["\n  .ant-drawer {\n    &-body {\n      background-color: ", ";\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n      padding: 0;\n    }\n\n    &-content {\n      background-color: transparent !important;\n    }\n\n    &-footer {\n      border: 0;\n      padding: 0;\n    }\n\n    &-mask {\n      background-color: ", ";\n    }\n  }\n"])), colors.base.secondary, rgba(colors.carbon.tint800, 0.9));
export var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: ", ";\n  justify-content: flex-end;\n  padding: 0 ", ";\n\n  ", "\n"], ["\n  align-items: center;\n  display: flex;\n  height: ", ";\n  justify-content: flex-end;\n  padding: 0 ", ";\n\n  ",
    "\n"])), rem(60), rem(16), ifProp('isRtl', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      flex-direction: row-reverse;\n\n      > img {\n        margin-left: 0 !important;\n        margin-right: ", ";\n      }\n    "], ["\n      flex-direction: row-reverse;\n\n      > img {\n        margin-left: 0 !important;\n        margin-right: ", ";\n      }\n    "])), rem(8))));
export var Navigation = styled.nav(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: flex-end;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: flex-end;\n  margin: 2rem 1rem;\n\n  ", "\n"], ["\n  align-items: flex-end;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: flex-end;\n  margin: 2rem 1rem;\n\n  ",
    "\n"])), ifProp('isRtl', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      align-items: flex-start;\n    "], ["\n      align-items: flex-start;\n    "])))));
export var NavLink = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-decoration: none;\n\n  &:not(:last-child) {\n    margin-bottom: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-decoration: none;\n\n  &:not(:last-child) {\n    margin-bottom: ", ";\n  }\n"])), colors.base.white, rem(18), fonts.weight.semibold, rem(6));
export var NavButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  outline: 0;\n  text-decoration: none;\n"], ["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  outline: 0;\n  text-decoration: none;\n"])), colors.base.white, rem(18), fonts.weight.semibold, rem(6));
export var Footer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: ", ";\n  border-top: 1px solid ", ";\n  display: flex;\n  height: ", ";\n  justify-content: flex-end;\n\n  ", "\n"], ["\n  background: ", ";\n  border-top: 1px solid ", ";\n  display: flex;\n  height: ", ";\n  justify-content: flex-end;\n\n  ",
    "\n"])), shade(0.1, colors.base.secondary), shade(0.15, colors.base.secondary), rem(80), ifProp('isRtl', css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      justify-content: flex-start;\n    "], ["\n      justify-content: flex-start;\n    "])))));
export var CloseButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  height: 100%;\n  min-width: ", ";\n  outline: 0;\n  width: 20vw;\n"], ["\n  background: none;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  height: 100%;\n  min-width: ", ";\n  outline: 0;\n  width: 20vw;\n"])), colors.base.white, rem(64));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
