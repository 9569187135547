import PrivateRoute from 'components/PrivateRoute';
import { makeSelectIsLoading, makeSelectIsLoggedIn, } from 'containers/Auth/selectors';
import { useSelector } from 'hooks';
import map from 'lodash/map';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import routes, { paths } from './routes';
import { RouterWrapper } from './styles';
var RoutesProvider = function (_a) {
    var match = _a.match;
    var isLoggedIn = useSelector(makeSelectIsLoggedIn());
    var isLoading = useSelector(makeSelectIsLoading());
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(RouterWrapper, null,
        React.createElement(Switch, null, map(routes(), function (_a) {
            var path = _a.path, component = _a.component, exact = _a.exact, auth = _a.auth;
            return auth ? (React.createElement(PrivateRoute, { key: path, path: "" + match.url + path, exact: exact, 
                // @ts-ignore
                component: component, isAuthenticated: isLoggedIn, redirectPath: paths.signInPage, isLoading: isLoading })) : (React.createElement(Route, { key: path, path: "" + match.url + path, exact: exact, component: component }));
        })),
        React.createElement(CookieConsent, { location: "bottom", enableDeclineButton: true, buttonText: formatMessage({
                id: 'containers.app.cookieConsentButtonOk',
            }), declineButtonText: formatMessage({
                id: 'containers.app.cookieConsentButtonDecline',
            }), cookieName: "jaccede-cookie-consent", expires: 999, acceptOnScroll: false, 
            // eslint-disable-next-line no-console
            onAccept: function () {
                // const tagManagerArgs = {
                //   gtmId: process.env.RAZZLE_APP_GOOGLE_TAG_MANAGER,
                // };
                // TagManager.initialize(tagManagerArgs);
            } }, formatMessage({
            id: 'containers.app.cookieConsentText',
        }))));
};
export default RoutesProvider;
