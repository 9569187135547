var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem, shade } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { ifProp } from 'styled-tools';
import { breakpoints, colors, fonts } from 'styles';
var hasCover = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"], ["\n  height: ", ";\n\n  ",
    ";\n\n  ",
    ";\n\n  ",
    ";\n"])), rem(90), media.between('small', 'medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: ", ";\n  "], ["\n    height: ", ";\n  "])), rem(110)), media.greaterThan('medium')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: ", ";\n  "], ["\n    height: ", ";\n  "])), rem(130)), ifProp('hasCover', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: ", ";\n      height: ", ";\n\n      ", ";\n\n      ", ";\n    "], ["\n      background-color: ", ";\n      height: ", ";\n\n      ",
    ";\n\n      ",
    ";\n    "])), colors.base.black, rem(125), media.between('small', 'medium')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        height: ", ";\n      "], ["\n        height: ", ";\n      "])), rem(150)), media.greaterThan('medium')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        height: ", ";\n      "], ["\n        height: ", ";\n      "])), rem(175)))));
export var HeaderWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n"], ["\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n"])), breakpoints.medium);
export var HeaderContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  padding-bottom: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  position: relative;\n\n  ", ";\n"], ["\n  background-color: ", ";\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  padding-bottom: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  position: relative;\n\n  ", ";\n"])), colors.base.secondary, rem(20), rem(20), rem(8), hasCover);
export var Title = styled.h3(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n  position: relative;\n  z-index: 1;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-align: center;\n  position: relative;\n  z-index: 1;\n"])), colors.base.white, rem(18), fonts.weight.semibold, rem(8));
export var Score = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: 0 ", ";\n  height: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  position: relative;\n  z-index: 1;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background: ", ";\n  border: ", " solid ", ";\n  border-radius: ", ";\n  padding: 0 ", ";\n  height: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  position: relative;\n  z-index: 1;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), colors.base.primary, rem(1), shade(0.3, colors.base.primary), rem(15), rem(12), rem(30), colors.base.white, rem(14), fonts.weight.semibold);
export var ImageContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  overflow: hidden;\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  opacity: 0.4;\n\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n\n  ", ";\n"], ["\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  overflow: hidden;\n  margin: 0 auto;\n  max-width: ", ";\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  opacity: 0.4;\n\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n\n  ", ";\n"])), rem(20), rem(20), breakpoints.medium, hasCover);
export var ChallengeImage = styled.img(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n"], ["\n  display: flex;\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n"])));
export var JaccedeLogo = styled.img(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  object-fit: contain;\n  width: 100%;\n  height: 60%;\n  opacity: 0.1;\n"], ["\n  display: flex;\n  object-fit: contain;\n  width: 100%;\n  height: 60%;\n  opacity: 0.1;\n"])));
export var ButtonWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  margin-top: ", ";\n  max-width: ", ";\n  position: relative;\n  z-index: 1;\n\n  button {\n    margin-right: ", ";\n    margin-left: auto;\n  }\n"], ["\n  display: flex;\n  margin-top: ", ";\n  max-width: ", ";\n  position: relative;\n  z-index: 1;\n\n  button {\n    margin-right: ", ";\n    margin-left: auto;\n  }\n"])), rem(-14), breakpoints.medium, rem(20));
export var EditCoverWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  z-index: 1;\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  z-index: 1;\n"])), rem(16), rem(16));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
