import { UNSUBSCRIBE_USER_FAILURE, UNSUBSCRIBE_USER_REQUEST, UNSUBSCRIBE_USER_SUCCESS, } from './constants';
export var unsubscribeUserRequest = function (payload) { return ({
    type: UNSUBSCRIBE_USER_REQUEST,
    payload: payload,
}); };
export var unsubscribeUserSuccess = function () { return ({
    type: UNSUBSCRIBE_USER_SUCCESS,
}); };
export var unsubscribeUserFailure = function (payload) { return ({
    type: UNSUBSCRIBE_USER_FAILURE,
    payload: payload,
}); };
