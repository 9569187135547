import { UPLOAD_COVER_IMAGE_TEAM_CLEAR_FAILURE, UPLOAD_COVER_IMAGE_TEAM_FAILURE, UPLOAD_COVER_IMAGE_TEAM_REQUEST, UPLOAD_COVER_IMAGE_TEAM_SUCCESS, } from './constants';
export var uploadCoverImageTeamRequest = function (payload) { return ({
    type: UPLOAD_COVER_IMAGE_TEAM_REQUEST,
    payload: payload,
}); };
export var uploadCoverImageTeamFailure = function (payload) { return ({
    type: UPLOAD_COVER_IMAGE_TEAM_FAILURE,
    payload: payload,
}); };
export var uploadCoverImageTeamSuccess = function (payload) { return ({
    type: UPLOAD_COVER_IMAGE_TEAM_SUCCESS,
    payload: payload,
}); };
export var uploadCoverImageTeamClearFailure = function () { return ({
    type: UPLOAD_COVER_IMAGE_TEAM_CLEAR_FAILURE,
}); };
