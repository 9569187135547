import produce from 'immer';
import { ACCOUNT_ACTIVATION_FAILURE, ACCOUNT_ACTIVATION_REQUEST, ACCOUNT_ACTIVATION_SUCCESS, ACCOUNT_ACTIVATION_TOGGLE_VISIBLE, } from './constants';
export var initialState = {
    visible: false,
    loading: false,
    hasError: false,
    error: undefined,
};
var accountActivationReducer = produce(function (draft, action) {
    switch (action.type) {
        case ACCOUNT_ACTIVATION_REQUEST:
            draft.visible = true;
            draft.loading = true;
            draft.hasError = false;
            draft.error = undefined;
            break;
        case ACCOUNT_ACTIVATION_FAILURE:
            draft.visible = true;
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            break;
        case ACCOUNT_ACTIVATION_SUCCESS:
            draft.visible = true;
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            break;
        case ACCOUNT_ACTIVATION_TOGGLE_VISIBLE:
            draft.visible = !draft.visible;
            break;
        default:
    }
}, initialState);
export default accountActivationReducer;
