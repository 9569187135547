/**
 * PlacesAutocomplete Messages
 *
 * This contains all the text for the PlacesAutocomplete component.
 */
import { defineMessages } from 'react-intl';
export var scope = 'components.placesAutocomplete';
var messages = defineMessages({
    googlePlaceIdLabel: { id: scope + ".googlePlaceIdLabel" },
    googlePlaceIdPlaceholder: { id: scope + ".googlePlaceIdPlaceholder" },
});
export default messages;
