import { Button } from 'components/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAction, useSelector } from 'utils/hooks';
import { ignoreSuggestedPlaces } from '../actions';
import { makeSelectorSuggestedPlaces } from '../selectors';
import { ModalButtonWrapper } from '../styles';
import { SuggestionList } from './SuggestionList';
export var SuggestedPlaces = function () {
    var suggestedPlaces = useSelector(makeSelectorSuggestedPlaces());
    var ignoreSuggestedPlacesCallback = useAction(ignoreSuggestedPlaces);
    return (React.createElement(React.Fragment, null,
        React.createElement(SuggestionList, { places: suggestedPlaces }),
        React.createElement(ModalButtonWrapper, null,
            React.createElement(Button, { onClick: ignoreSuggestedPlacesCallback },
                React.createElement(FormattedMessage, { id: "pages.explorePlacesPage.newPlaceModal.suggestionButton" })))));
};
