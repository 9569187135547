var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { rem } from 'polished';
import React, { memo } from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'styles';
export var DesktopTitle = memo(function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Heading, __assign({}, props), children));
});
var Heading = styled.h2(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  max-width: ", ";\n  position: relative;\n\n  &::after {\n    background-color: ", ";\n    border-radius: ", ";\n    bottom: -", ";\n    content: '';\n    height: ", ";\n    left: 0;\n    position: absolute;\n    width: ", ";\n  }\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n  max-width: ", ";\n  position: relative;\n\n  &::after {\n    background-color: ", ";\n    border-radius: ", ";\n    bottom: -", ";\n    content: '';\n    height: ", ";\n    left: 0;\n    position: absolute;\n    width: ", ";\n  }\n"])), rem(34), fonts.weight.bold, rem(41), rem(266), function (_a) {
    var underline = _a.underline;
    return underline || colors.base.warning;
}, rem(4), rem(14), rem(5), rem(60));
var templateObject_1;
