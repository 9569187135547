import produce from 'immer';
import { CHALLENGES_JOIN_TEAM_CLEAR_FAILURE, CHALLENGES_JOIN_TEAM_CLEAR_SUCCESS, CHALLENGES_JOIN_TEAM_FAILURE, CHALLENGES_JOIN_TEAM_REQUEST, CHALLENGES_JOIN_TEAM_SUCCESS, } from './constants';
export var addCurrentUserJoinTeamInitialState = {
    loading: false,
    hasError: false,
    error: undefined,
    hasSuccess: false,
};
export var addCurrentUserJoinTeamReducer = produce(function (draft, action) {
    switch (action.type) {
        case CHALLENGES_JOIN_TEAM_REQUEST:
            draft.loading = true;
            break;
        case CHALLENGES_JOIN_TEAM_FAILURE:
            draft.loading = false;
            draft.hasError = true;
            draft.error = action.payload;
            draft.hasSuccess = false;
            break;
        case CHALLENGES_JOIN_TEAM_CLEAR_FAILURE:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.hasSuccess = false;
            break;
        case CHALLENGES_JOIN_TEAM_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.hasSuccess = true;
            break;
        case CHALLENGES_JOIN_TEAM_CLEAR_SUCCESS:
            draft.loading = false;
            draft.hasError = false;
            draft.error = undefined;
            draft.hasSuccess = false;
            break;
        default:
    }
}, addCurrentUserJoinTeamInitialState);
