var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'components';
import { rem } from 'polished';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors } from 'styles';
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint200,
});
export var Content = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  bottom: ", ";\n  left: 0;\n  right: 0;\n\n  display: flex;\n  flex-direction: column;\n"], ["\n  position: absolute;\n  bottom: ", ";\n  left: 0;\n  right: 0;\n\n  display: flex;\n  flex-direction: column;\n"])), rem(40));
export var SeeInListButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-self: flex-end;\n  margin-right: ", ";\n  margin-bottom: ", ";\n"], ["\n  align-self: flex-end;\n  margin-right: ", ";\n  margin-bottom: ", ";\n"])), rem(16), rem(16));
export var Scrollbar = styled(ScrollContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var CardPlacesWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &:first-child {\n    margin-left: ", ";\n  }\n\n  > div {\n    margin-right: ", ";\n    max-width: ", ";\n    border: ", " solid ", ";\n    border-radius: ", ";\n    overflow: hidden;\n  }\n"], ["\n  &:first-child {\n    margin-left: ", ";\n  }\n\n  > div {\n    margin-right: ", ";\n    max-width: ", ";\n    border: ", " solid ", ";\n    border-radius: ", ";\n    overflow: hidden;\n  }\n"])), rem(16), rem(16), rem(220), rem(1), borderColor, rem(8));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
