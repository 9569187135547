var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var textColor = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var subsSizeColor = theme('mode', {
    light: colors.base.secondary,
    dark: colors.base.white,
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint800,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border-top: 1px solid ", ";\n"], ["\n  display: flex;\n  border-top: 1px solid ", ";\n"])), borderColor);
export var SubscriptionButton = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  padding: ", " 0;\n"], ["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  padding: ", " 0;\n"])), rem(24));
export var SubscriptionSize = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n  padding-right: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n  padding-right: ", ";\n"])), fonts.weight.bold, rem(17), subsSizeColor, rem(6));
export var SubscriptionText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n"])), fonts.weight.semibold, rem(14), textColor);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
