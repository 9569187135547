import { Button, Spin } from 'components';
import { Bookmark } from 'icons/mono';
import { rem } from 'polished';
import React from 'react';
import { colors } from 'styles';
import { Container } from './styles';
export var BookmarkButton = function (_a) {
    var loading = _a.loading, addToBookmark = _a.addToBookmark, removeFromBookmark = _a.removeFromBookmark, inBookmark = _a.inBookmark;
    return (React.createElement(Container, null,
        React.createElement(Spin, { spinning: loading }, inBookmark ? (React.createElement(Button, { shape: "circle", size: "icon", variant: "primary", onClick: function (e) {
                e.preventDefault();
                removeFromBookmark();
            }, disabled: loading, title: "Remove from favorites" },
            React.createElement(Bookmark, { size: rem(24), color: colors.base.white }))) : (React.createElement(Button, { shape: "circle", size: "icon", variant: "ghost", onClick: function (e) {
                e.preventDefault();
                addToBookmark();
            }, disabled: loading, title: "Add as favorite" },
            React.createElement(Bookmark, { size: rem(24) }))))));
};
