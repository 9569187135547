var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Facebook, Twitter } from 'icons/mono';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Container, ExternalLink, SocialNetworks } from './styles';
export var Sharing = function () {
    var location = useLocation();
    var _a = __read(useState(), 2), url = _a[0], setUrl = _a[1];
    useEffect(function () {
        var siteUrl = process.env.RAZZLE_APP_SITE_URL;
        if (siteUrl) {
            if (siteUrl.endsWith('/')) {
                siteUrl = siteUrl.slice(0, -1);
            }
            setUrl("" + siteUrl + location.pathname);
        }
    }, []);
    return (React.createElement(Container, null, url && (React.createElement(SocialNetworks, null,
        React.createElement(ExternalLink, null,
            React.createElement(TwitterShareButton, { url: url, title: "Share on Twitter" },
                React.createElement(Twitter, { size: rem(20) }))),
        React.createElement(ExternalLink, null,
            React.createElement(FacebookShareButton, { url: url, title: "Share on Facebook" },
                React.createElement(Facebook, { size: rem(20) })))))));
};
