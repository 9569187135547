import { createSelector } from 'reselect';
import { subFooterLinksInitialState } from './reducer';
export var selectSubFooterLinksDomain = function (state) {
    return state.subFooterLinks || subFooterLinksInitialState;
};
export var makeSelectSubFooterLinksLoading = function () {
    return createSelector(selectSubFooterLinksDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectSubFooterLinksHasError = function () {
    return createSelector(selectSubFooterLinksDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectSubFooterLinksError = function () {
    return createSelector(selectSubFooterLinksDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectSubFooterLinksSearchByTypeOfPlace = function () {
    return createSelector(selectSubFooterLinksDomain, function (_a) {
        var searchByTypeOfPlace = _a.searchByTypeOfPlace;
        return searchByTypeOfPlace;
    });
};
export var makeSelectSubFooterLinksFindPlacesInFrance = function () {
    return createSelector(selectSubFooterLinksDomain, function (_a) {
        var findPlacesInFrance = _a.findPlacesInFrance;
        return findPlacesInFrance;
    });
};
export var makeSelectSubFooterLinksFindPlacesInEurope = function () {
    return createSelector(selectSubFooterLinksDomain, function (_a) {
        var findPlacesInEurope = _a.findPlacesInEurope;
        return findPlacesInEurope;
    });
};
export var makeSelectSubFooterLinksFindPlacesInWorld = function () {
    return createSelector(selectSubFooterLinksDomain, function (_a) {
        var findPlacesInWorld = _a.findPlacesInWorld;
        return findPlacesInWorld;
    });
};
export var makeSubFooterLinks = function () {
    return createSelector(selectSubFooterLinksDomain, function (subState) { return subState; });
};
export default makeSubFooterLinks;
