var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { useSelector } from 'hooks';
import React, { memo } from 'react';
import { PageHeaderWrapper } from './styles';
export var PageHeader = memo(function (props) {
    var keyboard = useSelector(makeSelectUseKeyboard());
    return React.createElement(PageHeaderWrapper, __assign({ isKeyboardMode: keyboard }, props));
});
