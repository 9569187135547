var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled from 'styled-components';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.base.white,
});
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-top: ", ";\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-top: ", ";\n  margin-bottom: ", ";\n"])), rem(60), rem(20));
export var Message = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  max-width: ", ";\n  margin: ", " 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n  max-width: ", ";\n  margin: ", " 0;\n"])), color, rem(16), fonts.weight.semibold, rem(250), rem(20));
var templateObject_1, templateObject_2, templateObject_3;
