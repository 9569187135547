var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Popover } from 'components';
import { fetchMyNotificationsRequest } from 'containers/Profile/actions/myNotificationsActions';
import makeMyNotifications from 'containers/Profile/selectors/myNotificationsSelectors';
import { useAction, useSelector } from 'hooks';
import { Bell } from 'icons/mono';
import { rem } from 'polished';
import React, { memo, useEffect, useState } from 'react';
import { NotificationContent } from './NotificationContent';
import { NotificationIcon, Target } from './styles';
var PER_PAGE = 5;
export var NotificationMenu = memo(function () {
    var _a = __read(useState(1), 2), myNotificationPage = _a[0], setMyNotificationPage = _a[1];
    var _b = __read(useState(false), 2), modalVisible = _b[0], setModalVisible = _b[1];
    var toggleModal = function () { return setModalVisible(!modalVisible); };
    var fetchMyNotifications = useAction(function (payload) {
        return fetchMyNotificationsRequest(payload);
    });
    var notifications = useSelector(makeMyNotifications());
    // TODO: Put a badge about total unread messages ob BELL ICON
    // const ureadNotifications = useSelector(
    //   makeSelectMyNotificationsTotalUnread(),
    // );
    var loadMyNotifications = function () {
        var payload = {
            pagination: {
                page: myNotificationPage,
                perPage: PER_PAGE,
            },
        };
        fetchMyNotifications(payload);
    };
    useEffect(function () {
        if (myNotificationPage > 1 && myNotificationPage <= notifications.pages)
            loadMyNotifications();
    }, [myNotificationPage]);
    return (React.createElement(Popover, { content: React.createElement(NotificationContent, { notifications: notifications.items, hasMore: myNotificationPage <= notifications.pages, loading: notifications.loading, currentPage: myNotificationPage, loadMore: function (page) { return setMyNotificationPage(page); } }), trigger: "click", placement: "bottomRight", visible: modalVisible, onVisibleChange: toggleModal },
        React.createElement(Target, { variant: "link", tabIndex: 0, "aria-haspopup": "true" },
            React.createElement(NotificationIcon, { hasNotifications: notifications.items.some(function (item) { return !item.read; }) },
                React.createElement(Bell, { size: rem(24) })))));
});
