import { createSelector } from 'reselect';
import { unsubscribeUserInitialState } from './reducer';
export var selectUnsubscribeUserDomain = function (state) {
    return state.unsubscribeUser || unsubscribeUserInitialState;
};
export var makeSelectUnsubscribeLoading = function () {
    return createSelector(selectUnsubscribeUserDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeUnsubscribe = function () {
    return createSelector(selectUnsubscribeUserDomain, function (subState) { return subState; });
};
export default makeUnsubscribe;
