import { AUTH_LOGIN_FAILURE, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_FAILURE, AUTH_LOGOUT_REQUEST, AUTH_LOGOUT_SUCCESS, } from './constants';
export var authLoginSuccess = function (payload) { return ({
    type: AUTH_LOGIN_SUCCESS,
    payload: payload,
}); };
export var authLoginFailure = function () { return ({
    type: AUTH_LOGIN_FAILURE,
}); };
export var authLogoutRequest = function () { return ({
    type: AUTH_LOGOUT_REQUEST,
}); };
export var authLogoutSuccess = function () { return ({
    type: AUTH_LOGOUT_SUCCESS,
}); };
export var authLogoutFailure = function (payload) { return ({
    type: AUTH_LOGOUT_FAILURE,
    payload: payload,
}); };
