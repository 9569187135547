var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var background = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint800,
});
var color = theme('mode', {
    light: colors.space.tint900,
    dark: colors.space.tint100,
});
var border = theme('mode', {
    light: colors.space.tint400,
    dark: colors.carbon.tint500,
});
export var Container = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: ", " solid ", ";\n  color: ", ";\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  position: sticky;\n  top: ", ";\n  width: 100%;\n  z-index: 1000;\n\n  ", ";\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: ", " solid ", ";\n  color: ", ";\n  display: flex;\n  height: ", ";\n  justify-content: center;\n  position: sticky;\n  top: ", ";\n  width: 100%;\n  z-index: 1000;\n\n  ",
    ";\n"])), background, rem(1), border, color, rem(57), rem(80), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    top: ", ";\n  "], ["\n    top: ", ";\n  "])), rem(60)));
export var BackButton = styled(NavLink)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  background-color: initial;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  left: ", ";\n  outline: none;\n  padding: ", ";\n  position: absolute;\n"], ["\n  align-items: center;\n  background-color: initial;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  left: ", ";\n  outline: none;\n  padding: ", ";\n  position: absolute;\n"])), colors.space.tint600, rem(12), rem(4));
export var Title = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-left: ", ";\n  margin-right: ", ";\n  text-align: center;\n\n  ", "\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-left: ", ";\n  margin-right: ", ";\n  text-align: center;\n\n  ",
    "\n"])), rem(16), fonts.weight.semibold, rem(48), rem(16), media.greaterThan('medium')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: ", ";\n  "], ["\n    font-size: ", ";\n  "])), rem(24)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
