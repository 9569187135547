import { avatarImage1 } from 'assets/images';
import { Avatar } from 'components';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { makeSelectUserSlug } from 'containers/Auth/selectors';
import { paths } from 'containers/RoutesProvider/routes';
import { useSelector } from 'hooks';
import { Medal200Desktop } from 'icons/medal';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { BlockTitle, Content, MedalWrapper, MembersScoreBoardContainer, Podium, Score, Username, } from './styles';
export var MembersScoreBoard = function (_a) {
    var _b = _a.members, members = _b === void 0 ? [] : _b, isLoading = _a.isLoading;
    var formatMessage = useIntl().formatMessage;
    var history = useHistory();
    var location = useLocation();
    var userSlug = useSelector(makeSelectUserSlug());
    var handleRedirectUserProfile = function (slug) {
        var redirectTo = userSlug === slug
            ? paths.profilePage
            : paths.usersPage.replace(':userSlug', slug);
        history.push(redirectTo, { from: location.pathname });
    };
    if (members.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null, isLoading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(MembersScoreBoardContainer, null,
        React.createElement(BlockTitle, null, formatMessage({
            id: 'containers.challengesDetail.membersScoreBoard',
        })),
        React.createElement(Content, null, map(members, function (member, index) {
            var _a;
            return (React.createElement(Podium, { key: member.slug, position: "pos" + (index + 1), onClick: function () { return handleRedirectUserProfile(member.slug); } },
                React.createElement(MedalWrapper, null,
                    React.createElement(Medal200Desktop, { size: rem(32) })),
                React.createElement(Avatar, { src: ((_a = member === null || member === void 0 ? void 0 : member.avatar) === null || _a === void 0 ? void 0 : _a.urlThumb) || avatarImage1, alt: member.firstName
                        ? member.firstName + " profile image"
                        : 'User profile image', size: "medium" }),
                React.createElement(Username, null, "@" + member.slug),
                React.createElement(Score, null,
                    member.score,
                    formatMessage({
                        id: 'containers.profile.user.points',
                    }))));
        }))))));
};
