var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var AddUser = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M22.1333 19.9333C25.6527 19.9333 28.762 16.6193 29.0667 12.5453C29.218 10.4987 28.576 8.59 27.2587 7.172C25.9553 5.77133 24.1333 5 22.1333 5C20.1173 5 18.294 5.76667 17 7.15867C15.6913 8.566 15.0533 10.4787 15.2 12.544C15.4993 16.6187 18.608 19.9333 22.1333 19.9333Z", fill: color }),
        React.createElement("path", { d: "M35.9587 32.184C35.396 29.0627 33.6393 26.4407 30.8793 24.6007C28.428 22.9667 25.322 22.0667 22.1333 22.0667C18.9447 22.0667 15.8387 22.9667 13.3873 24.6C10.6273 26.44 8.87067 29.062 8.308 32.1833C8.17933 32.8987 8.354 33.606 8.78733 34.124C9.18539 34.6021 9.77795 34.875 10.4 34.8667H33.8667C34.4891 34.8755 35.0822 34.6029 35.4807 34.1247C35.9127 33.6067 36.0873 32.8993 35.9587 32.184Z", fill: color }),
        React.createElement("path", { d: "M9.86667 22.0667V19.4H12.5333C13.1224 19.4 13.6 18.9224 13.6 18.3333C13.6 17.7442 13.1224 17.2667 12.5333 17.2667H9.86667V14.6C9.86667 14.0109 9.3891 13.5333 8.8 13.5333C8.2109 13.5333 7.73333 14.0109 7.73333 14.6V17.2667H5.06667C4.47756 17.2667 4 17.7442 4 18.3333C4 18.9224 4.47756 19.4 5.06667 19.4H7.73333V22.0667C7.73333 22.6558 8.2109 23.1333 8.8 23.1333C9.3891 23.1333 9.86667 22.6558 9.86667 22.0667Z", fill: color })));
});
