import { createSelector } from 'reselect';
import { homeLatestNewsInitialState } from './reducer';
export var selectHomeLatestNewsDomain = function (state) {
    return state.homeLatestNews || homeLatestNewsInitialState;
};
export var makeSelectHomeLatestNewsLoading = function () {
    return createSelector(selectHomeLatestNewsDomain, function (_a) {
        var loading = _a.loading;
        return loading;
    });
};
export var makeSelectHomeLatestNewsHasError = function () {
    return createSelector(selectHomeLatestNewsDomain, function (_a) {
        var hasError = _a.hasError;
        return hasError;
    });
};
export var makeSelectHomeLatestNewsError = function () {
    return createSelector(selectHomeLatestNewsDomain, function (_a) {
        var error = _a.error;
        return error;
    });
};
export var makeSelectHomeLatestNewsItems = function () {
    return createSelector(selectHomeLatestNewsDomain, function (_a) {
        var items = _a.items;
        return items;
    });
};
export var makeHomeLatestNews = function () {
    return createSelector(selectHomeLatestNewsDomain, function (subState) { return subState; });
};
export default makeHomeLatestNews;
