var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Close } from 'icons/mono';
import { rem } from 'polished';
import React, { memo } from 'react';
import { colors } from 'styles';
import { ModalWrapper } from './styles';
export var Modal = memo(function (props) { return (React.createElement(ModalWrapper, __assign({ closeIcon: React.createElement(Close, { size: rem(16), color: colors.space.tint700 }), zIndex: 4000, centered: true }, props))); });
