var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { notification } from 'antd';
import * as api from 'api';
import { Alert, Button, PasswordField, PasswordScore } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import makeSelectRouterProvider from 'containers/RoutesProvider/selectors';
import { signInUserChangePassword } from 'containers/SignInEmail/actions';
import { Form, Formik } from 'formik';
import { useSelector } from 'hooks';
import split from 'lodash/split';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import messages from './messages';
import changePasswordSchema from './schema';
export var ChangePassword = function () {
    var formatMessage = useIntl().formatMessage;
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var router = useSelector(makeSelectRouterProvider());
    var history = useHistory();
    var location = useLocation();
    var dispath = useDispatch();
    var changePassword = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var search, token, user, payload, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    search = router.location.search;
                    token = split(search, '=')[1];
                    return [4 /*yield*/, api.auth.changePassword(value.password, value.confirmPassword, token)];
                case 1:
                    user = _b.sent();
                    payload = {
                        email: user.email,
                        password: value.password,
                    };
                    dispath(signInUserChangePassword(payload));
                    history.push(paths.homePage, { from: location.pathname });
                    notification.success({
                        message: formatMessage(messages.success),
                        description: formatMessage(messages.successfully),
                    });
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    notification.error({
                        message: formatMessage(messages.error),
                        description: formatMessage(messages.errorMessage),
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var checkIsExpired = function (token) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.auth.isPasswordExpired(token)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    history.push(paths.forgotPasswordPage + "?isExpired=true", {
                        from: location.pathname,
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var search = router.location.search;
        var token = split(search, '=')[1];
        checkIsExpired(token);
    }, []);
    return (React.createElement(React.Fragment, null,
        isLoading && (React.createElement(Alert, { message: React.createElement(React.Fragment, null, formatMessage({
                id: 'containers.changePasswordPage.checkingPasswordIsLoading',
            })), type: "info", style: { marginBottom: rem(16) } })),
        React.createElement(Formik, { validationSchema: function () { return changePasswordSchema(formatMessage); }, onSubmit: function (values) { return changePassword(values); }, initialValues: {
                password: '',
                confirmPassword: '',
            } }, function (_a) {
            var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur, values = _a.values, isSubmitting = _a.isSubmitting;
            return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
                React.createElement(PasswordField, { required: true, name: "password", label: formatMessage({
                        id: 'containers.changePasswordPage.newPasswordLabel',
                    }), value: values.password, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting || isLoading }),
                values.password.length > 0 && (React.createElement(PasswordScore, { password: values.password })),
                React.createElement(PasswordField, { required: true, name: "confirmPassword", label: formatMessage({
                        id: 'containers.changePasswordPage.confirmPasswordLabel',
                    }), value: values.confirmPassword, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting || isLoading }),
                React.createElement(Button, { type: "submit", disabled: isSubmitting || isLoading, full: true }, formatMessage({
                    id: 'containers.changePasswordPage.submitButton',
                }))));
        })));
};
export default ChangePassword;
