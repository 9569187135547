var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import theme from 'styled-theming';
import { colors, fonts } from 'styles';
var media = generateMedia({
    large: '1096px',
    medium: '768px',
});
var borderColor = theme('mode', {
    light: colors.space.tint200,
    dark: colors.carbon.tint900,
});
var textColor = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var backgroundColor = theme('mode', {
    light: colors.base.white,
    dark: colors.carbon.tint700,
});
export var Container = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: 1px solid ", ";\n  cursor: pointer;\n  margin: ", ";\n  width: ", ";\n\n  text-decoration: none;\n\n  ", ";\n"], ["\n  border-radius: ", ";\n  border: 1px solid ", ";\n  cursor: pointer;\n  margin: ", ";\n  width: ", ";\n\n  text-decoration: none;\n\n  ",
    ";\n"])), rem(6), borderColor, rem(10), rem(165), media.lessThan('medium')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: calc(50% - ", ");\n  "], ["\n    width: calc(50% - ", ");\n  "])), rem(20)));
export var ImageWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n  position: relative;\n  width: 100%;\n"], ["\n  height: ", ";\n  position: relative;\n  width: 100%;\n"])), rem(100));
export var PlaceImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: 100%;\n  object-fit: cover;\n  width: 100%;\n"], ["\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: 100%;\n  object-fit: cover;\n  width: 100%;\n"])), rem(6), rem(6));
export var CategoryIconWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: 1px solid ", ";\n  overflow: hidden;\n  position: absolute;\n  bottom: ", ";\n  left: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"], ["\n  border-radius: ", ";\n  border: 1px solid ", ";\n  overflow: hidden;\n  position: absolute;\n  bottom: ", ";\n  left: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > svg {\n    flex-shrink: 0;\n  }\n"])), rem(4), colors.base.white, rem(10), rem(10), rem(30), rem(30));
export var Content = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  padding: ", ";\n  position: relative;\n"], ["\n  background: ", ";\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  padding: ", ";\n  position: relative;\n"])), backgroundColor, rem(6), rem(6), rem(10));
export var PlaceName = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n\n  display: block;\n  position: absolute;\n  max-width: calc(87%);\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n\n  display: block;\n  position: absolute;\n  max-width: calc(87%);\n\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n"])), rem(17), fonts.weight.bold, textColor);
export var CategoryLabel = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n\n  margin-top: ", ";\n"], ["\n  font-size: ", ";\n  color: ", ";\n\n  margin-top: ", ";\n"])), rem(13), textColor, rem(30));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
