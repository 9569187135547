/* eslint-disable @typescript-eslint/camelcase */
import { axios } from 'api';
var versionV4 = '/v4';
export var endpoints = {
    getChallenges: versionV4 + "/challenges",
    getChallengeByCode: function (code) { return versionV4 + "/challenges/" + code; },
    getChallengeBySlug: function (challengeSlug) {
        return versionV4 + "/challenges/" + challengeSlug;
    },
    getChallengeMembers: function (challengeSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/members";
    },
    inviteUserOnChallenge: function (challengeSlug) {
        return versionV4 + "/challenges/" + challengeSlug;
    },
    listTeamsOnChallenges: function (challengeSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams";
    },
    createNewTeam: function (challengeSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams";
    },
    getTeamOnChallenge: function (challengeSlug, teamSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams/" + teamSlug;
    },
    inviteUserOnTeam: function (challengeSlug, teamSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams/" + teamSlug + "/invite";
    },
    joinTeamChat: function (challengeSlug, teamSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams/" + teamSlug + "/join-chat";
    },
    addMediaOnTeam: function (challengeSlug, teamSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams/" + teamSlug + "/medias";
    },
    getMembersOnTeam: function (challengeSlug, teamSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams/" + teamSlug + "/members";
    },
    addCurrentUserOnTeam: function (challengeSlug, teamSlug) {
        return versionV4 + "/challenges/" + challengeSlug + "/teams/" + teamSlug + "/members";
    },
};
export default {
    getChallenges: function () { return axios.get(endpoints.getChallenges); },
    getChallengeByCode: function (code) {
        var config = {
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                by_code: 1,
            },
        };
        return axios.get(endpoints.getChallengeByCode(code), config);
    },
    getChallengeBySlug: function (challengeSlug) {
        var config = {
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                by_code: 0,
            },
        };
        return axios.get(endpoints.getChallengeBySlug(challengeSlug), config);
    },
    getChallengeMembers: function (payload) {
        var config = {
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                per_page: 3,
                page: 1,
            },
        };
        return axios.get(endpoints.getChallengeMembers(payload.challengeSlug), config);
    },
    inviteUserOnChallenge: function (challengeSlug, payload) { return axios.post(endpoints.inviteUserOnChallenge(challengeSlug), payload); },
    listTeamsOnChallenges: function (payload) {
        var config = {
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                per_page: 20,
                page: payload.pagination.page,
            },
        };
        return axios.get(endpoints.listTeamsOnChallenges(payload.challengeSlug), config);
    },
    createNewTeam: function (challengeSlug, payload) {
        return axios.post(endpoints.createNewTeam(challengeSlug), payload);
    },
    getTeamOnChallenge: function (payload) {
        return axios.get(endpoints.getTeamOnChallenge(payload.challengeSlug, payload.teamSlug));
    },
    inviteUserOnTeam: function (challengeSlug, teamSlug, payload) {
        var data = {
            emails: [payload.emails],
        };
        return axios.post(endpoints.inviteUserOnTeam(challengeSlug, teamSlug), data);
    },
    joinTeamChat: function (challengeSlug, teamSlug) {
        return axios.post(endpoints.getMembersOnTeam(challengeSlug, teamSlug));
    },
    addMediaOnTeam: function (payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        var config = { headers: { 'content-type': 'multipart/form-data' } };
        var url = endpoints.addMediaOnTeam(payload.challengeSlug, payload.teamSlug);
        return axios.post(url, formData, config);
    },
    getMembersOnTeam: function (payload) {
        var config = {
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                per_page: 20,
                page: payload.pagination.page,
            },
        };
        return axios.get(endpoints.getMembersOnTeam(payload.challengeSlug, payload.teamSlug), config);
    },
    addCurrentUserOnTeam: function (challengeSlug, teamSlug, payload) {
        return axios.post(endpoints.getTeamOnChallenge(challengeSlug, teamSlug), payload);
    },
};
