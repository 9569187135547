var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Container } from './styles';
export var List = memo(function (_a) {
    var size = _a.size, color = _a.color, props = __rest(_a, ["size", "color"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 40 40", "aria-hidden": "true" }, props),
        props.title && React.createElement("title", null, props.title),
        React.createElement("path", { d: "M12.4528 13.2264H34.1887C35.1891 13.2264 36 12.4155 36 11.4151C36 10.4147 35.1891 9.60377 34.1887 9.60377H12.4528C11.4525 9.60377 10.6415 10.4147 10.6415 11.4151C10.6415 12.4155 11.4525 13.2264 12.4528 13.2264Z", fill: color }),
        React.createElement("path", { d: "M34.1887 18.0566H12.4528C11.4525 18.0566 10.6415 18.8675 10.6415 19.8679C10.6415 20.8683 11.4525 21.6792 12.4528 21.6792H34.1887C35.1891 21.6792 36 20.8683 36 19.8679C36 18.8675 35.1891 18.0566 34.1887 18.0566Z", fill: color }),
        React.createElement("path", { d: "M34.1887 26.5094H12.4528C11.4525 26.5094 10.6415 27.3204 10.6415 28.3208C10.6415 29.3211 11.4525 30.1321 12.4528 30.1321H34.1887C35.1891 30.1321 36 29.3211 36 28.3208C36 27.3204 35.1891 26.5094 34.1887 26.5094Z", fill: color }),
        React.createElement("path", { d: "M6.41509 9C5.0834 9 4 10.0834 4 11.4151C4 12.7468 5.0834 13.8302 6.41509 13.8302C7.74679 13.8302 8.83019 12.7468 8.83019 11.4151C8.83019 10.0834 7.74679 9 6.41509 9Z", fill: color }),
        React.createElement("path", { d: "M6.41509 17.4528C5.0834 17.4528 4 18.5362 4 19.8679C4 21.1996 5.0834 22.283 6.41509 22.283C7.74679 22.283 8.83019 21.1996 8.83019 19.8679C8.83019 18.5362 7.74679 17.4528 6.41509 17.4528Z", fill: color }),
        React.createElement("path", { d: "M6.41509 25.9057C5.0834 25.9057 4 26.9891 4 28.3208C4 29.6525 5.0834 30.7358 6.41509 30.7358C7.74679 30.7358 8.83019 29.6525 8.83019 28.3208C8.83019 26.9891 7.74679 25.9057 6.41509 25.9057Z", fill: color })));
});
