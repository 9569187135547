var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { colors } from 'styles';
import { Container } from '../styles';
export var Medal200Mobile = memo(function (_a) {
    var size = _a.size, dark = _a.dark, props = __rest(_a, ["size", "dark"]);
    return (React.createElement(Container, __assign({ width: size, height: size, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "chall-1", transform: "translate(2.000000, 2.000000)" },
                React.createElement("path", { d: "M20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.898,47.211 51.477,50.8 C50.798,51.512 50.067,52.173 49.291,52.779 C48.514,53.385 47.691,53.935 46.83,54.422 C43.927,56.063 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 Z", id: "stroke-gray", stroke: "#EFEFEF", strokeWidth: "4" }),
                React.createElement("path", { d: "M46.83,54.422 C43.927,56.064 40.573,57 37,57 L20,57 C8.955,57 0,48.046 0,37 L0,20 C0,8.954 8.955,0 20,0 L37,0 C48.046,0 57,8.954 57,20 L57,37 C57,42.351 54.899,47.211 51.476,50.8", id: "stroke-progress", stroke: dark ? colors.base.secondary : colors.base.warning, strokeWidth: "4", strokeLinecap: "round" }),
                React.createElement("rect", { id: "active-bg", fill: dark ? colors.carbon.tint100 : colors.base.secondary, x: "8", y: "8", width: "41", height: "41", rx: "14" }),
                React.createElement("g", { id: "Chalenges/Badges/Medails-without-number/1", transform: "translate(16.000000, 13.000000)" },
                    React.createElement("g", { id: "Group", transform: "translate(0.672918, 0.288807)" },
                        React.createElement("polygon", { id: "Clip-2", points: "0.627082182 0.274829091 24.3039113 0.274829091 24.3039113 30.4220473 0.627082182 30.4220473" }),
                        React.createElement("path", { d: "M12.4658371,0.274829091 C5.92801891,0.274829091 0.626855273,5.54088364 0.626855273,12.0373564 C0.626855273,15.5792473 2.21068073,18.7480109 4.70441164,20.9044836 L4.70441164,29.7762139 L8.58512436,27.6732867 L12.4658371,25.5697958 L16.3465498,27.6732867 L20.2272625,29.7762139 L20.2272625,20.9050473 C22.7204262,18.7480109 24.3042516,15.5792473 24.3042516,12.0373564 C24.3042516,5.54088364 19.0042225,0.274829091 12.4658371,0.274829091 Z", id: "Fill-1", fillOpacity: "0.2", fill: "#FF9400" }),
                        React.createElement("polygon", { id: "Fill-4", fill: "#EF8E1B", points: "5.09117818 16.448863 5.09117818 28.2733903 12.4657236 24.2772085 19.8402691 28.2733903 19.8402691 16.448863" }),
                        React.createElement("polygon", { id: "Fill-5", fill: "#E08119", points: "8.77845091 16.448863 8.77845091 26.2752994 12.4657236 24.2772085 16.1529964 26.2752994 16.1529964 16.448863" }),
                        React.createElement("path", { d: "M12.4657236,0.894547273 C6.60749818,0.894547273 1.84184,5.62965636 1.84184,11.4503291 C1.84184,17.2710018 6.60749818,22.0049836 12.4657236,22.0049836 C18.3233818,22.0049836 23.08904,17.2710018 23.08904,11.4503291 C23.08904,5.62965636 18.3233818,0.894547273 12.4657236,0.894547273", id: "Fill-6", fill: "#F8E831" }),
                        React.createElement("path", { d: "M12.013664,20.71978 C6.87020218,20.71978 2.684864,16.5618345 2.684864,11.4513436 C2.684864,6.33916182 6.87020218,2.18234364 12.013664,2.18234364 C17.1571258,2.18234364 21.3418967,6.33916182 21.3418967,11.4513436 C21.3418967,16.5618345 17.1571258,20.71978 12.013664,20.71978 M12.013664,1.02407091 C6.21783855,1.02407091 1.51911855,5.69210727 1.51911855,11.4513436 C1.51911855,17.2088891 6.21783855,21.8786164 12.013664,21.8786164 C17.8094895,21.8786164 22.5082095,17.2088891 22.5082095,11.4513436 C22.5082095,5.69210727 17.8094895,1.02407091 12.013664,1.02407091", id: "Fill-8", fill: "#F7D131" }),
                        React.createElement("path", { d: "M12.4657236,21.3852655 C6.9524,21.3852655 2.46697455,16.9291564 2.46697455,11.4511745 C2.46697455,5.97206545 6.9524,1.51652 12.4657236,1.51652 C17.97848,1.51652 22.4639055,5.97206545 22.4639055,11.4511745 C22.4639055,16.9291564 17.97848,21.3852655 12.4657236,21.3852655 M12.4657236,0.274829091 C6.25352,0.274829091 1.21727273,5.27822909 1.21727273,11.4511745 C1.21727273,17.6224291 6.25352,22.6269564 12.4657236,22.6269564 C18.6779273,22.6269564 23.7141745,17.6224291 23.7141745,11.4511745 C23.7141745,5.27822909 18.6779273,0.274829091 12.4657236,0.274829091", id: "Fill-10", fill: "#F8E831" }),
                        React.createElement("polygon", { id: "Fill-12", fill: "#EBC72E", points: "16.8357658 9.36617091 16.7325222 8.07995273 15.4941658 7.69386182 14.8395331 6.58068 13.5552276 6.76611636 12.4791113 6.04522545 12.4683331 6.04522545 11.3922167 6.76611636 10.1079113 6.58068 9.45327855 7.69386182 8.21492218 8.07995273 8.11167855 9.36617091 7.16433309 10.2471345 7.63233309 11.4510618 7.16433309 12.6532982 8.11167855 13.5348255 8.21492218 14.8210436 9.45327855 15.2065709 10.1079113 16.3203164 11.3922167 16.13488 12.4683331 16.8552073 12.4791113 16.8552073 13.5552276 16.13488 14.8395331 16.3203164 15.4941658 15.2065709 16.7325222 14.8210436 16.8357658 13.5348255 17.782544 12.6532982 17.314544 11.4510618 17.782544 10.2471345" })),
                    React.createElement("g", { id: "Group-14", transform: "translate(1.867273, 0.274829)" })),
                React.createElement("path", { d: "M52.9130002,49.34725 C53.2090002,49.74125 53.7760002,49.80325 54.1490002,49.48125 L56.0610002,47.83225 C56.6440002,47.32925 57.5430002,47.79825 57.4630002,48.56425 L56.7080002,55.83025 C56.6630002,56.26325 56.2980002,56.59225 55.8610002,56.59225 L42.6060002,56.59225 C42.1700002,56.59225 41.8050002,56.26325 41.7600002,55.83025 L41.0050002,48.56425 C40.9250002,47.79825 41.8230002,47.32925 42.4060002,47.83225 L44.3180002,49.48125 C44.6910002,49.80325 45.2600002,49.74125 45.5550002,49.34725 L48.5530002,45.34125 C48.8940002,44.88625 49.5740002,44.88625 49.9150002,45.34125 L52.9130002,49.34725 Z", id: "crown", stroke: dark ? colors.carbon.tint900 : colors.base.white, strokeWidth: "2", fill: "#F7CC00" })))));
});
