import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { signUpNewsletter } from './actions';
import { NewsletterForm } from './Form';
import signUpNewsletterSchema, { defaultProps } from './schemas';
export var Newsletter = withFormik({
    mapPropsToValues: function () { return defaultProps; },
    validationSchema: function (props) {
        return signUpNewsletterSchema(props.intl.formatMessage);
    },
    handleSubmit: function (_a, _b) {
        var email = _a.email;
        var props = _b.props, setSubmitting = _b.setSubmitting, resetForm = _b.resetForm, setErrors = _b.setErrors, setStatus = _b.setStatus;
        var payload = {
            email: email,
        };
        var actions = {
            resetForm: resetForm,
            setErrors: setErrors,
            setSubmitting: setSubmitting,
            setStatus: setStatus,
        };
        props.handleSignUpNewsletter(payload, actions);
    },
    displayName: 'Newsletter',
})(NewsletterForm);
var mapStateToProps = function () { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    handleSignUpNewsletter: function (payload, actions) {
        dispatch(signUpNewsletter(payload, actions));
    },
}); };
var NewsletterIntl = injectIntl(Newsletter);
export var ConnectNewsletter = connect(mapStateToProps, mapDispatchToProps)(NewsletterIntl);
