var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import produce from 'immer';
import { ADD_NEW_MESSAGE, GET_CONVERSATION, GET_CONVERSATIONS, GET_HISTORY, LOCK_CONVERSATION_NOTIFICATION, OPTIONS_CHANGED_SUCCESS, QUIT_GROUP_CONVERSATION, SET_CHAT_CONNECTION, SHOW_CONVERSATION, SHOW_CONVERSATIONS, SHOW_HISTORY, UNLOCK_CONVERSATION_NOTIFICATION, } from './contants';
export var initialState = {
    conversations: {
        items: [],
        currentPage: 1,
        pages: 1,
        total: 0,
    },
    history: {
        items: [],
        currentPage: 1,
        pages: 1,
        total: 0,
    },
    messages: [],
    conversationsLoading: false,
    conversationLoading: false,
    conversationOptionsLoading: false,
};
var chatReducer = produce(function (draft, action) {
    var _a, _b;
    switch (action.type) {
        case GET_CONVERSATIONS:
            if (action.payload.silent)
                break;
            draft.conversationsLoading = true;
            break;
        case SHOW_CONVERSATIONS:
            draft.conversations = action.payload;
            draft.conversationsLoading = false;
            break;
        case GET_CONVERSATION:
            draft.conversationLoading = true;
            break;
        case SHOW_CONVERSATION:
            draft.conversation = action.payload;
            draft.conversationLoading = false;
            break;
        case LOCK_CONVERSATION_NOTIFICATION:
        case UNLOCK_CONVERSATION_NOTIFICATION:
        case QUIT_GROUP_CONVERSATION:
            draft.conversationOptionsLoading = true;
            break;
        case OPTIONS_CHANGED_SUCCESS:
            draft.conversationOptionsLoading = false;
            break;
        case GET_HISTORY:
            draft.conversationLoading = true;
            break;
        case SHOW_HISTORY:
            draft.history = action.payload;
            draft.messages = action.payload.items;
            draft.conversationLoading = false;
            break;
        case SET_CHAT_CONNECTION:
            draft.chatConnection = action.payload;
            break;
        case ADD_NEW_MESSAGE:
            if (((_a = draft.conversation) === null || _a === void 0 ? void 0 : _a.id) === ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.conversation_id))
                draft.messages = __spread([action.payload], draft.messages);
            break;
        default:
    }
}, initialState);
export default chatReducer;
