import { emoticonMonsterVidegame } from 'assets/images';
import { EmptyListText } from 'components';
import { paths } from 'containers/RoutesProvider/routes';
import map from 'lodash/map';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { MyChallengeCard } from '../MyChallengeCard';
import { Container, EmoticonImage } from './styles';
export var ListMyChallenges = function (_a) {
    var challenges = _a.challenges, showMobile = _a.showMobile;
    var _b;
    var history = useHistory();
    var location = useLocation();
    var handleGoToChallengeDetail = function (challengeSlug) {
        return history.push(paths.challengeDetailPage.replace(':challengeSlug', challengeSlug), {
            from: location.pathname,
        });
    };
    return (React.createElement(Container, null, ((_b = challenges === null || challenges === void 0 ? void 0 : challenges.items) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (React.createElement(React.Fragment, null, map(challenges.items, function (item) { return (React.createElement(MyChallengeCard, { key: item.id, item: item, showMobile: showMobile, onClick: function () { return handleGoToChallengeDetail(item.slug); } })); }))) : (React.createElement(EmptyListText, null,
        React.createElement("span", null,
            React.createElement(FormattedMessage, { id: "containers.profile.myActiveChallenges.challengesEmptyList" })),
        React.createElement(EmoticonImage, { src: emoticonMonsterVidegame, alt: "emoticon" })))));
};
