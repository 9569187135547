var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { ifProp, switchProp } from 'styled-tools';
import { colors, fonts } from 'styles';
var text = theme('mode', {
    light: colors.carbon.tint100,
    dark: colors.base.white,
});
var bgLogged = theme('mode', {
    light: colors.space.tint100,
    dark: colors.carbon.tint700,
});
export var Container = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: ", " ", ";\n\n  overflow: hidden;\n\n  cursor: pointer;\n\n  ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: ", " ", ";\n\n  overflow: hidden;\n\n  cursor: pointer;\n\n  ",
    ";\n"])), rem(8), rem(6), ifProp('highlightedBackground', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), bgLogged)));
export var UserName = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  width: 100%;\n  padding-left: ", ";\n  margin-right: ", ";\n\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n\n  display: inline-block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n"], ["\n  flex: 1;\n  width: 100%;\n  padding-left: ", ";\n  margin-right: ", ";\n\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n\n  display: inline-block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n"])), rem(12), rem(20), fonts.weight.bold, rem(16), text);
export var Score = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  padding: ", ";\n  border-radius: ", ";\n\n  ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  padding: ", ";\n  border-radius: ", ";\n\n  ",
    ";\n"])), fonts.weight.bold, rem(16), rem(8), rem(20), switchProp('kind', {
    default: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), colors.space.tint500),
    success: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      padding: ", " ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      padding: ", " ", ";\n    "])), colors.base.primary, colors.base.white, rem(4), rem(16)),
    noPoints: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      padding: ", " ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      padding: ", " ", ";\n    "])), colors.space.tint500, colors.carbon.tint100, rem(4), rem(16)),
}));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
