import { keyboardDisabled, keyboardEnabled } from 'containers/App/actions';
import { makeSelectUseKeyboard } from 'containers/App/selectors';
import { useAction, useSelector } from 'hooks';
import { ChevronRight } from 'icons/mono';
import { rem } from 'polished';
import React, { memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { KeyboardModeContainer } from './styles';
export var KeyboardMode = memo(function () {
    var isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    var keyboard = useSelector(makeSelectUseKeyboard());
    var handleEnableKeyboard = useAction(keyboardEnabled);
    var handleDisableKeyboard = useAction(keyboardDisabled);
    useEffect(function () {
        window.addEventListener('keydown', function (e) {
            // TAB keycode = 9
            if (e.keyCode === 9) {
                handleEnableKeyboard();
                document.body.classList.add('keyboard');
            }
        });
        window.addEventListener('keydown', function (e) {
            // ESC keycode = 27
            if (e.keyCode === 27) {
                handleDisableKeyboard();
                document.body.classList.remove('keyboard');
            }
        });
    }, []);
    return (React.createElement(React.Fragment, null, !(isMobile || isTabletOrMobile) && (React.createElement(React.Fragment, null, keyboard && (React.createElement(KeyboardModeContainer, null,
        React.createElement("div", null,
            React.createElement("a", { href: "#main", tabIndex: 1 },
                React.createElement(FormattedMessage, { id: "components.keyboardMode.tabButton" }),
                React.createElement(ChevronRight, { size: rem(16) }))),
        React.createElement("span", null,
            React.createElement(FormattedMessage, { id: "components.keyboardMode.escButton" }))))))));
});
